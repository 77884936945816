import webSocket from '@/webSocket/webSocket';
import tools from '@/utils/tools';

export default (action, data) => {
  const serialId = tools.getQueryFromUrl('serial_id');
  if (!serialId) {
    return false;
  }
  webSocket.sendMsg({
    to: serialId,
    content: {
      action,
      data,
    },
  });
};
