<template>
  <div class="page-box">
    <div class="list-box">
      <div v-for="(item, index) in list" :key="item.id" @touchstart="touchStart(item, index)" @touchend="touchend">
        <van-swipe-cell>
          <van-cell :title="item.time" :label="format(item.cycle)" is_link center>
            <template #right-icon>
              <van-switch
                active-color="#f87a1b"
                inactive-color="#ffeac9"
                size="20px"
                :value="item.status === 'open'"
                @input="timingChange(item)"
              />
            </template>
          </van-cell>
          <template #right>
            <van-button square style="height: 100%" color="#999" text="编辑" @click.stop.prevent="edit(item)" />
            <van-button
              square
              style="height: 100%"
              type="danger"
              text="删除"
              @click.stop.prevent="openDialog(item, index)"
            />
          </template>
        </van-swipe-cell>
      </div>
    </div>
    <div v-if="list.length === 0" class="empty-box f fc fvc">暂无定时</div>
    <van-button @click="add" class="add-btn fixed" type="primary">添加定时</van-button>

    <van-popup v-model="show">
      <div class="f fv fc dialog-box">
        <p class="title">确认删除该定时吗</p>
        <div class="f fc frd btn-box">
          <div @click="closeDialog" class="btn cancel">取消</div>
          <div @click="del" class="btn">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
import CellGroup from '@/components/CellGroup/CellGroup.vue';
const groupArr = ['每周一', '每周二', '每周三', '每周四', '每周五', '每周六', '每周日'];

export default {
  components: { CellGroup },
  data() {
    return {
      serialId: '',
      list: [],
      show: false,
      editingItem: null,
      editingIndex: 0,
      longPressTimer: null,
    };
  },
  computed: {
    ...mapState(['generalConfig']),
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.getIncenseTiming();
  },
  methods: {
    touchStart(item, index) {
      this.longPressTimer = setTimeout(() => {
        this.openDialog(item, index);
      }, 2000);
    },
    touchend() {
      clearTimeout(this.longPressTimer);
    },
    format(arr) {
      arr = JSON.parse(arr);

      let str = '';
      let tmp = Array.from(new Set(arr));
      if (tmp.length == 1) {
        if (tmp[0] == 0) {
          str = '永不';
        } else if (tmp[0] == 1) {
          str = '每天';
        }
      } else {
        let tmpArr = [];
        arr.forEach((i, idx) => {
          if (i == 1) {
            tmpArr.push(groupArr[idx]);
          }
        });
        str = tmpArr.join();
      }
      return str;
    },
    closeDialog() {
      this.show = false;
      this.editingItem = null;
      this.editingIndex = 0;
    },
    openDialog(item, index) {
      this.show = true;
      this.editingItem = item;
      this.editingIndex = index;
    },
    del() {
      const formData = new FormData();
      formData.append('id', this.editingItem.id);
      this.$http.formPost(`${apiPath.delTiming}?serial_id=${this.serialId}`, formData).then(() => {
        this.$toast('删除成功');
        this.closeDialog();
        this.getIncenseTiming();
      });
    },
    edit(item) {
      this.$router.push(`/settings/timingEdit?from=edit&serial_id=${this.serialId}&id=${item.id}`);
    },
    timingChange(e) {
      const formData = new FormData();
      formData.append('serial_id', this.serialId);
      formData.append('status', e.status === 'open' ? 'close' : 'open');
      formData.append('time', e.time);
      formData.append('cycle', e.cycle);
      formData.append('id', e.id);
      this.$http.formPost(`${apiPath.incenseTiming}?serial_id=${this.serialId}`, formData).then(() => {
        this.$toast('修改成功');
        this.getIncenseTiming();
      });
    },
    getIncenseTiming() {
      this.$http
        .get(apiPath.incenseTiming, {
          serial_id: this.serialId,
        })
        .then(res => {
          this.list = res.data;
        });
    },
    add() {
      this.$router.push(`/settings/timingEdit?from=add&serial_id=${this.serialId}`);
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
  .list-box {
    height: calc(100vh - 73px);
    overflow: auto;
  }
  .empty-box {
    height: 80vh;
    color: #999;
    font-size: 14px;
  }
  .add-btn {
    width: 330px;
    height: 43px;
    background: #f87a1b;
    border-radius: 15px;
    font-size: 15px;
    color: #fff;
    border: none;
  }
  .fixed {
    position: fixed;
    bottom: 15px;
    left: 50%;
    margin-left: -165px;
  }
  .dialog-box {
    padding: 25px 0;
    width: 300px;
    border-radius: 15px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #3c3c3c;
    }

    .btn-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 22px 0 0;
      .btn {
        width: 120px;
        height: 43px;
        border-radius: 15px;
        line-height: 43px;
        text-align: center;
        font-size: 15px;
        color: #fff;
        background: #f87a1b;
      }
      .cancel {
        background: #fff;
        border: 1px solid #f87a1b;
        color: #f87a1b;
      }
    }
  }
}
</style>
