import axios from 'axios';
import { Toast } from 'vant';
import store from '@/store';
import router from '@/router';
import utils from '@/utils/utils';
import { Encrypt } from './aesEncrypt';
import initHmac from './hmacVerify.js';
const env = store.state.env;
const baseHost =
  env === 'isProd'
    ? 'https://worship.gem-ji.com'
    : env === 'isPre'
    ? 'https://pre-worship.gem-ji.com'
    : 'https://worship.jinhuiss.cn';
const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const service = axios.create({
  baseURL: '',
  timeout: 10 * 60 * 1000, // 请求超时时间
});

service.interceptors.request.use(
  config => {
    config.api_url = config.url;
    config.url = `${baseHost}${config.url}`;
    const headersRes = getRequestHeader(config);
    config.headers = Object.assign(config.headers, {
      Authorization: headersRes['Authorization'],
      'x-Date': headersRes['Date'],
      'access-token': store.getters.userAccessToken,
      mmc_platform: 'h5', //此字段专门给后端判断h5或者app
    });
    return config;
  },
  error => {
    console.error(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    errorHandler(error?.response?.data);
    return Promise.reject(error?.response?.data);
  }
);

const getRequestHeader = config => {
  const userName = 'web';
  const secret = ['isProd', 'isPre'].includes(env)
    ? 'fSJi8kl96uLDNnRXa9w4NOiZ7n2Qwx4t'
    : 'OzgME5q4XYgsVNVyKfxVFOMf55s5pWsT';
  const method = (config.method || 'GET').toUpperCase();
  const params = {
    userName,
    secret,
    url: config.api_url,
    method,
    host: env === 'isProd' ? 'worship.gem-ji.com' : env === 'isPre' ? 'pre-worship.gem-ji.com' : 'worship.jinhuiss.cn',
  };
  return initHmac(params);
};

const showErrMsg = data => {
  console.error(data);
  Toast({
    message: `${data.msg || '系统繁忙，请稍后再试'}`,
    duration: 3 * 1000,
  });
};

const errorHandler = data => {
  if (!data) {
    return false;
  }
  if ([400202, 400203].includes(data.code)) {
    Toast({
      message: `登录状态已失效，请重新登录`,
      type: 'error',
      duration: 3 * 1000,
    });
    utils.logout();
  } else if (data.code === 400204) {
    Toast({
      message: `账号已被冻结，请联系客服`,
      type: 'error',
      duration: 3 * 1000,
    });
    utils.logout();
  } else if (data.code === 401002) {
    Toast({
      message: `请先绑定智能神台`,
      type: 'error',
      duration: 3 * 1000,
    });
    router.replace('/');
  } else if ([400101, 400104].includes(data.code)) {
    showErrMsg(data);
    router.replace('/');
  } else {
    showErrMsg(data);
  }
};
const handleRequestBody = (body = null) => {
  if (body === null) return '';
  const tmp = Encrypt(body);
  return tmp;
};
const get = (url, params, headers = {}) => {
  params = params ? { ciphertext: handleRequestBody(params) } : undefined;
  return service.get(url, { params, headers });
};
const post = (url, body, headers = {}, _options = {}) => {
  const text = handleRequestBody(body);
  const formData = new FormData();
  text && formData.append('ciphertext', text);

  return service.post(url, formData, {
    headers: Object.assign(
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      headers
    ),
    onUploadProgress: e => {
      if (_options?.needProgress) {
        calcUploadProgress(e);
      }
    },
    cancelToken: source.token,
  });
};

const formPost = (url, formData, headers = {}, _options = {}) => {
  return service.post(url, formData, {
    headers: Object.assign(
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      headers
    ),
    onUploadProgress: e => {
      if (_options?.needProgress) {
        calcUploadProgress(e);
      }
    },
    cancelToken: source.token,
  });
};

function calcUploadProgress(e) {
  const tmp = (((e.loaded / e.total) * 1000) / 10).toFixed(2) + '%';
  store.commit('setUploadProgress', tmp);
}

function cancelRequest(reason) {
  source.cancel(reason);
  Toast('用户取消上传');
  source = CancelToken.source();
}

export const request = ({ data, url, method = 'post', headers, params = {} }) => {
  const text = handleRequestBody(data);
  const formData = new FormData();
  text && formData.append('ciphertext', text);
  const paramsFormdata = {};
  if (Reflect.ownKeys(params).length) {
    const urlTxt = handleRequestBody(params);
    paramsFormdata.ciphertext = urlTxt;
  }
  return service({
    url,
    method,
    headers: Object.assign(
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      headers
    ),
    data: formData,
    params: paramsFormdata,
  });
};

export default { get, post, formPost, cancel: cancelRequest };
