var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container f fv fc" },
    [
      _c("GoBack", {
        attrs: {
          backIcon:
            "https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png",
        },
      }),
      _c("div", { staticClass: "list" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", {
            staticClass: "content",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }