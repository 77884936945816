<template>
  <div class="page">
    <div v-if="detail.id" class="pt-30">
      <div class="wish-card">
        <div class="wish-name">祈愿人：{{ detail.wish_name }}</div>
        <div class="wish-address">居住于：{{ detail.wish_address }}</div>
        <div class="wish-content">神明保佑：{{ detail.wish_content }}</div>
        <div class="wish-days">祈愿天数&nbsp;{{ detail.cumulative_days }}</div>
        <Dialog ref="dialog" />
      </div>
      <div class="wishHandle" v-if="$utils.calcUserIsMasterWithSerialId($route.query.serial_id)">
        <van-button
          :class="detail.intensify_status ? '' : 'van-button--disabled'"
          block
          color="#F87A1B"
          size="small"
          native-type="submit"
          v-debounce="intensifyFn"
        >
          强化祈愿
        </van-button>
        <van-button plain block color="#F87A1B" size="small" native-type="submit" class="wishEdit" v-debounce="wishEdit"
          >更换祈愿</van-button
        >
      </div>
    </div>
    <template v-else>
      <div class="f">
        <img src="https://img-fe.tengzhihh.com/image/7c2d418c14e8ae55c80c8760f7ba0cb3-148x346.png" class="null-icon" />
      </div>
      <div class="null-tip">尚未发布祈愿</div>
      <div v-if="$utils.calcUserIsMasterWithSerialId($route.query.serial_id)" class="wishAdd">
        <van-button block color="#F87A1B" size="small" native-type="submit" v-debounce="wishAdd">开始祈愿</van-button>
      </div>
    </template>
  </div>
</template>

<script>
import { getGodWish, intensifyGodWish } from '@/api/fude';
import Dialog from '@/components/Dialog/Dialog.vue';
export default {
  components: { Dialog },
  data() {
    return {
      detail: {},
    };
  },
  created() {
    this.getGodWish();
  },
  methods: {
    async getGodWish() {
      const { code, data } = await getGodWish({
        serial_id: this.$route.query.serial_id,
        god_id: this.$route.query.god_id,
      });
      if (!data.id) return;
      this.detail = data;
    },
    async intensifyFn() {
      await intensifyGodWish({
        serial_id: this.$route.query.serial_id,
        id: this.detail.id,
      });
      this.getGodWish();
      this.$toast('祈愿强化成功');
    },
    wishAdd() {
      this.$router.push({ name: 'wishInfoHandle', query: this.$route.query });
    },
    wishEdit() {
      this.$refs.dialog.initDialog({
        title: '更换祈愿',
        content: `更换后将会覆盖之前的祈愿，是否继续？`,
        confirmText: '更换',
        cancelText: '取消',
        confirmCallback: () => {
          this.$router.push({
            name: 'wishInfoHandle',
            query: Object.assign(this.$route.query, {
              id: this.detail.id,
            }),
          });
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: calc(var(--vh) * 100);
  background: linear-gradient(180deg, #eba95d 0%, #fef2e4 100%);
  padding-bottom: 16px;
  button {
    border-radius: 10px;
    height: 44px;
  }
  .pt-30 {
    padding-top: 30px;
  }
  .wish-card {
    width: 100%;
    height: 528px;
    background-image: url('https://img-fe.tengzhihh.com/image/3b0437fc16e241a477760bbef2021043-746x1057.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 50px;
    position: relative;
    .wish-name {
      padding-top: 25px;
      height: 70px;
      font-size: 18px;
      color: #7b5439;
      line-height: 70px;
      text-align: center;
    }
    .wish-address {
      font-size: 14px;
      color: #7b5439;
      padding: 20px 40px 0;
      text-align: start;
      margin-bottom: 20px;
    }
    .wish-content {
      font-size: 14px;
      color: #7b5439;
      padding: 0 40px 0;
      text-align: start;
      height: 290px;
      overflow: auto;
      word-wrap: break-word;
    }
    .wish-days {
      position: absolute;
      font-weight: bold;
      font-size: 14px;
      color: #f87a1b;
      bottom: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .wishHandle {
    padding: 0 38px;
    button {
      margin-bottom: 16px;
    }
    .wishEdit {
      background: transparent;
    }
  }
  .null-icon {
    width: 73px;
    height: 173px;
    display: flex;
    margin: 150px auto 36px;
  }
  .null-tip {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
  .wishAdd {
    padding: 0 38px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 66px;
  }
}
</style>
