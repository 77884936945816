<template>
  <div class="layout">
    <orderTabs class="mb-10" />
    <div v-if="list.length">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <orderCard
          v-for="item in list"
          :key="item.id"
          :description="item"
          class="mb-10"
          :handleList="handleList(item)"
          @handleClick="handleClick"
          @cardClick="cardClick"
          @copy="copyFn"
        />
      </van-list>
    </div>
    <empty v-else />
    <Dialog ref="dialog" />
    <cancelPop ref="cancelPop" @cancelSuccess="resetList" />
    <confirmPop ref="confirmPop" @receiveConfirm="resetList" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';
import orderTabs from './component/orderTabs';
import orderCard from './component/orderCard';
import empty from '@/components/Empty';
import cancelPop from './component/cancelPop';
import confirmPop from './component/confirmPop';
import { orderList } from '@/api/order';
import { orderStatus, handleMap, virtualHandle } from '@/enum/shop.js';
import copy from 'copy-to-clipboard';
import { setStore } from '@/utils/localStore';
export default {
  components: {
    orderTabs,
    orderCard,
    empty,
    Dialog,
    cancelPop,
    confirmPop,
  },
  data() {
    return {
      list: [],
      current: 1,
      size: 10,
      finished: false,
      loading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleList(item) {
      if (item.goods_info.category === 'entity') {
        return handleMap[item.status];
      }
      if (item.goods_info.category === 'virtual') {
        return virtualHandle[item.status];
      }
      return [{ name: 'service', label: '联系客服' }];
    },
    handleClick({ name }, detail) {
      if (name === 'service') {
        this.contact();
        return;
      } else if (name === 'cancel') {
        this.$refs.cancelPop.openPop(detail.id);
      } else if (name === 'payment') {
        this.payFn(detail);
      } else if (name === 'confirm') {
        this.$refs.confirmPop.openPop(detail);
      } else if (name === 'express') {
        this.viewExpress(detail);
      } else if (name === 'repeat') {
        this.$router.push({
          name: 'goodsDetail',
          query: {
            id: detail.goods_id,
          },
        });
      }
    },
    viewExpress({ logistics }) {
      if (!logistics.logistics_sn) {
        this.$toast('暂无物流信息');
        return;
      }
      window.location.href = logistics.logistics_url;
    },
    copyFn({ order_center_id }) {
      this.copy(order_center_id);
    },
    copy(value) {
      if (
        copy(value, {
          format: 'text/plain',
        })
      ) {
        this.$toast('复制成功');
      }
    },
    receiveConfirm() {
      this.resetList();
    },
    payFn({ payment_mode, pay_info, order_center_id }) {
      const jumpUrl = this.spliceUrl(payment_mode, Object.assign(pay_info, { order_center_id }));
      jumpUrl && (location.href = jumpUrl);
    },
    spliceUrl(payment_mode, response) {
      setStore({
        name: 'routePrevious',
        content: this.$route.path,
      });
      let url = `https://${response.default}/pay?order_id=${response.order_center_id}&channel_id=${response.ali_pay_channel}`;
      if (this.$tools.isWeChat()) {
        url = `https://${response.wechatv3_official}/pay?order_id=${response.order_center_id}&channel_id=${response.wechat_office_pay_channel}`;
      }
      return url;
    },
    resetList() {
      this.list = [];
      this.current = 1;
      this.getList();
    },
    cardClick(item) {
      this.$router.push('/shop/orderInfo?order_id=' + item.order_center_id);
    },
    contact() {
      this.$utils.kefuDialog(this.$refs.dialog);
    },
    async onLoad() {
      this.current++;
      await this.getList();
    },
    async getList() {
      this.loading = true;
      const res = await orderList({ page: this.current, size: this.size, status: orderStatus[this.$route.name] });
      this.list = this.list.concat(res.data.list);
      if (res.data.pager.total_page === res.data.pager.current_page) {
        this.finished = true;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  background: #f5f5f5;
  padding: 10px;
  min-height: 100vh;
  .mb-10 {
    margin-bottom: 10px;
  }
}
</style>
