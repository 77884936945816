var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.pageStep === "index"
      ? _c(
          "div",
          { staticClass: "init-box f frd flex-wrap" },
          [
            _vm._l(_vm.modeList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "mode-box f fv fc",
                  on: {
                    click: function ($event) {
                      return _vm.clickMode(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: ` ${_vm.itemClass(
                        item,
                        "index_mode"
                      )} mode-info f fv fc`,
                    },
                    [
                      _c("img", {
                        staticClass: "mode-img",
                        attrs: { src: item.image },
                      }),
                      _c("div", { staticClass: "mode-name" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ]
                  ),
                  _c("div", [
                    item.index_mode === _vm.selectedMode.index_mode
                      ? _c("img", {
                          staticClass: "selected-icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/b7b12cf06d838d-56x56.png",
                          },
                        })
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            _vm._l(new Array(_vm.modeList.length % 2), function (item) {
              return _c("i", { staticClass: "mode-box" })
            }),
            _c("div", { staticClass: "confirm-box" }, [
              _c(
                "div",
                {
                  staticClass: "confirm-btn",
                  on: { click: _vm.confirmIndexMode },
                },
                [_vm._v(_vm._s(_vm.calcModeBtn))]
              ),
            ]),
          ],
          2
        )
      : _vm.pageStep === "religion"
      ? _c(
          "div",
          { staticClass: "init-box f frd flex-wrap religion-box" },
          [
            _c("GoBack", {
              attrs: {
                backFunction: () => {
                  _vm.pageStep = "index"
                },
              },
            }),
            _vm._l(_vm.religionList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "mode-box f fv fc",
                  on: {
                    click: function ($event) {
                      return _vm.clickReligion(item)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: ` ${_vm.itemClass(
                        item,
                        "religion"
                      )} mode-info f fv fc`,
                    },
                    [
                      _c("img", {
                        staticClass: "religion-img",
                        attrs: { src: item.image },
                      }),
                      _c("div", { staticClass: "mode-name" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ]
                  ),
                  _c("div", [
                    item.religion === _vm.selectedReligion.religion
                      ? _c("img", {
                          staticClass: "selected-icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/b7b12cf06d838d-56x56.png",
                          },
                        })
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            _vm._l(new Array(_vm.religionList.length % 2), function (item) {
              return _c("i", { staticClass: "mode-box" })
            }),
            _c("div", { staticClass: "confirm-box" }, [
              _c(
                "div",
                {
                  staticClass: "confirm-btn",
                  on: { click: _vm.confirmReligion },
                },
                [_vm._v(_vm._s(_vm.calcReligionBtn))]
              ),
            ]),
          ],
          2
        )
      : _vm.pageStep === "list"
      ? _c(
          "div",
          [
            _c("GodList", {
              attrs: {
                godType: _vm.godType,
                serialId: _vm.serialId,
                religion: _vm.selectedReligion.religion,
                indexMode: _vm.selectedMode.index_mode,
                hasMasterWorship: _vm.hasMasterWorship,
                from: "settings",
              },
              on: {
                goBack: _vm.goBack,
                setModeAndReligionAndGod: _vm.setModeAndReligionAndGod,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }