<template>
  <div class="page-box">
    <div class="list-box">
      <div class="msg-item f fc" v-for="(item, index) in list" :key="index">
        <div class="item-img">
          <img :src="item.image" class="img" />
        </div>
        <div class="item-info fv fbt">
          <div class="item-data f fbt">
            <div class="item-name">{{ item.name }}</div>
          </div>
        </div>

        <van-switch
          active-color="#f87a1b"
          inactive-color="#ffeac9"
          size="20px"
          :value="item.message_notify === 'open'"
          @input="messageNotify($event, item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    messageNotify(e, item) {
      const tmp = JSON.stringify([
        {
          message_notify: e ? 'open' : 'close',
          serial_id: item.serial_id,
        },
      ]);
      const formData = new FormData();
      formData.append('bind_machines', tmp);
      this.$http.formPost(apiPath.updateMsgConfig, formData).then(() => {
        this.$toast('修改成功');
        this.getSettings();
      });
    },
    getSettings() {
      this.$http.get(apiPath.msgConfig).then((res) => {
        this.list = res.data.bind_machines;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
  .list-box {
    background: #fff;
    .msg-item {
      padding: 10px 0;
      margin: 0 10px;
      font-size: 14px;
      border-bottom: 1px solid #ebebeb;
      .item-img {
        width: 50px;
        margin: 0 20px 0 10px;
        img {
          width: 50px;
        }
      }
      .item-info {
        height: 100%;
        flex: 1;
        .item-data {
          height: 100%;
          .item-name {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
