<template>
  <van-pull-refresh @refresh="onRefresh" style="min-height: 100%" loading-text="刷新中" v-model="refreshLoading">
    <div class="list-page-box">
      <div class="banner">
        <van-swipe v-if="bannerInfo && bannerInfo.length" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="item in bannerInfo" :key="item.id">
            <img :src="item.wap_banner_image" alt="" @click="bannerClick(item)" />
          </van-swipe-item>
        </van-swipe>
        <img v-else src="https://img-fe.tengzhihh.com/image/36f01f5d5af05b-690x180.png" alt="" />
      </div>
      <div class="f fc fbt header-btn">
        <div @click="scanCode">+添加设备</div>
        <div @click="manageStatus = true" v-if="!manageStatus">管理设备</div>

        <div @click="manageStatus = false" v-if="manageStatus" :class="`${manageStatus ? 'cancel' : ''}`">取消</div>
      </div>

      <div class="list-box">
        <div v-for="item in list" class="item f fbt" :key="item.id" @click="handleDevice(item)">
          <div class="img-box f fvc fc">
            <img v-lazy="item.image" class="img" />
          </div>
          <div class="info-box f fv fbt">
            <div :class="`f fc name  ${manageStatus ? 'manage-status' : ''}`">
              <div :class="`point ${item.device_status === 1 ? 'online' : ''}`" v-if="!manageStatus"></div>
              <div class="name-content clamp1">
                {{ item.name }}
              </div>
              <div :class="`${item.device_status === 1 ? '' : 'offline-status'}`" v-if="!manageStatus">
                [ {{ item.device_status === 1 ? '在线' : '离线' }} ]
              </div>
            </div>
            <van-button v-if="manageStatus" class="op-btn del-btn" @click.stop.prevent="openUnBindDialog(item)">
              删除
            </van-button>
            <van-button v-else class="op-btn" @click.stop.prevent="handleIncense(item)">
              {{ item.incense_status === 1 ? '正在喷洒' : '开启香薰' }}</van-button
            >
          </div>
        </div>
      </div>

      <van-popup v-model="unBindFlag">
        <div v-if="settingItem" class="f fv fc dialog-box">
          <p class="tip">确认删除</p>

          <div class="f fv fc info">
            <div class="img-box">
              <img :src="settingItem.image" />
            </div>
            <div class="name">{{ settingItem.name }}</div>
          </div>
          <p class="sub-tip">接触绑定后，该神台机关于你的全部资料都会被删除</p>

          <div class="f fc frd btn-box">
            <van-button @click="unBind" :loading="loading" class="btn">删除</van-button>
            <div @click="closeDialog" class="btn cancel">取消</div>
          </div>
        </div>
      </van-popup>
    </div>
  </van-pull-refresh>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { mapGetters, mapState } from 'vuex';
export default {
  props: {},
  data() {
    return {
      manageStatus: false,
      list: [],
      unBindFlag: false,
      settingItem: null,
      refreshLoading: false,
      loading: false,
      bannerInfo: null,
    };
  },
  computed: {
    ...mapGetters(['userAccessToken']),
    ...mapState(['env']),
  },
  mounted() {
    this.$utils.checkToken(() => {
      this.getUserDevices();
      this.$initWXSDK();
    });
  },

  methods: {
    handleIncense(item) {
      if (item.device_status !== 1) {
        return this.$toast('该设备已离线');
      }

      if (!item.index_mode) {
        this.$router.replace(`/settings/initSelect?serial_id=${item.serial_id}&from=mode_select`);
        return;
      }

      this.$utils.useIncense({ serial_id: item.serial_id, from: 'wap_index' }, () => {
        // item.incense_status === 1;
      });
    },
    openUnBindDialog(item) {
      if (item.is_master) {
        this.$toast('您是当前神台机主账号，如需删除设备，请到眷吉智能神台操作');
        return;
      }
      this.settingItem = item;
      this.unBindFlag = true;
    },
    closeDialog() {
      this.unBindFlag = false;
      this.settingItem = null;
    },
    unBind() {
      this.loading = true;
      const formData = new FormData();
      formData.append('serial_id', this.settingItem.serial_id);
      this.$http
        .formPost(apiPath.unBind, formData)
        .then(() => {
          this.loading = false;
          this.closeDialog();
          this.$toast('解绑成功');
          this.getUserDevices();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    scanCode() {
      this.$tools.scanCode();
    },
    handleDevice(item) {
      if (item.device_status !== 1) {
        if (this.env !== 'isLocal') {
          return this.$toast('该设备已离线');
        }
      }

      if (!item.index_mode) {
        this.$router.replace(`/settings/initSelect?serial_id=${item.serial_id}&from=mode_select`);
        return;
      }

      this.$track('shouye_enter_shentai_click');
      this.$router.push(`/device/index?serial_id=${item.serial_id}`);
    },
    bannerClick({ id }) {
      if (id) {
        this.$track('shouye_banner_yuhuang');
        this.$router.push(`/shop/goodsDetail?id=${id}&from=list_banner`);
      }
    },
    onRefresh() {
      this.refreshLoading = true;
      this.getUserDevices();
    },
    getUserDevices() {
      this.$http
        .get(apiPath.getUserDevices, {
          ver: '1.1',
        })
        .then(res => {
          this.list = res.data.list;
          this.$store.commit('setUserMachinesList', res.data.list);
          this.bannerInfo = res.data.banner;
          if (this.list.length === 0) {
            this.$router.replace(`/login/index?from=index&page_step=4`);
          }
          this.$store.commit('setDevicesNumber', res.data.length);
          this.$store.commit('setSiMiaoLianMingGodGoodsId', res.data.banner[0]?.id);

          this.refreshLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list-page-box {
  background: #f5f5f5;
  min-height: calc(var(--vh) * 100 - 60px);
  padding-bottom: 10px;
  .header-btn {
    padding: 18px 18px 0;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    .cancel {
      color: #f87a1b;
    }
  }
  .banner {
    width: 345px;
    height: 90px;
    border-radius: 10px 10px 10px 10px;
    padding-top: 16px;
    margin: 0 auto;
    img {
      width: 345px;
      height: 90px;
    }
  }

  .list-box {
    margin-top: 24px;
    padding: 0 18px;

    .item {
      box-sizing: border-box;
      width: 345px;
      height: 150px;
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 20px;
      margin-bottom: 16px;
      .img-box {
        width: 67px;
        background: #fff;
        border-radius: 7px;
        .img {
          width: 67px;
          border-radius: 7px;
        }
      }
      .info-box {
        width: 70%;
        .point {
          width: 6px;
          height: 6px;
          background: rgba(0, 0, 0, 0.45);
          border-radius: 50%;
          margin-right: 20px;
        }
        .online {
          background: #53c41c;
        }
        .offline-status {
          color: #969696;
        }
        .name {
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          .name-content {
            width: 55%;
          }
        }
        .op-btn {
          width: 209px;
          height: 40px;
          background: #f87a1b;
          border-radius: 10px;
          font-size: 16px;
          color: #fff;
          line-height: 40px;
          text-align: center;
        }
        .del-btn {
          background: #eb1b1b;
        }
      }
    }
  }
}
.dialog-box {
  background: #fff;
  border-radius: 15px;
  padding: 38px 0 20px;
  width: 295px;
  .img-box {
    width: 56px;
    background: #fff;
    border-radius: 7px;
    margin: 28px 0 8px;
    img {
      width: 56px;
      border-radius: 7px;
    }
  }
  .name {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }

  .sub-tip {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    padding: 0 50px;
    text-align: center;
    margin-top: 28px;
  }
  .tip {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }

  .btn-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 35px;
    margin: 27px 0 0;

    .btn {
      width: 100px;
      height: 40px;
      background: #f6f6f6;
      border-radius: 10px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.45);
    }
    .cancel {
      background: #f87a1b;
      color: #fff;
    }
  }
}
</style>
