var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          staticClass: "popup",
          attrs: { position: "bottom", "get-container": "body" },
          on: { "click-overlay": _vm.onCancel },
          model: {
            value: _vm.dialogFlag,
            callback: function ($$v) {
              _vm.dialogFlag = $$v
            },
            expression: "dialogFlag",
          },
        },
        [
          _c("div", { staticClass: "popup-box" }, [
            _c("div", {
              staticClass: "popup-line",
              on: {
                click: function ($event) {
                  _vm.dialogFlag = false
                },
                touchmove: _vm.onTouchMove,
                touchend: _vm.onTouchEnd,
              },
            }),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.godName) + " 供奉香火"),
            ]),
            _c("div", { staticClass: "f fc fbt" }, [
              _c("div", { staticStyle: { width: "40px" } }),
              _c("div", { staticClass: "f fc fvc" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/08de8f0e69a2db-50x50.png",
                  },
                }),
                _c("div", { staticClass: "f-value" }, [
                  _vm._v("香火 " + _vm._s(_vm.godFude)),
                ]),
              ]),
              _c("div", { staticClass: "link", on: { click: _vm.viewFude } }, [
                _vm._v("查看明细"),
              ]),
            ]),
            _c(
              "div",
              [
                _c("div", { staticClass: "sub" }, [_vm._v("今日任务")]),
                _vm._l(_vm.todayTask, function (task, idx) {
                  return _c("div", { key: idx, staticClass: "content" }, [
                    _c("div", { staticClass: "f fc fbt" }, [
                      _c("div", { staticClass: "f fc" }, [
                        _c("img", {
                          staticClass: "detail-icon",
                          attrs: { src: task.icon_image },
                        }),
                        _c("div", [
                          _c("div", { staticStyle: { "font-size": "16px" } }, [
                            _vm._v(_vm._s(task.title) + "，"),
                          ]),
                          _c("div", {
                            domProps: { innerHTML: _vm._s(task.subtitle) },
                          }),
                        ]),
                      ]),
                      task.status
                        ? _c("div", { staticClass: "btn cancel" }, [
                            _vm._v("已供奉"),
                          ])
                        : _c(
                            "div",
                            {
                              staticClass: "btn confirm",
                              on: { click: _vm.goOffer },
                            },
                            [_vm._v("去供奉")]
                          ),
                    ]),
                  ])
                }),
              ],
              2
            ),
            _c("div", { staticStyle: { "margin-top": "40px" } }, [
              _c("div", { staticClass: "sub f fc fbt" }, [
                _c("div", [_vm._v("香火说明")]),
              ]),
              _c("div", { staticClass: "content" }, [
                _vm._v(
                  "香火表达了对神明的尊敬、膜拜和为子孙后代祈求保佑。增加香火，也会同步增加功德值。"
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }