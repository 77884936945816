// https://cloud.tencent.com/document/product/269/37411
import { Toast } from 'vant';
import dayjs from 'dayjs';
import store from '@/store';
import TIM from 'tim-js-sdk';
import bus from '@/utils/bus';
import request from '@/utils/request';
const env = store.state.env;
const SDKAppID = ['isProd', 'isPre'].includes(env) ? 1400740475 : 1400565283;
import apiPath from '@/utils/apiPath';
let tim = null;
let hasInitSerialId = '';
let timeout = null;
export default {
  initIM(serialId) {
    const that = this;
    return new Promise(async (resolve, reject) => {
      if (serialId === hasInitSerialId) {
        console.log('已经初始化过');
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          resolve();
        }, 1000);
        return;
      }
      if (!serialId) {
        Toast('连接失败，请重新进入页面');
        reject('连接失败，请重新进入页面');
      }
      const res = await request.get(`${apiPath.getUserSig}?serial_id=${serialId}`);
      let options = {
        SDKAppID,
      };
      tim = TIM.create(options);

      tim.setLogLevel(1);
      const onMessageReceived = function (event) {
        that.getMsg(event.data);
      };
      const onReady = function (event) {
        hasInitSerialId = serialId;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          resolve(event);
        }, 1000);
      };
      tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);
      tim.on(TIM.EVENT.SDK_READY, onReady);

      const imResponse = await tim.login({ userID: res.data.cloud_username, userSig: res.data.im_usersig });
      if (imResponse?.data?.repeatLogin) {
        await tim.logout();
        await tim.login({ userID: res.data.cloud_username, userSig: res.data.im_usersig });
      }
    });
  },

  getMsg(e) {
    if (Array.isArray(e) && e.length >= 0) {
      const body = e[0];
      const from = body.from;
      let content = body?.payload;
      if (content && content.text) {
        this.handleMsg(from, JSON.parse(content.text));
      }
    }
  },
  handleMsg(serialId, content) {
    bus.$emit('onMsg', serialId, content);
  },
  sendMsg({ to, content }) {
    const time = dayjs().unix();
    let tmp = JSON.stringify({
      ...content,
      send_at: time,
      expire_at: 100,
    });
    let message = tim.createTextMessage({
      to,
      conversationType: TIM.TYPES.CONV_C2C,
      payload: {
        text: tmp,
      },
    });
    if (message && typeof message.catch === 'function') {
      message.catch(res => {
        Toast('连接失败，请刷新重试');
        setTimeout(async () => {
          this.sendMsg({ to, content });
        }, 1000);
      });
      return;
    }
    let promise = tim.sendMessage(message);
    console.log('发送消息', to, tmp);
    promise.catch(function (imError) {
      return Toast('连接失败，请刷新重试', imError);
    });
  },
  handleError(err) {
    return Toast('连接错误，请刷新重试', err.data.message);
  },
  destroy() {
    tim.destroy();
    tim = null;
    serialId = '';
    console.log('销毁socket');
  },
};
