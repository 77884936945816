<template>
  <div class="index-box">
    <div class="banner-box">
      <img src="https://img-fe.tengzhihh.com/image/e06376e0803b49-690x256.png" class="banner" />
      <img src="https://img-fe.tengzhihh.com/image/8c8d4e0f69b76b-690x106.png" class="bg" />
      <div class="name">广宁-紫竹观</div>
    </div>

    <div class="icon-box f fc frd flex-wrap">
      <div class="icon-item f fv fvc" v-for="(item, index) in iconList" @click="view(link)">
        <img :src="item.icon" class="icon-logo" />
        <div class="name">
          {{ item.name }}
        </div>
      </div>

      <i v-for="item in iList" />
    </div>
    <div class="news-box">
      <div class="news-title f fc">
        <div class="point"></div>
        <div>最新活动</div>
      </div>
      <div class="news-list">
        <div class="news-item f fc" v-for="(item, index) in list" @click="view(item.link)">
          <div class="left">
            <img src="https://img-fe.tengzhihh.com/image/e9faf356cd85dd-92x34.png" class="sub" />
            <img :src="item.cover" class="cover" />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import list from './list';
export default {
  components: {},
  data() {
    return {
      list,
      iconList: [
        {
          icon: 'https://img-fe.tengzhihh.com/image/50d5993f4cbf07-88x91.png',
          name: '祈福拜祭',
        },
        {
          icon: 'https://img-fe.tengzhihh.com/image/2dd36dc75bc313-88x88.png',
          name: '预约敬香',
        },
        {
          icon: 'https://img-fe.tengzhihh.com/image/27cbc3cf003d21-88x88.png',
          name: '点长明灯',
        },

        {
          icon: 'https://img-fe.tengzhihh.com/image/6631a775f4b01f-88x88.png',
          name: '放生祈福',
        },
        {
          icon: 'https://img-fe.tengzhihh.com/image/a5a3d2fb0a4ede-88x88.png',
          name: '受生债',
        },
      ],
      link: '',
    };
  },
  created() {
    this.$initWXSDK(() => {});
  },
  mounted() {
    this.getXCXLink();
  },
  computed: {
    isWeChat() {
      return this.$tools.isWeChat();
    },
    iList: function () {
      const len = this.iconList.length;
      const need = 5 - (len % 5);
      return new Array(need === 5 ? 0 : need);
    },
  },
  methods: {
    getXCXLink() {
      this.$http.get(apiPath.getXCXLink, { url: '/pages/coupon/index' }).then((res) => {
        this.link = res.data.mini;
      });
    },
    view(url) {
      location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
.index-box {
  width: 375px;
  min-height: calc(var(--vh) * 100 - 50px);
  background-color: #f5f5f5;
  padding-top: 16px;

  .banner-box {
    width: 345px;
    height: 128px;
    position: relative;
    margin: 0 auto;
    .name {
      position: absolute;
      bottom: 0;
      width: 345px;
      padding-left: 16px;
      padding-bottom: 10px;
      color: rgba(255, 255, 255, 0.85);
      font-size: 16px;
    }
    .bg {
      position: absolute;
      bottom: 0;
      width: 345px;
    }
    .banner {
      width: 345px;
      height: 128px;
      border-radius: 10px;
      margin: 0 auto;
      display: block;
    }
  }

  .icon-box {
    background: #fff;
    border-radius: 10px;
    width: 345px;
    margin: 16px auto;
    padding: 16px 0 0;
    box-sizing: border-box;
    .icon-item,
    i {
      width: calc(345px / 5);
      margin-bottom: 16px;
      .icon-logo {
        width: 44px;
        height: 44px;
        margin-bottom: 10px;
      }
      .name {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  .news-box {
    .news-title {
      margin: 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      .point {
        width: 5px;
        height: 16px;
        margin-right: 12px;
        background: #f87a1b;
        border-radius: 3px 3px 3px 3px;
      }
    }

    .news-list {
      .news-item {
        width: 345px;
        height: 100px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        margin: 16px auto;
        padding: 0 16px;
        .left {
          margin-right: 16px;
          position: relative;
          .sub {
            position: absolute;
            left: 0;
            top: 0;
            width: 46px;
            height: 17px;
          }
          .cover {
            width: 121px;
            height: 68px;
            border-radius: 8px 8px 8px 8px;
          }
        }
        .right {
          .title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 700;
          }
        }
      }
      .news-item:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
