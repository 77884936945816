<template>
  <div class="edit-page-box">
    <GoBack backIcon="https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png" />

    <img src="https://img-fe.tengzhihh.com/image/4e0e73a30570bb-750x1496.png" class="bg" />

    <div>
      <div class="com f fc fbt">
        <div class="title">功课名称</div>
        <div class="name">
          {{ content }}
        </div>
      </div>

      <div class="com">
        <div class="title">功课内容</div>
        <div class="item f fc fvc">
          <img src="https://img-fe.tengzhihh.com/image/93069dc8c7c89d-210x210.png" class="cover" />
          <img :src="calcTag" class="tag" />
          <!-- <div class="name">{{ content }}</div> -->
        </div>
      </div>

      <div class="com f fc fbt">
        <div class="title">设定播放时间</div>
        <div class="select-time-box f fc" @click="openDatePicker">
          <div class="tip">{{ pushTime || '点击选择时间' }}</div>
          <div class="f fc">
            <van-icon name="arrow" size="20px" />
            <div style="font-size: 14px; margin-left: 10px" v-if="pushTime" @click.stop.prevent="pushTime = ''">
              取消
            </div>
          </div>
        </div>
      </div>

      <van-button class="save-btn" type="primary" @click="save">保存</van-button>
    </div>

    <date-picker
      pickerType="time"
      :show="datePickerFlag"
      :currentHour="pushTime"
      :filter="timeFilter"
      :propMinDate="new Date()"
      :propMaxDate="new Date(2100, 0, 0)"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';
import GoBack from '@/components/GoBack/GoBack.vue';

export default {
  components: {
    DatePicker,
    GoBack,
  },
  props: {
    lessonId: {
      type: [Number, String],
      default: 0,
    },
    serialId: {
      type: String,
      default: '',
    },
    categoryId: {
      type: String,
      default: '1',
    },
    editMode: {
      type: String,
      default: 'new',
    },
  },
  data() {
    return {
      timeFilter: (type, options) => {
        const nowMin = new Date().getMinutes();
        let minArr = [],
          hourArr = [];
        if (type === 'minute') {
          minArr = options.filter((option) => option % 5 === 0);
          return minArr;
        }
        if (type === 'hour') {
          hourArr = options.filter((option, idx) => {
            return (idx === 0 && nowMin <= 55) || idx >= 1;
          });
          return hourArr;
        }
        return options;
      },
      content: '',
      datePickerFlag: false,
      pushTime: '',
    };
  },
  computed: {
    calcTag: function () {
      return this.categoryId === '1'
        ? 'https://img-fe.tengzhihh.com/image/165cf1f212e6b7-37x181.png'
        : this.categoryId === '2'
        ? 'https://img-fe.tengzhihh.com/image/f8725290a7fd5a-37x181.png'
        : 'https://img-fe.tengzhihh.com/image/e417f2ea85612e-37x181.png';
    },
  },
  mounted() {},

  methods: {
    setDetail(lessonDetail) {
      this.content = lessonDetail.name;
      this.pushTime = lessonDetail.remind_time;
    },
    openDatePicker() {
      this.datePickerFlag = true;
    },

    onConfirm(e) {
      this.pushTime = e;
    },
    onCancel() {
      this.datePickerFlag = false;
    },

    save() {
      const data = {
        serial_id: this.serialId,
        name: this.content,
        remind_type: this.pushTime ? 'every_day' : 'not',
      };
      if (this.pushTime) {
        data['remind_time'] = this.pushTime;
      }
      if (this.editMode === 'edit') {
        data['curriculum_id'] = this.lessonId;
      }
      this.$http.post(this.editMode === 'new' ? apiPath.addLesson : apiPath.saveLesson, data).then(() => {
        if (this.pushTime) {
          this.$track('gongke_set_play_time');
        }
        this.$toast('操作成功');
        if (this.editMode === 'new') {
          this.$router.replace(`/music/lessonList?serial_id=${this.serialId}`);
        } else {
          this.$router.replace(
            `/music/musicIndex?from=lesson&serial_id=${this.serialId}&lesson_id=${this.lessonId}&lesson_category_id=4`
          );
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-page-box {
  top: 0;
  left: 0;
  padding: 70px 20px 14px;
  height: calc(var(--vh) * 100);
  box-sizing: border-box;
  width: 375px;
  .van-cell {
    padding: 0;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }

  .com {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
    .title {
      color: #7b5439;
      font-weight: bold;
      font-size: 16px;
    }
    .name {
      font-size: 14px;
      color: rgba(3, 3, 3, 0.65);
    }
  }

  .textarea-area {
    width: 70%;
    background: #f6f2eb;
  }
  .placeholder {
    position: absolute;
    left: 15px;
    top: 12px;
    font-size: 14px;
    color: rgba(3, 3, 3, 0.65);
  }
  .item {
    margin: 16px 0 0;
    width: 105px;
    height: 105px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    .add-icon {
      width: 14px;
      height: 14px;
    }
    .cover {
      width: 105px;
      height: 105px;
    }
    .tag {
      width: 19px;
      height: 90px;
      position: absolute;
      top: 0;
      left: 10px;
    }
    .name {
      position: absolute;
      writing-mode: vertical-lr;
      text-orientation: upright;
      font-size: 12px;
      color: #ad7d48;
      left: 11px;
      top: 10px;
      letter-spacing: 4px;
    }
    .del-icon {
      width: 18px;
      height: 18px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .save-btn {
    width: 300px;
    height: 43px;
    background: #f87a1b;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #fff4e4;
    border: none;
    position: fixed;
    left: 50%;
    margin-left: -150px;
    bottom: 20px;
  }
}

.tip {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  color: #7b5439;
}
.time-tip {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.line {
  width: 330px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
</style>
