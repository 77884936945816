var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "van-popup",
        {
          staticClass: "popup",
          attrs: { position: "bottom" },
          model: {
            value: _vm.dialogFlag,
            callback: function ($$v) {
              _vm.dialogFlag = $$v
            },
            expression: "dialogFlag",
          },
        },
        [
          _c("div", { staticClass: "f fv fc popup-box" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "已经累计供奉" + _vm._s(_vm.godInfo.cumulative_days) + "天"
              ),
            ]),
            _c("div", { staticClass: "god-img-box" }, [
              _c("img", {
                staticClass: "bg",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/81925064b6126d-276x276.png",
                },
              }),
              _c("img", {
                staticClass: "god-item-img",
                attrs: { src: _vm.godInfo.wap_main_image },
              }),
            ]),
            _c("div", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.godInfo.name)),
            ]),
            _vm.godInfo.unique_number
              ? _c("div", { staticClass: "id-box" }, [
                  _c("img", {
                    staticClass: "id-bg-img",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/11e1a446efd149-392x64.png",
                    },
                  }),
                  _c("div", { staticClass: "id" }, [
                    _vm._v(
                      "神像编号（ID：" +
                        _vm._s(_vm.godInfo.unique_number) +
                        "）"
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "desc" }, [
              _c("div", { staticClass: "sub-title" }, [_vm._v("神明简介")]),
              _c("div", {
                staticClass: "content",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.godInfo.introduce &&
                      _vm.godInfo.introduce.replace(/\n/g, "<br />")
                  ),
                },
              }),
            ]),
            _c("div", { staticClass: "btn-box f fc fbt" }, [
              _c(
                "div",
                {
                  staticClass: "cancel-btn",
                  on: {
                    click: function ($event) {
                      return _vm.$utils.checkAuth(_vm.serialId, _vm.sendGod)
                    },
                  },
                },
                [_vm._v("送神回天")]
              ),
              _c(
                "div",
                {
                  staticClass: "confirm-btn",
                  on: {
                    click: function ($event) {
                      _vm.dialogFlag = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ]),
          ]),
        ]
      ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }