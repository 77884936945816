var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "number-status mb-16" }, [
      _c("div", { staticClass: "goods-id f" }, [
        _vm._v("订单编号:" + _vm._s(_vm.description.order_center_id)),
        _c("span", { staticClass: "copy", on: { click: _vm.copy } }),
      ]),
      _c("div", { staticClass: "statusText" }, [
        _vm._v(_vm._s(_vm.orderStatusText[_vm.description.status])),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "particulars mb-16 f", on: { click: _vm.cardClick } },
      [
        _c("van-image", {
          staticClass: "good-img",
          attrs: {
            src: _vm.description.goods_info && _vm.description.goods_info.image,
          },
          scopedSlots: _vm._u([
            {
              key: "error",
              fn: function () {
                return [_vm._v("加载失败")]
              },
              proxy: true,
            },
          ]),
        }),
        _c("div", { staticClass: "order-content" }, [
          _c("div", { staticClass: "name-amount f mb-10" }, [
            _c("p", { staticClass: "good-name" }, [
              _vm._v(_vm._s(_vm.description.goods_info.name)),
            ]),
            _c("p", { staticClass: "good-amount" }, [
              _vm._v("¥ " + _vm._s(_vm.description.goods_info.original_amount)),
            ]),
          ]),
          _c("div", { staticClass: "stats-count f" }, [
            _c(
              "p",
              { staticClass: "good-desc" },
              [
                _vm.description.goods_info.category === "virtual"
                  ? [_vm._v(" " + _vm._s(_vm.description.serial_id) + " ")]
                  : _vm._e(),
                _vm.description.goods_info.category === "entity" &&
                Object.keys(_vm.description.sku).length
                  ? [
                      _vm._v(
                        " " +
                          _vm._s(_vm.description.sku.sku) +
                          "：" +
                          _vm._s(_vm.description.sku.sku_name) +
                          " "
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("p", { staticClass: "good-count lh-20" }, [
              _vm._v("x" + _vm._s(_vm.description.goods_num)),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "pay-amount" }, [
      _vm._v(" 实付款"),
      _c("span", { staticClass: "amount-count" }, [
        _vm._v(" ¥ " + _vm._s(_vm.description.amount)),
      ]),
    ]),
    _vm.handleList.length
      ? _c(
          "div",
          { staticClass: "handle" },
          _vm._l(_vm.handleList, function (item) {
            return _c(
              "button",
              {
                key: item.name,
                staticClass: "handle-button",
                on: {
                  click: function ($event) {
                    return _vm.handleClick(item)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.label) + " ")]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }