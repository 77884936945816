var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [_c("cell-group", { attrs: { list: _vm.settingList } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }