var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("app-main", {
        attrs: { showBottom: _vm.currentRouteInfo.showBottom },
      }),
      _vm.currentRouteInfo.showBottom ? _c("bottom") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }