var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c(
        "van-cell-group",
        [
          _c("van-cell", {
            attrs: { title: "功课消息通知" },
            scopedSlots: _vm._u([
              {
                key: "right-icon",
                fn: function () {
                  return [
                    _c("van-switch", {
                      attrs: {
                        "active-color": "#f87a1b",
                        size: "20px",
                        "inactive-color": "#ffeac9",
                        value: _vm.settings.curriculum_notify,
                      },
                      on: { input: _vm.curriculum_notify },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("van-cell", {
            attrs: { title: "供奉敬香通知" },
            scopedSlots: _vm._u([
              {
                key: "right-icon",
                fn: function () {
                  return [
                    _c("van-switch", {
                      attrs: {
                        "active-color": "#f87a1b",
                        size: "20px",
                        "inactive-color": "#ffeac9",
                        value: _vm.settings.operation,
                      },
                      on: { input: _vm.operation },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("van-cell", {
            attrs: { title: "家风家训播放通知" },
            scopedSlots: _vm._u([
              {
                key: "right-icon",
                fn: function () {
                  return [
                    _c("van-switch", {
                      attrs: {
                        "active-color": "#f87a1b",
                        size: "20px",
                        "inactive-color": "#ffeac9",
                        value: _vm.settings.familyMotto,
                      },
                      on: { input: _vm.familyMotto },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("van-cell", {
            attrs: { title: "管理推送设备" },
            scopedSlots: _vm._u([
              {
                key: "right-icon",
                fn: function () {
                  return [
                    _c("van-switch", {
                      attrs: {
                        "active-color": "#f87a1b",
                        size: "20px",
                        "inactive-color": "#ffeac9",
                        value: _vm.settings.machineNotify,
                      },
                      on: { input: _vm.machineNotify },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("van-cell", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.settings.machineNotify,
                expression: "settings.machineNotify",
              },
            ],
            attrs: {
              title: "选择要管理的设备",
              "is-link": "",
              to: "/msg/machineNotify",
            },
          }),
          _c("van-cell", {
            attrs: { title: "消息免打扰" },
            scopedSlots: _vm._u([
              {
                key: "right-icon",
                fn: function () {
                  return [
                    _c("van-switch", {
                      attrs: {
                        "active-color": "#f87a1b",
                        size: "20px",
                        "inactive-color": "#ffeac9",
                        value: _vm.settings.dndMode,
                      },
                      on: { input: _vm.dndMode },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("van-cell", {
            attrs: {
              title: "免打扰时段",
              "is-link": "",
              label: _vm.dndModeStr,
            },
            on: { click: _vm.opendndModePopup },
          }),
        ],
        1
      ),
      _c("time-range-picker", {
        attrs: {
          title: "选择时间段",
          show: _vm.timeRangePickerFlag,
          defaultStart: _vm.dndStartHour,
          defaultEnd: _vm.dndEndHour,
          pickerType: "hour",
        },
        on: { onConfirm: _vm.confirmDatetimePicker, onCancel: _vm.onCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }