<template>
  <van-popup v-model="showFlag"
    @click-overlay="onCancel"
    position='bottom'>
    <div class="f fc fbt time-range-header">
      <div class="cancel"
        @click="onCancel">取消</div>
      <div class="title">选择时间段</div>
      <div class="confirm"
        @click="onConfirm">确定</div>
    </div>
    <div class="f fc frd time-range-body"
      v-if="pickerType==='hourminter'">
      <div style="width:40%">
        <van-datetime-picker v-model="beginTime"
          type="time"
          :show-toolbar='false' />
      </div>
      <div>
        至
      </div>
      <div style="width:40%">
        <van-datetime-picker v-model="endTime"
          type="time"
          :show-toolbar='false' />
      </div>
    </div>
    <div class="f fc frd time-range-body"
      v-if="pickerType==='hour'">
      <div style="width:40%">
        <van-picker :columns="columns"
          :default-index="defaultStart"
          @change="changeStart" />
      </div>
      <div>
        至
      </div>
      <div style="width:40%">
        <van-picker :columns="columns"
          :default-index="defaultEnd"
          @change="changeEnd" />
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    defaultStart: {
      type: [String, Number],
      default: 0,
    },
    defaultEnd: {
      type: [String, Number],
      default: 0,
    },
    show: {
      type: Boolean,
      default: false,
    },
    pickerType: {
      type: String,
      default: 'hourminter',
    },
  },
  data() {
    return {
      columns: new Array(24).fill(1).map((i, index) => index),
      showFlag: this.show,
      beginTime: `${new Date().getHours()}:${new Date().getMinutes()}`,
      endTime: `${new Date().getHours()}:${new Date().getMinutes()}`,
      beginHour: this.defaultStart,
      endHour: this.defaultEnd,
    };
  },
  watch: {
    show: function (v) {
      this.showFlag = v;
      this.init();
    },
  },
  methods: {
    init() {
      this.beginHour = this.defaultStart;
      this.endHour = this.defaultEnd;
    },
    changeStart(picker, value, index) {
      this.beginHour = value;
    },
    changeEnd(picker, value, index) {
      this.endHour = value;
    },
    onConfirm() {
      if (this.pickerType === 'hour') {
        this.$emit('onConfirm', this.beginHour, this.endHour);
      } else {
        this.$emit('onConfirm', this.beginTime, this.endTime);
      }
    },
    onCancel() {
      this.$emit('onCancel');
    },
    onChange(value, index) {
      this.$emit('onChange', value, index);
    },
  },
};
</script>

<style lang="less" scoped>
.time-range-header {
  padding: 0 20px;
  height: 50px;
  .title {
    font-size: 16px;
  }
  .confirm {
    font-size: 13px;
    color: #576b95;
  }
  .cancel {
    font-size: 13px;
  }
}
.van-popup {
  border-radius: 0;
}
</style>