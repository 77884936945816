import { setStore } from './../utils/localStore';
export default {
  setCurrentRouteInfo(state, info) {
    state.currentRouteInfo = info;
  },
  setUserOpenId(state, info) {
    setStore({
      name: 'user_open_id',
      content: info,
    });
    state.userOpenId = info;
  },
  setUserAccessToken(state, info) {
    setStore({
      name: 'user_access_token',
      content: info,
    });
    state.userAccessToken = info;
  },
  setUserInfo(state, info) {
    state.userInfo = info;
  },
  setVideoFile(state, info) {
    state.videoFile = info;
  },
  setAudioFile(state, info) {
    state.audioFile = info;
  },
  setUploadProgress(state, info) {
    state.uploadProgress = info;
  },
  setGeneralConfig(state, info) {
    Object.assign(state.generalConfig, info);
  },
  setDevicesNumber(state, info) {
    state.devicesNumber = info;
  },

  setAudioDuration(state, info) {
    state.audioDuration = info;
  },
  setMusicList(state, info) {
    state.musicList = info;
  },
  setSiMiaoLianMingGodGoodsId(state, info) {
    state.siMiaoLianMingGodGoodsId = info;
  },
  setUserMachinesList(state, info) {
    state.userMachinesList = info;
  },
  setRoutePrevious(state, info) {
    state.routePrevious = info;
  },
  setFudeGift(state, fude) {
    state.fudeGift.fude = fude;
  },
};
