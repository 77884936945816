import bus from '@/utils/bus';
import { commonInfo } from '@/mixins/commonInfo';
export const commonOperate = {
  mixins: [commonInfo],
  mounted() {
    bus.$on('onMsg', (serialId, content) => {
      if (serialId !== this.routeQuery.serial_id) return false;
      this.handleMsg !== void 0 && this.handleMsg(content);
      this.commonHandleMsg(content);
    });
    this.$webSocket.initIM(this.routeQuery.serial_id).then(() => {
      this.getSystemInfo && this.getSystemInfo();
    });
  },
  beforeDestroy() {
    bus.$off('onMsg');
  },
  methods: {
    commonHandleMsg(msg) {
      const action = msg.action;
      const operation = msg.data.operation;
      const map = {
        systemInfo: {
          operation: {
            indexModeRefresh: () => {
              this.goDevice();
            },
          },
        },
      };
      const actionCb = map[action]?.cb;
      const operationCb = map[action] && map[action]['operation'] && map[action]['operation'][operation];
      typeof actionCb === 'function' && actionCb();
      typeof operationCb === 'function' && operationCb();
    },
    goDevice() {
      this.$router.replace(`/device/index?serial_id=${this.routeQuery.serial_id}`);
    },
  },
};
