import http from './request';
import tools from './tools';
import apiPath from './apiPath';
export function InitWXSDK(onReady) {
  if (!tools.isWeChat()) {
    console.log('非微信环境，不注入微信JS-SDK');
    onReady && onReady();
    return;
  }
  let URL = window.entryUrl ? window.entryUrl : location.href;
  http
    .get(apiPath.getWXSign, {
      url: encodeURIComponent(URL),
    })
    .then(async res => {
      signWXSDK(res.data, onReady);
    });
}

function signWXSDK(config, onReady) {
  wx.config({
    debug: false,
    appId: config.app_id,
    timestamp: config.timestamp,
    nonceStr: config.noncestr,
    signature: config.signature,
    jsApiList: [
      'checkJsApi',
      'onMenuShareAppMessage',
      'hideMenuItems',
      'showMenuItems',
      'hideAllNonBaseMenuItem',
      'showAllNonBaseMenuItem',
      'translateVoice',
      'startRecord',
      'stopRecord',
      'onRecordEnd',
      'playVoice',
      'pauseVoice',
      'stopVoice',
      'uploadVoice',
      'downloadVoice',
      'chooseImage',
      'previewImage',
      'uploadImage',
      'downloadImage',
      'getNetworkType',
      'openLocation',
      'getLocation',
      'hideOptionMenu',
      'showOptionMenu',
      'closeWindow',
      'scanQRCode',
      'chooseWXPay',
      'openProductSpecificView',
      'addCard',
      'chooseCard',
      'openCard',
      'openAddress',
    ],
    openTagList: ['wx-open-launch-weapp'],
  });
  wx.ready(() => {
    wx.updateAppMessageShareData({
      title: '眷吉', // 分享标题
      desc: '爱·永·存', // 分享描述
      link: 'https://h5-worship.gem-ji.com/', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: 'https://img-fe.tengzhihh.com/image/c4477fbb7d2f6d-228x282.png', // 分享图标
    });
    if (!config.openShare) {
      wx.hideMenuItems({
        menuList: [
          'menuItem:share:timeline',
          'menuItem:share:appMessage',
          'menuItem:share:qq',
          'menuItem:share:QZone',
          'menuItem:copyUrl',
        ],
      });
    } else {
      wx.hideMenuItems({
        menuList: [
          'menuItem:share:timeline',
          'menuItem:share:qq',
          'menuItem:share:QZone',
          'menuItem:openWithSafari',
          'menuItem:share:email',
          'menuItem:openWithQQBrowser',
          'menuItem:copyUrl',
        ],
      });
    }
    onReady && onReady();
  });
  wx.error(error => {
    console.log('WX SDk loaded failed', error);
  });
}
