var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.deviceInfo && _vm.deviceInfo.machine
    ? _c(
        "div",
        { staticClass: "device-index-container" },
        [
          _c("DownloadBar"),
          _c(
            "div",
            { staticClass: "index-out-box" },
            [
              _c("GoBack", {
                attrs: {
                  backFunction: _vm.goHome,
                  homeTop: _vm.$tools.isAndroid() ? "118px" : "68px",
                  type: "home",
                },
              }),
              _vm.latelyFestival.length
                ? _c("festivalCalendar", {
                    staticClass: "festivalCalendar",
                    style: _vm.$tools.isAndroid()
                      ? { top: "123px" }
                      : { top: "73px" },
                    attrs: { list: _vm.latelyFestival },
                  })
                : _vm._e(),
              _c("div", { staticClass: "header-icon f fc fbt" }, [
                _c("div", { staticClass: "f fc" }, [
                  _c("img", {
                    staticClass: "btn-img",
                    attrs: { src: _vm.batteryIcon },
                  }),
                  _c("img", {
                    staticClass: "btn-img",
                    attrs: { src: _vm.incenseIcon },
                  }),
                ]),
                _vm.isLessonStarted
                  ? _c("div", { staticClass: "playing-box f fc fbt" }, [
                      _c(
                        "div",
                        { staticClass: "f fc", on: { click: _vm.goPlaying } },
                        [
                          _c("img", {
                            staticClass: "icon1",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/fb2f93f37e1ddf-36x36.png",
                            },
                          }),
                          _c("div", { staticClass: "name clamp1" }, [
                            _vm._v(_vm._s(_vm.playingName)),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "f fc" }, [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isPlaying,
                              expression: "isPlaying",
                            },
                          ],
                          staticClass: "icon2",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "exit-btn", on: { click: _vm.exit } },
                          [_vm._v("退出")]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "f fc" }, [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clickImgType !== "instructions",
                        expression: "clickImgType !== 'instructions'",
                      },
                    ],
                    staticClass: "btn-img",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/b59dc011a2374d-60x60.png",
                    },
                    on: { click: _vm.useInstructions },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clickImgType === "instructions",
                        expression: "clickImgType === 'instructions'",
                      },
                    ],
                    staticClass: "btn-img",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/b59dc011a2374d-60x60.png",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clickImgType !== "setting",
                        expression: "clickImgType !== 'setting'",
                      },
                    ],
                    staticClass: "btn-img",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/04b68e52664a82-60x60.png",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$utils.checkAuth(_vm.serialId, _vm.goSetting)
                      },
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.clickImgType === "setting",
                        expression: "clickImgType === 'setting'",
                      },
                    ],
                    staticClass: "btn-img",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/04b68e52664a82-60x60.png",
                    },
                  }),
                ]),
              ]),
              _c("img", {
                staticClass: "background-img",
                attrs: { src: _vm.deviceInfo.machine.wap_background_image },
              }),
              _c("div", { staticClass: "index-box" }, [
                _c("div", { staticClass: "incense-box" }, [
                  _c("div", {
                    staticClass: "yan-svga",
                    attrs: { id: "yan-svga-device-index" },
                  }),
                  _c("img", {
                    staticClass: "incense-img",
                    attrs: { src: _vm.deviceInfo.incense.image },
                    on: { click: _vm.handleIncense },
                  }),
                ]),
                _c("img", {
                  staticClass: "task-img",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/b5e8e8702186fa09dcf4c2afb7dc773c-94x110.png",
                  },
                  on: { click: _vm.handleTask },
                }),
                _vm.$utils.calcUserIsMasterWithSerialId(_vm.serialId)
                  ? _c(
                      "div",
                      { staticClass: "fude-box", on: { click: _vm.showFude } },
                      [
                        _c("div", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.deviceInfo.machine.user_fude || 0)),
                        ]),
                        _c("img", {
                          staticClass: "bg",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/9a2fc540f310fa-128x128.png",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _c("img", {
                  staticClass: "lesson-img",
                  attrs: {
                    src:
                      _vm.deviceInfo.curriculum.image ||
                      "https://img-fe.tengzhihh.com/image/db43a3a12f2312-92x142.png",
                  },
                  on: { click: _vm.handleLesson },
                }),
                _c("img", {
                  staticClass: "music-img",
                  attrs: { src: _vm.deviceInfo.music.image },
                  on: { click: _vm.handleMusic },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.clickImgType !== "family_motto",
                      expression: "clickImgType !== 'family_motto'",
                    },
                  ],
                  staticClass: "family-motto-img",
                  attrs: { src: _vm.deviceInfo.family_motto.image },
                  on: { click: _vm.handleMotto },
                }),
                _vm.wooden_fish.image
                  ? _c("img", {
                      staticClass: "woodfish-img",
                      attrs: { src: _vm.wooden_fish.image },
                      on: { click: _vm.woodfishFn },
                    })
                  : _vm._e(),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.clickImgType === "family_motto",
                      expression: "clickImgType === 'family_motto'",
                    },
                  ],
                  staticClass: "family-motto-img",
                  attrs: { src: _vm.deviceInfo.family_motto.image },
                  on: { click: _vm.handleMotto },
                }),
                !_vm.soloMode
                  ? _c(
                      "div",
                      { staticClass: "multi-guizi-box" },
                      [
                        _c("img", {
                          staticClass: "guizi-img",
                          attrs: {
                            src: _vm.deviceInfo.machine.main_body_image,
                          },
                        }),
                        _vm._l(_vm.fireList, function (item, index) {
                          return _c("img", {
                            key: index,
                            staticClass: "fire-ani",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/26b2cc7b06f2e3-207x207.png",
                              id: `fire-${
                                _vm.fireList.length === 6 ? index : index + 6
                              }`,
                            },
                          })
                        }),
                        _c("div", { staticClass: "god-box" }, [
                          _c("img", {
                            staticClass: "god-img click-hover",
                            attrs: { src: _vm.deviceInfo.god.image },
                            on: {
                              click: function ($event) {
                                return _vm.handleGod("multi")
                              },
                            },
                          }),
                          _vm.deviceInfo.god.left_lamp_image
                            ? _c("img", {
                                staticClass: "left-lamp-image",
                                attrs: {
                                  src: _vm.deviceInfo.god.left_lamp_image,
                                },
                              })
                            : _vm._e(),
                          _vm.deviceInfo.god.right_lamp_image
                            ? _c("img", {
                                staticClass: "right-lamp-image",
                                attrs: {
                                  src: _vm.deviceInfo.god.right_lamp_image,
                                },
                              })
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "earth-god-box" }, [
                          _c("img", {
                            staticClass: "earth-god-img",
                            attrs: { src: _vm.deviceInfo.earth_god.image },
                            on: {
                              click: function ($event) {
                                return _vm.handleEarthGod("multi")
                              },
                            },
                          }),
                          _c("img", {
                            staticClass: "left-lamp-image",
                            attrs: {
                              src: _vm.deviceInfo.earth_god.left_lamp_image,
                            },
                          }),
                          _c("img", {
                            staticClass: "right-lamp-image",
                            attrs: {
                              src: _vm.deviceInfo.earth_god.right_lamp_image,
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "deceased-box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "deceased-avatar click-hover",
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick("multi")
                                },
                              },
                            },
                            [
                              _c("div", [
                                _vm.deviceInfo.deceased.avatar
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      attrs: {
                                        src: _vm.deviceInfo.deceased.avatar,
                                      },
                                    })
                                  : _vm._e(),
                                _vm.deviceInfo.deceased.avatar
                                  ? _c("img", {
                                      staticClass: "bg",
                                      attrs: {
                                        src: _vm.deviceInfo.deceased.image,
                                      },
                                    })
                                  : _vm._e(),
                                !_vm.deviceInfo.deceased.avatar
                                  ? _c("div", { staticClass: "paiwei f" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.deviceInfo.deceased.image ||
                                            "https://img-fe.tengzhihh.com/image/9bfdaf39ae173d8970ee25236b3322c4-314x662.png",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "deceased-avatar-text f fvc",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "paiwei-title" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.deviceInfo.deceased.title
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c("img", {
                            staticClass: "left-lamp-image",
                            attrs: {
                              src: _vm.deviceInfo.deceased.left_lamp_image,
                            },
                          }),
                          _c("img", {
                            staticClass: "right-lamp-image",
                            attrs: {
                              src: _vm.deviceInfo.deceased.right_lamp_image,
                            },
                          }),
                        ]),
                      ],
                      2
                    )
                  : _c(
                      "div",
                      { staticClass: "solo-guizi-box" },
                      [
                        _c("img", {
                          staticClass: "guizi-img",
                          attrs: {
                            src: _vm.deviceInfo.machine.main_body_image,
                          },
                        }),
                        _vm._l(_vm.fireList, function (item, index) {
                          return _c("img", {
                            key: index,
                            staticClass: "fire-ani",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/26b2cc7b06f2e3-207x207.png",
                              id: `fire-${
                                _vm.fireList.length === 6 ? index : index + 6
                              }`,
                            },
                          })
                        }),
                        _vm.indexMode === "deceased"
                          ? _c("div", { staticClass: "deceased-mode" }, [
                              _vm.deviceInfo.deceased.decease_show == 1
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "enshrine-center-0",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClick("solo")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.deviceInfo.deceased.image ||
                                            "https://img-fe.tengzhihh.com/image/9bfdaf39ae173d8970ee25236b3322c4-314x662.png",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "deceased-text f fvc" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "enshrine-name" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.deviceInfo.deceased.title
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "deceased-box click-hover",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClick("solo")
                                        },
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c("img", {
                                          staticClass: "bg",
                                          attrs: {
                                            src: _vm.deviceInfo.deceased.image,
                                          },
                                        }),
                                        _c("img", {
                                          staticClass: "avatar",
                                          attrs: {
                                            src: _vm.deviceInfo.deceased.avatar,
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                              _c("img", {
                                staticClass: "left-lamp-image",
                                attrs: {
                                  src: _vm.currentOfferObj.left_lamp_image,
                                },
                              }),
                              _c("img", {
                                staticClass: "right-lamp-image",
                                attrs: {
                                  src: _vm.currentOfferObj.right_lamp_image,
                                },
                              }),
                            ])
                          : _c("div", { staticClass: "solo-god-mode" }, [
                              _c("img", {
                                staticClass: "god-img",
                                attrs: { src: _vm.currentOfferObj.image },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClick("solo")
                                  },
                                },
                              }),
                              _c("img", {
                                staticClass: "left-lamp-image",
                                attrs: {
                                  src: _vm.currentOfferObj.left_lamp_image,
                                },
                              }),
                              _c("img", {
                                staticClass: "right-lamp-image",
                                attrs: {
                                  src: _vm.currentOfferObj.right_lamp_image,
                                },
                              }),
                            ]),
                      ],
                      2
                    ),
              ]),
            ],
            1
          ),
          _c("Dialog", { ref: "dialog" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }