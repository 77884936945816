export default [
  {
    title: '道教礼仪解说 拱手礼与作揖礼',
    cover: 'https://uploadtouch.ggwan.com/image/6377f45736c229-1080x810.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56691.html',
  },
  {
    title: '道教知识：初一十五吃素还能有大功德？',
    cover: 'https://uploadtouch.ggwan.com/image/1fa8c7a82e8a9c-1080x810.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56692.html',
  },
  {
    title: '道教中供奉的水果和鲜花有什么寓意？',
    cover: 'https://uploadtouch.ggwan.com/image/6db2133d3a9b2b-670x369.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56693.html',
  },
  {
    title: '广宁紫竹观，你真的了解它吗？',
    cover: 'https://uploadtouch.ggwan.com/image/aeea993d80c885-800x475.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56694.html',
  },
  {
    title: '积攒功德的这些事，做多了竟有这么多收获！',
    cover: 'https://uploadtouch.ggwan.com/image/3b2545e2707efb-640x427.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56695.html',
  },
  {
    title: '去道观必知的礼仪',
    cover: 'https://uploadtouch.ggwan.com/image/1793213cb9e421-730x523.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56696.html',
  },
  {
    title: '三清，道教神明的最高信仰！',
    cover: 'https://uploadtouch.ggwan.com/image/3ecbeb350a6969-1080x734.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56697.html',
  },
  {
    title: '属兔、鸡、马、鼠、龙必看丨2023年太嵗不化，压运三年！',
    cover: 'https://uploadtouch.ggwan.com/image/bd61314e9e2148-1080x607.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56698.html',
  },
  {
    title: '文昌诞  二月初三拜文昌，学考顺利事业兴旺！',
    cover: 'https://uploadtouch.ggwan.com/image/23ea10a8edbc06-1080x810.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56699.html',
  },
  {
    title: '这样敬香祈福，好运才会来！',
    cover: 'https://uploadtouch.ggwan.com/image/af3d6d0a13643c-792x489.png',
    link: 'https://touch.lingyunqi365.cn/article/news-56700.html',
  },
  // {
  //   title: '供灯供对神，得愿又得福！',
  //   cover: 'https://uploadtouch.ggwan.com/image/850e8d1aba8988-1080x600.png',
  //   link: 'https://touch.lingyunqi365.cn/article/news-56646.html',
  // },
  // {
  //   title: '解答 积阳德好还是攒阴德更重要？	',
  //   cover: 'https://uploadtouch.ggwan.com/image/4723079b689258-1080x459.png',
  //   link: 'https://touch.lingyunqi365.cn/article/news-56647.html',
  // },
  // {
  //   title: '解答先谢太岁还是拜（化）太岁？哪个更重要？',
  //   cover: 'https://uploadtouch.ggwan.com/image/6377f45736c229-1080x810.png',
  //   link: 'https://touch.lingyunqi365.cn/article/news-56648.html',
  // },
  // {
  //   title: '为什么求财者都需要做补财库祈福？',
  //   cover: 'https://uploadtouch.ggwan.com/image/4d5402f6205206-633x399.png',
  //   link: 'https://touch.lingyunqi365.cn/article/news-56649.html',
  // },
  // {
  //   title: '详细解答｜兔鼠鸡龙马犯太岁需要规避哪些禁忌？',
  //   cover: 'https://uploadtouch.ggwan.com/image/e65e74d95154c7-1080x810.png',
  //   link: 'https://touch.lingyunqi365.cn/article/news-56650.html',
  // },
];
