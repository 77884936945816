var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { "get-container": "body" },
      model: {
        value: _vm.dialogFlag,
        callback: function ($$v) {
          _vm.dialogFlag = $$v
        },
        expression: "dialogFlag",
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dialogFlag,
              expression: "dialogFlag",
            },
          ],
          staticClass: "f fv fc dialog-box",
        },
        [
          _c("p", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.title))]),
          _vm.content
            ? _c("div", { staticClass: "content-box" }, [
                _c("p", {
                  staticClass: "content",
                  domProps: { innerHTML: _vm._s(_vm.content) },
                }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "f fc frd btn-box" }, [
            _vm.showCancelBtn
              ? _c(
                  "div",
                  { staticClass: "cancel-btn", on: { click: _vm.cancel } },
                  [_vm._v(_vm._s(_vm.cancelText))]
                )
              : _vm._e(),
            _c(
              "div",
              {
                class: `${_vm.showCancelBtn ? "" : "big-btn"} confirm-btn`,
                on: { click: _vm.confirmClick },
              },
              [_vm._v(_vm._s(_vm.confirmText))]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }