<template>
  <div class="edit-page-box">
    <img src="https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png" class="bg" />

    <div>
      <div class="textarea-box">
        <van-field
          type="textarea"
          class="textarea-area"
          maxlength="300"
          autosize
          show-word-limit
          v-model="content"
          placeholder="请输入你想说的话"
        ></van-field>
      </div>
      <div v-if="mottoType === 'video'">
        <div class="tip">我的视频</div>
        <van-icon name="arrow-left" v-if="playing" size="24px" class="back" @click="stopPlay" />
        <video
          id="edit-video"
          @click="playVideo"
          :class="`video-com ${playing ? 'full' : ''}`"
          playsinline
          allowfullscreen
          :controls="playing"
          x5-video-player-type="h5"
          style="object-fit: contain"
        ></video>
        <div class="images-box">
          <div v-for="(item, index) in images" class="img-box" :key="index">
            <img :src="item.url" @click.stop.prevent="preview(item)" class="image-item" />

            <img
              src="https://img-fe.tengzhihh.com/image/526d3d05af8245-50x50.png"
              class="del-icon"
              @click.stop.prevent="delImg(item, index)"
            />
          </div>
        </div>

        <div class="cover-tip f fc" v-if="images.length === 0" @click="chooseImg(1)">
          <img src="https://img-fe.tengzhihh.com/image/64779b7183de79-32x32.png" class="tip-img" />
          添加封面图
        </div>
      </div>
      <div v-if="mottoType === 'audio'">
        <audio controls id="edit-audio" />

        <div class="tip">我的音频</div>
        <div class="icon-box" @click="playAudio">
          <img
            src="https://img-fe.tengzhihh.com/image/4a8cb9c7d0e114-58x47.png"
            v-show="!audioPlaying"
            class="icon-gif"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/537b413f01a8e4-59x50.gif"
            v-show="audioPlaying"
            class="icon-gif"
          />
          <img src="https://img-fe.tengzhihh.com/image/b25c67d927904b-300x90.png" class="icon" />
        </div>

        <div class="tip">上传照片</div>

        <div class="images-box f fc flex-wrap">
          <div v-for="(item, index) in images" class="img-box" :key="index">
            <img :src="item.url" @click.stop.prevent="preview(item)" />

            <img
              src="https://img-fe.tengzhihh.com/image/526d3d05af8245-50x50.png"
              class="del-icon"
              @click.stop.prevent="delImg(item, index)"
            />
          </div>
          <div class="img-box f fv fvc fc border" v-if="images.length < 9" @click="chooseImg(2)">
            <van-icon name="plus" size="16px" color="#000" />
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="time-tip">设定播放时间</div>
      <div class="select-time-box f fc fbt" @click="openDatePicker">
        <div class="tip">{{ pushTime || '点击选择时间' }}</div>
        <van-icon name="arrow" size="20px" />
      </div>
      <van-button @click="preSave" class="save-btn" type="primary" :loading="saveLoading">{{
        saveLoading ? '上传中' : '上传'
      }}</van-button>
    </div>

    <date-picker
      pickerType="datetime"
      :show="datePickerFlag"
      :filter="timeFilter"
      :propMinDate="new Date()"
      :propMaxDate="new Date(2100, 0, 0)"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
    ></date-picker>
    <img-picker ref="comImgPicker" @emitImgUrl="emitImgUrl" :from="imgType === 1 ? '4' : '5'"></img-picker>

    <van-overlay :show="loading && uploading">
      <van-loading size="24px" color="#fff" vertical>
        <div class="f fv fvc uploading-box">
          <div class="top-tip">正在上传数据</div>
          <div class="progress">
            {{ uploadProgress }}
          </div>
          <div class="main-tip">请保持网络畅通</div>
          <div class="main-tip">不要离开本页面</div>
          <van-button @click="cancelUpload" class="cancel-btn">取消上传</van-button>
        </div>
      </van-loading>
    </van-overlay>
  </div>
</template>

<script>
import ImgPicker from '@/components/ImgPicker/ImgPicker.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import { ImagePreview } from 'vant';
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';
import { ossTools } from '@/utils/ossUpload';
import videoUploader from './videoUploader';
export default {
  components: {
    DatePicker,
    ImgPicker,
  },
  props: {
    serialId: {
      type: String,
      default: '',
    },
    mottoType: {
      type: String,
      default: '',
    },
    fileName: {
      type: String,
      default: '',
    },

    duration: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeFilter: (type, options) => {
        const nowMin = new Date().getMinutes();
        let minArr = [],
          hourArr = [];
        if (type === 'minute') {
          minArr = options.filter((option) => option % 5 === 0);
          return minArr;
        }
        if (type === 'hour') {
          hourArr = options.filter((option, idx) => {
            return (idx === 0 && nowMin <= 55) || idx >= 1;
          });
          return hourArr;
        }
        return options;
      },

      content: '',
      datePickerFlag: false,
      pushTime: '',
      images: [],
      fileSize: 0,
      videoId: '',
      audioUrl: '',
      playing: false,
      confirmFlag: false,
      audioPlaying: false,
      ossToolsIns: null,
      videoUrl: '',
      uploader: null,
      timeout: null,
      uploading: false,
      saveLoading: false,
      loading: false,
      saveTimeout: null,
      imgType: 1,
    };
  },
  computed: {
    ...mapState(['uploadProgress', 'videoFile', 'audioFile']),
  },
  // created() {
  //   this.getOSSConfig();
  // },
  mounted() {
    this.loading = false;

    this.$nextTick(() => {
      const file = this.videoFile;
      if (!this.uploader) {
        this.uploader = this.createUploader();
        this.uploader.addFile(file, null, null, null);
      }
      this.load();
    });
  },

  beforeDestroy() {
    clearTimeout(this.saveTimeout);
    clearTimeout(this.timeout);
  },
  methods: {
    ...videoUploader,
    getOSSConfig() {
      this.$http.get(apiPath.ossConfig).then((res) => {
        const str = window.atob(res.config);
        const ossConfig = JSON.parse(str);
        this.ossToolsIns = new ossTools(ossConfig);
      });
    },

    cancelUpload() {
      if (this.mottoType === 'audio') {
        clearTimeout(this.saveTimeout);
        typeof this.$http.cancel === 'function' && this.$http.cancel('用户取消上传');
      } else {
        this.videoId = '';
        typeof this.uploader.stopUpload === 'function' && this.uploader.stopUpload();
        this.loading = false;
      }
      this.loading = false;
      this.uploading = false;
      this.saveLoading = false;

      // this.$dialog({
      //   message: `确定取消上传吗`,
      //   title: '提示',
      //   closeOnPopstate: true,
      //   showCancelButton: true,
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      // }).then(() => {
      //   if (this.mottoType === 'audio') {
      //     typeof this.$http.cancel === 'function' && this.$http.cancel('用户取消上传');
      //   } else {
      //     this.videoId = '';
      //     typeof this.uploader.stopUpload === 'function' && this.uploader.stopUpload();
      //     this.loading = false;
      //   }
      //   this.loading = false;
      //   this.uploading = false;
      // });
    },
    load() {
      if (this.mottoType === 'video') {
        if (!this.videoFile) {
          this.$toast('视频文件为空，请重新添加');
          this.$emit('setValue', 'activeClose', true);
          return this.$router.replace(`/motto/list?serial_id=${this.serialId}`);
        }
        this.videoFile && this.loadVideo(this.videoFile);
      } else if (this.mottoType === 'audio') {
        if (!this.audioFile) {
          this.$toast('音频文件为空，请重新添加');
          this.$emit('setValue', 'activeClose', true);

          return this.$router.replace(`/motto/list?serial_id=${this.serialId}`);
        }
        this.audioFile && this.loadAudio(this.audioFile);
      }
    },
    emitImgUrl(e) {
      if (this.mottoType === 'audio') {
        this.images.push(e);
      } else {
        this.images = [e];
      }
    },
    delImg(item, index) {
      this.images.splice(index, 1);
    },
    openDatePicker() {
      this.datePickerFlag = true;
    },
    playAudio() {
      var audio = document.getElementById('edit-audio');

      if (!this.audioPlaying) {
        audio.play();
        this.audioPlaying = true;
        audio.onended = () => {
          this.audioPlaying = false;
        };
      } else {
        this.audioPlaying = false;
        audio && audio.pause();
      }
    },

    loadAudio(blob) {
      var audio = document.getElementById('edit-audio');
      //简单利用URL生成播放地址，注意不用了时需要revokeObjectURL，否则霸占内存
      audio.src = (window.URL || webkitURL).createObjectURL(blob);
    },
    loadVideo(file) {
      const url = URL.createObjectURL(file);
      const video = document.getElementById('edit-video');
      video.src = url;
      video.muted = true;
      video.play();
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        video.pause();
        video.muted = false;
      }, 500);
    },
    stopPlay() {
      const video = document.getElementById('edit-video');
      video.pause();
      this.playing = false;
    },
    playVideo() {
      const video = document.getElementById('edit-video');
      if (!this.playing) {
        video.play();
        video.onend = () => {
          this.playing = false;
        };
        this.playing = true;
      }
    },
    onConfirm(e) {
      this.pushTime = e;
    },
    onCancel() {
      this.datePickerFlag = false;
    },
    preview(item) {
      ImagePreview([item.url]);
    },
    chooseImg(type) {
      this.imgType = type;
      this.$refs.comImgPicker.uploadImg();
    },
    async preSave() {
      if (this.content.length > 300) {
        return this.$toast('内容过长，请删除一些内容后重试');
      }
      this.$store.commit('setUploadProgress', '0%');
      this.loading = true;
      this.saveLoading = true;

      if (this.mottoType === 'video') {
        this.uploadVideo()
          .then((res) => {})
          .catch((error) => {
            console.error(error);
            this.loading = false;
            this.saveLoading = false;

            return this.$toast('上传失败，请换个好点的网络再试试');
          });
      } else {
        if (!this.audioUrl) {
          this.uploadAudio()
            .then((res) => {
              clearTimeout(this.saveTimeout);
              this.saveTimeout = setTimeout(() => {
                this.audioUrl = res?.data?.url;
                this.uploading = false;
                this.save();
              }, 2000);
            })
            .catch((error) => {
              console.error(error);
              this.loading = false;
              this.saveLoading = false;
              return this.$toast('上传失败，请换个好点的网络再试试');
            });
        } else {
          clearTimeout(this.saveTimeout);
          this.saveTimeout = setTimeout(() => {
            this.save();
          }, 2000);
        }
      }
    },
    async uploadVideo() {
      if (this.videoId) {
        this.loading = false;
        this.uploading = false;

        this.save();
      } else {
        this.uploader.startUpload();
      }
    },
    uploadAudio() {
      this.uploading = true;
      const blob = this.audioFile;
      const file = new window.File([blob], `${new Date().getTime()}.${blob.type.split('/')[1]}`, { type: blob.type });
      const formData = new FormData();
      formData.append('file', file);
      this.fileSize = this.$tools.bytesToKB(file.size);
      return this.$http.formPost(
        apiPath.uploadAudio,
        formData,
        {
          'Content-Type': 'multipart/form-data	',
        },
        {
          needProgress: true,
        }
      );
    },
    validTime(time) {
      if (time) {
        const min = time.split(':')[1];
        return min % 5 === 0;
      }
      return true;
    },
    save() {
      if (!this.validTime(this.pushTime)) {
        return this.$toast('播放时间有误，请重新选择');
      }
      const formData = new FormData();
      formData.append('serial_id', this.serialId);
      formData.append('type', this.mottoType === 'video' ? 1 : 2);
      this.content && formData.append('content', this.content);
      this.mottoType === 'video' && formData.append('video_id', this.videoId);
      // this.mottoType === 'video' && formData.append('video_url', this.videoId);

      this.mottoType === 'audio' && formData.append('url', this.audioUrl);
      this.pushTime && formData.append('push_time', this.pushTime);
      this.duration && formData.append('duration', this.duration);
      this.fileSize && formData.append('size', this.fileSize);
      this.mottoType === 'video' && formData.append('cover_url', this.images.length > 0 ? this.images[0].url : '');
      this.mottoType === 'video' && formData.append('cover_size', this.images.length > 0 ? this.images[0].size : 0);
      this.mottoType === 'video' && formData.append('images', JSON.stringify([]));
      this.mottoType === 'audio' &&
        formData.append('images', this.images.length > 0 ? JSON.stringify(this.images) : JSON.stringify([]));
      this.$http
        .formPost(apiPath.addMotto, formData)
        .then((res) => {
          this.$track(this.mottoType === 'video' ? 'upload_video_success' : 'upload_audio_success');
          this.$toast(res?.data?.fude === 0 ? '上传成功' : `上传成功，功德值+${res.data.fude}`);
          this.loading = false;
          this.saveLoading = false;

          this.videoId = '';
          this.$emit('setValue', 'activeClose', true);

          this.$router.push(`/motto/list?serial_id=${this.serialId}`);
        })
        .catch(() => {
          this.loading = false;
          this.saveLoading = false;
        });
    },
  },
};
</script>

<style lang="less">
.edit-page-box {
  .van-cell::after {
    border: none;
  }
  .van-field {
    background: rgba(255, 255, 255, 0.3);
  }
  .van-field__body {
    height: 90px !important;

    .van-field__control {
      height: 100% !important;
    }
    .van-field__control::-webkit-input-placeholder {
      font-size: 14px;
      color: rgba(3, 3, 3, 0.65);
    }
  }
}
</style>
<style lang="less" scoped>
#edit-audio {
  display: none !important;
}
.edit-page-box {
  padding: 14px 20px;
  min-height: calc(var(--vh) * 100);
  box-sizing: border-box;
  #audio {
    display: none;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }
  .video-com {
    width: 100px;
    height: 100px;
    background: #494949;
    border-radius: 5px;
  }
  .textarea-box {
    width: 335px;
    height: 125px;
    border-radius: 5px;
    margin-bottom: 34px;
    overflow: hidden;
    position: relative;
    .textarea-area {
      height: 125px;
      width: 100%;
    }
    .placeholder {
      position: absolute;
      left: 15px;
      top: 12px;
      font-size: 14px;
      color: rgba(3, 3, 3, 0.65);
    }
  }
  .full {
    width: 100vw;
    height: calc(var(--vh) * 100);
    z-index: 100;
    position: fixed;
    left: 0;
    top: 0;
  }
  .save-btn {
    width: 300px;
    height: 43px;
    background: #f87a1b;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #fff4e4;
    border: none;
    position: fixed;
    left: 50%;
    margin-left: -150px;
    bottom: 20px;
  }
  .icon-box {
    position: relative;
    .icon {
      width: 150px;
    }
    .icon-gif {
      position: absolute;
      top: 9px;
      left: 20px;
      width: 28px;
      height: 25px;
    }
  }
}

.tip {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin: 13px 0;
}
.line {
  width: 330px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
.time-tip {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.cover-tip {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin: 14px 0;
  .tip-img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
}
.back {
  position: fixed;
  z-index: 120;
  left: 20px;
  top: 40px;
  color: #fff;
}

.images-box {
  width: 100%;
  margin: 14px 0;
  .img-box {
    position: relative;
    margin: 0 36px 14px 0;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.45);
    img {
      width: 50px;
      height: 50px;
    }
    .del-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      right: -8px;
      top: -8px;
    }
  }
}

.uploading-box {
  box-sizing: border-box;
  padding: 37px 0 20px;
  width: 295px;
  background: #fff;
  border-radius: 15px;
  .top-tip {
    font-size: 18px;
    color: #333333;
  }
  .progress {
    font-size: 34px;
    color: #333333;
    margin: 44px 0;
  }
  .main-tip {
    font-size: 15px;
    color: #f87a1b;
  }
  .cancel-btn {
    margin-top: 44px;
    width: 218px;
    height: 43px;
    background: #f87a1b;
    border-radius: 15px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
  }
}
</style>
