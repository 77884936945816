<template>
  <div class="list-container">
    <GoBack backIcon="https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png" />

    <div class="top-bar">
      <div class="btn" @click="explanation">说明</div>
      <div class="title">
        {{ fudeType === 'user_fude' ? '总' : `${godName} `
        }}{{
          fudeType === 'user_fude'
            ? '功德值'
            : ['user_decease_fude'].includes(fudeType) && !godName
            ? '历代元祖宗亲之位'
            : '香火'
        }}
      </div>
      <div class="sub">
        <p class="sub-title">我的功德</p>
        <div class="f fvc">
          <img src="https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png" class="sub-icon" />
          <span class="sub-number">{{ fude }}</span>
        </div>
      </div>
    </div>
    <div class="list" id="fude-list" v-if="list.length > 0" @scroll="scrollBottomCheck">
      <div class="item f fc fbt" v-for="item in list" :key="item.id">
        <div class="name clamp1">{{ item.name }}</div>
        <div class="time f fv fc">
          <div>{{ item.created_at.split(' ')[0] }}</div>
          <div>{{ item.created_at.split(' ')[1] }}</div>
        </div>
        <div class="desc clamp2">{{ item.content }}</div>
        <div class="number">{{ item.numbers | fude }}</div>
      </div>
    </div>
    <div class="list f fv fvc" v-else>
      <img src="https://img-fe.tengzhihh.com/image/ca001910984ac3-140x132.png" class="icon" />
      <div class="tip">没有数据</div>
    </div>
  </div>
</template>

<script>
import GoBack from '@/components/GoBack/GoBack.vue';
import apiPath from '@/utils/apiPath';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  filters: {
    fude(val) {
      if (val < 0) {
        return val;
      }
      return `+${val}`;
    },
  },
  components: {
    GoBack,
  },
  props: {},
  data() {
    return {
      list: [],
      fude: 0,
      godName: '',
      fudeType: '',
      page: 1,
      isEnd: false,
    };
  },
  created() {
    this.fudeType = this.$route.query.fude_type;
  },
  mounted() {
    this.getList();
    if (this.fudeType === 'user_fude') {
      this.$track('enter_gongdezhi_panges');
    } else {
      this.$track('gongfeng_xianghuo_mingxi');
    }
  },
  methods: {
    getList() {
      if (this.fudeType === 'user_fude') {
        this.getUserFudeList();
      } else {
        this.getUserGodFudeList();
      }
    },
    scrollBottomCheck() {
      const myElement = document.getElementById('fude-list');
      if (this.$tools.isScrolledToBottom(myElement)) {
        !this.isEnd && this.loadMore();
      }
    },
    loadMore() {
      this.page++;
      this.getList();
    },
    explanation() {
      this.$track('gongdezhi_shuoming');
      this.$router.push(`/fude/explanation?fude_type=${this.fudeType}&serial_id=${this.routeQuery.serial_id}`);
    },
    getUserFudeList() {
      this.$http
        .get(apiPath.userFude, {
          serial_id: this.routeQuery.serial_id,
          type: this.routeQuery.type || 1,
          page: this.page,
        })
        .then(res => {
          this.fude = res.data.user_fude;
          this.list = [...this.list, ...res.data.list];
          this.isEnd = res.data.pager.total_page === this.page;
        });
    },
    getUserGodFudeList() {
      this.$http
        .get(apiPath.userGodFude, {
          god_id: this.routeQuery.god_id,
          type: this.routeQuery.type || 1,
          page: this.page,
        })
        .then(res => {
          this.fude = res.data.user_god_fude;
          this.list = [...this.list, ...res.data.list];
          this.godName = res.data.god_name || '';
          this.isEnd = res.data.pager.total_page === this.page;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  width: 375px;
  min-height: calc(var(--vh) * 100);
  background-image: url('https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 0 30px 0;
  box-sizing: border-box;
  .top-bar {
    width: 375px;
    padding: 20px 0;
    box-sizing: border-box;
    position: relative;
    .title {
      text-align: center;
      font-size: 20px;
      color: #7b5439;
    }
    .btn {
      position: absolute;
      right: 16px;
      top: 22px;
      font-size: 14px;
      color: #7b5439;
    }
    .sub {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      background: rgba(255, 235, 210, 0.65);
      margin: 20px 10px 0;
      border-radius: 10px;
      padding-bottom: 10px;
      .sub-title {
        text-align: center;
        font-size: 20px;
        color: #85531f;
        line-height: 30px;
        letter-spacing: 2px;
        padding-top: 10px;
      }
      .sub-number {
        font-size: 18px;
        color: #f87a1b;
      }
      .sub-icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }
  .list {
    min-height: calc(var(--vh) * 100 - 105px);
    overflow: auto;
    background: rgba(255, 235, 210, 0.65);
    margin: 0 10px;
    border-radius: 10px;
    .item {
      height: 56px;
      margin: 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.65);
      padding: 4px 0;
      .name {
        font-size: 14px;
        width: 20%;
      }
      .time {
        font-size: 12px;
        text-align: center;
        width: 30%;
      }
      .desc {
        width: 30%;
        text-align: center;
        font-size: 14px;
      }
      .number {
        font-size: 18px;
        color: #f87a1b;
        text-align: right;
        width: 20%;
      }
    }
  }
  .icon {
    width: 70px;
    height: 66px;
    margin-bottom: 16px;
  }
  .tip {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
}
</style>
