var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageStep === 0
    ? _c("div", { staticClass: "page-box f fc fvc loading-tip" }, [
        _vm._v("跳转中..."),
      ])
    : _vm.pageStep === 1
    ? _c("Slogan")
    : _vm.pageStep === 2
    ? _c("LoginWay", { on: { setValue: _vm.setValue } })
    : _vm.pageStep === 3
    ? _c("PhoneLogin", {
        attrs: { sign: _vm.sign, serialId: _vm.serialId },
        on: { setValue: _vm.setValue },
      })
    : _vm.pageStep === 4
    ? _c("LoginSuccess", {
        attrs: { sign: _vm.sign, serialId: _vm.serialId },
        on: { setValue: _vm.setValue },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }