<template>
  <div class="grid-list">
    <van-grid :border="false" :column-num="columnNum">
      <van-grid-item v-for="{ icon, label, name, badge } in list" :key="name" @click="clickFn({ name, badge })">
        <van-badge :content="badge" max="99">
          <img :src="icon" class="icon" />
        </van-badge>
        <div class="name">{{ label }}</div>
      </van-grid-item>
    </van-grid>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '我的订单',
    },
    columnNum: {
      type: [Number, String],
      default: 4,
    },
  },
  methods: {
    clickFn({ name, badge }) {
      this.$emit('click', { name, badge });
    },
  },
};
</script>
<style lang="less" scoped>
.grid-list {
  .icon {
    width: 24px;
    display: block;
    margin-bottom: 3px;
  }
  .name {
    font-size: 12px;
  }
}
</style>
