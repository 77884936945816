var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _c("div", { staticClass: "top-bar fvc" }, [
      _c("img", {
        staticClass: "go-back-icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png",
        },
        on: { click: _vm.goBack },
      }),
      _c("div", { staticClass: "bar-name" }, [_vm._v("功德榜")]),
      _c("div", { staticClass: "bar-des", on: { click: _vm.explanation } }, [
        _vm._v("功德说明"),
      ]),
    ]),
    _c("div", { staticClass: "common-css my-fude" }, [
      _c("div", { staticClass: "f fbt", on: { click: _vm.showFudeDetail } }, [
        _c(
          "div",
          { staticClass: "my-fude-title" },
          [_vm._v("我的功德"), _c("van-icon", { attrs: { name: "arrow" } })],
          1
        ),
        _c("div", { staticClass: "my-fude-num f" }, [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png",
            },
          }),
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.user_fu_de || 0)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "f fbt" }, [
        _c("div", [
          _c("span", { staticClass: "label" }, [_vm._v("总榜排名：")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              _vm._s(
                _vm.total_rank_num ? _vm.total_rank_num + "名" : "尚未进榜"
              )
            ),
          ]),
        ]),
        _c("div", [
          _c("span", { staticClass: "label" }, [_vm._v("日榜排名：")]),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.day_rank_num ? _vm.day_rank_num + "名" : "尚未进榜")
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "common-css fude-rank" }, [
      _c("div", { staticClass: "fvc" }, [
        _c(
          "div",
          { staticClass: "single-choice f" },
          _vm._l(_vm.nav, function ({ label, key }) {
            return _c(
              "div",
              {
                key: key,
                class: [
                  "choice",
                  _vm.checkChoice === key ? "check-choice" : "",
                ],
                on: {
                  click: function ($event) {
                    return _vm.checkChoiceClick(key)
                  },
                },
              },
              [_vm._v(" " + _vm._s(label) + " ")]
            )
          }),
          0
        ),
      ]),
      _vm._m(0),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.rankList.length,
              expression: "rankList.length",
            },
          ],
          staticClass: "rank-content",
        },
        _vm._l(_vm.rankList, function (item, index) {
          return _c("div", { staticClass: "rank-list rank-grid" }, [
            _c("div", { staticClass: "index fvc" }, [
              index === 0
                ? _c("img", {
                    staticClass: "rank-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/7b6da973fa3bb24fef498c061ae44451-56x64.png",
                    },
                  })
                : index === 1
                ? _c("img", {
                    staticClass: "rank-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/886a5bcbb1580122934d63296df87647-56x63.png",
                    },
                  })
                : index === 2
                ? _c("img", {
                    staticClass: "rank-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/43d18bb093029cb3cddf7e228b0597f8-56x64.png",
                    },
                  })
                : _c("div", [_vm._v(" " + _vm._s(index + 1) + " ")]),
            ]),
            _c("div", { staticClass: "fc" }, [
              _c("img", {
                staticClass: "avatar",
                attrs: { src: item.avatar, alt: "", srcset: "" },
              }),
              _c("span", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
            ]),
            _c("div", { staticClass: "numbers" }, [
              _vm._v(_vm._s(item.numbers)),
            ]),
          ])
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.rankList.length,
              expression: "!rankList.length",
            },
          ],
          staticClass: "rank-content",
        },
        [
          _c("img", {
            staticClass: "null-icon",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/ca001910984ac3-140x132.png",
            },
          }),
          _c("p", { staticClass: "null-text" }, [_vm._v("尚无人进入榜单")]),
        ]
      ),
    ]),
    _c("div", { staticClass: "bottom-tips" }, [_vm._v("只显示榜单前20")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rank-grid" }, [
      _c("div", { staticClass: "rank-tr" }, [_vm._v("排名")]),
      _c("div", { staticClass: "rank-tr" }, [_vm._v("昵称")]),
      _c("div", { staticClass: "rank-tr" }, [_vm._v("功德")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }