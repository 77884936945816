var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `instructions-box f fv fc ${_vm.from === "STJ" ? "" : "top70"}` },
    [
      _vm.from !== "STJ"
        ? _c("GoBack", {
            attrs: {
              backIcon:
                "https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png",
            },
          })
        : _vm._e(),
      _vm._l(_vm.list, function ({ title, content }, idx) {
        return _c("div", { key: idx, staticClass: "item" }, [
          title
            ? _c("div", { staticClass: "title" }, [_vm._v(_vm._s(title))])
            : _vm._e(),
          _c("div", { staticClass: "content" }, [
            _c("p", { staticClass: "txt-c" }, [
              _c("img", { attrs: { src: content, alt: "", srcset: "" } }),
            ]),
          ]),
        ])
      }),
      _c("div"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }