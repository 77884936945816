<template>
  <van-popup position="bottom" round v-model="cancelPop" @close="cancelPopFn">
    <div class="cancelTitle">取消订单</div>
    <div class="cancelSub">请选择取消订单原因</div>
    <div class="cancelReasons">
      <van-radio-group v-model="cancelReasonId">
        <van-cell-group>
          <van-cell v-for="{ value, label } in cancelReasons" :key="value" :title="label">
            <template #right-icon>
              <van-radio :name="value" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <div class="popHandle f fbt">
      <van-button type="default" @click="cancelPopFn">暂不取消</van-button>
      <van-button :loading="cancelLoading" color="#F87A1B" @click="cancelOrder">确定取消</van-button>
    </div>
  </van-popup>
</template>

<script>
import { cancelOrder } from '@/api/order';
export default {
  data() {
    return {
      cancelPop: false,
      cancelId: '',
      cancelLoading: false,
      cancelReasonId: '',
      cancelReasons: [
        { value: '1', label: '价格有点贵' },
        { value: '2', label: '选错服务' },
        { value: '3', label: '暂时不需要' },
        { value: '4', label: '其他' },
      ],
    };
  },
  methods: {
    openPop(id) {
      this.cancelId = id;
      this.cancelPop = true;
    },
    cancelPopFn() {
      this.cancelPop = false;
      this.cancelReasonId = '';
    },
    async cancelOrder(orde_id) {
      if (!this.cancelReasonId) {
        this.$toast('请选择取消订单原因');
        return;
      }
      const res = await cancelOrder({ order_id: this.cancelId, reason_id: this.cancelReasonId });
      this.cancelPopFn();
      this.$emit('cancelSuccess', orde_id);
    },
  },
};
</script>

<style lang="less" scoped>
.cancelTitle {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  padding-top: 16px;
}
.cancelSub {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  padding: 30px 16px 0;
}
.cancelReasons {
  padding: 0 16px;
  /deep/.van-radio__icon--checked .van-icon {
    background-color: #f87a1b;
    border-color: #f87a1b;
  }
  .van-cell-group::after {
    border: 0;
  }
  .van-cell::after {
    border: 0;
  }
  .van-cell {
    border: 0;
    padding: 0;
    margin-top: 32px;
  }
}
.popHandle {
  padding: 22px 21px 20px;
  /deep/.van-button--default {
    background: #f6f6f6;
  }
  .van-button {
    width: 144px;
    height: 43px;
    border-radius: 10px;
  }
}
</style>
