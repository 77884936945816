<template>
  <div class="page-box">
    <van-cell-group>
      <van-cell title="功课消息通知">
        <template #right-icon>
          <van-switch
            active-color="#f87a1b"
            size="20px"
            inactive-color="#ffeac9"
            :value="settings.curriculum_notify"
            @input="curriculum_notify"
          />
        </template>
      </van-cell>
      <van-cell title="供奉敬香通知">
        <template #right-icon>
          <van-switch
            active-color="#f87a1b"
            size="20px"
            inactive-color="#ffeac9"
            :value="settings.operation"
            @input="operation"
          />
        </template>
      </van-cell>
      <van-cell title="家风家训播放通知">
        <template #right-icon>
          <van-switch
            active-color="#f87a1b"
            size="20px"
            inactive-color="#ffeac9"
            :value="settings.familyMotto"
            @input="familyMotto"
          />
        </template>
      </van-cell>
      <van-cell title="管理推送设备">
        <template #right-icon>
          <van-switch
            active-color="#f87a1b"
            size="20px"
            inactive-color="#ffeac9"
            :value="settings.machineNotify"
            @input="machineNotify"
          />
        </template>
      </van-cell>
      <van-cell title="选择要管理的设备" v-show="settings.machineNotify" is-link to="/msg/machineNotify"> </van-cell>
      <van-cell title="消息免打扰">
        <template #right-icon>
          <van-switch
            active-color="#f87a1b"
            size="20px"
            inactive-color="#ffeac9"
            :value="settings.dndMode"
            @input="dndMode"
          />
        </template>
      </van-cell>
      <van-cell title="免打扰时段" is-link :label="dndModeStr" @click="opendndModePopup"> </van-cell>
    </van-cell-group>
    <time-range-picker
      title="选择时间段"
      :show="timeRangePickerFlag"
      :defaultStart="dndStartHour"
      :defaultEnd="dndEndHour"
      pickerType="hour"
      @onConfirm="confirmDatetimePicker"
      @onCancel="onCancel"
    >
    </time-range-picker>
  </div>
</template>

<script>
import TimeRangePicker from '@/components/TimeRangePicker/TimeRangePicker.vue';
import apiPath from '@/utils/apiPath';
export default {
  components: { TimeRangePicker },
  data() {
    return {
      settings: {
        operation: false,
        familyMotto: false,
        machineNotify: false,
        dndMode: false,
        curriculum_notify: false,
      },
      dndModeStr: '',
      dndStartHour: 0,
      dndEndHour: 0,
      timeRangePickerFlag: false,
    };
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    getSettings() {
      this.$http.get(apiPath.msgConfig).then((res) => {
        this.settings.dndMode = res.data.dnd_mode === 'open';
        this.settings.familyMotto = res.data.family_motto === 'open';
        this.settings.operation = res.data.operation === 'open';
        this.settings.machineNotify = res.data.machine_notify === 'open';
        this.settings.curriculum_notify = res.data.curriculum_notify === 'open';
        this.dndStartHour = res.data.dnd_start_hour;
        this.dndEndHour = res.data.dnd_end_hour;
        this.dndModeStr = `${res.data.dnd_start_hour}:00 - ${res.data.dnd_end_hour}:00`;
      });
    },
    curriculum_notify(e) {
      const formData = new FormData();
      formData.append('curriculum_notify', e ? 'open' : 'close');
      this.updateMsgConfig(formData);
    },
    operation(e) {
      const formData = new FormData();
      formData.append('operation', e ? 'open' : 'close');
      this.updateMsgConfig(formData);
    },
    familyMotto(e) {
      const formData = new FormData();
      formData.append('family_motto', e ? 'open' : 'close');
      this.updateMsgConfig(formData);
    },
    machineNotify(e) {
      const formData = new FormData();
      formData.append('machine_notify', e ? 'open' : 'close');
      this.updateMsgConfig(formData);
    },
    dndMode(e) {
      const formData = new FormData();
      formData.append('dnd_mode', e ? 'open' : 'close');
      this.updateMsgConfig(formData);
    },
    updateMsgConfig(formData) {
      this.$http.formPost(apiPath.updateMsgConfig, formData).then(() => {
        this.$toast('修改成功');
        this.timeRangePickerFlag = false;
        this.getSettings();
      });
    },
    opendndModePopup() {
      this.timeRangePickerFlag = true;
    },
    confirmDatetimePicker(s, e) {
      const formData = new FormData();
      formData.append('dnd_start_hour', s);
      formData.append('dnd_end_hour', e);
      this.updateMsgConfig(formData);
    },
    onCancel() {
      this.timeRangePickerFlag = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
</style>
