var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("SystemEdit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageStep === "sys_edit",
            expression: "pageStep === 'sys_edit'",
          },
        ],
        ref: "systemEdit",
        attrs: {
          serialId: _vm.serialId,
          lessonId: _vm.lessonId,
          editMode: _vm.editMode,
          categoryId: _vm.categoryId,
        },
        on: { setValue: _vm.setValue },
      }),
      _c("CusEdit", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageStep === "cus_edit",
            expression: "pageStep === 'cus_edit'",
          },
        ],
        ref: "cusEdit",
        attrs: {
          editMode: _vm.editMode,
          selectedArr: _vm.selectedArr,
          serialId: _vm.serialId,
          lessonId: _vm.lessonId,
        },
        on: { setValue: _vm.setValue, deleteItem: _vm.deleteItem },
      }),
      _c("LessonSelect", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pageStep === "lesson_select",
            expression: "pageStep === 'lesson_select'",
          },
        ],
        ref: "lessonSelect",
        attrs: { serialId: _vm.serialId, indexMode: _vm.indexMode },
        on: {
          setValue: _vm.setValue,
          confirmSelectedList: _vm.confirmSelectedList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }