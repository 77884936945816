<template>
  <div class="page-box" v-if="!loading">
    <div v-show="pageType === 'list'" class="page-type1">
      <div class="list-box">
        <div class="banner-box">
          <img :src="bannerImg || 'https://img-fe.tengzhihh.com/image/7ca26b7201d131-750x176.png'" class="banner-img" />
        </div>

        <div class="options-area f fc">
          <div @click="changeCurrentPlayingMode(0)" :class="`option-btn ${currentPlayingMode === 0 ? 'current' : ''}`">
            开始播放
          </div>
          <div @click="changeCurrentPlayingMode(1)" :class="`option-btn ${currentPlayingMode === 1 ? 'current' : ''}`">
            随机播放
          </div>
        </div>
        <div class="list-area f frd flex-wrap">
          <div
            class="list-item f fv fc"
            @click="clickListItem(item, index)"
            v-for="(item, index) in musicList"
            :key="item.id"
          >
            <div class="img-box">
              <img
                :src="
                  indexMode === 'western'
                    ? 'https://img-fe.tengzhihh.com/image/960f9c5a350e7b-210x210.png'
                    : 'https://img-fe.tengzhihh.com/image/6bc78acb44741f-210x210.png'
                "
                class="cover"
              />
            </div>

            <div class="clamp1 name">{{ item.name }}</div>
          </div>

          <i v-for="item in 3 - (musicList.length % 3)" class="i-tag"></i>
        </div>
      </div>
      <div class="audio-box f fc" v-show="currentMusicId && playerType === 'music'">
        <img
          src="https://img-fe.tengzhihh.com/image/e611b3c86ac896-70x70.png"
          class="playing-img"
          @click="changePageType('playing')"
        />
        <div class="player-box clamp1">
          {{ currentMusic.name }}
        </div>
        <div class="control-box" @click="playControl">
          <van-circle
            :value="currentRate"
            class="circle-out"
            color="#FFC600"
            layer-color="#D6D3D6"
            :stroke-width="60"
            :rate="30"
          />
          <img src="https://img-fe.tengzhihh.com/image/dfd676ac3fb6e0-24x24.png" v-show="!isPlaying" class="circle" />
          <img src="https://img-fe.tengzhihh.com/image/05bb2d0ee92216-24x24.png" v-show="isPlaying" class="circle" />
        </div>
        <img
          src="https://img-fe.tengzhihh.com/image/124ecf7b97df9c-56x56.png"
          class="list-icon"
          @click="showMusicList"
        />
      </div>
    </div>

    <Playing
      ref="playingRef"
      v-show="pageType === 'playing'"
      :serialId="routeQuery.serial_id"
      :lessonId="lessonId"
      :lessonCategoryId="lessonCategoryId"
      :from="from"
      :currentMusic="currentMusic"
      :currentRate="currentRate"
      :currentPosition="currentPosition"
      :isPlaying="isPlaying"
      :currentPlayingMode="currentPlayingMode"
      :showLyric="showLyric"
      :indexMode="indexMode"
      :bgImg="bgImg"
      @showMusicList="showMusicList"
      @playControl="playControl"
      @currentPositionChange="currentPositionChange"
      @closePlayingMusicList="closePlayingMusicList"
      @changeCurrentPlayingMode="playingChangeCurrentPlayingMode"
      @changeShowLyric="changeShowLyric"
      @back="back"
    />

    <PlayList
      ref="playListRef"
      :serialId="routeQuery.serial_id"
      :lessonId="lessonId"
      :isPlaying="isPlaying"
      :from="from"
      :currentMusic="currentMusic"
      :currentPlayingList="currentPlayingList"
      :currentPlayingMode="currentPlayingMode"
      @removeMusicFromList="removeMusicFromList"
      @changeCurrentPlayingMode="playingChangeCurrentPlayingMode"
    />

    <Dialog ref="dialog" />
  </div>
  <div v-else class="loading-box f fc fvc">
    <van-loading type="spinner" />
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';
import bus from '@/utils/bus';
import Dialog from '@/components/Dialog/Dialog.vue';
import sendMsg from '@/webSocket/sendMsg';

import PlayList from './comp/PlayList.vue';
import Playing from './comp/Playing.vue';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  components: { Dialog, PlayList, Playing },
  data() {
    return {
      loading: true,
      tim: null,
      bannerImg: '',
      lessonId: '', // 跳转进来的课程ID
      currentMusicId: 0,
      isPlaying: false, //是否正在播放
      isStarted: false, //是否正在播放
      currentPosition: 0,
      currentPlayingList: [],
      cumulativeDays: 0,
      currentPlayingMode: 0, //0顺序 1随机 2单曲
      showLyric: false,
      lessonCategoryId: 1,
      isLessonStarted: false, //是否开始播放
      from: 'music', //music  lesson    该值由路由带参进来决定
      pageType: 'list', //list playing
      STJPlayerType: '', //classes   music  。该值由IM响应回来的action值决定，代表了神台机此时的播放内容
      startedLessonId: 0, // 神台机回传的课程ID
      timeout: null,
      indexMode: '',
      bgImg: '',
    };
  },
  computed: {
    ...mapState({
      musicList: state => state.musicList,
      audioList: state => state.player.audioList,
      playerType: state => state.player.playerType,
    }),

    playingModeIcon: function () {
      const arr = [
        {
          white: 'https://img-fe.tengzhihh.com/image/f4f5480903a05b-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/9b5d8316a675eb-48x48.png',
        },
        {
          white: 'https://img-fe.tengzhihh.com/image/88c9ad27afd6db-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/def0dd42ded094-48x48.png',
        },
        {
          white: 'https://img-fe.tengzhihh.com/image/34da1bf5732349-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/3af16216079cbe-48x48.png',
        },
      ];
      return arr[this.currentPlayingMode];
    },
    totalValue: function () {
      let currentMusic = this.musicList.find(
        i => this.currentMusicId != 0 && this.currentMusicId == (i.relation_id || i.id)
      );
      return currentMusic?.duration || 0;
    },
    currentMusic: function () {
      let currentMusic = this.musicList.find(
        i => this.currentMusicId != 0 && this.currentMusicId == (i.relation_id || i.id)
      );
      return (
        currentMusic || {
          id: '',
          name: '',
          lyric: '',
          duration: 0,
        }
      );
    },

    currentRate: function () {
      return (this.currentPosition / this.currentMusic.duration) * 100;
    },
  },
  watch: {
    pageType: function () {
      this.closePlayingMusicList();
    },
  },
  created() {
    this.lessonId = this.$route.query.lesson_id;
    this.from = this.$route.query.from || 'music';
    this.STJPlayerType = this.from;
    this.lessonCategoryId = Number(this.$route.query.lesson_category_id || 0);
    if (this.from === 'lesson') {
      this.pageType = 'playing';
    }
  },
  mounted() {
    this.$track(this.from === 'music' ? 'enter_yinyue_pages' : 'enter_gongke_pages');
    this.$webSocket.initIM(this.routeQuery.serial_id).then(async () => {
      this.from === 'music' ? await this.getList() : this.from === 'lesson' ? await this.getLessonDetail() : '';
      this.getInitMsg();
      bus.$on('onMsg', (from, content) => {
        if (from !== this.routeQuery.serial_id) {
          return false;
        }
        this.loading = false;
        this.handleMsg(content);
      });
    });
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
    bus.$off('onMsg');
  },

  methods: {
    getInitMsg() {
      sendMsg('player', {
        operation: 'playerStatus',
      });
      sendMsg('systemInfo', {
        operation: 'getVolume',
      });
      sendMsg('player', {
        operation: 'getAudioList',
        playerType: 'music',
      });
    },
    back() {
      if (this.from === 'music' && this.pageType === 'playing') {
        this.pageType = 'list';
        return true;
      } else if (this.isLessonStarted) {
        this.$router.replace(`/music/lessonList?serial_id=${this.routeQuery.serial_id}`);
        return true;
      }

      this.$router.go(-1);
    },
    changePageType(e) {
      this.pageType = e;
    },
    changeShowLyric(e) {
      this.$toast(`${e ? '' : '不'}显示经文`);

      sendMsg('player', {
        operation: 'lrcShowStatus',
        extra: e ? 1 : 0,
        playerType: this.from === 'music' ? 'music' : 'classes',
      });
    },

    closePlayingMusicList() {
      typeof this.$refs?.playListRef?.cancel === 'function' && this.$refs.playListRef.cancel();
    },
    removeMusicFromList(item, index) {
      sendMsg('player', {
        operation: 'delete',
        extra: item.id,
        playerType: this.from === 'music' ? 'music' : 'classes',
      });

      this.currentPlayingList.splice(index, 1);
    },
    showMusicList() {
      typeof this.$refs?.playListRef?.show === 'function' && this.$refs.playListRef.show();
    },

    playControl() {
      if (this.isPlaying) {
        sendMsg('player', {
          operation: 'pause',
          playerType: this.from === 'music' ? 'music' : 'classes',
        });
      } else {
        sendMsg('player', {
          operation: 'play',
          playerType: this.from === 'music' ? 'music' : 'classes',
        });
      }
    },
    currentPositionChange(v) {
      this.currentPosition = v;
      sendMsg('player', {
        operation: 'changeCurrentPosition',
        extra: v,
        playerType: this.from === 'music' ? 'music' : 'classes',
      });
    },
    showConfirm(cb) {
      this.$refs.dialog.initDialog({
        title: '提示',
        content: `神台机正在进行功课，如果继续播放音乐，将打断功课，是否继续？`,
        confirmText: '确认',
        cancelText: '取消',
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
          this.$track('interrupt_gongke');
          sendMsg('player', {
            operation: 'stop',
            playerType: 'classes',
          });
          clearTimeout(this.timeout);
          this.timeout = setTimeout(() => {
            typeof cb === 'function' && cb();
          }, 500);
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    clickListItem(item) {
      const index = this.audioList.findIndex(x => x === String(item.id));
      if (this.isLessonStarted) {
        this.showConfirm(() => {
          this.$track('play_music');
          if (index === -1) {
            sendMsg('player', {
              operation: 'addAudioPlay',
              extra: item.id,
              playerType: 'music',
            });
          }
          sendMsg('player', {
            operation: 'clickListItem',
            extra: index,
            playerType: 'music',
          });
        });
      } else {
        this.$track('play_music');
        if (index === -1) {
          sendMsg('player', {
            operation: 'addAudioPlay',
            extra: item.id,
            playerType: 'music',
          });
          sendMsg('player', {
            operation: 'clickListItem',
            extra: index,
            playerType: 'music',
          });
          return;
        }
        if (!this.isPlaying && !this.isStarted) {
          sendMsg('player', {
            operation: 'clickListItem',
            extra: index,
            playerType: 'music',
          });
        } else {
          sendMsg('player', {
            operation: 'changeCurrentPlay',
            extra: item.id,
            playerType: 'music',
          });
        }
      }
    },
    playingChangeCurrentPlayingMode() {
      const extra = [0, 1].includes(this.currentPlayingMode) ? this.currentPlayingMode + 1 : 0;
      sendMsg('player', {
        operation: 'changeRepeatMode',
        extra,
        playerType: this.from === 'music' ? 'music' : 'classes',
      });
    },
    changeCurrentPlayingMode(e) {
      if (this.isLessonStarted) {
        this.showConfirm(() => {
          this.confirmChangeCurrentPlayingMode(e);
        });
      } else {
        this.confirmChangeCurrentPlayingMode(e);
      }
    },

    confirmChangeCurrentPlayingMode(e) {
      if ([0, 1].includes(e)) {
        if (e === 0) {
          this.$track('play_music');
          sendMsg('player', {
            operation: 'start',
            playerType: this.from === 'music' ? 'music' : 'classes',
          });
        } else {
          this.$track('play_music');
          sendMsg('player', {
            operation: 'startRandom',
            playerType: this.from === 'music' ? 'music' : 'classes',
          });
        }
      }
      sendMsg('player', {
        operation: 'changeRepeatMode',
        extra: e,
        playerType: this.from === 'music' ? 'music' : 'classes',
      });
    },
    async updatePlayerStatus(msg) {
      const data = msg.data;
      if (this.STJPlayerType === 'music' && data.playerType === 'classes') {
        this.pageType = 'list'; //从音乐播放切换到功课播放,关闭playing
      }
      this.currentPosition = data.currentPosition;
      data.classesId && (this.startedLessonId = data.classesId);
      this.currentMusicId = data.musicId;
      this.currentPlayingMode = data.repeatMode;
      this.isPlaying = data.isPlaying;
      this.currentPlayingList = this.musicList.filter(i => data.playlistIdList.includes(String(i.relation_id || i.id)));
      this.isLessonStarted = data.isStarted && data.playerType === 'classes';
      this.isStarted = data.isStarted;
      if (data.playerType === 'music') {
        this.$store.commit('player/setAudioList', data.playlistIdList);
      }
      // 手机端页面参数与回传参数不一致时，以回传为准且重新跳转
      if (this.from === 'lesson' && this.isLessonStarted !== this.lessonId && data.isPlaying && data.isStarted) {
        await this.getLessonDetail(false, this.startedLessonId);
        this.$router.replace(
          `/music/musicIndex?from=lesson&serial_id=${this.routeQuery.serial_id}&lesson_id=${this.startedLessonId}&lesson_category_id=${this.lessonCategoryId}`
        );
      }
      if (this.isPlaying) {
        typeof this.$refs?.dialog?.closeDialog === 'function' && this.$refs.dialog.closeDialog();
      }
    },
    onExit() {
      if (this.from === 'lesson') {
        this.$toast('神台机退出了功课');
        this.$router.replace(`/music/lessonList?serial_id=${this.routeQuery.serial_id}`);
      } else {
        this.$router.replace({
          name: 'musicIndex',
          query: { serial_id: this.routeQuery.serial_id, t: new Date().getTime() },
        });
      }
    },
    updateProgress(msg) {
      const v = msg.data;
      this.currentPosition = v.currentPosition;
      this.isPlaying = v.isPlaying;
      this.currentMusicId = v.musicId;
      v.classesId && (this.startedLessonId = v.classesId);
    },

    handleMsg(msg) {
      const action = msg.action;
      const operation = msg.data.operation;

      const map = {
        player: {
          cb: () => {
            const showLyric = msg?.data?.lrcShowStatus == 1;
            if (showLyric !== this.showLyric) {
              this.showLyric = showLyric;
            }
          },
          operation: {
            playerStatus: () => {
              this.$store.commit('player/setPlayer', msg.data);
              this.updatePlayerStatus(msg);
            },
            onProgressChanged: () => {
              this.updateProgress(msg);
            },
            exit: () => {
              this.onExit();
            },
            classesFinished: () => {
              if (this.pageType === 'playing') {
                this.showFinishedDialog();
                this.$track('complete_gongke');
              }
            },
            getAudioList: () => {
              this.$store.commit('player/setAudioList', msg.data.playlistIdList);
            },
          },
        },
        systemInfo: {
          cb: () => {},
          operation: {
            updateVolume: () => {
              this.updateVolume(msg);
            },
          },
        },
      };

      const actionCb = map[action]?.cb;
      const operationCb = map[action] && map[action]['operation'] && map[action]['operation'][operation];
      typeof actionCb === 'function' && actionCb();
      typeof operationCb === 'function' && operationCb();
    },
    showFinishedDialog() {
      this.$refs.dialog.initDialog({
        title: '提示',
        content: this.$utils.calcUserIsMasterWithSerialId(this.routeQuery.serial_id)
          ? `功课完成，已坚持${this.cumulativeDays}天`
          : `功课完成`,
        confirmText: '重新开始',
        cancelText: '退出',
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
          sendMsg('player', {
            operation: 'restart',
            playerType: 'classes',
          });
        },
        cancelCallback: () => {
          sendMsg('player', {
            operation: 'stop',
            playerType: 'classes',
          });
          this.$router.replace(`/music/lessonList?serial_id=${this.routeQuery.serial_id}`);
          this.$refs.dialog.closeDialog();
        },
      });
    },
    updateVolume(msg) {
      const e = msg.data.mediaVolume;
      this.$nextTick(() => {
        typeof this.$refs?.playingRef?.updateVolume === 'function' && this.$refs.playingRef.updateVolume(e);
      });
    },
    getList() {
      return this.$http.get(`${apiPath.musicList}?serial_id=${this.routeQuery.serial_id}&from=user`).then(res => {
        this.$store.commit('setMusicList', res.data.list);
        this.currentPlayingList = res.data.list;
        this.bannerImg = res.data.wap_banner_image;
        this.indexMode = res.data.index_mode;
      });
    },
    getLessonDetail(needStart = true, lessonId) {
      !lessonId && (lessonId = this.lessonId);
      return this.$http
        .get(`${apiPath.lessonDetail}/${lessonId}`, {
          serial_id: this.routeQuery.serial_id,
        })
        .then(res => {
          this.lessonCategoryId = res.data.category_id;
          this.currentPlayingList = res.data.chapters;
          this.cumulativeDays = res.data.cumulative_days;
          this.indexMode = res.data.index_mode;
          this.bgImg = res.data.background_image;
          this.$store.commit('setMusicList', res.data.chapters);
          this.$tools.setDocumentTitle(res.data.name);
          if (needStart) {
            this.$track('do_gongke');
            sendMsg('player', {
              operation: 'start',
              extra: this.lessonId,
              playerType: 'classes',
            });
          }
        });
    },
    goHome() {
      this.$router.replace('/');
    },
  },
};
</script>
<style lang="less" scoped>
.loading-box {
  height: calc(var(--vh) * 100);
}
.page-box {
  width: 375px;

  height: calc(var(--vh) * 100);
  overflow: hidden;
  position: relative;
  .page-type1 {
    .list-box {
      background: #ffebd2;
      .banner-box {
        width: 375px;
        height: 88px;

        .banner-img {
          width: 375px;
          height: 88px;
        }
      }
      .options-area {
        margin-top: 12px;

        padding: 0 20px;
        .option-btn {
          font-size: 14px;
          color: #606060;
          margin-right: 12px;
        }
        .current {
          font-size: 16px;
          color: #f87a1b;
        }
      }

      .list-area {
        overflow: auto;
        padding: 0 10px 100px;
        margin: 13px 0;
        height: calc(var(--vh) * 100 - 100px);
        box-sizing: border-box;

        .list-item {
          width: 105px;
          overflow: hidden;
          flex-shrink: 0;
          .name {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            text-align: center;
            margin: 10px 0;
          }
          .img-box {
            width: 105px;
            height: 105px;
            .cover {
              width: 100px;
              height: 105px;
            }
          }
        }
        .i-tag {
          width: 105px;
          height: 105px;
        }
      }
    }

    .audio-box {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0.95;
      width: 375px;
      height: 60px;
      background: #fff;
      position: absolute;
      overflow: hidden;
      padding: 0 16px 0 13px;
      box-sizing: border-box;
      border-top: 1px solid #dbd9dc;
      .player-box {
        z-index: 1;
        flex: 1;
      }
      .playing-img {
        width: 38px;
        height: 38px;
        z-index: 1;
        margin-right: 12px;
      }
      .player-box {
        font-size: 14px;
      }
      .control-box {
        position: relative;
        z-index: 1;
        width: 33px;
        height: 33px;
        margin-left: 20px;
        .circle-out {
          width: 33px;
          height: 33px;
        }
        .circle {
          position: absolute;
          width: 12px;
          height: 12px;
          left: 50%;
          margin-left: -6px;
          top: 50%;
          margin-top: -6px;
        }
      }
      .list-icon {
        width: 24px;
        height: 25px;
        margin-left: 20px;
        z-index: 1;
      }
    }
  }
}
</style>
