<template>
  <van-popup :value="showFlag" @click-overlay="onCancel" position="bottom">
    <div class="f fc fbt header">
      <div class="cancel" @click="onCancel">取消</div>
      <div class="title">{{ title }}</div>
      <div class="confirm" @click="onConfirm">确定</div>
    </div>
    <van-checkbox-group v-model="result">
      <van-cell-group>
        <van-cell v-for="(item, index) in list" clickable :key="index" :title="item.label" @click="toggle(index)">
          <template #right-icon>
            <van-checkbox :name="item.id" checked-color="#f87a1b" ref="checkboxes" />
          </template>
        </van-cell>
      </van-cell-group>
    </van-checkbox-group>
  </van-popup>
</template>

<script>
export default {
  props: {
    showFlag: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
    selectedResult: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '选择时间',
    },
  },
  data() {
    return {
      result: [],
    };
  },
  watch: {
    selectedResult: function(n) {
      this.result = [...this.result, ...n];
    },
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    onCancel() {
      this.$emit('onCancel');
    },
    onConfirm() {
      this.$emit('onConfirm', this.result);
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  padding: 0 20px;
  height: 50px;
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .confirm {
    font-size: 13px;
    color: #576b95;
  }
  .cancel {
    font-size: 13px;
  }
}
.van-popup {
  border-radius: 0;
}
</style>
