<template>
  <div class="musicPage">
    <van-icon name="arrow-left" size="17" class="goback" @click="back" />
    <div class="type">
      <div class="f fbt">
        <div
          :class="['button', buttonType === value ? 'btnActive' : '']"
          v-for="{ value, label } in buttonList"
          :key="value"
          @click="changeType(value)"
        >
          {{ label }}
        </div>
      </div>
      <div class="list">
        <div class="head f fc" v-show="buttonType === 'music'" v-throttle="changePlayMode">
          <img :src="playingModeImg[this.player.repeatMode]" alt="" srcset="" />
          <p>{{ playingModeTxt[this.player.repeatMode] }}</p>
        </div>
        <div class="content">
          <div
            v-show="buttonType === 'music'"
            class="cell f fc fbt"
            v-for="(item, index) in playMusicList"
            :key="'music' + item.id"
            v-throttle:[{item,index}]="musicChangePlay"
          >
            <p :class="['name', item.id == currentMusicId ? 'active' : '']">
              {{ item.name }}
              <van-icon
                v-if="item.id == currentMusicId && isPlaying"
                name="https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif"
                color="rgba(255, 255, 255, 0.85)"
                size="14"
              />
            </p>
          </div>
          <div
            v-show="buttonType === 'classes'"
            class="cell"
            v-for="item in lessonList"
            :key="'lesson' + item.id"
            v-throttle:[{item}]="lessonStartPlay"
          >
            <p :class="['name', item.id == currentLessonId ? 'active' : '']">
              {{ item.name }}
              <van-icon
                v-if="item.id == currentLessonId && isPlaying"
                name="https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif"
                color="rgba(255, 255, 255, 0.85)"
                size="14"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLesson, getMusic } from '@/api/woodfish';
export default {
  props: {},
  data() {
    return {
      buttonList: [
        { value: 'music', label: '音乐' },
        { value: 'classes', label: '功课' },
      ],
      buttonType: 'music',
      playingModeTxt: ['列表循环', '随机播放', '单曲循环'],
      playingModeImg: [
        'https://img-fe.tengzhihh.com/image/f4f5480903a05b-50x50.png',
        'https://img-fe.tengzhihh.com/image/88c9ad27afd6db-50x50.png',
        'https://img-fe.tengzhihh.com/image/34da1bf5732349-50x50.png',
      ],
    };
  },

  mounted() {
    this.changeType(this.buttonType);
  },
  computed: {
    ...mapState({
      player: state => state.player,
      musicList: state => state.player.musicList,
      lessonList: state => state.player.lessonList,
      audioList: state => state.player.audioList,
    }),
    serial_id() {
      return this.$route.query.serial_id;
    },
    isPlaying() {
      return this.player.isPlaying;
    },
    isStarted() {
      return this.player.isStarted;
    },
    classesId() {
      return this.player.classesId;
    },
    musicId() {
      return this.player.musicId;
    },
    playerType() {
      return this.player.playerType;
    },
    playMusicList() {
      let list = this.musicList;
      if (!this.isPlaying && !this.isStarted && this.audioList.length) {
        list = this.musicList.filter(x => this.audioList.includes(String(x.id)));
      } else {
        if (this.playerType === 'music') {
          list = this.musicList.filter(x => this.player.playlistIdList.includes(String(x.id)));
        }
      }
      return list;
    },
    isPlayStarted() {
      return this.isPlaying && this.isStarted;
    },
    currentPlay() {
      let data = { name: '', duration: 0, id: '' };
      if (!this.isPlaying && !this.isStarted) {
        this.playMusicList.length && (data = this.playMusicList[0]);
      }
      if (this.isPlaying || this.isStarted) {
        if (this.playerType === 'music') {
          data = this.playMusicList.find(x => x.id == this.musicId);
        } else if (this.playerType === 'classes') {
          data = this.lessonList.find(x => x.id == this.classesId);
        }
      }
      return data;
    },
    currentMusicId() {
      return this.isStarted && this.playerType === 'music' ? this.currentPlay.id : 0;
    },
    currentLessonId() {
      return this.isStarted && this.playerType === 'classes' ? this.currentPlay.id : 0;
    },
  },
  methods: {
    changePlayMode() {
      if (this.playerType !== 'music') return;
      this.$emit('changePlayMode');
    },
    musicChangePlay({ item, index }) {
      this.$emit('changePlay', { index, playerType: 'music', ...item });
    },
    lessonStartPlay({ item }) {
      this.$emit('lessonStartPlay', { ...item, playerType: 'classes' });
    },
    changeType(val) {
      this.buttonType = val;
      if (val === 'music') {
        this.getMusic();
      } else {
        this.getLesson();
      }
    },
    async getMusic() {
      const res = await getMusic({
        serial_id: this.serial_id,
        from: 'user',
      });
      this.$store.commit('player/setMusicList', res.data.list);
    },
    async getLesson() {
      const res = await getLesson({
        serial_id: this.serial_id,
        size: 100,
      });
      this.$store.commit('player/setLessonList', res.data.list);
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="less" scoped>
.musicPage {
  background: url('https://img-fe.tengzhihh.com/image/ef8796f34f539f-375x812.png') no-repeat center;
  background-size: cover;
  min-height: calc(var(--vh) * 100);
  position: absolute;
  top: 0;
  .goback {
    position: absolute;
    top: 17px;
    left: 23px;
    color: #fff;
  }
  .type {
    padding: 64px 15px 30px;
    .button {
      color: #fff;
      width: 164px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      margin-bottom: 28px;
    }
    .btnActive {
      background: #f87a1b;
    }
    .list {
      width: 345px;
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 15px;
      padding: 16px 0;
      .head {
        padding: 0 16px;
        margin-bottom: 16px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        p {
          font-size: 14px;
          height: 14px;
          line-height: 14px;
          color: rgba(255, 255, 255, 0.85);
        }
      }
      .content {
        height: 420px;
        padding: 0 16px;
        overflow-y: auto;
        .cell {
          height: 44px;
          .name {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.85);
            line-height: 44px;
            letter-spacing: 3px;
          }
          .active {
            color: #f87a1b;
          }
        }
      }
    }
  }
}
</style>
