import { mapState } from 'vuex';
export const commonInfo = {
  data() {
    return {
      routeQuery: {},
    };
  },
  computed: {
    ...mapState({
      commonConfig: state => state.commonConfig,
    }),
  },
  created() {
    this.routeQuery = this.$route.query;
  },
};
