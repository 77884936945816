<template>
  <div class="gift-fude">
    <div class="container">
      <img
        class="gift-fude-img"
        src="https://fe-img.tengzhihh.com/image/d457944a873cdc01f9f5d0ebcd5fdc90-690x690.png"
        alt=""
      />
      <div class="tips">感谢您一直以来的支持<br />获得功德</div>
      <div class="gongde-num">
        {{ num }}
      </div>
      <div class="btn" @click="handleGoRankList">查看我的功德排名</div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { mapState } from 'vuex';

import apiPath from '@/utils/apiPath';

export default {
  data() {
    return {};
  },
  mounted() {
    this.onGetGift();
  },
  computed: {
    ...mapState({
      num: state => state.fudeGift.fude,
    }),
  },
  beforeDestroy() {
    // 退出页面再次更新礼包数据
    this.$utils.fetchAndUpdateFudeGift();
  },
  methods: {
    handleGoRankList() {
      const serial_id = this.$route.query.serial_id || this.$store.state.userMachinesList[0].serial_id;
      this.$router.replace(`/fude/rankList?serial_id=${serial_id}`);
    },
    onGetGift() {
      Toast({
        duration: 10000,
        message: '获取中...',
        forbidClick: true,
      });
      this.$http.post(apiPath.getFudeGift).then(res => {
        Toast({
          message: '获取成功',
          forbidClick: false,
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.gift-fude {
  display: flex;
  flex-direction: column;
  height: 100vh;
  color: #000000d9;
  line-height: 21px;
  background-color: #ded8d8ff;
  .van-nav-bar {
    flex-shrink: 0;
    width: 100%;
    background-color: transparent;
    .van-icon {
      color: #000000d9;
    }
  }

  .container {
    flex-grow: 1;
    overflow: auto;
    text-align: center;

    .gift-fude-img {
      margin-top: 20px;
      width: 100%;
      max-width: 345px;
      max-height: 345px;
    }

    .tips {
      margin-top: 16px;
      color: #7b5439ff;
      font-size: 14px;
    }
    .gongde-num {
      margin-top: 22px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 500;
      font-size: 28px;
      color: #f87a1b;
    }

    .btn {
      margin: 140px auto 10px;
      width: 299px;
      height: 44px;
      line-height: 44px;
      background: #ff8e38;
      border-radius: 10px 10px 10px 10px;

      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      font-style: normal;
      text-transform: none;
    }
  }
}
</style>
