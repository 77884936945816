<template>
  <div class="index-box">
    <!-- <Swiper :dataList="bannerList" /> -->
    <div class="banner-box" @click="jump">
      <img src="https://img-fe.tengzhihh.com/image/5ce2ad28b9ee7c-624x352.png" class="banner" />
      <img src="https://img-fe.tengzhihh.com/image/0f8cccab051a34-624x156.png" class="bg" />
      <div class="name">广宁-紫竹观</div>
    </div>

    <div class="news-box">
      <div class="news-title f fc">
        <div class="point"></div>
        <div>最新活动</div>
      </div>
      <div class="news-list">
        <div class="news-item f fc" v-for="(item, index) in list" :key="index" @click="view(item.link)">
          <div class="left">
            <img src="https://img-fe.tengzhihh.com/image/e9faf356cd85dd-92x34.png" class="sub" />
            <img :src="item.cover" class="cover" />
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from './comp/Swiper.vue';
import list from './list';
export default {
  components: {
    Swiper,
  },
  data() {
    return {
      bannerList: [
        {
          img: 'https://img-fe.tengzhihh.com/image/5ce2ad28b9ee7c-624x352.png',
          cb: () => {
            this.$router.push('/news/subIndex');
          },
        },
      ],
      list,
    };
  },
  methods: {
    view(url) {
      location.href = url;
    },
    jump() {
      this.$router.push('/news/subIndex');
    },
  },
};
</script>

<style lang="less" scoped>
.index-box {
  width: 375px;
  min-height: calc(var(--vh) * 100 - 50px);
  background-color: #f5f5f5;
  overflow: hidden;
  .banner-box {
    position: relative;
    width: 345px;
    height: 176px;
    margin: 0 auto;
    .name {
      position: absolute;
      bottom: 0;
      width: 345px;
      text-align: center;
      padding-bottom: 16px;
      color: rgba(255, 255, 255, 0.85);
      font-size: 18px;
    }
    .bg {
      position: absolute;
      bottom: 0;
      width: 345px;
    }
    .banner {
      width: 345px;
      height: 176px;
      border-radius: 10px;
      margin: 0 auto;
      display: block;
    }
  }

  .news-box {
    .news-title {
      margin: 16px;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      .point {
        width: 5px;
        height: 16px;
        margin-right: 12px;
        background: #f87a1b;
        border-radius: 3px 3px 3px 3px;
      }
    }
    .news-list {
      max-height: 60vh;
      overflow-y: auto;
      .news-item {
        width: 345px;
        height: 100px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        margin: 16px auto;
        padding: 0 16px;
        .left {
          margin-right: 16px;
          position: relative;
          .sub {
            position: absolute;
            left: 0;
            top: 0;
            width: 46px;
            height: 17px;
          }
          .cover {
            width: 121px;
            height: 68px;
            border-radius: 8px 8px 8px 8px;
          }
        }
        .right {
          .title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 700;
          }
        }
      }
      .news-item:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
