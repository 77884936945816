<template>
  <div class="edit-page-box">
    <img src="https://img-fe.tengzhihh.com/image/4e0e73a30570bb-750x1496.png" class="bg" />
    <div class="page-title">选择功课内容</div>

    <div class="list-area f fc fbt flex-wrap">
      <div class="list-item f fv fvc" @click="chooseItem(item)" v-for="(item, index) in musicList" :key="item.id">
        <div class="img-box">
          <img
            :src="
              indexMode === 'western'
                ? 'https://img-fe.tengzhihh.com/image/960f9c5a350e7b-210x210.png'
                : 'https://img-fe.tengzhihh.com/image/aeb0168faca175-210x210.png'
            "
            class="cover"
          />

          <img
            src="https://img-fe.tengzhihh.com/image/5002d361cc9c93-94x34.png"
            class="select-icon"
            v-if="hasSelected(item.id)"
          />

          <img src="https://img-fe.tengzhihh.com/image/491eb945bd3076-94x34.png" class="select-icon" v-else />
        </div>

        <div class="clamp1 name">{{ item.name }}</div>
      </div>

      <i v-for="item in 3 - (musicList.length % 3)" class="i-tag"></i>
    </div>
    <div class="f fc frd">
      <div class="cancel-btn" @click="cancel">取消</div>

      <div class="confirm-btn" @click="confirm">确定</div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';

export default {
  components: {},
  props: {
    serialId: {
      type: String,
      default: '',
    },
    indexMode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedArr: [],
      musicList: [],
    };
  },
  computed: {},

  mounted() {
    this.getList();
  },

  methods: {
    setSelectedArr(list) {
      this.selectedArr = list;
    },
    hasSelected(id) {
      return this.selectedArr.map((i) => i.id).includes(id);
    },
    getList() {
      this.$http.get(`${apiPath.musicList}?serial_id=${this.serialId}&from=user`).then((res) => {
        this.musicList = res.data.list;
      });
    },
    chooseItem(item) {
      if (this.selectedArr.length >= 6) {
        return this.$toast('最多只能选六个');
      }
      const index = this.selectedArr.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        this.selectedArr.splice(index, 1);
      } else {
        this.selectedArr.push(item);
      }
    },
    cancel() {
      this.$emit('setValue', 'pageStep', 'cus_edit');
    },
    confirm() {
      if (this.selectedArr.length === 0) {
        return this.$toast('请先选择功课内容');
      }
      this.$emit('setValue', 'pageStep', 'cus_edit');
      this.$emit('confirmSelectedList', this.selectedArr);
    },
  },
};
</script>

<style lang="less" scoped>
.edit-page-box {
  top: 0;
  left: 0;
  padding: 20px 14px;
  height: calc(var(--vh) * 100);
  box-sizing: border-box;
  width: 375px;
  .van-cell {
    padding: 0;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }

  .page-title {
    color: #7b5439;
    font-size: 16px;
    font-weight: bold;
  }

  .cancel-btn {
    width: 160px;
    height: 40px;
    background: #f6f6f6;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 10px;
  }

  .confirm-btn {
    width: 160px;
    height: 40px;
    background: #f87a1b;
    border-radius: 10px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #ffeac9;
  }

  .list-area {
    height: calc(var(--vh) * 100 - 120px);
    overflow: auto;
    margin: 13px 0;
    .list-item {
      width: 105px;
      overflow: hidden;
      position: relative;
      .name {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        text-align: center;
        margin: 10px 0;
      }
      .img-box {
        width: 105px;
        height: 105px;
        .cover {
          width: 105px;
          height: 105px;
        }
      }

      .select-icon {
        width: 47px;
        height: 17px;
        position: absolute;
        top: 0;
        right: 5px;
      }
    }
    .i-tag {
      width: 105px;
      height: 105px;
    }
  }
}
</style>
