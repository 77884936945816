var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-box" },
    [
      _c("div", { staticClass: "avatar-box f fv fvc" }, [
        !_vm.avatar
          ? _c(
              "div",
              {
                staticClass: "avatar-empty f fv fvc fc border click-hover",
                on: {
                  click: function ($event) {
                    return _vm.chooseImg(1)
                  },
                },
              },
              [_c("van-icon", { attrs: { name: "plus", size: "24px" } })],
              1
            )
          : _c("img", {
              staticClass: "avatar-img",
              attrs: { src: _vm.avatar },
              on: {
                click: function ($event) {
                  return _vm.chooseImg(1)
                },
              },
            }),
        _c("div", { staticClass: "tip" }, [
          _vm._v(_vm._s(_vm.pageType == "add" ? "添加" : "修改") + "逝者头像"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "input-box f fv" },
        [
          _c("van-field", {
            staticClass: "input-item",
            attrs: {
              center: "",
              clearable: "",
              placeholder: "输入逝者的名字",
              maxlength: "10",
              border: false,
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
          _c("van-field", {
            staticClass: "input-item",
            attrs: {
              readonly: "",
              center: "",
              value: _vm.birthDay,
              placeholder: "选择逝者的出生时间",
              border: false,
            },
            on: {
              click: function ($event) {
                return _vm.selectDate(1)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c("p", { staticClass: "input-tip" }, [_vm._v("非必选")]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("van-field", {
            staticClass: "input-item",
            attrs: {
              readonly: "",
              center: "",
              value: _vm.deadDay,
              placeholder: "选择逝者的辞世时间",
              border: false,
            },
            on: {
              click: function ($event) {
                return _vm.selectDate(2)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c("p", { staticClass: "input-tip" }, [_vm._v("非必选")]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "image-tip" }, [_vm._v("添加逝者音容")]),
      _c(
        "div",
        { staticClass: "images-box f fc flex-wrap" },
        [
          _vm._l(_vm.images, function (item, index) {
            return _c("div", { key: index, staticClass: "img-box" }, [
              _c("img", {
                attrs: { src: item.url },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.preview(item)
                  },
                },
              }),
              _c("img", {
                staticClass: "del-icon",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/526d3d05af8245-50x50.png",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.delImg(item, index)
                  },
                },
              }),
            ])
          }),
          _vm.images.length < 9
            ? _c(
                "div",
                {
                  staticClass: "img-box f fv fvc fc border click-hover",
                  on: {
                    click: function ($event) {
                      return _vm.chooseImg(2)
                    },
                  },
                },
                [_c("van-icon", { attrs: { name: "plus", size: "24px" } })],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "f fv fvc" },
        [
          _c(
            "van-button",
            {
              staticClass: "save-btn",
              attrs: { loading: _vm.loading },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
          _vm.from === "init_select"
            ? _c(
                "div",
                { staticClass: "jump-btn", on: { click: _vm.goHome } },
                [_vm._v("先不添加，回到首页")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("date-picker", {
        attrs: { show: _vm.datePickerFlag, currentDate: _vm.pickDate },
        on: { onCancel: _vm.onCancel, onConfirm: _vm.onConfirm },
      }),
      _c("img-picker", {
        ref: "comImgPicker",
        attrs: {
          from: _vm.currentImgPickerType === 1 ? "2" : "3",
          aspectRatio: _vm.aspectRatio,
        },
        on: { emitImgUrl: _vm.emitImgUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }