var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c(
        "div",
        { staticClass: "list-box" },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              on: {
                touchstart: function ($event) {
                  return _vm.touchStart(item, index)
                },
                touchend: _vm.touchend,
              },
            },
            [
              _c(
                "van-swipe-cell",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "right",
                        fn: function () {
                          return [
                            _c("van-button", {
                              staticStyle: { height: "100%" },
                              attrs: {
                                square: "",
                                color: "#999",
                                text: "编辑",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.edit(item)
                                },
                              },
                            }),
                            _c("van-button", {
                              staticStyle: { height: "100%" },
                              attrs: {
                                square: "",
                                type: "danger",
                                text: "删除",
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.openDialog(item, index)
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("van-cell", {
                    attrs: {
                      title: item.time,
                      label: _vm.format(item.cycle),
                      is_link: "",
                      center: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "right-icon",
                          fn: function () {
                            return [
                              _c("van-switch", {
                                attrs: {
                                  "active-color": "#f87a1b",
                                  "inactive-color": "#ffeac9",
                                  size: "20px",
                                  value: item.status === "open",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.timingChange(item)
                                  },
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm.list.length === 0
        ? _c("div", { staticClass: "empty-box f fc fvc" }, [_vm._v("暂无定时")])
        : _vm._e(),
      _c(
        "van-button",
        {
          staticClass: "add-btn fixed",
          attrs: { type: "primary" },
          on: { click: _vm.add },
        },
        [_vm._v("添加定时")]
      ),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c("div", { staticClass: "f fv fc dialog-box" }, [
            _c("p", { staticClass: "title" }, [_vm._v("确认删除该定时吗")]),
            _c("div", { staticClass: "f fc frd btn-box" }, [
              _c(
                "div",
                { staticClass: "btn cancel", on: { click: _vm.closeDialog } },
                [_vm._v("取消")]
              ),
              _c("div", { staticClass: "btn", on: { click: _vm.del } }, [
                _vm._v("确定"),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }