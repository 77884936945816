<template>
  <img
    v-if="type === 'home'"
    src="https://img-fe.tengzhihh.com/image/c6c2d159d45d41-98x98.png"
    class="home"
    :style="{ left: homeLeft, top: homeTop }"
    @click="goBack"
  />
  <div v-else-if="type === 'str'" @click="goBack" class="str f fc">
    <img src="https://img-fe.tengzhihh.com/image/108b40b5aba11a-32x32.png" class="arrow" />
    {{ backStr }}
  </div>
  <img v-else :src="backIcon" class="go-back-icon" @click="goBack" />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    backFunction: {
      type: [Function, undefined],
      default: undefined,
    },
    backStr: {
      type: String,
      default: '返回',
    },
    backIcon: {
      type: String,
      default: 'https://img-fe.tengzhihh.com/image/fe56782d6c86d1-56x56.png',
    },
    homeLeft: {
      type: String,
      default: '15px',
    },
    homeTop: {
      type: String,
      default: '15px',
    },
  },
  methods: {
    goBack() {
      if (typeof this.backFunction === 'function') {
        this.backFunction();
      } else {
        this.$router.go(-1);
      }
      this.$emit('goBack');
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 45px;
  height: 45px;
  z-index: 1000;
  position: absolute;
}
.str {
  width: 80px;
  font-size: 14px;
  color: #9e511c;
  .arrow {
    width: 16px;
  }
}
.go-back-icon {
  width: 28px;
  height: 28px;
  z-index: 1000;
  position: absolute;
  left: 20px;
  top: 20px;
}
</style>
