<template>
  <div>
    <AddChoose v-if="currentStep === 0" :serialId="serialId" :subVolume="subVolume" @setValue="setValue" />
    <VideoPreview v-else-if="currentStep === 1" :serialId="serialId" :mottoType="mottoType" @setValue="setValue" />
    <EditDetail
      v-else-if="currentStep == 2"
      :serialId="serialId"
      :mottoType="mottoType"
      :fileName="fileName"
      :duration="duration"
      @setValue="setValue"
    />

    <van-popup :value="confirmFlag">
      <div class="confirm-box f fc fv">
        <p class="tip">返回将不保留填写好的信息内容</p>
        <div class="btn-box f fc fbt">
          <van-button class="btn" @click="confirmBack">返回</van-button>
          <van-button class="btn cancel" @click="confirmFlag = false">取消</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import AddChoose from './comp/AddChoose.vue';
import EditDetail from './comp/EditDetail.vue';
import VideoPreview from './comp/VideoPreview.vue';
import apiPath from '@/utils/apiPath';

export default {
  components: {
    AddChoose,
    EditDetail,
    VideoPreview,
  },
  data() {
    return {
      currentStep: 0,
      serialId: '',
      subVolume: -1,
      confirmFlag: false,
      activeClose: false,
      fileName: '',
      duration: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.activeClose) {
      next();
    } else {
      next(false);
      this.confirmFlag = true;
    }
  },
  created() {
    this.serialId = this.$route?.query?.serial_id;
  },
  mounted() {
    this.getMachineVolume();
  },
  methods: {
    setValue(key, value) {
      this[key] = value;
    },
    confirmBack() {
      this.activeClose = true;
      this.$router.replace(`/motto/list?serial_id=${this.serialId}`);
      this.$store.commit('setVideoFile', null);
      this.$store.commit('setAudioFile', null);
    },

    getMachineVolume() {
      this.$http
        .get(apiPath.machineVolume, {
          serial_id: this.serialId,
        })
        .then((res) => {
          let currentVolume = res.data.current_volume;
          let maxVolume = res.data.max_volume;
          this.subVolume = maxVolume - currentVolume >= 0 ? maxVolume - currentVolume : 0;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.confirm-box {
  width: 295px;
  background: #fff;
  border-radius: 5px;
  padding-top: 20px;
  .tip {
    font-size: 14px;
    color: #3c3c3c;
    margin-bottom: 37px;
  }
  .btn-box {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 0 35px;
    .btn {
      width: 100px;
      height: 43px;
      background: #f6f6f6;
      border-radius: 10px;
      font-size: 15px;
      border: none;
      color: rgba(0, 0, 0, 0.65);
    }
    .cancel {
      width: 100px;
      height: 43px;
      background: #f87a1b;
      border-radius: 10px;
      font-size: 15px;
      color: #fff;
    }
  }
}
</style>
