var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageType === "add"
    ? _c(
        "div",
        { staticClass: "f fv fvc page-box" },
        [
          _c("img", {
            staticClass: "bg",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png",
            },
          }),
          _c("GoBack"),
          !_vm.mottoType
            ? _c("div", { staticClass: "btn-area f fc fbt" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn-box f fv fc",
                    on: { click: _vm.newVideo },
                  },
                  [
                    _c("img", {
                      staticClass: "btn-img click-hover",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/c63c7c296a693a-80x80.png",
                      },
                    }),
                    _c("p", [_vm._v("视频家训")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "btn-box f fc fv",
                    on: { click: _vm.newAudio },
                  },
                  [
                    _c("img", {
                      staticClass: "btn-img click-hover",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/56c94cbe48e05b-80x80.png",
                      },
                    }),
                    _c("p", [_vm._v("语音家训")]),
                  ]
                ),
              ])
            : _vm.mottoType === "audio"
            ? _c("div", { staticClass: "audio-box f fc fv" }, [
                _c("div", { staticClass: "main-icon-box" }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/269a8a83cf7990-107x146.png",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.audioDuration === 0,
                        expression: "audioDuration === 0",
                      },
                    ],
                    staticClass: "png",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/ee8761d8ce2bb6-286x286.png",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.audioDuration !== 0,
                        expression: "audioDuration !== 0",
                      },
                    ],
                    staticClass: "gif",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/d8df00ab8d4aa3-500x500.gif",
                    },
                  }),
                ]),
                _c("div", { staticClass: "duration" }, [
                  _vm._v(
                    _vm._s(_vm.$tools.mileSecondToMinute(_vm.audioDuration))
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.audioDuration === 0,
                        expression: "audioDuration === 0",
                      },
                    ],
                    staticClass: "op-btn f fc fv",
                    on: { click: _vm.startRecord },
                  },
                  [
                    _c("img", {
                      staticClass: "op-img",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/d5ad63001541be-168x168.png",
                      },
                    }),
                    _c("p", { staticClass: "tips" }, [_vm._v("单击开始录音")]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.audioDuration !== 0,
                        expression: "audioDuration !== 0",
                      },
                    ],
                    staticClass: "op-btn f fc fv",
                  },
                  [
                    _c("img", {
                      staticClass: "op-img",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/6b4d6510cbf93a-168x168.png",
                      },
                      on: { click: _vm.stopRecord },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _c("input", {
            ref: "file",
            attrs: { type: "file", id: "videoInput", accept: "video/*" },
            on: { change: _vm.fileChanged },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "page-box audio-preview-box f fv fc" },
        [
          _c("img", {
            staticClass: "bg",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png",
            },
          }),
          _c("div", { staticClass: "f fv fc" }, [
            _c(
              "div",
              { staticClass: "icon-box", on: { click: _vm.playAudio } },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.audioPlaying,
                      expression: "!audioPlaying",
                    },
                  ],
                  staticClass: "icon-gif",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/4a8cb9c7d0e114-58x47.png",
                  },
                }),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.audioPlaying,
                      expression: "audioPlaying",
                    },
                  ],
                  staticClass: "icon-gif",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/537b413f01a8e4-59x50.gif",
                  },
                }),
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/11259e0482c1b3-336x126.png",
                  },
                }),
              ]
            ),
            _c("p", { staticClass: "duration" }, [
              _vm._v(_vm._s(_vm.$tools.mileSecondToMinute(_vm.duration))),
            ]),
            _c("p", { staticClass: "finish-tip" }, [_vm._v("录制完成")]),
          ]),
          _c(
            "van-button",
            {
              staticClass: "handle-btn reRecord-btn",
              on: { click: _vm.reRecord },
            },
            [_vm._v(" 重新录制 ")]
          ),
          _c(
            "van-button",
            { staticClass: "handle-btn next-btn", on: { click: _vm.next } },
            [_vm._v(" 下一步 ")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }