<template>
  <div class="deceased-page-box" v-if="!initLoading">
    <BackIcon class="back" @back="backFn" />
    <EnshrineSettingTab :list="tabList" @tabClick="tabClick" />
    <div class="header-btn f fc fbt" v-if="list.length > 0">
      <div class="btn-item" @click="$utils.checkAuth(routeQuery.serial_id, add)">+添加逝者</div>
      <div
        v-if="manageStatus === 0"
        class="btn-item"
        @click="
          $utils.checkAuth(routeQuery.serial_id, () => {
            manageStatus = 1;
          })
        "
      >
        管理信息
      </div>

      <div @click="manageStatus = 0" v-if="manageStatus === 1" class="btn-item">取消管理</div>
    </div>

    <div class="empty-box click-hover" v-if="list.length === 0" @click="add">
      <img src="https://img-fe.tengzhihh.com/image/261d4d86c8ae1f-429x552.png" class="empty-img" />
      <div class="empty-tip">暂无逝者档案</div>
    </div>

    <div class="list-container f fv fvc" v-else>
      <div
        :class="`item-container ${manageStatus === 1 && item.is_ancestral != 1 ? 'editing' : ''}`"
        @click="viewDetail(item)"
        v-for="(item, index) in list"
        :key="item.id"
      >
        <div class="info-box f fc">
          <div class="avatar">
            <div class="photo" v-if="item.decease_show !== 1">
              <img v-lazy="$tools.compressImg(item.box, 117)" />
              <img class="photo-img f" v-lazy="$tools.compressImg(item.avatar, 117)" />
            </div>
            <img v-if="item.decease_show === 1" v-lazy="$tools.compressImg(item.avatar, 117)" />
            <div v-if="item.decease_show === 1" class="deceased-text f fvc">
              <p class="enshrine-name">
                {{ item.name.length > 8 ? item.name.substr(0, 8) : item.name }}
              </p>
            </div>
          </div>
          <div class="right f fv fbt">
            <div class="right-content f fv">
              <div class="name">
                {{ item.is_ancestral === 1 ? '历代元祖宗亲之位' : '姓名：' + item.name }}
              </div>
              <div class="age" v-if="item.die_age">{{ item.die_age }}</div>
              <div class="die_duration">{{ item.die_duration ? `辞世：${item.die_duration}` : '' }}</div>
            </div>

            <div class="btn-box">
              <van-button class="btn current" v-if="index === 0"> 主位供奉 </van-button>
              <van-button class="btn" @click.stop.prevent="priorityWorship(item)" v-else> 设为主位 </van-button>
            </div>
          </div>
        </div>
        <div class="edit-box f fc frd" v-if="manageStatus === 1 && item.is_ancestral !== 1">
          <div v-if="manageStatus === 1" class="edit-btn f fv fc" @click.stop.prevent="updateInfo(item)">修改</div>
          <div v-if="manageStatus === 1" class="edit-btn f fv fc" @click.stop.prevent="showDelDialog(item)">删除</div>
        </div>
      </div>
    </div>
    <Dialog ref="dialog" />

    <van-popup v-model="dialogFlag">
      <div class="f fv fc dialog-box">
        <p class="tip">确认删除该档案信息?</p>
        <div class="f fc frd btn-box">
          <div @click="del" class="confirm-btn">确定</div>
          <div @click="closeDialog" class="cancel-btn">取消</div>
        </div>
      </div>
    </van-popup>
  </div>
  <van-overlay :show="true" v-else>
    <van-loading size="24px" color="#464645" vertical>加载中...</van-loading>
  </van-overlay>
</template>

<script>
import EnshrineSettingTab from '@/pages/god/comp/EnshrineSettingTab';
import Dialog from '@/components/Dialog/Dialog.vue';
import BackIcon from '@/components/BackIcon';
import apiPath from '@/utils/apiPath';
import { enshrineSettingTab } from '@/enum/enshrine';
import { getUserDeceasedList } from '@/api/deceased';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  components: {
    EnshrineSettingTab,
    BackIcon,
    Dialog,
  },
  data() {
    return {
      initLoading: true,
      list: [],
      manageStatus: 0,
      dialogFlag: false,
      currentId: 0,
    };
  },
  computed: {
    tabList: function () {
      return enshrineSettingTab.filter(x => x.god_type.includes(+this.routeQuery.god_type));
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    viewDetail(item) {
      if (item.is_ancestral != 1) {
        this.$router.push(`/deceased/detail?serial_id=${this.routeQuery.serial_id}&id=${item.id}`);
      }
    },
    updateInfo(item) {
      this.$router.push(`/deceased/info?page_type=edit&serial_id=${this.routeQuery.serial_id}&id=${item.id}`);
    },
    del() {
      const formData = new FormData();
      formData.append('serial_id', this.routeQuery.serial_id);
      this.$http.formPost(`${apiPath.delDeceased}/${this.currentId}`, formData).then(() => {
        this.$toast('删除成功');
        this.closeDialog();
        this.getList();
      });
    },
    showDelDialog(item) {
      this.currentId = item.id;
      this.dialogFlag = true;
    },
    closeDialog() {
      this.dialogFlag = false;
    },

    add() {
      this.$router.push(`/deceased/info?page_type=add&serial_id=${this.routeQuery.serial_id}`);
    },
    async getList() {
      const { data } = await getUserDeceasedList({
        god_type: 3,
        serial_id: this.routeQuery.serial_id,
        ver: '1.1',
      });
      this.list = data.list;
      this.initLoading = false;
    },
    priorityWorship(item) {
      const formData = new FormData();
      formData.append('serial_id', this.routeQuery.serial_id);
      this.$utils.checkAuth(this.routeQuery.serial_id, () => {
        this.$refs.dialog.initDialog({
          title: '设为主位',
          content: '是否设置为主位，确定后将作为供奉顺序的第一位，优先展示在首页神龛（神柜）里。',
          confirmText: '确认',
          cancelText: '取消',
          confirmCallback: () => {
            this.$http.formPost(`${apiPath.priorityWorship}/${item.id}`, formData).then(() => {
              this.$toast('设置成功');
              this.getList();
              this.$refs.dialog.closeDialog();
            });
          },
          cancelCallback: () => {
            this.$refs.dialog.closeDialog();
          },
        });
      });
    },
    backFn() {
      this.$router.push({
        name: 'enshrineList',
        query: this.$route.query,
      });
    },
    tabClick({ name }) {
      this.$router.replace({
        name,
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.back {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9999;
}
.empty-box {
  position: absolute;
  width: 214px;
  height: 276px;
  z-index: 1;
  .empty-img {
    position: absolute;
    top: 236px;
    left: 82px;
    width: 214px;
    height: 276px;
    object-fit: fill;
  }
  .empty-tip {
    position: absolute;
    top: 340px;
    left: 150px;
    margin: 20px 0;
    width: 140px;
    height: 201px;
  }
}
.deceased-page-box {
  // background: linear-gradient(180deg, #e0dbda 0%, #d4cece 100%);
  min-height: calc(var(--vh) * 100);
  .list-container {
    z-index: 1;
    position: relative;
    .edit-box {
      z-index: 1;
      box-sizing: border-box;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      .edit-btn {
        width: 49%;
        height: 42px;
        line-height: 42px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
      .edit-btn:last-child {
        border-right: none;
        color: #eb1b1b;
      }
    }
    .item-container {
      width: 345px;
      // background: #c4925a;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin-bottom: 16px;
      box-sizing: border-box;

      .info-box {
        padding: 8px 16px 16px 8px;
      }
      .avatar {
        position: relative;
        width: 100px;
        height: 147px;
        border-radius: 10px;
        margin-right: 16px;
        overflow: hidden;

        img {
          width: 100px;
          height: 147px;
          object-fit: contain;
        }
        .photo {
          position: relative;
          .photo-img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 85px;
            height: 100px;
          }
        }
        .deceased-text {
          position: absolute;
          width: 6px;
          height: 70px;
          top: 40px;
          left: 50%;
          transform: translateX(-2px);
          .enshrine-name {
            position: relative;
            max-height: 100%;
            overflow: hidden;
            font-size: 0.15rem;
            color: #faf6a8;
            word-break: break-all;
            letter-spacing: 0;
          }
        }
      }
      .bg-color {
        background: #fff;
      }
      .right {
        flex: 1;
        .right-content {
          min-height: 107px;
        }
        .name {
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 4px;
        }
        .age,
        .die_duration {
          font-size: 14px;
          color: #7b5439;
          margin-bottom: 8px;
        }
        .btn-box {
          width: 100%;
          .btn {
            width: 100%;
            height: 40px;
            border-radius: 10px;
            line-height: 40px;
            text-align: center;
            color: rgba(255, 255, 255, 0.9);
            font-size: 16px;
            background: #f87a1b;
            border: none;
          }
          .current {
            background: #fff;
            border: 1px solid #f87a1b;
            color: #f87a1b;
          }
        }
      }
    }
  }
  .header-btn {
    z-index: 1;
    position: relative;
    padding: 13px;
    font-size: 14px;
    justify-content: flex-end;

    .btn-item {
      width: fit-content;
      color: #464645;
    }
  }
}
.dialog-box {
  width: 295px;
  background: #ffffff;
  border-radius: 15px 15px 15px 15px;
  padding: 38px 0 20px;
  box-sizing: border-box;
  .tip {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 30px;
  }

  .cancel-btn {
    width: 100px;
    height: 40px;
    background: #f87a1b;
    border-radius: 10px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #fff;
  }
  .cancel-text {
    color: #f87a1b;
  }
  .confirm-btn {
    width: 100px;
    height: 40px;
    background: #f7f4f8;
    border-radius: 10px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #5b585c;
  }

  .btn-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
  }
}
.van-overlay {
  background-color: #e6e3de;
}
</style>
<style lang="less">
.deceased-page-box {
  .van-popup {
    border-radius: 15px;
  }
}
</style>
