<template>
  <div v-if="showPage">
    <div>
      <div v-show="!showMusicPage" class="page">
        <div v-if="is_quotation && quotation" class="pac quotation">{{ quotation }}</div>
        <div class="bg">
          <img
            :src="
              (woodfish_detail_v2.wooden_fish_list &&
                woodfish_detail_v2.wooden_fish_list.find(x => x.fish_type === fish_type).other_bg_img) ||
              'https://img-fe.tengzhihh.com/image/08493aff63695a-375x812.png'
            "
            alt=""
            srcset=""
          />
          <img
            :src="
              (woodfish_detail_v2.wooden_fish_list &&
                woodfish_detail_v2.wooden_fish_list.find(x => x.fish_type === fish_type).bg_img) ||
              'https://img-fe.tengzhihh.com/image/08493aff63695a-375x812.png'
            "
            alt=""
            srcset=""
          />
        </div>
        <van-icon name="arrow-left" size="20" class="goback" @click="backClick" />
        <div class="tabList f">
          <div
            v-for="{ value, label } in tabList"
            :key="value"
            :class="['tab', fish_type === value ? 'tab-active' : '']"
            @click="tabClick(value)"
          >
            {{ label }}
          </div>
        </div>
        <van-icon name="setting-o" size="20" class="setting" @click="toSet" />
        <div class="sound-switch">
          <van-icon
            :name="
              muted
                ? 'https://img-fe.tengzhihh.com/image/bb3bb05546175a3c88a7698342df859c-48x48.png'
                : 'https://img-fe.tengzhihh.com/image/58fc740a73ec1863e6ae5e7ba9db8e3d-48x48.png'
            "
            size="24"
            class="sound-icon"
            @click="checkMuted"
          />
          <p>声音</p>
        </div>
        <div class="pac billboard">
          <p class="count">今日：{{ woodfish_detail_v2.today_num || 0 }}</p>
          <p class="count">
            本次：<span class="light-txt">{{ point }}</span>
          </p>
        </div>
        <div id="svga-start" class="svga-start"></div>
        <div :id="`svga-${fish_type}`" class="svga"></div>
        <div class="content pac" v-show="fish_type === 1" @click="knockFn">
          <p :class="['pac', 'resultTxt', showTxtAnimate ? 'resultTxtAnimate' : '']">
            {{ woodfish_detail_v2.title_type === 1 ? woodfish_detail_v2.fish_title || '福报+1' : showTxtAnimate }}
          </p>
          <img
            :class="isKnock ? 'bigMuyu' : ''"
            src="https://img-fe.tengzhihh.com/image/30ac3ef695e74b-349x373.png"
            alt=""
            srcset=""
            class="woodfish"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/cf27df0550786a-166x108.png"
            alt=""
            srcset=""
            :class="isKnock ? 'knock' : 'stick'"
          />
        </div>
        <div class="chapletBox" v-show="fish_type === 2">
          <div class="swip-list" id="woodfishSwip">
            <img
              v-for="item in swipNianList"
              :key="item.key"
              @touchstart.stop.prevent="swipStartFn"
              @touchend.stop.prevent="swipEndFn"
              :class="['swip-nian', item.top >= -48 && item.top < 9 * 48 && 'swip-nian-tran']"
              :style="{ top: px2rem(item.top) }"
              src="https://img-fe.tengzhihh.com/image/119ea424ef8d72-100x100.png"
              alt=""
              srcset=""
            />
          </div>
          <p :class="['chapletTxt', isKnock ? 'chapletTxtAnimate' : '']">
            {{ woodfish_detail_v2.title_type === 1 ? woodfish_detail_v2.fish_title || '福报+1' : showTxtAnimate }}
          </p>
        </div>
        <div class="songbo" v-show="fish_type === 3" @click="playFn">
          <img
            src="https://img-fe.tengzhihh.com/image/0b5bbe5a70ab3b9dccef1e8c6bfe7ec2-698x746.png"
            alt=""
            srcset=""
            :class="['songbo-img']"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/0fff7143a78e56081645b3dc85cb272a-192x272.png"
            alt=""
            srcset=""
            :class="isKnock ? 'songbo-ban-knock' : 'songbo-ban-stick'"
          />
          <p :class="['pac', 'resultTxt', showTxtAnimate ? 'resultTxtAnimate' : '']">
            {{
              woodfish_detail_v2.title_type === 1
                ? woodfish_detail_v2.fish_title || '福报+1'
                : blessing_list[Math.floor(Math.random() * blessing_list.length)]
            }}
          </p>
        </div>
        <button class="pac submit" @click="backClick">完成修行</button>

        <van-popup round v-model="isShowPop">
          <div class="poplayout">
            <div class="txt-c cancelTitle">提示</div>
            <div class="cancelSub">
              {{ popText }}
            </div>
            <div class="cancelTips">说明：每敲木鱼/念珠100下，功德值+1，每日上限功德值200</div>
            <div class="popHandle f fvc">
              <button class="popbtn" @click="isShowPop = false">继续修行</button>
              <button class="popbtn" @click="cancelSubmit">退出页面</button>
            </div>
          </div>
        </van-popup>
        <div class="playbar f fc fbt">
          <div class="f fc">
            <img class="mask" src="https://img-fe.tengzhihh.com/image/61e05bc4f2709b-35x35.png" alt="" srcset="" />
            <p class="txt">{{ currentPlay.name }}</p>
          </div>
          <div class="f">
            <div class="playOrPause f fc" @click="playControl">
              <van-circle
                :value="currentPosition"
                :rate="currentPlay.duration"
                class="panel"
                layer-color="rgba(0,0,0,0.15)"
                :color="'#F87A1B'"
                :stroke-width="80"
              />
              <img
                src="https://img-fe.tengzhihh.com/image/dfd676ac3fb6e0-24x24.png"
                v-show="!isPlayStarted"
                class="circle"
              />
              <img
                src="https://img-fe.tengzhihh.com/image/069e6de2f802e0-24x24.png"
                v-show="isPlayStarted"
                class="circle"
              />
            </div>
            <img
              class="panel ml30"
              src="https://img-fe.tengzhihh.com/image/124ecf7b97df9c-56x56.png"
              alt=""
              srcset=""
              @click="showMusicPage = true"
            />
          </div>
        </div>
      </div>
      <audio id="muyuAudio" ref="muyuAudio">
        <source src="https://img-fe.tengzhihh.com/audio/ded9f954cac7be513d7ef4e2ad1fbf50.mp3" />
      </audio>
      <audio id="songboAudio" ref="songboAudio">
        <source src="https://img-fe.tengzhihh.com/file/31800a0a-531a-42d2-9957-d43e0f00a6b6.wav" />
      </audio>
      <audio
        id="nianzhuAudio"
        style="display: none"
        src="https://img-fe.tengzhihh.com/audio/6363d966b2cd1728687252a3cb9dd817.mp3"
        controls="controls"
      ></audio>
      <woodfishMusic
        v-if="showMusicPage"
        @back="showMusicPage = false"
        @changePlay="changePlay"
        @lessonStartPlay="lessonStartPlay"
        @changePlayMode="changePlayMode"
      />
    </div>
    <Dialog ref="dialog" />
  </div>
  <div v-else class="loading-box f fc fvc">
    <van-loading type="spinner" />
  </div>
</template>

<script>
const vibrate = () => {
  const navigator = window.navigator;
  if (!('vibrate' in navigator)) return;
  navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate;
  if (!navigator.vibrate) return;
  navigator.vibrate(300);
};
const woodenFish = {
  className() {},
  size() {},
  createText() {
    const p = document.createElement('p');
    p.innerText = '功德+1';
    dom.text.appendChild(p);
  },
};
const blessing_list = [
  '财源滚滚',
  '一生平安',
  '升官发财',
  '儿孙满堂',
  '家庭美满',
  '六时吉祥',
  '八方来财',
  '一帆风顺',
  '五福临门',
  '工作顺利',
  '学习进步',
  '六六大顺',
  '逢凶化吉',
  '福禄寿喜',
  '一夜暴富',
  '荣华富贵',
  '心想事成',
  '步步高升',
  '金榜题名',
  '爱情甜蜜',
  '十全十美',
  '万事如意',
  '吉星高照',
  '长命百岁',
  '招财进宝',
  '大吉大利',
  '金玉满堂',
];
const min_quotation_list = [
  '修行不在外境，而是内心的安静。',
  '学会在喧器中寻得一份宁静，让自己的心灵得以净化。',
  '经过内心的磨砺，才能找到自己的道路。',
  '执念如同心中的石头，放下才能获得自由。',
  '只有放下对结果的执着，才能真正体验到修行的快乐。',
  '只有敢于面对自己的内心，才能真正做到放下。',
  '只有专注于当下，才能真正把握生活的本质。',
  '不要被过去的阴影所束缚，也不要为未来的担忧所困扰。',
  '要学会发现身边的美好，让自己的心灵得到滋养。',
  '只有追求智慧，才能真正理解生活的意义。',
  '要学会深入思考，发现事物的内在规律。',
  '追求智慧需要持续的学习和实践。',
];
import { submitWoodenfish, getWoodenfishV2, getLesson, getMusic } from '@/api/woodfish';
import { numMul, numSub, numAdd } from '@/utils/math';
import { mapState } from 'vuex';
import woodfishMusic from './woodfishMusic.vue';
import Dialog from '@/components/Dialog/Dialog.vue';
import bus from '@/utils/bus';
import sendMsg from '@/webSocket/sendMsg';
import svgaplayerweb from 'svgaplayerweb';
import { getStore, setStore } from '@/utils/localStore';
import { commonOperate } from '@/mixins/commonOperate';
const trackKey = ['', 'H5_enter_muyu_people_all', 'H5_enter_nianzhu_people_all', 'H5_enter_songbo_people_all'];
export default {
  components: { woodfishMusic, Dialog },
  mixins: [commonOperate],
  data() {
    return {
      showPage: false,
      isKnock: false,
      showTxtAnimate: '',
      point: 0,
      fish_type: 1,
      woodfish_detail_v2: {
        wooden_fish_list: [],
      },
      isShowPop: false,
      popText: '您本次敲木鱼0遍，累计功德值0，是否上传当前功德，退出页面？',
      chapletTime: null,
      txtAnimateTime: null,
      showMusicPage: false,
      currentPosition: 0,
      newswipNianList: [new Date().getTime() + Math.random()],
      swipNianList: [
        { top: -48 * 2, key: new Date().getTime() + Math.random() },
        { top: -48, key: new Date().getTime() + Math.random() },
        { top: 0, key: new Date().getTime() + Math.random() },
        { top: 1 * 48, key: new Date().getTime() + Math.random() },
        { top: 2 * 48, key: new Date().getTime() + Math.random() },
        { top: 3 * 48, key: new Date().getTime() + Math.random() },
        { top: 4 * 48, key: new Date().getTime() + Math.random() },
        { top: 5 * 48, key: new Date().getTime() + Math.random() },
        { top: 6 * 48, key: new Date().getTime() + Math.random() },
        { top: 7 * 48, key: new Date().getTime() + Math.random() },
        { top: 8 * 48, key: new Date().getTime() + Math.random() },
        { top: 9 * 48, key: new Date().getTime() + Math.random() },
      ],
      touchStart: 0,
      touchEnd: 0,
      tabList: [
        { value: 1, label: '木鱼' },
        { value: 2, label: '念珠' },
        { value: 3, label: '颂钵' },
      ],
      blessing_list,
      min_quotation_list,
      is_quotation: 0,
      quotation: '',
      quotationTime: null,
      muted: false,
      woodfish_cache: {},
    };
  },
  computed: {
    ...mapState({
      player: state => state.player,
      musicList: state => state.player.musicList,
      lessonList: state => state.player.lessonList,
      audioList: state => state.player.audioList,
    }),
    serial_id() {
      return this.$route.query.serial_id;
    },
    isPlaying() {
      return this.player.isPlaying;
    },
    isStarted() {
      return this.player.isStarted;
    },
    classesId() {
      return this.player.classesId;
    },
    musicId() {
      return this.player.musicId;
    },
    playerType() {
      return this.player.playerType;
    },
    playMusicList() {
      let list = this.musicList;
      if (!this.isPlaying && !this.isStarted && this.audioList.length) {
        list = this.musicList.filter(x => this.audioList.includes(String(x.id)));
      } else {
        if (this.playerType === 'music') {
          list = this.musicList.filter(x => this.player.playlistIdList.includes(String(x.id)));
        }
      }
      return list;
    },
    isPlayStarted() {
      return this.isPlaying && this.isStarted;
    },
    currentPlay() {
      let data = { name: '', duration: 0, id: '' };
      if (!this.isPlaying && !this.isStarted) {
        this.playMusicList.length && (data = this.playMusicList[0]);
      }
      if (this.isPlaying || this.isStarted) {
        if (this.playerType === 'music') {
          data = this.playMusicList.find(x => x.id == this.musicId);
        } else if (this.playerType === 'classes') {
          data = this.lessonList.find(x => x.id == this.classesId);
        }
      }
      return data;
    },
  },
  beforeDestroy() {
    bus.$off('onMsg');
    this.fish_type === 2 &&
      document.getElementById('woodfishSwip')?.removeEventListener('touchmove', this.stopDefault, { passive: false });
  },

  async mounted() {
    // 备用功能
    // setInterval(() => {
    //   this.knockFn();
    // }, 500);
    this.getCache();
    this.getWoodenfishV2();
    await this.getLesson();
    await this.getMusic();
    this.playStart();
  },
  methods: {
    trackFn() {
      const key = trackKey[this.fish_type];
      this.$track(key);
    },
    getCache() {
      const woodfish_cache = getStore({ name: 'woodfish_cache' });
      if (woodfish_cache) {
        this.woodfish_cache = woodfish_cache;
        for (let item in woodfish_cache) {
          this[item] = woodfish_cache[item];
        }
      }
      this.upDateCache('point', 0);
      this.trackFn();
    },
    upDateCache(key, value) {
      this.woodfish_cache[key] = value;
      setStore({
        name: 'woodfish_cache',
        content: this.woodfish_cache,
      });
    },
    async playSvgaHandle(dom, svga, loops, endCb) {
      const player = new svgaplayerweb.Player(dom);
      const parser = new svgaplayerweb.Parser(dom);
      parser.load(svga, videoItem => {
        player.clearsAfterStop = true; // 动画结束时，是否清空画布
        loops && (player.loops = loops);
        player.setVideoItem(videoItem);
        player.startAnimation();
        player.onFinished(() => {
          typeof endCb === 'function' && endCb();
        });
      });
    },
    playSvg() {
      if (this.fish_type === 2) return;
      this.playSvgaHandle(
        `#svga-${this.fish_type}`,
        'https://mmc-forecast.oss-cn-shanghai.aliyuncs.com/file/ab456e65-492b-49b0-bf15-852ffa37908c.svga',
        // 'https://img-fe.tengzhihh.com/file/aa5b7a07-3ecb-4900-8fe5-db25b2bb7cd8.svga',
        1,
        () => {}
      );
    },
    playStart() {
      this.playSvgaHandle(
        `#svga-start`,
        'https://img-fe.tengzhihh.com/file/aa5b7a07-3ecb-4900-8fe5-db25b2bb7cd8.svga',
        0,
        () => {}
      );
    },
    checkMuted() {
      this.muted = !this.muted;
      this.upDateCache('muted', this.muted);
    },
    showQuotation() {
      const quotation = this.woodfish_detail_v2.text.quotation_list.find(
        x => x.num === this.point + (this.woodfish_detail_v2.today_num || 0)
      );
      if (quotation) {
        this.playSvg();
        this.$track('H5_show_jingwen');
        this.$track('H5_show_foguang');
        clearTimeout(this.quotationTime);
        this.quotation = quotation.text;
        this.quotationTime = setTimeout(() => {
          this.quotation = '';
        }, 5000);
      } else {
        if (this.point % 100 === 0) {
          this.playSvg();
          this.$track('H5_show_jingwen');
          this.$track('H5_show_foguang');
          clearTimeout(this.quotationTime);
          this.quotation = min_quotation_list[Math.floor(Math.random() * min_quotation_list.length)];
          this.quotationTime = setTimeout(() => {
            this.quotation = '';
          }, 5000);
        }
      }
    },
    tabClick(value) {
      this.fish_type = value;
      this.upDateCache('fish_type', value);
      this.trackFn();
    },
    stopDefault(e) {
      //阻止默认的处理方式(阻止下拉滑动的效果)
      e.preventDefault();
      return false;
    },
    getSystemInfo() {
      sendMsg('player', {
        operation: 'playerStatus',
      });
      sendMsg('player', {
        operation: 'getAudioList',
        playerType: this.playerType,
      });
    },
    playControl() {
      !this.isPlayStarted && this.$track('H5_muyu_yinyue_click');
      !this.isPlayStarted && this.$track('H5_muyu_foyin_click');
      const operation = this.isPlayStarted ? 'pause' : 'play';
      const playerType = this.playerType;
      if (!this.isStarted) {
        //没有播放中的音乐，默认播放第一个
        sendMsg('player', {
          operation: 'start',
          playerType: 'music',
        });
        return;
      }
      sendMsg('player', {
        operation,
        playerType,
      });
    },
    handleMsg(msg) {
      const action = msg.action;
      const operation = msg.data.operation;
      const map = {
        player: {
          cb: () => {},
          operation: {
            onProgressChanged: () => {
              this.updatePlayerStatus(msg);
            },
            playerStatus: () => {
              this.$store.commit('player/setPlayer', msg.data);
              this.updatePlayerStatus(msg);
            },
            getAudioList: () => {
              this.$store.commit('player/setAudioList', msg.data.playlistIdList);
            },
          },
        },
      };
      if (['onProgressChanged', 'playerStatus', 'getAudioList'].includes(operation)) {
        const operationCb = map[action]?.['operation']?.[operation];
        typeof operationCb === 'function' && operationCb();
      }
    },
    showConfirm(cb) {
      this.$refs.dialog.initDialog({
        title: '提示',
        content: `神台机正在进行功课，是否先退出？`,
        confirmText: '确认',
        cancelText: '取消',
        confirmCallback: async () => {
          this.$refs.dialog.closeDialog();
          await sendMsg('player', {
            operation: 'stop',
            playerType: 'classes',
          });
          setTimeout(() => {
            typeof cb === 'function' && cb();
          }, 500);
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    lessonStartPlay({ id, playerType }) {
      if (this.isStarted && this.playerType === 'classes') {
        if (+this.classesId === +id) {
          this.showConfirm(() => {});
        } else {
          this.showConfirm(() => {
            sendMsg('player', {
              operation: 'start',
              extra: id,
              playerType,
            });
          });
        }
        return;
      }
      sendMsg('player', {
        operation: 'start',
        extra: id,
        playerType,
      });
    },
    changePlay({ index, id, playerType }) {
      if (!this.isStarted) {
        sendMsg('player', {
          operation: 'clickListItem',
          extra: index,
          playerType: 'music',
        });
        return;
      } else if (this.isStarted && this.playerType === 'classes') {
        this.showConfirm(() => {
          sendMsg('player', {
            operation: 'clickListItem',
            extra: index,
            playerType,
          });
        });
        return;
      }
      sendMsg('player', {
        operation: 'changeCurrentPlay',
        extra: id,
        playerType,
      });
    },
    changePlayMode() {
      sendMsg('player', {
        operation: 'changeRepeatMode',
        extra: this.player.repeatMode === 2 ? 0 : this.player.repeatMode + 1,
        playerType: 'music',
      });
    },
    updatePlayerStatus({ data }) {
      if (!data.isStarted && !data.isPlaying) {
        this.currentPosition = 0;
      }
      if (data.isStarted) {
        const { currentPosition, totalDuration } = data;
        this.currentPosition = (currentPosition / totalDuration) * 100;
      }
    },
    async getMusic() {
      const res = await getMusic({
        serial_id: this.serial_id,
        from: 'user',
      });
      this.$store.commit('player/setMusicList', res.data.list);
    },
    async getLesson() {
      const res = await getLesson({
        serial_id: this.serial_id,
        size: 100,
      });
      this.$store.commit('player/setLessonList', res.data.list);
    },
    async getWoodenfishV2() {
      this.showPage = false;
      const { data } = await getWoodenfishV2({
        serial_id: this.$route.query.serial_id,
      });
      this.showPage = true;
      this.woodfish_detail_v2 = data;
      this.is_quotation = data.is_quotation;

      setTimeout(() => {
        //数据更新了视图还没更新
        this.fish_type === 2 &&
          document.getElementById('woodfishSwip')?.addEventListener('touchmove', this.stopDefault, { passive: false });
      }, 0);
    },
    playFn() {
      const audio = document.getElementById('songboAudio');
      audio.currentTime = 1;
      audio.muted = this.muted;
      audio.play();
      this.point++;
      this.isKnock = true;
      this.showTxtAnimate = blessing_list[Math.floor(Math.random() * blessing_list.length)];
      this.chapletTime = setTimeout(() => {
        clearTimeout(this.chapletTime);
        this.isKnock = false;
      }, 300);
      this.txtAnimateTime = setTimeout(() => {
        clearTimeout(this.txtAnimateTime);
        this.showTxtAnimate = '';
      }, 300);
      this.showQuotation();
      this.$track('H5_songbo_click');
    },
    knockFn() {
      // const audio = document.createElement('audio');
      // audio.src = yymuyu;
      // audio.autoplay = 'autoplay';
      // audio.style.display = 'none';
      // document.getElementById('muyuAudio').appendChild(audio);
      // const audio = document.getElementById('muyuAudio');
      // audio.playbackRate = 1.5;
      // audio.autoplay = true;
      // audio.load();
      // this.$refs.muyuAudio.playbackRate = 2.8;
      // setTimeout(() => {
      // this.$refs.muyuAudio.play();
      // }, 0);

      // this.$refs.muyuAudio.autoplay = true;
      // this.$refs.muyuAudio.autoplay = true;
      // this.$refs.muyuAudio.load();
      // this.$refs.muyuAudio.playbackRate = 2;
      // if (!this.$refs.muyuAudio.paused) {
      //   this.$refs.muyuAudio.pause();
      this.$refs.muyuAudio.currentTime = 0;
      this.$refs.muyuAudio.muted = this.muted;
      // }
      setTimeout(() => {
        this.$refs.muyuAudio.play();
      }, 0);

      this.point++;
      this.isKnock = true;
      this.showTxtAnimate = blessing_list[Math.floor(Math.random() * blessing_list.length)];
      this.chapletTime = setTimeout(() => {
        clearTimeout(this.chapletTime);
        this.isKnock = false;
        // document.getElementById('muyuAudio').innerHTML = '';
      }, 100);
      this.txtAnimateTime = setTimeout(() => {
        clearTimeout(this.txtAnimateTime);
        this.showTxtAnimate = '';
      }, 300);
      this.showQuotation();
      this.point === 100 && this.$track('H5_muyu_click_over_100');
      this.point === 300 && this.$track('H5_muyu_click_over_300');
      this.point === 500 && this.$track('H5_muyu_click_over_500');
    },
    swipStartFn(e) {
      this.touchStart = e.changedTouches[0].clientY;
    },
    swipEndFn(e) {
      this.touchEnd = e.changedTouches[0].clientY;
      this.swipFn(this.touchEnd - this.touchStart);
    },
    swipFn(top) {
      this.isKnock = true;
      this.showTxtAnimate = blessing_list[Math.floor(Math.random() * blessing_list.length)];
      this.point++;
      const audio = document.getElementById('nianzhuAudio');
      audio.playbackRate = 1.5;
      audio.muted = this.muted;
      audio.play();
      this.swipNianList = this.swipNianList.map(x => {
        if (top >= 0) {
          x.top = x.top + 48;
          if (x.top == 10 * 48) {
            x.top = -48 * 2;
          }
        } else {
          x.top = x.top - 48;
          if (x.top == 3 * -48) {
            x.top = 9 * 48;
          }
        }
        return x;
      });
      this.chapletTime = setTimeout(() => {
        clearTimeout(this.chapletTime);
        this.isKnock = false;
      }, 416);
      this.showQuotation();
      this.point === 100 && this.$track('H5_nianzhu_click_over_100');
      this.point === 300 && this.$track('H5_nianzhu_click_over_300');
      this.point === 500 && this.$track('H5_nianzhu_click_over_500');
    },
    px2rem(px) {
      return parseFloat(px) / 37.5 + 'rem';
    },
    async cancelSubmit() {
      await submitWoodenfish({
        numbers: this.point,
        serial_id: this.serial_id,
      });
      this.point = 0;
      this.isShowPop = false;
      this.getWoodenfishV2();
      this.goback();
    },
    backClick() {
      if (!this.point) {
        this.goback();
        return;
      }
      let exp = numMul(this.woodfish_detail_v2.change_point, this.point);
      const todayExp = numMul(this.woodfish_detail_v2.today_num, this.woodfish_detail_v2.change_point);
      const residualExp = numSub(this.woodfish_detail_v2.max_fu_de, todayExp);
      if (this.point < 100) {
        this.popText = `您本次敲木鱼/念珠${this.point}遍，未累计到功德值，是否退出页面？`;
      } else if (todayExp >= this.woodfish_detail_v2.max_fu_de) {
        this.popText = `今日功德值已达上限，您本次敲木鱼/念珠${this.point}遍，是否退出页面？`;
      } else {
        if (residualExp < exp) {
          exp = residualExp;
        }
        this.popText = `您本次敲木鱼/念珠${this.point}遍，累计功德值${Math.floor(exp)}，是否上传当前功德，退出页面？`;
      }
      this.isShowPop = true;
    },
    goback() {
      this.$router.go(-1);
    },
    toSet() {
      this.$track('H5_muyu_setting_click');
      this.upDateCache('point', this.point);
      this.$router.push({
        name: 'woodfishSetting',
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.loading-box {
  height: calc(var(--vh) * 100);
}
@keyframes resultTxtAnimate {
  0% {
    top: 30px;
    opacity: 0.85;
    font-size: 18px;
  }
  25% {
    top: 20px;
    opacity: 1;
    font-size: 20px;
  }
  50% {
    top: 10px;
    opacity: 1;
    font-size: 22px;
  }
  75% {
    top: -15px;
    opacity: 0.85;
    font-size: 26px;
  }
  100% {
    top: -35px;
    opacity: 0;
    font-size: 30px;
  }
}
@keyframes chapletTxtAnimate {
  0% {
    top: 140px;
    opacity: 1;
    font-size: 18px;
  }
  25% {
    top: 130px;
    opacity: 1;
    font-size: 20px;
  }
  50% {
    top: 120px;
    opacity: 1;
    font-size: 22px;
  }
  75% {
    top: 100px;
    opacity: 0.85;
    font-size: 26px;
  }
  100% {
    top: 80px;
    opacity: 0;
    font-size: 30px;
  }
}
.page {
  overflow: hidden;
  position: relative;
  .f {
    display: flex;
  }
  .bg {
    width: 100%;
    height: calc(var(--vh) * 100);
    min-height: 750px;
    margin-bottom: 58px;
    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .pac {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .goback {
    position: absolute;
    top: 17px;
    left: 10px;
    color: #fff;
    z-index: 1;
  }
  .quotation {
    top: 215px;
    width: 248px;
    line-height: 27px;
    font-weight: 500;
    font-size: 18px;
    color: #ffc36a;
    text-shadow: 5px 5px 30px #ffc400;
    text-align: center;
    z-index: 999;
  }
  .tabList {
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: rgba(255, 255, 255, 0.4);
    z-index: 1;
    .tab {
      width: 57px;
      text-align: center;
    }
    .tab-active {
      color: #ffffff;
      position: relative;
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        background: #f87a1b;
        border-radius: 50%;
        position: absolute;
        bottom: -11px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .setting {
    position: absolute;
    top: 17px;
    right: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }
  .sound-switch {
    position: absolute;
    right: 15px;
    top: 75px;
    .sound-icon {
      width: 24px;
      height: 24px;
      margin: auto;
      z-index: 1;
    }
    p {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 16px;
    }
  }
  .billboard {
    width: 240px;
    height: 44px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15px 15px 15px 15px;
    top: 67px;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    .count {
      height: 28px;
      line-height: 28px;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.9);
      padding-left: 16px;
    }
    .count:last-child {
      border-left: 1px solid #fff;
      padding-left: 23px;
    }
  }
  .svga-start {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100vh;
    top: 0px;
    z-index: 0;
  }
  .svga {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 780px;
    top: -50px;
    z-index: 0;
  }
  .content {
    top: 291px;
    .resultTxt {
      opacity: 0;
      top: 15px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
    }
    .resultTxtAnimate {
      top: 15px;
      opacity: 0;
      animation: resultTxtAnimate 0.3s linear;

      // animation-name: resultTxtAnimate;
      // animation-duration: 2s;
      // animation-timing-function: linear;
      // animation-fill-mode: forwards;
      // animation-delay: 1s;
    }
    .woodfish {
      width: 349px;
      height: 373px;
      display: flex;
    }
    .stick {
      position: absolute;
      left: 236px;
      top: 13px;
      // transition: all 0.3s;
    }
    .knock {
      position: absolute;
      left: 220px;
      top: 35px;
      transform-origin: center;
      transform: rotate(-25deg);
    }
    .bigMuyu {
      transform: scale(1.1);
    }
  }
  .chapletBox {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 160px;
    .swip-list {
      width: 200px;
      height: 385px;
      position: relative;
      overflow: hidden;
      .swip-nian {
        position: absolute;
        display: block;
        width: 48px;
        height: 48px;
        left: 50%;
        transform: translateX(-24px);
      }
      .swip-nian-tran {
        transition: all 0.416s;
      }
    }
    .chapletTxt {
      position: absolute;
      opacity: 0;
      right: 0;
      transform: translateX(50%);
      top: 140px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
    }
    .chapletTxtAnimate {
      animation: chapletTxtAnimate 0.416s linear;
    }
  }
  .songbo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 336px;
    .pac {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .resultTxt {
      opacity: 0;
      top: 15px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
    }
    .resultTxtAnimate {
      opacity: 0;
      animation: resultTxtAnimate 0.3s linear;
    }
    .songbo-img {
      display: flex;
      width: 349px;
      height: 373px;
    }
    .songbo-img-scale {
      transform: scale(1.2);
    }
    .songbo-ban-stick {
      position: absolute;
      left: 243px;
      top: -25px;
      width: 96px;
      height: 136px;
    }
    .songbo-ban-knock {
      position: absolute;
      left: 243px;
      top: -25px;
      width: 96px;
      height: 136px;
      transform-origin: 0 0;
      transform: rotate(20deg);
    }
  }
  .submit {
    top: 636px;
    // bottom: 121px;
    width: 116px;
    height: 35px;
    line-height: 35px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    background: linear-gradient(#eca873, #f87a1b);
    border: 0;
    border-radius: 14px;
  }
  .poplayout {
    width: 295px;
    padding: 20px;
    box-sizing: border-box;
    .cancelTitle {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
    .cancelSub {
      font-size: 16px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 16px;
    }
    .cancelTips {
      font-size: 12px;
      line-height: 16px;
      color: #f1bd82;
      margin-bottom: 20px;
    }
    .popbtn {
      width: 100px;
      height: 40px;
      text-align: center;
      border-radius: 10px;
      border: 0;
      background: #f6f6f6;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
    }
    .popbtn:last-child {
      margin-left: 25px;
      background: #f87a1b;
      color: #fff;
    }
  }
  .playbar {
    position: fixed;
    bottom: 0;
    width: 375px;
    height: 58px;
    box-sizing: border-box;
    background: #ffffff;
    opacity: 0.95;
    padding: 0 12px;
    transition: all 0.5s;
    .mask {
      width: 35px;
      height: 35px;
      margin-right: 16px;
    }
    .panel {
      width: 28px;
      height: 28px;
    }
    .ml30 {
      margin-left: 30px;
    }
    .playOrPause {
      position: relative;
      // .pause {
      //   position: absolute;
      //   left: 10px;
      //   top: 9px;
      //   width: 0;
      //   height: 0;
      //   border-left: 10px solid rgba(0, 0, 0, 0.85);
      //   border-top: 6px solid transparent;
      //   border-bottom: 6px solid transparent;
      // }
      .circle {
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .txt {
      line-height: 58px;
    }
  }
  .playbar-show {
    bottom: -58px;
  }
}
</style>
