var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageType === 1
    ? _c("div", { staticClass: "page-box f fv fc" }, [
        _vm._m(0),
        _c("div", { staticClass: "form-box" }, [
          _c(
            "div",
            { staticClass: "input-item" },
            [
              _c("van-field", {
                staticClass: "input-box",
                attrs: {
                  clearable: "",
                  center: "",
                  placeholder: "输入电话号码",
                  maxlength: "11",
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input-item f fc" },
            [
              _c("van-field", {
                staticClass: "input-box code",
                attrs: {
                  clearable: "",
                  center: "",
                  placeholder: "输入验证码",
                  maxlength: "11",
                },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _c(
                "van-button",
                { staticClass: "code-btn", on: { click: _vm.getCode } },
                [
                  _vm._v(
                    _vm._s(_vm.codeSentStatus ? _vm.calcTime : "获取验证码")
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "input-item" },
            [
              _c("van-field", {
                staticClass: "input-box",
                attrs: { clearable: "", center: "", placeholder: "输入激活码" },
                model: {
                  value: _vm.activationCode,
                  callback: function ($$v) {
                    _vm.activationCode = $$v
                  },
                  expression: "activationCode",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "confirm-btn", on: { click: _vm.confirm } },
            [_vm._v("领取")]
          ),
        ]),
        _vm._m(1),
      ])
    : _c("div", { staticClass: "page-box f fv fc" }, [
        _c("div", { staticClass: "success-box f fv fc" }, [
          _c("div", { staticClass: "title" }, [_vm._v("收到礼品了")]),
          _c("img", {
            staticClass: "success-pic",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/06b07a8e4b0976-288x298.png",
            },
          }),
          _c("div", { staticClass: "content" }, [
            _vm._v(
              " 恭喜您获得1888金币的返还，我们将会每个月返还300金币到您的账户，最后一个月返还388请注意查看钱包。 "
            ),
          ]),
          _c("div", { staticClass: "btn", on: { click: _vm.success } }, [
            _vm._v("确定"),
          ]),
        ]),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-box" }, [
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/0f6201953b630c-715x426.png",
        },
      }),
      _c("div", { staticClass: "title" }, [_vm._v("智能神台礼包领取活动")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tips" }, [
      _c("div", { staticClass: "title" }, [_vm._v("礼包领取说明")]),
      _c("div", { staticClass: "content" }, [
        _c("p", [_vm._v("1、参加活动可以获取特定兑换码")]),
        _c("p", [_vm._v("2、每个兑换码仅可使用一次")]),
        _c("p", [_vm._v("3、不同兑换码有效期不同，逾期失效")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }