var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("cell-group", { attrs: { list: _vm.settingList } }),
      _c("van-cell", {
        attrs: { title: "点香音效", is_link: "", center: "" },
        scopedSlots: _vm._u([
          {
            key: "right-icon",
            fn: function () {
              return [
                _c("van-switch", {
                  attrs: {
                    "active-color": "#f87a1b",
                    "inactive-color": "#ffeac9",
                    size: "20px",
                    value: _vm.generalConfig.incense_sound,
                  },
                  on: { input: _vm.incenseSoundChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.generalConfig.incense_sound
        ? [
            _c("van-cell", {
              attrs: { title: "一响", is_link: "", center: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "right-icon",
                    fn: function () {
                      return [
                        _c("van-radio", {
                          attrs: {
                            name: "one",
                            "icon-size": "20px",
                            "checked-color": "#f87a1b",
                          },
                          on: { click: _vm.updateIncenseSoundEffect },
                          model: {
                            value: _vm.incenseSoundEffect,
                            callback: function ($$v) {
                              _vm.incenseSoundEffect = $$v
                            },
                            expression: "incenseSoundEffect",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2639671322
              ),
            }),
            _c("van-cell", {
              attrs: { title: "三响", is_link: "", center: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "right-icon",
                    fn: function () {
                      return [
                        _c("van-radio", {
                          attrs: {
                            name: "three",
                            "icon-size": "20px",
                            "checked-color": "#f87a1b",
                          },
                          on: { click: _vm.updateIncenseSoundEffect },
                          model: {
                            value: _vm.incenseSoundEffect,
                            callback: function ($$v) {
                              _vm.incenseSoundEffect = $$v
                            },
                            expression: "incenseSoundEffect",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3956916912
              ),
            }),
          ]
        : _vm._e(),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          on: { "click-overlay": _vm.onCancel },
          model: {
            value: _vm.showFlag,
            callback: function ($$v) {
              _vm.showFlag = $$v
            },
            expression: "showFlag",
          },
        },
        [
          _c("div", { staticClass: "f fc fbt header" }, [
            _c("div", { staticClass: "cancel", on: { click: _vm.onCancel } }, [
              _vm._v("取消"),
            ]),
            _c(
              "div",
              { staticClass: "confirm", on: { click: _vm.onConfirm } },
              [_vm._v("确定")]
            ),
          ]),
          _c("div", { staticClass: "body" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("媒体声音")]),
              _c(
                "div",
                { staticClass: "f fc" },
                [
                  _c("img", {
                    staticClass: "left-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/ed019f3c1bd0d2-93x73.png",
                    },
                  }),
                  _c("van-slider", {
                    attrs: { max: "15" },
                    model: {
                      value: _vm.soundSize,
                      callback: function ($$v) {
                        _vm.soundSize = $$v
                      },
                      expression: "soundSize",
                    },
                  }),
                  _c("div", { staticClass: "size" }, [
                    _vm._v(" " + _vm._s(_vm.soundSize) + " "),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "tip" }, [_vm._v("操作声音")]),
              _c(
                "div",
                { staticClass: "f fc" },
                [
                  _c("img", {
                    staticClass: "left-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/ed019f3c1bd0d2-93x73.png",
                    },
                  }),
                  _c("van-slider", {
                    attrs: { max: "15" },
                    model: {
                      value: _vm.systemSoundSize,
                      callback: function ($$v) {
                        _vm.systemSoundSize = $$v
                      },
                      expression: "systemSoundSize",
                    },
                  }),
                  _c("div", { staticClass: "size" }, [
                    _vm._v(" " + _vm._s(_vm.systemSoundSize) + " "),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }