var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.successFlag
    ? _c(
        "div",
        { staticClass: "f fv fc page-box" },
        [
          _c("DownloadBar"),
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/dd9a19cedebd3b-144x144.png",
            },
          }),
          _c("div", { staticClass: "tip" }, [_vm._v("登录成功")]),
          _c("div", { staticClass: "sub-tip" }, [
            _vm._v(
              "你好，" +
                _vm._s(_vm.userInfo.name || "尊敬的用户") +
                "，欢迎你的加入"
            ),
          ]),
          _vm.serialId
            ? _c(
                "van-button",
                {
                  staticClass: "btn",
                  attrs: { type: "info" },
                  on: { click: _vm.bind },
                },
                [_vm._v("下一步")]
              )
            : _c(
                "van-button",
                {
                  staticClass: "btn",
                  attrs: { type: "info" },
                  on: { click: _vm.addDevice },
                },
                [_vm._v("添加我的设备")]
              ),
        ],
        1
      )
    : _c("div", { staticClass: "page-box f fc fvc loading-tip" }, [
        _vm._v("跳转中..."),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }