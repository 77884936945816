var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "festivalCalendar f fvc" }, [
    _c("div", { staticClass: "txt today", on: { click: _vm.goCalendar } }, [
      _vm._v(_vm._s(_vm.today)),
    ]),
    _c(
      "div",
      { staticClass: "list" },
      [
        _c(
          "van-swipe",
          {
            staticStyle: { height: "31px" },
            attrs: {
              autoplay: "3600",
              "show-indicators": false,
              touchable: false,
              vertical: "",
            },
          },
          _vm._l(_vm.list, function (item) {
            return _c(
              "van-swipe-item",
              {
                key: item.id,
                on: {
                  click: function ($event) {
                    return _vm.festivalFn(item)
                  },
                },
              },
              [
                item.left_day === 0
                  ? _c(
                      "div",
                      { staticClass: "item f fvc" },
                      [
                        _c("span", { staticClass: "txt" }, [_vm._v("今天是")]),
                        _c("van-icon", {
                          staticClass: "icon",
                          attrs: {
                            name: item.type === 2 ? _vm.foTag : _vm.daoTag,
                            size: "16px",
                          },
                        }),
                        _c(
                          "span",
                          {
                            class: [
                              "txt",
                              item.type === 2 ? "foTxt" : "daoTxt",
                            ],
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c("van-icon", {
                          staticClass: "icon",
                          attrs: {
                            name: "https://img-fe.tengzhihh.com/image/bac6c76bd18ad4-16x16.png",
                            size: "16px",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "item f fvc" },
                      [
                        _c("span", { staticClass: "txt" }, [_vm._v("距")]),
                        _c("van-icon", {
                          staticClass: "icon",
                          attrs: {
                            name: item.type === 2 ? _vm.foTag : _vm.daoTag,
                            size: "16px",
                          },
                        }),
                        _c(
                          "span",
                          {
                            class: [
                              "txt",
                              item.type === 2 ? "foTxt" : "daoTxt",
                            ],
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c("span", { staticClass: "txt" }, [_vm._v("还有")]),
                        _c("span", { staticClass: "txt light-txt" }, [
                          _vm._v(_vm._s(item.left_day)),
                        ]),
                        _c("span", { staticClass: "txt" }, [_vm._v("天")]),
                        _c("van-icon", {
                          staticClass: "icon",
                          attrs: {
                            name: "https://img-fe.tengzhihh.com/image/bac6c76bd18ad4-16x16.png",
                            size: "16px",
                          },
                        }),
                      ],
                      1
                    ),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }