var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "layout" },
        [
          _vm.list.length
            ? _c(
                "div",
                { staticClass: "address-list" },
                _vm._l(_vm.list, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "list",
                      on: {
                        click: function ($event) {
                          return _vm.addressClick(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "head flex" }, [
                        _c("div", { staticClass: "flex mr-15" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.username)),
                          ]),
                          _c("div", { staticClass: "mobile" }, [
                            _vm._v(_vm._s(item.mobile)),
                          ]),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "remove",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.removeClick(item)
                              },
                            },
                          },
                          [_c("van-icon", { attrs: { name: "cross" } })],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "detail" }, [
                        _vm._v(
                          " " +
                            _vm._s(item.province) +
                            " " +
                            _vm._s(item.city) +
                            " " +
                            _vm._s(item.district) +
                            " " +
                            _vm._s(item.street) +
                            _vm._s(item.address) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "handle flex" }, [
                        _c(
                          "div",
                          {
                            class: [
                              "default",
                              "flex",
                              item.is_default === 1 ? "checked" : "",
                            ],
                          },
                          [
                            item.is_default === 1
                              ? _c("img", {
                                  staticClass: "mr8",
                                  attrs: {
                                    src: "https://img-fe.tengzhihh.com/image/ddf795e2137823-14x14.png",
                                    alt: "",
                                    srcset: "",
                                  },
                                })
                              : _c("div", {
                                  directives: [
                                    {
                                      name: "throttle",
                                      rawName: "v-throttle:[item]",
                                      value: _vm.patchFn,
                                      expression: "patchFn",
                                      arg: item,
                                    },
                                  ],
                                  staticClass: "check mr8",
                                }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.is_default === 1 ? "已设为默认" : "默认"
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.editFn(item)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              )
            : _c("empty", { attrs: { label: "暂无地址" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "add-address" },
        [
          _c(
            "van-button",
            {
              directives: [
                {
                  name: "throttle",
                  rawName: "v-throttle",
                  value: _vm.addAddress,
                  expression: "addAddress",
                },
              ],
              attrs: { block: "", color: "#F87A1B", size: "small" },
            },
            [_vm._v("+添加收货地址")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.removepop,
            callback: function ($$v) {
              _vm.removepop = $$v
            },
            expression: "removepop",
          },
        },
        [
          _c("p", { staticClass: "remove-tip" }, [
            _vm._v("确定要删除该地址吗？"),
          ]),
          _c(
            "div",
            { staticClass: "popHandle flex" },
            [
              _c(
                "van-button",
                {
                  attrs: { type: "default" },
                  on: {
                    click: function ($event) {
                      _vm.removepop = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "van-button",
                {
                  directives: [
                    {
                      name: "throttle",
                      rawName: "v-throttle",
                      value: _vm.removeFn,
                      expression: "removeFn",
                    },
                  ],
                  staticClass: "btn",
                  attrs: { color: "#F87A1B" },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }