<template>
  <div v-if="pageStep === 'index'" id="god-index-container">
    <div class="index-top f fc fbt">
      <img src="https://img-fe.tengzhihh.com/image/5e5eeee8268676-56x56.png" class="top-icon" @click="goDevice" />

      <div class="index-top-box f fc fvc no-western">
        <div class="btn" @click="enshrineAdd">添加逝者</div>
        <div class="btn" @click="enshrineSetting">供奉设置</div>
      </div>
      <img
        src="https://img-fe.tengzhihh.com/image/01875a0e427436-56x56.png"
        class="top-icon"
        @click="showExplanation"
      />
    </div>

    <img src="https://img-fe.tengzhihh.com/image/252da40a8e5eda3b79833e25b82072e2-750x1624.png" class="bg-img" />
    <img
      src="https://img-fe.tengzhihh.com/image/14d060fbece29a82b4b0e0d88adc9eae-538x538.png"
      class="bg-img-light abs-c"
    />

    <!-- <div class="flags-box f fc fbt" v-if="enshrineImg.left_golden_flag_image">
        <img :src="enshrineImg.left_golden_flag_image" class="flag-item left" />
        <img :src="enshrineImg.right_golden_flag_image" class="flag-item right" />
      </div> -->

    <swiper class="swiper" :options="swiperOption" ref="godSwiper">
      <swiper-slide v-for="(item, index) in enshrineList" :key="item.id">
        <img
          v-if="index !== 0"
          src="https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png"
          class="arrow arrow-left"
          @click.stop.prevent="upDateIndex(index - 1)"
        />
        <img
          v-if="index !== enshrineList.length - 1"
          src="https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png"
          class="arrow arrow-right"
          @click.stop.prevent="upDateIndex(index + 1)"
        />

        <EnshrineDetail :enshrineId="item.id" @quickOffering="quickOffering" @setValue="setValue" />
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
  <div v-else-if="pageStep === 'offerings'">
    <OfferingsList
      :godType="3"
      :serialId="routeQuery.serial_id"
      :godId="enshrineList[activeIndex].id"
      :indexMode="'all'"
      ref="OfferingsListRef"
      @setValue="setValue"
      @updateGodInfo="quickOffering"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import EnshrineDetail from './detail.vue';
import OfferingsList from './offeringsList.vue';
import 'swiper/css/swiper.css';
import { getDeceasedList } from '@/api/deceased';
import { commonOperate } from '@/mixins/commonOperate';
import bus from '@/utils/bus';
import sendMsg from '@/webSocket/sendMsg';
export default {
  mixins: [commonOperate],
  components: {
    Swiper,
    SwiperSlide,
    EnshrineDetail,
    OfferingsList,
  },
  data() {
    const vue = this; //保存Vue实例
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            // 此处的this为swiper实例
            vue.$track('gongfeng_left_and_right_switch');
            vue.activeIndex = this.activeIndex;
            sendMsg('pageViewListMoveXianRen', this.activeIndex);
          },
        },
      },
      swiper: null,
      activeIndex: 0,
      enshrineImg: {},
      enshrineList: [],
      has_deceased: 0, //是否有先人档案，1是0否
      pageStep: 'index',
    };
  },
  created() {
    this.pageStep = this.$route.query.page_step || 'index';
    this.getDeceasedList();
  },
  beforeDestroy() {
    bus.$off('onMsg');
  },
  methods: {
    getSystemInfo() {
      sendMsg('systemInfo', {
        operation: 'getBatteryStatus',
      });
    },
    handleMsg(msg) {
      const action = msg.action;
      const operation = msg.data.operation;
      const map = {
        systemInfo: {
          operation: {
            updateBatteryStatus: () => {
              this.updateBatteryStatus(msg);
            },
            deceaseListRefresh: () => {
              this.quickOffering(this.enshrineList[this.activeIndex].id);
            },
            deadRefresh: () => {
              this.quickOffering(msg.data.deceased_id);
            },
          },
        },
      };
      const actionCb = map[action]?.cb;
      const operationCb = map[action] && map[action]['operation'] && map[action]['operation'][operation];
      typeof actionCb === 'function' && actionCb();
      typeof operationCb === 'function' && operationCb();
    },
    updateBatteryStatus(msg) {
      if (msg.action !== 'systemInfo') return;
      //香薰容量
      this.$store.commit('setGeneralConfig', { incenseCapacity: msg.data.incenseCapacity });
    },
    upDateIndex(index) {
      sendMsg('pageViewListMoveXianRen', index);
      this.sliceToTarget(index);
    },
    sliceToTarget(index) {
      this.$nextTick(() => this.$refs?.godSwiper?.$swiper?.slideTo(index));
    },
    async quickOffering(id) {
      const index = this.enshrineList.findIndex(i => i.id == id);
      this.enshrineList = [];
      await this.getDeceasedList();
      if (index !== -1) {
        this.activeIndex = index;
        this.sliceToTarget(index);
      } else {
        this.activeIndex = 0;
      }
    },
    async getDeceasedList() {
      const { data } = await getDeceasedList({
        god_type: 3,
        serial_id: this.routeQuery.serial_id,
      });
      const { icon, list, has_deceased } = data;
      this.enshrineImg = icon;
      this.enshrineList = list.map(x => x);
      this.has_deceased = has_deceased;
    },
    goDevice() {
      this.$router.replace(`/device/index?serial_id=${this.routeQuery.serial_id}`);
    },
    showExplanation() {
      this.$track('gongfeng_shuoming_button_click');
      this.$router.push(`/fude/explanation?fude_type=gong_feng_decease_notice&serial_id=${this.routeQuery.serial_id}`);
    },
    enshrineAdd() {
      this.$router.push(`/deceased/info?page_type=add&serial_id=${this.routeQuery.serial_id}`);
    },
    enshrineSetting() {
      this.$router.push({
        name: 'enshrineSetting',
        query: this.routeQuery,
      });
    },
    setValue(key, value) {
      this[key] = value;
    },
  },
};
</script>

<style lang="less" scope>
#god-index-container::-webkit-scrollbar {
  width: 0 !important;
}

#god-index-container {
  height: calc(var(--vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.hidden-overflow {
  overflow: hidden !important;
}
.index-top {
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  padding: 0 12px;
  box-sizing: border-box;
  .top-icon {
    width: 28px;
    height: 28px;
  }
  .index-top-box {
    width: 236px;
    height: 47px;
    position: relative;
    top: -2px;
    background-size: 100%;
    background-repeat: no-repeat;

    .btn {
      width: 72px;
      height: 20px;
      border-radius: 10px;
      line-height: 20px;
      text-align: center;
      font-size: 12px;
      margin: 0 8px;
      position: relative;
      top: -4px;
    }
  }
  .no-western {
    background-image: url('https://img-fe.tengzhihh.com/image/06e50b31c6a6ec-472x94.png');
    .btn {
      color: #ff913e;
      border: 1px solid #ba7e60;
      background: #884f30;
    }
  }
  .western {
    background-image: url('https://img-fe.tengzhihh.com/image/58581da2165ebe-472x94.png') !important;
    .btn {
      color: #ff913e !important;
      border: 1px solid #f1dca4;
      background: #ffffd5;
    }
  }
}
.cus-overlay {
  position: absolute !important;
  z-index: 2024;
  height: calc(var(--vh) * 100);
}
.abs-c {
  left: 50%;
  transform: translateX(-50%);
}
.bg-img {
  position: absolute;
  width: 375px;
  height: 812px;
}
.bg-img-light {
  position: absolute;
  top: 70px;
  width: 269px;
  height: 269px;
  object-fit: fill;
}
.flags-box {
  width: 100%;
  position: absolute;
  .flag-item {
    width: 58px;
    height: 336px;
  }
}
.enshrine-pop-title {
  line-height: 30px;
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 2px;
}
.enshrine-pop-text {
  width: 255px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin: 20px 0 30px;
}
</style>
<style lang="less" scoped>
/deep/ .swiper-pagination {
  position: absolute;
  top: 680px;
  .swiper-pagination-bullet-active {
    background: #f87a1b;
  }
  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
  }
}
.arrow {
  position: absolute;
  width: 36px;
  height: 36px;
  top: 300px;
  z-index: 2200;
}
.arrow-left {
  left: 10px;
  transform: rotate(180deg);
}
.arrow-right {
  right: 10px;
}
</style>
<style lang="less">
#god-index-container {
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    height: 30px;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal {
    // height: 780px;
  }
}
</style>
