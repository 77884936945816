<template>
  <div class="bottom-box f fc frd">
    <div v-for="(item, index) in calcList" :key="index" class="tab-item f fv fvc" @click="jump(item)">
      <van-badge class="badge" v-if="item.dot" dot></van-badge>
      <img :src="item.activeIcon" class="icon" v-if="currentRouteInfo.title === item.name" />
      <img :src="item.defaultIcon" class="icon" v-else />
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getStore, setStore } from '@/utils/localStore';
export default {
  data() {
    return {
      bottomList: [
        {
          name: '首页',
          path: '/',
          defaultIcon: 'https://img-fe.tengzhihh.com/image/1542117f27dab6-48x48.png',
          activeIcon: 'https://img-fe.tengzhihh.com/image/a5232dd5344af8-48x48.png',
        },
        {
          name: '商城',
          path: '/shop/list',
          defaultIcon: 'https://img-fe.tengzhihh.com/image/8f8413fc81ae85-48x48.png',
          activeIcon: 'https://img-fe.tengzhihh.com/image/5aba0489bbaf1f-48x48.png',
          trackName: 'enter_shangcheng_tab',
          dot: getStore({
            name: `海南玉蟾宫|刘财神实景数字神像-商品-已点击`,
          })
            ? false
            : true,
        },
        // {
        //   name: '资讯',
        //   path: '/news/index',
        //   defaultIcon: 'https://img-fe.tengzhihh.com/image/b5ccae98c5802e-48x48.png',
        //   activeIcon: 'https://img-fe.tengzhihh.com/image/4f2ef5932e6818-48x48.png',
        //   trackName: 'enter_zixun_tab',
        // },
        {
          name: '工具',
          path: '/tools/calendar',
          defaultIcon: 'https://img-fe.tengzhihh.com/image/3ad79f20c06074-48x48.png',
          activeIcon: 'https://img-fe.tengzhihh.com/image/9505af2d7e7903-48x48.png',
          trackName: 'enter_gongju_tab',
        },
        {
          name: '我的',
          path: '/my/index',
          defaultIcon: 'https://img-fe.tengzhihh.com/image/0cf44d125f4f00-48x48.png',
          activeIcon: 'https://img-fe.tengzhihh.com/image/45a39490da0124-48x48.png',
          trackName: 'enter_mine_tab',
        },
      ],
    };
  },
  computed: {
    ...mapState(['currentRouteInfo']),
    calcList() {
      if (this.$tools.isInApp()) {
        return this.bottomList.filter((i) => i.name !== '商城');
      }
      return this.bottomList;
    },
  },
  methods: {
    jump(item) {
      if (item.path) {
        this.$router.replace(item.path);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bottom-box {
  background-color: #fff;
  width: 100%;
  height: 50px;
  border-top: 1px solid #ebebeb;
  position: absolute;
  z-index: 1000;
  left: 0;
  bottom: 0;
  font-size: 10px;
  font-weight: 500;
  color: #333333;
  .icon {
    margin-bottom: 3px;
    width: 21px;
    height: 21px;
  }

  .tab-item {
    position: relative;

    .badge {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
