<template>
  <div class="vip-privilege-container">
    <div :class="['user-vip-info', { 'no-vip': !userInfo.is_vip }]">
      <div class="user">
        <div class="avatar f fc fvc">
          <img :src="$tools.compressImg(userInfo.avatar, 70)" v-if="userInfo.avatar" />
        </div>
        <div class="name">
          {{ userInfo.name }}
        </div>
        <img
          class="vip-icon"
          :src="
            userInfo.is_vip
              ? 'https://fe-img.tengzhihh.com/image/e723ca21a459759733341351878527c6-88x30.png'
              : 'https://fe-img.tengzhihh.com/image/86bcc71dcccfb21bdada1512dbb8ddb9-88x30.png'
          "
        />
      </div>
      <div class="info">
        <p class="vip-validity">{{ userInfo.vip_info ? userInfo.vip_info.txt : '未成为VIP' }}</p>
        <div class="btn" @click="handleVipBtnClick">
          {{ userInfo.is_vip ? '续费' : '加入VIP' }}
        </div>
      </div>
    </div>
    <img class="divider" src="https://fe-img.tengzhihh.com/image/f60dd600fd0d54dd943a8c8ce7a27131-642x34.png" alt="" />
    <div class="menu">
      <div class="menu-item" v-for="item in menuList" :key="item.id + 'key'" @click="handleMenuClick(item.type)">
        <img class="menu-icon" :src="item.icon" alt="" />
        <div class="label">{{ item.title }}</div>
      </div>
    </div>
    <PopupVipTips :list="menuList" ref="vip" />
  </div>
</template>
<script>
import apiPath from '@/utils/apiPath';
import PopupVipTips from './comp/PopupVipTips.vue';

export default {
  components: {
    PopupVipTips,
  },
  data() {
    return {
      loading: true,
      userInfo: {},
      menuList: [],
    };
  },
  created() {
    this.getVipRight();
  },
  mounted() {
    !this.userInfo.phone && this.$utils.getUserInfo();
  },
  methods: {
    handleMenuClick(type) {
      if ([1, 2, 3, 4].includes(type)) {
        this.$refs.vip.open(type);
      } else if (type == '5') {
        const id = this.menuList.find(item => item.type == type).gift_id;
        this.$router.push(`/activity/xiangxunGift?id=${id}`);
      }
    },
    getVipRight() {
      this.$http.get(apiPath.getVipRight).then(res => {
        this.userInfo = res.data.user;
        this.menuList = res.data.list;
      });
    },
    handleVipBtnClick() {
      this.$router.push('/shop/list');
    },
  },
};
</script>

<style lang="less" scoped>
.vip-privilege-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  background: linear-gradient(358deg, #f5f5f5ff 75%, #ffead9 100%);

  .user-vip-info {
    position: relative;
    width: 345px;
    height: 128px;
    background-image: url('https://fe-img.tengzhihh.com/image/a90c068453c0ba91470c80160e455781-690x256.png');
    background-size: cover;
    background-position: bottom center;
    padding: 35px 16px 0 16px;
    box-sizing: border-box;
    margin-bottom: 16px;

    .user {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid #eaa436;
        overflow: hidden;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
      .name {
        width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
      }
      .vip-icon {
        width: 44px;
        height: 15px;
        margin-left: 8px;
      }
    }

    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .vip-validity {
        font-size: 12px;
        line-height: 17px;
        color: #dc9131;
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 26px;
        background: #ffedcb;
        border-radius: 14px;

        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: #dc9131;
      }
    }

    &.no-vip {
      background-image: url('https://fe-img.tengzhihh.com/image/f42705ab6ad6176d06f0774554f6c94b-690x256.png');

      .avatar {
        border-color: #fff;
      }
      .info {
        .btn {
          width: 70px;
          background: #f2af38ff;
          color: #fff;
        }
      }
    }
  }

  .divider {
    width: 321px;
    margin-bottom: 16px;
  }

  .menu {
    display: flex;
    flex-wrap: wrap;
    width: 345px;
    height: 218px;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    column-gap: 29px;
    row-gap: 20px;

    .menu-item {
      position: relative;
      width: 54px;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .menu-icon {
        width: 54px;
        height: 54px;
      }
      .label {
        position: absolute;
        bottom: 0;
        left: 50%;
        white-space: nowrap;
        transform: translateX(-50%);
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>
