<template>
  <van-popup class="confirm-popup" v-model="show">
    <div class="confirm-title">确认兑换信息</div>
    <div class="confirm-content">
      <div class="confirm-item">
        <span class="confirm-label">收件人名称</span>
        <span class="confirm-value">{{ name }}</span>
      </div>
      <div class="confirm-item">
        <span class="confirm-label">收件人手机号</span>
        <span class="confirm-value">{{ phone }}</span>
      </div>
      <div class="confirm-item">
        <span class="confirm-label">收件人地址</span>
        <span class="confirm-value">{{ address }}</span>
      </div>
      <div class="confirm-item">
        <span class="confirm-label">兑换物品</span>
        <span class="confirm-value">{{ giftName }}×{{ giftNum }}</span>
      </div>
      <van-button class="confirm-btn" round block color="#ff8e38" @click="close">确认</van-button>
      <div class="kefu" @click="kefu">联系客服</div>
    </div>
    <Dialog ref="dialog" />
  </van-popup>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      show: false,
      name: '',
      phone: '',
      address: '',
      giftName: '',
      giftNum: '',
    };
  },
  methods: {
    open(data) {
      this.name = data.user_name;
      this.phone = data.phone;
      this.address = data.address;
      this.giftName = data.gift_name;
      this.giftNum = data.num;
      this.show = true;
    },
    close() {
      this.show = false;
      this.name = '';
      this.phone = '';
      this.address = '';
      this.giftName = '';
      this.giftNum = '';
    },
    kefu() {
      this.$utils.kefuDialog(this.$refs.dialog);
    },
  },
};
</script>

<style lang="less" scoped>
.confirm-popup {
  width: 315px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  .confirm-title {
    padding-bottom: 17px;
    text-align: center;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .confirm-content {
    padding: 30px 17px 0;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .confirm-item {
      display: flex;

      .confirm-label {
        text-align: left;
        flex-shrink: 0;
        width: 100px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
      .confirm-value {
        text-align: left;
        flex-grow: 1;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        word-break: break-all;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .confirm-btn {
      margin-top: 10px;
      border-radius: 10px;
    }
    .kefu {
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 16px;
      color: #ff8e38;
    }
  }
}
</style>
