<template>
  <van-popup
    v-model="musicListFlag"
    position="bottom"
    :style="{ width: '90%', left: '5%', borderRadius: '14px', bottom: '20px' }"
    @click="musicListFlag = false"
  >
    <div class="music-list-box">
      <div class="header f fc fbt">
        <div class="f fc" @click.stop.prevent="changeCurrentPlayingMode" v-if="from === 'music'">
          <img :src="playingModeIcon.gray" class="playing-mode" />
          <div style="font-size: 14px">
            {{ currentPlayingMode === 0 ? '顺序模式' : currentPlayingMode === 1 ? '随机模式' : '单曲循环' }}
          </div>
        </div>
      </div>
      <div class="body">
        <div
          class="music-item f fc fbt"
          v-for="(item, index) in currentPlayingList"
          :key="item.id"
          @click.stop.prevent="change(item)"
        >
          <div :class="`f fc ${item.id === currentMusic.id ? 'current' : ''}`">
            {{ item.name }}
            <img
              v-if="item.id === currentMusic.id && isPlaying"
              src="https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif"
              class="icon"
            />
          </div>
          <div v-if="from === 'music'">
            <img
              src="https://img-fe.tengzhihh.com/image/aa923ae4966745-18x19.png"
              v-show="item.id != currentMusic.id"
              class="close-icon"
              @click.stop.prevent="removeMusicFromList(item, index)"
            />
          </div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import sendMsg from '@/webSocket/sendMsg';

export default {
  props: {
    isPlaying: {
      type: Boolean,
      default: false,
    },
    serialId: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: 'lesson',
    },
    lessonId: {
      type: [Number, String],
      default: 0,
    },
    currentPlayingMode: {
      type: Number,
      default: 0,
    },
    currentPlayingList: {
      type: Array,
      default: () => [],
    },
    currentMusic: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      musicListFlag: false,
    };
  },
  computed: {
    playingModeIcon: function () {
      const arr = [
        {
          white: 'https://img-fe.tengzhihh.com/image/f4f5480903a05b-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/9b5d8316a675eb-48x48.png',
        },
        {
          white: 'https://img-fe.tengzhihh.com/image/88c9ad27afd6db-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/def0dd42ded094-48x48.png',
        },
        {
          white: 'https://img-fe.tengzhihh.com/image/34da1bf5732349-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/3af16216079cbe-48x48.png',
        },
      ];
      return arr[this.currentPlayingMode];
    },
  },
  methods: {
    change(item) {
      if (this.from === 'music') {
        sendMsg('player', {
          operation: 'changeCurrentPlay',
          extra: item.id,
          playerType: 'music',
        });
      }
    },
    changeCurrentPlayingMode() {
      this.$emit('changeCurrentPlayingMode');
    },
    show() {
      this.musicListFlag = true;
    },
    cancel() {
      this.musicListFlag = false;
    },
    removeMusicFromList(item, index) {
      this.$emit('removeMusicFromList', item, index);
    },
  },
};
</script>

<style lang="less" scoped>
.music-list-box {
  z-index: 1000;
  box-sizing: border-box;
  width: 100%;
  height: 50vh;
  background: #ffffff;
  border: 0px solid #bf763b;
  border-radius: 14px;
  padding: 10px 0 0;
  .header {
    font-size: 17px;
    color: #333333;
    padding: 0 20px;
    .close-icon {
      width: 15px;
      height: 15px;
    }
    .playing-mode {
      width: 25px;
      height: 25px;
      margin-right: 12px;
    }
  }
  .body {
    height: calc(50vh - 50px);
    overflow: auto;
  }

  .music-item {
    font-size: 14px;
    color: #333333;
    padding: 10px 0;
    margin: 0 20px;
    .current {
      color: #eba95d;
    }
    .icon {
      width: 14px;
      height: 14px;
      margin-left: 10px;
    }
    .close-icon {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
