<template>
  <div class="audio-box f fc">
    <van-slider v-model="playerValue" bar-height="2px" button-size="12px" active-color="#fff" @change="onChange">
    </van-slider>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    musicId: {
      type: [String, Number],
      default: 0,
    },
    currentPosition: {
      type: [String, Number],
      default: 0,
    },
    currentRate: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      playerValue: 0,
    };
  },
  watch: {
    currentRate: {
      handler: function (n) {
        this.playerValue = n;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState(['musicList']),
    currentMusic: function () {
      return this.musicList.find((i) => this.musicId != 0 && this.musicId == i.id) || 0;
    },
  },
  mounted() {},

  methods: {
    updateTime(e) {
      this.showTime();
    },
    setTime(duration) {},

    onChange(v) {
      this.$emit('currentPositionChange', this.transformPerAndDuration({ per: v }));
    },
    transformPerAndDuration({ per, duration, totalDuration = this.currentMusic.duration || 270 }) {
      if (per) {
        return parseInt(totalDuration * ((per * 10) / 1000));
      } else if (duration) {
        return ((duration / totalDuration.toFixed(2)) * 1000) / 10;
      }
    },
  },
};
</script>

<style lang="less">
.audio-box {
  width: 70%;
  .custom-button {
    width: 26px;
    color: #fff;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    background-color: #ee0a24;
    border-radius: 100px;
  }
  .van-slider {
    background-color: rgba(255, 255, 255, 0.2) !important;
  }
}
</style>
