var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c("orderTabs", { staticClass: "mb-10" }),
      _vm.list.length
        ? _c(
            "div",
            [
              _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了",
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                _vm._l(_vm.list, function (item) {
                  return _c("orderCard", {
                    key: item.id,
                    staticClass: "mb-10",
                    attrs: {
                      description: item,
                      handleList: _vm.handleList(item),
                    },
                    on: {
                      handleClick: _vm.handleClick,
                      cardClick: _vm.cardClick,
                      copy: _vm.copyFn,
                    },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _c("empty"),
      _c("Dialog", { ref: "dialog" }),
      _c("cancelPop", {
        ref: "cancelPop",
        on: { cancelSuccess: _vm.resetList },
      }),
      _c("confirmPop", {
        ref: "confirmPop",
        on: { receiveConfirm: _vm.resetList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }