<template>
  <div class="god-list">
    <div class="god-box padding-44">
      <div class="btn-header f fc fbt">
        <img src="https://img-fe.tengzhihh.com/image/e1de3948873ac8-48x48.png" class="back-icon" @click="back" />
        <div class="btns-box f fc fvc frd">
          <div class="f fv fvc" v-for="(item, idx) in calcFactionList" :key="idx">
            <img
              :src="item.active"
              :class="`${[4, 5].includes(item.faction) ? 'big-btn' : ''} faction-btn`"
              v-if="curFaction === item.faction && calcFactionList.length > 1"
            />
            <img
              :src="item.default"
              :class="`${[4, 5].includes(item.faction) ? 'big-btn' : ''} faction-btn`"
              @click="changeFaction(item.faction)"
              v-else
            />
            <div
              :class="`line ${curFaction === item.faction ? '' : 'transparent'}`"
              v-if="calcFactionList.length > 1"
            ></div>
          </div>
        </div>
      </div>

      <div class="list-body" v-if="currentList.length > 0">
        <div class="group-item" v-for="(group, index) in currentList" :key="index">
          <img src="https://img-fe.tengzhihh.com/image/3dbd9693f78dc0-750x518.png" class="group-bg" />
          <div class="f fc">
            <div class="body-item f fv fvc" v-for="(item, idx) in group" :key="idx">
              <img v-lazy="item.main_image" @click="openDialog(item)" class="god-item-img" />
              <div class="name-box">
                <img :src="item.name_icon" class="name-img" />
              </div>

              <div class="option-area">
                <div class="moral">{{ item.moral }}</div>
                <img
                  v-if="item.is_invited === 1 && !['init_select'].includes(from)"
                  src="https://img-fe.tengzhihh.com/image/bce95fa8b22d77-162x48.png"
                  class="set-btn"
                  @click="openDialog(item)"
                />
                <img
                  v-else
                  src="https://img-fe.tengzhihh.com/image/a820b542e30df5-162x48.png"
                  class="set-btn"
                  @click="openDialog(item)"
                />
              </div>
            </div>
          </div>
        </div>
        <img src="https://img-fe.tengzhihh.com/image/57e9ae954ed5e2-1125x220.png" class="shadow" />
        <div v-if="showConfirmBtn" class="confirm-btn" @click="confirm">完成</div>
      </div>

      <div class="empty-box f fv fvc" v-else-if="currentList.length === 0 && curFaction === 4">
        <img src="https://img-fe.tengzhihh.com/image/caa44b5eb41071-276x276.png" class="empty-icon" />
        <img src="https://img-fe.tengzhihh.com/image/b6e1887a4dd9aa-212x32.png" class="empty-tip" />
        <img src="https://img-fe.tengzhihh.com/image/12379e396b172a-162x48.png" class="empty-btn" @click="goGoods" />
      </div>
    </div>

    <van-popup v-model="dialogFlag" position="bottom" class="popup">
      <div class="f fv fc popup-box">
        <div class="content f fv fc">
          <div class="god-img-box">
            <img src="https://img-fe.tengzhihh.com/image/81925064b6126d-276x276.png" class="bg" />
            <img :src="settingGodDetail.main_image" class="god-item-img" />
          </div>
          <div class="name">{{ settingGodDetail.name }}</div>

          <div class="id-box" v-if="settingGodDetail.unique_number">
            <img src="https://img-fe.tengzhihh.com/image/11e1a446efd149-392x64.png" class="id-bg-img" />
            <div class="id">神像编号（ID：{{ settingGodDetail.unique_number }}）</div>
          </div>
          <div
            :class="`confirm-btn ${settingGodDetail.is_invited && !['init_select'].includes(from) ? 'cancel-btn' : ''}`"
            @click="inviteGod"
          >
            {{ settingGodDetail.is_invited && !['init_select'].includes(from) ? '已供奉' : '请神上台' }}
          </div>
          <div class="desc">
            <img src="https://img-fe.tengzhihh.com/image/012a671b1d07c4-154x42.png" class="title-icon" />
            <div v-html="settingGodDetail.introduce && settingGodDetail.introduce.replace(/\n/g, '<br />')"></div>
          </div>
          <div class="desc">
            <img src="https://img-fe.tengzhihh.com/image/bf2a6a33efca80-154x42.png" class="title-icon" />

            <div class="step f fc">
              <div class="step-item f fv fvc">
                <img src="https://img-fe.tengzhihh.com/image/59155aa1d3f719-96x96.png" class="step-icon" />
                <div>请神</div>
              </div>
              <img src="https://img-fe.tengzhihh.com/image/86416c39ef0a6b-16x20.png" class="arrow" />
              <div class="step-item f fv fvc">
                <img src="https://img-fe.tengzhihh.com/image/b968c644706250-96x96.png" class="step-icon" />
                <div>供奉</div>
              </div>
              <img src="https://img-fe.tengzhihh.com/image/86416c39ef0a6b-16x20.png" class="arrow" />
              <div class="step-item f fv fvc">
                <img src="https://img-fe.tengzhihh.com/image/6c82d11a4810fc-96x96.png" class="step-icon" />
                <div>敬香</div>
              </div>
              <img src="https://img-fe.tengzhihh.com/image/86416c39ef0a6b-16x20.png" class="arrow" />
              <div class="step-item f fv fvc">
                <img src="https://img-fe.tengzhihh.com/image/6e78ac261f06b9-96x96.png" class="step-icon" />
                <div>获得功德</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <img src="https://img-fe.tengzhihh.com/image/1e033c3e04ba47-750x1558.png" class="bg" />
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { mapGetters, mapState } from 'vuex';
const factionList = [
  {
    faction: 2,
    name: '佛教',
    key: 'buddhism',
    active: 'https://img-fe.tengzhihh.com/image/2292d1eb6dc013-68x32.png',
    default: 'https://img-fe.tengzhihh.com/image/372daf59a4934d-68x32.png',
  },
  {
    faction: 1,
    name: '道教',
    key: 'taoism',
    active: 'https://img-fe.tengzhihh.com/image/3131185ee56356-68x32.png',
    default: 'https://img-fe.tengzhihh.com/image/a9810edf94150a-68x32.png',
  },
  {
    faction: 4,
    name: '寺庙联名',
    active: 'https://img-fe.tengzhihh.com/image/b319518e99042c-136x32.png',
    default: 'https://img-fe.tengzhihh.com/image/9db6fa35687ad3-136x32.png',
  },
  {
    faction: 5,
    name: '西方宗教',
    key: 'western',
    active: 'https://img-fe.tengzhihh.com/image/1132b98a0e67e9-136x32.png',
    default: 'https://img-fe.tengzhihh.com/image/891c45cae79ea4-204x48.png',
  },
];
export default {
  props: {
    serialId: {
      type: String,
      default: '',
    },
    godType: {
      type: [Number, String],
      default: 1,
    },
    religion: {
      type: String,
      default: '',
    },
    from: {
      type: String,
      default: '',
    },
    hasMasterWorship: {
      type: Boolean,
      default: false,
    },
    indexMode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      godList: [],
      dialogFlag: false,
      settingGodDetail: {
        wap_detail_image: '',
        name: '',
      },
      curFaction: 2,
      endY: 0,
      calcFactionList: [],
    };
  },
  mounted() {
    this.dialogFlag = false;
    this.getList();
  },
  computed: {
    ...mapState(['siMiaoLianMingGodGoodsId']),
    currentList: function () {
      if (this.godType == 1) {
        const tmp = this.godList.find(i => i.faction == this.curFaction);
        let result = this.$tools.sliceArr(tmp?.list, 2);
        return result;
      } else {
        let result = this.$tools.sliceArr(this.godList, 2);
        return result;
      }
    },
    showConfirmBtn: function () {
      return this.from === 'settings' && this.hasMasterWorship;
    },
  },
  methods: {
    onTouchMove(event) {
      this.endY = event.changedTouches[0].clientY;
    },
    onTouchEnd() {
      if (this.endY > 200) {
        this.dialogFlag = false;
        this.endY = 0;
      }
    },
    goGoods() {
      this.$router.push(
        `/shop/goodsDetail?id=${this.siMiaoLianMingGodGoodsId}&from=god_list&serial_id=${this.serialId}`
      );
    },
    back() {
      if (this.from === 'god_index') {
        this.$emit('setValue', 'pageStep', 'index');
      } else {
        this.$emit('goBack');
      }
    },
    changeFaction(targetFaction) {
      this.curFaction = targetFaction;
    },
    viewGodDetail(item) {
      this.$emit('setValue', 'godPreviewDetail', item);

      this.$emit('setValue', 'pageStep', 'detail');
    },
    openDialog(item) {
      this.settingGodDetail = item;
      this.dialogFlag = true;
    },

    confirm() {
      this.$emit('setModeAndReligionAndGod');
    },

    inviteGod() {
      // 初始化，需要一步直接设置，且不考虑是否已经设置
      if (['init_select'].includes(this.from)) {
        this.$emit('setModeAndReligionAndGod', this.settingGodDetail.id);
        return true;
      }
      // 设置页，不允许重复设置，但是调用setModeAndReligionAndGod
      if (['settings'].includes(this.from)) {
        if (this.settingGodDetail.is_invited) {
          return false;
        } else {
          this.$emit('setModeAndReligionAndGod', this.settingGodDetail.id);
          return true;
        }
      }
      // 供奉页面，不允许重复设置
      if (this.settingGodDetail.is_invited && ['god_index'].includes(this.from)) {
        return false;
      }
      this.$http
        .post(apiPath.inviteGod, {
          serial_id: this.serialId,
          god_id: this.settingGodDetail.id,
        })
        .then(res => {
          this.$toast('操作成功');

          this.$emit('setValue', 'pageStep', 'index');
          this.$emit('updateGodList', this.settingGodDetail.id);

          this.$track('H5_qingshen_success');
          this.dialogFlag = false;
        });
    },
    changeOfferings() {
      this.$emit('setValue', 'pageStep', 'offerings');
      this.$emit('setValue', 'offeringType', 'hua');
    },

    getList() {
      this.godType != 1 ? this.getEarthGodList() : this.getGodList();
    },

    getGodList() {
      const api = this.indexMode === 'western' ? apiPath.westernGodList : apiPath.religionGodList;

      this.$http
        .get(api, {
          serial_id: this.serialId,
          religion: this.religion,
        })
        .then(res => {
          this.godList = res.data;
          this.curFaction = res.data[0]?.faction;
          const tmp = this.godList.find(i => i.faction == this.curFaction);
          if (!tmp?.list.find(i => i.is_invited) || this.from === 'init_select') {
            this.$toast(this.indexMode === 'western' ? '请选择大天使' : '请选择神佛');
          }

          this.calcFactionList = res.data.map(i => {
            return factionList.find(j => i.faction === j.faction);
          });
        });
    },
    getEarthGodList() {
      const api = apiPath.earthGodList;
      this.$http
        .get(api, {
          serial_id: this.serialId,
        })
        .then(res => {
          this.godList = res.data[0].list;
          const list = this.godList;
          if (!list.find(i => i.is_invited) || this.from === 'init_select') {
            this.$toast('请选择神佛');
          }
          this.curFaction = res.data[0]?.faction;
        });
    },
    closeDialog() {
      this.settingGodDetail = null;
      this.dialogFlag = false;
    },
  },
};
</script>

<style lang="less" scoped>
.bg {
  position: fixed;
  width: 100%;
  height: calc(var(--vh) * 100 - 44px);
  left: 0;
  bottom: 0;
  z-index: -1;
}
.padding-44 {
  padding-top: 44px;
}
.god-box {
  position: relative;
  width: 100%;

  height: calc(var(--vh) * 100 - 44px);

  .btn-header {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    background-image: url('https://img-fe.tengzhihh.com/image/542f525d7ee85d-750x88.png');
    background-repeat: no-repeat;
    background-size: cover;
    .btns-box {
      width: 100%;
    }
    .back-icon {
      width: 24px;
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 123;
    }
    .faction-btn {
      width: 34px;
      height: 16px;
    }
    .big-btn {
      width: 68px !important;
    }
    .line {
      width: 30px;
      border-radius: 3px;
      height: 3px;
      background: #ff913e;
      margin-top: 5px;
    }
    .transparent {
      background: transparent !important;
    }
    .jump-btn {
      position: absolute;
      left: 10px;
      width: 30px;
      height: 30px;
    }
  }
  .confirm-btn {
    width: 300px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    color: #fff;
    margin: 30px 0;
    margin-left: -150px;
    position: fixed;
    top: calc(var(--vh) * 87);
    left: 50%;
    z-index: 100;
  }
  .empty-box {
    padding-top: 107px;
    .empty-icon {
      width: 138px;
      height: 138px;
    }
    .empty-tip {
      width: 106px;
      height: 16px;
      margin-top: 16px;
    }
    .empty-btn {
      width: 81px;
      height: 24px;
      margin-top: 16px;
    }
  }
  .group-item {
    position: relative;
    width: 100%;
    height: 259px;
    .group-bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .shadow {
    width: 375px;
  }
}
.body-item {
  width: 49%;
  z-index: 1;
  position: relative;
  .god-item-img {
    height: 186px;
    max-width: 100%;
    overflow: hidden;
  }
  .name-box {
    position: absolute;
    left: 82%;
    top: 50px;

    .name-img {
      width: 18px;
    }
  }

  .option-area {
    position: relative;
    top: 12px;
    .set-btn {
      display: block;
      width: 81px;
      height: 24px;
      margin: auto;
      margin-top: 6px;
    }

    .moral {
      text-align: center;
      color: #85531f;
      font-size: 14px;
    }
  }
}
.popup {
  width: 100%;
  background: #fff;
  border-radius: 10px 10px 0 0;
  .popup-box {
    background: #fff;
    border-radius: 15px;

    .content {
      overflow: scroll;
      padding: 20px 0 60px;
      width: 100%;
      box-sizing: border-box;
      max-height: calc(var(--vh) * 75);
      position: relative;
    }
    .god-img-box {
      position: relative;
      width: 138px;
      height: 166px;
      flex-shrink: 0;

      .god-item-img {
        position: absolute;
        width: 104px;
        left: 50%;
        margin-left: -52px;
        z-index: 2;
      }
      .bg {
        position: absolute;
        width: 138px;
        height: 138px;
        z-index: 1;
        top: 16px;
      }
    }

    .id-box {
      position: relative;
      width: 196px;
      height: 32px;
      margin-top: 16px;
      .id {
        font-size: 14px;
        line-height: 32px;
        color: #a35a26;
        position: absolute;
        width: 196px;
        top: 0;
        left: 50%;
        margin-left: -98px;
        text-align: center;
      }
      .id-bg-img {
        width: 196px;
        height: 32px;
      }
    }
    .name {
      font-size: 14px;
      color: #7b5439;
      margin-top: 8px;
    }
    .title-icon {
      width: 77px;
      height: 21px;
    }
    .desc {
      width: 335px;
      box-sizing: border-box;
      text-align: justify;
      padding: 20px 0;
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      .title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 14px;
        font-weight: bold;
      }
      .arrow {
        width: 8px;
        height: 10px;
        margin: 0 8px;
        position: relative;
        top: -14px;
      }
      .step {
        color: #996531;
        font-size: 14px;
        margin-top: 10px;

        .step-item {
          width: 80px;
        }
        .step-icon {
          width: 48px;
          height: 48px;
          margin-bottom: 12px;
        }
      }
    }
    .desc:last-child {
      border: none;
    }
    .confirm-btn {
      width: 299px;
      height: 44px;
      background: #f87a1b;
      border-radius: 10px 10px 10px 10px;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      margin: 20px 0;
      color: #fff;
    }
    .cancel-btn {
      background: #f6f6f6;
      color: rgba(0, 0, 0, 0.65);
    }

    .tip {
      font-size: 18px;
      color: #2e2d2d;
      margin-top: 10px;
    }

    .btn-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 22px 0 0;
      .cancel-btn {
        width: 100px;
        height: 40px;
        background: #f6f6f6;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 10px;
      }

      .confirm-btn {
        width: 100px;
        height: 40px;
        background: #f87a1b;
        border-radius: 10px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #ffeac9;
      }
    }
    .offering-select {
      .cancel-text {
        font-size: 16px;
        color: #f87a1b;
      }
      .confirm-btn {
        width: 228px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
