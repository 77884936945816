<template>
  <div class="card">
    <div class="number-status mb-16">
      <div class="goods-id f">订单编号:{{ description.order_center_id }}<span class="copy" @click="copy"></span></div>
      <div class="statusText">{{ orderStatusText[description.status] }}</div>
    </div>
    <div class="particulars mb-16 f" @click="cardClick">
      <van-image :src="description.goods_info && description.goods_info.image" class="good-img">
        <template v-slot:error>加载失败</template>
      </van-image>
      <div class="order-content">
        <div class="name-amount f mb-10">
          <p class="good-name">{{ description.goods_info.name }}</p>
          <p class="good-amount">¥&nbsp;{{ description.goods_info.original_amount }}</p>
        </div>
        <div class="stats-count f">
          <p class="good-desc">
            <template v-if="description.goods_info.category === 'virtual'">
              {{ description.serial_id }}
            </template>
            <template v-if="description.goods_info.category === 'entity' && Object.keys(description.sku).length">
              {{ description.sku.sku }}：{{ description.sku.sku_name }}
            </template>
          </p>
          <p class="good-count lh-20">x{{ description.goods_num }}</p>
        </div>
      </div>
    </div>
    <div class="pay-amount">
      实付款<span class="amount-count">&nbsp;¥&nbsp;{{ description.amount }}</span>
    </div>
    <div class="handle" v-if="handleList.length">
      <button v-for="item in handleList" :key="item.name" class="handle-button" @click="handleClick(item)">
        {{ item.label }}
      </button>
    </div>
  </div>
</template>

<script>
import { orderStatusText, orderCategory } from '@/enum/shop.js';
export default {
  props: {
    handleList: {
      type: Array,
      default: () => [],
    },
    description: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      orderStatusText: Object.freeze(orderStatusText),
      orderCategory: Object.freeze(orderCategory),
    };
  },
  methods: {
    handleClick(btn) {
      this.$emit('handleClick', btn, this.description);
    },
    cardClick() {
      this.$emit('cardClick', this.description);
    },
    copy() {
      this.$emit('copy', this.description);
    },
  },
};
</script>

<style lang="less" scoped>
.card {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 16px 12px;
  border-radius: 10px 10px 10px 10px;
  .mb-16 {
    margin-bottom: 16px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .lh-20 {
    line-height: 20px;
  }
  .number-status {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .goods-id {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      .copy {
        position: relative;
        background: #fff;
        width: 8px;
        height: 8px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.65);
        border-radius: 2px;
        margin-left: 8px;
        top: 0px;
      }
      .copy::after {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        left: -4px;
        top: 2px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.65);
        background: #fff;
        box-sizing: border-box;
      }
    }
    .statusText {
      font-size: 14px;
      font-weight: 400;
      color: #f87a1b;
    }
  }
  .particulars {
    font-size: 14px;
    width: 100%;
    .good-img {
      width: 90px;
      height: 90px;
      margin-right: 16px;
    }
    .order-content {
      width: calc(100% - 106px);
      .name-amount {
        width: 100%;
        justify-content: space-between;
        .good-name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 22px;
        }
        .good-amount {
          margin-left: 5px;
          white-space: nowrap;
          line-height: 22px;
        }
      }
      .stats-count {
        color: rgba(0, 0, 0, 0.45);
        justify-content: space-between;
        width: 100%;
        .good-desc {
          flex: 1;
          margin-right: 5px;
        }
      }
    }
  }
  .pay-amount {
    text-align: right;
    font-size: 14px;
    .amount-count {
      color: #f87a1b;
    }
  }
  .handle {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
    .handle-button {
      font-size: 14px;
      width: 74px;
      height: 30px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 10px 10px 10px 10px;
      background-color: #fff;
      margin-right: 10px;
    }
    .handle-button:last-child {
      color: #f87a1b;
      border-color: #f87a1b;
      margin: 0;
    }
  }
}
</style>
