var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("cell-group", { attrs: { list: _vm.settingList } }),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "f fv fc dialog-box" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("注销账号")]),
              _c("van-field", {
                staticClass: "input-item f fc",
                attrs: {
                  center: "",
                  "max-length": "6",
                  clearable: "",
                  placeholder: "请输入短信验证码",
                },
                scopedSlots: _vm._u([
                  {
                    key: "button",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "tip", on: { click: _vm.getCode } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.codeSentStatus ? _vm.calcTime : "发送验证码"
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _c("div", { staticClass: "f fc frd btn-box" }, [
                _c(
                  "div",
                  { staticClass: "btn cancel", on: { click: _vm.closeDialog } },
                  [_vm._v("取消")]
                ),
                _c(
                  "div",
                  { staticClass: "btn", on: { click: _vm.confirmClick } },
                  [_vm._v("确定")]
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }