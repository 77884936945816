<template>
  <van-popup class="confirmOrder" position="bottom" round v-model="confirmPop" @close="confirmPopFn">
    <div class="confirmTitle">确认收到货了吗</div>
    <div class="confirmDetail f">
      <div class="good-img">
        <van-image :src="confirmDetail.goods_info && confirmDetail.goods_info.image">
          <template v-slot:error>加载失败</template>
        </van-image>
      </div>
      <div class="order-content">
        <div class="f fbt" v-if="confirmDetail.goods_info">
          <p class="good-name">{{ confirmDetail.goods_info.name }}</p>
          <p class="good-amount">¥&nbsp;{{ confirmDetail.goods_info.original_amount }}</p>
        </div>
        <div class="f fbt" v-if="confirmDetail.goods_info">
          <div style="flex: 1">
            <template v-if="confirmDetail.goods_info.category === 'virtual'">
              <p class="stats-count">{{ confirmDetail.serial_id }}</p>
            </template>
            <template v-if="confirmDetail.goods_info.category === 'entity' && Object.values(confirmDetail.sku).length">
              <p class="stats-count">{{ confirmDetail.sku.sku }}：{{ confirmDetail.sku.sku_name }}</p>
            </template>
          </div>
          <p class="stats-count">x{{ confirmDetail.goods_num }}</p>
        </div>
        <div class="status-total f fbt">
          <p class="status">{{ orderStatusText[confirmDetail.status] }}</p>
          <p class="total f"><span class="black">实付款</span>&nbsp;¥&nbsp;{{ confirmDetail.amount }}</p>
        </div>
      </div>
    </div>
    <div>
      <van-button :loading="confirmLoading" block color="#F87A1B" @click="receiveConfirmFn">确认收货</van-button>
    </div>
  </van-popup>
</template>

<script>
import { orderList, receiveConfirm } from '@/api/order';
import { orderStatusText } from '@/enum/shop.js';
export default {
  data() {
    return {
      confirmPop: false,
      confirmId: '',
      confirmLoading: false,
      confirmDetail: {},
      orderStatusText: Object.freeze(orderStatusText),
    };
  },
  methods: {
    openPop(detail) {
      this.confirmPop = true;
      this.confirmId = detail.id;
      this.confirmDetail = detail;
    },
    confirmPopFn() {
      this.confirmPop = false;
      this.confirmId = '';
      this.confirmDetail = {};
    },
    receiveConfirmFn() {
      receiveConfirm({ order_id: this.confirmId });
      this.confirmPopFn();
      this.$emit('receiveConfirm');
    },
  },
};
</script>

<style lang="less" scoped>
.confirmOrder {
  padding: 30px 15px 22px;
  box-sizing: border-box;
  .confirmTitle {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    margin-bottom: 16px;
  }
  .confirmDetail {
    margin-bottom: 23px;
    .good-img {
      width: 90px;
      height: auto;
      border-radius: 10px 0px 0px 10px;
      overflow: hidden;
      display: flex;
      img {
        width: 90px;
      }
    }
    .order-content {
      flex: 1;
      background: #f5f5f5;
      border-radius: 0px 10px 10px 0px;
      padding: 11px 12px 10px 17px;
      font-size: 14px;
      .good-name {
        line-height: 22px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
      }
      .good-amout {
        line-height: 22px;
        font-weight: 500;
        color: #000000;
      }
      .stats-count {
        line-height: 22px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
      }
      .status {
        line-height: 22px;
        font-weight: 400;
        color: #f87a1b;
      }
      .total {
        line-height: 22px;
        font-weight: 400;
        color: #f87a1b;
        .black {
          color: #000;
        }
      }
    }
  }
}
</style>
