var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.currentStep === 0
        ? _c("AddChoose", {
            attrs: { serialId: _vm.serialId, subVolume: _vm.subVolume },
            on: { setValue: _vm.setValue },
          })
        : _vm.currentStep === 1
        ? _c("VideoPreview", {
            attrs: { serialId: _vm.serialId, mottoType: _vm.mottoType },
            on: { setValue: _vm.setValue },
          })
        : _vm.currentStep == 2
        ? _c("EditDetail", {
            attrs: {
              serialId: _vm.serialId,
              mottoType: _vm.mottoType,
              fileName: _vm.fileName,
              duration: _vm.duration,
            },
            on: { setValue: _vm.setValue },
          })
        : _vm._e(),
      _c("van-popup", { attrs: { value: _vm.confirmFlag } }, [
        _c("div", { staticClass: "confirm-box f fc fv" }, [
          _c("p", { staticClass: "tip" }, [
            _vm._v("返回将不保留填写好的信息内容"),
          ]),
          _c(
            "div",
            { staticClass: "btn-box f fc fbt" },
            [
              _c(
                "van-button",
                { staticClass: "btn", on: { click: _vm.confirmBack } },
                [_vm._v("返回")]
              ),
              _c(
                "van-button",
                {
                  staticClass: "btn cancel",
                  on: {
                    click: function ($event) {
                      _vm.confirmFlag = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }