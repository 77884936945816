<template>
  <div class="w-full fvc">
    <div v-for="{ name, label } in list" :key="name" class="tab" @click="tabClick({ name, label })">
      <div :style="{ color: active === name ? activeColor : 'rgba(0, 0, 0, 0.85)' }" class="label">{{ label }}</div>
      <div class="block" v-show="active === name"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    activeColor: {
      type: String,
      default: '#f87a1b',
    },
  },
  data() {
    return {
      active: '',
    };
  },
  created() {
    this.active = this.$route.name;
  },
  methods: {
    tabClick({ name, label }) {
      this.$emit('tabClick', { name, label });
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  height: 22px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin: 11px 22px;
  .block {
    width: calc(100% - 20px);
    height: 2px;
    background: #f87a1b;
    margin: 5px auto 0;
  }
}
</style>
