var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-index-container" },
    [
      _c("div", { staticClass: "my-index-header f fc" }, [
        _c("div", { staticClass: "my-avatar f fc fvc" }, [
          _vm.userInfo.avatar
            ? _c("img", {
                attrs: { src: _vm.$tools.compressImg(_vm.userInfo.avatar, 70) },
              })
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "f fc fbt",
            staticStyle: { flex: "1" },
            on: { click: _vm.jumptoInfo },
          },
          [
            _c("div", [
              _c("div", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(_vm.userInfo.name)),
                _vm.userInfo.is_vip
                  ? _c("img", {
                      staticClass: "vip-icon",
                      attrs: { src: _vm.userInfo.vip_info.vip_icon },
                    })
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "client" }, [
                _vm._v(_vm._s(_vm.devicesNumber) + "台设备"),
              ]),
            ]),
            _c(
              "div",
              [
                _c("van-icon", {
                  staticClass: "right-arrow-icon",
                  attrs: { name: "arrow" },
                }),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.userInfo.is_vip
        ? _c(
            "div",
            { staticClass: "vip-btn", on: { click: _vm.goVipPrivilege } },
            [
              _c("img", {
                staticClass: "vip-bg-img",
                attrs: {
                  src: "https://fe-img.tengzhihh.com/image/5491d4c23c742fcaacba42fc8e444908-690x80.png",
                  alt: "",
                },
              }),
              _c("img", {
                staticClass: "arrow",
                attrs: {
                  src: "https://fe-img.tengzhihh.com/image/c4a4c70b74e1071f1318837ab62e7656-33x32.png",
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "tool-box f fv fc" }, [
        _c("img", {
          staticClass: "top-1",
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/29ac80cda9f24b-648x158.png",
          },
          on: { click: _vm.goCalendar },
        }),
      ]),
      _c(
        "div",
        { staticClass: "cell-box" },
        [
          _c("grid-list", {
            attrs: { list: _vm.orderList },
            on: { click: _vm.gridClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "cell-box" },
        [_c("cell-group", { attrs: { list: _vm.settingList } })],
        1
      ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }