var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasGift
    ? _c(
        "div",
        [
          _vm.btnShow
            ? _c("img", {
                staticClass: "float-btn",
                style: { ..._vm.position },
                attrs: {
                  src: "https://fe-img.tengzhihh.com/image/0749e5b222830d8694fbe461618bb7cd-112x112.png",
                },
                on: { click: _vm.handleBtnClick },
              })
            : _vm._e(),
          _c(
            "van-popup",
            {
              staticClass: "gongde-gift",
              on: { closed: _vm.onPopupClose },
              model: {
                value: _vm.popupShow,
                callback: function ($$v) {
                  _vm.popupShow = $$v
                },
                expression: "popupShow",
              },
            },
            [
              _c("img", {
                staticClass: "gongde-gift-btn",
                attrs: {
                  src: "https://fe-img.tengzhihh.com/image/89ae660b7f7ed098462fe3ec14574185-380x80.png",
                },
                on: { click: _vm.handleGoFudeGift },
              }),
              _c("van-icon", {
                staticClass: "close-icon",
                attrs: { name: "close" },
                on: {
                  click: function ($event) {
                    _vm.popupShow = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }