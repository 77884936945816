var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full fvc" },
    _vm._l(_vm.list, function ({ name, label }) {
      return _c(
        "div",
        {
          key: name,
          staticClass: "tab",
          on: {
            click: function ($event) {
              return _vm.tabClick({ name, label })
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "label",
              style: {
                color:
                  _vm.active === name ? _vm.activeColor : "rgba(0, 0, 0, 0.85)",
              },
            },
            [_vm._v(_vm._s(label))]
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.active === name,
                expression: "active === name",
              },
            ],
            staticClass: "block",
          }),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }