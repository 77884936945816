var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "god-index-container" } },
    [
      _vm.pageStep === "index"
        ? _c(
            "div",
            [
              _c("div", { staticClass: "index-top f fc fbt" }, [
                _c("img", {
                  staticClass: "top-icon",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/5e5eeee8268676-56x56.png",
                  },
                  on: { click: _vm.goDevice },
                }),
                _c(
                  "div",
                  { class: `index-top-box f fc fvc ${_vm.indexTopBoxClss}` },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        on: {
                          click: function ($event) {
                            return _vm.$utils.checkAuth(
                              _vm.routeQuery.serial_id,
                              _vm.inviteGod
                            )
                          },
                        },
                      },
                      [_vm._v("恭请神明")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn",
                        on: { click: _vm.enshrineSetting },
                      },
                      [_vm._v("供奉设置")]
                    ),
                  ]
                ),
                _c("img", {
                  staticClass: "top-icon",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/01875a0e427436-56x56.png",
                  },
                  on: { click: _vm.showExplanation },
                }),
              ]),
              _c("img", {
                staticClass: "god-bg",
                attrs: {
                  src:
                    _vm.currentGodInfo.wap_background_image ||
                    _vm.default_img.wap_background_image,
                },
              }),
              _vm.currentGodInfo.left_golden_flag_image
                ? _c("div", { staticClass: "flags-box f fc fbt" }, [
                    _c("img", {
                      staticClass: "flag-item left",
                      attrs: { src: _vm.currentGodInfo.left_golden_flag_image },
                    }),
                    _c("img", {
                      staticClass: "flag-item right",
                      attrs: {
                        src: _vm.currentGodInfo.right_golden_flag_image,
                      },
                    }),
                  ])
                : _vm._e(),
              _c(
                "swiper",
                {
                  ref: "godSwiper",
                  staticClass: "swiper",
                  attrs: { options: _vm.swiperOption },
                },
                [
                  _vm._l(_vm.godList, function (item) {
                    return _c(
                      "swiper-slide",
                      { key: item.id },
                      [
                        _c("GodIndex", {
                          attrs: {
                            godType: _vm.godType,
                            serialId: _vm.routeQuery.serial_id,
                            godData: item.godData,
                            godId: item.god_id,
                            emptyIndex: !!item.emptyIndex,
                            indexMode: _vm.indexMode,
                            religion: _vm.religion,
                            activeIndex: _vm.activeIndex,
                            defaultImg: item.default_img,
                          },
                          on: {
                            setValue: _vm.setValue,
                            openPopup: _vm.openPopup,
                            updateGodList: _vm.updateGodList,
                            updateIndex: _vm.updateIndex,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination",
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm.pageStep === "list"
        ? _c(
            "div",
            [
              _c("GodList", {
                attrs: {
                  godType: _vm.godType,
                  serialId: _vm.routeQuery.serial_id,
                  indexMode: _vm.indexMode,
                  religion: _vm.religion,
                  from: "god_index",
                },
                on: {
                  setValue: _vm.setValue,
                  updateGodList: _vm.updateGodList,
                },
              }),
            ],
            1
          )
        : _vm.pageStep === "offerings"
        ? _c(
            "div",
            [
              _c("OfferingsList", {
                ref: "OfferingsListRef",
                attrs: {
                  godType: _vm.godType,
                  serialId: _vm.routeQuery.serial_id,
                  godId: _vm.currentGodInfo.id,
                  indexMode: _vm.indexMode,
                  from: "godIndex",
                },
                on: {
                  setValue: _vm.setValue,
                  updateGodInfo: _vm.updateGodInfo,
                },
              }),
            ],
            1
          )
        : _vm.pageStep === "detail"
        ? _c(
            "div",
            [
              _c("GodDetail", {
                attrs: { godDetail: _vm.godPreviewDetail, from: "godIndex" },
                on: { setValue: _vm.setValue },
              }),
            ],
            1
          )
        : _vm.pageStep === "explanation"
        ? _c("div", [_c("Explanation", { on: { setValue: _vm.setValue } })], 1)
        : _vm._e(),
      _vm.showGuide && _vm.pageStep === "index"
        ? _c("Guide", { ref: "guideRef", on: { setValue: _vm.setValue } })
        : _vm._e(),
      _c("SendGod", {
        ref: "SendGodRef",
        attrs: {
          serialId: _vm.routeQuery.serial_id,
          godInfo: _vm.currentGodInfo,
        },
        on: { updateGodList: _vm.updateGodList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }