var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.detail
    ? _c("div", { staticClass: "page-box f fv" }, [
        _c("img", {
          staticClass: "back",
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/c7cfa932707506-18x35.png",
          },
          on: { click: _vm.back },
        }),
        _c("img", { staticClass: "bg", attrs: { src: _vm.calcBg } }),
        _c("div", { staticClass: "content-box" }, [
          _c("img", {
            staticClass: "content-bg",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/1353f85b5bf45a-672x950.png",
            },
          }),
          _c("div", { staticClass: "title-box" }, [
            _c("img", {
              staticClass: "title-bg",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/3015fb81a1713d-269x58.png",
              },
            }),
            _c("div", [_vm._v(_vm._s(_vm.detail.name) + "介绍")]),
          ]),
          _c("p", { domProps: { innerHTML: _vm._s(_vm.detail.introduction) } }),
        ]),
        _c("div", { staticClass: "confirm-btn", on: { click: _vm.start } }, [
          _vm._v("开始功课"),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }