var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "xiangxun-gift-container" },
    [
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.giftInfo.title))]),
      _vm.giftInfo.gift
        ? _c("img", {
            staticClass: "gift-img",
            attrs: { src: _vm.giftInfo.gift.img, alt: "" },
          })
        : _vm._e(),
      _c("div", { staticClass: "time" }, [
        _vm._v(_vm._s(_vm.giftInfo.can_receive_num) + " 次"),
      ]),
      _c(
        "div",
        {
          class: ["btn", { disabled: _vm.giftInfo.can_receive_num == 0 }],
          on: { click: _vm.handleClick },
        },
        [_vm._v("兑换")]
      ),
      _c("div", { staticClass: "sub-title" }, [_vm._v("兑换历史")]),
      _c(
        "div",
        { staticClass: "history" },
        [
          _vm.giftInfo.list
            ? [
                _vm.giftInfo.list.length == 0
                  ? _c("div", { staticClass: "empty" }, [
                      _vm._v("暂无兑换记录"),
                    ])
                  : _vm._e(),
                _vm._l(_vm.giftInfo.list, function (item) {
                  return _c(
                    "div",
                    {
                      staticClass: "history-item",
                      on: {
                        click: function ($event) {
                          return _vm.handleItemClick(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "user-info" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.user_name)),
                        ]),
                        _vm._v(" " + _vm._s(item.phone) + " "),
                      ]),
                      _c("div", { staticClass: "address" }, [
                        _vm._v(_vm._s(item.address)),
                      ]),
                      _c("div", { staticClass: "gift-info" }, [
                        _vm._v(
                          "兑换产品：" +
                            _vm._s(item.gift_name) +
                            "×" +
                            _vm._s(item.num)
                        ),
                      ]),
                    ]
                  )
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("PopupForm", {
        ref: "form",
        attrs: { info: _vm.giftInfo },
        on: { submit: _vm.getGift },
      }),
      _c("PopupDetails", { ref: "details" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }