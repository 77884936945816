<template>
  <div class="page-box">
    <img src="https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png" class="bg" />

    <div class="top f fc">
      <img src="https://img-fe.tengzhihh.com/image/c7cfa932707506-18x35.png" class="back" @click="goBack" />
      <div class="title">{{ calcTitle }}</div>
    </div>
    <div class="tab-list f fc frd">
      <div
        v-for="(item, idx) in tabList"
        :class="`tab-item f fv fc ${currentTab === item.title ? 'current' : ''}`"
        :key="idx"
        @click="clickTab(item)"
      >
        {{ item.title }}
        <div :class="`line  ${currentTab === item.title ? 'current-line' : ''}`"></div>
      </div>
    </div>
    <div class="list-box">
      <div v-for="(item, idx) in calcList" :key="idx" class="item f fc" @click="view(item.url)">
        <div class="icon-box"><img :src="item.cover_image" class="icon" /></div>
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import GoBack from '@/components/GoBack/GoBack.vue';
const titleMap = {
  family_tradition: '上传家风家训',
  buddhist_culture: '佛教文化',
  geomancy: '风水习俗',
  taoist_culture: '道教文化',
};
export default {
  components: { GoBack },
  data() {
    return {
      serialId: '',
      type: '',
      list: [],
      pageType: 'typeList',
      currentTab: '',
    };
  },
  created() {
    this.serialId = this.$route?.query?.serial_id;
    this.type = this.$route?.query?.type;
    this.$tools.setDocumentTitle(titleMap[this.type]);
  },
  computed: {
    tabList: function () {
      return this.list.map(i => {
        return {
          title: i.title,
          image: i.image,
        };
      });
    },
    calcList: function () {
      return this.list.find(i => i.title === this.currentTab)?.items || [];
    },
    calcTitle: function () {
      const map = {
        taoist_culture: '道教文化',
        buddhist_culture: '佛教文化',
        geomancy: '风水知识',
        western_religious_culture: '西方宗教文化',
      };

      return map[this.type] || '文化科普';
    },
  },
  mounted() {
    this.getList();
  },

  methods: {
    clickTab(item) {
      localStorage.setItem('sub_list_title', item.title);
      this.currentTab = item.title;
    },
    getList() {
      this.$http
        .get(apiPath.eduList, {
          type: this.type,
        })
        .then(res => {
          this.list = res.data.list.map((i, idx) => {
            return {
              ...i,
              bgColor: idx === 0 ? '#F7DBB9' : idx === 1 ? '#F2EDD6' : '#F7E6B8',
            };
          });
          const subListTitle = localStorage.getItem('sub_list_title');
          const index = subListTitle ? res.data.list.findIndex(i => i.title === subListTitle) : -1;
          this.currentTab = res.data?.list[index !== -1 ? index : 0]?.title;
        });
    },
    viewType(item) {
      this.currentTab = item.title;
      this.pageType = 'articleList';
    },
    goBack() {
      this.$router.go(-1);
    },
    view(url) {
      location.href = url;
    },
  },
};
</script>

<style lang="less" scoped>
.type-box {
  width: 100%;
  box-sizing: border-box;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }
  .top {
    position: relative;
    padding: 20px;
    .back {
      width: 9px;
      height: 17px;
      position: absolute;
      left: 20px;
    }
    .title {
      color: #9e511c;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
  }

  .list-box {
    padding: 0 10px;
    .item {
      width: 343px;
      height: 89px;
      background: #f7dbb9;
      border-radius: 15px;
      padding: 0 28px;
      box-sizing: border-box;
      margin-bottom: 20px;
      color: #7b5439;
      font-size: 18px;
      .icon {
        width: 65px;
        height: 65px;
        margin-right: 47px;
      }
      .title {
        color: #7b5439;
        font-size: 18px;
      }
    }
  }
}
.page-box {
  width: 100%;
  box-sizing: border-box;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }
  .top {
    position: relative;
    padding: 20px;
    .back {
      width: 9px;
      height: 17px;
      position: absolute;
      left: 20px;
    }
    .title {
      color: #9e511c;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      width: 100%;
    }
  }
  .tab-list {
    width: 375px;
    height: 44px;
    color: rgba(158, 81, 28, 0.8);
    font-size: 14px;
    background: #eecba1;
    margin-bottom: 20px;
    .tab-item {
      position: relative;
      top: 4px;
    }
    .current {
      font-weight: bold;
      color: #9e511c;
    }
    .current-line {
      background: #9e511c !important;
    }
    .line {
      width: 38px;
      height: 3px;
      border-radius: 16px;
      background: transparent;
      margin-top: 6px;
    }
  }

  .list-box {
    padding: 0 10px;
    height: calc(var(--vh) * 100 - 130px);
    overflow: auto;
    .item {
      width: 343px;
      height: 100px;
      background: #f7dbb9;
      border-radius: 15px;
      padding: 0 10px;
      box-sizing: border-box;
      margin: 0 auto 20px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 6px;
      overflow: hidden;
      .icon-box {
        width: 135px;
        height: 84px;
        margin-right: 10px;
        .icon {
          width: 135px;
          height: 84px;
        }
      }
      .title {
        color: #7b5439;
        font-size: 18px;
      }
    }
  }
}
</style>
