var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.list.length === 0
    ? _c("div", { staticClass: "empty-list f fc fvc" }, [_vm._v("暂无内容")])
    : _c(
        "div",
        { staticClass: "list-box f fbt", attrs: { id: "goods-list" } },
        [
          _c(
            "div",
            { staticClass: "left" },
            _vm._l(_vm.leftList, function (item, index) {
              return _c("list-item", { key: index, attrs: { item: item } })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            _vm._l(_vm.rightList, function (item, index) {
              return _c("list-item", { key: index, attrs: { item: item } })
            }),
            1
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }