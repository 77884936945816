var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container f fv fc",
      attrs: { id: "god-index-explanation" },
      on: { scroll: _vm.scrollBottomCheck },
    },
    [
      _c("GoBack", {
        attrs: {
          backIcon:
            "https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png",
          backFunction: _vm.backFunction,
        },
      }),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "list" }, [
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("请神说明")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            " 请神像或者佛像回家需知，宜摆放于静室高处，大厅或者书房，不可面对厕所、炉灶和卧床。佛像背后不可临窗，应当面对门窗，光线充足，使人进门便一目了然。如果家中地方有限，可以先妥善存放于清净处，做功课时再请出来。 "
          ),
        ]),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("供品说明")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            '"供奉"是指针对当前页面供奉的神明，分别进行请香、献花、供果、供灯动作。'
          ),
        ]),
        _c("div", { staticClass: "content" }, [
          _c("br"),
          _c("br"),
          _vm._v(
            ' "一键供奉"是指一键点击为所有正在供奉的神明上供品。方便缘主一键完成上供香、花、果、灯给所有神明的操作。一键供奉不会替换当前正在供奉的贡品。 '
          ),
        ]),
        _c("img", {
          staticClass: "img",
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/5c4959e22ea868c5fef4729770dff981-622x758.png",
          },
        }),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("“供奉”与“一键供奉”说明")]),
        _c("div", { staticClass: "content" }, [
          _vm._v("供品会在请香，上香12小时后送予神/佛，届时可重新上新供品。"),
        ]),
        _c("img", {
          staticClass: "img",
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/3636426656a562-622x724.png",
          },
        }),
      ]),
      _c("div", { staticClass: "item" }, [
        _c("div", { staticClass: "title" }, [_vm._v("送神回天")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            " 在送走的前一天要焚香上供，诚心的说明送走的原因。确定送神上天后，意味着你不再向此神明祈愿供奉，将在24小时内无法上供品，24小时后自动送神上天，累计的供奉天数和香火值也会消失。 "
          ),
        ]),
        _c("img", {
          staticClass: "img",
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/6f22ca9141d8c4-622x728.png",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }