var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      style: { width: "90%", left: "5%", borderRadius: "14px", bottom: "20px" },
      attrs: { position: "bottom" },
      on: {
        click: function ($event) {
          _vm.musicListFlag = false
        },
      },
      model: {
        value: _vm.musicListFlag,
        callback: function ($$v) {
          _vm.musicListFlag = $$v
        },
        expression: "musicListFlag",
      },
    },
    [
      _c("div", { staticClass: "music-list-box" }, [
        _c("div", { staticClass: "header f fc fbt" }, [
          _vm.from === "music"
            ? _c(
                "div",
                {
                  staticClass: "f fc",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.changeCurrentPlayingMode.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "playing-mode",
                    attrs: { src: _vm.playingModeIcon.gray },
                  }),
                  _c("div", { staticStyle: { "font-size": "14px" } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.currentPlayingMode === 0
                            ? "顺序模式"
                            : _vm.currentPlayingMode === 1
                            ? "随机模式"
                            : "单曲循环"
                        ) +
                        " "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "body" },
          _vm._l(_vm.currentPlayingList, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "music-item f fc fbt",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.change(item)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    class: `f fc ${
                      item.id === _vm.currentMusic.id ? "current" : ""
                    }`,
                  },
                  [
                    _vm._v(" " + _vm._s(item.name) + " "),
                    item.id === _vm.currentMusic.id && _vm.isPlaying
                      ? _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm.from === "music"
                  ? _c("div", [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.id != _vm.currentMusic.id,
                            expression: "item.id != currentMusic.id",
                          },
                        ],
                        staticClass: "close-icon",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/aa923ae4966745-18x19.png",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.removeMusicFromList(item, index)
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }