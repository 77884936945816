var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { position: "bottom", round: "" },
      on: { close: _vm.cancelPopFn },
      model: {
        value: _vm.cancelPop,
        callback: function ($$v) {
          _vm.cancelPop = $$v
        },
        expression: "cancelPop",
      },
    },
    [
      _c("div", { staticClass: "cancelTitle" }, [_vm._v("取消订单")]),
      _c("div", { staticClass: "cancelSub" }, [_vm._v("请选择取消订单原因")]),
      _c(
        "div",
        { staticClass: "cancelReasons" },
        [
          _c(
            "van-radio-group",
            {
              model: {
                value: _vm.cancelReasonId,
                callback: function ($$v) {
                  _vm.cancelReasonId = $$v
                },
                expression: "cancelReasonId",
              },
            },
            [
              _c(
                "van-cell-group",
                _vm._l(_vm.cancelReasons, function ({ value, label }) {
                  return _c("van-cell", {
                    key: value,
                    attrs: { title: label },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "right-icon",
                          fn: function () {
                            return [_c("van-radio", { attrs: { name: value } })]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "popHandle f fbt" },
        [
          _c(
            "van-button",
            { attrs: { type: "default" }, on: { click: _vm.cancelPopFn } },
            [_vm._v("暂不取消")]
          ),
          _c(
            "van-button",
            {
              attrs: { loading: _vm.cancelLoading, color: "#F87A1B" },
              on: { click: _vm.cancelOrder },
            },
            [_vm._v("确定取消")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }