<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>
  </div>
</template>

<script>
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import { getDeceaseShow, getMachineColor } from '@/api/setting';
export default {
  components: { CellGroup },
  data() {
    return {
      serialId: '',
      decease_show: '',
      machine_color: '',
    };
  },
  computed: {
    settingList: function () {
      return [
        {
          title: '神柜外观',
          isLink: true,
          path: `/settings/cabinetAppearance?serial_id=${this.serialId}`,
          value: this.machine_color,
          cb: () => {
            this.$track('H5_setting_waiguan_click');
          },
        },
        {
          title: '逝者牌位',
          isLink: true,
          path: `/settings/ancestorMemorial?serial_id=${this.serialId}`,
          value: this.decease_show,
          cb: () => {
            this.$track('H5_setting_zuxian_click');
          },
        },
      ];
    },
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.$utils.getGeneralConfig(this.serialId);
    this.getDeceaseShow();
    this.getMachineColor();
  },
  methods: {
    async getDeceaseShow() {
      const res = await getDeceaseShow({
        serial_id: this.serialId,
      });
      this.decease_show = res.data.list.find(x => x.id === res.data.decease_show).name;
    },
    async getMachineColor() {
      const res = await getMachineColor({
        serial_id: this.serialId,
      });
      this.machine_color = res.data.list.find(x => x.id === res.data.machine_color).name;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
</style>
