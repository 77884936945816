<template>
  <div
    :class="`playing-page ${from === 'lesson' ? 'lesson-page' : ''} `"
    :style="calcStyle"
    @click="closePlayingMusicList"
  >
    <div class="detail-box">
      <div class="">
        <GoBack :backFunction="backFunction" />
        <img
          v-if="from === 'lesson'"
          src="https://img-fe.tengzhihh.com/image/e8d4b3316fbe14-104x56.png"
          class="setting"
          @click="$utils.checkAuth(serialId, edit)"
        />
      </div>
      <div class="detail-content f fv fc" v-if="from === 'music'">
        <div class="detail-name">
          {{ currentMusic.name }}
        </div>
        <div class="main-box">
          <img
            src="https://img-fe.tengzhihh.com/image/733201b554ac4c-202x316.png"
            :class="`rod-img
            ${isPlaying ? 'playing-rod-img' : ''}`"
          />
          <img
            :src="
              indexMode === 'western'
                ? 'https://img-fe.tengzhihh.com/image/0089b3e3a27570-546x556.jpg'
                : 'https://img-fe.tengzhihh.com/image/de16a894ccd581-580x580.png'
            "
            class="main-img"
          />
          <div class="img-bg"></div>
        </div>
      </div>

      <div class="lesson-content f fv fc" v-if="from === 'lesson' && indexMode !== 'western'">
        <div v-show="showLyric" class="lyric-box">
          <div v-for="(item, idx) in calcCurrentLyrics" :key="item.key">
            {{ item && item.text }}
          </div>
        </div>
      </div>
    </div>
    <div class="player-box f fc fbt">
      <div class="time">
        {{ $tools.secondToMinute(currentPosition) }}
      </div>

      <PlayProgress
        :musicId="currentMusic.id"
        :currentRate="currentRate"
        :currentPosition="currentPosition"
        @currentPositionChange="currentPositionChange"
      ></PlayProgress>
      <div class="time">
        {{ $tools.secondToMinute(totalValue) }}
      </div>
    </div>
    <img
      v-if="from === 'lesson' && indexMode !== 'western'"
      src="https://img-fe.tengzhihh.com/image/18d86492bbe5e3-60x60.png"
      class="up-voice-icon"
      @click="showVoiceControl"
    />

    <div class="bottom-area">
      <div class="options-area f fc frd" v-if="from === 'music'">
        <div class="playing-mode">
          <img :src="playingModeIcon.white" class="playing-mode" @click.stop.prevent="changeCurrentPlayingMode" />
        </div>

        <div @click.stop.prevent="previous">
          <img src="https://img-fe.tengzhihh.com/image/703e802d70b22e-50x50.png" class="control-btn" />
        </div>
        <div @click.stop.prevent="playControl">
          <img
            src="https://img-fe.tengzhihh.com/image/6b245d44d0fdcb-100x100.png"
            v-show="!isPlaying"
            class="play-btn"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/2d53e0d9bf52bc-100x100.png"
            v-show="isPlaying"
            class="play-btn"
          />
        </div>

        <div @click.stop.prevent="next">
          <img src="https://img-fe.tengzhihh.com/image/727fe638c481d9-50x50.png" class="control-btn" />
        </div>
        <div @click.stop.prevent="showMusicList">
          <img src="https://img-fe.tengzhihh.com/image/1f29133e544a14-37x37.png" class="list-icon" />
        </div>
      </div>

      <div class="options-area f fc" v-else>
        <div v-if="indexMode !== 'western'" :class="`lyric-btn ${showLyric ? 'show' : ''}`" @click="changeShowLyric">
          {{ showLyric ? '关闭' : '显示' }}经文
        </div>
        <img
          v-else
          src="https://img-fe.tengzhihh.com/image/a3b8994630d348-60x60.png"
          class="down-voice-icon"
          @click="showVoiceControl"
        />

        <div @click.stop.prevent="playControl">
          <img
            src="https://img-fe.tengzhihh.com/image/6b245d44d0fdcb-100x100.png"
            v-show="!isPlaying"
            class="play-btn lesson-play"
          />
          <img
            src="https://img-fe.tengzhihh.com/image/2d53e0d9bf52bc-100x100.png"
            v-show="isPlaying"
            class="play-btn lesson-play"
          />
        </div>

        <div @click.stop.prevent="showMusicList">
          <img src="https://img-fe.tengzhihh.com/image/1f29133e544a14-37x37.png" class="list-icon" />
        </div>
      </div>
    </div>

    <van-popover
      v-model="timeoutPopover"
      class="time-set-box"
      v-if="actions.length > 0 && from === 'music'"
      trigger="click"
      placement="top-start"
      :actions="actions"
      @select="onSelect"
    >
      <template #reference>
        <div class="time-set">
          <img src="https://img-fe.tengzhihh.com/image/7872bcd9d2fa97-50x50.png" class="time-icon" />
        </div>
      </template>
    </van-popover>

    <van-popup v-model="volumeControlFlag" @click-overlay="volumeControlFlag = false">
      <div class="control-box f fc">
        <img src="https://img-fe.tengzhihh.com/image/fa7b62c864d486-48x48.png" class="icon" />
        <van-slider
          v-model="currentVolume"
          bar-height="2px"
          button-size="12px"
          max="15"
          active-color="#F87A1B"
          @change="volumeChange"
        >
        </van-slider>
        <div class="number">{{ currentVolume }}</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import GoBack from '@/components/GoBack/GoBack.vue';
import PlayProgress from './PlayProgress.vue';
import { mapState } from 'vuex';
import sendMsg from '@/webSocket/sendMsg';
let lyricsCache = [];
export default {
  components: {
    GoBack,
    PlayProgress,
  },
  props: {
    serialId: {
      type: String,
      default: '',
    },
    lessonId: {
      type: [Number, String],
      default: 0,
    },
    from: {
      type: String,
      default: 'lesson',
    },
    currentPosition: {
      type: [String, Number],
      default: 0,
    },
    currentRate: {
      type: [String, Number],
      default: 0,
    },
    currentMusic: {
      type: Object,
      default: { id: 0, lyric: '' },
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    currentPlayingMode: {
      type: Number,
      default: 0,
    },
    showLyric: {
      type: Boolean,
      default: false,
    },
    lessonCategoryId: {
      type: Number,
      default: 1,
    },
    indexMode: {
      type: String,
      default: '',
    },
    bgImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 0:当前歌曲结束，10：10分钟，15：15分钟......60：60分钟
      actions: [
        { key: 0, text: '当前歌曲结束' },
        { key: 10, text: '10分钟后' },
        { key: 15, text: '15分钟后' },
        { key: 30, text: '30分钟后' },
        { key: 60, text: '60分钟后' },
      ],
      timeoutPopover: false,
      volumeControlFlag: false,
      currentVolume: 0,
      startVolume: 0,
    };
  },
  computed: {
    ...mapState(['musicList']),
    calcLyric: function () {
      if (!this.currentMusic.lyric) return [];
      const lyrics = this.currentMusic.lyric.split('\n').map((line) => {
        const time = line.match(/\[(.*?)\]/)?.length >= 2 ? line.match(/\[(.*?)\]/)[1] : '00:00.00';
        const text = line.replace(/\[(.*?)\]/, '').trim();
        const timeParts = time.split(':');
        const minutes = parseInt(timeParts[0]);
        const seconds = parseFloat(timeParts[1]);
        const position = minutes * 60 + seconds;
        return { time, text, position };
      });
      return lyrics;
    },
    calcCurrentLyrics: function () {
      if (this.calcLyric.length === 0) {
        return [
          {
            text: '暂无经文',
            key: 1,
          },
        ];
      }
      const timeList = Array.from(new Set(this.calcLyric.map((i) => i.position)));
      let tmp = timeList.find((i, idx) => {
        if (idx === timeList.length) {
          return this.currentPosition >= i && this.currentPosition <= timeList[idx];
        } else {
          return this.currentPosition >= i && this.currentPosition < timeList[idx + 1];
        }
      });
      if (tmp !== undefined) {
        lyricsCache = this.calcLyric.filter((i) => tmp === i?.position);
      } else {
        tmp = timeList[timeList.length - 1];
        lyricsCache = this.calcLyric.filter((i) => tmp === i?.position);
      }
      return lyricsCache.map((i) => {
        return {
          ...i,
          key: Math.random().toString(10).slice(-5),
        };
      });
    },
    playingModeIcon: function () {
      const arr = [
        {
          white: 'https://img-fe.tengzhihh.com/image/f4f5480903a05b-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/9b5d8316a675eb-48x48.png',
        },
        {
          white: 'https://img-fe.tengzhihh.com/image/88c9ad27afd6db-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/def0dd42ded094-48x48.png',
        },
        {
          white: 'https://img-fe.tengzhihh.com/image/34da1bf5732349-50x50.png',
          gray: 'https://img-fe.tengzhihh.com/image/3af16216079cbe-48x48.png',
        },
      ];
      return arr[this.currentPlayingMode];
    },
    totalValue: function () {
      let currentMusic = this.musicList.find((i) => this.currentMusic.id != 0 && this.currentMusic.id == i.id);
      return currentMusic?.duration || 0;
    },
    calcStyle: function () {
      const bg =
        this.from === 'music'
          ? this.indexMode === 'western'
            ? 'https://img-fe.tengzhihh.com/image/7de1a7a621a04e-375x1000.png'
            : ''
          : this.bgImg;

      return bg
        ? {
            backgroundImage: `url('${bg}')`,
          }
        : {};
    },
  },

  methods: {
    updateVolume(e) {
      this.currentVolume = e;
      this.startVolume = e;
    },
    volumeChange(e) {
      sendMsg('systemInfo', {
        operation: 'changeVolume',
        extra: e,
      });
      if (this.currentVolume > this.startVolume) {
        this.$toast('智能神台音量已放大');
      } else if (this.currentVolume < this.startVolume) {
        this.$toast('智能神台音量已减小');
      }
    },
    showVoiceControl() {
      this.volumeControlFlag = true;
    },
    backFunction() {
      this.$emit('back');
    },
    changeShowLyric() {
      this.$emit('changeShowLyric', !this.showLyric);
    },
    changeCurrentPlayingMode() {
      this.$emit('changeCurrentPlayingMode');
    },
    closePlayingMusicList() {
      this.$emit('closePlayingMusicList');
    },
    currentPositionChange(v) {
      this.$emit('currentPositionChange', v);
    },
    playControl() {
      this.$emit('playControl');
    },
    showMusicList() {
      this.$emit('showMusicList');
    },
    onSelect(v) {
      this.$toast('设置成功');
      sendMsg('player', {
        operation: 'openTimedOff',
        extra: v.key,
        playerType: 'music',
      });
    },
    edit() {
      this.$router.push(
        `/music/lessonEdit?lesson_id=${this.lessonId}&edit_mode=edit&serial_id=${this.serialId}&category_id=${this.lessonCategoryId}&index_mode=${this.indexMode}`
      );
    },
    previous() {
      sendMsg('player', {
        operation: 'previous',
        playerType: 'music',
      });
    },
    next() {
      sendMsg('player', {
        operation: 'next',
        playerType: 'music',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.playing-page {
  background: linear-gradient(180deg, #fef2e4 0%, #eba95d 100%);
  height: calc(var(--vh) * 100);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 375px;
  .setting {
    position: fixed;
    right: 20px;
    top: 20px;
    width: 52px;
    height: 28px;
  }
  .time-set-box {
    position: absolute;
    left: 25px;
    bottom: 160px;
    .time-set {
      .time-icon {
        width: 25px;
        height: 25px;
      }
    }
  }
  .lyric-btn {
    line-height: 23px;
    height: 23px;
    padding: 0 10px;
    min-width: fit-content;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 13px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
  }
  .show {
    border: 1px solid rgba(255, 255, 255, 1);
    color: rgba(255, 255, 255, 1);
  }
  .detail-content {
    padding-top: 50px;
    .detail-name {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 26px;
    }
    .main-box {
      width: 290px;
      height: 343px;
      display: block;
      margin: 0 auto;
      position: relative;
      .main-img {
        margin-top: 72px;
        width: 290px;
        height: 290px;
      }

      .rod-img {
        width: 101px;
        height: 158px;
        position: absolute;
        left: 130px;
        top: 10px;
        transform-origin: left 10px;
        transform: rotate(-20deg);
        transition: all 0.5s ease-out;
      }
      .playing-rod-img {
        transform: rotate(0deg);
      }
    }
  }

  .lesson-content {
    .lyric-box {
      width: 307px;
      max-height: 40vh;
      overflow-y: auto;
      position: absolute;
      bottom: 180px;
      text-align: center;
      color: #ffffff;
      font-size: 16px;
      line-height: 2;
      font-weight: bold;
      padding: 20px;
      border-radius: 10px;
    }
  }
  .bg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 375px;
    height: 347px;
    // background: linear-gradient(transparent, rgba(151, 80, 27, 0.8), rgba(151, 80, 27, 0.9) 100%);
    opacity: 0.8;
  }
  .up-voice-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 43px;
    bottom: 130px;
  }
  .down-voice-icon {
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }
  .player-box {
    padding: 0 20px;
    position: absolute;
    left: 0;
    bottom: 90px;
    width: 375px;
    box-sizing: border-box;
    z-index: 10;
  }
  .time {
    font-size: 12px;
    transform: scale(0.8);
    color: #fff;
  }
  .bottom-area {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 375px;
    padding: 5px 45px 0;
    box-sizing: border-box;
    overflow: hidden;

    .options-area {
      padding: 18px 0 22px;

      .control-btn {
        width: 25px;
        height: 25px;
      }
      .play-btn {
        width: 50px;
        height: 50px;
      }
      .lesson-play {
        margin: 0 80px 0 60px;
      }
      .list-icon {
        width: 25px;
        height: 25px;
      }
      .playing-mode {
        width: 25px;
        height: 25px;
      }
    }
  }
}
.lesson-page {
  background-size: 100%;
  background-repeat: no-repeat;
}
.control-box {
  width: 328px;
  height: 64px;
  background: #ffffff;
  border-radius: 15px;
  padding: 0 20px 0 16px;
  font-size: 16px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .number {
    margin-left: 10px;
  }
}
</style>
