<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>
    <van-cell title="点香音效" is_link center>
      <template #right-icon>
        <van-switch
          active-color="#f87a1b"
          inactive-color="#ffeac9"
          size="20px"
          :value="generalConfig.incense_sound"
          @input="incenseSoundChange"
        />
      </template>
    </van-cell>

    <template v-if="generalConfig.incense_sound">
      <van-cell title="一响" is_link center>
        <template #right-icon>
          <van-radio
            name="one"
            icon-size="20px"
            checked-color="#f87a1b"
            v-model="incenseSoundEffect"
            @click="updateIncenseSoundEffect"
          ></van-radio>
        </template>
      </van-cell>

      <van-cell title="三响" is_link center>
        <template #right-icon>
          <van-radio
            name="three"
            icon-size="20px"
            checked-color="#f87a1b"
            v-model="incenseSoundEffect"
            @click="updateIncenseSoundEffect"
          ></van-radio>
        </template>
      </van-cell>
    </template>

    <van-popup v-model="showFlag" position="bottom" @click-overlay="onCancel">
      <div class="f fc fbt header">
        <div class="cancel" @click="onCancel">取消</div>
        <div class="confirm" @click="onConfirm">确定</div>
      </div>

      <div class="body">
        <div class="item">
          <div class="tip">媒体声音</div>
          <div class="f fc">
            <img src="https://img-fe.tengzhihh.com/image/ed019f3c1bd0d2-93x73.png" class="left-icon" />
            <van-slider v-model="soundSize" max="15" />
            <div class="size">
              {{ soundSize }}
            </div>
          </div>
        </div>

        <div class="item">
          <div class="tip">操作声音</div>
          <div class="f fc">
            <img src="https://img-fe.tengzhihh.com/image/ed019f3c1bd0d2-93x73.png" class="left-icon" />
            <van-slider v-model="systemSoundSize" max="15" />
            <div class="size">
              {{ systemSoundSize }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import { mapState } from 'vuex';
export default {
  components: { CellGroup },
  data() {
    return {
      selectorTitle: '',
      showFlag: false,
      selectorColumns: [],
      serialId: '',
      soundSize: 0,
      systemSoundSize: 0,
      incenseSoundEffect: '',
    };
  },
  computed: {
    ...mapState(['generalConfig']),
    settingList: function () {
      return [
        {
          title: '音量设置',
          isLink: true,
          cb: () => {
            this.init().then(() => {
              this.showFlag = true;
            });
          },
          // value: this.generalConfig.sound_size,
        },
      ];
    },
  },

  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.init();
  },
  methods: {
    init() {
      return this.$utils.getGeneralConfig(this.serialId).then((res) => {
        this.soundSize = res.sound_size;
        this.systemSoundSize = res.system_sound_size;
        this.incenseSoundEffect = res.incense_sound_effect;
      });
    },
    findIndex(second) {
      return soundArr.findIndex((i) => i === second);
    },
    updateIncenseSoundEffect() {
      this.$utils.updateGeneralConfig(
        this.serialId,
        [
          {
            key: 'incense_sound_effect',
            value: this.incenseSoundEffect,
          },
        ],
        () => {
          this.$track(
            this.incenseSoundEffect === 'one' ? 'setting_yinxiao_dianxiang_one' : 'setting_yinxiao_dianxiang_three'
          );
        }
      );
    },
    incenseSoundChange(e) {
      this.$utils.updateGeneralConfig(
        this.serialId,
        [
          {
            key: 'incense_sound',
            value: e ? 'open' : 'close',
          },
        ],

        () => {
          this.$track(e ? 'setting_yinxiao_dianxiang_on' : 'setting_yinxiao_dianxiang_off');
        }
      );
    },
    onConfirm(e, idx) {
      this.$utils.updateGeneralConfig(
        this.serialId,
        [
          {
            key: 'sound_size',
            value: this.soundSize,
          },
          {
            key: 'system_sound_size',
            value: this.systemSoundSize,
          },
        ],
        () => {
          this.showFlag = false;
        }
      );
    },
    onCancel() {
      this.showFlag = false;
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
.header {
  padding: 0 20px;
  height: 50px;
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .confirm {
    font-size: 13px;
    color: #576b95;
  }
  .cancel {
    font-size: 13px;
  }
}
.body {
  padding: 10px 20px 20px;
  .item {
    font-size: 19px;
    font-weight: 500;
    color: #473d37;
    margin-bottom: 20px;
    .tip {
      font-size: 13px;
      font-weight: bold;
      color: #2e2d2d;
      margin-bottom: 10px;
    }
    .left-icon {
      width: 22px;
      margin-right: 20px;
    }
    .size {
      margin-left: 20px;
    }
  }
}
.van-popup {
  border-radius: 0;
}
</style>
