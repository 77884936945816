<template>
  <div class="cell-container">
    <van-cell-group>
      <van-cell
        v-for="(item, index) in list.filter((i) => !i.hide)"
        :key="index"
        :title="item.title"
        :label="item.label"
        :value="item.value"
        :to="item.path"
        :center="!!item.center"
        @click="handleClick(item)"
      >
        <template #title v-if="item.icon">
          <div class="f fc">
            <img :src="item.icon" class="custom-icon" />
            <span class="custom-title">{{ item.title }}</span>
          </div>
        </template>

        <template #right-icon v-if="!!item.isLink">
          <img src="https://img-fe.tengzhihh.com/image/08e7301a2a1c12-33x32.png" class="right-arrow" />
        </template>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(item) {
      if (item.cb && typeof item.cb === 'function') {
        item.cb();
      }
    },
  },
};
</script>
<style lang="less">
.cell-container {
  .van-cell {
    height: 70px;
    line-height: 26px;
    display: flex;
    align-items: center;
  }
}
.right-arrow {
  width: 16px;
  height: 16px;
}
.van-cell__title {
  .custom-icon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }
  .custom-title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
