var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPage
    ? _c(
        "div",
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showMusicPage,
                      expression: "!showMusicPage",
                    },
                  ],
                  staticClass: "page",
                },
                [
                  _vm.is_quotation && _vm.quotation
                    ? _c("div", { staticClass: "pac quotation" }, [
                        _vm._v(_vm._s(_vm.quotation)),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "bg" }, [
                    _c("img", {
                      attrs: {
                        src:
                          (_vm.woodfish_detail_v2.wooden_fish_list &&
                            _vm.woodfish_detail_v2.wooden_fish_list.find(
                              (x) => x.fish_type === _vm.fish_type
                            ).other_bg_img) ||
                          "https://img-fe.tengzhihh.com/image/08493aff63695a-375x812.png",
                        alt: "",
                        srcset: "",
                      },
                    }),
                    _c("img", {
                      attrs: {
                        src:
                          (_vm.woodfish_detail_v2.wooden_fish_list &&
                            _vm.woodfish_detail_v2.wooden_fish_list.find(
                              (x) => x.fish_type === _vm.fish_type
                            ).bg_img) ||
                          "https://img-fe.tengzhihh.com/image/08493aff63695a-375x812.png",
                        alt: "",
                        srcset: "",
                      },
                    }),
                  ]),
                  _c("van-icon", {
                    staticClass: "goback",
                    attrs: { name: "arrow-left", size: "20" },
                    on: { click: _vm.backClick },
                  }),
                  _c(
                    "div",
                    { staticClass: "tabList f" },
                    _vm._l(_vm.tabList, function ({ value, label }) {
                      return _c(
                        "div",
                        {
                          key: value,
                          class: [
                            "tab",
                            _vm.fish_type === value ? "tab-active" : "",
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.tabClick(value)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(label) + " ")]
                      )
                    }),
                    0
                  ),
                  _c("van-icon", {
                    staticClass: "setting",
                    attrs: { name: "setting-o", size: "20" },
                    on: { click: _vm.toSet },
                  }),
                  _c(
                    "div",
                    { staticClass: "sound-switch" },
                    [
                      _c("van-icon", {
                        staticClass: "sound-icon",
                        attrs: {
                          name: _vm.muted
                            ? "https://img-fe.tengzhihh.com/image/bb3bb05546175a3c88a7698342df859c-48x48.png"
                            : "https://img-fe.tengzhihh.com/image/58fc740a73ec1863e6ae5e7ba9db8e3d-48x48.png",
                          size: "24",
                        },
                        on: { click: _vm.checkMuted },
                      }),
                      _c("p", [_vm._v("声音")]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "pac billboard" }, [
                    _c("p", { staticClass: "count" }, [
                      _vm._v(
                        "今日：" + _vm._s(_vm.woodfish_detail_v2.today_num || 0)
                      ),
                    ]),
                    _c("p", { staticClass: "count" }, [
                      _vm._v(" 本次："),
                      _c("span", { staticClass: "light-txt" }, [
                        _vm._v(_vm._s(_vm.point)),
                      ]),
                    ]),
                  ]),
                  _c("div", {
                    staticClass: "svga-start",
                    attrs: { id: "svga-start" },
                  }),
                  _c("div", {
                    staticClass: "svga",
                    attrs: { id: `svga-${_vm.fish_type}` },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fish_type === 1,
                          expression: "fish_type === 1",
                        },
                      ],
                      staticClass: "content pac",
                      on: { click: _vm.knockFn },
                    },
                    [
                      _c(
                        "p",
                        {
                          class: [
                            "pac",
                            "resultTxt",
                            _vm.showTxtAnimate ? "resultTxtAnimate" : "",
                          ],
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.woodfish_detail_v2.title_type === 1
                                  ? _vm.woodfish_detail_v2.fish_title ||
                                      "福报+1"
                                  : _vm.showTxtAnimate
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("img", {
                        staticClass: "woodfish",
                        class: _vm.isKnock ? "bigMuyu" : "",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/30ac3ef695e74b-349x373.png",
                          alt: "",
                          srcset: "",
                        },
                      }),
                      _c("img", {
                        class: _vm.isKnock ? "knock" : "stick",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/cf27df0550786a-166x108.png",
                          alt: "",
                          srcset: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fish_type === 2,
                          expression: "fish_type === 2",
                        },
                      ],
                      staticClass: "chapletBox",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "swip-list",
                          attrs: { id: "woodfishSwip" },
                        },
                        _vm._l(_vm.swipNianList, function (item) {
                          return _c("img", {
                            key: item.key,
                            class: [
                              "swip-nian",
                              item.top >= -48 &&
                                item.top < 9 * 48 &&
                                "swip-nian-tran",
                            ],
                            style: { top: _vm.px2rem(item.top) },
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/119ea424ef8d72-100x100.png",
                              alt: "",
                              srcset: "",
                            },
                            on: {
                              touchstart: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.swipStartFn.apply(null, arguments)
                              },
                              touchend: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.swipEndFn.apply(null, arguments)
                              },
                            },
                          })
                        }),
                        0
                      ),
                      _c(
                        "p",
                        {
                          class: [
                            "chapletTxt",
                            _vm.isKnock ? "chapletTxtAnimate" : "",
                          ],
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.woodfish_detail_v2.title_type === 1
                                  ? _vm.woodfish_detail_v2.fish_title ||
                                      "福报+1"
                                  : _vm.showTxtAnimate
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.fish_type === 3,
                          expression: "fish_type === 3",
                        },
                      ],
                      staticClass: "songbo",
                      on: { click: _vm.playFn },
                    },
                    [
                      _c("img", {
                        class: ["songbo-img"],
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/0b5bbe5a70ab3b9dccef1e8c6bfe7ec2-698x746.png",
                          alt: "",
                          srcset: "",
                        },
                      }),
                      _c("img", {
                        class: _vm.isKnock
                          ? "songbo-ban-knock"
                          : "songbo-ban-stick",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/0fff7143a78e56081645b3dc85cb272a-192x272.png",
                          alt: "",
                          srcset: "",
                        },
                      }),
                      _c(
                        "p",
                        {
                          class: [
                            "pac",
                            "resultTxt",
                            _vm.showTxtAnimate ? "resultTxtAnimate" : "",
                          ],
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.woodfish_detail_v2.title_type === 1
                                  ? _vm.woodfish_detail_v2.fish_title ||
                                      "福报+1"
                                  : _vm.blessing_list[
                                      Math.floor(
                                        Math.random() * _vm.blessing_list.length
                                      )
                                    ]
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "button",
                    { staticClass: "pac submit", on: { click: _vm.backClick } },
                    [_vm._v("完成修行")]
                  ),
                  _c(
                    "van-popup",
                    {
                      attrs: { round: "" },
                      model: {
                        value: _vm.isShowPop,
                        callback: function ($$v) {
                          _vm.isShowPop = $$v
                        },
                        expression: "isShowPop",
                      },
                    },
                    [
                      _c("div", { staticClass: "poplayout" }, [
                        _c("div", { staticClass: "txt-c cancelTitle" }, [
                          _vm._v("提示"),
                        ]),
                        _c("div", { staticClass: "cancelSub" }, [
                          _vm._v(" " + _vm._s(_vm.popText) + " "),
                        ]),
                        _c("div", { staticClass: "cancelTips" }, [
                          _vm._v(
                            "说明：每敲木鱼/念珠100下，功德值+1，每日上限功德值200"
                          ),
                        ]),
                        _c("div", { staticClass: "popHandle f fvc" }, [
                          _c(
                            "button",
                            {
                              staticClass: "popbtn",
                              on: {
                                click: function ($event) {
                                  _vm.isShowPop = false
                                },
                              },
                            },
                            [_vm._v("继续修行")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "popbtn",
                              on: { click: _vm.cancelSubmit },
                            },
                            [_vm._v("退出页面")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "playbar f fc fbt" }, [
                    _c("div", { staticClass: "f fc" }, [
                      _c("img", {
                        staticClass: "mask",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/61e05bc4f2709b-35x35.png",
                          alt: "",
                          srcset: "",
                        },
                      }),
                      _c("p", { staticClass: "txt" }, [
                        _vm._v(_vm._s(_vm.currentPlay.name)),
                      ]),
                    ]),
                    _c("div", { staticClass: "f" }, [
                      _c(
                        "div",
                        {
                          staticClass: "playOrPause f fc",
                          on: { click: _vm.playControl },
                        },
                        [
                          _c("van-circle", {
                            staticClass: "panel",
                            attrs: {
                              value: _vm.currentPosition,
                              rate: _vm.currentPlay.duration,
                              "layer-color": "rgba(0,0,0,0.15)",
                              color: "#F87A1B",
                              "stroke-width": 80,
                            },
                          }),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isPlayStarted,
                                expression: "!isPlayStarted",
                              },
                            ],
                            staticClass: "circle",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/dfd676ac3fb6e0-24x24.png",
                            },
                          }),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isPlayStarted,
                                expression: "isPlayStarted",
                              },
                            ],
                            staticClass: "circle",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/069e6de2f802e0-24x24.png",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("img", {
                        staticClass: "panel ml30",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/124ecf7b97df9c-56x56.png",
                          alt: "",
                          srcset: "",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showMusicPage = true
                          },
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c("audio", { ref: "muyuAudio", attrs: { id: "muyuAudio" } }, [
                _c("source", {
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/audio/ded9f954cac7be513d7ef4e2ad1fbf50.mp3",
                  },
                }),
              ]),
              _c(
                "audio",
                { ref: "songboAudio", attrs: { id: "songboAudio" } },
                [
                  _c("source", {
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/file/31800a0a-531a-42d2-9957-d43e0f00a6b6.wav",
                    },
                  }),
                ]
              ),
              _c("audio", {
                staticStyle: { display: "none" },
                attrs: {
                  id: "nianzhuAudio",
                  src: "https://img-fe.tengzhihh.com/audio/6363d966b2cd1728687252a3cb9dd817.mp3",
                  controls: "controls",
                },
              }),
              _vm.showMusicPage
                ? _c("woodfishMusic", {
                    on: {
                      back: function ($event) {
                        _vm.showMusicPage = false
                      },
                      changePlay: _vm.changePlay,
                      lessonStartPlay: _vm.lessonStartPlay,
                      changePlayMode: _vm.changePlayMode,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("Dialog", { ref: "dialog" }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "loading-box f fc fvc" },
        [_c("van-loading", { attrs: { type: "spinner" } })],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }