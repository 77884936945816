var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit-page-box" }, [
    _c("img", {
      staticClass: "bg",
      attrs: {
        src: "https://img-fe.tengzhihh.com/image/4e0e73a30570bb-750x1496.png",
      },
    }),
    _c("div", { staticClass: "page-title" }, [_vm._v("选择功课内容")]),
    _c(
      "div",
      { staticClass: "list-area f fc fbt flex-wrap" },
      [
        _vm._l(_vm.musicList, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "list-item f fv fvc",
              on: {
                click: function ($event) {
                  return _vm.chooseItem(item)
                },
              },
            },
            [
              _c("div", { staticClass: "img-box" }, [
                _c("img", {
                  staticClass: "cover",
                  attrs: {
                    src:
                      _vm.indexMode === "western"
                        ? "https://img-fe.tengzhihh.com/image/960f9c5a350e7b-210x210.png"
                        : "https://img-fe.tengzhihh.com/image/aeb0168faca175-210x210.png",
                  },
                }),
                _vm.hasSelected(item.id)
                  ? _c("img", {
                      staticClass: "select-icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/5002d361cc9c93-94x34.png",
                      },
                    })
                  : _c("img", {
                      staticClass: "select-icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/491eb945bd3076-94x34.png",
                      },
                    }),
              ]),
              _c("div", { staticClass: "clamp1 name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]
          )
        }),
        _vm._l(3 - (_vm.musicList.length % 3), function (item) {
          return _c("i", { staticClass: "i-tag" })
        }),
      ],
      2
    ),
    _c("div", { staticClass: "f fc frd" }, [
      _c("div", { staticClass: "cancel-btn", on: { click: _vm.cancel } }, [
        _vm._v("取消"),
      ]),
      _c("div", { staticClass: "confirm-btn", on: { click: _vm.confirm } }, [
        _vm._v("确定"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }