<template>
  <div class="page-box f fv fc fvc">
    <img src="https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png" class="bg" />
    <GoBack />
    <div class="list f fv fc">
      <img v-for="(item, idx) in list" :key="idx" :src="item.icon" class="item-img" @click="view(item)" />
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import GoBack from '@/components/GoBack/GoBack.vue';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  components: { GoBack },
  mixins: [commonOperate],
  data() {
    return {
      list: [],
    };
  },
  created() {},
  mounted() {
    this.getEduType();
    localStorage.removeItem('sub_list_title');
  },
  methods: {
    getEduType() {
      this.$http
        .get(apiPath.eduType, {
          serial_id: this.routeQuery.serial_id,
        })
        .then(res => {
          this.list = res.data.list;
        });
    },
    view(item) {
      let url = '';
      if (item.cate_type === 'family_tradition') {
        this.$track('wenhua_jiafengjiaxun_click');
        url = `/motto/list?serial_id=${this.routeQuery.serial_id}`;
      } else {
        const map = {
          taoist_culture: 'wenhua_daojiaowenhua_click',
          buddhist_culture: 'wenhua_fojiaowenhua_click',
          geomancy: 'wenhua_fengsuizhishi_click',
          western_religious_culture: 'wenhua_xifangwenhua_wenhua_click',
        };
        this.$track(map[item.cate_type]);

        url = `/edu/subList?type=${item.cate_type}&serial_id=${this.routeQuery.serial_id}`;
      }
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  width: 100%;
  height: 100%;
  // padding: 0 20px;
  box-sizing: border-box;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }
  .list {
  }
  .item-img {
    width: 252px;
    height: 100px;
  }
}
</style>
