<template>
  <div class="msg-detail">
    <div class="detail-header f fc fbt">
      <div @click="manageStatus = true" v-if="!manageStatus">消息管理</div>
      <div @click="selectAll" v-if="manageStatus">
        {{ allFlag ? '全不选' : '全选' }}
      </div>
      <div @click="cancelSelect" v-if="manageStatus">取消</div>
    </div>

    <van-checkbox-group v-model="result" ref="checkboxGroup">
      <div :class="`detail-body ${manageStatus ? 'manage-status' : ''}`" id="msg-list">
        <div class="empty-box f fc fvc" v-if="calcList.length === 0">暂无消息</div>
        <div @click="loadMore" v-if="hasMore && calcList.length > 0" class="loadmore">点击加载更多</div>

        <div class="msg-group f fv fvc" v-for="(item, index) in calcList" :key="index" :id="item.id">
          <div class="group-date">{{ item.created_at }}</div>
          <div class="group-list">
            <div class="list-item f fc">
              <van-checkbox :name="item.id" style="margin-right: 10px" v-if="manageStatus" icon-size="18px">
              </van-checkbox>
              <div class="msg-content" v-html="item.content"></div>
            </div>
          </div>
        </div>
      </div>
    </van-checkbox-group>

    <van-button class="default-btn del-btn" size="small" v-if="manageStatus" @click="delMsg">删除所选</van-button>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
export default {
  data() {
    return {
      manageStatus: false,
      list: [],
      result: [],
      allFlag: false,
      serialId: '',
      currentPage: 1,
      scrollHeight: 0,
      hasMore: true,
    };
  },
  computed: {
    calcList: function () {
      const tmp = this.list.sort((a, b) => {
        return a.id - b.id;
      });

      return tmp;
    },
  },

  mounted() {
    this.serialId = this.$route.query.serial_id;

    this.msgList(() => {
      this.$nextTick(() => {
        this.$tools.scrollToBottomById('main-container');
        this.scrollHeight = document.getElementById('main-container').scrollHeight;
      });
    });
  },
  methods: {
    loadMore() {
      this.currentPage++;
      this.msgList(() => {
        this.$nextTick(() => {
          document.getElementById('main-container').scrollTop = this.scrollHeight - 100;
        });
      });
    },
    msgList(cb) {
      this.$http
        .get(apiPath.msgList, {
          serial_id: this.serialId,
          page: this.currentPage,
        })
        .then((res) => {
          this.list.unshift(...res.data.list);
          this.hasMore = res.data.pager.current_page !== res.data.pager.total_page;
          typeof cb === 'function' && cb();
        });
    },
    toggle(index) {
      if (!this.manageStatus) {
        return false;
      }
      this.$refs.checkboxes[index].toggle();
    },
    selectAll() {
      if (!this.manageStatus) {
        return false;
      }
      if (!this.allFlag) {
        this.allFlag = true;
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.allFlag = false;
        this.$refs.checkboxGroup.toggleAll(false);
      }
    },
    delMsg() {
      if (this.result.length === 0) {
        return this.$toast('请先勾选消息');
      }
      this.$dialog({
        message: `确定删除所选消息吗`,
        title: '提示',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        const formData = new FormData();
        formData.append('serial_id', this.serialId);
        formData.append('ids', this.result.join(','));
        this.$http.formPost(apiPath.delMsg, formData).then(() => {
          this.$toast('删除成功');
          this.list = this.list.filter((i) => !this.result.includes(i.id));
          this.result = [];
        });
      });
    },
    cancelSelect() {
      this.manageStatus = false;
      this.allFlag = false;
    },
  },
};
</script>

<style lang="less" scoped>
.msg-detail {
  background: rgb(234, 235, 236);

  .detail-header {
    z-index: 100;
    font-size: 14px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    border-bottom: 1px solid #ebebeb;
    line-height: 40px;
    padding: 0 10px;
    position: fixed;
    top: 0;
    left: 0;
  }
  .empty-box {
    padding: 90px 0;
    background: #fff;
  }
  .detail-body {
    min-height: calc(var(--vh) * 100 - 40px);
    padding-top: 40px;
    .msg-group {
      .group-date {
        width: fit-content;
        // background-color: rgba(0, 0, 0, 0.68);
        // border-radius: 10px;
        color: #999;
        padding: 5px 20px;
        margin-bottom: 10px;
      }
      .group-list {
        .list-item {
          margin-bottom: 20px;
          border-radius: 10px;
          background: #fff;
          padding: 6px 14px;
          .msg-content {
            line-height: 1.5;
            font-size: 13px;
          }
        }
      }
    }
    .msg-group:last-child {
      margin: 0;
    }
  }
  .del-btn {
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -50px;
    width: 100px;
  }

  .loadmore {
    text-align: center;
    margin: 13px 0;
  }
  .manage-status {
    padding-bottom: 40px;
  }
}
</style>
