<template>
  <div>
    <div class="init-box">
      <div v-for="item in modeList" class="mode-box f fv fc" :key="item.id" @click="clickMode(item.id)">
        <div :class="`${currentId === item.id ? 'selected-item' : ''} mode-info f fv fc`">
          <img :src="item.img" class="mode-img" />
          <div class="mode-name">{{ item.name }}</div>
        </div>
        <div>
          <img
            v-if="item.id === currentId"
            src="https://img-fe.tengzhihh.com/image/b7b12cf06d838d-56x56.png"
            class="selected-icon"
          />
        </div>
      </div>
      <div class="confirm-box">
        <div class="confirm-btn" @click="submit">确认</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMachineColor, setConfigGeneral } from '@/api/setting';
import GodList from '@/components/God/GodList.vue';
const trackKey = [
  'H5_setting_waiguan_moren_success',
  'H5_setting_waiguan_jin_success',
  'H5_setting_waiguan_mu_success',
  'H5_setting_waiguan_shui_success',
  'H5_setting_waiguan_huo_success',
  'H5_setting_waiguan_tu_success',
];
export default {
  components: {
    GodList,
  },
  data() {
    return {
      modeList: [],
      serialId: '',
      currentId: '',
    };
  },
  computed: {},
  created() {
    this.serialId = this.$route.query.serial_id;
  },
  mounted() {
    this.getModeList();
  },
  methods: {
    async getModeList() {
      const res = await getMachineColor({
        serial_id: this.serialId,
      });
      this.modeList = res.data.list;
      this.currentId = res.data.machine_color;
    },
    clickMode(id) {
      this.currentId = id;
    },

    trackFn() {
      const key = trackKey[this.currentId];
      this.$track(key);
    },
    async submit() {
      const { code } = await setConfigGeneral({
        serial_id: this.serialId,
        machine_color: this.currentId,
      });
      if (code !== 0) return;
      this.trackFn();
      this.successJump();
    },

    successJump() {
      this.$toast('操作成功');
      this.$router.replace(`/device/index?serial_id=${this.serialId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.init-box {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px 0 60px;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  .mode-box {
    position: relative;
    margin-bottom: 20px;
    width: 176px;
    .selected-icon {
      width: 28px;
      height: 28px;
      position: absolute;
      bottom: -14px;
      left: 50%;
      margin-left: -14px;
    }
    .selected-item {
      border: 2px solid #f97a1d !important;
    }
    .mode-info {
      padding-top: 8px;
      border-radius: 10px;
      text-align: center;
      width: 165px;
      background: #ffffff;
      border: 2px solid transparent;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
      .mode-img {
        width: 150px;
        height: 170px;
      }
      .religion-img {
        width: 150px;
        height: 170px;
      }
      .mode-name {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin: 12px 0;
      }
    }
    .select-btn {
      width: 165px;
      height: 40px;
      line-height: 40px;
      background: rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-size: 16px;
      color: #ffffff;
      margin-top: 16px;
      text-align: center;
    }
    .current {
      background: #f87a1b;
    }
  }
}
.religion-box {
  padding-top: 60px;
}

.confirm-box {
  position: fixed;
  bottom: 0;
  width: 375px;
  height: 60px;
  background: rgba(255, 255, 255, 0.65);
  .confirm-btn {
    margin: 8px auto;
  }
}

.confirm-btn {
  width: 299px;
  height: 44px;
  background: #f87a1b;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  color: #fff;
  margin: 0 0 30px;
}
.popup-box {
  width: 295px;
  height: 382px;
  background: #ffffff;
  padding: 38px 0 0;
  .img {
    width: 150px;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin: 16px 0;
  }
  .btn-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 35px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    .cancel-btn {
      width: 100px;
      height: 40px;
      background: #f6f6f6;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.65);
    }
    .confirm-btn {
      width: 100px;
      height: 40px;
      background: #f87a1b;
      border-radius: 10px;
      color: #fff;
    }
  }
}
</style>
