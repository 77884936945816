var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _vm.detail.id
        ? _c("div", { staticClass: "pt-30" }, [
            _c(
              "div",
              { staticClass: "wish-card" },
              [
                _c("div", { staticClass: "wish-name" }, [
                  _vm._v("祈愿人：" + _vm._s(_vm.detail.wish_name)),
                ]),
                _c("div", { staticClass: "wish-address" }, [
                  _vm._v("居住于：" + _vm._s(_vm.detail.wish_address)),
                ]),
                _c("div", { staticClass: "wish-content" }, [
                  _vm._v("神明保佑：" + _vm._s(_vm.detail.wish_content)),
                ]),
                _c("div", { staticClass: "wish-days" }, [
                  _vm._v("祈愿天数 " + _vm._s(_vm.detail.cumulative_days)),
                ]),
                _c("Dialog", { ref: "dialog" }),
              ],
              1
            ),
            _vm.$utils.calcUserIsMasterWithSerialId(_vm.$route.query.serial_id)
              ? _c(
                  "div",
                  { staticClass: "wishHandle" },
                  [
                    _c(
                      "van-button",
                      {
                        directives: [
                          {
                            name: "debounce",
                            rawName: "v-debounce",
                            value: _vm.intensifyFn,
                            expression: "intensifyFn",
                          },
                        ],
                        class: _vm.detail.intensify_status
                          ? ""
                          : "van-button--disabled",
                        attrs: {
                          block: "",
                          color: "#F87A1B",
                          size: "small",
                          "native-type": "submit",
                        },
                      },
                      [_vm._v(" 强化祈愿 ")]
                    ),
                    _c(
                      "van-button",
                      {
                        directives: [
                          {
                            name: "debounce",
                            rawName: "v-debounce",
                            value: _vm.wishEdit,
                            expression: "wishEdit",
                          },
                        ],
                        staticClass: "wishEdit",
                        attrs: {
                          plain: "",
                          block: "",
                          color: "#F87A1B",
                          size: "small",
                          "native-type": "submit",
                        },
                      },
                      [_vm._v("更换祈愿")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : [
            _vm._m(0),
            _c("div", { staticClass: "null-tip" }, [_vm._v("尚未发布祈愿")]),
            _vm.$utils.calcUserIsMasterWithSerialId(_vm.$route.query.serial_id)
              ? _c(
                  "div",
                  { staticClass: "wishAdd" },
                  [
                    _c(
                      "van-button",
                      {
                        directives: [
                          {
                            name: "debounce",
                            rawName: "v-debounce",
                            value: _vm.wishAdd,
                            expression: "wishAdd",
                          },
                        ],
                        attrs: {
                          block: "",
                          color: "#F87A1B",
                          size: "small",
                          "native-type": "submit",
                        },
                      },
                      [_vm._v("开始祈愿")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "f" }, [
      _c("img", {
        staticClass: "null-icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/7c2d418c14e8ae55c80c8760f7ba0cb3-148x346.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }