var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading && [1, 3, 4, 5, 6].includes(_vm.detail.status)
        ? _c("div", { staticClass: "page-box" }, [
            _c(
              "div",
              { staticClass: "box-header" },
              [
                _c("van-icon", {
                  staticClass: "back-icon",
                  attrs: { name: "arrow-left" },
                  on: { click: _vm.goBack },
                }),
              ],
              1
            ),
            _vm.detail.goods_info.category !== "entity"
              ? _c("div", { staticClass: "main-area f fv fc" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "good_name" }, [
                    _vm._v(_vm._s(_vm.detail.goods_info.name)),
                  ]),
                  _c("div", { staticClass: "btn-area f fc" }, [
                    _c(
                      "div",
                      { staticClass: "btn-item", on: { click: _vm.goHome } },
                      [_vm._v("返回首页")]
                    ),
                    _c(
                      "div",
                      { staticClass: "btn-item", on: { click: _vm.use } },
                      [_vm._v("马上使用")]
                    ),
                  ]),
                  _c("div", { staticClass: "tip", on: { click: _vm.kefu } }, [
                    _vm._v("使用如果有问题，请联系客服"),
                  ]),
                ])
              : _vm._e(),
            _vm.detail.goods_info.category === "entity"
              ? _c("div", { staticClass: "main-area" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "good_name" }, [
                    _vm._v(_vm._s(_vm.detail.goods_info.name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "handles f fbt" },
                    _vm._l(_vm.handleList, function ({ label, name }) {
                      return _c(
                        "button",
                        {
                          key: name,
                          attrs: { round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resHandleClick(name)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(label) + " ")]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "good_address" }, [
                    _c("div", { staticClass: "f mb16" }, [
                      _c("p", { staticClass: "label" }, [_vm._v("收件人：")]),
                      _c("p", { staticClass: "flex1" }, [
                        _c("span", { staticClass: "name" }, [
                          _vm._v(_vm._s(_vm.detail.logistics.user_name)),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("mobileCode")(_vm.detail.logistics.mobile)
                            )
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "f" }, [
                      _c("p", { staticClass: "label" }, [_vm._v("收货地址：")]),
                      _c("p", { staticClass: "flex1" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.detail.logistics.province) +
                            " " +
                            _vm._s(_vm.detail.logistics.city) +
                            " " +
                            _vm._s(_vm.detail.logistics.district) +
                            " " +
                            _vm._s(_vm.detail.logistics.street) +
                            _vm._s(_vm.detail.logistics.address) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "goods-area" },
              [
                _c("div", { staticClass: "tip" }, [_vm._v("其他人正在购买")]),
                _c("goods-list", { attrs: { list: _vm.goodsList } }),
              ],
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "vh-full w-full f fvc" },
            [
              _c("van-loading", { attrs: { type: "spinner", vertical: "" } }, [
                _vm._v("正在获取支付结果..."),
              ]),
            ],
            1
          ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "resText f" }, [
      _c("img", {
        staticClass: "address-icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/7774da745eb046-20x20.png",
          alt: "",
          srcset: "",
        },
      }),
      _c("span", { staticClass: "text" }, [_vm._v("支付成功")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "resText f" }, [
      _c("img", {
        staticClass: "address-icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/7774da745eb046-20x20.png",
          alt: "",
          srcset: "",
        },
      }),
      _c("span", { staticClass: "text" }, [_vm._v("支付成功")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }