var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c("img", {
      staticClass: "bg",
      attrs: {
        src: "https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png",
      },
    }),
    _c("div", { staticClass: "top f fc" }, [
      _c("img", {
        staticClass: "back",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/c7cfa932707506-18x35.png",
        },
        on: { click: _vm.goBack },
      }),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.calcTitle))]),
    ]),
    _c(
      "div",
      { staticClass: "tab-list f fc frd" },
      _vm._l(_vm.tabList, function (item, idx) {
        return _c(
          "div",
          {
            key: idx,
            class: `tab-item f fv fc ${
              _vm.currentTab === item.title ? "current" : ""
            }`,
            on: {
              click: function ($event) {
                return _vm.clickTab(item)
              },
            },
          },
          [
            _vm._v(" " + _vm._s(item.title) + " "),
            _c("div", {
              class: `line  ${
                _vm.currentTab === item.title ? "current-line" : ""
              }`,
            }),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "list-box" },
      _vm._l(_vm.calcList, function (item, idx) {
        return _c(
          "div",
          {
            key: idx,
            staticClass: "item f fc",
            on: {
              click: function ($event) {
                return _vm.view(item.url)
              },
            },
          },
          [
            _c("div", { staticClass: "icon-box" }, [
              _c("img", {
                staticClass: "icon",
                attrs: { src: item.cover_image },
              }),
            ]),
            _vm._v(" " + _vm._s(item.title) + " "),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }