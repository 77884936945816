var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "init-box" },
      [
        _vm._l(_vm.modeList, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "mode-box f fv fc",
              on: {
                click: function ($event) {
                  return _vm.clickMode(item.id)
                },
              },
            },
            [
              _c(
                "div",
                {
                  class: `${
                    _vm.currentId === item.id ? "selected-item" : ""
                  } mode-info f fv fc`,
                },
                [
                  _c("img", {
                    staticClass: "mode-img",
                    attrs: { src: item.img },
                  }),
                  _c("div", { staticClass: "mode-name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]
              ),
              _c("div", [
                item.id === _vm.currentId
                  ? _c("img", {
                      staticClass: "selected-icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/b7b12cf06d838d-56x56.png",
                      },
                    })
                  : _vm._e(),
              ]),
            ]
          )
        }),
        _c("div", { staticClass: "confirm-box" }, [
          _c("div", { staticClass: "confirm-btn", on: { click: _vm.submit } }, [
            _vm._v("确认"),
          ]),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }