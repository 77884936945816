var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.images.length > 0 && !_vm.loading
    ? _c(
        "div",
        { staticClass: "detail-box" },
        [
          _c(
            "van-swipe",
            {
              staticClass: "swipe-box",
              attrs: { "indicator-color": "#f87a1b", autoplay: 5000 },
            },
            _vm._l(_vm.images, function (item) {
              return _c("van-swipe-item", { key: item.id }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: _vm.$tools.compressImg(item.image, 375),
                      expression: "$tools.compressImg(item.image, 375)",
                    },
                  ],
                  staticStyle: { "object-fit": "contain" },
                  on: {
                    click: function ($event) {
                      return _vm.preview(item)
                    },
                  },
                }),
              ])
            }),
            1
          ),
        ],
        1
      )
    : !_vm.loading && _vm.images.length === 0
    ? _c(
        "div",
        { staticClass: "empty-box" },
        [
          _c("img", {
            staticClass: "empty-img",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/f6195755f05b8e-750x1334.png",
            },
          }),
          _vm.images.length === 0
            ? _c(
                "van-button",
                { staticClass: "upload-btn", on: { click: _vm.edit } },
                [_vm._v("上传音容")]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }