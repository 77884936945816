<template>
  <div class="info-box">
    <div class="avatar-box f fv fvc">
      <div class="avatar-empty f fv fvc fc border click-hover" v-if="!avatar" @click="chooseImg(1)">
        <van-icon name="plus" size="24px" />
      </div>
      <img :src="avatar" @click="chooseImg(1)" v-else class="avatar-img" />

      <div class="tip">{{ pageType == 'add' ? '添加' : '修改' }}逝者头像</div>
    </div>

    <div class="input-box f fv">
      <van-field
        v-model="name"
        center
        clearable
        placeholder="输入逝者的名字"
        class="input-item"
        maxlength="10"
        :border="false"
      ></van-field>
      <van-field
        readonly
        center
        :value="birthDay"
        @click="selectDate(1)"
        placeholder="选择逝者的出生时间"
        class="input-item"
        :border="false"
      >
        <template #button>
          <p class="input-tip">非必选</p>
        </template>
      </van-field>
      <van-field
        readonly
        center
        :value="deadDay"
        @click="selectDate(2)"
        placeholder="选择逝者的辞世时间"
        class="input-item"
        :border="false"
      >
        <template #button>
          <p class="input-tip">非必选</p>
        </template>
      </van-field>
    </div>
    <div class="image-tip">添加逝者音容</div>

    <div class="images-box f fc flex-wrap">
      <div v-for="(item, index) in images" class="img-box" :key="index">
        <img :src="item.url" @click.stop.prevent="preview(item)" />

        <img
          src="https://img-fe.tengzhihh.com/image/526d3d05af8245-50x50.png"
          class="del-icon"
          @click.stop.prevent="delImg(item, index)"
        />
      </div>
      <div class="img-box f fv fvc fc border click-hover" v-if="images.length < 9" @click="chooseImg(2)">
        <van-icon name="plus" size="24px" />
      </div>
    </div>

    <div class="f fv fvc">
      <van-button @click="save" class="save-btn" :loading="loading">保存</van-button>

      <div class="jump-btn" @click="goHome" v-if="from === 'init_select'">先不添加，回到首页</div>
    </div>
    <date-picker
      :show="datePickerFlag"
      :currentDate="pickDate"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
    ></date-picker>
    <img-picker
      ref="comImgPicker"
      :from="currentImgPickerType === 1 ? '2' : '3'"
      :aspectRatio="aspectRatio"
      @emitImgUrl="emitImgUrl"
    ></img-picker>
  </div>
</template>

<script>
import ImgPicker from '@/components/ImgPicker/ImgPicker.vue';
import DatePicker from '@/components/DatePicker/DatePicker';
import apiPath from '@/utils/apiPath';
import { ImagePreview } from 'vant';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  components: {
    ImgPicker,
    DatePicker,
  },
  data() {
    return {
      pageType: 'add',
      datePickerFlag: false,
      avatar: '',
      avatarSize: 1,
      name: '',
      birthDay: null,
      deadDay: null,
      selectDateType: 1,
      images: [],
      currentImgPickerType: 1, //1 头像 2音容
      deceasedId: '',
      pickDate: new Date(),
      currentDate: new Date(),
      from: '',
      loading: false,
    };
  },
  computed: {
    currentDay() {
      return `${this.currentDate.getFullYear()}-${this.currentDate.getMonth() + 1}-${this.currentDate.getDate()}`;
    },
    aspectRatio: function () {
      return this.currentImgPickerType === 1 ? 0.66 : NaN;
    },
  },
  mounted() {
    this.pageType = this.$route.query.page_type || 'add';
    this.from = this.$route.query.from;
    this.deceasedId = this.$route.query.id;
    if (this.pageType === 'edit') {
      this.getDetail();
    }
  },
  methods: {
    delImg(item, index) {
      this.images.splice(index, 1);
    },
    getDetail() {
      this.$http.get(`${apiPath.deceased}/${this.deceasedId}?serial_id=${this.routeQuery.serial_id}`).then(res => {
        const { name, avatar, images, birth_time, die_time } = res.data;
        this.name = name;
        this.avatar = avatar;
        images &&
          (this.images = images.map(i => {
            return {
              url: i.image,
              size: i.size,
            };
          }));
        this.birthDay = birth_time && birth_time.split(' ')[0];
        this.deadDay = die_time && die_time.split(' ')[0];
      });
    },
    goHome() {
      this.$router.replace(`/`);
    },
    save() {
      if (!this.name) {
        return this.$toast('请先输入名字');
      }
      if (!this.$tools.inputCheck(this.name)) {
        return this.$toast('名字只能输入汉字、字母、数字');
      }
      if (this.compareDate(this.birthDay, this.deadDay) > 0) {
        return this.$toast('出生时间不能大于辞世时间');
      }
      if (this.compareDate(this.birthDay, this.currentDay) > 0) {
        return this.$toast('出生时间不能大于当前时间');
      }
      if (this.compareDate(this.deadDay, this.currentDay) > 0) {
        return this.$toast('辞世时间不能大于当前时间');
      }
      this.loading = true;

      const data = {
        serial_id: this.routeQuery.serial_id,
        name: this.name,
        avatar: this.avatar,
        avatar_size: this.avatarSize,
        images: JSON.stringify(this.images.length > 0 ? this.images : []),
      };
      if (this.birthDay) {
        data['birth_time'] = this.birthDay;
      }
      if (this.deadDay) {
        data['die_time'] = this.deadDay;
      }

      if (this.pageType == 'add') {
        this.saveAdd(data);
      } else {
        data['deceased_id'] = this.deceasedId;
        this.saveEdit(data);
      }
    },
    changeDateFormat(date) {
      return date.split('-').join('/');
    },
    compareDate(d1, d2) {
      if (!d1 || !d2) {
        return 0;
      }
      d1 = this.changeDateFormat(d1);
      d2 = this.changeDateFormat(d2);
      return new Date(d1).getTime() - new Date(d2).getTime();
    },
    saveAdd(data) {
      this.$http
        .post(apiPath.addDeceased, data)
        .then(() => {
          this.$toast('保存成功');
          this.$track('H5_zuxian_add_success');
          if (this.from === 'init_select') {
            this.$router.replace(`/device/index?serial_id=${this.routeQuery.serial_id}`);
          } else {
            this.$router.replace(
              `/deceased/${this.$route.query.from === 'enshrineList' ? 'enshrineList' : 'deceasedList'}?serial_id=${
                this.routeQuery.serial_id
              }&god_type=3`
            );
          }
        })
        .finally(res => {
          this.loading = false;
        });
    },
    saveEdit(data) {
      this.$http
        .post(apiPath.editDeceased, data)
        .then(() => {
          this.$toast('保存成功');
          this.$router.replace(
            `/deceased/${this.$route.query.from === 'enshrineList' ? 'enshrineList' : 'deceasedList'}?serial_id=${
              this.routeQuery.serial_id
            }&god_type=3`
          );
        })
        .finally(res => {
          this.loading = false;
        });
    },
    preview(item) {
      ImagePreview([item.image || item.url]);
    },
    chooseImg(type) {
      this.currentImgPickerType = type;
      this.$refs.comImgPicker.uploadImg(); // 比例
    },
    selectDate(type) {
      this.selectDateType = type;
      this.pickDate = type == 1 ? this.newDateByStr(this.birthDay || '1950-01-01') : this.newDateByStr(this.deadDay);
      this.datePickerFlag = true;
    },
    newDateByStr(str) {
      if (str) {
        const time = str.split('-');
        return new Date(time[0], time[1] - 1, time[2]);
      } else {
        return new Date();
      }
    },
    onCancel() {
      this.datePickerFlag = false;
    },
    onConfirm(date) {
      if (this.selectDateType === 1) {
        this.birthDay = date;
      } else {
        this.deadDay = date;
      }
      this.datePickerFlag = false;
    },
    emitImgUrl(e) {
      if (this.currentImgPickerType === 1) {
        this.avatar = e.url;
        this.avatarSize = e.size;
      } else {
        this.images.push(e);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.info-box {
  min-height: calc(var(--vh) * 100);
  background: #ece8e7;
  position: relative;
  z-index: 1;

  .input-box {
    margin-top: 28px;
    margin-bottom: 30px;
    padding: 0 40px;
    font-size: 16px;
    .input-item {
      width: 290px;
      height: 41px;
      background: transparent;
      margin-bottom: 10px;
      border-bottom: 1px dotted #000000;
    }
  }

  .input-tip {
    font-size: 16px;
    color: #838383;
  }
  .image-tip {
    font-size: 16px;
    color: #333333;
    margin-left: 40px;
  }
  .images-box {
    width: 375px;
    box-sizing: border-box;
    padding: 0 40px;
    margin: 14px 0 40px;
    .img-box {
      border-radius: 10px;
      position: relative;
      margin: 0 25px 14px 0;
      width: 80px;
      height: 80px;
      img {
        width: 80px;
        height: 80px;
      }
      .del-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        right: -8px;
        top: -8px;
      }
    }
    .img-box:nth-child(3n) {
      margin-right: 0;
    }
  }

  .border {
    border: 1px solid #000;
  }
  .save-btn {
    width: 299px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px;
    line-height: 44px;
    font-size: 16px;
    color: #fff;
    border: none;
  }
  .jump-btn {
    text-align: center;
    font-size: 13px;
    color: #000;
    font-weight: bold;
    margin-top: 10px;
    border: none;
  }
  .avatar-box {
    padding-top: 20px;
    .avatar-empty {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 1px solid #f87a1b;
      color: #f87a1b;
      position: relative;
    }
    .avatar-img {
      width: 104px;
      height: 104px;
      border-radius: 5px;
    }
    .tip {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      margin-top: 16px;
    }
  }
}
</style>
