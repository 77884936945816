var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "offerings-timeout",
      attrs: { position: "bottom", "get-container": "body" },
      on: { "click-overlay": _vm.onCancel },
      model: {
        value: _vm.showFlag,
        callback: function ($$v) {
          _vm.showFlag = $$v
        },
        expression: "showFlag",
      },
    },
    [
      _c("div", { staticClass: "popup-box" }, [
        _c(
          "div",
          {
            staticClass: "arrow f fc fv",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onCancel.apply(null, arguments)
              },
            },
          },
          [
            _c("img", {
              staticClass: "arrow-img",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/7c67e9737668df-80x80.png",
              },
            }),
          ]
        ),
        _c("div", { staticClass: "content-box" }, [
          _c("div", { staticClass: "title" }, [_vm._v("供品说明")]),
          _c("div", { staticClass: "desc" }, [
            _vm._v(" 供品将在"),
            _c("span", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))]),
            _vm._v(
              "后送予" + _vm._s(_vm.offerTarget) + "，届时再可以重新上供品。 "
            ),
          ]),
          _c(
            "div",
            { staticClass: "content f fc fbt" },
            _vm._l(_vm.offerings, function (item) {
              return _c("img", {
                key: item.image,
                staticClass: "offers-item",
                attrs: { src: item.offer_image },
              })
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "autoOfferings",
              on: { click: _vm.autoOfferingsFn },
            },
            [_vm._v("修改 “一键供奉” 设置")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }