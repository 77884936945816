var clientType = {
    // 默认 UA
    dua: window.navigator.userAgent,
    isIOS: function () {
        return !!this.dua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    isAndroid: function () {
        return this.dua.indexOf('Android') > -1 || this.dua.indexOf('Adr') > -1;
    }
};

var jsonHandler = {
    // JSON对象转字符串
    encode: function (data) {
        if (!data) {
            return '';
        }

        if (data === 'string') {
            return data;
        }
        return JSON.stringify(data);
    },
    // JSON字符串转对象
    decode: function (data) {
        try {
            if (!data) {
                return {};
            }
            if (typeof data === 'object') {
                return data;
            }
            return JSON.parse(data);
        } catch (e) {
            console.warn(`客户端返回JSON字符串格式有误：${e.message}`);
            return {};
        }
    }
};

window.MMCWKEventWeb = {
    /**
     * 调用客户端接口的方法
     * @param {string} nativeMethodName  	客户端接口的方法名
     * @param {object} params				调用方法的入参
     * @param {string} callBackID			回调方法的方法名，存储于this.eventController._listeners
     * @param {function} callBack			回调方法
     */
    callNative: function (nativeMethodName, params, callBackID, callBack) {
        try {
            if (typeof params === 'string') {
                params = jsonHandler.decode(params);
            }

            let message = {
                methodName: nativeMethodName,
                params: params || {}
            };

            if (callBackID && callBack) {
                let _this = this;
                message.callBackID = callBackID;
                if (!this.eventController._listeners[callBackID]) {
                    this.eventController.addEvent(callBackID, function (data) {
                        callBack(data);
                        _this.eventController.removeEvent(callBackID);
                    });
                }
            }

            if (clientType.isIOS()) {
                window.webkit.messageHandlers.MMCWKEventClient.postMessage(
                    jsonHandler.encode(message)
                );
            } else if (clientType.isAndroid()) {
                window.MMCWKEventClient.postMessage(jsonHandler.encode(message));
            }
        } catch (e) {
            console.warn(`MMCWKEventWeb's callNative ERR: ${e.message}`);
        }
    },

    /**
     * 前端提供给客户端，用来调用前端方法，如：客户端调用回调集合中的方法
     * @param {string} name 	回调队列中的方法名
     * @param {object} param  	回调方法的入参
     */
    callJsFunc: function (name, param) {
        this.eventController.fireEvent(name, param);
    },

    /**
     * 维护一个回调集合，并暴露几个接口出去
     */
    eventController: {
        _listeners: {},
        addEvent: function (name, fn) {
            if (!this._listeners[name] && typeof fn === 'function') {
                this._listeners[name] = fn;
            }
            return this;
        },
        fireEvent: function (name, param) {
            if (typeof this._listeners[name] === 'function') {
                this._listeners[name](param);
            }
            return this;
        },
        removeEvent: function (name, fn) {
            if (typeof this._listeners[name] === 'function') {
                delete this._listeners[name];
            }
            return this;
        },
        clearEvent: function () {
            this._listeners = {};
            return this;
        }
    }
};

export function callNativePromise(funcName, params, cbName, callback) {
    return new Promise((resolve, reject) => {
        window.MMCWKEventWeb.callNative(funcName, params, cbName, (rsp) => {
            let _rsp = rsp;
            if (typeof rsp === 'string') {
                _rsp = jsonHandler.decode(rsp);
            }
            if (_rsp.status && _rsp.data) {
                typeof callback === 'function' && callback(_rsp);
                resolve(_rsp.data);
            } else {
                reject(new Error('error'));
            }
        });
    });
}
