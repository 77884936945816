<template>
  <div class="detail-box" v-if="images.length > 0 && !loading">
    <van-swipe class="swipe-box" indicator-color="#f87a1b" :autoplay="5000">
      <van-swipe-item v-for="item in images" :key="item.id">
        <img v-lazy="$tools.compressImg(item.image, 375)" style="object-fit: contain" @click="preview(item)" />
      </van-swipe-item>
    </van-swipe>
  </div>
  <div v-else-if="!loading && images.length === 0" class="empty-box">
    <img src="https://img-fe.tengzhihh.com/image/f6195755f05b8e-750x1334.png" class="empty-img" />
    <van-button class="upload-btn" v-if="images.length === 0" @click="edit">上传音容</van-button>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { ImagePreview } from 'vant';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  data() {
    return {
      loading: false,
      detail: {},
      images: [],
    };
  },
  created() {},
  mounted() {
    this.getDetail();
  },
  methods: {
    edit() {
      this.$router.push({
        path: '/deceased/info',
        query: Object.assign(this.routeQuery, {
          page_type: 'edit',
        }),
      });
    },
    preview(item) {
      ImagePreview([item.image]);
    },
    getDetail() {
      this.loading = true;
      this.$http.get(`${apiPath.deceased}/${this.routeQuery.id}?serial_id=${this.routeQuery.serial_id}`).then(res => {
        this.detail = res.data;
        this.images = res.data.images || [];
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.empty-box {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #e7e3df;
  left: 0;
  top: 0;
  img {
    width: 100%;
  }
  .upload-btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -165px;
    width: 329px;
    height: 43px;
    background: #f87a1b;
    border-radius: 15px;
    font-size: 15px;
    font-weight: bold;
    color: #ffeac9;
    text-align: center;
  }
}
.detail-box {
  .swipe-box {
    .van-swipe-item {
      width: 100%;
      height: calc(var(--vh) * 100);
      img {
        width: 100%;
        height: calc(var(--vh) * 100);
      }
    }
  }
}
</style>
