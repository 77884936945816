var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "confirmOrder",
      attrs: { position: "bottom", round: "" },
      on: { close: _vm.confirmPopFn },
      model: {
        value: _vm.confirmPop,
        callback: function ($$v) {
          _vm.confirmPop = $$v
        },
        expression: "confirmPop",
      },
    },
    [
      _c("div", { staticClass: "confirmTitle" }, [_vm._v("确认收到货了吗")]),
      _c("div", { staticClass: "confirmDetail f" }, [
        _c(
          "div",
          { staticClass: "good-img" },
          [
            _c("van-image", {
              attrs: {
                src:
                  _vm.confirmDetail.goods_info &&
                  _vm.confirmDetail.goods_info.image,
              },
              scopedSlots: _vm._u([
                {
                  key: "error",
                  fn: function () {
                    return [_vm._v("加载失败")]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c("div", { staticClass: "order-content" }, [
          _vm.confirmDetail.goods_info
            ? _c("div", { staticClass: "f fbt" }, [
                _c("p", { staticClass: "good-name" }, [
                  _vm._v(_vm._s(_vm.confirmDetail.goods_info.name)),
                ]),
                _c("p", { staticClass: "good-amount" }, [
                  _vm._v(
                    "¥ " + _vm._s(_vm.confirmDetail.goods_info.original_amount)
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.confirmDetail.goods_info
            ? _c("div", { staticClass: "f fbt" }, [
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  [
                    _vm.confirmDetail.goods_info.category === "virtual"
                      ? [
                          _c("p", { staticClass: "stats-count" }, [
                            _vm._v(_vm._s(_vm.confirmDetail.serial_id)),
                          ]),
                        ]
                      : _vm._e(),
                    _vm.confirmDetail.goods_info.category === "entity" &&
                    Object.values(_vm.confirmDetail.sku).length
                      ? [
                          _c("p", { staticClass: "stats-count" }, [
                            _vm._v(
                              _vm._s(_vm.confirmDetail.sku.sku) +
                                "：" +
                                _vm._s(_vm.confirmDetail.sku.sku_name)
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("p", { staticClass: "stats-count" }, [
                  _vm._v("x" + _vm._s(_vm.confirmDetail.goods_num)),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "status-total f fbt" }, [
            _c("p", { staticClass: "status" }, [
              _vm._v(_vm._s(_vm.orderStatusText[_vm.confirmDetail.status])),
            ]),
            _c("p", { staticClass: "total f" }, [
              _c("span", { staticClass: "black" }, [_vm._v("实付款")]),
              _vm._v(" ¥ " + _vm._s(_vm.confirmDetail.amount)),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "van-button",
            {
              attrs: {
                loading: _vm.confirmLoading,
                block: "",
                color: "#F87A1B",
              },
              on: { click: _vm.receiveConfirmFn },
            },
            [_vm._v("确认收货")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }