import apiPath from '@/utils/apiPath';
export default {
  createUploader(type) {
    const that = this;
    let uploader = new AliyunUpload.Vod({
      timeout: 60000,
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      enableUploadProgress: false,
      region: 'cn-shanghai',
      userId: '111',
      // 添加文件成功
      addFileSuccess: function (uploadInfo) {},
      // 开始上传
      onUploadstarted: function (uploadInfo) {
        that.uploading = true;
        const formData = new FormData();
        formData.append('filename', that.fileName);

        that.$http.formPost(apiPath.getUploadVideoAuth, formData).then((res) => {
          let uploadAuth = res.data.UploadAuth;
          let uploadAddress = res.data.UploadAddress;
          let videoId = res.data.VideoId;
          uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
        });
      },
      // 文件上传成功
      onUploadSucceed: function (uploadInfo) {
        that.videoId = uploadInfo?.videoId;
        that.uploading = false;
        that.save();
      },
      // 文件上传失败
      onUploadFailed: function (uploadInfo, code, message) {
        console.log('onUploadFailed', uploadInfo, code, message);

        that.loading = false;

        that.uploading = false;
        that.saveLoading = false;
        that.$toast('上传失败，请换个好点的网络再试试');
      },
      // 取消文件上传
      onUploadCanceled: function (uploadInfo, code, message) {
        that.loading = false;
        that.uploading = false;
      },
      // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
      onUploadProgress: function (uploadInfo, totalSize, progress) {
        let progressPercent = Math.ceil(progress * 100);
        that.$store.commit('setUploadProgress', `${progressPercent}%`);
      },
      // 上传凭证超时
      onUploadTokenExpired: function (uploadInfo) {
        console.log('onUploadTokenExpired', uploadInfo);
        that.loading = false;
        that.uploading = false;
        that.saveLoading = false;
        that.$toast('上传凭证超时，请退出后重新上传');
      },
      // 全部文件上传结束
      onUploadEnd: function (uploadInfo) {
        that.loading = false;
        that.uploading = false;
      },
    });
    return uploader;
  },
};
