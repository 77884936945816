var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "addressForm" },
    [
      _c(
        "van-form",
        {
          ref: "form",
          attrs: { "show-error-message": false, "show-error": false },
          on: { submit: _vm.onSubmit, failed: _vm.onFailed },
        },
        [
          _c("van-field", {
            attrs: {
              center: "",
              border: false,
              name: "user_name",
              label: "收货人",
              placeholder: "名字",
              maxlength: 10,
              rules: [{ required: true, message: "请填写收货人" }],
            },
            model: {
              value: _vm.form.user_name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "user_name", $$v)
              },
              expression: "form.user_name",
            },
          }),
          _c("van-field", {
            attrs: {
              center: "",
              border: false,
              type: "number",
              name: "mobile",
              label: "手机号码",
              placeholder: "手机号码",
              maxlength: "11",
              rules: [
                { required: true, message: "请输入手机号码" },
                {
                  pattern: /^(?:(?:\+|00)86)?1\d{10}$/,
                  message: "请输入正确的11位手机号",
                },
              ],
            },
            scopedSlots: _vm._u([
              {
                key: "button",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "mr-13" },
                      [
                        _vm._v("+86 "),
                        _c("van-icon", {
                          staticClass: "right-arrow",
                          attrs: { name: "arrow" },
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.form.mobile,
              callback: function ($$v) {
                _vm.$set(_vm.form, "mobile", $$v)
              },
              expression: "form.mobile",
            },
          }),
          _c("van-field", {
            staticClass: "over-h",
            attrs: {
              readonly: "",
              clickable: "",
              center: "",
              rows: "1",
              autosize: "",
              type: "textarea",
              border: false,
              name: "region",
              label: "所在地区",
              placeholder: "省、市、区、街道",
              "right-icon":
                "https://img-fe.tengzhihh.com/image/016c56c1cbff8f-36x36.png",
              rules: [{ required: true, message: "请选择所在地区" }],
            },
            on: {
              click: function ($event) {
                _vm.showRegion = true
              },
            },
            model: {
              value: _vm.form.region,
              callback: function ($$v) {
                _vm.$set(_vm.form, "region", $$v)
              },
              expression: "form.region",
            },
          }),
          _c("van-field", {
            attrs: {
              center: "",
              border: false,
              type: "textarea",
              rows: "2",
              autosize: "",
              name: "address",
              label: "详细地址",
              placeholder: "小区楼栋/乡村名称",
              rules: [{ required: true, message: "请填写详细地址" }],
            },
            model: {
              value: _vm.form.address,
              callback: function ($$v) {
                _vm.$set(_vm.form, "address", $$v)
              },
              expression: "form.address",
            },
          }),
          _c(
            "van-checkbox-group",
            {
              model: {
                value: _vm.form.is_default,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "is_default", $$v)
                },
                expression: "form.is_default",
              },
            },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-cell", {
                    attrs: { clickable: "", title: "设为默认收货地址" },
                    scopedSlots: _vm._u([
                      {
                        key: "right-icon",
                        fn: function () {
                          return [
                            _c("van-checkbox", {
                              ref: "checkboxes",
                              attrs: {
                                "label-disabled": "",
                                name: "1",
                                "checked-color": "#F87A1B",
                              },
                              on: { click: _vm.isDefaultClick },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "van-popup",
            {
              attrs: { position: "bottom" },
              model: {
                value: _vm.showRegion,
                callback: function ($$v) {
                  _vm.showRegion = $$v
                },
                expression: "showRegion",
              },
            },
            [
              _c("van-picker", {
                attrs: {
                  "show-toolbar": "",
                  "item-height": "34",
                  "visible-item-count": "8",
                  title: "请选择地区",
                  columns: _vm.regionList,
                  "value-key": "label",
                },
                on: {
                  confirm: _vm.confirmRegion,
                  cancel: function ($event) {
                    _vm.showRegion = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "submit" },
        [
          _c(
            "van-button",
            {
              directives: [
                {
                  name: "throttle",
                  rawName: "v-throttle",
                  value: _vm.submit,
                  expression: "submit",
                },
              ],
              attrs: {
                block: "",
                color: "#F87A1B",
                size: "small",
                "native-type": "submit",
              },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }