export default {
  userOpenId(state) {
    return state.userOpenId;
  },
  userAccessToken(state, info) {
    return state.userAccessToken;
  },
  userUserInfo(state, info) {
    return state.userInfo;
  },
};
