var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "back f fvc" }, [
    _c("div", {
      staticClass: "icon",
      style: {
        "border-bottom-color": _vm.borderColor,
        "border-left-color": _vm.borderColor,
      },
      on: { click: _vm.backFn },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }