import { request } from '@/utils/request';

export function getDailyTaskList(params) {
  return request({
    url: '/fude/task',
    method: 'get',
    params,
  });
}
export function getGodWish(params) {
  return request({
    url: '/wish/god-wish',
    method: 'get',
    params,
  });
}
export function addGodWish(params) {
  return request({
    url: '/wish/add',
    method: 'put',
    params,
  });
}
export function editGodWish(data) {
  return request({
    url: '/wish/replace',
    method: 'post',
    data,
  });
}

export function intensifyGodWish(data) {
  return request({
    url: '/wish/intensify',
    method: 'post',
    data,
  });
}
export function getRankingData(params) {
  return request({
    url: '/fude/ranking',
    method: 'get',
    params,
  });
}
export function getRaskFude(params) {
  return request({
    url: '/fude/get-task-fude',
    method: 'get',
    params,
  });
}
