var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "img",
    attrs: {
      src: "https://img-fe.tengzhihh.com/image/7fc7da05510999-750x3529.jpg",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }