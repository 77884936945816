<template>
  <div class="page">
    <div class="top-bar fvc">
      <img src="https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png" class="go-back-icon" @click="goBack" />
      <div class="bar-name">功德榜</div>
      <div class="bar-des" @click="explanation">功德说明</div>
    </div>
    <div class="common-css my-fude">
      <div class="f fbt" @click="showFudeDetail">
        <div class="my-fude-title">我的功德<van-icon name="arrow" /></div>
        <div class="my-fude-num f">
          <img class="icon" src="https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png" />
          <div class="text">{{ user_fu_de || 0 }}</div>
        </div>
      </div>
      <div class="f fbt">
        <div>
          <span class="label">总榜排名：</span
          ><span class="value">{{ total_rank_num ? total_rank_num + '名' : '尚未进榜' }}</span>
        </div>
        <div>
          <span class="label">日榜排名：</span
          ><span class="value">{{ day_rank_num ? day_rank_num + '名' : '尚未进榜' }}</span>
        </div>
      </div>
    </div>
    <div class="common-css fude-rank">
      <div class="fvc">
        <div class="single-choice f">
          <div
            :class="['choice', checkChoice === key ? 'check-choice' : '']"
            v-for="{ label, key } in nav"
            :key="key"
            @click="checkChoiceClick(key)"
          >
            {{ label }}
          </div>
        </div>
      </div>
      <div class="rank-grid">
        <div class="rank-tr">排名</div>
        <div class="rank-tr">昵称</div>
        <div class="rank-tr">功德</div>
      </div>
      <div v-show="rankList.length" class="rank-content">
        <div v-for="(item, index) in rankList" class="rank-list rank-grid">
          <div class="index fvc">
            <img
              v-if="index === 0"
              src="https://img-fe.tengzhihh.com/image/7b6da973fa3bb24fef498c061ae44451-56x64.png"
              class="rank-icon"
            />
            <img
              v-else-if="index === 1"
              src="https://img-fe.tengzhihh.com/image/886a5bcbb1580122934d63296df87647-56x63.png"
              class="rank-icon"
            />
            <img
              v-else-if="index === 2"
              src="https://img-fe.tengzhihh.com/image/43d18bb093029cb3cddf7e228b0597f8-56x64.png"
              class="rank-icon"
            />
            <div v-else>
              {{ index + 1 }}
            </div>
          </div>
          <div class="fc">
            <img class="avatar" :src="item.avatar" alt="" srcset="" /><span class="name">{{ item.name }}</span>
          </div>
          <div class="numbers">{{ item.numbers }}</div>
        </div>
      </div>
      <div v-show="!rankList.length" class="rank-content">
        <img src="https://img-fe.tengzhihh.com/image/ca001910984ac3-140x132.png" class="null-icon" />
        <p class="null-text">尚无人进入榜单</p>
      </div>
      <!-- <van-tabs v-model:active="active" background="#e9e7e6" animated sticky swipeable>
        <van-tab v-for="item in nav" :title="item.title" :key="item.id">
          <template v-if="!!item.list.length">
            <div v-for="l in item.list" :key="l.user_id" class="f">
              <div class="icon"><img :src="l.avatar" alt="" srcset="" style="width: 40px" /></div>
              <div class="content">
                <div class="name">{{ l.name }}</div>
                <div class="numbers">功德：{{ l.numbers }}</div>
              </div>
            </div>
          </template>
          <div v-else>暂无人进入榜单</div>
        </van-tab>
      </van-tabs> -->
    </div>
    <div class="bottom-tips">只显示榜单前20</div>
  </div>
</template>

<script>
import { getRankingData } from '@/api/fude';
export default {
  components: {},
  data() {
    return {
      user_fu_de: '',
      total_rank_num: '',
      day_rank_num: '',
      checkChoice: 'total_rank_list',
      rankList: [],
      total_rank_list: [],
      day_rank_list: [],
      nav: [
        { label: '总榜', key: 'total_rank_list' },
        { label: '日榜', key: 'day_rank_list' },
      ],
    };
  },
  created() {
    this.getRankingData();
  },
  methods: {
    showFudeDetail() {
      this.$router.push(`/fude/rankDetail?fude_type=user_fude&serial_id=${this.$route.query.serial_id}`);
    },
    explanation() {
      this.$router.push(`/fude/explanation?fude_type=user_fude&serial_id=${this.$route.query.serial_id}`);
    },
    async getRankingData() {
      const { code, data } = await getRankingData();
      this.user_fu_de = data.user_fu_de;
      this.total_rank_num = data.total_rank_num;
      this.day_rank_num = data.day_rank_num;
      this.rankList = data[this.checkChoice];
    },
    checkChoiceClick(choice) {
      this.checkChoice = choice;
      this.rankList = [];
      this.getRankingData();
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 375px;
  min-height: calc(var(--vh) * 100);
  background-image: url('https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0 8px 50px;
  box-sizing: border-box;
  .top-bar {
    height: 44px;
    margin-bottom: 16px;
    position: relative;
    .go-back-icon {
      width: 28px;
      height: 28px;
      position: absolute;
      left: 16px;
    }
    .bar-name {
      text-align: center;
      font-size: 20px;
      line-height: 44px;
      color: #7b5439;
    }
    .bar-des {
      right: 16px;
      font-size: 14px;
      color: #7b5439;
      position: absolute;
      right: 16px;
    }
  }
  .common-css {
    border-radius: 8px;
    background: rgba(255, 235, 210, 0.6);
  }
  .my-fude {
    padding: 12px 20px 16px;
    margin-bottom: 20px;
    .my-fude-title {
      font-weight: 500;
      font-size: 20px;
      color: #85531f;
      line-height: 25px;
      margin-bottom: 17px;
    }
    .my-fude-num {
      .icon {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }
      .text {
        line-height: 25px;
        font-size: 18px;
        color: #f87a1b;
      }
    }
    .label {
      font-weight: 400;
      font-size: 14px;
      color: #7b5439;
      margin-right: 10px;
    }
    .value {
      font-weight: 400;
      font-size: 14px;
      color: #f87a1b;
    }
  }
  .fude-rank {
    padding: 20px 20px 0;
    margin-bottom: 16px;
    .single-choice {
      background: rgba(232, 203, 162, 1);
      border-radius: 5px;
      margin-bottom: 15px;
      .choice {
        padding: 9px 32px;
        font-weight: 400;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.7);
      }
      .check-choice {
        border-radius: 5px;
        background: #f87a1b;
        color: rgba(255, 255, 255, 0.9);
      }
    }
    .rank-grid {
      display: grid;
      grid-template-columns: 28px auto 60px;
      grid-column-gap: 20px;
    }
    .rank-tr {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: rgba(0, 0, 0, 0.35);
      text-align: center;
      margin-bottom: 20px;
    }
    .rank-content {
      height: 480px;
      overflow: auto;
      .rank-list {
        margin-bottom: 16px;
        .index {
          text-align: center;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          .rank-icon {
            width: 28px;
            height: 32px;
          }
        }
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-right: 16px;
        }
        .name {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.65);
          width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .numbers {
          text-align: center;
          font-size: 16px;
          color: #f87a1b;
        }
      }
    }
    .null-icon {
      display: flex;
      width: 70px;
      height: 66px;
      margin: 94px auto 24px;
    }
    .null-text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
    }
  }
  .bottom-tips {
    text-align: center;
    font-size: 16px;
    color: #7b5439;
  }
}
</style>
