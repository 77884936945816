var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm.show && _vm.currentData
        ? _c("div", { staticClass: "box" }, [
            _c("img", {
              staticClass: "logo",
              attrs: {
                src: "https://fe-img.tengzhihh.com/image/ece52fb90ebe5345c114c50e25f9ec27-132x154.png",
                alt: "",
              },
            }),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.currentData.title)),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.currentData.desc)),
            ]),
            _c("div", { staticClass: "btn", on: { click: _vm.handleClick } }, [
              _vm._v(_vm._s(_vm.currentData.button)),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }