var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.godDetail
    ? _c(
        "div",
        { staticClass: "detail-box f fv fc", attrs: { id: "detail-box" } },
        [
          _c("div", { staticClass: "img-box" }, [
            _c("img", {
              staticClass: "detail-img",
              attrs: { src: _vm.godDetail.wap_detail_image },
              on: { click: _vm.showVideo },
            }),
            _c("img", {
              staticClass: "shadow",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/f8cb483ff0ece9-609x69.png",
              },
            }),
          ]),
          _c("div", { staticClass: "content" }, [
            _vm.godDetail.moral
              ? _c("div", [_vm._v(_vm._s(_vm.godDetail.moral))])
              : _vm._e(),
            _vm.godDetail.unique_number
              ? _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                  _vm._v(
                    " 数字藏品唯一ID：" +
                      _vm._s(_vm.godDetail.unique_number) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.godDetail.introduce) + " "),
          ]),
          _vm.godDetail.expire_time
            ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
                _vm._v(
                  "供奉时间至：" +
                    _vm._s(_vm.calcTime(_vm.godDetail.expire_time))
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "back-btn", on: { click: _vm.confirm } }, [
            _vm._v("确认"),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }