var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `playing-page ${_vm.from === "lesson" ? "lesson-page" : ""} `,
      style: _vm.calcStyle,
      on: { click: _vm.closePlayingMusicList },
    },
    [
      _c("div", { staticClass: "detail-box" }, [
        _c(
          "div",
          {},
          [
            _c("GoBack", { attrs: { backFunction: _vm.backFunction } }),
            _vm.from === "lesson"
              ? _c("img", {
                  staticClass: "setting",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/e8d4b3316fbe14-104x56.png",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$utils.checkAuth(_vm.serialId, _vm.edit)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.from === "music"
          ? _c("div", { staticClass: "detail-content f fv fc" }, [
              _c("div", { staticClass: "detail-name" }, [
                _vm._v(" " + _vm._s(_vm.currentMusic.name) + " "),
              ]),
              _c("div", { staticClass: "main-box" }, [
                _c("img", {
                  class: `rod-img
          ${_vm.isPlaying ? "playing-rod-img" : ""}`,
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/733201b554ac4c-202x316.png",
                  },
                }),
                _c("img", {
                  staticClass: "main-img",
                  attrs: {
                    src:
                      _vm.indexMode === "western"
                        ? "https://img-fe.tengzhihh.com/image/0089b3e3a27570-546x556.jpg"
                        : "https://img-fe.tengzhihh.com/image/de16a894ccd581-580x580.png",
                  },
                }),
                _c("div", { staticClass: "img-bg" }),
              ]),
            ])
          : _vm._e(),
        _vm.from === "lesson" && _vm.indexMode !== "western"
          ? _c("div", { staticClass: "lesson-content f fv fc" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showLyric,
                      expression: "showLyric",
                    },
                  ],
                  staticClass: "lyric-box",
                },
                _vm._l(_vm.calcCurrentLyrics, function (item, idx) {
                  return _c("div", { key: item.key }, [
                    _vm._v(" " + _vm._s(item && item.text) + " "),
                  ])
                }),
                0
              ),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "player-box f fc fbt" },
        [
          _c("div", { staticClass: "time" }, [
            _vm._v(
              " " + _vm._s(_vm.$tools.secondToMinute(_vm.currentPosition)) + " "
            ),
          ]),
          _c("PlayProgress", {
            attrs: {
              musicId: _vm.currentMusic.id,
              currentRate: _vm.currentRate,
              currentPosition: _vm.currentPosition,
            },
            on: { currentPositionChange: _vm.currentPositionChange },
          }),
          _c("div", { staticClass: "time" }, [
            _vm._v(
              " " + _vm._s(_vm.$tools.secondToMinute(_vm.totalValue)) + " "
            ),
          ]),
        ],
        1
      ),
      _vm.from === "lesson" && _vm.indexMode !== "western"
        ? _c("img", {
            staticClass: "up-voice-icon",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/18d86492bbe5e3-60x60.png",
            },
            on: { click: _vm.showVoiceControl },
          })
        : _vm._e(),
      _c("div", { staticClass: "bottom-area" }, [
        _vm.from === "music"
          ? _c("div", { staticClass: "options-area f fc frd" }, [
              _c("div", { staticClass: "playing-mode" }, [
                _c("img", {
                  staticClass: "playing-mode",
                  attrs: { src: _vm.playingModeIcon.white },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.changeCurrentPlayingMode.apply(null, arguments)
                    },
                  },
                }),
              ]),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.previous.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "control-btn",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/703e802d70b22e-50x50.png",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.playControl.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isPlaying,
                        expression: "!isPlaying",
                      },
                    ],
                    staticClass: "play-btn",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/6b245d44d0fdcb-100x100.png",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPlaying,
                        expression: "isPlaying",
                      },
                    ],
                    staticClass: "play-btn",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/2d53e0d9bf52bc-100x100.png",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.next.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "control-btn",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/727fe638c481d9-50x50.png",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.showMusicList.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "list-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/1f29133e544a14-37x37.png",
                    },
                  }),
                ]
              ),
            ])
          : _c("div", { staticClass: "options-area f fc" }, [
              _vm.indexMode !== "western"
                ? _c(
                    "div",
                    {
                      class: `lyric-btn ${_vm.showLyric ? "show" : ""}`,
                      on: { click: _vm.changeShowLyric },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.showLyric ? "关闭" : "显示") + "经文 "
                      ),
                    ]
                  )
                : _c("img", {
                    staticClass: "down-voice-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/a3b8994630d348-60x60.png",
                    },
                    on: { click: _vm.showVoiceControl },
                  }),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.playControl.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isPlaying,
                        expression: "!isPlaying",
                      },
                    ],
                    staticClass: "play-btn lesson-play",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/6b245d44d0fdcb-100x100.png",
                    },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPlaying,
                        expression: "isPlaying",
                      },
                    ],
                    staticClass: "play-btn lesson-play",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/2d53e0d9bf52bc-100x100.png",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.showMusicList.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "list-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/1f29133e544a14-37x37.png",
                    },
                  }),
                ]
              ),
            ]),
      ]),
      _vm.actions.length > 0 && _vm.from === "music"
        ? _c("van-popover", {
            staticClass: "time-set-box",
            attrs: {
              trigger: "click",
              placement: "top-start",
              actions: _vm.actions,
            },
            on: { select: _vm.onSelect },
            scopedSlots: _vm._u(
              [
                {
                  key: "reference",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "time-set" }, [
                        _c("img", {
                          staticClass: "time-icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/7872bcd9d2fa97-50x50.png",
                          },
                        }),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3629612607
            ),
            model: {
              value: _vm.timeoutPopover,
              callback: function ($$v) {
                _vm.timeoutPopover = $$v
              },
              expression: "timeoutPopover",
            },
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          on: {
            "click-overlay": function ($event) {
              _vm.volumeControlFlag = false
            },
          },
          model: {
            value: _vm.volumeControlFlag,
            callback: function ($$v) {
              _vm.volumeControlFlag = $$v
            },
            expression: "volumeControlFlag",
          },
        },
        [
          _c(
            "div",
            { staticClass: "control-box f fc" },
            [
              _c("img", {
                staticClass: "icon",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/fa7b62c864d486-48x48.png",
                },
              }),
              _c("van-slider", {
                attrs: {
                  "bar-height": "2px",
                  "button-size": "12px",
                  max: "15",
                  "active-color": "#F87A1B",
                },
                on: { change: _vm.volumeChange },
                model: {
                  value: _vm.currentVolume,
                  callback: function ($$v) {
                    _vm.currentVolume = $$v
                  },
                  expression: "currentVolume",
                },
              }),
              _c("div", { staticClass: "number" }, [
                _vm._v(_vm._s(_vm.currentVolume)),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }