var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c("van-icon", {
        staticClass: "goback",
        attrs: { name: "arrow-left", size: "17" },
        on: { click: _vm.goback },
      }),
      _c("div", { staticClass: "layout" }, [
        _c("div", { staticClass: "mb28" }, [
          _c("p", { staticClass: "label" }, [_vm._v("敲击祈福文案")]),
          _c(
            "div",
            { staticClass: "type f" },
            _vm._l(_vm.buttonList, function ({ value, label }) {
              return _c(
                "div",
                {
                  key: value,
                  class: ["button", _vm.title_type === value ? "active" : ""],
                  on: {
                    click: function ($event) {
                      _vm.title_type = value
                    },
                  },
                },
                [_vm._v(" " + _vm._s(label) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.title_type === 1,
                  expression: "title_type === 1",
                },
              ],
              staticClass: "f fvc",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.fish_title,
                    expression: "fish_title",
                  },
                ],
                staticClass: "fish_title",
                attrs: {
                  type: "text",
                  maxlength: "5",
                  clear: "",
                  placeholder: "请输入悬浮文字，例如：福报+1",
                },
                domProps: { value: _vm.fish_title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.fish_title = $event.target.value
                  },
                },
              }),
            ]
          ),
          _c("div", [
            _c("p", { staticClass: "label" }, [_vm._v("个性化设置")]),
            _c("div", { staticClass: "setting" }, [
              _c(
                "div",
                { staticClass: "f fbt" },
                [
                  _c("span", { staticClass: "label-txt" }, [
                    _vm._v("修行语录"),
                  ]),
                  _c("van-switch", {
                    attrs: { "active-color": "#F87A1B", size: "18px" },
                    model: {
                      value: _vm.is_quotation,
                      callback: function ($$v) {
                        _vm.is_quotation = $$v
                      },
                      expression: "is_quotation",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "tips-txt" }, [
                _vm._v(_vm._s(_vm.quotation_tips)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("button", { staticClass: "submit", on: { click: _vm.submit } }, [
        _vm._v("保存"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }