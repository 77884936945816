<template>
  <van-popup v-model="dialogFlag" get-container="body">
    <div class="f fv fc dialog-box" v-show="dialogFlag">
      <p class="tip">{{ title }}</p>
      <div class="content-box" v-if="content">
        <p class="content" v-html="content"></p>
      </div>
      <div class="f fc frd btn-box">
        <div @click="cancel" class="cancel-btn" v-if="showCancelBtn">{{ cancelText }}</div>
        <div @click="confirmClick" :class="`${showCancelBtn ? '' : 'big-btn'} confirm-btn`">{{ confirmText }}</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      dialogFlag: false,
      showCancelBtn: true,
      title: '',
      content: '',
      confirmText: '确定',
      cancelText: '取消',
      confirmCallback: undefined,
      cancelCallback: undefined,
    };
  },
  methods: {
    initDialog(options) {
      this.title = options.title;
      this.content = options.content;
      this.showCancelBtn = options.showCancelBtn !== false;
      options.confirmText && (this.confirmText = options.confirmText);
      options.cancelText && (this.cancelText = options.cancelText);
      typeof options.confirmCallback === 'function' && (this.confirmCallback = options.confirmCallback);
      typeof options.cancelCallback === 'function' && (this.cancelCallback = options.cancelCallback);

      this.dialogFlag = true;
    },
    cancel() {
      if (typeof this.cancelCallback === 'function') {
        this.cancelCallback();
      } else {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.dialogFlag = false;
      this.$nextTick(() => {
        this.clearOptions();
      });
    },
    clearOptions() {
      this.title = '';
      this.showCancelBtn = true;
      this.content = '';
      this.confirmText = '确定';
      this.cancelText = '取消';
    },
    confirmClick() {
      (typeof this.confirmCallback === 'function' && this.confirmCallback()) || this.$emit('confirmClick');
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-box {
  width: 295px;
  max-height: 70vh;
  padding: 0 0 20px;
  background: #fff;
  border-radius: 15px;

  .tip {
    font-size: 20px;
    color: #2e2d2d;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .content-box {
    padding: 10px 30px;
    overflow-y: scroll;
    max-height: 80%;
    .content {
      text-align: justify;
      font-size: 14px;
      color: #707070;
    }
  }

  .btn-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 22px 0 0;
    .cancel-btn {
      width: 100px;
      height: 40px;
      background: #f6f6f6;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 10px;
    }

    .confirm-btn {
      width: 100px;
      height: 40px;
      background: #f87a1b;
      border-radius: 10px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #ffeac9;
    }
    .big-btn {
      width: 235px !important;
    }
  }
}
</style>
