var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-box" }, [
      _c("h2", [_vm._v("《“智能神台”用户协议》")]),
      _c("br"),
      _c("p", [
        _vm._v(
          " 本《软件使用及服务协议》（下称《协议》），是您（下称“用户”）与智能神台之间关于用户安装、使用、复制“智能神台”手机软件（包括所有手机系统平台版本，下称“智能神台”）；注册、使用、管理智能神台帐号；以及使用智能神台相关服务所订立的协议。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "用户应认真阅读并充分理解此《“智能神台”用户协议》。请审慎阅读并选择接受或不接受本《协议》（未成年人应在法定监护人陪同下阅读）。用户的安装、开通订阅、使用、帐号获取和登录等行为均被视为对本《协议》的接受，并同意接受各项条款的约束。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "本《协议》会由智能神台随时更新，更新后的协议条款一经公布，即时代替原来的协议条款，用户可随时查阅最新版协议条款。在智能神台修改《协议》条款后，用户继续使用“智能神台”软件和服务将被视为已接受了修改后的协议。如果用户不接受修改后的条款，请立即停止使用。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "用户因使用本平台服务所产生的及与本平台服务有关的争议，由本平台与用户协商解决，协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("一、知识产权声明 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "1.1“智能神台”是由智能神台开发，一切著作权、商标权、专利权、商业秘密等知识产权，以及相关的所有信息内容，包括但不限于：图标、图表、色彩、界面设计、版面框架、数据、电子文档等均受中华人民共和国著作权法、商标法、专利法、反不正当竞争法和相应的国际条约以及其他知识产权法律法规的保护，智能神台享有上述知识产权。 "
        ),
      ]),
      _c("p", [
        _vm._v(
          "1.2未经智能神台书面同意，用户不得以任何目的使用或转让上述知识产权，智能神台保留追究上述未经许可行为的权利。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("二、订阅说明 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "2.1智能神台允许用户以订阅方式购买内容。在账户设置的“管理订阅”部分取消订阅之前，付费订阅自动续展。如果付费订阅价格上涨，智能神台将在智能神台APP通知用户。在最新付费订阅开始前24小时之内，智能神台将通过用户的支付方式收费。若智能神台无法通过用户的支付方式收费，无论因为任何原因（比如，过期或资金不足），而用户尚未取消付费订阅，则依然有责任支付任何未付金额，智能神台会尝试在用户更新的支付方式信息后通过用户的支付方式收费。在从用户的支付方式中扣除订阅费用前，部分付费订阅可提供免费试用。在智能神台收费前，如果用户决定停止付费订阅，请在免费试用结束前取消订阅。订阅服务费用苹果收取。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("三、帐号使用需知 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "3.1“智能神台”帐号的所有权归智能神台，用户完成注册后获得相应的使用权。用户承担“智能神台”帐号与密码的保管责任，并对所注册的帐号及密码下的一切活动负全部责任。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "3.2用户的“智能神台”帐号在丢失或遗忘密码后，可以通过绑定过“智能神台”帐号的手机帐号进行登录密码重置。智能神台只负责核对验证信息是否与帐号相关信息一致，而不对信息提供者身份进行证实，不对冒名验证行为承担任何责任。同时，智能神台不承诺通过帐号相关的个人信息进行验证一定能找回帐号。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "3.3用户不得实施包括但不限于下列行为： 通过非智能神台开发、授权或认可的软件、插件、外挂等登录或使用“智能神台”软件和服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("1、去除“智能神台”中的版权信息 ")]),
      _c("br"),
      _c("p", [_vm._v("2、对“智能神台”进行反向工程、反向汇编、反向编译等； ")]),
      _c("br"),
      _c("p", [_vm._v("3、利用安全漏洞恶意攻击智能神台； ")]),
      _c("br"),
      _c("p", [_vm._v("4、为盗取测算内容，多次购买内容并无理申请退款； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5、以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件和和智能神台提供的其它服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "3.4用户若违反上述规定，智能神台有权在不事先通知用户的情况下注销用户帐号。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "3.5使用“智能神台”必须遵守国家有关法律和政策并遵守本《协议》。对于用户违法或违反本《协议》的使用而引起的一切责任，由用户负全部责任，一概与智能神台及合作方无关。导致智能神台及合作方损失的，智能神台及合作方有权要求用户赔偿，并有权保留相关记录。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "3.6用户若通过非官方网站，或非智能神台授权网站下载的“智能神台”，将可能导致不可预知的风险，由此产生的一切法律责任与纠纷一概与智能神台无关。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v("3.7智能神台有权在“智能神台”中投放不同形式的广告信息。 "),
      ]),
      _c("br"),
      _c("p", [_vm._v("四、隐私保护 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "4.1个人隐私信息指可以用于对用户进行个人辨识或涉及个人通信的信息，包括：IP地址，以及用户在参与智能神台及合作方发起的特定活动时填写的姓名、身份证号、手机号码、联系地址。非个人隐私信息指用户在使用“智能神台”时的操作状态、设备信息以及使用习惯等明确客观记录在智能神台服务器端的基本记录信息，和其他一切个人隐私信息范围外的普通信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "4.2用户若参与智能神台及合作方发起的特定活动，需要向智能神台及合作方提供特定的个人隐私信息，智能神台及合作方不得向任何其它组织、单位和个人公开、透露相关信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "4.3智能神台会采取合理的措施保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或用户同意等原因外，智能神台未经用户同意不向除合作方以外的第三方公开、透露用户个人隐私信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "4.4用户在本平台进行注册、浏览、下单、评价、参加活动等行为时，涉及用户个人隐私信息的，包括真实姓名/名称、通信地址、联系电话、电子邮箱、订单详情、评价或反馈、投诉内容、cookies等信息，本站有权从完成交易、提供配送、售后及客户服务、开展活动、完成良好的客户体验等多种角度予以收集并使用，并将对其中涉及个人隐私信息予以严格保密，除非得到用户的授权、为履行强行性法律义务（如国家安全机关指令）或法律另有规定、本注册协议或其他条款另有约定外，本站不会向外界披露用户的隐私信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "4.5用户的购买记录和订单记录会被自动上传至智能神台服务器，在用户使用“智能神台”帐号时，可以通过智能神台服务器查看购买记录和订单记录。购买记录和订单记录用户用于记录用户的付费凭证。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "4.6用户应自行诚信向本站提供注册资料，用户保证提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且智能神台保留单方终止用户使用本平台各项服务的权利。  "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("五、法律责任与免责申明 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.1智能神台将会尽其商业上的合理努力以保护用户的设备资源及通讯的隐私性和完整性，但是，用户承认和同意智能神台不能就此事提供任何保证。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.2智能神台可以根据用户的使用状态和行为，改进“智能神台”的功能、用户体验和服务，开发或调整软件功能。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.3 遇到不可抗拒的市场下架行为，导致“智能神台”不能继续付费，智能神台及合作方不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.4在任何情况下因使用或不能使用本“智能神台”所产生的直接、间接、偶然、特殊及后续的损害及风险，智能神台及合作方不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.5因技术故障等不可抗事件影响到服务的正常运行的，智能神台及合作方承诺在第一时间内与相关单位配合，及时处理进行修复，但用户因此而遭受的一切损失，智能神台及合作方不承担责任。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.6用户通过“智能神台”与其他用户联系，因受误导或欺骗而导致或可能导致的任何心理、生理上的伤害以及经济上的损失，由过错方依法承担所有责任，一概与智能神台及合作方无关。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.7用户应合理看待测算结果，命是先天的因素，不可改变，运可改变，运会因为个人行为有所改变而变化。因此测算结果可作为参考依据，但还需结合自身努力才达到理想的彼岸。只想依赖算命改变命运而不努力的用户，智能神台及合作方不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "5.8用户手动删除，或主动行为注销智能神台帐号导致信息销毁的，智能神台及合作方不承担任何责任。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "在本协议中未声明的其他一切权利，仍归智能神台所有。智能神台保留对本协议的最终解释权利。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }