var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pageStep === "index"
    ? _c(
        "div",
        { attrs: { id: "god-index-container" } },
        [
          _c("div", { staticClass: "index-top f fc fbt" }, [
            _c("img", {
              staticClass: "top-icon",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/5e5eeee8268676-56x56.png",
              },
              on: { click: _vm.goDevice },
            }),
            _c("div", { staticClass: "index-top-box f fc fvc no-western" }, [
              _c(
                "div",
                { staticClass: "btn", on: { click: _vm.enshrineAdd } },
                [_vm._v("添加逝者")]
              ),
              _c(
                "div",
                { staticClass: "btn", on: { click: _vm.enshrineSetting } },
                [_vm._v("供奉设置")]
              ),
            ]),
            _c("img", {
              staticClass: "top-icon",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/01875a0e427436-56x56.png",
              },
              on: { click: _vm.showExplanation },
            }),
          ]),
          _c("img", {
            staticClass: "bg-img",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/252da40a8e5eda3b79833e25b82072e2-750x1624.png",
            },
          }),
          _c("img", {
            staticClass: "bg-img-light abs-c",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/14d060fbece29a82b4b0e0d88adc9eae-538x538.png",
            },
          }),
          _c(
            "swiper",
            {
              ref: "godSwiper",
              staticClass: "swiper",
              attrs: { options: _vm.swiperOption },
            },
            [
              _vm._l(_vm.enshrineList, function (item, index) {
                return _c(
                  "swiper-slide",
                  { key: item.id },
                  [
                    index !== 0
                      ? _c("img", {
                          staticClass: "arrow arrow-left",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.upDateIndex(index - 1)
                            },
                          },
                        })
                      : _vm._e(),
                    index !== _vm.enshrineList.length - 1
                      ? _c("img", {
                          staticClass: "arrow arrow-right",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.upDateIndex(index + 1)
                            },
                          },
                        })
                      : _vm._e(),
                    _c("EnshrineDetail", {
                      attrs: { enshrineId: item.id },
                      on: {
                        quickOffering: _vm.quickOffering,
                        setValue: _vm.setValue,
                      },
                    }),
                  ],
                  1
                )
              }),
              _c("div", {
                staticClass: "swiper-pagination",
                attrs: { slot: "pagination" },
                slot: "pagination",
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm.pageStep === "offerings"
    ? _c(
        "div",
        [
          _c("OfferingsList", {
            ref: "OfferingsListRef",
            attrs: {
              godType: 3,
              serialId: _vm.routeQuery.serial_id,
              godId: _vm.enshrineList[_vm.activeIndex].id,
              indexMode: "all",
            },
            on: { setValue: _vm.setValue, updateGodInfo: _vm.quickOffering },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }