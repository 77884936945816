// 订单列表:0:未支付，1:已支付，待发货，2：已取消，3：全额退款,4，部分退款，5已发货 6已完成,99全部订单
export const orderStatus = {
  unpaid: 0,
  unshipped: 1,
  cancel: 2,
  refund: 3,
  rebates: 4,
  unreceived: 5,
  completed: 6,
  allorder: 99,
};
export const orderStatusTxt = {
  [orderStatus.unpaid]: '等待买家付款',
  [orderStatus.unshipped]: '待发货',
  [orderStatus.cancel]: '订单已取消',
  [orderStatus.refund]: '已全额退款',
  [orderStatus.rebates]: '已部分退款',
  [orderStatus.unreceived]: '卖家已发货',
  [orderStatus.completed]: '交易成功',
};
export const orderStatusText = ['等待买家付款', '待发货', '已取消', '全额退款', '部分退款', '卖家已发货', '交易成功'];
export const orderCategory = {
  entity: '实物',
  virtual: '虚拟',
  recharge: '充值',
};
export const orderTabs = [
  { name: 'allorder', label: '全部' },
  { name: 'unpaid', label: '待付款' },
  { name: 'unshipped', label: '待发货' },
  { name: 'unreceived', label: '待收货' },
  { name: 'completed', label: '已完成' },
];
export const handleMap = {
  [orderStatus.unpaid]: [
    { name: 'cancel', label: '取消订单' },
    { name: 'payment', label: '继续付款', color: '#F87A1B' },
  ],
  [orderStatus.unshipped]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.cancel]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.unreceived]: [
    { name: 'express', label: '查看物流' },
    { name: 'service', label: '联系客服', color: '#F87A1B' },
    { name: 'confirm', label: '确认收货', color: '#F87A1B' },
  ],
  [orderStatus.refund]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.rebates]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.completed]: [
    { name: 'express', label: '查看物流' },
    { name: 'service', label: '联系客服' },
    { name: 'repeat', label: '再来一单', color: '#F87A1B' },
  ],
};
export const virtualHandle = {
  [orderStatus.unpaid]: [
    { name: 'cancel', label: '取消订单' },
    { name: 'payment', label: '继续付款', color: '#F87A1B' },
  ],
  [orderStatus.unshipped]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.cancel]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.unreceived]: [{ name: 'confirm', label: '确认收货', color: '#F87A1B' }],
  [orderStatus.refund]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.rebates]: [{ name: 'service', label: '联系客服' }],
  [orderStatus.completed]: [{ name: 'service', label: '联系客服' }],
};
