var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box video-box f fv fc" },
    [
      _c("video", {
        staticClass: "video-item",
        staticStyle: { "object-fit": "contain", background: "#000" },
        attrs: {
          id: "video",
          playsinline: "",
          preload: "meta",
          controls: "",
          "x5-video-player-type": "h5",
        },
      }),
      _c("van-button", { staticClass: "next-btn", on: { click: _vm.next } }, [
        _vm._v(" 下一步 "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }