var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.initLoading
    ? _c(
        "div",
        { staticClass: "deceased-page-box" },
        [
          _c("BackIcon", { staticClass: "back", on: { back: _vm.backFn } }),
          _c("EnshrineSettingTab", {
            attrs: { list: _vm.tabList },
            on: { tabClick: _vm.tabClick },
          }),
          _vm.list.length > 0
            ? _c("div", { staticClass: "header-btn f fc fbt" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn-item",
                    on: {
                      click: function ($event) {
                        return _vm.$utils.checkAuth(
                          _vm.routeQuery.serial_id,
                          _vm.add
                        )
                      },
                    },
                  },
                  [_vm._v("+添加逝者")]
                ),
                _vm.manageStatus === 0
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-item",
                        on: {
                          click: function ($event) {
                            _vm.$utils.checkAuth(
                              _vm.routeQuery.serial_id,
                              () => {
                                _vm.manageStatus = 1
                              }
                            )
                          },
                        },
                      },
                      [_vm._v(" 管理信息 ")]
                    )
                  : _vm._e(),
                _vm.manageStatus === 1
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-item",
                        on: {
                          click: function ($event) {
                            _vm.manageStatus = 0
                          },
                        },
                      },
                      [_vm._v("取消管理")]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm.list.length === 0
            ? _c(
                "div",
                {
                  staticClass: "empty-box click-hover",
                  on: { click: _vm.add },
                },
                [
                  _c("img", {
                    staticClass: "empty-img",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/261d4d86c8ae1f-429x552.png",
                    },
                  }),
                  _c("div", { staticClass: "empty-tip" }, [
                    _vm._v("暂无逝者档案"),
                  ]),
                ]
              )
            : _c(
                "div",
                { staticClass: "list-container f fv fvc" },
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class: `item-container ${
                        _vm.manageStatus === 1 && item.is_ancestral != 1
                          ? "editing"
                          : ""
                      }`,
                      on: {
                        click: function ($event) {
                          return _vm.viewDetail(item)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "info-box f fc" }, [
                        _c("div", { staticClass: "avatar" }, [
                          item.decease_show !== 1
                            ? _c("div", { staticClass: "photo" }, [
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: _vm.$tools.compressImg(
                                        item.box,
                                        117
                                      ),
                                      expression:
                                        "$tools.compressImg(item.box, 117)",
                                    },
                                  ],
                                }),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "lazy",
                                      rawName: "v-lazy",
                                      value: _vm.$tools.compressImg(
                                        item.avatar,
                                        117
                                      ),
                                      expression:
                                        "$tools.compressImg(item.avatar, 117)",
                                    },
                                  ],
                                  staticClass: "photo-img f",
                                }),
                              ])
                            : _vm._e(),
                          item.decease_show === 1
                            ? _c("img", {
                                directives: [
                                  {
                                    name: "lazy",
                                    rawName: "v-lazy",
                                    value: _vm.$tools.compressImg(
                                      item.avatar,
                                      117
                                    ),
                                    expression:
                                      "$tools.compressImg(item.avatar, 117)",
                                  },
                                ],
                              })
                            : _vm._e(),
                          item.decease_show === 1
                            ? _c(
                                "div",
                                { staticClass: "deceased-text f fvc" },
                                [
                                  _c("p", { staticClass: "enshrine-name" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.name.length > 8
                                            ? item.name.substr(0, 8)
                                            : item.name
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "right f fv fbt" }, [
                          _c("div", { staticClass: "right-content f fv" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.is_ancestral === 1
                                      ? "历代元祖宗亲之位"
                                      : "姓名：" + item.name
                                  ) +
                                  " "
                              ),
                            ]),
                            item.die_age
                              ? _c("div", { staticClass: "age" }, [
                                  _vm._v(_vm._s(item.die_age)),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "die_duration" }, [
                              _vm._v(
                                _vm._s(
                                  item.die_duration
                                    ? `辞世：${item.die_duration}`
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "btn-box" },
                            [
                              index === 0
                                ? _c(
                                    "van-button",
                                    { staticClass: "btn current" },
                                    [_vm._v(" 主位供奉 ")]
                                  )
                                : _c(
                                    "van-button",
                                    {
                                      staticClass: "btn",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.priorityWorship(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 设为主位 ")]
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm.manageStatus === 1 && item.is_ancestral !== 1
                        ? _c("div", { staticClass: "edit-box f fc frd" }, [
                            _vm.manageStatus === 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "edit-btn f fv fc",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.updateInfo(item)
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                            _vm.manageStatus === 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "edit-btn f fv fc",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.showDelDialog(item)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              ),
          _c("Dialog", { ref: "dialog" }),
          _c(
            "van-popup",
            {
              model: {
                value: _vm.dialogFlag,
                callback: function ($$v) {
                  _vm.dialogFlag = $$v
                },
                expression: "dialogFlag",
              },
            },
            [
              _c("div", { staticClass: "f fv fc dialog-box" }, [
                _c("p", { staticClass: "tip" }, [
                  _vm._v("确认删除该档案信息?"),
                ]),
                _c("div", { staticClass: "f fc frd btn-box" }, [
                  _c(
                    "div",
                    { staticClass: "confirm-btn", on: { click: _vm.del } },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "cancel-btn",
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("取消")]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _c(
        "van-overlay",
        { attrs: { show: true } },
        [
          _c(
            "van-loading",
            { attrs: { size: "24px", color: "#464645", vertical: "" } },
            [_vm._v("加载中...")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }