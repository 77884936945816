var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-box", on: { click: _vm.view } },
    [
      _vm.item.dot
        ? _c("van-badge", { staticClass: "badge", attrs: { dot: "" } })
        : _vm._e(),
      _c("div", { staticClass: "item-img" }, [
        _c("img", { staticClass: "img", attrs: { src: _vm.item.image } }),
      ]),
      _c("div", { staticClass: "item-info" }, [
        _c("div", { staticClass: "item-name" }, [
          _vm._v(_vm._s(_vm.item.name)),
        ]),
        _c("div", { staticClass: "item-desc" }, [
          _vm._v(_vm._s(_vm.item.subtitle)),
        ]),
        _c("div", { staticClass: "item-price f" }, [
          _c("div", { staticClass: "current-price" }, [
            _c("span", { staticClass: "unit" }, [_vm._v("￥")]),
            _vm._v(_vm._s(_vm.item.final_amount)),
          ]),
          _c("div", { staticClass: "origin-price" }, [
            _c("span", { staticClass: "unit" }, [
              _vm._v("￥" + _vm._s(_vm.item.original_amount)),
            ]),
          ]),
        ]),
      ]),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }