<template>
  <div class="form">
    <div class="field">
      <input v-model="form.wish_name" type="text" class="field-inner wish-name" placeholder="祈福人名字" />
    </div>
    <div class="field">
      <textarea
        v-model="form.wish_address"
        autocomplete="off"
        rows="6"
        class="field-inner wish-address"
        placeholder="祈福人地址"
      ></textarea>
    </div>
    <div class="field">
      <textarea
        v-model="form.wish_content"
        autocomplete="off"
        rows="12"
        class="field-inner wish-content"
        placeholder="你的祈福内容"
      ></textarea>
    </div>
    <div class="submit">
      <van-button
        :disabled="!(form.wish_name && form.wish_address && form.wish_content)"
        block
        color="#F87A1B"
        size="small"
        native-type="submit"
        v-debounce="submit"
        >提交祈愿</van-button
      >
    </div>
  </div>
</template>

<script>
import { getGodWish, editGodWish, addGodWish } from '@/api/fude.js';
export default {
  data() {
    return {
      form: {
        wish_name: '',
        wish_address: '',
        wish_content: '',
      },
      id: '',
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getGodWish();
    }
  },
  methods: {
    async getGodWish() {
      const { data } = await getGodWish({
        serial_id: this.$route.query.serial_id,
        god_id: this.$route.query.god_id,
      });
      if (!data.id) return;
      this.form = {
        wish_name: data.wish_name,
        wish_address: data.wish_address,
        wish_content: data.wish_content,
      };
    },
    async addFn() {
      await addGodWish(
        Object.assign(
          {
            serial_id: this.$route.query.serial_id,
            god_id: this.$route.query.god_id,
          },
          this.form
        )
      );
      this.$toast('祈愿提交成功');
      this.$router.replace({ name: 'wishInfoIndex', query: this.$route.query });
    },
    async editFn() {
      await editGodWish(
        Object.assign(this.form, {
          id: this.id,
          serial_id: this.$route.query.serial_id,
          god_id: this.$route.query.god_id,
        })
      );
      this.$toast('祈愿更换成功');
      this.$router.replace({ name: 'wishInfoIndex', query: this.$route.query });
    },
    submit() {
      if (this.form.wish_name.length > 6) {
        this.$toast('名字不超过6个字');
        return;
      }
      if (this.form.wish_address.length > 50) {
        this.$toast('地址不超过50个字');
        return;
      }
      if (this.form.wish_content.length > 400) {
        this.$toast('祈愿内容不超过400个字');
        return;
      }
      this.id ? this.editFn() : this.addFn();
    },
    onFailed(errorInfo) {
      if (errorInfo.errors?.length) {
        this.$toast(errorInfo.errors[0].message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  position: relative;
  background: linear-gradient(180deg, #eba95d 0%, #fef2e4 100%);
  min-height: calc(var(--vh) * 100);
  padding: 16px 16px 84px;
  box-sizing: border-box;
  .field {
    background: #ffead1;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #f87a1b;
    padding: 16px;
    position: relative;
    margin-bottom: 16px;
    .field-inner {
      background: transparent;
      width: 100%;
      border: 0;
      font-size: 16px;
      color: rgba(123, 84, 57, 1);
      resize: vertical;
    }
    .wish-name {
      height: 20px;
    }
    &:before {
      display: block;
      width: 11px;
      height: 11px;
      background: #fff;
      position: absolute;
      left: 1px;
      top: 1px;
      content: '';
      background: url('https://img-fe.tengzhihh.com/image/f1e38365ff0bcf3083a693c8e79f1d8a-20x22.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    &:after {
      display: block;
      width: 11px;
      height: 11px;
      background: #fff;
      position: absolute;
      right: 1px;
      bottom: 1px;
      content: '';
      background: url('https://img-fe.tengzhihh.com/image/f1e38365ff0bcf3083a693c8e79f1d8a-20x22.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
  }
  .submit {
    position: absolute;
    bottom: 40px;
    right: 0;
    left: 0;
    margin: 0 38px;
    .van-button {
      border-radius: 10px;
      height: 44px;
    }
  }
  input::placeholder {
    font-size: 16px;
    color: rgba(123, 84, 57, 0.45);
  }
  textarea::placeholder {
    font-size: 16px;
    color: rgba(123, 84, 57, 0.45);
  }
}
</style>
