import request from '@/utils/request';
import apiPath from '@/utils/apiPath';
import { callNativePromise } from './MMCWKEventWeb';
import store from '@/store';
import qs from 'qs';
import router from '@/router/index';
import { Toast } from 'vant';

export default {
  isWeChat() {
    return /micromessenger/.test(window.navigator.userAgent.toLowerCase());
  },
  isInApp() {
    return /shentaicontroller/.test(window.navigator.userAgent.toLowerCase());
  },
  isHuaWei() {
    const sUserAgent = navigator.userAgent.toLowerCase();
    return sUserAgent.match(/huawei/i) == 'huawei';
  },
  isAndroid() {
    return navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
  },
  isIos() {
    return !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  },
  getQueryFromUrl(key) {
    let queryString = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (key instanceof Array) {
      let obj = {};
      for (let i of key) {
        obj[i] = queryString[i];
      }
      return obj;
    }
    return queryString[key] || '';
  },
  scanCode(cb) {
    if (this.isInApp()) {
      callNativePromise('openQrScan', null, c => {
        console.log('openQrScan', c);
      });
      return;
    }

    if (!this.isWeChat()) {
      Toast('请在微信里操作');
      return;
    }

    wx.scanQRCode({
      needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res) {
        typeof cb === 'function' && cb(res);
      },
    });
  },

  weChatLogin(reUrl) {
    const appId = store.state.appId;
    const redirectUri = encodeURIComponent(reUrl || location.href);
    const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`;
    location.href = url;
  },

  scrollToBottomById(id) {
    const ele = document.getElementById(id);
    ele.scrollTop = ele.scrollHeight;
  },

  sliceArr(arr = [], sliceLen = 2) {
    const newLen = parseInt(arr.length / sliceLen);
    let pushTime = 0;
    let tmp = [];
    while (pushTime < newLen) {
      tmp.push(arr.slice(pushTime * sliceLen, pushTime * sliceLen + sliceLen));
      pushTime++;
    }
    arr.slice(pushTime * sliceLen).length > 0 && tmp.push(arr.slice(pushTime * sliceLen));
    return tmp;
  },
  compressImg(url, showWidth = 375) {
    if (!url) {
      return '';
    }
    const tmp = url.match(/-(\S*)x/);
    const width = tmp ? tmp[1] : 0;
    if (width > 750) {
      url = `${url}?x-oss-process=image/resize,w_${showWidth},m_lfit`;
    }
    return url;
  },

  fillZero(v) {
    return v ? (String(v).length === 1 ? `0${v}` : v) : '00';
  },

  mileSecondToMinute(s) {
    const totalSecond = s / 1000;
    const min = parseInt(totalSecond / 60);
    const second = parseInt(totalSecond - 60 * min);
    return `${this.fillZero(min)}:${this.fillZero(second)}`;
  },
  secondToMinute(s) {
    let second = s % 60;
    let min = (s - second) / 60;
    return `${this.fillZero(min)}:${this.fillZero(second)}`;
  },
  inputCheck(str) {
    return /^[a-zA-Z0-9\u4e00-\u9fa5\,]+$/.test(str);
  },
  isPicture(ext) {
    return ['png', 'jpg', 'jpeg', 'PNG', 'JPG', 'JPEG'].findIndex(i => ext === i) !== -1;
  },
  clickA(url) {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.setAttribute('id', 'targetA');
    // 防止反复添加
    if (document.getElementById('targetA')) {
      document.body.removeChild(document.getElementById('targetA'));
    }
    document.body.appendChild(a);
    a.click();
  },
  myInterval(callback, interval) {
    let timerId;

    function loop() {
      callback();
      timerId = setTimeout(loop, interval);
    }

    timerId = setTimeout(loop, interval);

    function cancel() {
      clearTimeout(timerId);
    }

    return cancel;
  },
  isScrolledToBottom(el) {
    return el.scrollHeight - el.scrollTop - el.clientHeight < 10;
  },
  importJSON(url) {
    return new Promise(resolve => {
      const request = new XMLHttpRequest();
      request.open('get', url);
      request.send(null);
      request.onreadystatechange = () => {
        if (request.readyState == 4 && request.status == 200) {
          var json = JSON.parse(request.responseText);
          resolve(json || []);
        }
      };
    });
  },
  isLocal() {
    return /localhost|127\.0.0.1|10.1.200/i.test(location.hostname);
  },
  isTest() {
    return /sandbox/i.test(location.hostname);
  },
  isPre() {
    return /pre/i.test(location.hostname);
  },
  isProd() {
    return !this.isPre() && !this.isTest() && !this.isLocal();
  },
  setDocumentTitle(title) {
    document.title = title || '神台手机端';
  },
  /**
   * 将文件字节大小转换为KB大小
   * @param {number} bytes - 文件大小，单位字节
   * @param {boolean} [round=true] - 是否取整，默认为取整
   * @returns {number} - 转换后的KB大小
   */
  bytesToKB(bytes, round = true) {
    if (bytes < 0) {
      return Toast('文件错误，请重新上传');
    }

    const kb = bytes / 1024;

    // 处理浮点数精度问题
    const preciseKb = Math.round((kb + Number.EPSILON) * 100) / 100;

    if (round) {
      return Math.round(preciseKb);
    }

    return kb;
  },
};
