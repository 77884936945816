<template>
  <div>
    <van-popup v-model="dialogFlag" position="bottom" class="popup" get-container="body" @click-overlay="onCancel">
      <div class="popup-box">
        <div class="popup-line" @click="dialogFlag = false" @touchmove="onTouchMove" @touchend="onTouchEnd"></div>
        <div class="title">{{ godName }} 供奉香火</div>
        <div class="f fc fbt">
          <div style="width: 40px"></div>
          <div class="f fc fvc">
            <img src="https://img-fe.tengzhihh.com/image/08de8f0e69a2db-50x50.png" class="icon" />
            <div class="f-value">香火 {{ godFude }}</div>
          </div>
          <div class="link" @click="viewFude">查看明细</div>
        </div>
        <div>
          <div class="sub">今日任务</div>
          <div class="content" v-for="(task, idx) in todayTask" :key="idx">
            <div class="f fc fbt">
              <div class="f fc">
                <img :src="task.icon_image" class="detail-icon" />
                <div>
                  <div style="font-size: 16px">{{ task.title }}，</div>
                  <div v-html="task.subtitle"></div>
                </div>
              </div>
              <div class="btn cancel" v-if="task.status">已供奉</div>
              <div class="btn confirm" v-else @click="goOffer">去供奉</div>
            </div>
          </div>
        </div>
        <div style="margin-top: 40px">
          <div class="sub f fc fbt">
            <div>香火说明</div>
          </div>
          <div class="content">香火表达了对逝者的尊敬、膜拜和为子孙后代祈求保佑。增加香火，也会同步增加功德值。</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    godId: {
      type: Number,
      default: 0,
    },
    godName: {
      type: String,
      default: '',
    },
    godFude: {
      type: Number,
      default: 0,
    },
    todayTask: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogFlag: false,
      endY: 0,
    };
  },
  methods: {
    onTouchMove(event) {
      this.endY = event.changedTouches[0].clientY;
    },
    onTouchEnd() {
      if (this.endY > 200) {
        this.dialogFlag = false;
        this.endY = 0;
      }
    },
    show() {
      this.dialogFlag = true;
    },
    onCancel() {
      this.dialogFlag = false;
    },
    goOffer() {
      this.$emit('goOffer');
    },
    viewFude() {
      this.$emit('viewFude');
    },
  },
};
</script>

<style lang="less" scoped>
.popup {
  width: 100%;
  background: #fff;
  border-radius: 10px 10px 0 0;
  .popup-box {
    padding: 0 20px 20px;
    background: #fff;
    border-radius: 15px;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }

    .icon {
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }
    .f-value {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .sub {
      font-size: 16px;
      margin: 20px 0;
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
    }
    .link {
      font-size: 14px;
      color: #f87a1b;
      font-weight: normal;
    }
    .content {
      width: 100%;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      .detail-icon {
        width: 17px;
        height: 17px;
        margin-right: 10px;
      }
      .confirm {
        background: #f87a1b;
        color: rgba(255, 255, 255, 0.85);
      }
      .cancel {
        background: #f6f6f6;
        color: rgba(0, 0, 0, 0.65);
      }
      .btn {
        width: 81px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        border-radius: 16px;
      }
    }
  }
}
</style>
