var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "swiper",
    { staticClass: "swiper", attrs: { options: _vm.swiperOption } },
    _vm._l(_vm.dataList, function (item, index) {
      return _c("swiper-slide", { key: index }, [
        _c(
          "div",
          {
            staticClass: "swiper-item",
            on: {
              click: function ($event) {
                return _vm.callback(item.cb)
              },
            },
          },
          [_c("img", { staticClass: "item-img", attrs: { src: item.img } })]
        ),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }