import { request } from '@/utils/request';

export function getQuickOffering(params) {
  return request({
    url: '/user/quick_offering_set',
    method: 'get',
    params,
  });
}
export function setQuickOffering(data) {
  return request({
    url: '/user/quick_offering_set',
    method: 'post',
    data,
  });
}
export function saveQuickOffering(data) {
  return request({
    url: '/offering/quick/save',
    method: 'post',
    data,
  });
}
