<template>
  <div class="page-box video-box f fv fc">
    <video
      id="video"
      playsinline
      preload="meta"
      controls
      x5-video-player-type="h5"
      class="video-item"
      style="object-fit: contain; background: #000"
    ></video>

    <van-button class="next-btn" @click="next"> 下一步 </van-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    serialId: {
      type: String,
      default: '',
    },
    mottoType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      duration: 0,
      audioPlaying: false,
    };
  },
  computed: {
    ...mapState(['videoFile', 'audioFile']),
  },
  mounted() {
    this.duration = this.$route.query.duration || 0;
    this.fileName = decodeURIComponent(this.$route.query.fileName);
    this.$nextTick(() => {
      this.load();
    });
  },

  methods: {
    next() {
      this.$emit('setValue', 'currentStep', 2);
      // this.$router.push(
      //   `/motto/edit?type=${this.mottoType}&serial_id=${this.serialId}&fileName=${encodeURIComponent(this.fileName)}`
      // );
    },
    load() {
      if (this.mottoType === 'video') {
        if (!this.videoFile) {
          this.$toast('视频文件为空，请重新添加');

          this.$emit('setValue', 'activeClose', true);
          return this.$router.replace(`/motto/list?serial_id=${this.serialId}`);
        }
        this.loadVideo(this.videoFile);
      }
    },
    playAudio() {
      var audio = document.getElementById('preview-audio');
      if (!this.audioPlaying) {
        audio.play();
        this.audioPlaying = true;
        audio.onended = () => {
          console.log('播放结束');
          this.audioPlaying = false;
        };
      } else {
        audio.pause();

        this.audioPlaying = false;
      }
    },

    loadVideo(file) {
      const url = URL.createObjectURL(file);
      const video = document.getElementById('video');
      video.src = url;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  height: calc(var(--vh) * 100);
  #audio {
    display: none;
  }
  #preview-audio {
    display: none;
  }
  .video-item {
    width: 100vw;
    height: 88vh;
  }
  .next-btn {
    border: none;
    width: 300px;
    height: 43px;
    background: #f87a1b;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #fff4e4;
    position: fixed;
    bottom: 20px;
  }
  .duration {
    font-size: 15px;
    font-weight: bold;
    color: #797979;
  }
  .finish-tip {
    font-size: 15px;
    font-weight: bold;
    color: #454343;
    line-height: 13px;
    margin-top: 45px;
  }
  .icon-box {
    margin-top: 170px;
    position: relative;
    .icon {
      width: 154px;
      height: 63px;
    }
    .icon-gif {
      position: absolute;
      top: 10px;
      left: 20px;
      width: 30px;
      height: 25px;
    }
  }
}
.video-box {
  background: #000;
}
.audio-box {
  background: #e9e7e6;
}
</style>
