var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mottoType === "video" && _vm.detail
    ? _c(
        "div",
        { staticClass: "motto-box" },
        [
          _vm.detail.status == 1
            ? _c("video", {
                staticClass: "player video",
                staticStyle: { "object-fit": "contain", background: "#000" },
                attrs: {
                  id: "video",
                  playsinline: "",
                  autoplay: "",
                  controls: "",
                  "x5-video-player-type": "h5",
                  src: _vm.detail.url,
                },
              })
            : _vm._e(),
          _vm.detail.status == 1
            ? _c("div", { staticClass: "content-box f fv" }, [
                _c("div", { staticClass: "f fc" }, [
                  _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.detail.user_avatar },
                  }),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.detail.user_name)),
                  ]),
                ]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.detail.content)),
                ]),
              ])
            : _vm._e(),
          _c("van-overlay", { attrs: { show: _vm.detail.status != 1 } }, [
            _c("div", { staticClass: "overlay-box f fc fvc" }, [
              _c("div", { staticClass: "content f fc fvc" }, [
                _vm._v(" " + _vm._s(_vm.detail.status_message) + " "),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm.mottoType === "audio" && _vm.detail
    ? _c(
        "div",
        { staticClass: "motto-box" },
        [
          _c(
            "van-swipe",
            {
              staticClass: "swipe-box",
              attrs: { autoplay: 5000, "indicator-color": "white" },
            },
            _vm._l(_vm.detail.images, function (item) {
              return _c("van-swipe-item", { key: item.id }, [
                _c("img", {
                  directives: [
                    {
                      name: "lazy",
                      rawName: "v-lazy",
                      value: _vm.$tools.compressImg(item.image, 375),
                      expression: "$tools.compressImg(item.image, 375)",
                    },
                  ],
                  staticStyle: { "object-fit": "contain", background: "#fff" },
                  on: {
                    click: function ($event) {
                      return _vm.preview(item)
                    },
                  },
                }),
              ])
            }),
            1
          ),
          _vm.detail.status == 1
            ? _c("div", { staticClass: "content-box f fv" }, [
                _c("div", { staticClass: "f fc" }, [
                  _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.detail.user_avatar },
                  }),
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.detail.user_name)),
                  ]),
                ]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.detail.content)),
                ]),
              ])
            : _vm._e(),
          _vm.detail.status === 1
            ? _c("img", {
                staticClass: "play-btn",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/638bac7dbde2e9-179x140.png",
                },
                on: { click: _vm.playAudio },
              })
            : _vm._e(),
          _c("div", { attrs: { id: "audio-play-box" } }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }