<template>
  <img src="https://img-fe.tengzhihh.com/image/7fc7da05510999-750x3529.jpg" class="img" />
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.img {
  width: 100%;
  display: block;
}
</style>
