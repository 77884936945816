var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { value: _vm.showFlag, position: "bottom" },
      on: { "click-overlay": _vm.onCancel },
    },
    [
      _c("div", { staticClass: "f fc fbt header" }, [
        _c("div", { staticClass: "cancel", on: { click: _vm.onCancel } }, [
          _vm._v("取消"),
        ]),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "confirm", on: { click: _vm.onConfirm } }, [
          _vm._v("确定"),
        ]),
      ]),
      _c(
        "van-checkbox-group",
        {
          model: {
            value: _vm.result,
            callback: function ($$v) {
              _vm.result = $$v
            },
            expression: "result",
          },
        },
        [
          _c(
            "van-cell-group",
            _vm._l(_vm.list, function (item, index) {
              return _c("van-cell", {
                key: index,
                attrs: { clickable: "", title: item.label },
                on: {
                  click: function ($event) {
                    return _vm.toggle(index)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "right-icon",
                      fn: function () {
                        return [
                          _c("van-checkbox", {
                            ref: "checkboxes",
                            refInFor: true,
                            attrs: {
                              name: item.id,
                              "checked-color": "#f87a1b",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }