<template>
  <van-popup v-model="showFlag"
    position='bottom'
    @click-overlay="onCancel">
    <van-picker show-toolbar
      :default-index="defaultIndex"
      :title="title"
      :columns="columns"
      @confirm="onConfirm"
      @cancel="onCancel"
      @change="onChange" />
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showFlag: this.show,
    };
  },
  watch: {
    show: function (v) {
      this.showFlag = v;
    },
  },
  methods: {
    onConfirm(value, index) {
      this.$emit('onConfirm', value, index);
    },
    onCancel() {
      this.$emit('onCancel');
    },
    onChange(value, index) {
      this.$emit('onChange', value, index);
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  border-radius: 0;
}
</style>