<template>
  <div class="item-box" @click="view">
    <van-badge class="badge" v-if="item.dot" dot></van-badge>
    <div class="item-img">
      <img :src="item.image" class="img" />
    </div>

    <div class="item-info">
      <div class="item-name">{{ item.name }}</div>
      <div class="item-desc">{{ item.subtitle }}</div>
      <div class="item-price f">
        <div class="current-price"><span class="unit">￥</span>{{ item.final_amount }}</div>
        <div class="origin-price">
          <span class="unit">￥{{ item.original_amount }}</span>
        </div>
      </div>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import { setStore } from '@/utils/localStore';
import Dialog from '@/components/Dialog/Dialog.vue';
export default {
  components: { Dialog },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          category: 'virtual',
          final_amount: '',
          id: '',
          image: '',
          name: '',
          original_amount: '',
          subtitle: '',
          url: '',
        };
      },
    },
  },

  methods: {
    view() {
      if (this.item.dot) {
        setStore({
          name: `${this.item.name}-商品-已点击`,
          content: true,
        });
      }
      if (this.item.goods_type === 'vip') {
        this.$router.push(`/vip/index?&goods_id=${this.item.id}`);
        return;
      }
      this.$router.push(`/shop/goodsDetail?&id=${this.item.id}`);
      // 商品类型，entity实体物品，virtual虚拟物品
      // if (this.item.category === 'entity') {
      //   this.$utils.kefuDialog(this.$refs.dialog);
      // } else {
      //   this.$router.push(`/shop/goodsDetail?&id=${this.item.id}`);
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.item-box {
  width: 174px;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;

  .badge {
    position: absolute;
    top: 0;
    right: 0;
    &.van-badge--dot {
      width: 12px;
      height: 12px;
    }
  }
  .item-img {
    width: 100%;
    background: #fff;

    img {
      width: 100%;
    }
  }
  .item-info {
    padding: 5px 5px;
    .item-name {
      font-size: 15px;
      font-weight: 800;
      color: #333333;
    }
    .item-desc {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin: 4px 0 6px;
    }
    .item-price {
      align-items: flex-end;
      .current-price {
        font-size: 18px;
        font-weight: bold;
        color: #ff2400;
        .unit {
          font-size: 10px;
          font-weight: bold;
          color: #ff2400;
        }
      }
      .origin-price {
        font-size: 12px;
        font-weight: 500;
        text-decoration: line-through;
        color: #a1a1a1;
      }
    }
  }
}
</style>
