var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("cell-group", { attrs: { list: _vm.settingList } }),
      _c("van-cell", {
        attrs: {
          title: "勿扰模式",
          is_link: "",
          center: "",
          label: `${_vm.generalConfig.dnd_start_hour}:00 - ${_vm.generalConfig.dnd_end_hour}:00`,
        },
        scopedSlots: _vm._u([
          {
            key: "right-icon",
            fn: function () {
              return [
                _c("van-switch", {
                  attrs: {
                    "active-color": "#f87a1b",
                    "inactive-color": "#ffeac9",
                    size: "20px",
                    value: _vm.generalConfig.dnd_mode,
                  },
                  on: { input: _vm.modeChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "van-popup",
        {
          model: {
            value: _vm.nameDialog,
            callback: function ($$v) {
              _vm.nameDialog = $$v
            },
            expression: "nameDialog",
          },
        },
        [
          _c(
            "div",
            { staticClass: "f fv fc dialog-box" },
            [
              _c("div", { staticClass: "title" }, [_vm._v("修改名称")]),
              _c("van-field", {
                staticClass: "input-item f fc",
                attrs: { clearable: "", maxlength: "10" },
                model: {
                  value: _vm.newName,
                  callback: function ($$v) {
                    _vm.newName = $$v
                  },
                  expression: "newName",
                },
              }),
              _c(
                "van-button",
                { staticClass: "save-btn", on: { click: _vm.saveNewName } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.timeRangePickerFlag
        ? _c("time-range-picker", {
            attrs: {
              title: "选择时间段",
              show: _vm.timeRangePickerFlag,
              defaultStart: _vm.generalConfig.dnd_start_hour,
              defaultEnd: _vm.generalConfig.dnd_end_hour,
              pickerType: "hour",
            },
            on: {
              onConfirm: _vm.confirmDatetimePicker,
              onCancel: _vm.onCancel,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }