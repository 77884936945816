var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("BackIcon", { staticClass: "back", on: { back: _vm.backFn } }),
      _c("EnshrineSettingTab", {
        attrs: { list: _vm.tabList },
        on: { tabClick: _vm.tabClick },
      }),
      _c(
        "div",
        { staticClass: "list-box f fv fc" },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            { key: item.god_id, staticClass: "list-item f fc" },
            [
              _c("div", { staticClass: "img-box" }, [
                _c("img", {
                  staticClass: "item-img",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/a5edc717e7869e-300x456.png",
                  },
                }),
                _c("img", {
                  staticClass: "main-img",
                  attrs: { src: item.main_image },
                }),
                _c("img", {
                  staticClass: "tag",
                  attrs: { src: item.name_icon },
                }),
              ]),
              _c("div", { staticClass: "item-info f fv frd" }, [
                _c("div", { staticClass: "info-top" }, [
                  _c("div", [
                    _vm._v(" 恭请于 "),
                    _c("span", { staticClass: "detail" }, [
                      _vm._v(_vm._s(item.created_at)),
                    ]),
                  ]),
                  _vm.$utils.calcUserIsMasterWithSerialId(
                    _vm.routeQuery.serial_id
                  )
                    ? _c("div", [
                        _vm._v(" 功德值 "),
                        _c("span", { staticClass: "detail" }, [
                          _vm._v(_vm._s(item.user_god_fude)),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", [
                    _vm._v(" 累计祈福 "),
                    _c("span", { staticClass: "detail" }, [
                      _vm._v(_vm._s(item.cumulative_days) + "天"),
                    ]),
                  ]),
                ]),
                item.is_default
                  ? _c("div", { staticClass: "info-bottom main" }, [
                      _vm._v("神明主位"),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass: "info-bottom",
                        on: {
                          click: function ($event) {
                            _vm.$utils.checkAuth(_vm.routeQuery.serial_id, () =>
                              _vm.setDefaultGod(item)
                            )
                          },
                        },
                      },
                      [_vm._v(" 设为主位 ")]
                    ),
              ]),
            ]
          )
        }),
        0
      ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }