<template>
  <div>
    <div class="page-box" v-if="!loading && [1, 3, 4, 5, 6].includes(detail.status)">
      <div class="box-header">
        <van-icon name="arrow-left" @click="goBack" class="back-icon" />
      </div>

      <div class="main-area f fv fc" v-if="detail.goods_info.category !== 'entity'">
        <div class="resText f">
          <img
            src="https://img-fe.tengzhihh.com/image/7774da745eb046-20x20.png"
            alt=""
            srcset=""
            class="address-icon"
          />
          <span class="text">支付成功</span>
        </div>
        <div class="good_name">{{ detail.goods_info.name }}</div>
        <div class="btn-area f fc">
          <div class="btn-item" @click="goHome">返回首页</div>
          <div class="btn-item" @click="use">马上使用</div>
        </div>
        <div class="tip" @click="kefu">使用如果有问题，请联系客服</div>
      </div>
      <div class="main-area" v-if="detail.goods_info.category === 'entity'">
        <div class="resText f">
          <img
            src="https://img-fe.tengzhihh.com/image/7774da745eb046-20x20.png"
            alt=""
            srcset=""
            class="address-icon"
          />
          <span class="text">支付成功</span>
        </div>
        <div class="good_name">{{ detail.goods_info.name }}</div>
        <div class="handles f fbt">
          <button v-for="{ label, name } in handleList" :key="name" round @click="resHandleClick(name)">
            {{ label }}
          </button>
        </div>
        <div class="good_address">
          <div class="f mb16">
            <p class="label">收件人：</p>
            <p class="flex1">
              <span class="name">{{ detail.logistics.user_name }}</span
              ><span>{{ detail.logistics.mobile | mobileCode }}</span>
            </p>
          </div>
          <div class="f">
            <p class="label">收货地址：</p>
            <p class="flex1">
              {{ detail.logistics.province }}&nbsp;{{ detail.logistics.city }}&nbsp;{{
                detail.logistics.district
              }}&nbsp;{{ detail.logistics.street }}{{ detail.logistics.address }}
            </p>
          </div>
        </div>
      </div>

      <div class="goods-area">
        <div class="tip">其他人正在购买</div>
        <goods-list :list="goodsList"></goods-list>
      </div>
    </div>
    <div v-else class="vh-full w-full f fvc">
      <van-loading type="spinner" vertical>正在获取支付结果...</van-loading>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import Dialog from '@/components/Dialog/Dialog.vue';
import GoodsList from '@/components/Goods/GoodsList';
import { getStore } from '@/utils/localStore';
export default {
  components: { GoodsList, Dialog },
  filters: {
    mobileCode(str) {
      return str.replace(/(\d{3}).*(\d{4})/, '$1****$2');
    },
  },
  data() {
    return {
      goodsList: [],
      orderId: '',
      detail: {
        goods_name: '',
        serial_id: '',
        status: 0,
      },
      goodsRelationId: 0,
      compatible: 1,
      checkTime: 0,
      timeout: null,
      goodsUniqueNumber: 0,
      handleList: [
        { label: '返回首页', name: 'home' },
        { label: '联系客服', name: 'service' },
        { label: '查看订单', name: 'view' },
      ],
      loading: false,
    };
  },
  async mounted() {
    this.orderId = this.$tools.getQueryFromUrl('order_id');
    this.getList({ is_recommend: 1 });
    await this.getUserDevices();
    this.getOrderDetail();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    kefu() {
      this.$utils.kefuDialog(this.$refs.dialog);
    },
    getUserDevices() {
      return this.$http.get(apiPath.getUserDevices).then(res => {
        this.$store.commit('setUserMachinesList', res.data.list);
        this.$store.commit('setSiMiaoLianMingGodGoodsId', res.data.banner[0]?.id);
      });
    },
    check() {
      const serialId = this.detail?.serial_id;
      if (!serialId) {
        this.$router.push('/shop/list');
        return false;
      }

      const isMaster = this.$utils.calcUserIsMasterWithSerialId(serialId);
      if (!isMaster) {
        return this.$toast('购买成功，请提醒机主进行设置');
      } else {
        return this.$http
          .get(apiPath.checkCompatible, {
            serial_id: serialId,
            god_id: this.goodsRelationId,
          })
          .then(res => {
            this.compatible = res.data.status;
            this.showDialog();
          });
      }
    },
    getOrderDetail() {
      // STXM169018870532610243
      if (!this.orderId) {
        return;
      }
      this.loading = true;
      this.checkTime++;
      this.$http.get(`${apiPath.orderDetail}?order_id=${this.orderId}`).then(res => {
        this.detail = res.data.order;
        this.goodsRelationId = res.data?.order?.product?.relation?.id;
        this.goodsUniqueNumber = res.data?.order?.product?.goods_unique_number;
        this.loading = false;
        if ([1, 3, 4, 5, 6].includes(this.detail.status)) {
          //非0，2状态都可认为已支付
          if (this.detail?.product?.relation?.faction === 4) {
            this.check();
          }
        } else {
          if (this.checkTime > 3) {
            this.$toast({
              message: '支付失败',
              type: 'fail',
            });
            let returnUrl = this.getReturnUrl();
            this.$router.replace(returnUrl);
          } else {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.getOrderDetail();
            }, 1200);
          }
        }
      });
    },
    getReturnUrl() {
      const routePrevious = getStore({ name: 'routePrevious' });
      let returnUrl = `/shop/goodsDetail?id=${this.detail.goods_id}`;
      if (['/shop/allorder', '/shop/unpaid', '/shop/orderInfo', '/vip/index'].includes(routePrevious)) {
        returnUrl = `/shop/orderInfo?order_id=${this.orderId}`;
      }
      return returnUrl;
    },
    confirmClick() {
      this.$http
        .post(apiPath.setCompatible, {
          serial_id: this.detail?.serial_id,
          god_id: this.goodsRelationId,
        })
        .then(() => {
          this.$router.replace(`/device/index?serial_id=${this.detail.serial_id}`);
        });
    },

    showDialog() {
      this.$refs.dialog.initDialog({
        title: '购买成功',
        // content: this.compatible === 0 ? `当前供奉信仰有冲突，是否切换为道教进行供奉？` : `是否确认供奉玉皇大帝神像？`,
        content: `<div class="dialog-box f fv fc">
                    <div class="god-img-box">
                      <img src="https://img-fe.tengzhihh.com/image/81925064b6126d-276x276.png" class="bg" />
                      <img src="${this.detail.product.relation.god_img}" class="god-item-img" />
                    </div>
                    <div class="id-box">
                      <img src="https://img-fe.tengzhihh.com/image/11e1a446efd149-392x64.png" class="id-bg-img" />
                      <div class="id">神像编号（ID：${this.goodsUniqueNumber}）</div>
                    </div>
                    <div class="name">
                      ${
                        this.compatible === 0
                          ? `当前供奉信仰有冲突，是否切换为道教进行供奉？`
                          : `是否确认供奉${this.detail.product.relation.name}神像？`
                      }
                    </div>
                  </div>`,
        showCancelButton: true,
        cancelText: '取消',
        confirmText: this.compatible === 0 ? '切换' : '确认',
        confirmCallback: () => {
          this.confirmClick();
        },
        cancelCallback: () => {
          this.showCancelDialog();
        },
      });
    },
    showCancelDialog() {
      this.$refs.dialog.initDialog({
        title: '取消供奉',
        content: `供奉已暂停，可以在神像设置进行修改`,
        showCancelBtn: false,
        confirmText: '返回商城首页',
        confirmCallback: () => {
          this.$router.replace(`/shop/list`);
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.replace('/');
    },
    use() {
      this.check();
      // this.$router.replace(`/device/index?serial_id=${this.detail.serial_id}`);
    },
    getList(e) {
      this.$http
        .get(
          `${apiPath.goods}?page=1&size=200${e.category ? '&category=' + e.category : ''}${
            e.is_recommend ? '&is_recommend=' + e.is_recommend : ''
          }`
        )
        .then(res => {
          this.goodsList = res.data.list;
        });
    },
    resHandleClick(name) {
      if (name === 'home') {
        this.$router.replace('/');
      } else if (name === 'service') {
        this.kefu();
      } else {
        this.$router.push('/shop/orderInfo?order_id=' + this.orderId);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  background: #e9e7e6;
  min-height: calc(var(--vh) * 100);
  .box-header {
    width: 375px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 20px 20px 0;
    .back-icon {
      color: #000;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .main-area {
    background: #fff;
    padding: 15px 0 10px;
    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }
    .info {
      font-size: 13px;
      font-weight: 500;
      color: #939393;
      margin: 23px 27px;
      .machine-name {
        margin-right: 30px;
      }
    }
    .content {
      font-size: 18px;
      font-weight: 800;
      color: #333333;
    }
    .btn-area {
      margin: 0 0 25px;
      .btn-item {
        width: 88px;
        height: 30px;
        border: 1px solid #b7b7b7;
        border-radius: 15px;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        text-align: center;
        margin-right: 33px;
      }
      .btn-item:last-child {
        margin: 0;
      }
    }
    .tip {
      font-size: 13px;
      font-weight: 500;
      color: #939393;
    }
    .resText {
      justify-content: center;
      margin-bottom: 20px;
      .address-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .text {
        font-size: 18px;
        line-height: 20px;
        font-weight: bold;
        color: #f87a1b;
      }
    }
    .good_name {
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      margin: 0 15px 16px;
    }
    .handles {
      padding: 0 40px;
      margin-bottom: 30px;
      button {
        font-size: 14px;
        width: 88px;
        height: 30px;
        line-height: 28px;
        border-radius: 15px;
        background: #fff;
        border: 1px solid #707070;
      }
    }
    .good_address {
      width: 345px;
      box-sizing: border-box;
      margin: 0 15px 10px;
      background: rgba(245, 245, 245, 0.5);
      border-radius: 10px 10px 10px 10px;
      padding: 16px 12px;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      .mb16 {
        margin-bottom: 16px;
      }
      .label {
        width: 80px;
      }
      .flex1 {
        flex: 1;
      }
      .name {
        margin-right: 10px;
      }
    }
  }
  .goods-area {
    .tip {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin: 24px 15px 15px;
    }
  }
}
</style>
<style lang="less">
.dialog-box {
  .id-box {
    position: relative;
    margin-top: 16px;
    .id {
      font-size: 14px;
      line-height: 32px;
      color: #a35a26;
      position: absolute;
      width: 196px;
      left: 50%;
      top: 0;
      margin-left: -98px;
      text-align: center;
    }
    .id-bg-img {
      width: 196px;
      height: 32px;
    }
  }
  .name {
    margin-top: 16px;
    font-size: 18px;
  }
  .god-img-box {
    position: relative;
    width: 138px;
    height: 166px;
    flex-shrink: 0;
    .god-item-img {
      position: absolute;
      width: 104px;
      left: 50%;
      margin-left: -52px;
      z-index: 2;
    }
    .bg {
      position: absolute;
      width: 138px;
      height: 138px;
      z-index: 1;
      top: 1px;
    }
  }
}
</style>
