const LOCAL_VER = '1.0.0'; // 修改此值，会让客户端在载入后先清空localStorage
(function localVerManage() {
  let localVer = window.localStorage.getItem('LOCAL_VER');
  if (localVer) {
    if (localVer !== LOCAL_VER) {
      window.localStorage.clear();
    }
  } else {
    window.localStorage.setItem('LOCAL_VER', LOCAL_VER);
  }
})();

(function refreshLocal() {
  let storage = window.localStorage;
  for (let key in storage) {
    let data;
    try {
      data = JSON.parse(storage[key]);
    } catch (e) {
      data = storage[key];
    }
    if (typeof data === 'object' && data?.clean === 'refresh') {
      window.localStorage.removeItem(key);
    }
  }
})();

const TokenKey = 'WORSHIP_TOKEN';

export function getToken() {
  return getStore({
    name: TokenKey,
  });
}

export function setToken(token, expiry) {
  return setStore({
    name: TokenKey,
    content: token,
    options: { expiry },
  });
}

export function removeToken() {
  return removeStore({
    name: TokenKey,
  });
}

/* 生成过期时间 */
function expiry(val) {
  if (!val) {
    return null;
  }
  let interval = parseInt(val);
  let unit = val.replace(interval, '');
  if ('d' === unit) {
    interval = interval * 24 * 60 * 60 * 1000;
  }

  if ('h' === unit) {
    interval = interval * 60 * 60 * 1000;
  }

  if ('m' === unit) {
    interval = interval * 60 * 1000;
  }

  if ('s' === unit) {
    interval = interval * 1000;
  }
  return Date.now() + interval;
}
/* 获取localStore值 */
function _getStore(name) {
  let obj = null;
  try {
    obj = window.localStorage.getItem(name) ? JSON.parse(window.localStorage.getItem(name)) : null;
  } catch (e) {
    window.localStorage.removeItem(name);
  }
  return obj;
}
/**
    存储localStorage
    setStore({
        name: 'name',
        key: 'key',
        content: 'content'
    })
 */
export const setStore = ({ name, key, content, options }) => {
  if (!name) return;
  let obj = {
    value: {},
    expiry: options ? expiry(options.expiry) : null,
    clean: options ? options.clean : null,
  };
  if (key) {
    let tmp_obj = _getStore(name);
    if (tmp_obj && tmp_obj.value) {
      tmp_obj.value[key] = content;
      obj.value = tmp_obj.value;
    }
  } else {
    obj.value = content;
  }
  window.localStorage.setItem(name, JSON.stringify(obj));
};

/**
    获取localStorage
    getStore({
        name: 'name',
        key: 'key'
    })
 */
export const getStore = ({ name, key }) => {
  if (!name) return;
  let obj = null;
  const tmp_obj = _getStore(name);
  // 判断是否过期
  if (tmp_obj && tmp_obj.expiry && Date.now() > tmp_obj.expiry) {
    window.localStorage.removeItem(name);
    return null;
  }
  if (key) {
    if (tmp_obj && tmp_obj.value) {
      obj = tmp_obj.value[key];
    }
  } else {
    obj = tmp_obj ? tmp_obj.value : null;
  }
  return obj;
};

export const removeStore = ({ name }) => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

export function addClass(el, cls) {
  if (!el) return;
  var curClass = el.className;
  var classes = (cls || '').split(' ');

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else {
      if (!hasClass(el, clsName)) {
        curClass += ' ' + clsName;
      }
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
}

export function removeClass(el, cls) {
  if (!el || !cls) return;
  var classes = cls.split(' ');
  var curClass = ' ' + el.className + ' ';

  for (var i = 0, j = classes.length; i < j; i++) {
    var clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.remove(clsName);
    } else {
      if (hasClass(el, clsName)) {
        curClass = curClass.replace(' ' + clsName + ' ', ' ');
      }
    }
  }
  if (!el.classList) {
    el.className = trim(curClass);
  }
}
