var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "page-box" }, [
      _c("h2", [_vm._v("用户隐私权政策")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "智能神台隐私权政策适用于智能神台提供的软件、网站、服务（统称为“智能神台产品或服务”），包括但不限于适用于电脑、移动智能终端的产品及服务、智能神台开放平台产品及服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "本隐私权政策旨在帮助您了解我们会收集哪些数据、为什么收集这些数据、会利用这些数据做什么以及我们如何保护这些数据。了解这些内容，对于您行使个人权利及保护您的个人信息至关重要，请您在使用智能神台产品或服务前务必抽出时间认真阅读本政策。当您开始下载、访问或使用智能神台产品或服务，即表示您已经同意本隐私权政策并信赖我们对您的信息的处理方式。请注意，您可以通过产品中的设置功能或控制项对您的信息进行动态管理。我们同时提醒您，当您通过智能神台产品或服务使用第三方产品或服务时，您的信息应当适用第三方的隐私条款。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("本政策包含以下内容： ")]),
      _c("br"),
      _c("p", [_vm._v("（1）我们如何收集和使用您的个人信息。 ")]),
      _c("br"),
      _c("p", [_vm._v("（2）我们如何使用Cookie和同类技术。 ")]),
      _c("br"),
      _c("p", [_vm._v("（3）我们如何共享、转让、公开披露您的个人信息。 ")]),
      _c("br"),
      _c("p", [_vm._v("（4）我们如何保存及保护您的个人信息。 ")]),
      _c("br"),
      _c("p", [_vm._v("（5）您如何管理您的个人信息。 ")]),
      _c("br"),
      _c("p", [_vm._v("（6）您共享的信息。 ")]),
      _c("br"),
      _c("p", [_vm._v("（7）适用法律与争议解决。 ")]),
      _c("br"),
      _c("p", [_vm._v("（8）未成年人保护。 ")]),
      _c("br"),
      _c("p", [_vm._v("（9）变更。 ")]),
      _c("br"),
      _c("p", [_vm._v("（10）联系我们。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "感谢您对智能神台的使用与信任，智能神台深知个人信息对您的重要性，我们将持续致力于为您提供更加可靠的服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("1. 我们如何收集和使用您的个人信息 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "“个人信息”是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。在您使用智能神台产品或服务时，智能神台可能自动接收并记录您的网站或应用程序上的服务器数值，包括但不限于您输入的关键词、您要求取用的网页记录及各种记录、智能神台产品或服务的操作状态、网络环境、异常日志等一些明确且客观反映在智能神台产品或服务服务器端的基本记录信息。你理解并同意，前述基本记录信息以及其他无法通过其识别您的个人身份的信息不属于您的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("1.1 您直接提供和我们主动收集的信息： ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）在您开启设备定位功能并使用智能神台提供的位置服务时，我们需要收集您的设备信息、位置信息、网络身份标识信息。我们会使用各种技术进行定位，这些技术包括 IP 地址、GPS 以及能够提供相关信息的其他传感器（比如说可能会为智能神台提供附近设备、Wi-Fi 接入点和基站的信息）。我们会对智能神台用户的位置信息进行定位计算，帮助您和其他智能神台用户获得更加精准的定位服务。您可以无需手动输入自身地理坐标就享受位置服务。您可以通过系统授权关闭定位功能，停止智能神台对您的地理位置信息的收集，但您可能将无法获得相关服务或功能，或者无法达到相关服务拟达到的效果。您的设备系统可能会在必要信息授权的同时开放其他权限，智能神台自身无法通过手动或自动设置关闭授权。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）当您使用搜索服务时，我们会收集您的日志信息：如您搜索查询的内容、点击的链接、发布的报错消息、反馈和评论的内容、您上传的图片以及您的IP地址、设备信息、浏览器的类型和使用的语言、操作系统版本、网络运营商的信息、访问服务时间等。我们会基于前述信息、结合我们收集的位置信息以及您的收藏数据（您收藏的坐标、地点等）对您的偏好、习惯、位置等作特征分析和用户画像，以便为您提供更合适您和您的设备的定制化服务。例如，向您展现或推荐相关程度更高的搜索结果、信息流或广告及推广信息。我们可能将您的画像信息与您授权的其他方的信息结合起来，更好的满足您的需求。智能神台暂不支持无痕搜索等即时删除功能，但您可以通过删除记录方式管理您的信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）您注册智能神台帐号时，会向我们提供帐号名称、昵称、手机号码等，成为智能神台会员后，您将会更多专属服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（4）您参加我们的运营活动时，我们需要您提供某些信息，可能会包含您的姓名、照片、通讯地址、邮编、身份信息、联系方式、银行或支付交易帐号。这些信息可以帮助我们与您取得联系，向您发放礼品。如果您拒绝提供此类信息，我们将无法完成相关服务或礼品发放。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（5）智能神台部分产品及版本中为您提供了基于设备的语音识别功能，您开启语音功能后，我们将基于您的语音指令做出反馈。您可以通过点击搜索框内的语音按钮或其他产品中明示的方式进入语音交互页面，也可通过设置功能中的选项将其取消或关闭。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（6）当您使用的客户端软件频繁向我们进行报错或显示错误日志信息时，为了检查软件错误，给您提供更好的服务，我们会在您的设备上记录、收集您的配置信息、异常数据等用于诊断并完善产品及服务的文件。上述信息的收集不会涉及到您的个人信息，请您放心使用。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（7）当您与智能神台联系时，为了方便与您联系或帮助您解决问题，我们可能会记录您的联系方式、身份信息，并结合需要记录您的通话内容。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("1.2 您使用第三方服务及功能时我们间接收集的信息： ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）您使用或订购第三方产品或服务的信息。为了给您更加便捷的生活服务，智能神台为您接入了丰富的第三方服务。您可以通过发布动态定位等产品模块使用相关功能。当您使用智能神台接入的第三方服务时，可能需要提交您的个人信息（部分情况下包括您的身份信息、联系方式、等个人敏感信息）。您授权我们将该信息提供给为您提供服务的第三方，并授权我们间接向第三方获取相关信息。若您拒绝我们收集此类信息，我们将无法为您提供相关功能和服务，但不会影响您对定位及搜索等核心产品和服务功能的使用。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "a.当使用发布动态定位的功能时，需要收集实现该功能所必需的位置信息，以为您完成服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）我们可能使用您的信息用于安全防范、诈骗预防及非法监测等，以预防、发现、调查危害公共安全、侵犯个人合法权益、违反法规或协议规则的行为，以保护您、我们或我们的关联公司、合作伙伴的合法权益及社会公益。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）在与您的设备系统及第三方应用交互为您提供服务时，智能神台会收集实现功能所必要的信息。如您拒绝此类信息收集及使用，需要在设备系统中进行设置或关闭提供服务的软件，智能神台无法自动或手动设置关闭相关服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "a.当第三方软件、设备系统与智能神台产品或服务结合为您提供基于位置的服务时，智能神台可能会向第三方软件或您的设备系统提供您的位置信息，特殊情境下会包含您另行填写的联系信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("1.3 使用说明。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）您授权我们在您使用智能神台产品及服务期间使用上述信息，若您注销帐号，我们将停止使用并根据您的要求删除您的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）如我们需要基于本政策未载明的其他用途或非特定目的收集或使用您的个人信息，我们会事先征求您的同意。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("1.4 去标识化。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "在收集到您的个人信息后，我们将通过技术手段及时对数据进行去标识化处理，去标识化处理的信息将无法识别主体。在不透露您个人信息的前提下，智能神台有权对用户数据进行挖掘、分析和利用（包括商业化利用）。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("1.5 例外。 ")]),
      _c("br"),
      _c("p", [_vm._v("以下情形中，收集您的个人信息无需经过您的授权同意： ")]),
      _c("br"),
      _c("p", [_vm._v("a.与国家安全、国防安全有关的； ")]),
      _c("br"),
      _c("p", [_vm._v("b.与公共安全、公共卫生、重大公共利益有关的； ")]),
      _c("br"),
      _c("p", [_vm._v("c.与犯罪侦查、起诉、审判和判决执行等有关的； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "d.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您同意的； "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("e.所收集的个人信息是您自行向社会公众公开的； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道； "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("g.根据您的要求实现产品功能及服务所必需的； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "h.用于维护所提供的产品和服务的安全和合规所必需的，例如发现、处置产品和服务的故障； "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("i.为合法的新闻报道所必需的； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "j.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("k.法律法规规定的其他情形。 ")]),
      _c("br"),
      _c("p", [_vm._v("2. 我们如何使用Cookie和同类技术 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）当您使用智能神台产品或服务时，为使您获得更轻松的访问体验，我们可能会使用各种技术来收集和存储信息，在此过程中可能会向您的设备发送一个或多个 Cookie 或匿名标识符。这么做是为了解您的使用习惯，使您省去重复输入注册信息等步骤，或帮助判断您的帐户安全。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）当您使用智能神台产品或服务时，我们还可能会利用Cookie和同类技术收取您的信息用于了解您的偏好，进行咨询或数据分析，改善产品服务及用户体验，提高广告效果，及时发现并防范安全风险，为用户和合作伙伴提供更好的服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）我们不会将Cookie用于本政策所述目的之外的任何用途，您可根据自己的偏好留存或删除Cookie。您可清除软件内保存的所有Cookie，当您手动清除后您的相关信息即已删除。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("3. 我们如何共享、转让、公开披露您的个人信息 ")]),
      _c("br"),
      _c("p", [_vm._v("3.1 共享 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）智能神台会以高度的勤勉义务对待您的信息，不会与智能神台及其关联公司以外的任何公司、组织和个人分享您的个人信息。但以下情况除外： "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("a.事先获得您的明确授权或同意； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "b.根据适用的法律法规、法律程序的要求或强制性的政府要求或司法裁定智能神台必须提供； "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "c.在法律要求或允许的范围内，为了保护智能神台、智能神台用户或社会公众的利益、财产或安全免遭损害而有必要提供； "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("d.基于学术研究而使用； ")]),
      _c("br"),
      _c("p", [_vm._v("e.基于符合法律法规的社会公共利益而使用； ")]),
      _c("br"),
      _c("p", [
        _vm._v("f.符合与您签署的其它法律文件（如本条款）的约定而使用； "),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "g.由于我们的某些服务将由授权合作伙伴提供，智能神台可能会向合作伙伴等第三方提供您的个人信息。我们的合作伙伴无权将共享的个人信息用于其他任何用途。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("（2）我们的授权合作伙伴可能包括以下类型： ")]),
      _c("br"),
      _c("p", [_vm._v("a.软件服务提供商、智能设备提供商和系统服务提供商。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "智能神台与第三方软件、设备系统结合为您提供基于位置的服务时，可能会基于您对系统定位的授权及设定，收取您的位置信息及设备信息并提供给系统，特殊情境下会包含您另行填写的个人信息。如果您拒绝此类信息收集及使用，您需要在设备系统中进行设置或关闭提供服务的软件，智能神台无法通过自动或手动设置关闭相关服务。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("b.供应商、服务提供商和其他合作伙伴。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "我们可能将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、提供产品内或产品链接后的功能型服务，分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务和调查。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("c.广告、分析服务类的授权合作伙伴。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "未经您的授权，我们不会将您的个人信息与提供广告、分析服务的合作伙伴共享。但我们可能会将使用您信息而形成的用户画像与提供广告、分析服务的合作伙伴共享，以帮助其在不识别您个人的前提下提升广告及服务信息的有效触达率。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("d.金融、征信服务等其他合作伙伴。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "根据您与相关合作伙伴签订的协议或文件，或基于法律法规、法律程序的要求或强制性的政府要求或司法裁定，或金融、征信审查等机构的合法需求，对您的个人信息进行综合统计、分析或加工等处理，并将数据结果分享给合作伙伴，用于判定您的资信状况，筛选优质用户或降低信贷坏账等。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("（3）共享要求。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "在共享信息前，我们会对共享个人信息的第三方进行数据安全能力审核，与其签署严格的数据安全和保密协定，要求他们按照我们的说明、本隐私权政策采取相关的保密和安全措施来处理上述信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("3.2 转让与公开 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）转让。我们不会将您的个人信息转让给除智能神台及其关联公司外的公司、组织和个人，但以下情况除外： "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("a.事先获得您的明确授权或同意； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "b.根据法律法规、法律程序的要求或强制性的政府要求或司法裁定智能神台必须提供； "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v("c.符合您签署的其它法律文件（如本条款）的约定而使用； "),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "d.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此隐私权政策的约束，否则我们会要求该公司、组织重新向您征求授权同意。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v("（2）公开披露。我们仅在以下情况，公开披露您的个人信息： "),
      ]),
      _c("br"),
      _c("p", [_vm._v("a.已获得您的明确同意； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "b.在法律法规、法律程序、诉讼或政府主管部门强制性要求下，我们可能会公开披露您的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("3.3 例外 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "在以下情形中，共享、转让、公开披露个人信息无需征得个人信息主体的授权与同意： "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("a.与国家安全、国防安全有关的； ")]),
      _c("br"),
      _c("p", [_vm._v("b.与公共安全、公共卫生、重大公共利益有关的； ")]),
      _c("br"),
      _c("p", [_vm._v("c.与犯罪侦查、起诉、审判和判决执行等有关的； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "d.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("e.个人信息主体自行向社会公众公开的个人信息； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("4. 我们如何保存及保护您的个人信息 ")]),
      _c("br"),
      _c("p", [_vm._v("4.1 保存地域。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "您的个人信息将储存于中华人民共和国境内，除您在境外使用智能神台产品或服务需要数据传输情形外，如需跨境传输，我们将会单独征得您的授权同意。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("4.2 保存期限。 ")]),
      _c("br"),
      _c("p", [
        _vm._v("除非法律法规另有规定，智能神台将按如下期间保存您的信息： "),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）您使用智能神台产品或服务期间，我们将持续为您保存，除非您自主删除这些信息或者注销帐户； "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）当您删除个人信息或注销帐户后，智能神台将立即停止对用户个人信息的使用并对信息进行匿名化处理，但智能神台有权在36个月内继续单独保存您的信息； "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）您的个人信息经匿名化处理后将形成可以使用及流通的数据，智能神台对此类数据的保存及处理无需另行通知并征得您的同意。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("4.3 安全措施。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）智能神台会采取适当的符合业界标准的安全措施和技术手段存储和保护您的个人信息，以防止其丢失、被误用、受到未授权访问或泄漏、被篡改或毁坏，如通过网络安全层技术SSL进行加密传输、信息加密存储、严格限制数据中心的访问、使用专用网络通道及网络代理。您的个人信息存放在有密码控制的服务器中，访问均是受到限制的。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）我们设立了个人信息保护责任部门，建立了相关内控制度，对可能接触到您的信息的工作人员采用最小够用授权原则。即我们仅允许有必要知晓这些信息的智能神台和其关联公司的员工在采取合理的措施验证身份之后，访问或修改这些信息。同时，我们会严格要求他们履行保密及安全义务，如果未能履行这些义务，其会被追究法律责任或被终止与智能神台的合作关系。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）为了保护您的信息安全，如果用户个人信息有误，智能神台会在严格验证并核实申请人身份后，根据用户要求访问、修正或删除相关信息（除非智能神台出于合法的原因而必须保留这些个人信息）。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("4.4 特别提示。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）互联网并非绝对安全的环境，电子邮件、即时通讯、社交软件、交易平台等与其他用户的交流方式无法确定是否完全加密，请您在进行交互使用时，注意保护您个人信息的安全。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）请您理解，由于计算机及互联网技术的飞速发展及同步速度的限制，可能存在或出现各种恶意或非恶意的攻击手段。虽然智能神台持续致力于提升和加强安全措施，以保护您的信息免遭意外或破坏，但仍无法始终保证信息的百分之百安全。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）您使用产品或服务时所用的系统和通讯网络，或硬件设备等智能神台均无法控制，请您了解并注意保护您的个人信息安全。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("4.5 安全事件 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）如不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处理措施、您可自主防范和降低的风险的建议、对您的补救措施等。我们会及时将事件相关情况以站内通知、短信通知、电话、邮件等您预留的联系方式告知您，难以逐一告知时我们会采取合理、有效的方式发布公告。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）我们会及时处置系统漏洞、网络攻击、病毒侵袭及网络侵入等安全风险。在发生危害网络安全的事件时，我们会按照网络安全事件应急预案，及时采取相应的补救措施，并按照规定向有关主管部门报告。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或损毁，导致您的合法权益受损，我们将严格依照法律的规定承担相应的责任。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("5. 您如何管理自己的信息 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "智能神台非常尊重您对个人信息的关注，并为您提供了管理个人信息的方法。您有权利查询、更正、管理、删除自己的信息并保护自己的隐私和安全。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("5.1 访问权。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "除法律法规规定的例外情况，无论您何时使用我们的服务，我们都会力求让您顺利访问自己的个人信息。您可以通过如下方式行使您的访问权利： "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）帐户信息：如果您希望访问或编辑您的帐户中的个人资料信息、更改您的密码、添加安全信息等，您可以在您使用的产品中执行此类操作。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）使用信息：您可以在智能神台产品中查阅或清除您的搜索记录、使用记录及行动轨迹。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）订单信息：您可以在智能神台产品中查阅或清除您的订单记录，交易记录及发票记录等。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("5.2 更正权。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "当您需要更新您的个人信息时，或发现我们处理您的个人信息有错误时，您有权作出更正或更新。您可以自行在智能神台产品内进行更正，或通过反馈与报错等将您的更正申请提交给我们。在您进行更新或更正前，我们可能会先要求您验证自己的身份，然后再处理您的请求。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("5.3 删除权。 ")]),
      _c("br"),
      _c("p", [_vm._v("（1）您可以通过产品中的设置或功能控件删除您的信息。 ")]),
      _c("br"),
      _c("p", [
        _vm._v("（2）发生以下情形，您可以向我们提出删除个人信息的请求： "),
      ]),
      _c("br"),
      _c("p", [_vm._v("a.如果我们处理个人信息的行为违反法律法规； ")]),
      _c("br"),
      _c("p", [_vm._v("b.如果收集、使用您的个人信息，却未征得您的同意； ")]),
      _c("br"),
      _c("p", [_vm._v("c.如果我们处理个人信息的行为违反了与您的约定； ")]),
      _c("br"),
      _c("p", [
        _vm._v("d.如果您注销了帐号，我们会删除您的个人信息或做匿名化处理； "),
      ]),
      _c("br"),
      _c("p", [_vm._v("e.如果我们终止产品运营及服务。 ")]),
      _c("br"),
      _c("p", [_vm._v("5.4 撤销权。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）您可以通过解除绑定、修改个人设置、删除相关信息等方式撤回部分授权，也可以通过关闭功能的方式撤销授权。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("5.5 管理问题。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）如果您无法通过上述方式访问、更正或删除您的个人信息，或者您无法完成帐号注销申请，或者您就智能神台收集、使用您信息有任何疑问或者问题，您都可以通过智能神台客服渠道与我们取得联系。为保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份。我们将在收到您的反馈并验证您身份后30日内答复您的相关请求。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）我们将尽合理商业努力，满足您对于个人信息的查询、更正、管理、删除及帐号注销的要求。但对于无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（3）对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("5.6 例外。 ")]),
      _c("br"),
      _c("p", [
        _vm._v("按照法律法规要求，以下情况中，我们将无法响应您的请求： "),
      ]),
      _c("br"),
      _c("p", [_vm._v("a.与国家安全、国防安全有关的； ")]),
      _c("br"),
      _c("p", [_vm._v("b.与公共安全、公共卫生、重大公共利益有关的； ")]),
      _c("br"),
      _c("p", [_vm._v("c.与犯罪侦查、起诉和审判等有关的； ")]),
      _c("br"),
      _c("p", [_vm._v("d.有充分证据表明您存在主观恶意或滥用权利的； ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "e.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("6. 您共享的信息 ")]),
      _c("br"),
      _c("p", [_vm._v("6.1 情报与反馈。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）智能神台的成长离不开您的支持与帮助，非常感谢您向我们提供的数据更新、情报反馈以及各种评论与内容的贡献。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）当您在智能神台产品或服务中公开分享任何信息时，任何人都可能在使用或未使用智能神台产品或服务时查看或访问这些信息，如您在智能神台中上传的照片、评价等数据。请您共享信息时尽量避免个人敏感信息，并审慎决策。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("6.2 位置分享。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）您可以基于我们的服务与他人、社交网络分享您的位置，并自主选择沟通、分享的对象，作为能够看到您搜索内容、分享内容、交流信息等相关信息的第三方。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）当您与他人、社交网络、或第三方服务提供商交互，共享您的位置、使用记录或其他个人信息，或结合第三方的服务使用、或指向第三方服务的链接时，您可能会披露或使第三方获取您的个人信息，以及可能获取用于广告目的的随机匿名标识符。智能神台建议您查看此类社交网络或第三方服务提供商的隐私声明，以了解他们如何处理您的信息，以便审慎决策。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("7. 适用法律与争议解决 ")]),
      _c("br"),
      _c("p", [_vm._v("7.1 适用法律。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "本政策的执行、解释及争议的解决均适用中华人民共和国法律，且不考虑任何冲突法。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("7.2 争议解决。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "您和智能神台就本政策内容或其执行发生任何争议的，双方应友好协商解决；如双方无法协商解决争议时，双方同意应将争议提交至被告住所地人民法院裁决。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("8. 未成年人保护 ")]),
      _c("br"),
      _c("p", [_vm._v("8.1 我们重视未成年人的个人信息保护。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "如您为未成年人（特别是年龄不满14周岁的未成年人），请务必要求您的监护人仔细阅读本隐私权政策，并请您在征得您的监护人同意的前提下使用我们的服务或向我们提供信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("8.2 补救措施。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）如果您未能取得您监护人的同意或您未同意您的被监护人使用我们的服务并向我们提供信息，请您或被监护人立即停止使用我们的服务并及时与我们取得联系。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）在收到您的书面通知而知晓或我们自行知晓，智能神台在未事先获得监护人同意的情况下收集了未成年的个人信息时，智能神台会立即停止相关信息收集及使用。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("9. 变更 ")]),
      _c("br"),
      _c("p", [_vm._v("9.1 更新与调整。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "本政策将根据业务模式的调整或更新不定期进行修订，该等修订构成本隐私权政策的一部分，并具有溯及力。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("9.2 告知方式。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）未经您的明确同意，我们不会削减您按照本隐私权政策所享有的权利。我们会通过合理的方式发布隐私权政策所做的变更。若您继续使用我们的产品或服务，即表示您同意修订后政策条款。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）如发生重大变更，我们将会采取更具有提示作用的方式告知用户，包括但不限于发送邮件、信函、电话、推送通知等。本政策所指的重大变更包括但不限于： "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("a.我们的服务模式发生重大变化； ")]),
      _c("br"),
      _c("p", [_vm._v("b.个人信息公开披露的主要对象发生变化； ")]),
      _c("br"),
      _c("p", [_vm._v("c.个人信息处理的权利及其行使方式发生重大变化； ")]),
      _c("br"),
      _c("p", [_vm._v("d.我们的联络方式及投诉渠道发生变化。 ")]),
      _c("br"),
      _c("p", [_vm._v("10. 您如何联系我们 ")]),
      _c("br"),
      _c("p", [_vm._v("10.1 客服渠道。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "如果您对本隐私政策有任何疑问、意见或建议，或您在使用智能神台产品或服务时，就您的个人信息的收集、使用、共享、访问、删除、更正等相关事宜有任何意见或建议，或您在使用产品或服务时遇到哪些问题，您都可以通过智能神台客服与我们联系。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("10.2 特别提示。 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（1）智能神台提醒您，如您反馈的问题涉及您的个人信息，尤其是您的敏感信息，请您不要直接在电话或邮件中进行透露，我们将会在确保您个人信息安全的情况下与您进一步沟通。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "（2）部分情况下，为保障您个人信息的安全，我们可能需要您提供书面请求，或以其他方式证明您的身份。我们将在收到您的反馈并验证您的身份后30日内答复您的相关请求。 "
        ),
      ]),
      _c("br"),
      _c("p", [_vm._v("附：名词解释 ")]),
      _c("br"),
      _c("p", [
        _vm._v(
          "为了帮助您更好的了解本隐私权政策，将前述条款中的部分名词进行解释如下： "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•个人敏感信息指一旦泄露或滥用，可能危害人身和财产安全、导致个人名誉、身心健康等受到损害或歧视性待遇等的个人信息。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•唯一设备标识符指具有唯一性的设备标识符，由设备制造商编入到设备中的一串字符，可用于以独有方式标识相应设备（例如国际移动设备身份码IMEI号、网络设备硬件地址MAC等）。不同的设备标识符在有效期、用户是否可以重置以及获取方式方面会有所不同。相应设备可能会有多个不同的具有唯一性的设备标识符。唯一设备标识符可用于多种用途，其中包括安全性和欺诈检测、同步服务（如用户的电子邮件收件箱）、记录用户的偏好设置以及提供具有相关性的广告。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•Cookie是指支持服务器端（或者脚本）在客户端上存储和检索信息的一种机制，通过增加简单、持续的客户端状态来扩展基于Web的客户端/服务器应用。服务器在向客户端返回HTTP对象的同时发送一条状态信息，并由客户端保存。状态信息中说明了该状态下有效的URL范围。此后，客户端发起的该范围内的HTTP请求都将把该状态信息的当前值从客户端返回给服务器，这个状态信息被称为cookie。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•匿名标示符是指基于设备参数，通过机器算法，生成一个标示符帮助智能神台识别一个用户，为其提供更好的产品和/或服务与改善用户体验。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•用户画像是指通过收集、汇聚、分析个人信息，对自然人个人特征，如其职业、经济、健康、教育、个人喜好、信用、行为等方面做出分析或预测，形成其个人特征模型的过程。直接使用特定自然人的个人信息，形成该自然人的特征模型，称为直接用户画像。使用来源于特定自然人以外的个人信息，如其所在群体的数据，形成该自然人的特征模型，称为间接用户画像。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•去标识化是指通过对个人信息的处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•匿名化是指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。 "
        ),
      ]),
      _c("br"),
      _c("p", [
        _vm._v(
          "•关联公司是指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、合法机构以及上述公司、合法机构的合法继承人。控制是指（1）直接或间接持有过半数的投票权；或（2）直接或间接享有过半数的可分配利润；或（3）直接或间接控制董事会半数以上的成员的组成；或（4）直接或间接持有半数的注册资本。 "
        ),
      ]),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }