<template>
  <div :class="`instructions-box f fv fc ${from === 'STJ' ? '' : 'top70'}`">
    <GoBack v-if="from !== 'STJ'" backIcon="https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png" />

    <div class="item" v-for="({ title, content }, idx) in list" :key="idx">
      <div v-if="title" class="title">{{ title }}</div>
      <div class="content">
        <p class="txt-c">
          <img :src="content" alt="" srcset="" />
        </p>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import GoBack from '@/components/GoBack/GoBack.vue';

export default {
  components: {
    GoBack,
  },
  data() {
    return {
      list: [
        {
          title: '',
          content: 'https://img-fe.tengzhihh.com/image/90db9dc8aa4aa3f2ce1d270d0da7f147.png',
        },
        {
          title: '',
          content: 'https://img-fe.tengzhihh.com/image/5a3b81dfc47a05be364c6a937ce4d09f.png',
        },
        {
          title: '',
          content: 'https://img-fe.tengzhihh.com/image/c98c6f3d6a2beb3b79c543d61ebb6ddd.png',
        },
      ],
      from: '',
    };
  },
  created() {
    this.from = this.$route.query.from;
  },
};
</script>

<style lang="less" scoped>
.instructions-box {
  padding: 10px 0;
  background: #f8f8f8;
  width: 375px;
  min-height: calc(var(--vh) * 100);

  .item {
    width: 343px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    box-sizing: border-box;
    .title {
      font-size: 15px;
      color: #f87a1b;
      margin-bottom: 16px;
    }
    .content {
      font-size: 16px;
      .txt-c {
        text-align: center;
      }
    }
  }
}
.top70 {
  padding-top: 70px;
}
</style>

<style lang="less">
.instructions-box {
  .item img {
    width: 300px;
  }
}
</style>
