var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-box" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "icon-box f fc frd flex-wrap" },
      [
        _vm._l(_vm.iconList, function (item, index) {
          return _c(
            "div",
            {
              staticClass: "icon-item f fv fvc",
              on: {
                click: function ($event) {
                  return _vm.view(_vm.link)
                },
              },
            },
            [
              _c("img", {
                staticClass: "icon-logo",
                attrs: { src: item.icon },
              }),
              _c("div", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(item.name) + " "),
              ]),
            ]
          )
        }),
        _vm._l(_vm.iList, function (item) {
          return _c("i")
        }),
      ],
      2
    ),
    _c("div", { staticClass: "news-box" }, [
      _vm._m(1),
      _c(
        "div",
        { staticClass: "news-list" },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              staticClass: "news-item f fc",
              on: {
                click: function ($event) {
                  return _vm.view(item.link)
                },
              },
            },
            [
              _c("div", { staticClass: "left" }, [
                _c("img", {
                  staticClass: "sub",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/e9faf356cd85dd-92x34.png",
                  },
                }),
                _c("img", { staticClass: "cover", attrs: { src: item.cover } }),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-box" }, [
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/e06376e0803b49-690x256.png",
        },
      }),
      _c("img", {
        staticClass: "bg",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/8c8d4e0f69b76b-690x106.png",
        },
      }),
      _c("div", { staticClass: "name" }, [_vm._v("广宁-紫竹观")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "news-title f fc" }, [
      _c("div", { staticClass: "point" }),
      _c("div", [_vm._v("最新活动")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }