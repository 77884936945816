var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { position: "bottom" },
      on: { "click-overlay": _vm.onCancel },
      model: {
        value: _vm.showFlag,
        callback: function ($$v) {
          _vm.showFlag = $$v
        },
        expression: "showFlag",
      },
    },
    [
      _c("van-picker", {
        attrs: {
          "show-toolbar": "",
          "default-index": _vm.defaultIndex,
          title: _vm.title,
          columns: _vm.columns,
        },
        on: {
          confirm: _vm.onConfirm,
          cancel: _vm.onCancel,
          change: _vm.onChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }