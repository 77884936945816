<template>
  <div class="back f fvc">
    <div
      class="icon"
      :style="{
        'border-bottom-color': borderColor,
        'border-left-color': borderColor,
      }"
      @click="backFn"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    borderColor: {
      type: String,
      default: '#000',
    },
  },
  methods: {
    backFn() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="less" scoped>
.back {
  width: 28px;
  height: 28px;
  .icon {
    width: 12px;
    height: 12px;
    border: 2px solid transparent;
    transform: rotate(45deg);
  }
}
</style>
