var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods-list-box" },
    [
      _c(
        "van-tabs",
        {
          attrs: {
            background: "#e9e7e6",
            animated: "",
            sticky: "",
            swipeable: "",
          },
          on: { change: _vm.changeNav },
          model: {
            value: _vm.active,
            callback: function ($$v) {
              _vm.active = $$v
            },
            expression: "active",
          },
        },
        _vm._l(_vm.nav, function (item) {
          return _c(
            "van-tab",
            {
              key: item.id,
              attrs: { title: item.name, "title-class": "item" },
            },
            [
              _c(
                "div",
                { staticClass: "list-box" },
                [_c("goods-list", { attrs: { list: _vm.list } })],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }