<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>
    <van-cell
      title="勿扰模式"
      is_link
      center
      :label="`${generalConfig.dnd_start_hour}:00 - ${generalConfig.dnd_end_hour}:00`"
    >
      <template #right-icon>
        <van-switch
          active-color="#f87a1b"
          inactive-color="#ffeac9"
          size="20px"
          :value="generalConfig.dnd_mode"
          @input="modeChange"
        />
      </template>
    </van-cell>

    <van-popup v-model="nameDialog">
      <div class="f fv fc dialog-box">
        <div class="title">修改名称</div>
        <van-field v-model="newName" clearable class="input-item f fc" maxlength="10"></van-field>
        <van-button class="save-btn" @click="saveNewName">保存</van-button>
      </div>
    </van-popup>

    <time-range-picker
      title="选择时间段"
      v-if="timeRangePickerFlag"
      :show="timeRangePickerFlag"
      :defaultStart="generalConfig.dnd_start_hour"
      :defaultEnd="generalConfig.dnd_end_hour"
      pickerType="hour"
      @onConfirm="confirmDatetimePicker"
      @onCancel="onCancel"
    >
    </time-range-picker>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import TimeRangePicker from '@/components/TimeRangePicker/TimeRangePicker.vue';
import { mapState } from 'vuex';
export default {
  components: { CellGroup, TimeRangePicker },
  data() {
    return {
      serialId: '',
      nameDialog: false,
      newName: '',
      timeRangePickerFlag: false,
    };
  },
  computed: {
    ...mapState(['generalConfig']),
    settingList: function () {
      return [
        {
          title: '神台机名称',
          value: this.generalConfig.name,
          isLink: true,
          cb: () => {
            this.nameDialog = true;
          },
        },
        // {
        //   title: '绑定账号管理',
        //   isLink: true,
        //   path: `/settings/account?serial_id=${this.serialId}`,
        //   value: this.generalConfig.account_num - 1,
        // },
      ];
    },
  },
  async mounted() {
    this.serialId = this.$route.query.serial_id;
    await this.$utils.getGeneralConfig(this.serialId);
    this.newName = this.generalConfig.name;
  },
  methods: {
    modeChange(e) {
      if (e) {
        this.openDatePicker();
      } else {
        const formData = new FormData();
        formData.append('serial_id', this.serialId);
        formData.append('dnd_mode', this.generalConfig.dnd_mode ? 'close' : 'open');
        this.$http.formPost(apiPath.generalConfig, formData).then(() => {
          this.$toast('修改成功');
          this.$utils.getGeneralConfig(this.serialId);
        });
      }
    },
    onCancel() {
      this.timeRangePickerFlag = false;
    },
    confirmDatetimePicker(s, e) {
      const formData = new FormData();
      formData.append('serial_id', this.serialId);
      formData.append('dnd_mode', this.generalConfig.dnd_mode ? 'close' : 'open');
      formData.append('dnd_start_hour', s);
      formData.append('dnd_end_hour', e);
      this.$http.formPost(apiPath.generalConfig, formData).then(() => {
        this.$toast('修改成功');
        this.$utils.getGeneralConfig(this.serialId);

        this.timeRangePickerFlag = false;
      });
    },

    openDatePicker() {
      this.timeRangePickerFlag = true;
    },

    async saveNewName() {
      if (!this.newName) {
        this.$toast('请输入名称');

        return false;
      }
      await this.changeName();
      this.nameDialog = false;
      this.newName = '';
      this.$utils.getGeneralConfig(this.serialId);
    },
    changeName() {
      const formData = new FormData();
      formData.append('serial_id', this.serialId);
      formData.append('name', this.newName);
      return this.$http.formPost(apiPath.generalConfig, formData);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
  .dialog-box {
    width: 232px;
    height: 167px;
    border-radius: 15px;
    .title {
      font-size: 15px;
      font-weight: bold;
      color: #2e2d2d;
      margin: 25px 0 19px;
    }
    .input-item {
      width: 202px;
      height: 37px;
      border-radius: 2px;
      border: 1px solid #999;
    }
    .save-btn {
      width: 159px;
      height: 37px;
      background: #f87a1b;
      border-radius: 15px;
      font-size: 13px;
      font-weight: 800;
      color: #fff6ec;
      margin: 19px 0;
    }
  }
}
</style>
