var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "f fv fc page-box" }, [
    _c("div", { staticClass: "info f fc fv" }, [
      _c("div", { staticClass: "img-box" }, [
        _vm.image
          ? _c("img", { staticClass: "device-img", attrs: { src: _vm.image } })
          : _vm._e(),
      ]),
      _c("div", { staticClass: "tip" }, [_vm._v("检测到")]),
      _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
    ]),
    _c(
      "div",
      { staticClass: "btn-box f fv fc" },
      [
        _vm.bindCount === 0
          ? _c("div", { staticClass: "tip" }, [
              _vm._v("神台机首次启动，需要进行初始化设置才能绑定"),
            ])
          : _vm.userIsBind === 1
          ? _c("div", { staticClass: "tip" }, [
              _vm._v("你已经绑定该设备，进入首页开始管理"),
            ])
          : _vm._e(),
        _vm.userIsBind === 1
          ? _c(
              "van-button",
              { staticClass: "add-btn", on: { click: _vm.toHome } },
              [_vm._v("开始管理")]
            )
          : _c(
              "van-button",
              {
                staticClass: "add-btn",
                attrs: { loading: _vm.loading },
                on: { click: _vm.startSetting },
              },
              [_vm._v(_vm._s(_vm.bindCount === 0 ? "开始设置" : "添加设备"))]
            ),
        _c("div", { staticClass: "re-btn", on: { click: _vm.reScan } }, [
          _vm._v("重新扫描"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }