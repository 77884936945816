<template>
  <div class="list-box">
    <div class="line"></div>

    <div class="box-header">
      <van-icon name="arrow-left" @click="goBack" class="back-icon" />
    </div>

    <van-radio-group v-model="defaultMachine">
      <div class="machine-item" v-for="item in list" :key="item.serial_id">
        <div class="item-box">
          <div class="top f fc fbt">
            <div class="name-box f fc">
              <div class="name">{{ item.name }}</div>

              <img
                src="https://img-fe.tengzhihh.com/image/45c9e2c3351bcd-56x39.png"
                v-if="item.is_vip"
                class="vip-icon"
              />

              <span class="is-buy" v-if="item.is_buy">已购买</span>
            </div>
            <div
              :class="`select ${selectedItem.serial_id === item.serial_id ? 'selected' : ''}`"
              @click="selectMachine(item)"
            ></div>
          </div>
          <!-- <div class="info">
            <van-radio :name="item"
              @click.stop.prevent="setDefaultMachine(item)"
              checked-color="#E6A763"
              icon-size="14px">{{item.is_default?'已':''}}设为默认</van-radio>
          </div> -->
        </div>

        <div class="line"></div>
      </div>
    </van-radio-group>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
export default {
  data() {
    return {
      list: [],
      defaultMachine: '',
      selectedItem: {
        serial_id: '',
      },
      goodsId: 0,
    };
  },
  mounted() {
    this.goodsId = this.$route.query.goods_id;
    this.getList();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    selectMachine(item) {
      if (item.is_buy) {
        return this.$toast('该神台机已购买该物品');
      }
      this.selectedItem = item;
      this.$router.push(
        `/shop/goodsDetail?id=${this.goodsId}&machine=${encodeURIComponent(JSON.stringify(this.selectedItem))}`
      );
    },
    setDefaultMachine(item) {
      if (item.is_default) {
        return false;
      }
      const formData = new FormData();
      formData.append('serial_id', item.serial_id);
      this.$http.formPost(apiPath.setDefaultMachine, formData).then(() => {
        this.$toast('设置成功');
        this.getList();
      });
    },
    getList() {
      this.$http
        .get(apiPath.userMachines, {
          goods_id: this.goodsId,
        })
        .then((res) => {
          this.list = res.data;
          this.defaultMachine = this.list.find((i) => i.is_default);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list-box {
  .line {
    width: 375px;
    height: 10px;
    background-color: #f2f2f2;
  }
  .box-header {
    width: 375px;
    box-sizing: border-box;
    height: 40px;
    background-color: #fff;
    padding: 10px 20px;
    .back-icon {
      color: #000;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .machine-item {
    .item-box {
      padding: 18px 20px;
      width: 375px;
      box-sizing: border-box;
    }

    .top {
      .select {
        width: 18px;
        height: 18px;
        background: #ffffff;
        border: 1px solid #999999;
        border-radius: 50%;
      }
      .selected {
        width: 10px;
        height: 10px;
        border: 6px solid #e6a763;
      }
      .name-box {
        position: relative;
        .name {
          font-size: 14px;
          font-weight: 800;
          color: #333333;
        }
        .vip-icon {
          position: absolute;
          right: -30px;
          top: 1px;
          width: 27px;
          height: 19px;
        }
        .is-buy {
          font-size: 12px;
          padding: 2px 4px;
          border: 1px solid #999;
          border-radius: 5px;
          margin-left: 10px;
        }
      }
    }
  }
  .info {
    width: fit-content;
  }
}
</style>
<style lang="less">
.van-radio__label {
  font-size: 13px;
  font-weight: 500;
  color: #939393;
}
</style>
