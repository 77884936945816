<template>
  <van-popup v-model="showFlag" @click-overlay="onCancel" position="bottom">
    <van-datetime-picker
      v-model="time"
      v-if="pickerType === 'time'"
      title="选择时间"
      :filter="filter"
      :type="pickerType"
      :swipe-duration="100"
      :min-hour="0"
      :max-hour="23"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
    <van-datetime-picker
      v-model="date"
      v-else
      title="选择时间"
      :filter="filter"
      :type="pickerType"
      :swipe-duration="100"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
      @cancel="onCancel"
    />
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pickerType: {
      type: String,
      default: 'date',
    },
    currentDate: {
      type: Date,
      default: () => new Date(),
    },
    currentHour: {
      type: String,
      default: `${new Date().getHours()}:${new Date().getMinutes()}`,
    },
    propMinDate: {
      type: Date,
      default: () => new Date(1800, 0, 1),
    },
    propMaxDate: {
      type: Date,
      default: () => new Date(),
    },
    filter: {
      type: Function,
    },
  },
  data() {
    return {
      showFlag: this.show,
      date: this.currentDate,
      time: this.currentHour,
      minDate: this.propMinDate,
      maxDate: this.propMaxDate,
    };
  },
  watch: {
    show: function (v) {
      this.showFlag = v;
      this.init();
    },
  },
  methods: {
    init() {
      this.date = this.currentDate;
      this.time = this.currentHour;
      this.minDate = this.propMinDate;
      this.maxDate = this.propMax;
    },
    formatter(date) {
      if (date) {
        if (this.pickerType === 'date') {
          return `${date.getFullYear()}-${this.addZero(date.getMonth() + 1)}-${this.addZero(date.getDate())}`;
        } else {
          return `${date.getFullYear()}-${this.addZero(date.getMonth() + 1)}-${this.addZero(
            date.getDate()
          )} ${this.addZero(date.getHours())}:${this.addZero(date.getMinutes())}`;
        }
      } else {
        return '';
      }
    },
    addZero(v) {
      if (String(v).length === 1) {
        return `0${v}`;
      } else {
        return v;
      }
    },
    onConfirm() {
      if (this.pickerType === 'time') {
        this.$emit('onConfirm', this.time);
      } else {
        this.$emit('onConfirm', this.formatter(this.date));
      }
      this.onCancel();
    },
    onCancel() {
      this.$emit('onCancel');
    },
    onChange(value, index) {
      this.$emit('onChange', value, index);
    },
  },
};
</script>

<style lang="less" scoped>
.time-range-header {
  padding: 0 20px;
  height: 50px;
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .confirm {
    font-size: 13px;
    color: #576b95;
  }
  .cancel {
    font-size: 13px;
  }
}
.van-popup {
  border-radius: 0;
}
</style>
