import { request } from '@/utils/request';

// addressList: '/address/list',
// address: '/address/item', //GET /{id}地址详情 POST 地址添加 DELETE /{id}收货地址删除 PUT /{id}收货地址修改 PATCH /{id}地址更新（默认设置）
// addressDefault: '/address/default', //默认地址
export function addressList() {
  return request({
    url: '/address/list',
    method: 'get',
  });
}
export function getDefault(data) {
  return request({
    url: '/address/default',
    method: 'get',
  });
}
export function getAddress(data) {
  return request({
    url: '/address/item/' + data.id,
    method: 'get',
  });
}
export function addAddress(data) {
  return request({
    url: '/address/item',
    method: 'post',
    data,
  });
}
export function removeAddress(data) {
  return request({
    url: `/address/item/${data.id}`,
    method: 'delete',
  });
}

export function patchAddress(data) {
  return request({
    data,
    url: `/address/item/${data.id}`,
    method: 'patch',
  });
}
export function editAddress(data) {
  return request({
    url: `/address/item/${data.id}`,
    method: 'put',
    data,
  });
}
