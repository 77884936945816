var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c("img", {
      staticClass: "bg",
      attrs: {
        src: "https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png",
      },
    }),
    _vm.list.length === 0
      ? _c(
          "div",
          { staticClass: "f fv fc empty-box" },
          [
            _c("GoBack"),
            _c(
              "div",
              {
                staticClass: "add-btn click-hover f fc fvc",
                on: { click: _vm.uploadMotto },
              },
              [
                _c("img", {
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/474ebc18b71bbb-228x228.png",
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "tip", on: { click: _vm.uploadMotto } }, [
              _vm._v("上传我的家风家训"),
            ]),
            _c("div", { staticClass: "volume-tip" }, [
              _vm._v(" " + _vm._s(_vm.volumeStr) + " "),
            ]),
          ],
          1
        )
      : _c("div", { staticClass: "list-box" }, [
          _c("div", { staticClass: "volume-tip" }, [
            _vm._v(" " + _vm._s(_vm.volumeStr) + " "),
          ]),
          _c(
            "div",
            { staticClass: "f fc fbt" },
            [
              _c("GoBack", { attrs: { type: "str" } }),
              _c(
                "van-button",
                { staticClass: "add-btn", on: { click: _vm.handleBtn } },
                [_vm._v(_vm._s(_vm.editing ? "删除" : "上传新的家风家训"))]
              ),
              _c("div", { staticClass: "f fc btn-header" }, [
                _c("div", { on: { click: _vm.handleEditing } }, [
                  _vm._v(_vm._s(_vm.editing ? "取消" : "管理")),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "f fc flex-wrap fbt" },
            _vm._l(_vm.list, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "item-box",
                  on: {
                    click: function ($event) {
                      return _vm.viewDetail(item)
                    },
                  },
                },
                [
                  _vm.editing
                    ? _c("div", { staticClass: "check-box" }, [
                        _vm.hasSelected(item.id)
                          ? _c("img", {
                              staticClass: "checked",
                              attrs: {
                                src: "https://img-fe.tengzhihh.com/image/d68fc0abc797cf-18x18.png",
                              },
                            })
                          : _c("img", {
                              staticClass: "un-check",
                              attrs: {
                                src: "https://img-fe.tengzhihh.com/image/625bee427aecd9-18x18.png",
                              },
                            }),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "cover-box f fc fvc" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: _vm.calcIcon(item).url },
                    }),
                    _vm.calcCover(item)
                      ? _c("img", {
                          staticClass: "cover",
                          attrs: { src: _vm.calcCover(item) },
                        })
                      : _c("div", { staticClass: "cover" }),
                  ]),
                  _c("div", { staticClass: "uploader f fc" }, [
                    _c("img", {
                      staticClass: "avatar",
                      attrs: {
                        src: _vm.$tools.compressImg(item.user_avatar, 30),
                      },
                    }),
                    _c("div", { staticClass: "info-box" }, [
                      _c("p", { staticClass: "name" }, [_vm._v("我")]),
                      _c("p", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.formatTime(item.created_at))),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }