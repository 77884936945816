var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("cell-group", { attrs: { list: _vm.settingList } }),
      _c("selector", {
        attrs: {
          title: _vm.selectorTitle,
          show: _vm.selectorShow,
          columns: _vm.selectorColumns,
          defaultIndex: _vm.findIndex(_vm.generalConfig.spray_duration),
        },
        on: { onConfirm: _vm.onConfirm, onCancel: _vm.onCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }