<template>
  <div v-if="show || showFields">
    <van-popup class="form-popup" v-model="show">
      <img class="img" :src="info.gift.img" alt="" />
      <div class="title">填写邮寄地址</div>
      <van-form @submit="onSubmit" validate-first>
        <van-field
          v-model="name"
          name="name"
          placeholder="收件人名称"
          :rules="[
            { required: true, message: '请填写收件人名称' },
            { pattern: /^.{0,12}$/, message: '长度不超过12个字' },
          ]"
          :border="false"
        />
        <van-field
          v-model="phone"
          name="phone"
          placeholder="收件人手机号"
          :maxlength="11"
          :rules="[
            { required: true, message: '请填写手机号码' },
            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确手机号' },
          ]"
          :border="false"
        />
        <van-field
          v-model="address"
          name="address"
          rows="3"
          autosize
          placeholder="收件人地址"
          type="textarea"
          :rules="[
            { required: true, message: '请填写收件人地址' },
            { pattern: /^.{0,50}$/, message: '长度不超过50个字' },
          ]"
          :border="false"
        />

        <van-button round block color="#ff8e38" native-type="submit">提交</van-button>
      </van-form>
    </van-popup>
    <van-popup class="confirm-popup" v-model="showFields">
      <div class="confirm-title">确认兑换信息</div>
      <div class="confirm-content">
        <div class="confirm-item">
          <span class="confirm-label">收件人名称</span>
          <span class="confirm-value">{{ name }}</span>
        </div>
        <div class="confirm-item">
          <span class="confirm-label">收件人手机号</span>
          <span class="confirm-value">{{ phone }}</span>
        </div>
        <div class="confirm-item">
          <span class="confirm-label">收件人地址</span>
          <span class="confirm-value">{{ address }}</span>
        </div>
        <div class="confirm-item">
          <span class="confirm-label">兑换物品</span>
          <span class="confirm-value">{{ info.gift.name }}×{{ info.can_receive_num }}</span>
        </div>
        <van-button class="confirm-btn" round block color="#ff8e38" @click="onConfirm">确认</van-button>
        <div class="kefu" @click="kefu">联系客服</div>
      </div>
    </van-popup>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';

export default {
  components: {
    Dialog,
  },
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      showFields: false,
      name: '',
      phone: '',
      address: '',
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.name = '';
      this.phone = '';
      this.address = '';
    },
    onSubmit(val) {
      this.showFields = true;
      this.$nextTick(() => {
        this.show = false;
      });
    },
    onConfirm() {
      this.$emit('submit', {
        name: this.name,
        phone: this.phone,
        address: this.address,
        num: this.info.can_receive_num,
      });
      this.close();
      this.showFields = false;
    },
    kefu() {
      this.$utils.kefuDialog(this.$refs.dialog);
    },
  },
};
</script>

<style lang="less" scoped>
.form-popup {
  width: 315px;
  background: #ffffff;
  border-radius: 10px;
  padding: 27px 16px 15px;
  box-sizing: border-box;
  text-align: center;

  .img {
    display: inline-block;
    width: 70px;
    margin-bottom: 22px;
  }

  .title {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 21px;
  }

  .van-cell {
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 16px;
  }

  .van-button {
    border-radius: 10px;
  }
}
.confirm-popup {
  width: 315px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 0;
  .confirm-title {
    padding-bottom: 17px;
    text-align: center;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .confirm-content {
    padding: 30px 17px 0;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .confirm-item {
      display: flex;

      .confirm-label {
        text-align: left;
        flex-shrink: 0;
        width: 100px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
      .confirm-value {
        text-align: left;
        flex-grow: 1;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 14px;
        word-break: break-all;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .confirm-btn {
      margin-top: 10px;
    }
    .kefu {
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      font-size: 16px;
      color: #ff8e38;
    }
  }
}
</style>
