var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { position: "bottom" },
      on: { "click-overlay": _vm.onCancel },
      model: {
        value: _vm.showFlag,
        callback: function ($$v) {
          _vm.showFlag = $$v
        },
        expression: "showFlag",
      },
    },
    [
      _vm.pickerType === "time"
        ? _c("van-datetime-picker", {
            attrs: {
              title: "选择时间",
              filter: _vm.filter,
              type: _vm.pickerType,
              "swipe-duration": 100,
              "min-hour": 0,
              "max-hour": 23,
            },
            on: { confirm: _vm.onConfirm, cancel: _vm.onCancel },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          })
        : _c("van-datetime-picker", {
            attrs: {
              title: "选择时间",
              filter: _vm.filter,
              type: _vm.pickerType,
              "swipe-duration": 100,
              "min-date": _vm.minDate,
              "max-date": _vm.maxDate,
            },
            on: { confirm: _vm.onConfirm, cancel: _vm.onCancel },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }