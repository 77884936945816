var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "god-list" },
    [
      _c("div", { staticClass: "god-box padding-44" }, [
        _c("div", { staticClass: "btn-header f fc fbt" }, [
          _c("img", {
            staticClass: "back-icon",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/e1de3948873ac8-48x48.png",
            },
            on: { click: _vm.back },
          }),
          _c(
            "div",
            { staticClass: "btns-box f fc fvc frd" },
            _vm._l(_vm.calcFactionList, function (item, idx) {
              return _c("div", { key: idx, staticClass: "f fv fvc" }, [
                _vm.curFaction === item.faction &&
                _vm.calcFactionList.length > 1
                  ? _c("img", {
                      class: `${
                        [4, 5].includes(item.faction) ? "big-btn" : ""
                      } faction-btn`,
                      attrs: { src: item.active },
                    })
                  : _c("img", {
                      class: `${
                        [4, 5].includes(item.faction) ? "big-btn" : ""
                      } faction-btn`,
                      attrs: { src: item.default },
                      on: {
                        click: function ($event) {
                          return _vm.changeFaction(item.faction)
                        },
                      },
                    }),
                _vm.calcFactionList.length > 1
                  ? _c("div", {
                      class: `line ${
                        _vm.curFaction === item.faction ? "" : "transparent"
                      }`,
                    })
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
        _vm.currentList.length > 0
          ? _c(
              "div",
              { staticClass: "list-body" },
              [
                _vm._l(_vm.currentList, function (group, index) {
                  return _c("div", { key: index, staticClass: "group-item" }, [
                    _c("img", {
                      staticClass: "group-bg",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/3dbd9693f78dc0-750x518.png",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "f fc" },
                      _vm._l(group, function (item, idx) {
                        return _c(
                          "div",
                          { key: idx, staticClass: "body-item f fv fvc" },
                          [
                            _c("img", {
                              directives: [
                                {
                                  name: "lazy",
                                  rawName: "v-lazy",
                                  value: item.main_image,
                                  expression: "item.main_image",
                                },
                              ],
                              staticClass: "god-item-img",
                              on: {
                                click: function ($event) {
                                  return _vm.openDialog(item)
                                },
                              },
                            }),
                            _c("div", { staticClass: "name-box" }, [
                              _c("img", {
                                staticClass: "name-img",
                                attrs: { src: item.name_icon },
                              }),
                            ]),
                            _c("div", { staticClass: "option-area" }, [
                              _c("div", { staticClass: "moral" }, [
                                _vm._v(_vm._s(item.moral)),
                              ]),
                              item.is_invited === 1 &&
                              !["init_select"].includes(_vm.from)
                                ? _c("img", {
                                    staticClass: "set-btn",
                                    attrs: {
                                      src: "https://img-fe.tengzhihh.com/image/bce95fa8b22d77-162x48.png",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDialog(item)
                                      },
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "set-btn",
                                    attrs: {
                                      src: "https://img-fe.tengzhihh.com/image/a820b542e30df5-162x48.png",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDialog(item)
                                      },
                                    },
                                  }),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                }),
                _c("img", {
                  staticClass: "shadow",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/57e9ae954ed5e2-1125x220.png",
                  },
                }),
                _vm.showConfirmBtn
                  ? _c(
                      "div",
                      {
                        staticClass: "confirm-btn",
                        on: { click: _vm.confirm },
                      },
                      [_vm._v("完成")]
                    )
                  : _vm._e(),
              ],
              2
            )
          : _vm.currentList.length === 0 && _vm.curFaction === 4
          ? _c("div", { staticClass: "empty-box f fv fvc" }, [
              _c("img", {
                staticClass: "empty-icon",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/caa44b5eb41071-276x276.png",
                },
              }),
              _c("img", {
                staticClass: "empty-tip",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/b6e1887a4dd9aa-212x32.png",
                },
              }),
              _c("img", {
                staticClass: "empty-btn",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/12379e396b172a-162x48.png",
                },
                on: { click: _vm.goGoods },
              }),
            ])
          : _vm._e(),
      ]),
      _c(
        "van-popup",
        {
          staticClass: "popup",
          attrs: { position: "bottom" },
          model: {
            value: _vm.dialogFlag,
            callback: function ($$v) {
              _vm.dialogFlag = $$v
            },
            expression: "dialogFlag",
          },
        },
        [
          _c("div", { staticClass: "f fv fc popup-box" }, [
            _c("div", { staticClass: "content f fv fc" }, [
              _c("div", { staticClass: "god-img-box" }, [
                _c("img", {
                  staticClass: "bg",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/81925064b6126d-276x276.png",
                  },
                }),
                _c("img", {
                  staticClass: "god-item-img",
                  attrs: { src: _vm.settingGodDetail.main_image },
                }),
              ]),
              _c("div", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.settingGodDetail.name)),
              ]),
              _vm.settingGodDetail.unique_number
                ? _c("div", { staticClass: "id-box" }, [
                    _c("img", {
                      staticClass: "id-bg-img",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/11e1a446efd149-392x64.png",
                      },
                    }),
                    _c("div", { staticClass: "id" }, [
                      _vm._v(
                        "神像编号（ID：" +
                          _vm._s(_vm.settingGodDetail.unique_number) +
                          "）"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  class: `confirm-btn ${
                    _vm.settingGodDetail.is_invited &&
                    !["init_select"].includes(_vm.from)
                      ? "cancel-btn"
                      : ""
                  }`,
                  on: { click: _vm.inviteGod },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.settingGodDetail.is_invited &&
                          !["init_select"].includes(_vm.from)
                          ? "已供奉"
                          : "请神上台"
                      ) +
                      " "
                  ),
                ]
              ),
              _c("div", { staticClass: "desc" }, [
                _c("img", {
                  staticClass: "title-icon",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/012a671b1d07c4-154x42.png",
                  },
                }),
                _c("div", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.settingGodDetail.introduce &&
                        _vm.settingGodDetail.introduce.replace(/\n/g, "<br />")
                    ),
                  },
                }),
              ]),
              _c("div", { staticClass: "desc" }, [
                _c("img", {
                  staticClass: "title-icon",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/bf2a6a33efca80-154x42.png",
                  },
                }),
                _c("div", { staticClass: "step f fc" }, [
                  _c("div", { staticClass: "step-item f fv fvc" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/59155aa1d3f719-96x96.png",
                      },
                    }),
                    _c("div", [_vm._v("请神")]),
                  ]),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/86416c39ef0a6b-16x20.png",
                    },
                  }),
                  _c("div", { staticClass: "step-item f fv fvc" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/b968c644706250-96x96.png",
                      },
                    }),
                    _c("div", [_vm._v("供奉")]),
                  ]),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/86416c39ef0a6b-16x20.png",
                    },
                  }),
                  _c("div", { staticClass: "step-item f fv fvc" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/6c82d11a4810fc-96x96.png",
                      },
                    }),
                    _c("div", [_vm._v("敬香")]),
                  ]),
                  _c("img", {
                    staticClass: "arrow",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/86416c39ef0a6b-16x20.png",
                    },
                  }),
                  _c("div", { staticClass: "step-item f fv fvc" }, [
                    _c("img", {
                      staticClass: "step-icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/6e78ac261f06b9-96x96.png",
                      },
                    }),
                    _c("div", [_vm._v("获得功德")]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c("img", {
        staticClass: "bg",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/1e033c3e04ba47-750x1558.png",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }