<template>
  <div class="page-box">
    <img src="https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png" class="bg" />
    <div v-if="list.length === 0" class="f fv fc empty-box">
      <GoBack />
      <div class="add-btn click-hover f fc fvc" @click="uploadMotto">
        <img src="https://img-fe.tengzhihh.com/image/474ebc18b71bbb-228x228.png" />
      </div>
      <div @click="uploadMotto" class="tip">上传我的家风家训</div>
      <div class="volume-tip">
        {{ volumeStr }}
      </div>
    </div>
    <div class="list-box" v-else>
      <div class="volume-tip">
        {{ volumeStr }}
      </div>
      <div class="f fc fbt">
        <GoBack type="str" />
        <van-button @click="handleBtn" class="add-btn">{{ editing ? '删除' : '上传新的家风家训' }}</van-button>
        <div class="f fc btn-header">
          <div @click="handleEditing">{{ editing ? '取消' : '管理' }}</div>
        </div>
      </div>
      <div class="f fc flex-wrap fbt">
        <div @click="viewDetail(item)" v-for="item in list" :key="item.id" class="item-box">
          <div class="check-box" v-if="editing">
            <img
              src="https://img-fe.tengzhihh.com/image/d68fc0abc797cf-18x18.png"
              v-if="hasSelected(item.id)"
              class="checked"
            />
            <img src="https://img-fe.tengzhihh.com/image/625bee427aecd9-18x18.png" v-else class="un-check" />
          </div>
          <div class="cover-box f fc fvc">
            <img :src="calcIcon(item).url" class="icon" />
            <img :src="calcCover(item)" v-if="calcCover(item)" class="cover" />
            <div v-else class="cover"></div>
          </div>
          <!-- <div :class="`play-status ${item.is_played?'yes':'no'}`">
            {{item.is_played===1?'已播放':'未播放'}}
          </div> -->
          <div class="uploader f fc">
            <img :src="$tools.compressImg(item.user_avatar, 30)" class="avatar" />
            <div class="info-box">
              <!-- <p class="name">{{item.user_name}}</p> -->
              <p class="name">我</p>
              <p class="time">{{ formatTime(item.created_at) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import GoBack from '@/components/GoBack/GoBack.vue';
import tools from '@/utils/tools';
export default {
  components: { GoBack },
  data() {
    return {
      tools,
      serialId: '',
      list: [],
      editing: false,
      volumeStr: '',
      currentVolume: 0,
      maxVolume: 0,
      subVolume: 0,
      selectedArr: [],
    };
  },
  created() {
    this.serialId = this.$route?.query?.serial_id;
  },
  mounted() {
    this.getUserMotto();
    this.machineVolume();
  },
  methods: {
    handleEditing() {
      this.editing = !this.editing;
      !this.editing && (this.selectedArr = []);
    },
    formatTime(s) {
      return /\d{4}-\d{1,2}-\d{1,2} \d{1,2}:\d{1,2}/g.exec(s)[0];
    },
    hasSelected(id) {
      return this.selectedArr.includes(id);
    },
    calcIcon(item) {
      let tmp = {
        url: '',
      };
      if (item.type === 1) {
        tmp = { url: 'https://img-fe.tengzhihh.com/image/7c5aa241814d02-80x80.png' };
      } else {
        tmp = { url: 'https://img-fe.tengzhihh.com/image/23ef9a09db9e63-80x80.png' };
      }
      return tmp;
    },
    machineVolume() {
      this.$http
        .get(apiPath.machineVolume, {
          serial_id: this.serialId,
        })
        .then(res => {
          this.currentVolume = res.data.current_volume;
          this.maxVolume = res.data.max_volume;
          this.subVolume = this.maxVolume - this.currentVolume >= 0 ? this.maxVolume - this.currentVolume : 0;
          if (this.subVolume === 0) {
            this.volumeStr = '神台机云存储空间已存满，请先清理神台机云存储空间';
          } else {
            this.volumeStr = `云存储空间已使用${this.formatVolume(this.currentVolume)}GB/${this.formatVolume(
              this.maxVolume
            )}GB，还剩下${this.formatVolume(this.subVolume)}GB`;
          }
        });
    },
    formatVolume(v) {
      return (v / 1024 / 1024).toFixed(2);
    },
    calcCover(item) {
      return this.$tools.compressImg(item.cover_url ? item.cover_url : item.wap_cover_url, 140);
    },

    delMottos() {
      if (this.selectedArr.length === 0) {
        return this.$toast('请先选择删除内容');
      }
      this.$dialog({
        message: `确认删除已选内容吗`,
        title: '提示',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const formData = new FormData();
        formData.append('serial_id', this.serialId);
        formData.append('ids', this.selectedArr.join(','));
        this.$http.formPost(apiPath.delMottos, formData).then(() => {
          const tmpList = this.list.filter(i => this.selectedArr.includes(i.id));
          if (tmpList.find(item => item.type === 1)) {
            this.$track('delete_video_success');
          }
          if (tmpList.find(item => item.type === 2)) {
            this.$track('delete_audio_success');
          }

          this.$toast('删除成功');
          this.selectedArr = [];
          this.getUserMotto();
          this.machineVolume();
        });
      });
    },
    handleBtn() {
      if (this.editing) {
        this.delMottos();
      } else {
        this.add();
      }
    },
    add() {
      if (this.subVolume <= 0) {
        return this.$toast('神台机云存储空间已存满，请先清理神台机云存储空间');
      }
      this.$router.replace(`/motto/uploadIndex?serial_id=${this.serialId}`);
    },
    viewDetail(item) {
      if (!this.editing) {
        this.$track(item.type === 1 ? 'play_video_success' : 'play_audio_success');
        this.$router.push(
          `/motto/detail?motto_id=${item.id}&motto_type=${item.type === 1 ? 'video' : 'audio'}&serial_id=${
            this.serialId
          }`
        );
      } else {
        const index = this.selectedArr.findIndex(i => i === item.id);
        if (index !== -1) {
          this.selectedArr.splice(index, 1);
        } else {
          this.selectedArr.push(item.id);
        }
      }
    },
    getUserMotto() {
      this.$http
        .get(apiPath.mottoList, {
          serial_id: this.serialId,
          size: 1000,
        })
        .then(res => {
          this.list = res.data.list;
        });
    },
    uploadMotto() {
      this.$router.replace(`/motto/uploadIndex?serial_id=${this.serialId}`);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  width: 100%;
  // padding: 0 20px;
  box-sizing: border-box;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }

  .empty-box {
    .home-btn {
      width: 40px;
      height: 40px;
      position: fixed;
      top: 20px;
      left: 20px;
    }
    .add-btn {
      z-index: 1;
      width: 114px;
      height: 114px;
      border-radius: 15px;
      margin: 105px 0 14px;
      img {
        width: 114px;
        height: 114px;
      }
    }
    .tip {
      z-index: 1;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
    }
    .volume-tip {
      width: 335px;
      font-size: 14px;
      color: #c23333;
      text-align: center;
      position: fixed;
      bottom: 20px;
    }
  }
  .list-box {
    padding: 0 10px;

    .home-btn {
      width: 30px;
      height: 30px;
    }
    max-height: calc(var(--vh) * 100 - 100px);
    overflow-y: scroll;
    .btn-header {
      width: 100%;
      font-size: 14px;
      color: #9e511c;
      padding: 18px 0;
      justify-content: flex-end;
    }

    .item-box {
      position: relative;
      width: 49%;
      margin-bottom: 10px;

      .check-box {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 4px;
        top: 4px;
        z-index: 3;

        img {
          width: 20px;
          height: 20px;
        }
      }
      .cover-box {
        box-shadow: 0px 1px 8px 1px rgba(186, 169, 153, 0.88);
        width: 100%;
        height: 102px;
        margin-bottom: 10px;
        border-radius: 5px;
        overflow: hidden;
        .icon {
          width: 40px;
          z-index: 12;
        }

        .cover {
          width: 100%;
          height: 102px;
          border-radius: 5px;
          position: absolute;
          left: 0;
          top: 0;
          object-fit: cover;
        }
      }
      .play-status {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 5px 0 10px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }
      .no {
        width: 44px;
        height: 17px;
        background: #de3900;
        color: #fff2da;
      }
      .yes {
        width: 44px;
        height: 17px;
        background: #ffce94;
        color: #713106;
      }
      .uploader {
        width: 100%;
        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 8px;
        }
        .name {
          font-size: 14px;

          color: #7b5439;
        }
        .time {
          font-size: 12px;
          color: #7b5439;
          margin-top: 4px;
        }
      }
    }

    .volume-tip {
      width: 350px;
      font-size: 13px;
      color: #9d2100;
      text-align: center;
      position: fixed;
      bottom: 78px;
    }
    .add-btn {
      width: 300px;
      height: 44px;
      background: #f87a1b;
      border-radius: 10px;
      font-size: 15px;
      font-weight: bold;
      color: #fff4e4;
      border: none;
      position: fixed;
      bottom: 24px;
      left: 50%;
      margin-left: -150px;
    }
  }
}
</style>
