import { request } from '@/utils/request';

export function submitWoodenfish(data) {
  return request({
    url: '/wooden-fish/numbers',
    method: 'post',
    data,
  });
}
export function getWoodenfish(params) {
  return request({
    url: '/wooden-fish/index',
    method: 'get',
    params,
  });
}
export function getWoodenfishV2(params) {
  return request({
    url: '/wooden-fish/index-v2',
    method: 'get',
    params,
  });
}
export function getWoodfish(params) {
  return request({
    url: '/wooden-fish/edit',
    method: 'get',
    params,
  });
}
export function editWoodenfish(data) {
  return request({
    url: '/wooden-fish/edit',
    method: 'post',
    data,
  });
}
export function getLesson(params) {
  return request({
    url: '/curriculum/user',
    method: 'get',
    params,
  });
}
export function getMusic(params) {
  return request({
    url: '/machine/music',
    method: 'get',
    params,
  });
}
