<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>

    <van-popup v-model="show">
      <div class="f fv fc dialog-box">
        <div class="title">注销账号</div>

        <van-field
          v-model="code"
          class="input-item f fc"
          center
          max-length="6"
          clearable
          placeholder="请输入短信验证码"
        >
          <template #button>
            <div class="tip" @click="getCode">{{ codeSentStatus ? calcTime : '发送验证码' }}</div>
          </template>
        </van-field>
        <div class="f fc frd btn-box">
          <div @click="closeDialog" class="btn cancel">取消</div>
          <div @click="confirmClick" class="btn">确定</div>
        </div>
      </div>
    </van-popup>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import apiPath from '@/utils/apiPath';
export default {
  components: { CellGroup, Dialog },
  data() {
    return {
      settingList: [
        {
          title: '我的收货地址',
          isLink: true,
          path: '/my/delivery-address',
        },
        {
          title: '消息设置',
          isLink: true,
          path: '/msg/settings',
        },
        {
          title: '关于我们',
          isLink: true,
          path: '/my/about',
        },
        {
          title: '客服中心',
          isLink: true,
          cb: () => {
            this.$utils.kefuDialog(this.$refs.dialog);
          },
        },
        {
          title: '用户协议',
          isLink: true,
          path: '/my/userAgreement',
        },
        {
          title: '注销账号',
          isLink: true,
          cb: () => {
            this.showConfirm();
          },
        },
      ],
      show: false,
      code: '',
      codeSentStatus: false,
      interval: null,
      calcTime: 60,
    };
  },
  watch: {
    calcTime: function (n) {
      if (n <= 0) {
        this.resetCodeSent();
      }
    },
    show: function (n) {
      !n && (this.code = '');
    },
  },

  methods: {
    closeDialog() {
      this.show = false;
      this.resetCodeSent();
    },
    confirmClick() {
      if (!this.code) {
        return this.$toast('请先输入验证码');
      }
      this.delUser();
    },

    delUser() {
      const formData = new FormData();
      formData.append('code', this.code);
      this.$http.formPost(apiPath.delUser, formData).then(() => {
        this.$toast('注销成功');
        this.$utils.logout(() => {
          this.$toast('退出登录成功');
        });
      });
    },
    resetCodeSent() {
      clearInterval(this.interval);
      this.codeSentStatus = false;
      this.calcTime = 60;
    },
    getCode() {
      if (this.codeSentStatus) {
        return false;
      }

      this.$http.formPost(apiPath.getCode).then(() => {
        this.codeSentStatus = true;
        this.interval = setInterval(() => {
          this.calcTime--;
        }, 1000);
      });
    },
    showConfirm() {
      this.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
  .dialog-box {
    padding: 25px 0;
    width: 300px;
    border-radius: 15px;
    .title {
      font-size: 15px;
      font-weight: bold;
      color: #2e2d2d;
      margin-bottom: 25px;
    }
    .input-item {
      width: 250px;
      height: 37px;
      border: 1px solid #999;
    }
    .btn-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 22px 0 0;
      .btn {
        width: 120px;
        height: 43px;
        background: #f87a1b;
        border-radius: 15px;
        line-height: 43px;
        text-align: center;
        font-size: 15px;
        color: #ffeac9;
      }
      .cancel {
        background: #fff;
        border: 1px solid #f87a1b;
        color: #f87a1b;
      }
    }
  }
}
</style>
