<template>
  <div v-if="mottoType === 'video' && detail" class="motto-box">
    <video
      v-if="detail.status == 1"
      id="video"
      class="player video"
      playsinline
      autoplay
      controls
      x5-video-player-type="h5"
      style="object-fit: contain; background: #000"
      :src="detail.url"
    ></video>

    <div class="content-box f fv" v-if="detail.status == 1">
      <div class="f fc">
        <img :src="detail.user_avatar" class="avatar" />
        <div class="name">{{ detail.user_name }}</div>
      </div>
      <div class="content">{{ detail.content }}</div>
    </div>

    <van-overlay :show="detail.status != 1">
      <div class="overlay-box f fc fvc">
        <div class="content f fc fvc">
          {{ detail.status_message }}
        </div>
      </div>
    </van-overlay>
  </div>
  <div v-else-if="mottoType === 'audio' && detail" class="motto-box">
    <van-swipe class="swipe-box" :autoplay="5000" indicator-color="white">
      <van-swipe-item v-for="item in detail.images" :key="item.id">
        <img
          v-lazy="$tools.compressImg(item.image, 375)"
          style="object-fit: contain; background: #fff"
          @click="preview(item)"
        />
      </van-swipe-item>
    </van-swipe>

    <div class="content-box f fv" v-if="detail.status == 1">
      <div class="f fc">
        <img :src="detail.user_avatar" class="avatar" />
        <div class="name">{{ detail.user_name }}</div>
      </div>
      <div class="content">{{ detail.content }}</div>
    </div>

    <img
      src="https://img-fe.tengzhihh.com/image/638bac7dbde2e9-179x140.png"
      @click="playAudio"
      v-if="detail.status === 1"
      class="play-btn"
    />

    <div id="audio-play-box"></div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      mottoType: 'video',
      mottoId: '',
      serialId: '',
      detail: {
        images: [],
        status: 1,
      },
    };
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.mottoType = this.$route.query.motto_type;
    this.mottoId = this.$route.query.motto_id;
    this.getDetail();
  },
  beforeDestroy() {
    document.getElementById('audio-play-box') && document.getElementById('audio-play-box').removeChild();
  },
  methods: {
    playAudio() {
      var audio = document.createElement('audio');
      audio.controls = true;
      audio.setAttribute('style', 'width:100%');
      audio.setAttribute('id', 'audio-play-item');

      document.getElementById('audio-play-box').appendChild(audio);
      audio.src = this.detail.url;
      audio.play();
      audio.onended = function () {
        console.log('播放结束');
        document.getElementById('audio-play-box') && document.getElementById('audio-play-box').removeChild(audio);
      };
    },
    getDetail() {
      this.$http
        .get(`${apiPath.mottoDetail}/${this.mottoId}`, {
          serial_id: this.serialId,
        })
        .then((res) => {
          this.detail = res.data;
          this.mottoType = res.data.type === 1 ? 'video' : 'audio';
        });
    },
    preview(item) {
      ImagePreview([item.image || item.url]);
    },
  },
};
</script>

<style lang="less" scoped>
.motto-box {
  width: 100%;
  height: calc(var(--vh) * 100);
  background-color: #ebebeb;
}
.player.video {
  width: 100%;
  height: calc(var(--vh) * 100);
}
.play-btn {
  width: 42px;
  height: 33px;
  position: fixed;
  top: 270px;
  left: 50%;
  margin-left: -16.5px;
}
#audio-play-box {
  display: none;
  position: fixed;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  .audio-play-item {
    display: none;
  }
}
.content-box {
  position: fixed;
  left: 0;
  bottom: 100px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  text-align: justify;
  .avatar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .name {
    font-size: 11px;
    color: #ffffff;
    font-weight: bold;
  }
  .content {
    margin-top: 12px;
    font-size: 10px;
    font-weight: bold;
    color: #ffffff;
  }
}

.swipe-box {
  .van-swipe-item {
    width: 100%;
    height: calc(var(--vh) * 100);
    img {
      width: 100%;
      height: calc(var(--vh) * 100);
    }
  }
}
.overlay-box {
  width: 100vw;
  height: calc(var(--vh) * 100);
  .content {
    width: 232px;
    height: 142px;
    background: #fff;
    border-radius: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #2e2d2d;
  }
}
</style>
