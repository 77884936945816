<template>
  <div class="empty">
    <img src="https://img-fe.tengzhihh.com/image/49134cd0377920-146x146.png" alt="" srcset="" />
    <p>{{ label }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '您暂无订单哦~',
    },
  },
};
</script>

<style lang="less" scoped>
.empty {
  padding-top: 38px;
  img {
    width: 146px;
    height: 146px;
    display: block;
    margin: 0 auto;
  }
  p {
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
