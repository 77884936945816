var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("cell-group", { attrs: { list: _vm.settingList } }),
      _c("van-cell", {
        attrs: { title: "背景灯开关", is_link: "", center: "" },
        scopedSlots: _vm._u([
          {
            key: "right-icon",
            fn: function () {
              return [
                _c("van-switch", {
                  attrs: {
                    "active-color": "#f87a1b",
                    "inactive-color": "#ffeac9",
                    size: "20px",
                    value: _vm.generalConfig.backlight,
                  },
                  on: { input: _vm.backlightChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("van-cell", {
        attrs: { title: "宗教节日", is_link: "", center: "" },
        scopedSlots: _vm._u([
          {
            key: "right-icon",
            fn: function () {
              return [
                _c("van-switch", {
                  attrs: {
                    "active-color": "#f87a1b",
                    "inactive-color": "#ffeac9",
                    size: "20px",
                    value: _vm.generalConfig.festival_tab,
                  },
                  on: { input: _vm.festivalTabChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("selector", {
        attrs: {
          title: _vm.selectorTitle,
          show: _vm.selectorShow,
          defaultIndex: _vm.findIndex(_vm.generalConfig.screen_time),
          columns: _vm.selectorColumns,
        },
        on: { onConfirm: _vm.onConfirm, onCancel: _vm.onCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }