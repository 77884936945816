<template>
  <div class="info-box">
    <div class="my-header f fc fbt" @click="chooseImg">
      <div class="label">头像</div>
      <div class="f fc fbt">
        <div class="my-avatar f fc fvc">
          <img :src="userInfo.avatar" />
        </div>

        <div>
          <van-icon name="arrow" class="right-arrow-icon" />
        </div>
      </div>
    </div>

    <div class="btn f fc fvc">
      <van-button @click="logout" class="default-btn" size="small" :loading="loading">退出登录</van-button>
    </div>
    <cell-group :list="settingList"></cell-group>
    <img-picker ref="comImgPicker" from="1" @emitImgUrl="emitImgUrl"></img-picker>
    <van-dialog v-model="nameDialog" title="修改名称" show-cancel-button :beforeClose="beforeNameDialogClose">
      <van-field v-model="newName" maxlength="10" clearable placeholder="请输入新的名称" />
    </van-dialog>
  </div>
</template>

<script>
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import ImgPicker from '@/components/ImgPicker/ImgPicker.vue';
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
export default {
  components: { CellGroup, ImgPicker },
  data() {
    return {
      nameDialog: false,
      newName: '',
      loading: false,
    };
  },
  computed: {
    ...mapState(['userInfo']),
    settingList() {
      return [
        {
          title: '昵称',
          isLink: true,
          value: this.userInfo.name,
          cb: () => {
            this.nameDialog = true;
          },
        },
        {
          title: '手机号码',
          isLink: true,
          value: this.userInfo.phone,
          path: '/my/changePhone',
        },
      ];
    },
  },
  mounted() {
    this.$utils.checkToken(() => {
      this.$initWXSDK();
    }, this.$route.path);
  },
  methods: {
    beforeNameDialogClose(action, done) {
      if (action === 'confirm') {
        if (!this.newName) {
          this.$toast('请输入名称');
          done(false);
          return false;
        }
        if (!this.$tools.inputCheck(this.newName)) {
          return this.$toast('名字只能输入汉字、字母、数字');
        }
        this.updateInfo(2, this.newName);
        done();

        this.newName = '';
      } else {
        done();
      }
    },
    emitImgUrl(e) {
      this.avatarUrl = e.url;
      this.updateInfo(1, e.url);
    },
    chooseImg() {
      this.$refs.comImgPicker.uploadImg();
    },
    updateInfo(type, content) {
      const formData = new FormData();
      if (type === 1) {
        formData.append('avatar', content);
      } else {
        formData.append('name', content);
      }
      this.$http.formPost(apiPath.userInfo, formData).then(() => {
        this.$utils.getUserInfo();
        this.$toast('修改成功');
      });
    },
    logout() {
      this.loading = true;
      this.$http.post(apiPath.logout).then(res => {
        this.$utils.logout(() => {
          this.loading = false;
          this.$toast('退出登录成功');
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info-box {
  height: calc(var(--vh) * 100);
  .btn {
    width: 100%;
    position: fixed;
    bottom: 40px;
  }
  .my-header {
    padding: 20px;
    .my-avatar {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 18px;
      font-weight: bold;
    }
    .label {
      color: #323233;
      font-size: 14px;
      font-weight: normal;
      line-height: 24px;
    }
  }
}
</style>
