import { request } from '@/utils/request';

export function getUserDeceasedList(params) {
  return request({
    url: '/deceased/list',
    method: 'get',
    params,
  });
}
export function getDeceasedList(params) {
  return request({
    url: '/machine/user/gods',
    method: 'get',
    params,
  });
}
export function getDeceasedDetail(params) {
  return request({
    url: '/machine/deceased',
    method: 'get',
    params,
  });
}
export function setConfigGeneral(data) {
  return request({
    url: '/config/general',
    method: 'post',
    data,
  });
}
