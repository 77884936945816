var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("van-cell", {
        attrs: { title: "自动供奉", is_link: "", center: "" },
        scopedSlots: _vm._u([
          {
            key: "right-icon",
            fn: function () {
              return [
                _c("van-switch", {
                  attrs: {
                    "active-color": "#f87a1b",
                    "inactive-color": "#ffeac9",
                    size: "20px",
                    value: _vm.generalConfig.auto_offering.status
                      ? true
                      : false,
                  },
                  on: { input: _vm.gongfengChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("van-cell", {
        attrs: { is_link: "", center: "" },
        on: { click: _vm.handleTimeclick },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.generalConfig.auto_offering.offering_time &&
                        _vm.generalConfig.auto_offering.offering_time >= 0
                        ? _vm.generalConfig.auto_offering.offering_time
                        : 0
                    ) +
                    "点 "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "right-icon",
            fn: function () {
              return [
                _c(
                  "van-button",
                  { attrs: { type: "default", size: "mini", round: "" } },
                  [_vm._v("设置时间")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.timePicker,
            callback: function ($$v) {
              _vm.timePicker = $$v
            },
            expression: "timePicker",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              title: "选择时间",
              columns: _vm.columns,
              "default-index": _vm.generalConfig.auto_offering.offering_time
                ? _vm.generalConfig.auto_offering.offering_time - 1
                : 0,
            },
            on: { cancel: _vm.onCancel, confirm: _vm.onConfirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }