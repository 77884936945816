<template>
  <div class="addressForm">
    <van-form ref="form" :show-error-message="false" :show-error="false" @submit="onSubmit" @failed="onFailed">
      <van-field
        center
        :border="false"
        v-model="form.user_name"
        name="user_name"
        label="收货人"
        placeholder="名字"
        :maxlength="10"
        :rules="[{ required: true, message: '请填写收货人' }]"
      >
      </van-field>
      <van-field
        center
        :border="false"
        type="number"
        v-model="form.mobile"
        name="mobile"
        label="手机号码"
        placeholder="手机号码"
        maxlength="11"
        :rules="[
          { required: true, message: '请输入手机号码' },
          { pattern: /^(?:(?:\+|00)86)?1\d{10}$/, message: '请输入正确的11位手机号' },
        ]"
      >
        <template #button>
          <div class="mr-13">+86 <van-icon name="arrow" class="right-arrow" /></div>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        center
        rows="1"
        autosize
        type="textarea"
        :border="false"
        v-model="form.region"
        class="over-h"
        name="region"
        label="所在地区"
        placeholder="省、市、区、街道"
        :right-icon="'https://img-fe.tengzhihh.com/image/016c56c1cbff8f-36x36.png'"
        :rules="[{ required: true, message: '请选择所在地区' }]"
        @click="showRegion = true"
      >
      </van-field>
      <van-field
        center
        :border="false"
        type="textarea"
        rows="2"
        autosize
        v-model="form.address"
        name="address"
        label="详细地址"
        placeholder="小区楼栋/乡村名称"
        :rules="[{ required: true, message: '请填写详细地址' }]"
      />
      <van-checkbox-group v-model="form.is_default">
        <van-cell-group>
          <van-cell clickable title="设为默认收货地址">
            <template #right-icon>
              <van-checkbox @click="isDefaultClick" label-disabled name="1" ref="checkboxes" checked-color="#F87A1B" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
      <van-popup v-model="showRegion" position="bottom">
        <van-picker
          show-toolbar
          item-height="34"
          visible-item-count="8"
          title="请选择地区"
          :columns="regionList"
          :value-key="'label'"
          @confirm="confirmRegion"
          @cancel="showRegion = false"
        />
      </van-popup>
    </van-form>
    <div class="submit">
      <van-button block color="#F87A1B" size="small" native-type="submit" v-throttle="submit">提交</van-button>
    </div>
  </div>
</template>

<script>
import { getDefault, addAddress, getAddress, editAddress } from '@/api/address.js';

export default {
  data() {
    return {
      form: {
        user_name: '',
        mobile: '',
        region: '',
        country: '中国大陆',
        address: '',
        is_default: [],
      },
      showRegion: false,
      regionList: null,
      id: '',
      defaultId: '',
      type: 'create',
    };
  },
  async created() {
    const CHINA_MAP_JSON = await this.$tools.importJSON(
      'https://img-fe.tengzhihh.com/file/e9862e73-029d-4d19-9ecb-b373e1306ab4.json'
    );
    this.regionList = Object.freeze(CHINA_MAP_JSON);
    if (Reflect.ownKeys(this.$route.params).length !== 0) {
      this.id = this.$route.params.id;
      this.type = 'edit';
      this.getDetail();
    }
    this.getAddress();
  },
  methods: {
    async getAddress() {
      const res = await getDefault();
      if (res.data.id) {
        this.defaultId = res.data.id;
        return;
      }
      this.form.is_default = ['1'];
    },
    isDefaultClick() {
      !this.defaultId && (this.form.is_default = ['1']);
    },
    async getDetail() {
      const { data } = await getAddress({ id: this.id });
      const { username, is_default, province, city, district, street } = data.info;
      this.form = Object.assign(data.info, {
        user_name: username,
        is_default: is_default ? ['1'] : [],
        region: [province, city, district, street].join(' '),
      });
    },
    async onSubmit(values) {
      const region = this.form.region.split(' ');
      const params = Object.assign(
        {
          province: region[0],
          city: region[1],
          district: region[2],
          street: region[3],
          country: '中国大陆',
          is_default: this.form.is_default.length ? 1 : 0,
        },
        values
      );
      this.type === 'edit' ? await editAddress(Object.assign({ id: this.id }, params)) : await addAddress(params);
      this.$router.go(-1);
    },
    onFailed(errorInfo) {
      if (errorInfo.errors?.length) {
        this.$toast(errorInfo.errors[0].message);
      }
    },
    confirmRegion(region) {
      this.form.region = region.join(' ');
      this.showRegion = false;
    },
    submit() {
      this.$refs.form.submit();
    },
  },
};
</script>

<style lang="less" scoped>
.addressForm {
  position: relative;
  min-height: 100vh;
  padding-bottom: 60px;
  box-sizing: border-box;
  .over-h {
    /deep/textarea {
      overflow: hidden;
    }
  }
  .mr-13 {
    margin-right: 13px;
  }
  /deep/.van-cell__title {
    margin-right: 8px;
    width: 64px;
  }
  .van-cell {
    padding: 8px 15px;
  }
  .van-popup {
    border-radius: 15px 15px 0 0;
    .van-picker-column {
      font-size: 14px;
    }
  }
  .submit {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 20px;
    .van-button {
      border-radius: 10px;
    }
  }
  /deep/.van-field__body {
    background-color: #f1f1f1;
    border-radius: 10px;
    .van-field__control {
      padding: 13px;
    }
    .van-field__right-icon {
      margin-right: 13px;
      padding: 0;
      .van-icon {
        font-size: 18px;
      }
    }
    .van-field__button {
      padding: 0;
    }
  }
  .van-cell-group::after {
    border: 0 !important;
  }
  // .van-cell::after {
  //   border: 0;
  // }
  input::placeholder {
    color: rgba(0, 0, 0, 0.45);
  }
  textarea::placeholder {
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
