<template>
  <div class="page">
    <van-icon name="arrow-left" size="17" class="goback" @click="goback" />
    <div class="layout">
      <div class="mb28">
        <p class="label">敲击祈福文案</p>
        <div class="type f">
          <div
            :class="['button', title_type === value ? 'active' : '']"
            v-for="{ value, label } in buttonList"
            :key="value"
            @click="title_type = value"
          >
            {{ label }}
          </div>
        </div>
        <div class="f fvc" v-show="title_type === 1">
          <input
            type="text"
            maxlength="5"
            v-model="fish_title"
            class="fish_title"
            clear
            placeholder="请输入悬浮文字，例如：福报+1"
          />
        </div>
        <div>
          <p class="label">个性化设置</p>
          <div class="setting">
            <div class="f fbt">
              <span class="label-txt">修行语录</span>
              <van-switch v-model="is_quotation" active-color="#F87A1B" size="18px" />
            </div>
            <div class="tips-txt">{{ quotation_tips }}</div>
          </div>
        </div>
      </div>
    </div>
    <button class="submit" @click="submit">保存</button>
  </div>
</template>

<script>
import { getWoodfish, editWoodenfish } from '@/api/woodfish';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  data() {
    return {
      buttonList: [
        { value: 1, label: '自定义' },
        { value: 2, label: '天官赐福' },
      ],
      woodfishDetail: {},
      tips: '每次敲木鱼/念珠100下，功德值+1',
      fish_title: '',
      title_type: 1,
      is_quotation: true,
      quotation_tips: '当敲击数达到特定的数目时，会有一些修行感悟的语录出现。',
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await getWoodfish({
        ver: '1.1',
      });
      const { fish_title, tips, title_type, quotation } = data;
      this.is_quotation = Boolean(quotation.is_quotation);
      this.quotation_tips = quotation.quotation_tips;
      this.fish_title = fish_title;
      this.tips = tips;
      this.title_type = title_type;
    },
    async submit() {
      await editWoodenfish({
        fish_type: 1,
        title_type: this.title_type,
        fish_title: this.fish_title || '福报+1',
        is_quotation: Number(this.is_quotation),
        ver: '1.1',
      });
      // this.woodfishDetail.fish_type !== this.fish_type &&
      //   this.form.fish_type === 1 &&
      //   this.$track('H5_switch_nianzhu_success');
      // this.woodfishDetail.fish_type !== this.form.fish_type &&
      //   this.fish_type === 2 &&
      //   this.$track('H5_switch_muyu_success');
      // this.woodfishDetail.fish_title !== this.form.fish_title && this.$track('H5_muyu_setting_word_success');
      this.goback();
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: url('https://img-fe.tengzhihh.com/image/ef8796f34f539f-375x812.png') no-repeat center;
  background-size: cover;
  height: calc(var(--vh) * 100);
  .goback {
    position: absolute;
    top: 17px;
    left: 23px;
    color: #fff;
  }
  .layout {
    padding: 52px 15px 0;

    .fish_title {
      width: 345px;
      height: 40px;
      border: 0;
      background: transparent;
      color: rgba(255, 255, 255, 0.3);
      padding: 22px 16px;
      box-sizing: border-box;
      font-size: 16px;
      margin-bottom: 28px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    input::placeholder {
      color: rgba(255, 255, 255, 0.3);
    }
    .type {
      width: 345px;
      height: 52px;
      box-sizing: border-box;
      padding: 4px;
      background: rgba(255, 255, 255, 0.2);
      font-size: 16px;
      border-radius: 5px;
      margin-bottom: 10px;
      .button {
        width: 172px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: rgba(255, 255, 255, 0.3);
      }
      .active {
        border-radius: 7px;
        background: #f87a1b;
        color: #fff;
      }
    }
    .mb28 {
      margin-bottom: 28px;
    }
    .label {
      color: #fff;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 16px;
    }
    .mb16 {
      margin-bottom: 16px;
    }
    .mb10 {
      margin-bottom: 10px;
    }
    .tips {
      font-size: 14px;
      line-height: 14px;
      color: rgba(255, 255, 255, 0.8);
    }
    .setting {
      width: 100%;
      box-sizing: border-box;
      height: 59px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
      width: 345px;
      padding: 12px 10px;
      .label-txt {
        font-size: 16px;
        color: rgba(255, 255, 255, 0.9);
        line-height: 22px;
      }
      .tips-txt {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 22px;
      }
    }
  }
  .submit {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    border-radius: 10px;
    width: 335px;
    height: 44px;
    border: 0;
    background: #f87a1b;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }
}
</style>
