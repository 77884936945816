const map = Object.freeze([
  { key: 'enter_shouye_tab', name: '进入_首页tab' },
  { key: 'enter_shangcheng_tab', name: '进入_商城tab' },
  { key: 'enter_zixun_tab', name: '进入_资讯tab' },
  { key: 'enter_gongju_tab', name: '进入_工具tab' },
  { key: 'enter_mine_tab', name: '进入_我的tab' },
  { key: 'wechat_log_success', name: '微信登录成功' },
  { key: 'phone_log_success', name: '绑定手机号成功' },
  { key: 'zizhagnhao_log_success', name: '子账号绑定成功' },
  { key: 'enter_shentai_zhuye', name: '进入神台主页' },
  { key: 'shentai_sangui', name: '设置神台三柜总人数' },
  { key: 'shentai_sangui_daojiao', name: '设置神台三柜_道教' },
  { key: 'shentai_sangui_fojiao', name: '设置神台三柜_佛教' },
  { key: 'shentai_sangui_fodaojiao', name: '设置神台三柜_佛道综合' },
  { key: 'shentai_dangui_xianren', name: '设置神台单柜_先人' },
  { key: 'shentai_dangui_tudishen', name: '设置神台单柜_土地神' },
  { key: 'shentai_dangui_datianshi', name: '设置神台单柜_西方大天使' },
  { key: 'enter_gongfeng_pages', name: '进入供奉页面总数' },
  { key: 'enter_dangui_gongfeng_pages', name: '从单柜进入供奉页面' },
  { key: 'enter_sangui_one_gongfeng_pages', name: '从三柜_上柜进入供奉页面' },
  { key: 'enter_sangui_tow_gongfeng_pages', name: '从三柜_中柜进入供奉页面' },
  { key: 'enter_sangui_three_gongfeng_pages', name: '从三柜_下柜进入供奉页面' },
  { key: 'xinshou_tanchuan_gongpin_show', name: '贡品新手弹窗_展示' },
  { key: 'xinshou_tanchuan_gongpin_close', name: '贡品新手弹窗_关闭' },
  { key: 'complete_gongpin', name: '完成上贡品（花、香、果、蜡烛）' },
  { key: 'gongfeng_gongqing_button_click', name: '供奉页_点击_恭请神明_按钮' },
  { key: 'gongfeng_mygongfeng_button_click', name: '供奉页_点击_我的供奉_按钮' },
  { key: 'gongfeng_mygongfeng_sheweizhuwei', name: '供奉页_我的供奉_点击_设为主位' },
  { key: 'gongfeng_kong_gongqing_click', name: '点击_空供奉页_恭请神明' },
  { key: 'gongfeng_shuoming_button_click', name: '供奉页_查看说明' },
  { key: 'gongfeng_shuoming_bottom', name: '供奉页_说明页_滑到底部' },
  { key: 'gongfeng_left_and_right_switch', name: '供奉页_左右切换操作' },
  { key: 'gongfeng_shenxiang_jieshao_show', name: '供奉页_神像介绍页_展示' },
  { key: 'gongfeng_shenxiang_jieshao_close', name: '供奉页_神像介绍页_关闭' },
  { key: 'gongfeng_shenxiang_jieshao_songshen', name: '供奉页_神像介绍页_点击送神' },
  { key: 'gongfeng_songshen_sure', name: '确定送神' },
  { key: 'gongfeng_songshen_cancel', name: '取消送神' },
  { key: 'shangxiang_total_click', name: '敬香总数' },
  { key: 'gongfeng_shangxiang_click', name: '供奉页_敬香' },
  { key: 'gongfeng_xianghuo_click', name: '供奉页_点击_香火值' },
  { key: 'gongfeng_xianghuo_mingxi', name: '供奉页_查看香火明细' },
  { key: 'gongfeng_sheweizhuwei_click', name: '供奉页_点击_设为主位' },
  { key: 'gongfeng_replace_zhuwei_shenxiang', name: '确定更换主位' },
  { key: 'enter_yinyue_pages', name: '进入音乐页面' },
  { key: 'play_music', name: '播放音乐' },
  { key: 'play_music_liebiaobofang', name: '顺序播放音乐' },
  { key: 'play_music_suijibofang', name: '随机播放音乐' },
  { key: 'play_music_danjubofang', name: '单曲播放音乐' },
  { key: 'enter_gongke_pages', name: '进入功课页面' },
  { key: 'do_gongke', name: '做功课' },
  { key: 'interrupt_gongke', name: '中断功课' },
  { key: 'complete_gongke', name: '完成功课' },
  { key: 'new_gongke_success', name: '成功新建功课' },
  { key: 'delete_gongke_success', name: '成功删除功课' },
  { key: 'gongke_set_play_time', name: '设定功课播放时间' },
  { key: 'enter_gongdezhi_panges', name: '进入总功德值记录页面' },
  { key: 'gongdezhi_shuoming', name: '查看功德值说明' },
  { key: 'enter_wenhua_pages', name: '进去文化科普页面' },
  { key: 'wenhua_jiafengjiaxun_click', name: '文化科普_家风家训' },
  { key: 'wenhua_fengsuizhishi_click', name: '文化科普_风水知识' },
  { key: 'wenhua_fojiaowenhua_click', name: '文化科普_佛教文化' },
  { key: 'wenhua_daojiaowenhua_click', name: '文化科普_道教文化' },
  { key: 'upload_audio_success', name: '家风家训_成功上传音频' },
  { key: 'upload_video_success', name: '家风家训_成功上传视频' },
  { key: 'delete_audio_success', name: '家风家训_删除音频' },
  { key: 'delete_video_success', name: '家风家训_删除视频' },
  { key: 'play_audio_success', name: '家风家训_播放音频' },
  { key: 'play_video_success', name: '家风家训_播放视频' },
  { key: 'bangzhu_click', name: '主页_点击_帮助' },
  { key: 'setting_click', name: '主页_点击_设置' },
  { key: 'setting_shentaiji_click', name: '设置_神台机设置' },
  { key: 'setting_moshi_click', name: '设置_模式选择' },
  { key: 'setting_xianshi_click', name: '设置_显示设置' },
  { key: 'setting_xianshi_xiping_time_never', name: '显示设置_息屏_永不' },
  { key: 'setting_xianshi_xiping_time_thirty_s', name: '显示设置_息屏_30秒' },
  { key: 'setting_xianshi_xiping_time_one_min', name: '显示设置_息屏_1分钟' },
  { key: 'setting_xianshi_xiping_time_five_min', name: '显示设置_息屏_5分钟' },
  { key: 'setting_xianshi_xiping_time_ten_min', name: '显示设置_息屏_10分钟' },
  { key: 'setting_xianshi_xiping_time_thirty_min', name: '显示设置_息屏_30分钟' },
  { key: 'setting_xianshi_beijingdeng_on', name: '显示设置_背景灯_开' },
  { key: 'setting_xianshi_beijingdeng_off', name: '显示设置_背景灯_关' },
  { key: 'setting_yinxiao_click', name: '设置_音效设置' },
  { key: 'setting_yinxiao_dianxiang_on', name: '音效设置_敬香音效_开' },
  { key: 'setting_yinxiao_dianxiang_off', name: '音效设置_敬香音效_关' },
  { key: 'setting_yinxiao_dianxiang_one', name: '音效设置_敬香音效_一响' },
  { key: 'setting_yinxiao_dianxiang_three', name: '音效设置_敬香音效_三响' },
  { key: 'setting_xiangxun_click', name: '设置_香薰设置' },
  { key: 'setting_xiangxun_new_time_success', name: '香薰设置_成功添加定时' },
  { key: 'setting_xiangxun_time_length_five', name: '香薰设置_喷洒时长_5秒' },
  { key: 'setting_xiangxun_time_length_ten', name: '香薰设置_喷洒时长_10秒' },
  { key: 'setting_xiangxun_time_length_twenty', name: '香薰设置_喷洒时长_20秒' },
  { key: 'setting_xiangxun_time_length_thirty', name: '香薰设置_喷洒时长_30秒' },
  { key: 'return_shouye_button_click', name: '点击返回首页按钮' },
  { key: 'shouye_banner_yuhuang', name: '首页_点击玉皇大帝广告图' },
  { key: 'shouye_enter_shentai_click', name: '首页_点击进去神台机' },
  { key: 'shouye_xiangxun_click', name: '首页_点击开启香薰' },
  { key: 'H5_shouye_muyu_click', name: '首页_点击木鱼' },
  { key: 'H5_gongfeng_muyu_click', name: '供奉页_点击木鱼' },
  { key: 'H5_muyu_setting_click', name: '木鱼_点击设置' },
  { key: 'H5_muyu_yinyue_click', name: '木鱼_点击播放音乐' },
  { key: 'H5_switch_nianzhu_success', name: '成功切换念珠' },
  { key: 'H5_switch_muyu_success', name: '成功切换木鱼' },
  { key: 'H5_muyu_setting_word_success', name: '木鱼_修改悬浮文案' },
  { key: 'H5_muyu_click_over_100', name: '敲击木鱼超过100' },
  { key: 'H5_muyu_click_over_300', name: '敲击木鱼超过300' },
  { key: 'H5_muyu_click_over_500', name: '敲击木鱼超过500' },
  { key: 'H5_nianzhu_click_over_100', name: '敲击念珠超过100' },
  { key: 'H5_nianzhu_click_over_300', name: '敲击念珠超过300' },
  { key: 'H5_nianzhu_click_over_500', name: '敲击念珠超过500' },
  { key: 'H5_shouye_rili_click', name: '首页_点击宗教日历' },
  { key: 'H5_setting_xianshi_rili_off', name: '显示设置_宗教日历_关' },
  { key: 'H5_setting_xianshi_rili_on', name: '显示设置_宗教日历_开' },
  { key: 'H5_rili_setting_time_on', name: '日历开启时间提醒' },
  //三期新增
  { key: 'H5_gongfeng_one_click_all', name: '点击一键供奉按钮汇总' },
  { key: 'H5_gongfeng_one_click_setting_success_all', name: '成功设置一键供奉汇总' },
  { key: 'H5_complete_gongpin_all', name: '完成上贡品汇总' },
  { key: 'H5_qingshen_success', name: '成功请神' },
  { key: 'H5_zuxian_add_success', name: '成功添加祖先' },
  { key: 'H5_zuxian_complete_gongpin', name: '祖先_完成上供品' },
  { key: 'H5_shenming_complete_gongpin', name: '神明_完成上贡品' },
  { key: 'H5_zuxian_gongfeng_one_click', name: '祖先_点击一键供奉' },
  { key: 'H5_shenming_gongfeng_one_click', name: '神明_点击一键供奉' },
  { key: 'H5_enter_muyu_people_all', name: '进入木鱼页面总人数' },
  { key: 'H5_enter_nianzhu_people_all', name: '进入念珠页面总人数' },
  { key: 'H5_enter_songbo_people_all', name: '进入颂钵页面总人数' },
  { key: 'H5_songbo_click', name: '点击颂钵' },
  { key: 'H5_show_foguang', name: '出现佛光（木鱼念珠颂钵）' },
  { key: 'H5_show_jingwen', name: '出现悬浮经文（木鱼念珠颂钵）' },
  { key: 'H5_muyu_foyin_click', name: '在木鱼念珠颂钵页听佛音' },
  { key: 'H5_setting_waiguan_click', name: '外观设置' },
  { key: 'H5_setting_waiguan_moren_success', name: '外观设置_默认' },
  { key: 'H5_setting_waiguan_jin_success', name: '外观设置_金' },
  { key: 'H5_setting_waiguan_mu_success', name: '外观设置_木' },
  { key: 'H5_setting_waiguan_shui_success', name: '外观设置_水' },
  { key: 'H5_setting_waiguan_huo_success', name: '外观设置_火' },
  { key: 'H5_setting_waiguan_tu_success', name: '外观设置_土' },
  { key: 'H5_setting_zuxian_click', name: '祖先设置' },
  { key: 'H5_setting_zuxian_style_one', name: '祖先设置-牌位' },
  { key: 'H5_setting_zuxian_style_two', name: '祖先设置-人物像' },
  { key: 'H5_xianren_shangxiang_click', name: '祖先页_敬香' },
]);

export const track = key => {
  const tmp = map.find(track => track.key === key);
  if (!tmp) return false;
  window.sense &&
    window.sense.track('$Click', {
      $event_id: key,
      $event_name: tmp.name,
      $module: key,
      $title: tmp.name,
    });
};
