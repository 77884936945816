var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vip-privilege-container" },
    [
      _c(
        "div",
        { class: ["user-vip-info", { "no-vip": !_vm.userInfo.is_vip }] },
        [
          _c("div", { staticClass: "user" }, [
            _c("div", { staticClass: "avatar f fc fvc" }, [
              _vm.userInfo.avatar
                ? _c("img", {
                    attrs: {
                      src: _vm.$tools.compressImg(_vm.userInfo.avatar, 70),
                    },
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "name" }, [
              _vm._v(" " + _vm._s(_vm.userInfo.name) + " "),
            ]),
            _c("img", {
              staticClass: "vip-icon",
              attrs: {
                src: _vm.userInfo.is_vip
                  ? "https://fe-img.tengzhihh.com/image/e723ca21a459759733341351878527c6-88x30.png"
                  : "https://fe-img.tengzhihh.com/image/86bcc71dcccfb21bdada1512dbb8ddb9-88x30.png",
              },
            }),
          ]),
          _c("div", { staticClass: "info" }, [
            _c("p", { staticClass: "vip-validity" }, [
              _vm._v(
                _vm._s(
                  _vm.userInfo.vip_info
                    ? _vm.userInfo.vip_info.txt
                    : "未成为VIP"
                )
              ),
            ]),
            _c(
              "div",
              { staticClass: "btn", on: { click: _vm.handleVipBtnClick } },
              [
                _vm._v(
                  " " + _vm._s(_vm.userInfo.is_vip ? "续费" : "加入VIP") + " "
                ),
              ]
            ),
          ]),
        ]
      ),
      _c("img", {
        staticClass: "divider",
        attrs: {
          src: "https://fe-img.tengzhihh.com/image/f60dd600fd0d54dd943a8c8ce7a27131-642x34.png",
          alt: "",
        },
      }),
      _c(
        "div",
        { staticClass: "menu" },
        _vm._l(_vm.menuList, function (item) {
          return _c(
            "div",
            {
              key: item.id + "key",
              staticClass: "menu-item",
              on: {
                click: function ($event) {
                  return _vm.handleMenuClick(item.type)
                },
              },
            },
            [
              _c("img", {
                staticClass: "menu-icon",
                attrs: { src: item.icon, alt: "" },
              }),
              _c("div", { staticClass: "label" }, [_vm._v(_vm._s(item.title))]),
            ]
          )
        }),
        0
      ),
      _c("PopupVipTips", { ref: "vip", attrs: { list: _vm.menuList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }