<template>
  <div>
    <div class="init-box f frd flex-wrap" v-if="pageStep === 'index'">
      <div v-for="item in modeList" class="mode-box f fv fc" :key="item.id" @click="clickMode(item)">
        <div :class="` ${itemClass(item, 'index_mode')} mode-info f fv fc`">
          <img :src="item.image" class="mode-img" />
          <div class="mode-name">{{ item.title }}</div>
        </div>
        <div>
          <img
            v-if="item.index_mode === selectedMode.index_mode"
            src="https://img-fe.tengzhihh.com/image/b7b12cf06d838d-56x56.png"
            class="selected-icon"
          />
        </div>
      </div>
      <i class="mode-box" v-for="item in new Array(modeList.length % 2)"></i>
      <div class="confirm-box">
        <div class="confirm-btn" @click="confirmIndexMode">{{ calcModeBtn }}</div>
      </div>
    </div>

    <div class="init-box f frd flex-wrap religion-box" v-else-if="pageStep === 'religion'">
      <GoBack
        :backFunction="
          () => {
            pageStep = 'index';
          }
        "
      />
      <div v-for="item in religionList" class="mode-box f fv fc" :key="item.id" @click="clickReligion(item)">
        <div :class="` ${itemClass(item, 'religion')} mode-info f fv fc`">
          <img :src="item.image" class="religion-img" />
          <div class="mode-name">{{ item.title }}</div>
        </div>
        <div>
          <img
            v-if="item.religion === selectedReligion.religion"
            src="https://img-fe.tengzhihh.com/image/b7b12cf06d838d-56x56.png"
            class="selected-icon"
          />
        </div>
      </div>
      <i class="mode-box" v-for="item in new Array(religionList.length % 2)"></i>

      <div class="confirm-box">
        <div class="confirm-btn" @click="confirmReligion">{{ calcReligionBtn }}</div>
      </div>
    </div>

    <div v-else-if="pageStep === 'list'">
      <GodList
        :godType="godType"
        :serialId="serialId"
        :religion="selectedReligion.religion"
        :indexMode="selectedMode.index_mode"
        :hasMasterWorship="hasMasterWorship"
        from="settings"
        @goBack="goBack"
        @setModeAndReligionAndGod="setModeAndReligionAndGod"
      />
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import GodList from '@/components/God/GodList.vue';
import GoBack from '@/components/GoBack/GoBack.vue';
import sendMsg from '@/webSocket/sendMsg';
export default {
  components: {
    GodList,
    GoBack,
  },
  data() {
    return {
      pageStep: 'index',
      modeList: [],
      religionList: [],
      serialId: '',
      selectedMode: { index_mode: '' },
      selectedReligion: {
        religion: '',
      },
      currentIndexMode: '',
      currentReligion: '',
      from: '',
      bindCount: 0,
      hasDefaultGod: 0,
      deceasedCount: 0,
      loading: false,
      godType: 1,
    };
  },
  computed: {
    calcModeBtn: function () {
      const mode = this.selectedMode.index_mode;
      return ['god', 'all'].includes(mode) ? '下一步，选择宗教类型' : '确认';
    },
    calcReligionBtn: function () {
      return '下一步，选择供奉对象';
    },
    hasMasterWorship: function () {
      if (['earth_god', 'western'].includes(this.selectedMode.index_mode)) {
        const tmp = this.modeList.find(i => i.index_mode === this.selectedMode.index_mode);
        return tmp?.has_master_worship === 1;
      } else if (['all', 'god'].includes(this.selectedMode.index_mode)) {
        const tmp = this.religionList.find(i => i.religion === this.selectedReligion.religion);
        return tmp?.has_master_worship === 1;
      }
    },
  },
  created() {
    this.serialId = this.$route.query.serial_id;
    this.pageStep = this.$route.query.page_step || 'index';
    this.from = this.$route.query.from;
  },
  mounted() {
    this.getModeList();
    this.getReligionList();
    this.getDeviceInfo();
    this.$webSocket.initIM(this.serialId);
  },
  methods: {
    goBack() {
      if (['earth_god', 'deceased', 'western'].includes(this.selectedMode.index_mode)) {
        this.pageStep = 'index';
      } else {
        this.pageStep = 'religion';
      }
    },
    setValue(key, value) {
      this[key] = value;
    },
    itemClass(item, type) {
      return (type === 'index_mode' && item.index_mode === this.selectedMode?.index_mode) ||
        (type === 'religion' && item.religion === this.selectedReligion?.religion)
        ? 'selected-item'
        : '';
    },
    getDeviceInfo() {
      return this.$http
        .get(apiPath.getDeviceInfo, {
          serial_id: this.serialId,
        })
        .then(res => {
          this.bindCount = res.data.bind_count;
          this.currentIndexMode = res.data.index_mode;
          this.currentReligion = res.data.religion;

          this.selectedMode.index_mode = res.data.index_mode;
          this.selectedReligion.religion = res.data.index_mode === 'western' ? 'cn_comprehensive' : res.data.religion;
          this.deceasedCount = res.data.deceased_count;
          this.hasDefaultGod = res.data.has_default_god;
        });
    },
    getReligionList() {
      this.$http
        .get(apiPath.religionList, {
          serial_id: this.serialId,
          from_page: 'config',
        })
        .then(res => {
          this.religionList = res.data;
        });
    },
    getModeList() {
      this.$http
        .get(apiPath.mode, {
          serial_id: this.serialId,
          from_page: 'config',
        })
        .then(res => {
          this.modeList = res.data;
        });
    },
    clickMode(item) {
      this.selectedMode = item;
    },
    clickReligion(item) {
      this.selectedReligion = item;
    },

    confirmIndexMode() {
      if (!this.selectedMode?.index_mode) {
        return this.$toast('请先选择模式');
      }
      // 选了先人，直接设置模式
      if (['deceased'].includes(this.selectedMode.index_mode)) {
        this.setDeceasedMode();
        return true;
      } else if (['earth_god', 'western'].includes(this.selectedMode.index_mode)) {
        this.godType = this.selectedMode.index_mode === 'earth_god' ? 2 : 1;
        this.pageStep = 'list';
      } else {
        this.pageStep = 'religion';
      }
    },
    confirmReligion() {
      if (!this.selectedReligion.religion) {
        return this.$toast('请先选择宗教类型');
      }
      this.godType = 1;
      this.pageStep = 'list';
    },

    setModeAndReligionAndGod(god_id) {
      const data = {
        serial_id: this.serialId,
        mode: this.selectedMode.index_mode,
        religion: this.selectedReligion.religion,
      };
      if (god_id) {
        data['god_id'] = god_id;
      }
      this.$http.post(apiPath.setModeAndReligion, data).then(() => {
        this.handleTrack(this.selectedMode.index_mode, this.selectedReligion.religion);

        this.successJump();
      });
    },
    handleTrack(indexMode, religion) {
      if (indexMode === 'all') {
        this.$track('shentai_sangui');
        this.$track(
          religion === 'buddhism'
            ? 'shentai_sangui_fojiao'
            : religion === 'taoism'
            ? 'shentai_sangui_daojiao'
            : 'shentai_sangui_fodaojiao'
        );
      } else if (indexMode === 'god') {
        this.$track('shentai_dangui');
        this.$track(
          religion === 'buddhism'
            ? 'shentai_dangui_fojiao'
            : religion === 'taoism'
            ? 'shentai_dangui_daojiao'
            : 'shentai_dangui_fodaojiao'
        );
      } else {
        this.$track(indexMode === 'earth_god' ? 'shentai_dangui_tudishen' : 'shentai_dangui_datianshi');
      }
    },

    setDeceasedMode() {
      const index_mode = this.selectedMode.index_mode;
      this.$http
        .post(apiPath.setModeAndReligion, {
          mode: index_mode,
          serial_id: this.serialId,
        })
        .then(() => {
          this.$track('shentai_dangui_xianren');
          this.successJump();
        });
    },
    successJump() {
      sendMsg('player', {
        operation: 'stop',
        playerType: 'classes',
      });
      this.$toast('操作成功');
      this.$router.replace(`/device/index?serial_id=${this.serialId}&from=init_select`);
    },
  },
};
</script>

<style lang="less" scoped>
.init-box {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  padding: 20px 0 60px;
  .mode-box {
    position: relative;
    margin-bottom: 20px;
    width: 176px;
    .selected-icon {
      width: 28px;
      height: 28px;
      position: absolute;
      bottom: -14px;
      left: 50%;
      margin-left: -14px;
    }
    .selected-item {
      border: 2px solid #f97a1d !important;
    }
    .mode-info {
      padding-top: 8px;
      border-radius: 10px;
      text-align: center;
      width: 165px;
      background: #ffffff;
      border: 2px solid transparent;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
      .mode-img {
        width: 150px;
        height: 170px;
      }
      .religion-img {
        width: 150px;
        height: 170px;
      }
      .mode-name {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin: 12px 0;
      }
    }
    .select-btn {
      width: 165px;
      height: 40px;
      line-height: 40px;
      background: rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-size: 16px;
      color: #ffffff;
      margin-top: 16px;
      text-align: center;
    }
    .current {
      background: #f87a1b;
    }
  }
}
.religion-box {
  padding-top: 60px;
}

.confirm-box {
  position: fixed;
  bottom: 0;
  width: 375px;
  height: 60px;
  background: rgba(255, 255, 255, 0.65);
  .confirm-btn {
    margin: 8px auto;
  }
}

.confirm-btn {
  width: 299px;
  height: 44px;
  background: #f87a1b;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  line-height: 44px;
  font-size: 16px;
  color: #fff;
  margin: 0 0 30px;
}
.popup-box {
  width: 295px;
  height: 382px;
  background: #ffffff;
  padding: 38px 0 0;
  .img {
    width: 150px;
  }
  .name {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    margin: 16px 0;
  }
  .btn-box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 35px;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    .cancel-btn {
      width: 100px;
      height: 40px;
      background: #f6f6f6;
      border-radius: 10px;
      color: rgba(0, 0, 0, 0.65);
    }
    .confirm-btn {
      width: 100px;
      height: 40px;
      background: #f87a1b;
      border-radius: 10px;
      color: #fff;
    }
  }
}
</style>
