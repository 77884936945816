<template>
  <div class="page-box">
    <div class="calendar-container">
      <div class="top-area f fc fbt">
        <div class="month-area f fc" @click="datePickerFlag = true">
          <div class="detail">{{ currentYear }}年{{ currentMonth }}月</div>
          <img src="https://img-fe.tengzhihh.com/image/9043985035d0a2-32x32.png" class="down-arrow" />
        </div>
        <div class="tag-info f fc">
          <div class="info-item f fc">
            <img :src="foTag" />
            佛教节日
          </div>
          <div class="f fc">
            <img :src="daoTag" />
            道教节日
          </div>
        </div>
      </div>
      <div class="days-area">
        <div class="days f fc frd">
          <div class="day-item orange f fv fc fvc">日</div>
          <div class="day-item f fv fc fvc">一</div>
          <div class="day-item f fv fc fvc">二</div>
          <div class="day-item f fv fc fvc">三</div>
          <div class="day-item f fv fc fvc">四</div>
          <div class="day-item f fv fc fvc">五</div>
          <div class="day-item orange f fv fc fvc">六</div>
        </div>
        <div class="days-line f fc frd" v-for="(line, index) in calcDaysLine" :key="index">
          <div
            :class="`day-item f fv fc fvc ${idx === 0 || idx === 6 ? 'orange' : ''}
            ${item.solar === today ? 'is-today' : ''}
            ${currentDateInfoObj && item.solar === currentDateInfoObj.solar ? 'is-selected' : ''}
            ${item.is_now_month ? '' : 'not-current-month'}
            `"
            v-for="(item, idx) in line"
            :key="idx"
            @click="setCurrentDate(item)"
          >
            <div class="day-num">
              {{ item.solar.split('-')[2] }}
            </div>
            <div
              :class="`${item.solar === today ? 'is-today' : ''}
            ${calcItemSubTitle(item).key === 'festival' ? 'is-festival' : ''} clamp1 day-n`"
            >
              {{ calcItemSubTitle(item).value }}
            </div>
            <div class="tag-img-box f fv">
              <img :src="foTag" class="tag-img" v-if="haveFo(item.festivals)" />
              <img :src="daoTag" class="tag-img" v-if="haveDao(item.festivals)" />
            </div>
          </div>
          <i class="day-item" v-for="j in new Array(7 - line.length)" :key="j"></i>
        </div>
      </div>
    </div>

    <div class="detail-box">
      <div class="day-detail">
        <div class="top f fc">
          <div class="left">{{ currentDate }}</div>
          <div class="right">
            <div class="sub-top">农历 {{ lunar }}</div>
            <div class="sub-bottom">
              {{ lunarYear }}
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="bottom">
          <div class="bottom-sub f fc">
            <div class="tag good f fc fvc">宜</div>
            <div class="good-detail">{{ yi }}</div>
          </div>
          <div class="f fc">
            <div class="tag bad f fc fvc">忌</div>
            <div class="bad-detail">{{ ji }}</div>
          </div>
        </div>
      </div>

      <div class="festival-about" v-if="latelyFestival.length > 0">
        <div class="title">最近的节日</div>
        <div class="festival-item" v-for="item in latelyFestival" :key="item.id">
          <div class="date">
            <div class="g f fbt">
              <span>{{ item.solar }}</span>
              <button v-if="expiredFn(item.solar)" @click="remindFn(item)">设置提醒</button>
            </div>
            <div class="n">{{ item.lunar }}</div>
          </div>
          <div class="detail f fc fbt" v-for="f in item.festivals" :key="f.id">
            <div class="info f fc">
              <img
                v-if="f.type === 1 || f.type === 2"
                :src="f.type === 1 ? daoTag : f.type === 2 ? foTag : ''"
                class="tag"
              />
              <div class="name">{{ f.name }}</div>
            </div>
            <!-- <img src="https://img-fe.tengzhihh.com/image/08e7301a2a1c12-33x32.png" class="right-arrow" /> -->
          </div>
        </div>
      </div>
    </div>

    <date-picker
      :show="datePickerFlag"
      :currentDate="pickDate"
      pickerType="year-month"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
    ></date-picker>
    <van-popup round v-model="remindPop" @close="closeRemindPop" class="remindPop">
      <div class="remindTitle txt-c" v-for="item in remindSwitch.remindFestivals" :key="item.id">{{ item.name }}</div>
      <div class="remindSub f fvc">
        <span>{{ remindSwitch.left_day < 0 ? '已过去' : '还有' }}&nbsp;</span
        ><span class="timeoutDay light-txt">{{ Math.abs(remindSwitch.left_day) }}</span
        ><span>&nbsp;天</span>
      </div>
      <div class="remindDate txt-c">{{ remindSwitch.solar }} 星期{{ weekTxt[remindSwitch.week] }}</div>
      <div class="remindLunar txt-c">{{ remindSwitch.lunar }}</div>
      <div class="remindSwitch">
        <div class="f fc fbt mb10" v-if="showEarlyRemind(remindSwitch.solar)">
          <span>提前一天提醒 上午9:00</span>
          <van-switch size="26" active-color="#F87A1B" inactive-color="#D5D5D5" v-model="remindSwitch.earlySwitch" />
        </div>
        <div class="f fc fbt">
          <span>当天提醒 上午9:00</span>
          <van-switch size="26" active-color="#F87A1B" inactive-color="#D5D5D5" v-model="remindSwitch.timelySwitch" />
        </div>
      </div>
      <div class="popHandle f fvc">
        <van-button type="default" class="basics-btn" @click="remindPop = false">取消</van-button>
        <van-button color="#F87A1B" class="primary-btn" @click="setRemindFn">保存</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import DatePicker from '@/components/DatePicker/DatePicker';
import { setRemindDate, getRemindDate } from '@/api/tools';
import dayjs from 'dayjs';
export default {
  components: { DatePicker },
  data() {
    return {
      days: [],
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      currentDate: new Date().getDate(),

      datePickerFlag: false,
      pickDate: new Date(),
      currentDateInfoObj: null, // 月接口返回的天详情
      currentDateDetailObj: null, // 天接口返回的具体填详情
      latelyFestival: [],
      remindPop: false,
      remindSwitch: {
        remindFestivals: [],
        earlySwitch: false,
        timelySwitch: false,
      },
      weekTxt: ['日', '一', '二', '三', '四', '五', '六'],
    };
  },
  computed: {
    calcDaysLine() {
      return this.$tools.sliceArr(this.days, 7);
    },
    today() {
      const now = new Date();
      return `${now.getFullYear()}-${this.zero(now.getMonth() + 1)}-${this.zero(now.getDate())}`;
    },
    foTag() {
      return 'https://img-fe.tengzhihh.com/image/7bb2a9d2c0513f-32x32.png';
    },
    daoTag() {
      return 'https://img-fe.tengzhihh.com/image/d045b0f0d86678-32x32.png';
    },
    yi() {
      return (this.currentDateDetailObj?.yi && this.currentDateDetailObj.yi.join()) || '';
    },
    ji() {
      return (this.currentDateDetailObj?.ji && this.currentDateDetailObj.ji.join()) || '';
    },
    lunar() {
      return (this.currentDateDetailObj?.lunar && this.currentDateDetailObj.lunar.split('年')[1]) || '';
    },
    lunarYear() {
      return (this.currentDateDetailObj?.lunar && this.currentDateDetailObj.lunar.split('年')[0] + '年') || '';
    },
  },
  mounted() {
    this.getCalendar();
    this.getDay();
    this.getLatelyFestival();
  },
  methods: {
    zero(v) {
      return String(v).length === 1 ? `0${v}` : v;
    },
    haveFo(f = []) {
      return f && f.length ? !!f.find(i => i.type === 2) : false;
    },
    haveDao(f = []) {
      return f && f.length ? !!f.find(i => i.type === 1) : false;
    },
    calcItemSubTitle(item) {
      // type节日类型，0是传统节日，1是道教节日，2是佛教节日，4是国际节日
      // const tmp = item.festivals?.find((i) => i.type === 1 || i.type === 2)?.name || '';//在template模板中用?.会报错
      const tmp =
        item.festivals && item.festivals.length ? item.festivals.find(i => i.type === 1 || i.type === 2)?.name : '';
      if (tmp) {
        return {
          key: 'festival',
          value: tmp,
        };
      } else {
        return {
          value: item.lunar.split('月')[1],
          key: 'lunar',
        };
      }
    },
    setCurrentDate(item) {
      this.currentDateInfoObj = item;
      this.currentMonth = item.solar.split('-')[1];
      this.currentDate = item.solar.split('-')[2];
      this.getDay();
      this.getLatelyFestival();
    },
    getLatelyFestival() {
      let date = `${this.currentYear}${this.zero(this.currentMonth)}${this.zero(this.currentDate)}`;
      // this.$route.params.date ??
      this.$http
        .get(apiPath.getLatelyFestival, {
          date,
        })
        .then(res => {
          this.latelyFestival = res.data;
        });
    },
    expiredFn(day) {
      return dayjs().unix() <= dayjs(day).hour(9).unix();
    },
    onCancel() {
      this.datePickerFlag = false;
    },
    async onConfirm(v) {
      this.pickDate = new Date(v);
      this.currentYear = v.split('-')[0];
      this.currentMonth = v.split('-')[1];

      await this.getCalendar();
      const tmp = this.days.find(i => i.is_now_month);
      this.setCurrentDate(tmp);
      this.getDay();
      this.datePickerFlag = false;
    },
    getDay() {
      this.$http
        .get(apiPath.getDay, {
          date: `${this.currentYear}${this.zero(this.currentMonth)}${this.zero(this.currentDate)}`,
        })
        .then(res => {
          this.currentDateDetailObj = res.data;
        });
    },
    getCalendar() {
      return this.$http
        .get(apiPath.getMonth, {
          date: `${this.currentYear}${this.zero(this.currentMonth)}`,
        })
        .then(res => {
          this.days = res.data;
        });
    },
    async remindFn({ festivals, solar, lunar }) {
      const { data } = await getRemindDate({
        festival_id: JSON.stringify(festivals.map(x => x.id)),
        serial_id: this.$route.query.serial_id ?? '',
      });
      this.remindSwitch = {
        remindFestivals: festivals.map(x => {
          if (data.list.length) {
            const detail = data.list.find(l => l.festival_id === x.id)?.list;
            x.earlySwitchId = detail.find(l => l.type === 1)?.id;
            x.timelySwitchId = detail.find(l => l.type === 0)?.id;
          }
          return x;
        }),
        solar,
        lunar,
        left_day: festivals.length ? festivals[0].left_day : '-',
        week: dayjs(solar).day(),
        earlySwitch: data.list?.length ? !!data.list[0].list.find(x => x.type === 1)?.status : false,
        timelySwitch: data.list?.length ? !!data.list[0].list.find(x => x.type === 0)?.status : false,
      };
      this.remindPop = true;
    },
    async setRemindFn() {
      const time = dayjs(this.remindSwitch.solar);
      const data = JSON.stringify(
        this.remindSwitch.remindFestivals.map(x => {
          return {
            festival_id: x.id,
            data: [
              {
                time: time.subtract(1, 'day').hour(9).unix(),
                type: 1,
                status: Number(this.remindSwitch.earlySwitch),
                id: x.earlySwitchId ?? 0,
              },
              {
                time: time.hour(9).unix(),
                type: 0,
                status: Number(this.remindSwitch.timelySwitch),
                id: x.timelySwitchId ?? 0,
              },
            ],
          };
        })
      );
      await setRemindDate({
        data,
      });
      this.remindPop = false;
      this.$toast('设置提醒成功');
      this.$track('H5_rili_setting_time_on');
    },
    closeRemindPop() {},
    showEarlyRemind(day) {
      return day ? dayjs().unix() <= dayjs(day).subtract(1, 'day').hour(9).unix() : false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100 - 50px);
  padding-bottom: 16px;
  background-color: #fcf5ec;
  .calendar-container {
    background: #fff;
    .top-area {
      padding: 0 16px;
      height: 50px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      .month-area {
        .detail {
          font-size: 20px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
        }
        .down-arrow {
          width: 16px;
          height: 16px;
        }
      }
      .tag-info {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
        .info-item {
          margin-right: 4px;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 2px;
        }
      }
    }
    .orange {
      color: #f87a1b !important;
    }
    .days-area {
      .days {
        .day-item {
          width: 48px;
          height: 30px;
        }
      }
      .days-line {
        .day-item {
          margin-bottom: 10px;
          width: 49px;
          height: 52px;
          color: rgba(0, 0, 0, 0.85);
          border: 1px solid transparent;
          position: relative;
          .tag-img-box {
            position: absolute;
            top: 0;
            right: 0;
            .tag-img {
              width: 12px;
              height: 12px;
              margin-bottom: 2px;
            }
          }
          .day-num {
            font-size: 18px;
          }
          .day-n {
            font-size: 12px;
          }
          .is-festival {
            color: #f87a1b;
          }
        }
        .not-current-month {
          opacity: 0.25;
        }
        .is-today {
          color: #fff !important;
          background: #f87a1b !important;
          border-radius: 8px;
        }
        .is-selected {
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border: 1px solid #f87a1b;
          border-radius: 8px;
        }
      }
    }
  }
  .detail-box {
    .day-detail {
      width: 345px;
      margin: 16px auto;
      background: #ffffff;
      border-radius: 10px;
      padding: 16px;
      box-sizing: border-box;
      .top {
        .left {
          font-size: 55px;
          color: #f87a1b;
          margin-right: 28px;
          width: 58px;
          text-align: center;
        }
        .right {
          .sub-top {
            color: #000000;
            font-size: 16px;
            margin-bottom: 4px;
            font-weight: bold;
          }
          .sub-bottom {
            color: #000000;
            font-size: 12px;
          }
        }
      }
      .line {
        width: 229px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: 88px;
      }
      .bottom {
        margin-top: 16px;
        .bottom-sub {
          margin-bottom: 8px;
        }
        .tag {
          margin-left: 19px;
          margin-right: 45px;
          font-size: 16px;
          width: 24px;
          height: 24px;
          border-radius: 4px;
        }
        .good {
          border: 1px solid #f87a1b;
          color: #f87a1b;
        }
        .bad {
          border: 1px solid #000000;
          color: #000000;
        }
        .good-detail {
          color: #f87a1b;
          font-size: 14px;
          line-height: 24px;
          flex: 1;
        }
        .bad-detail {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 24px;
          flex: 1;
        }
      }
    }
    .festival-about {
      width: 345px;
      margin: 16px auto;
      background: #ffffff;
      border-radius: 10px;
      padding: 16px;
      box-sizing: border-box;
      .title {
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .festival-item {
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        .date {
          .g {
            font-size: 20px;
            color: #f87a1b;
            margin-bottom: 8px;
            span {
              line-height: 30px;
            }
            button {
              height: 30px;
              padding: 0 8px;
              color: rgba(0, 0, 0, 0.65);
              box-sizing: border-box;
              border-radius: 10px 10px 10px 10px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              background: #fff;
              font-size: 14px;
            }
          }
          .n {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
          }
        }
        .detail {
          margin-top: 18px;
          .info {
            .tag {
              width: 14px;
              margin-right: 8px;
              height: 14px;
            }

            .name {
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
            }
          }
          .right-arrow {
            width: 16px;
            height: 16px;
          }
        }
      }
      .festival-item:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  .remindPop {
    padding: 28px 10px 20px;
    .remindTitle {
      height: 26px;
      line-height: 26px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.85);
    }
    .remindSub {
      font-weight: 400;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
      margin: 8px 0 19px;
      span {
        height: 20px;
        line-height: 20px;
      }
    }
    .remindDate {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 6px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 14px;
    }
    .remindLunar {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 20px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 14px;
    }
    .remindSwitch {
      width: 275px;
      padding: 11px 12px;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      background: #fcf5ec;
      border-radius: 10px 10px 10px 10px;
      margin-bottom: 20px;
      span {
        height: 16px;
        line-height: 16px;
      }
      .mb10 {
        margin-bottom: 10px;
      }
    }
    .popHandle {
      .van-button:first-child {
        margin-right: 25px;
      }
      .van-button {
        width: 100px;
        height: 40px;
      }
    }
  }
}
</style>
