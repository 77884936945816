var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c("div", { staticClass: "date" }, [_vm._v(" " + _vm._s(_vm.date) + " ")]),
    _c("div", {
      staticClass: "content",
      domProps: { innerHTML: _vm._s(_vm.content) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }