<template>
  <div class="page-box f fv" v-if="detail">
    <img src="https://img-fe.tengzhihh.com/image/c7cfa932707506-18x35.png" class="back" @click="back" />

    <img :src="calcBg" class="bg" />

    <div class="content-box">
      <img src="https://img-fe.tengzhihh.com/image/1353f85b5bf45a-672x950.png" class="content-bg" />
      <div class="title-box">
        <img src="https://img-fe.tengzhihh.com/image/3015fb81a1713d-269x58.png" class="title-bg" />
        <div>{{ detail.name }}介绍</div>
      </div>
      <p v-html="detail.introduction"></p>
    </div>

    <div class="confirm-btn" @click="start">开始功课</div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
export default {
  props: {
    lessonDetailId: {
      type: Number,
      default: 0,
    },
    serialId: {
      type: String,
      default: '',
    },
    indexMode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      detail: null,
    };
  },
  computed: {
    calcBg: function () {
      return this.indexMode === 'western'
        ? 'https://img-fe.tengzhihh.com/image/3a6c8e3a95d49b-750x1496.png'
        : 'https://img-fe.tengzhihh.com/image/c243534c673235-750x1500.png';
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$http
        .get(apiPath.lessonDetail + `/${this.lessonDetailId}`, {
          serial_id: this.serialId,
        })
        .then((res) => {
          this.detail = res.data;
        });
    },

    back() {
      this.$emit('setValue', 'introduceFlag', false);
    },
    start() {
      this.$router.push(
        `/music/musicIndex?from=lesson&serial_id=${this.serialId}&lesson_id=${this.lessonDetailId}&lesson_category_id=${this.detail.category_id}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1111;
  width: 100%;
  height: calc(var(--vh) * 100);
  padding: 20px;
  .back {
    width: 9px;
    height: 17px;
    margin-bottom: 20px;
  }
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }
  .title-box {
    position: relative;
    color: #7b5439;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 50px;
    .title-bg {
      position: absolute;
      left: 50%;
      top: -4px;
      margin-left: -67.5px;
      width: 135px;
      height: 30px;
    }
  }
  .content-box {
    position: relative;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    padding: 30px 20px;
    width: 336px;
    height: 475px;
    box-sizing: border-box;
    white-space: pre-wrap;
    .content-bg {
      width: 336px;
      height: 475px;
      position: absolute;
      left: 0;
      top: 0;
    }
    p {
      margin-bottom: 30px;
    }
  }
  .confirm-btn {
    width: 299px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    color: #fff;
    margin: 20px auto 0;
  }
}
</style>
