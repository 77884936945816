var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "f fv fc page-box" },
    [
      _c("div", { staticClass: "success-tip" }, [_vm._v("设备添加成功")]),
      _c("div", { staticClass: "info f fv fc" }, [
        _c("div", { staticClass: "img-box" }, [
          _vm.image
            ? _c("img", {
                staticClass: "device-img",
                attrs: { src: _vm.image },
              })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "tip" }, [
          _vm._v(" " + _vm._s(_vm.name || "神台机") + " "),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "btn-box f fv fc" },
        [
          _c(
            "van-button",
            { staticClass: "add-btn", on: { click: _vm.manageDevice } },
            [_vm._v("开始管理")]
          ),
          _c("div", { staticClass: "re-btn", on: { click: _vm.addDevice } }, [
            _vm._v("继续添加"),
          ]),
        ],
        1
      ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }