<template>
  <div class="flex">
    <div v-for="{ name, label } in list" :key="name" class="box" @click="clickFn(name)">
      <div class="label">{{ label }}</div>
      <div class="block" v-show="active === name"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: [String, Number],
      default: '',
    },
  },
  methods: {
    clickFn(name) {
      this.$emit('click', name);
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.box {
  margin: 0 10px;
  .label {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
  }
  .block {
    width: 28px;
    height: 2px;
    background: #f87a1b;
    margin: 0 auto;
  }
}
</style>
