<template>
  <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for="(item, index) in dataList" :key="index">
      <div class="swiper-item" @click="callback(item.cb)">
        <img :src="item.img" class="item-img" />
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // https://v1.github.surmon.me/vue-awesome-swiper
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        centeredSlides: true,
        slidesPerGroup: 1,
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    };
  },
  methods: {
    callback(cb) {
      typeof cb === 'function' && cb();
    },
  },
};
</script>

<style lang="less" scoped>
.swiper {
  padding: 16px 0;
}
.swiper-item {
  border-radius: 10px;
  width: 300px;
  height: 176px;
  overflow: hidden;
  .item-img {
    width: 300px;
    height: 176px;
  }
}
.swiper-slide {
  width: 80%;
}
.swiper-slide:nth-child(2n) {
  width: 80%;
}
.swiper-slide:nth-child(3n) {
  width: 80%;
}
</style>
