<template>
  <van-popup v-model="show">
    <div class="box" v-if="show && currentData">
      <img class="logo" src="https://fe-img.tengzhihh.com/image/ece52fb90ebe5345c114c50e25f9ec27-132x154.png" alt="" />
      <div class="title">{{ currentData.title }}</div>
      <div class="text">{{ currentData.desc }}</div>
      <div class="btn" @click="handleClick">{{ currentData.button }}</div>
    </div>
  </van-popup>
</template>

<script>
import apiPath from '@/utils/apiPath';

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
      type: '',
    };
  },
  computed: {
    currentData() {
      return this.list.find(item => item.type == this.type);
    },
  },
  methods: {
    open(type) {
      this.type = type;
      this.show = true;
    },
    handleClick() {
      let that = this;
      this.$toast('正在跳转...');
      const current = this.$store.state.userMachinesList[0];
      let map = {
        1: () => {
          this.$router.push('/shop/list');
        },
        2: async () => {
          await updateUserMachinesList();
          if (isOffline()) return;
          if (!isMaster()) return;
          const id = this.$store.state.userMachinesList[0].serial_id;
          this.$router.push(`/settings/vipTools?serial_id=${id}`);
        },
        3: async () => {
          await updateUserMachinesList();
          if (isOffline()) return;
          const modeMap = {
            type: {
              deceased: 3,
              earth_god: 2,
            },
            route: {
              deceased: '/deceased/enshrineList',
            },
          };
          const id = this.$store.state.userMachinesList[0].serial_id;
          const route = '/device/index';
          this.$router.push(`${route}?serial_id=${id}`);
        },
        4: async () => {
          await updateUserMachinesList();
          if (isOffline()) return;
          const id = this.$store.state.userMachinesList[0].serial_id;
          this.$router.push(`/music/musicIndex?serial_id=${id}`);
        },
      };
      map[this.type] && map[this.type]();

      function isOffline() {
        if (current.device_status === 0) {
          that.$toast('当前设备离线');
          return true;
        } else {
          return false;
        }
      }

      async function updateUserMachinesList() {
        await that.$http.get(apiPath.getUserDevices).then(res => {
          that.$store.commit('setUserMachinesList', res.data.list);
        });
      }

      function isMaster() {
        if (current.is_master == 1) {
          return true;
        } else {
          that.$toast('仅VPI主账可配置自动供奉');
          return false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background: transparent;
}
.box {
  margin-top: 40px;
  position: relative;
  width: 295px;
  padding: 20px;
  background: linear-gradient(180deg, #fff9e8 0%, #ffebb8 100%);
  border-radius: 15px;
  text-align: center;
  box-sizing: border-box;

  .logo {
    position: absolute;
    top: 0;
    left: 50%;
    width: 66px;
    height: 77px;
    transform: translate(-50%, -50%);
  }

  .title {
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 500;
    font-size: 24px;
    color: #7b5439;
    padding-top: 34px;
  }
  .text {
    width: 255px;
    display: inline-block;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-weight: 400;
    font-size: 16px;
    color: #7b5439;
    line-height: 21px;
    padding-top: 30px;
    text-align: left;
    letter-spacing: 2px;
  }
  .btn {
    margin-top: 40px;
    display: inline-block;
    width: 255px;
    height: 40px;
    background: #df9738;
    border-radius: 10px 10px 10px 10px;

    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 40px;
  }
}
</style>
