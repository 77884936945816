<template>
  <div class="festivalCalendar f fvc">
    <div class="txt today" @click="goCalendar">{{ today }}</div>
    <div class="list">
      <van-swipe style="height: 31px" autoplay="3600" :show-indicators="false" :touchable="false" vertical>
        <van-swipe-item v-for="item in list" :key="item.id" @click="festivalFn(item)">
          <div class="item f fvc" v-if="item.left_day === 0">
            <span class="txt">今天是</span>
            <van-icon :name="item.type === 2 ? foTag : daoTag" size="16px" class="icon" />
            <span :class="['txt', item.type === 2 ? 'foTxt' : 'daoTxt']">{{ item.name }}</span>
            <van-icon name="https://img-fe.tengzhihh.com/image/bac6c76bd18ad4-16x16.png" size="16px" class="icon" />
          </div>
          <div class="item f fvc" v-else>
            <span class="txt">距</span>
            <van-icon :name="item.type === 2 ? foTag : daoTag" size="16px" class="icon" />
            <span :class="['txt', item.type === 2 ? 'foTxt' : 'daoTxt']">{{ item.name }}</span>
            <span class="txt">还有</span>
            <span class="txt light-txt">{{ item.left_day }}</span>
            <span class="txt">天</span>
            <van-icon name="https://img-fe.tengzhihh.com/image/bac6c76bd18ad4-16x16.png" size="16px" class="icon" />
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  computed: {
    foTag() {
      return 'https://img-fe.tengzhihh.com/image/7bb2a9d2c0513f-32x32.png';
    },
    daoTag() {
      return 'https://img-fe.tengzhihh.com/image/d045b0f0d86678-32x32.png';
    },
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      latelyFestival: [],
      today: dayjs().format('MM/DD'),
    };
  },
  created() {},
  methods: {
    goCalendar(params = {}) {
      this.$track('H5_shouye_rili_click');
      this.$router.push({ name: 'calendar', query: this.$route.query, params });
    },
    festivalFn(item) {
      const date = dayjs().add(item.left_day, 'day').format('YYYYMMDD');
      this.goCalendar({ date });
    },
  },
};
</script>

<style lang="less" scoped>
.festivalCalendar {
  padding: 0 8px;
  height: 31px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 21px 21px 21px 21px;
  .txt {
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.65);
  }
  .today {
    height: 16px;
    line-height: 16px;
    border-right: 1px solid #000;
    padding-right: 6px;
    margin-right: 6px;
  }
  .foTxt {
    color: #2163d6;
    margin: 0 2px;
  }
  .daoTxt {
    color: #4cda63;
    margin: 0 2px;
  }
  .list {
    height: 31px;
    min-width: 190px;
    overflow: hidden;
    .item {
      height: 31px;
      .icon {
        margin-left: 2px;
      }
    }
  }
}
</style>
