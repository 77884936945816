var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-box" }, [
    _c("div", { staticClass: "banner-box", on: { click: _vm.jump } }, [
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/5ce2ad28b9ee7c-624x352.png",
        },
      }),
      _c("img", {
        staticClass: "bg",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/0f8cccab051a34-624x156.png",
        },
      }),
      _c("div", { staticClass: "name" }, [_vm._v("广宁-紫竹观")]),
    ]),
    _c("div", { staticClass: "news-box" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "news-list" },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "news-item f fc",
              on: {
                click: function ($event) {
                  return _vm.view(item.link)
                },
              },
            },
            [
              _c("div", { staticClass: "left" }, [
                _c("img", {
                  staticClass: "sub",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/e9faf356cd85dd-92x34.png",
                  },
                }),
                _c("img", { staticClass: "cover", attrs: { src: item.cover } }),
              ]),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "news-title f fc" }, [
      _c("div", { staticClass: "point" }),
      _c("div", [_vm._v("最新活动")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }