var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c(
      "div",
      { staticClass: "list-box" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "msg-item f fc",
            on: {
              click: function ($event) {
                return _vm.viewDetail(item)
              },
            },
          },
          [
            _c("div", {
              class: `point ${
                item.unread_message_count !== 0 ? "red-point" : ""
              }`,
            }),
            _c("div", { staticClass: "item-img" }, [
              _c("img", { staticClass: "img", attrs: { src: item.image } }),
            ]),
            _c("div", { staticClass: "item-info fv fbt" }, [
              _c("div", { staticClass: "item-data f fbt" }, [
                _c("div", { staticClass: "item-name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c("div", { staticClass: "msg-date" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        (item.last_message && item.last_message.created_at) ||
                          " "
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "bottom f fc fbt" },
                [
                  _c("div", { staticClass: "msg-content clamp1" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          (item.last_message && item.last_message.content) ||
                            "暂无新消息"
                        ) +
                        " "
                    ),
                  ]),
                  _c("van-icon", {
                    staticClass: "right-arrow-icon f fc",
                    staticStyle: { height: "100%" },
                    attrs: { name: "arrow" },
                  }),
                ],
                1
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }