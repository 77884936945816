<template>
  <tabs :list="list" :active="active" @click="tabClick"></tabs>
</template>

<script>
import tabs from '@/components/Tabs';
import { orderTabs } from '@/enum/shop.js';
export default {
  components: {
    tabs,
  },
  data() {
    return {
      active: 'allorder',
      list: Object.freeze(orderTabs),
    };
  },
  created() {
    this.active = this.$route.name;
  },
  methods: {
    tabClick(name) {
      this.$router.replace(`/shop/${name}`);
    },
  },
};
</script>

<style></style>
