<template>
  <div class="detail-box f fv fc" id="detail-box" v-if="godDetail">
    <div class="img-box">
      <img :src="godDetail.wap_detail_image" class="detail-img" @click="showVideo" />
      <img src="https://img-fe.tengzhihh.com/image/f8cb483ff0ece9-609x69.png" class="shadow" />
    </div>
    <div class="content">
      <div v-if="godDetail.moral">{{ godDetail.moral }}</div>
      <div v-if="godDetail.unique_number" style="margin-bottom: 20px">
        数字藏品唯一ID：{{ godDetail.unique_number }}
      </div>

      {{ godDetail.introduce }}
    </div>

    <div v-if="godDetail.expire_time" style="margin-top: 20px">供奉时间至：{{ calcTime(godDetail.expire_time) }}</div>

    <div class="back-btn" @click="confirm">确认</div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import dayjs from 'dayjs';

export default {
  props: {
    serialId: {
      type: String,
      default: '',
    },
    godType: {
      type: [Number, String],
      default: 1,
    },
    godId: {
      type: [Number, String],
      default: 0,
    },
    from: {
      type: String,
      default: 'hua',
    },
    godDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      detail: {
        wap_main_image: '',
        content: '',
      },
    };
  },
  async mounted() {
    this.showVideo();
  },
  methods: {
    confirm() {
      this.$emit('setValue', 'pageStep', 'list');
    },
    showVideo() {
      if (!this.godDetail.video_url) {
        return;
      }
      const dom = document.createElement('video');
      dom.setAttribute('src', this.godDetail.video_url);
      dom.setAttribute('id', 'video-preview');
      dom.setAttribute('controls', true);
      dom.setAttribute('muted', true);
      dom.setAttribute('autoplay', true);
      dom.onended = function () {
        document.getElementById('video-preview') && document.getElementById('video-preview').remove();
      };
      document.getElementById('detail-box').appendChild(dom);
      dom.play();
    },
    calcTime(t) {
      return dayjs(t * 1000).format('YYYY-MM-DD');
    },
  },
};
</script>
<style lang="less">
#video-preview {
  width: 100vw !important;
  height: calc(var(--vh) * 100) !important;
  z-index: 3000;
  position: fixed;
  left: 0;
  top: 0;
  background: black;
}
</style>
<style lang="less" scoped>
.detail-box {
  background: #fcf5ec;
  height: calc(var(--vh) * 100);
  padding-bottom: 20px;
  .img-box {
    position: relative;

    .detail-img {
      width: 104px;
    }
    .shadow {
      position: absolute;
      left: 50%;
      bottom: -10px;
      margin-left: -152px;
      width: 305px;
      height: 35px;
    }
  }
  .content {
    margin-top: 10px;
    width: 340px;
    padding: 20px;
    background: #fff;
    color: #4b4b4b;
    box-sizing: border-box;
    border-radius: 15px;
    text-align: justify;
    line-height: 26px;
    font-size: 14px;
  }
  .btn {
    width: 176px;
    height: 43px;
    background: #f87a1b;
    border-radius: 15px;
    line-height: 43px;
    text-align: center;
    font-size: 16px;
    color: #ffeac9;
    margin-top: 16px;
  }
  .dialog-box {
    width: 295px;
    padding: 0 0 20px;
    background: #fff;
    border-radius: 15px;
    .god-item-img {
      height: 166px;
    }
    .name {
      font-size: 14px;
      color: #7b5439;
      margin-top: 10px;
    }

    .tip {
      font-size: 18px;
      color: #2e2d2d;
      margin-top: 10px;
    }

    .btn-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 22px 0 0;
      .cancel-btn {
        width: 100px;
        height: 40px;
        background: #f6f6f6;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 10px;
      }

      .confirm-btn {
        width: 100px;
        height: 40px;
        background: #f87a1b;
        border-radius: 10px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #ffeac9;
      }
    }
    .offering-select {
      .cancel-text {
        font-size: 16px;
        color: #f87a1b;
      }
      .confirm-btn {
        width: 228px;
        margin-bottom: 8px;
      }
    }
  }
  .back-btn {
    width: 299px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    color: #fff;
    margin-top: 20px;
  }
}
</style>
