<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>
    <van-button @click="save" :loading="loading" class="add-btn fixed" type="primary">保存</van-button>

    <date-picker
      :show="datePickerFlag"
      pickerType="time"
      @onCancel="onCancel"
      @onConfirm="onConfirmDatePicker"
    ></date-picker>
    <check-group
      :showFlag="checkGroupFlag"
      :list="groupArr"
      :selectedResult="selectedResult"
      @onConfirm="onConfirmCheckGroup"
      @onCancel="onCancel"
    ></check-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
import CheckGroup from '@/components/CheckGroup/CheckGroup.vue';
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import CellGroup from '@/components/CellGroup/CellGroup.vue';
const groupArr = ['每周一', '每周二', '每周三', '每周四', '每周五', '每周六', '每周日'];
export default {
  components: { CellGroup, DatePicker, CheckGroup },
  data() {
    return {
      loading: false,
      groupArr: groupArr.map((i, idx) => {
        return { label: i, id: idx };
      }),
      serialId: '',
      from: 'add',
      timeSet: {
        time: '点击选择',
        cycle: '[0,0,0,0,0,0,0]',
      },
      datePickerFlag: false,
      checkGroupFlag: false,
    };
  },
  computed: {
    settingList: function () {
      return [
        {
          title: '时间',
          label: this.timeSet?.time,
          isLink: true,
          cb: () => {
            this.datePickerFlag = true;
          },
        },
        {
          title: '重复',
          label: this.format(this.timeSet?.cycle),
          isLink: true,
          cb: () => {
            this.checkGroupFlag = true;
          },
        },
      ];
    },
    selectedResult: function () {
      const arr = JSON.parse(this.timeSet.cycle);

      let tmp = arr
        .map((i, idx) => {
          return {
            label: groupArr[idx],
            id: idx,
            flag: i == 1,
          };
        })
        .filter((i) => i.flag)
        .map((i) => i.id);
      return tmp || [];
    },
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.from = this.$route.query.from;
    this.getTimingInfo(this.$route.query.id);
  },
  methods: {
    format(arr) {
      arr = JSON.parse(arr);
      if (typeof arr == 'string') {
        arr = arr.split(',');
      }
      let str = '';
      let tmp = Array.from(new Set(arr));
      if (tmp.length == 1) {
        if (tmp[0] == 0) {
          str = '永不';
        } else if (tmp[0] == 1) {
          str = '每天';
        }
      } else {
        let tmpArr = [];
        arr.forEach((i, idx) => {
          if (i == 1) {
            tmpArr.push(groupArr[idx]);
          }
        });
        str = tmpArr.join();
      }
      return str;
    },
    getTimingInfo(id) {
      if (id) {
        this.$http
          .get(apiPath.timingInfo, {
            id,
          })
          .then((res) => {
            this.timeSet = res.data;
          });
      }
    },
    save() {
      if (this.timeSet.time == '点击选择') {
        return this.$toast('请先选择时间');
      }
      this.loading = true;
      const formData = new FormData();
      formData.append('serial_id', this.serialId);
      formData.append('status', 'open');
      formData.append('time', this.timeSet.time);
      formData.append('cycle', this.timeSet.cycle);
      if (this.from === 'add') {
        this.saveNew(formData);
      } else {
        formData.append('id', this.timeSet.id);
        this.saveEdit(formData);
      }
    },
    saveNew(formData) {
      this.$http.formPost(apiPath.addTiming, formData).then(() => {
        this.loading = false;
        this.$toast('创建成功');
        this.$track('setting_xiangxun_new_time_success');
        this.$router.replace(`/settings/timingList?serial_id=${this.serialId}`);
      });
    },
    saveEdit(formData) {
      this.$http.formPost(`${apiPath.incenseTiming}?serial_id=${this.serialId}`, formData).then(() => {
        this.$toast('修改成功');
        this.loading = false;
        this.$router.replace(`/settings/timingList?serial_id=${this.serialId}`);
      });
    },
    onCancel() {
      this.datePickerFlag = false;
      this.checkGroupFlag = false;
    },
    onConfirmDatePicker(e) {
      this.timeSet.time = e;
    },
    onConfirmCheckGroup(result) {
      let tmp = this.timeSet.cycle.split(',');
      tmp = tmp.map((i) => 0);
      result.forEach((i) => {
        tmp[i] = 1;
      });
      this.timeSet.cycle = JSON.stringify(tmp);
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
  .add-btn {
    width: 330px;
    height: 43px;
    background: #f87a1b;
    border-radius: 15px;
    font-size: 15px;
    font-weight: bold;
    color: #fff4e4;
    border: none;
  }
  .fixed {
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: -165px;
  }
}
</style>
