<template>
  <div v-if="!loading">
    <div class="page-box">
      <img :src="calcBg" class="bg" />

      <div class="top f fc fbt">
        <img src="https://img-fe.tengzhihh.com/image/c7cfa932707506-18x35.png" class="back" @click="goDevice" />
        <div class="btns f fc">
          <div class="btn" @click="$utils.checkAuth(routeQuery.serial_id, newLesson)">新建功课</div>
          <div class="btn" @click="openEdit">{{ editing ? '取消管理' : '管理' }}</div>
        </div>
      </div>

      <div class="list-box f fbt flex-wrap">
        <div
          :class="`item f fc fvc ${indexMode === 'western' ? 'western-item' : ''}`"
          v-for="(item, index) in list"
          :key="item.id"
          @click="viewDetail(item)"
        >
          <img :src="calcCover(item.category_id, index)" class="item-bg" />
          <div class="name">{{ sliceName(item.name) }}</div>
          <div class="edit" v-if="editing && item.category_id === 4 && startedLessonId != item.id">
            <img
              src="https://img-fe.tengzhihh.com/image/a4059a290d41b4-36x36.png"
              class="edit-icon"
              v-if="hasSelected(item.id)"
            />
            <img src="https://img-fe.tengzhihh.com/image/4407cea6dacfab-36x36.png" class="edit-icon" v-else />
          </div>
        </div>
        <i v-for="item in 3 - (list.length % 3)" style="width: 90px"></i>
      </div>
      <div class="playing-box f fc fbt" v-if="!editing && isLessonStarted">
        <div class="f fc" @click="goPlaying">
          <img src="https://img-fe.tengzhihh.com/image/fb2f93f37e1ddf-36x36.png" class="icon1" />
          <div class="name">{{ playingName }}</div>
        </div>
        <div class="f fc">
          <img v-show="isPlaying" src="https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif" class="icon2" />
          <div class="exit-btn" @click="exit">退出</div>
        </div>
      </div>
      <div class="del-btn" @click="confirmDel" v-if="editing">删除</div>
    </div>
    <Introduce
      v-if="introduceFlag"
      :lessonDetailId="lessonDetailId"
      :indexMode="indexMode"
      :serialId="routeQuery.serial_id"
      @setValue="setValue"
    />
    <Dialog ref="dialog" />
  </div>
  <van-overlay :show="true" v-else>
    <van-loading size="24px" color="#dcdcdc" vertical>加载中...</van-loading>
  </van-overlay>
</template>

<script>
import apiPath from '@/utils/apiPath';
import tools from '@/utils/tools';
import Dialog from '@/components/Dialog/Dialog.vue';

import Introduce from './comp/Lesson/Introduce.vue';
import sendMsg from '@/webSocket/sendMsg';

import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  components: {
    Introduce,
    Dialog,
  },
  data() {
    return {
      tools,
      list: [],
      editing: false,
      volumeStr: '',
      currentVolume: 0,
      maxVolume: 0,
      subVolume: 0,
      selectedArr: [],
      introduceFlag: false,
      lessonDetailId: 0,
      startedLessonId: '',
      startedLessonCategoryId: 0,
      isLessonStarted: false,
      loading: false,
      indexMode: '',
      isPlaying: false,
    };
  },
  computed: {
    playingName: function () {
      return this.isLessonStarted && this.startedLessonId
        ? this.list.find(i => i.id == this.startedLessonId)?.name.slice(0, 5)
        : '';
    },
    calcBg: function () {
      return this.indexMode === 'western'
        ? 'https://img-fe.tengzhihh.com/image/92e07944714e16-750x1496.png'
        : 'https://img-fe.tengzhihh.com/image/4e0e73a30570bb-750x1496.png';
    },
  },
  mounted() {
    this.loading = true;
    this.getLesson();
  },
  methods: {
    sliceName(n) {
      return (n || '').slice(0, 5);
    },
    exit() {
      this.showConfirm(() => {
        this.confirmExit();
      });
    },
    confirmExit() {
      this.$track('interrupt_gongke');
      sendMsg('player', {
        operation: 'stop',
        playerType: 'classes',
      });
    },
    calcCover(category_id, index) {
      if (this.indexMode === 'western') {
        if ([4].includes(category_id)) {
          return 'https://img-fe.tengzhihh.com/image/880531a366174a-180x288.png';
        } else {
          return index % 3 == 0
            ? 'https://img-fe.tengzhihh.com/image/fba136b755691a-180x288.png'
            : index % 3 === 1
            ? 'https://img-fe.tengzhihh.com/image/15ca8fe2a7e732-180x288.png'
            : 'https://img-fe.tengzhihh.com/image/82805e13ea57bf-180x288.png';
        }
      } else {
        if ([1, 2, 3].includes(category_id)) {
          return 'https://img-fe.tengzhihh.com/image/ed34e008ac140c-180x288.png';
        } else {
          return index % 3 == 0
            ? 'https://img-fe.tengzhihh.com/image/9ea483138a365f-180x288.png'
            : index % 3 === 1
            ? 'https://img-fe.tengzhihh.com/image/a0b2624ce41bed-184x290.png'
            : 'https://img-fe.tengzhihh.com/image/a6a659129030c0-186x288.png';
        }
      }
    },

    getSystemInfo() {
      sendMsg('player', {
        operation: 'playerStatus',
      });
    },
    handleMsg(msg) {
      const action = msg.action;
      const operation = msg.data.operation;

      const map = {
        player: {
          cb: () => {},
          operation: {
            playerStatus: () => {
              this.updatePlayerStatus(msg);
            },
          },
        },
      };

      const actionCb = map[action]?.cb;
      const operationCb = map[action] && map[action]['operation'] && map[action]['operation'][operation];
      typeof actionCb === 'function' && actionCb();
      typeof operationCb === 'function' && operationCb();

      this.loading = false;
    },
    updatePlayerStatus(msg) {
      this.isLessonStarted = msg.data.isStarted && msg.data.playerType === 'classes';
      this.isPlaying = msg.data.isPlaying;
      if (msg.data.isStarted) {
        this.startedLessonId = msg.data.classesId;
        this.startedLessonCategoryId = this.list.find(i => i.id == this.startedLessonId)?.category_id;
      } else {
        this.startedLessonId = '';
        this.startedLessonCategoryId = '';
      }
    },
    newLesson() {
      this.$router.push(
        `/music/lessonEdit?serial_id=${this.routeQuery.serial_id}&edit_mode=new&page_step=cus_edit&index_mode=${this.indexMode}`
      );
    },
    confirmDel() {
      if (this.selectedArr.length === 0) {
        return this.$toast('请先选择删除内容');
      }
      this.$dialog({
        message: `确认删除已选内容吗`,
        title: '提示',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http
          .post(apiPath.batchDeleteLesson, {
            curriculum_id: this.selectedArr.join(','),
            serial_id: this.routeQuery.serial_id,
          })
          .then(() => {
            this.$track('delete_gongke_success');
            this.$toast('删除成功');
            this.getLesson();
          })
          .finally(() => {
            this.selectedArr = [];
          });
      });
    },
    setValue(k, v) {
      this[k] = v;
    },
    goDevice() {
      this.$router.push(`/device/index?serial_id=${this.routeQuery.serial_id}`);
    },
    openEdit() {
      this.editing = !this.editing;
      !this.editing && (this.selectedArr = []);
    },

    hasSelected(id) {
      return this.selectedArr.includes(id);
    },

    viewDetail(item) {
      if (!this.editing) {
        this.handleClick(item);
      } else {
        this.handleEditing(item);
      }
    },
    startLesson(item) {
      if (item.category_id === 4) {
        this.$router.push(
          `/music/musicIndex?from=lesson&serial_id=${this.routeQuery.serial_id}&lesson_id=${item.id}&lesson_category_id=${item.category_id}`
        );
      } else {
        this.lessonDetailId = item.id;
        this.introduceFlag = true;
      }
    },
    goPlaying() {
      if (!this.startedLessonCategoryId || !this.startedLessonId) {
        return false;
      }
      this.$router.push(
        `/music/musicIndex?from=lesson&serial_id=${this.routeQuery.serial_id}&lesson_id=${this.startedLessonId}&lesson_category_id=${this.startedLessonCategoryId}`
      );
    },
    handleClick(item) {
      if (this.isLessonStarted) {
        if (this.startedLessonId == item.id) {
          this.goPlaying();
        } else {
          this.showConfirm(() => {
            this.confirmExit();
          });
        }
      } else {
        this.startLesson(item);
      }
    },
    showConfirm(cb) {
      this.$refs.dialog.initDialog({
        title: '提示',
        content: `神台机正在进行功课，是否先退出？`,
        confirmText: '确认',
        cancelText: '取消',
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
          typeof cb === 'function' && cb();
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    handleEditing(item) {
      if (item.id == this.startedLessonId) return;
      const index = this.selectedArr.findIndex(i => i === item.id);
      if (index !== -1) {
        this.selectedArr.splice(index, 1);
      } else {
        this.selectedArr.push(item.id);
      }
    },
    getLesson() {
      this.$http
        .get(apiPath.lessonList, {
          serial_id: this.routeQuery.serial_id,
          size: 100,
        })
        .then(res => {
          this.list = res.data.list;
          this.indexMode = res.data.index_mode;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  width: 375px;
  height: calc(var(--vh) * 100);
  position: absolute;

  box-sizing: border-box;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }

  .top {
    padding: 20px;
    .back {
      width: 9px;
      height: 17px;
    }
    .btns {
      font-size: 16px;
      font-weight: bold;
      color: #7b5439;
      .btn {
        margin-left: 24px;
      }
    }
  }
  .list-box {
    padding: 0 30px;
    max-height: calc(var(--vh) * 100 - 110px);
    overflow: auto;
    .item {
      position: relative;
      width: 90px;
      height: 144px;
      margin-bottom: 30px;
      .item-bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 90px;
        height: 144px;
      }
      .name {
        position: relative;
        writing-mode: vertical-lr;
        text-orientation: upright;
        font-size: 14px;
        font-weight: bold;
        color: #7b5439;
        left: -5px;
        top: 0;
        letter-spacing: 2px;
      }
      .edit {
        position: absolute;
        left: 0;
        top: 0;
        .edit-icon {
          width: 18px;
          height: 18px;
        }
      }
    }
    .western-item {
      .name {
        left: 0px;
        color: #fbaf76;
      }
      .edit {
        top: 10px;
      }
    }
  }
  .playing-box {
    width: 240px;
    height: 46px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 21px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -120px;
    padding: 0 14px;
    .name {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    .icon1 {
      width: 18px;
      height: 18px;
      margin-right: 4px;
    }
    .icon2 {
      width: 14px;
      height: 14px;
    }
    .exit-btn {
      width: 70px;
      height: 24px;
      line-height: 24px;
      background: #f87a1b;
      border-radius: 20px;
      font-size: 14px;
      color: #ffffff;
      margin-left: 4px;
      text-align: center;
    }
  }
  .del-btn {
    width: 300px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px;
    text-align: center;
    line-height: 44px;
    font-size: 16px;
    color: #fff;
    position: fixed;
    bottom: 20px;
    left: 50%;
    margin-left: -150px;
  }
}
</style>
