<template>
  <van-popup
    v-model="showFlag"
    position="bottom"
    get-container="body"
    class="offerings-timeout"
    @click-overlay="onCancel"
  >
    <div class="popup-box">
      <div class="arrow f fc fv" @click.stop.prevent="onCancel">
        <img src="https://img-fe.tengzhihh.com/image/7c67e9737668df-80x80.png" class="arrow-img" />
      </div>
      <div class="content-box">
        <div class="title">供品说明</div>
        <div class="desc">
          供品将在<span class="time">{{ time }}</span
          >后送予{{ offerTarget }}，届时再可以重新上供品。
        </div>
        <div class="content f fc fbt">
          <img v-for="item in offerings" :key="item.image" :src="item.offer_image" class="offers-item" />
        </div>
        <div class="autoOfferings" @click="autoOfferingsFn">修改 “一键供奉” 设置</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    offerings: {
      type: Array,
      default: [],
    },
    time: {
      type: String,
      default: '',
    },
    offerTarget: {
      type: String,
      default: '神/佛',
    },
  },
  data() {
    return {
      showFlag: false,
    };
  },
  methods: {
    show() {
      this.showFlag = true;
    },
    onCancel() {
      this.showFlag = false;
    },
    autoOfferingsFn() {
      this.$router.push({
        name: 'enshrineSetting',
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.offerings-timeout {
  box-sizing: border-box;
  width: 343px;
  left: 50%;
  margin-left: -171.5px;
  bottom: 20px;
  background: unset;
}
.popup-box {
  .arrow {
    .arrow-img {
      width: 40px;
      height: 40px;
    }
  }
  .content-box {
    border-radius: 20px;
    background: #fff;
    padding: 20px;
    .title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .desc {
      line-height: 1.3;
      font-size: 14px;
      .time {
        color: #f87a1b;
      }
    }
    .content {
      height: 135px;
      background: #fcf5ec;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 12px;
      .offers-item {
        width: 68px;
      }
    }
    .autoOfferings {
      text-align: center;
      font-size: 14px;
      color: #f87a1b;
      line-height: 20px;
    }
  }
}
</style>
