<template>
  <div class="container f fv fc" id="god-index-explanation" @scroll="scrollBottomCheck">
    <GoBack backIcon="https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png" :backFunction="backFunction" />

    <div class="list">
      <div class="item">
        <div class="title">请神说明</div>
        <div class="content">
          请神像或者佛像回家需知，宜摆放于静室高处，大厅或者书房，不可面对厕所、炉灶和卧床。佛像背后不可临窗，应当面对门窗，光线充足，使人进门便一目了然。如果家中地方有限，可以先妥善存放于清净处，做功课时再请出来。
        </div>
      </div>

      <div class="item">
        <div class="title">供品说明</div>
        <div class="content">"供奉"是指针对当前页面供奉的神明，分别进行请香、献花、供果、供灯动作。</div>
        <div class="content">
          <br />
          <br />
          "一键供奉"是指一键点击为所有正在供奉的神明上供品。方便缘主一键完成上供香、花、果、灯给所有神明的操作。一键供奉不会替换当前正在供奉的贡品。
        </div>
        <img src="https://img-fe.tengzhihh.com/image/5c4959e22ea868c5fef4729770dff981-622x758.png" class="img" />
      </div>
      <div class="item">
        <div class="title">“供奉”与“一键供奉”说明</div>
        <div class="content">供品会在请香，上香12小时后送予神/佛，届时可重新上新供品。</div>
        <img src="https://img-fe.tengzhihh.com/image/3636426656a562-622x724.png" class="img" />
      </div>

      <div class="item">
        <div class="title">送神回天</div>
        <div class="content">
          在送走的前一天要焚香上供，诚心的说明送走的原因。确定送神上天后，意味着你不再向此神明祈愿供奉，将在24小时内无法上供品，24小时后自动送神上天，累计的供奉天数和香火值也会消失。
        </div>
        <img src="https://img-fe.tengzhihh.com/image/6f22ca9141d8c4-622x728.png" class="img" />
      </div>
    </div>
  </div>
</template>

<script>
import GoBack from '@/components/GoBack/GoBack.vue';

export default {
  components: {
    GoBack,
  },

  methods: {
    backFunction() {
      this.$emit('setValue', 'pageStep', 'index');
    },
    scrollBottomCheck() {
      const myElement = document.getElementById('god-index-explanation');
      if (this.$tools.isScrolledToBottom(myElement)) {
        this.$track('gongfeng_shuoming_bottom');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 375px;
  height: calc(var(--vh) * 100);
  overflow: scroll;
  z-index: 1111;
  background: #f8f8f8;
  padding-top: 70px;
  .list {
    padding-bottom: 20px;
  }
  .item {
    width: 343px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;

    .title {
      color: #f87a1b;
      margin-bottom: 16px;
    }
    .img {
      width: 311px;
      margin: 20px auto;
    }
  }
}
</style>
