var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "home"
    ? _c("img", {
        staticClass: "home",
        style: { left: _vm.homeLeft, top: _vm.homeTop },
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/c6c2d159d45d41-98x98.png",
        },
        on: { click: _vm.goBack },
      })
    : _vm.type === "str"
    ? _c("div", { staticClass: "str f fc", on: { click: _vm.goBack } }, [
        _c("img", {
          staticClass: "arrow",
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/108b40b5aba11a-32x32.png",
          },
        }),
        _vm._v(" " + _vm._s(_vm.backStr) + " "),
      ])
    : _c("img", {
        staticClass: "go-back-icon",
        attrs: { src: _vm.backIcon },
        on: { click: _vm.goBack },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }