var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c(
      "div",
      { staticClass: "list-box" },
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "msg-item f fc" },
          [
            _c("div", { staticClass: "item-img" }, [
              _c("img", { staticClass: "img", attrs: { src: item.image } }),
            ]),
            _c("div", { staticClass: "item-info fv fbt" }, [
              _c("div", { staticClass: "item-data f fbt" }, [
                _c("div", { staticClass: "item-name" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ]),
            ]),
            _c("van-switch", {
              attrs: {
                "active-color": "#f87a1b",
                "inactive-color": "#ffeac9",
                size: "20px",
                value: item.message_notify === "open",
              },
              on: {
                input: function ($event) {
                  return _vm.messageNotify($event, item)
                },
              },
            }),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }