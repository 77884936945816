<template>
  <div v-if="changeState === 1" class="f fv fvc">
    <div class="tip">当前手机号：{{ userInfo.phone }}</div>
    <van-field v-model="code" center clearable max-length="6" placeholder="请输入短信验证码">
      <template #button>
        <van-button size="small" @click="getCode">{{ codeSentStatus ? calcTime : '发送验证码' }}</van-button>
      </template>
    </van-field>
    <van-button @click="verifyCode" :loading="loading" class="next-btn" type="info">下一步</van-button>
  </div>
  <div v-else class="f fv fvc">
    <van-field v-model="phone" clearable center placeholder="请输入新的电话号码" maxlength="11" />
    <van-field v-model="code" center clearable placeholder="请输入短信验证码">
      <template #button>
        <van-button size="small" @click="getCode">{{ codeSentStatus ? calcTime : '发送验证码' }}</van-button>
      </template>
    </van-field>
    <van-button @click="checkPhone" :loading="loading" class="next-btn" type="info">下一步</van-button>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      changeState: 1,
      code: '',
      phone: '',
      codeSentStatus: false,
      calcTime: 60,
      interval: null,
      smsToken: '',
      loading: false,
    };
  },
  computed: {
    ...mapState(['userInfo']),
  },
  watch: {
    calcTime: function (n) {
      if (n <= 0) {
        this.resetCodeSent();
      }
    },
  },
  mounted() {
    this.$utils.checkToken(() => {}, this.$route.path);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    verifyCode() {
      if (!this.code) {
        return this.$toast('请先输入验证码');
      }
      this.loading = true;

      this.$http
        .post(apiPath.verifyCode, {
          code: this.code,
        })
        .then(res => {
          this.changeState = 2;
          this.resetCodeSent();
          this.code = '';
          this.smsToken = res.data.sms_token;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkPhone() {
      if (!this.phone) {
        return this.$toast('请先输入新的手机号');
      }
      if (!this.code) {
        return this.$toast('请先输入验证码');
      }
      this.loading = true;

      this.$http
        .post(apiPath.userPhone, {
          code: this.code,
          new_phone: this.phone,
          sms_token: this.smsToken,
        })
        .then(() => {
          this.$utils.getUserInfo();
          this.$toast({
            message: '修改成功',
          });
          this.$router.replace('/my/info');
        })
        .catch(res => {
          if (res?.code === 400014) {
            this.$http.post(apiPath.logout).then(res => {
              this.$utils.logout(() => {});
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCode() {
      if (this.codeSentStatus) {
        return false;
      }
      if (this.changeState === 2 && !this.phone) {
        return this.$toast('请先输入新的手机号');
      }

      this.$http
        .post(
          apiPath.getCode,
          this.changeState === 2
            ? {
                phone: this.phone,
              }
            : null
        )
        .then(() => {
          this.codeSentStatus = true;
          this.interval = setInterval(() => {
            this.calcTime--;
          }, 1000);
        });
    },
    resetCodeSent() {
      clearInterval(this.interval);
      this.codeSentStatus = false;
      this.calcTime = 60;
    },
  },
};
</script>

<style lang="less" scoped>
.tip {
  margin: 20px 0;
}
.next-btn {
  width: 100px;
  margin-top: 40px;
}
</style>
