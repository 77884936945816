var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cell-container" },
    [
      _c(
        "van-cell-group",
        _vm._l(
          _vm.list.filter((i) => !i.hide),
          function (item, index) {
            return _c("van-cell", {
              key: index,
              attrs: {
                title: item.title,
                label: item.label,
                value: item.value,
                to: item.path,
                center: !!item.center,
              },
              on: {
                click: function ($event) {
                  return _vm.handleClick(item)
                },
              },
              scopedSlots: _vm._u(
                [
                  item.icon
                    ? {
                        key: "title",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "f fc" }, [
                              _c("img", {
                                staticClass: "custom-icon",
                                attrs: { src: item.icon },
                              }),
                              _c("span", { staticClass: "custom-title" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  !!item.isLink
                    ? {
                        key: "right-icon",
                        fn: function () {
                          return [
                            _c("img", {
                              staticClass: "right-arrow",
                              attrs: {
                                src: "https://img-fe.tengzhihh.com/image/08e7301a2a1c12-33x32.png",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            })
          }
        ),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }