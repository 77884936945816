const state = {
  classesId: '',
  musicId: '',
  playerType: 'music',
  isPlaying: false,
  isStarted: false,
  playlistIdList: [],
  repeatMode: 0, //0顺序 1随机 2单曲
  musicList: [],
  lessonList: [],
  audioList: [], //单独展示播放列表
};

const mutations = {
  resetPlayer(state) {
    state.classesId = '';
    state.musicId = '';
    state.playerType = 'music';
    state.isPlaying = false;
    state.isStarted = false;
    state.playlistIdList = [];
    state.repeatMode = 0;
  },
  setPlayer(state, payload) {
    state.classesId = payload.classesId;
    state.musicId = payload.musicId;
    state.playerType = payload.playerType;
    state.isPlaying = payload.isPlaying;
    state.isStarted = payload.isStarted;
    state.playlistIdList = payload.playlistIdList;
    state.repeatMode = payload.repeatMode;
  },
  setMusicList(state, payload) {
    state.musicList = payload;
  },
  setLessonList(state, payload) {
    state.lessonList = payload;
  },
  setAudioList(state, payload) {
    state.audioList = payload;
  },
};

const actions = {
  setPlayer({ commit }, payload) {
    commit('setPlayer', payload);
  },
  resetPlayer({ commit }, payload) {
    commit('resetPlayer', payload);
  },
  setMusicList({ commit }, payload) {
    commit('setPlayer', payload);
  },
  setLessonList({ commit }, payload) {
    commit('setPlayer', payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
