let timeout = null;
const clearDOM = () => {
  const dom = document.getElementById('cus-toast');
  if (dom) {
    dom.remove();
  }
};
const wToast = ({ icon = '', content = '', duration = 3000 }) => {
  clearTimeout(timeout);
  clearDOM();
  if (!content) {
    return false;
  }
  const div = document.createElement('div');

  div.setAttribute('id', 'cus-toast');
  div.className = 'f fc';

  if (icon) {
    const iconImg = document.createElement('img');
    iconImg.setAttribute('id', 'cus-toast-icon');
    iconImg.src = icon;
    div.appendChild(iconImg);
  }

  const text = document.createElement('span');
  text.innerText = content;

  div.appendChild(text);

  document.body.append(div);
  timeout = setTimeout(() => {
    clearDOM();
  }, duration);
};

export default wToast;
