const state = {
  ver: '1.1', //默认ver=1.0，三期及以后ver>=1.1
};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
