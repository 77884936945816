<template>
  <div class="f fv fvc page-box" v-if="pageType === 'add'">
    <img src="https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png" class="bg" />

    <GoBack />

    <div class="btn-area f fc fbt" v-if="!mottoType">
      <div class="btn-box f fv fc" @click="newVideo">
        <img src="https://img-fe.tengzhihh.com/image/c63c7c296a693a-80x80.png" class="btn-img click-hover" />
        <p>视频家训</p>
      </div>

      <div class="btn-box f fc fv" @click="newAudio">
        <img src="https://img-fe.tengzhihh.com/image/56c94cbe48e05b-80x80.png" class="btn-img click-hover" />
        <p>语音家训</p>
      </div>
    </div>
    <div v-else-if="mottoType === 'audio'" class="audio-box f fc fv">
      <div class="main-icon-box">
        <img src="https://img-fe.tengzhihh.com/image/269a8a83cf7990-107x146.png" class="icon" />

        <img
          src="https://img-fe.tengzhihh.com/image/ee8761d8ce2bb6-286x286.png"
          v-show="audioDuration === 0"
          class="png"
        />

        <img
          src="https://img-fe.tengzhihh.com/image/d8df00ab8d4aa3-500x500.gif"
          v-show="audioDuration !== 0"
          class="gif"
        />
      </div>
      <div class="duration">{{ $tools.mileSecondToMinute(audioDuration) }}</div>
      <div @click="startRecord" v-show="audioDuration === 0" class="op-btn f fc fv">
        <img src="https://img-fe.tengzhihh.com/image/d5ad63001541be-168x168.png" class="op-img" />
        <p class="tips">单击开始录音</p>
      </div>
      <div v-show="audioDuration !== 0" class="op-btn f fc fv">
        <img src="https://img-fe.tengzhihh.com/image/6b4d6510cbf93a-168x168.png" @click="stopRecord" class="op-img" />
      </div>
    </div>

    <input type="file" id="videoInput" accept="video/*" ref="file" @change="fileChanged" />
  </div>

  <div v-else class="page-box audio-preview-box f fv fc">
    <img src="https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png" class="bg" />

    <div class="f fv fc">
      <div class="icon-box" @click="playAudio">
        <img
          src="https://img-fe.tengzhihh.com/image/4a8cb9c7d0e114-58x47.png"
          v-show="!audioPlaying"
          class="icon-gif"
        />
        <img src="https://img-fe.tengzhihh.com/image/537b413f01a8e4-59x50.gif" v-show="audioPlaying" class="icon-gif" />
        <img src="https://img-fe.tengzhihh.com/image/11259e0482c1b3-336x126.png" class="icon" />
      </div>
      <p class="duration">{{ $tools.mileSecondToMinute(duration) }}</p>
      <p class="finish-tip">录制完成</p>
    </div>
    <van-button class="handle-btn reRecord-btn" @click="reRecord"> 重新录制 </van-button>
    <van-button class="handle-btn next-btn" @click="next"> 下一步 </van-button>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import GoBack from '@/components/GoBack/GoBack.vue';

import { mapState } from 'vuex';
export default {
  components: { GoBack },
  props: {
    serialId: {
      type: String,
      default: '',
    },
    subVolume: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      mottoType: '',
      duration: 0,
      pageType: 'add',
      audioPlaying: false,
    };
  },
  computed: {
    ...mapState(['audioDuration', 'audioFile']),
  },
  watch: {
    pageType: function (n) {
      if (n === 'add') {
        document.getElementById('preview-audio') && document.getElementById('preview-audio').remove();
      }
    },
  },
  mounted() {},
  beforeDestroy() {
    document.getElementById('preview-audio') && document.getElementById('preview-audio').remove();
    this.$audio.recClose();
  },
  methods: {
    goHome() {
      this.$router.replace('/');
    },
    calcSize(v) {
      v = Number(v);
      return (typeof v === 'number' && !isNaN(v) && this.$tools.bytesToKB(v)) || 0;
    },
    fileChanged() {
      let file = this.$refs.file.files[0];
      const size = this.calcSize(file.size);
      if (size > this.subVolume || this.subVolume === 0) {
        this.$toast('神台机云存储空间不足，请先清理神台机云存储空间');
        this.$refs.file.value = null;
        return;
      }
      this.$store.commit('setVideoFile', file);
      this.mottoType = 'video';

      this.$emit('setValue', 'currentStep', 1);
      this.$emit('setValue', 'mottoType', this.mottoType);
      this.$emit('setValue', 'fileName', file.name);
      // this.$router.push(
      //   `/motto/preview?type=${this.mottoType}&serial_id=${this.serialId}&fileName=${encodeURIComponent(file.name)}`
      // );
    },
    newVideo() {
      document.querySelector('#videoInput').click();
    },
    newAudio() {
      this.$audio.recOpen(() => {
        this.mottoType = 'audio';
        this.$emit('setValue', 'mottoType', this.mottoType);
      });
    },
    startRecord() {
      this.$audio.recStart();
      this.duration = 0;
    },

    stopRecord() {
      this.$audio.recStop((blob, duration) => {
        const size = this.calcSize(blob.size);
        if (size > this.subVolume || this.subVolume === 0) {
          this.$toast('神台机云存储空间不足，请先清理神台机云存储空间');
          this.$router.replace(`/motto/list?serial_id=${this.serialId}`);
          return;
        }
        this.$store.commit('setAudioFile', blob);
        this.$emit('setValue', 'duration', duration);
        this.duration = duration || 0;
        // this.$router.push(`/motto/preview?duration=${duration}&type=${this.mottoType}&serial_id=${this.serialId}`);
        this.loadAudio(blob);
        this.pageType = 'audio';
      });
    },
    playAudio() {
      var audio = document.getElementById('preview-audio');
      if (!this.audioPlaying) {
        audio.play();
        this.audioPlaying = true;
        audio.onended = () => {
          console.log('播放结束');
          this.audioPlaying = false;
        };
      } else {
        audio.pause();
        this.audioPlaying = false;
      }
    },
    loadAudio(blob) {
      document.getElementById('preview-audio') && document.getElementById('preview-audio').remove();
      var audio = document.createElement('audio');
      audio.setAttribute('id', 'preview-audio');
      document.body.appendChild(audio);
      //简单利用URL生成播放地址，注意不用了时需要revokeObjectURL，否则霸占内存
      audio.src = (window.URL || webkitURL).createObjectURL(blob);
    },
    next() {
      this.$emit('setValue', 'currentStep', 2);
      // this.$router.push(
      //   `/motto/edit?type=audio&serial_id=${this.serialId}&fileName=${encodeURIComponent(this.fileName)}`
      // );
    },
    reRecord() {
      this.$store.commit('setAudioFile', null);
      this.$store.commit('setAudioDuration', 0);
      this.$audio.recOpen(() => {
        this.mottoType = 'audio';
        this.$emit('setValue', 'mottoType', this.mottoType);
      });
      this.pageType = 'add';
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }
}

#videoInput {
  display: none;
}
.btn-area {
  padding: 153px 70px 0;
  box-sizing: border-box;
  width: 100%;

  .btn-box {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    .btn-img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
  }
}
.home-btn {
  width: 40px;
  height: 40px;
  position: fixed;
  top: 20px;
  left: 20px;
}
.main-icon-box {
  position: relative;
  margin-top: 30px;
  width: 143px;
  height: 143px;
  margin: 143px 0 60px;
  .icon {
    position: absolute;
    width: 54px;
    height: 73px;
    left: 50%;
    margin-left: -27px;
    top: 50%;
    margin-top: -36.5px;
  }
  .gif {
    width: 180px;
    height: 180px;
    position: absolute;
    left: 50%;
    margin-left: -90px;
    top: 50%;
    margin-top: -90px;
  }
  .png {
    width: 143px;
    height: 143px;
    position: absolute;
  }
}
.audio-box {
  width: 100%;
  .duration {
    text-align: center;
    font-size: 30px;
    color: #996531;
    font-weight: normal;
  }
  .op-btn {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 47px;
    .op-img {
      width: 68px;
      height: 68px;
    }
    .tips {
      padding-top: 10px;
      line-height: 24px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .op-gif {
      width: 126px;
      height: 76px;
    }
  }
}

.audio-preview-box {
  height: calc(var(--vh) * 100);
  .handle-btn {
    border: none;
    width: 300px;
    height: 43px;
    background: #f87a1b;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #fff4e4;
    position: fixed;
  }
  .next-btn {
    bottom: 40px;
  }
  .reRecord-btn {
    bottom: 93px;
  }
  .duration {
    font-size: 15px;
    color: #797979;
  }
  .finish-tip {
    font-size: 16px;
    color: rgba(3, 3, 3, 0.65);
    line-height: 13px;
    margin-top: 45px;
  }
  .icon-box {
    margin-top: 170px;
    margin-bottom: 20px;
    position: relative;
    .icon {
      width: 154px;
    }
    .icon-gif {
      position: absolute;
      top: 14px;
      left: 20px;
      width: 28px;
      height: 25px;
    }
  }
  #audio {
    display: none;
  }
  #preview-audio {
    display: none;
  }
}
</style>
