<template>
  <div class="page-box">
    <cell-group :list="settingList"></cell-group>
    <van-cell title="背景灯开关" is_link center>
      <template #right-icon>
        <van-switch
          active-color="#f87a1b"
          inactive-color="#ffeac9"
          size="20px"
          :value="generalConfig.backlight"
          @input="backlightChange"
        />
      </template>
    </van-cell>
    <van-cell title="宗教节日" is_link center>
      <template #right-icon>
        <van-switch
          active-color="#f87a1b"
          inactive-color="#ffeac9"
          size="20px"
          :value="generalConfig.festival_tab"
          @input="festivalTabChange"
        />
      </template>
    </van-cell>
    <selector
      :title="selectorTitle"
      :show="selectorShow"
      :defaultIndex="findIndex(generalConfig.screen_time)"
      :columns="selectorColumns"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    >
    </selector>
  </div>
</template>

<script>
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import Selector from '@/components/Selector/Selector.vue';
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
const timeArr = ['30秒', '1分钟', '5分钟', '10分钟', '30分钟', '永不息屏'];
const timeReportArr = [
  'setting_xianshi_xiping_time_thirty_s',
  'setting_xianshi_xiping_time_one_min',
  'setting_xianshi_xiping_time_five_min',
  'setting_xianshi_xiping_time_ten_min',
  'setting_xianshi_xiping_time_thirty_min',
  'setting_xianshi_xiping_time_never',
];

const secondArr = [30, 60, 5 * 60, 10 * 60, 30 * 60, 0];
export default {
  components: { CellGroup, Selector },
  data() {
    return {
      selectorType: 1,
      selectorTitle: '',
      selectorShow: false,
      selectorColumns: [],
      serialId: '',
    };
  },
  computed: {
    ...mapState(['generalConfig']),
    settingList: function () {
      return [
        // {
        //   title: '亮度调节',
        //   isLink: true,
        //   cb: () => {
        //     this.openSelector(0);
        //   },
        //   value: 4,
        // },
        {
          title: '息屏时间',
          isLink: true,
          cb: () => {
            this.openSelector(1);
          },
          value: this.formatSecond(this.generalConfig.screen_time),
        },
      ];
    },
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.$utils.getGeneralConfig(this.serialId);
  },
  methods: {
    findIndex(second) {
      return secondArr.findIndex(i => i === second);
    },
    formatSecond(second) {
      if (!second) {
        return '永不';
      }
      let index = secondArr.findIndex(i => i === second);
      return index === -1 ? '永不' : timeArr[index];
    },
    backlightChange(e) {
      this.$utils.updateGeneralConfig(
        this.serialId,
        [
          {
            key: 'backlight',
            value: e ? 'open' : 'close',
          },
        ],
        () => {
          this.$track(e ? 'setting_xianshi_beijingdeng_on' : 'setting_xianshi_beijingdeng_off');
        }
      );
    },
    festivalTabChange(e) {
      this.$utils.updateGeneralConfig(this.serialId, [
        {
          key: 'festival_tab',
          value: e ? 1 : 0,
        },
      ]);
      !!e && this.$track('H5_setting_xianshi_rili_on');
      !e && this.$track('H5_setting_xianshi_rili_off');
    },
    openSelector(type) {
      const arr = ['亮度调节', '息屏时间'];
      const columns = [[1, 2, 3, 4, 5], timeArr];
      this.selectorType = type;
      this.selectorTitle = arr[type];
      this.selectorColumns = columns[type];
      this.selectorShow = true;
    },
    onConfirm(e, idx) {
      if (this.selectorType == 1) {
        this.$utils.updateGeneralConfig(
          this.serialId,
          [
            {
              key: 'screen_time',
              value: secondArr[idx],
            },
          ],
          () => {
            this.$track(timeReportArr[idx]);
            this.selectorShow = false;
          }
        );
      }
    },
    onCancel() {
      this.selectorShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
</style>
