import Vue from 'vue';
import App from './App.vue';
import store from './store';
import request from '@/utils/request';
import router from './router/index';
import 'lib-flexible';
import Recorder from 'recorder-core/recorder.mp3.min';
import wToast from './utils/toast';
import utils from '@/utils/utils';
import tools from '@/utils/tools';
import audio from '@/utils/audio';
import { InitWXSDK } from '@/utils/wechat';
import webSocket from '@/webSocket/webSocket';
import { track } from '@/utils/tracking';
import { errHandler } from '@/utils/errReport';
import {
  Dialog,
  Toast,
  Button,
  Popover,
  Cascader,
  Cell,
  CellGroup,
  Picker,
  Swipe,
  SwipeItem,
  Field,
  Popup,
  Switch,
  DatetimePicker,
  Checkbox,
  Icon,
  Loading,
  Overlay,
  SwipeCell,
  CheckboxGroup,
  Tab,
  Tabs,
  Tag,
  PullRefresh,
  Lazyload,
  Stepper,
  Radio,
  RadioGroup,
  Sticky,
  List,
  Slider,
  Circle,
  Grid,
  GridItem,
  Badge,
  Image as VanImage,
  Form,
  CountDown,
  Progress,
  NavBar,
} from 'vant';

Toast.setDefaultOptions({ forbidClick: false, duration: 1800 });
document.title = '  ';
Vue.prototype.$http = request;
Vue.prototype.$utils = utils;
Vue.prototype.$tools = tools;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$toast = Toast;
Vue.prototype.$wToast = wToast;
Vue.prototype.$track = track;

Vue.prototype.$audio = audio;
Vue.prototype.$webSocket = webSocket;

Vue.prototype.$initWXSDK = InitWXSDK;

Vue.config.ignoredElements = ['wx-open-launch-weapp'];

if (tools.isProd()) {
  Vue.config.errorHandler = errHandler;
} else {
  const script = document.createElement('script');
  script.src = 'https://img-fe.tengzhihh.com/file/8338f5c8-454d-4991-8038-282096759057.js';
  document.getElementsByTagName('head')[0].appendChild(script);
  script.onload = () => {
    const VConsole = window.VConsole;
    window.vConsole = new VConsole();
  };
}

Vue.directive('debounce', {
  inserted: (el, binding) => {
    const { arg, value } = binding;
    let timer;
    el.addEventListener('click', () => {
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        arg ? value(arg) : value();
      }, 300);
    });
  },
});
Vue.directive('throttle', {
  inserted: (el, binding) => {
    const { arg, value } = binding;
    let time;
    el.addEventListener('click', () => {
      if (time) return;
      arg ? value(arg) : value();
      time = setTimeout(() => {
        time = null;
      }, 3000);
    });
  },
});

Vue.use(Button)
  .use(Popover)
  .use(Cascader)
  .use(CellGroup)
  .use(Cell)
  .use(Picker)
  .use(Swipe)
  .use(SwipeItem)
  .use(Dialog)
  .use(Field)
  .use(Popup)
  .use(Switch)
  .use(DatetimePicker)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Icon)
  .use(Loading)
  .use(Overlay)
  .use(SwipeCell)
  .use(Tab)
  .use(Tabs)
  .use(Tag)
  .use(PullRefresh)
  .use(Lazyload)
  .use(Stepper)
  .use(Radio)
  .use(RadioGroup)
  .use(Sticky)
  .use(List)
  .use(Slider)
  .use(Grid)
  .use(GridItem)
  .use(Badge)
  .use(Circle)
  .use(VanImage)
  .use(CountDown)
  .use(Progress)
  .use(NavBar)
  .use(Form);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
