var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-pull-refresh",
    {
      staticStyle: { "min-height": "100%" },
      attrs: { "loading-text": "刷新中" },
      on: { refresh: _vm.onRefresh },
      model: {
        value: _vm.refreshLoading,
        callback: function ($$v) {
          _vm.refreshLoading = $$v
        },
        expression: "refreshLoading",
      },
    },
    [
      _c(
        "div",
        { staticClass: "list-page-box" },
        [
          _c(
            "div",
            { staticClass: "banner" },
            [
              _vm.bannerInfo && _vm.bannerInfo.length
                ? _c(
                    "van-swipe",
                    { attrs: { autoplay: 3000, "show-indicators": false } },
                    _vm._l(_vm.bannerInfo, function (item) {
                      return _c("van-swipe-item", { key: item.id }, [
                        _c("img", {
                          attrs: { src: item.wap_banner_image, alt: "" },
                          on: {
                            click: function ($event) {
                              return _vm.bannerClick(item)
                            },
                          },
                        }),
                      ])
                    }),
                    1
                  )
                : _c("img", {
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/36f01f5d5af05b-690x180.png",
                      alt: "",
                    },
                  }),
            ],
            1
          ),
          _c("div", { staticClass: "f fc fbt header-btn" }, [
            _c("div", { on: { click: _vm.scanCode } }, [_vm._v("+添加设备")]),
            !_vm.manageStatus
              ? _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        _vm.manageStatus = true
                      },
                    },
                  },
                  [_vm._v("管理设备")]
                )
              : _vm._e(),
            _vm.manageStatus
              ? _c(
                  "div",
                  {
                    class: `${_vm.manageStatus ? "cancel" : ""}`,
                    on: {
                      click: function ($event) {
                        _vm.manageStatus = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "list-box" },
            _vm._l(_vm.list, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "item f fbt",
                  on: {
                    click: function ($event) {
                      return _vm.handleDevice(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "img-box f fvc fc" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: item.image,
                          expression: "item.image",
                        },
                      ],
                      staticClass: "img",
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "info-box f fv fbt" },
                    [
                      _c(
                        "div",
                        {
                          class: `f fc name  ${
                            _vm.manageStatus ? "manage-status" : ""
                          }`,
                        },
                        [
                          !_vm.manageStatus
                            ? _c("div", {
                                class: `point ${
                                  item.device_status === 1 ? "online" : ""
                                }`,
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "name-content clamp1" }, [
                            _vm._v(" " + _vm._s(item.name) + " "),
                          ]),
                          !_vm.manageStatus
                            ? _c(
                                "div",
                                {
                                  class: `${
                                    item.device_status === 1
                                      ? ""
                                      : "offline-status"
                                  }`,
                                },
                                [
                                  _vm._v(
                                    " [ " +
                                      _vm._s(
                                        item.device_status === 1
                                          ? "在线"
                                          : "离线"
                                      ) +
                                      " ] "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.manageStatus
                        ? _c(
                            "van-button",
                            {
                              staticClass: "op-btn del-btn",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.openUnBindDialog(item)
                                },
                              },
                            },
                            [_vm._v(" 删除 ")]
                          )
                        : _c(
                            "van-button",
                            {
                              staticClass: "op-btn",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.handleIncense(item)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.incense_status === 1
                                      ? "正在喷洒"
                                      : "开启香薰"
                                  )
                              ),
                            ]
                          ),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "van-popup",
            {
              model: {
                value: _vm.unBindFlag,
                callback: function ($$v) {
                  _vm.unBindFlag = $$v
                },
                expression: "unBindFlag",
              },
            },
            [
              _vm.settingItem
                ? _c("div", { staticClass: "f fv fc dialog-box" }, [
                    _c("p", { staticClass: "tip" }, [_vm._v("确认删除")]),
                    _c("div", { staticClass: "f fv fc info" }, [
                      _c("div", { staticClass: "img-box" }, [
                        _c("img", { attrs: { src: _vm.settingItem.image } }),
                      ]),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.settingItem.name)),
                      ]),
                    ]),
                    _c("p", { staticClass: "sub-tip" }, [
                      _vm._v("接触绑定后，该神台机关于你的全部资料都会被删除"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "f fc frd btn-box" },
                      [
                        _c(
                          "van-button",
                          {
                            staticClass: "btn",
                            attrs: { loading: _vm.loading },
                            on: { click: _vm.unBind },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn cancel",
                            on: { click: _vm.closeDialog },
                          },
                          [_vm._v("取消")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }