var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.loading
    ? _c(
        "div",
        [
          _c("div", { staticClass: "page-box" }, [
            _c("img", { staticClass: "bg", attrs: { src: _vm.calcBg } }),
            _c("div", { staticClass: "top f fc fbt" }, [
              _c("img", {
                staticClass: "back",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/c7cfa932707506-18x35.png",
                },
                on: { click: _vm.goDevice },
              }),
              _c("div", { staticClass: "btns f fc" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.$utils.checkAuth(
                          _vm.routeQuery.serial_id,
                          _vm.newLesson
                        )
                      },
                    },
                  },
                  [_vm._v("新建功课")]
                ),
                _c("div", { staticClass: "btn", on: { click: _vm.openEdit } }, [
                  _vm._v(_vm._s(_vm.editing ? "取消管理" : "管理")),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "list-box f fbt flex-wrap" },
              [
                _vm._l(_vm.list, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      class: `item f fc fvc ${
                        _vm.indexMode === "western" ? "western-item" : ""
                      }`,
                      on: {
                        click: function ($event) {
                          return _vm.viewDetail(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "item-bg",
                        attrs: { src: _vm.calcCover(item.category_id, index) },
                      }),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.sliceName(item.name))),
                      ]),
                      _vm.editing &&
                      item.category_id === 4 &&
                      _vm.startedLessonId != item.id
                        ? _c("div", { staticClass: "edit" }, [
                            _vm.hasSelected(item.id)
                              ? _c("img", {
                                  staticClass: "edit-icon",
                                  attrs: {
                                    src: "https://img-fe.tengzhihh.com/image/a4059a290d41b4-36x36.png",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "edit-icon",
                                  attrs: {
                                    src: "https://img-fe.tengzhihh.com/image/4407cea6dacfab-36x36.png",
                                  },
                                }),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._l(3 - (_vm.list.length % 3), function (item) {
                  return _c("i", { staticStyle: { width: "90px" } })
                }),
              ],
              2
            ),
            !_vm.editing && _vm.isLessonStarted
              ? _c("div", { staticClass: "playing-box f fc fbt" }, [
                  _c(
                    "div",
                    { staticClass: "f fc", on: { click: _vm.goPlaying } },
                    [
                      _c("img", {
                        staticClass: "icon1",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/fb2f93f37e1ddf-36x36.png",
                        },
                      }),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(_vm.playingName)),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "f fc" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPlaying,
                          expression: "isPlaying",
                        },
                      ],
                      staticClass: "icon2",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/9cdb07bae013a0-28x28.gif",
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "exit-btn", on: { click: _vm.exit } },
                      [_vm._v("退出")]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.editing
              ? _c(
                  "div",
                  { staticClass: "del-btn", on: { click: _vm.confirmDel } },
                  [_vm._v("删除")]
                )
              : _vm._e(),
          ]),
          _vm.introduceFlag
            ? _c("Introduce", {
                attrs: {
                  lessonDetailId: _vm.lessonDetailId,
                  indexMode: _vm.indexMode,
                  serialId: _vm.routeQuery.serial_id,
                },
                on: { setValue: _vm.setValue },
              })
            : _vm._e(),
          _c("Dialog", { ref: "dialog" }),
        ],
        1
      )
    : _c(
        "van-overlay",
        { attrs: { show: true } },
        [
          _c(
            "van-loading",
            { attrs: { size: "24px", color: "#dcdcdc", vertical: "" } },
            [_vm._v("加载中...")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }