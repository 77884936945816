var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c("GongdeBtn", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: ["index", "device"].includes(_vm.currentRouteName),
            expression: "['index', 'device'].includes(currentRouteName)",
          },
        ],
        attrs: {
          position:
            _vm.currentRouteName == "device"
              ? { bottom: "10px", right: "10px" }
              : undefined,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }