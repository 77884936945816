<template>
  <div class="page-box f fv fc" v-if="pageType === 1">
    <div class="banner-box">
      <img src="https://img-fe.tengzhihh.com/image/0f6201953b630c-715x426.png" class="banner" />
      <div class="title">智能神台礼包领取活动</div>
    </div>
    <div class="form-box">
      <div class="input-item">
        <van-field v-model="phone" class="input-box" clearable center placeholder="输入电话号码" maxlength="11" />
      </div>
      <div class="input-item f fc">
        <van-field v-model="code" class="input-box code" clearable center placeholder="输入验证码" maxlength="11" />
        <van-button class="code-btn" @click="getCode">{{ codeSentStatus ? calcTime : '获取验证码' }}</van-button>
      </div>
      <div class="input-item">
        <van-field v-model="activationCode" class="input-box" clearable center placeholder="输入激活码" />
      </div>
      <div class="confirm-btn" @click="confirm">领取</div>
    </div>
    <div class="tips">
      <div class="title">礼包领取说明</div>
      <div class="content">
        <p>1、参加活动可以获取特定兑换码</p>
        <p>2、每个兑换码仅可使用一次</p>
        <p>3、不同兑换码有效期不同，逾期失效</p>
      </div>
    </div>
  </div>
  <div v-else class="page-box f fv fc">
    <div class="success-box f fv fc">
      <div class="title">收到礼品了</div>
      <img src="https://img-fe.tengzhihh.com/image/06b07a8e4b0976-288x298.png" class="success-pic" />
      <div class="content">
        恭喜您获得1888金币的返还，我们将会每个月返还300金币到您的账户，最后一个月返还388请注意查看钱包。
      </div>
      <div class="btn" @click="success">确定</div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
export default {
  data() {
    return {
      pageType: 1,
      phone: '',
      code: '',
      activationCode: '',
      codeSentStatus: false,
      calcTime: 60,
      interval: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  watch: {
    calcTime: function (n) {
      if (n <= 0) {
        this.resetCodeSent();
      }
    },
  },
  methods: {
    confirm() {
      if (!this.phone) {
        return this.$toast('请输入手机号');
      }
      if (!this.code) {
        return this.$toast('请输入验证码');
      }
      if (!this.activationCode) {
        return this.$toast('请输入兑换码');
      }
      const formData = new FormData();
      formData.append('phone', this.phone);
      formData.append('code', this.code);
      formData.append('gift_bag_code', this.activationCode);
      this.$http.formPost(apiPath.exchangeGift, formData).then(() => {
        this.pageType = 2;
      });
    },
    getCode() {
      if (this.codeSentStatus) {
        return false;
      }
      if (!this.phone) {
        return this.$toast('请输入手机号');
      }
      this.$http
        .get(apiPath.checkPhone, {
          phone: this.phone,
        })
        .then((res) => {
          if (res.data.have_registered === 'yes') {
            this.$http
              .post(apiPath.getCode, {
                phone: this.phone,
              })
              .then(() => {
                this.codeSentStatus = true;
                this.interval = setInterval(() => {
                  this.calcTime--;
                }, 1000);
              });
          } else {
            this.$toast('该手机号尚未注册，请先注册');
            setTimeout(() => {
              this.$utils.logout();
              this.$router.push('/login/index');
            }, 2000);
          }
        });
    },
    resetCodeSent() {
      clearInterval(this.interval);
      this.codeSentStatus = false;
      this.calcTime = 60;
    },
    success() {
      this.$router.replace('/');
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  background-color: #e9e7e6;
  min-height: calc(var(--vh) * 100);
  .banner-box {
    position: relative;
    width: 357px;
    height: 212px;
    border-radius: 5px;
    margin: 12px 0;
    overflow: hidden;
    .banner {
      width: 357px;
      height: 212px;
    }
    .title {
      font-size: 16px;
      font-weight: 800;
      color: #ef2e32;
      position: absolute;
      z-index: 1;
      bottom: 20px;
      left: 0;
      width: 357px;
      text-align: center;
    }
  }
  .form-box {
    .input-item {
      margin-bottom: 10px;
      .input-box {
        width: 330px;
        height: 41px;
        background: #fefefe;
        border-radius: 5px;
        padding-right: 13px;
      }
      .code {
        width: 215px;
      }
      .code-btn {
        margin-left: 8px;
        width: 107px;
        height: 41px;
        line-height: 41px;
        text-align: center;
        background: #d00000;
        border-radius: 15px;
        font-size: 14px;
        font-weight: bold;
        color: #fefefe;
      }
    }
    .confirm-btn {
      width: 329px;
      height: 43px;
      background: #f87a1b;
      border-radius: 15px;
      font-size: 15px;
      line-height: 43px;
      font-weight: bold;
      text-align: center;
      color: #ffeac9;
      margin-top: 26px;
    }
  }
  .tips {
    .title {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      color: #333333;
      margin: 30px 0 18px;
    }
    .content {
      font-size: 14px;
      font-weight: 500;
      color: #696969;
      line-height: 26px;
    }
  }
  .success-box {
    margin-top: 13px;
    width: 356px;
    border-radius: 10px;
    background: #fff;
    padding: 20px 0 15px;
    .title {
      font-size: 18px;
      font-weight: 800;
      color: #333333;
    }
    .success-pic {
      width: 144px;
      height: 149px;
      margin: 23px 0 4px;
    }
    .content {
      font-size: 14px;
      font-weight: 500;
      color: #696969;
      max-width: 288px;
      text-align: justify;
    }
    .btn {
      width: 133px;
      height: 41px;
      background: #d00000;
      border-radius: 15px;
      font-size: 14px;
      line-height: 41px;
      text-align: center;
      font-weight: bold;
      color: #fefefe;
      margin-top: 18px;
    }
  }
}
</style>
