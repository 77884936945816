<template>
  <div class="f fv fc page-box">
    <div class="success-tip">设备添加成功</div>
    <div class="info f fv fc">
      <div class="img-box">
        <img :src="image" v-if="image" class="device-img" />
      </div>
      <div class="tip">
        {{ name || '神台机' }}
      </div>
    </div>

    <div class="btn-box f fv fc">
      <van-button @click="manageDevice" class="add-btn">开始管理</van-button>
      <div @click="addDevice" class="re-btn">继续添加</div>
    </div>

    <Dialog ref="dialog" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      name: '',
      image: '',
      serialId: '',
      bindCount: 0,
      isInit: false,
    };
  },
  computed: {
    ...mapState(['userInfo']),
  },
  async mounted() {
    this.serialId = this.$route.query.serial_id;
    this.sign = this.$tools.getQueryFromUrl('sign');
    this.$initWXSDK();

    if (this.serialId) {
      this.getDeviceInfo();
    }
  },
  methods: {
    getDeviceInfo() {
      this.$http
        .get(apiPath.getDeviceInfo, {
          serial_id: this.serialId,
        })
        .then((res) => {
          this.name = res.data.name;
          this.image = res.data.image;
          this.bindCount = res.data.bind_count;
          this.isInit = !res.data.index_mode;
          if (!res.data.is_master) {
            this.showTip();
            this.$track('zizhagnhao_log_success');
          }
        });
    },
    showTip() {
      this.$refs.dialog.initDialog({
        title: `你的账号已绑定为${this.name || '神台机'}的子账号`,
        content: ` 1、子账号任何操作获得的功德值都归主账号所有。<br />
2、子账号不允许对智能神台进行更改设置的操作。<br />
3、子账号可以观看和提交家风家训。<br />`,
        confirmText: '我知道了',
        showCancelBtn: false,
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    manageDevice() {
      if (this.isInit) {
        this.$router.replace(`/settings/initSelect?serial_id=${this.serialId}&from=mode_select`);
      } else {
        this.$router.replace(`/device/index?serial_id=${this.serialId}&from=bind_page`);
      }
    },
    addDevice() {
      this.$tools.scanCode();
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  width: 100%;
  height: calc(var(--vh) * 100);
  background-color: #e9e7e6;
  box-sizing: border-box;
  .success-tip {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin: 130px 0 66px;
  }
  .info {
    .img-box {
      width: 64px;

      .device-img {
        width: 64px;
      }
    }
    .tip {
      font-size: 16px;
      font-weight: 500;
      margin-top: 20px;
      color: #333333;
    }
    .name {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
  }
  .btn-box {
    position: fixed;
    bottom: 28px;
    .add-btn {
      width: 330px;
      height: 43px;
      background: #f87a1b;
      border-radius: 15px;
      border: none;
      font-size: 15px;
      font-weight: bold;
      color: #fff4e4;
    }
    .re-btn {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-top: 17px;
    }
  }
}
</style>
