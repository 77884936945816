var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c("BackIcon", { staticClass: "back", on: { back: _vm.backFn } }),
      _c("EnshrineSettingTab", {
        attrs: { list: _vm.tabList },
        on: { tabClick: _vm.tabClick },
      }),
      _c(
        "div",
        { staticClass: "offerings" },
        [
          _c(
            "div",
            { staticClass: "f fbt" },
            _vm._l(_vm.offerings, function ({ title, type }) {
              return _c(
                "div",
                {
                  key: type,
                  class: [
                    "offer",
                    _vm.offerActive === type ? "offer-active" : "",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.offerClick(type)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(title) + " ")]
              )
            }),
            0
          ),
          _vm._l(_vm.offerings, function ({ type, list }, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.offerActive === type,
                    expression: "offerActive === type",
                  },
                ],
                key: type,
              },
              [
                _c(
                  "div",
                  { staticClass: "offer-list" },
                  _vm._l(list, function ({ id, name, image }) {
                    return _c("div", { key: id, staticClass: "offer-item" }, [
                      _c("div", { staticClass: "offer-item-img f fvc" }, [
                        _c("img", {
                          attrs: { src: image, alt: "", srcset: "" },
                        }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "offer-item-name fvc",
                          on: {
                            click: function ($event) {
                              return _vm.offerItemClick(type, { id, image })
                            },
                          },
                        },
                        [
                          _vm.checkedList[index] &&
                          _vm.checkedList[index].id === id
                            ? _c("van-icon", {
                                attrs: { name: "checked", color: "#f87a1b" },
                              })
                            : _c("van-icon", { attrs: { name: "circle" } }),
                          _c("span", { staticClass: "name" }, [
                            _vm._v(" " + _vm._s(name)),
                          ]),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]
            )
          }),
          _c(
            "div",
            { staticClass: "default-offers" },
            [
              _c("p", { staticClass: "title" }, [_vm._v("默认贡品")]),
              _c(
                "div",
                { staticClass: "default-offers-list" },
                [
                  _vm._l(_vm.checkedList, function (item, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          class: ["default-offers-item", "f", "fvc"],
                          style: {
                            backgroundImage: `url(${
                              item.id
                                ? "https://img-fe.tengzhihh.com/image/7f64b9987dad1065dbb9396674b53000-136x190.png"
                                : "https://img-fe.tengzhihh.com/image/9dcceb4b4d08ea62ba10634a85f34ce9-136x190.png"
                            })`,
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: item.image || item.defaultBg,
                              alt: "",
                              srcset: "",
                            },
                          }),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm.checkedOfferings.length === _vm.offerings.length
                ? _c("p", { staticClass: "tips tips-all" }, [
                    _vm._v("一键供奉不会替换正在供奉的贡品"),
                  ])
                : _c("p", { staticClass: "tips" }, [
                    _vm._v("一键供奉不会替换正在供奉的贡品"),
                  ]),
              _c(
                "van-button",
                {
                  directives: [
                    {
                      name: "throttle",
                      rawName: "v-throttle",
                      value: _vm.submit,
                      expression: "submit",
                    },
                  ],
                  attrs: {
                    block: "",
                    color: "#F87A1B",
                    disabled:
                      _vm.checkedOfferings.length !== _vm.offerings.length,
                    size: "small",
                  },
                },
                [_vm._v(" 确定 ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }