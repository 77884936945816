<template>
  <div class="list-container">
    <BackIcon class="back" @back="backFn" />
    <EnshrineSettingTab :list="tabList" @tabClick="tabClick" />
    <div class="list-box f fv fc">
      <div class="list-item f fc" v-for="item in list" :key="item.god_id">
        <div class="img-box">
          <img src="https://img-fe.tengzhihh.com/image/a5edc717e7869e-300x456.png" class="item-img" />
          <img :src="item.main_image" class="main-img" />
          <img :src="item.name_icon" class="tag" />
        </div>
        <div class="item-info f fv frd">
          <div class="info-top">
            <div>
              恭请于 <span class="detail">{{ item.created_at }}</span>
            </div>
            <div v-if="$utils.calcUserIsMasterWithSerialId(routeQuery.serial_id)">
              功德值 <span class="detail">{{ item.user_god_fude }}</span>
            </div>
            <div>
              累计祈福 <span class="detail">{{ item.cumulative_days }}天</span>
            </div>
          </div>
          <div class="info-bottom main" v-if="item.is_default">神明主位</div>
          <div class="info-bottom" v-else @click="$utils.checkAuth(routeQuery.serial_id, () => setDefaultGod(item))">
            设为主位
          </div>
        </div>
      </div>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';

import apiPath from '@/utils/apiPath';
import EnshrineSettingTab from './comp/EnshrineSettingTab.vue';
import BackIcon from '@/components/BackIcon';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  components: {
    BackIcon,
    Dialog,
    EnshrineSettingTab,
  },
  data() {
    return {
      list: [],
      godType: '',
    };
  },
  computed: {
    tabList: function () {
      return [
        { name: 'myGodList', label: '神明管理', god_type: [1, 2] },
        { name: 'deceasedList', label: '逝者管理', god_type: [3] },
        { name: 'enshrineSetting', label: '一键供奉', god_type: [1, 2, 3] },
      ].filter(x => x.god_type.includes(+this.godType));
    },
  },
  created() {
    this.godType = this.$route.query.god_type || 1;
  },
  mounted() {
    this.getCurGodList();
  },
  methods: {
    getCurGodList() {
      this.$http
        .get(apiPath.currentGodList, {
          god_type: this.godType,
          serial_id: this.routeQuery.serial_id,
        })
        .then(res => {
          this.list = res.data.list;
        });
    },
    setDefaultGod(item) {
      this.$track('gongfeng_mygongfeng_sheweizhuwei');
      this.$refs.dialog.initDialog({
        title: '设为主位',
        content: `是否设置该神明为主位，确定后将作为供奉顺序的第一位，优先展示在首页神龛(神柜)里。`,
        cancelText: '取消',
        confirmText: '确定',
        confirmCallback: () => {
          this.$http
            .post(apiPath.setDefaultGod, {
              serial_id: this.routeQuery.serial_id,
              god_type: this.godType,
              god_id: item.god_id,
            })
            .then(() => {
              this.$track('gongfeng_replace_zhuwei_shenxiang');
              this.$refs.dialog.closeDialog();
              this.$toast('设置成功');
              this.getCurGodList();
            });
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    tabClick({ name }) {
      this.$router.replace({
        name,
        query: this.$route.query,
      });
    },
    backFn() {
      this.$router.push({
        path: '/god/index',
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  .back {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  min-height: calc(var(--vh) * 100);
  background: #fff;
  .list-box {
    padding-top: 16px;
    .list-item {
      width: 343px;
      height: 152px;
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      margin-bottom: 16px;
      .img-box {
        position: relative;
        margin-right: 20px;
        .item-img {
          width: 100px;
          height: 153px;
          display: block;
        }
        .main-img {
          position: absolute;
          width: 80px;
          bottom: 14px;
          left: 50%;
          margin-left: -40px;
        }
        .tag {
          position: absolute;
          top: 10px;
          right: 6px;
          width: 14px;
        }
      }
      .item-info {
        height: 100%;
        padding: 10px 0;
        box-sizing: border-box;
        .info-top {
          font-size: 14px;
          color: 595959;
          line-height: 1.8;
          .detail {
            color: #7b5439;
          }
        }
        .info-bottom {
          width: 211px;
          font-size: 16px;
          text-align: center;
          line-height: 40px;
          height: 40px;
          background: #f87a1b;
          border-radius: 10px;
          color: rgba(255, 255, 255, 0.9);
          border: 1px solid #f87a1b;
        }
        .main {
          border-radius: 10px;
          background: #fff;
          border: 1px solid #f87a1b;
          color: #f87a1b;
        }
      }
    }
  }
}
</style>
