var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c("div", { staticClass: "page" }, [
        _c(
          "div",
          { staticClass: "orderStatus f fvc" },
          [
            _c("van-icon", {
              staticClass: "mr8",
              attrs: { size: "18px", name: _vm.statusIcon[_vm.order.status] },
            }),
            _c("p", [_vm._v(_vm._s(_vm.orderStatusTxt[_vm.order.status]))]),
            _vm.order.status === 0
              ? _c(
                  "div",
                  { staticClass: "f fvc" },
                  [
                    _c("span", [_vm._v("（剩")]),
                    _c("van-count-down", {
                      attrs: { time: _vm.timeOut },
                      on: { finish: _vm.getOrderDetail },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (timeData) {
                              return [
                                _c("div", { staticClass: "ordertime" }, [
                                  _vm._v(
                                    "  " +
                                      _vm._s(timeData.hours) +
                                      " 小时 " +
                                      _vm._s(timeData.minutes) +
                                      " 分 " +
                                      _vm._s(timeData.seconds) +
                                      "秒  "
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        932004264
                      ),
                    }),
                    _c("span", [_vm._v("自动关闭）")]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.category === "entity" && _vm.order.logistics.user_name
          ? _c("div", { staticClass: "borderFFF f fbt mb10 orderAddress" }, [
              _c("img", {
                staticClass: "address-icon",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/1b4f21eebe0a3e-24x24.png",
                  alt: "",
                  srcset: "",
                },
              }),
              _c("div", { staticClass: "address-detail" }, [
                _c("div", { staticClass: "f" }, [
                  _c("span", { staticClass: "name mr10" }, [
                    _vm._v(_vm._s(_vm.order.logistics.user_name)),
                  ]),
                  _c("span", { staticClass: "mobile" }, [
                    _vm._v(_vm._s(_vm.order.logistics.mobile)),
                  ]),
                ]),
                _c("p", { staticStyle: { "word-break": "break-all" } }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.order.logistics.province) +
                      " " +
                      _vm._s(_vm.order.logistics.city) +
                      " " +
                      _vm._s(_vm.order.logistics.district) +
                      " " +
                      _vm._s(_vm.order.logistics.street) +
                      _vm._s(_vm.order.logistics.address) +
                      " "
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "borderFFF mb10" }, [
          _vm.order.goods_info
            ? _c(
                "div",
                { staticClass: "f orderDetail" },
                [
                  _c("van-image", {
                    staticClass: "good-img",
                    attrs: {
                      src: _vm.order.goods_info && _vm.order.goods_info.image,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "error",
                          fn: function () {
                            return [_vm._v("加载失败")]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1393410444
                    ),
                  }),
                  _c("div", { staticClass: "order-content" }, [
                    _c("div", { staticClass: "name-amount f mb10" }, [
                      _c("p", { staticClass: "good-name" }, [
                        _vm._v(_vm._s(_vm.order.goods_info.name)),
                      ]),
                      _c("p", { staticClass: "good-amount" }, [
                        _vm._v(
                          "¥ " + _vm._s(_vm.order.goods_info.original_amount)
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "stats-count f" }, [
                      _c(
                        "p",
                        { staticClass: "good-desc" },
                        [
                          _vm.category === "virtual"
                            ? [_vm._v(" " + _vm._s(_vm.order.serial_id) + " ")]
                            : _vm._e(),
                          _vm.category === "entity" &&
                          Object.keys(_vm.order.product.sku).length
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.order.product.sku.sku) +
                                    "：" +
                                    _vm._s(_vm.order.product.sku.sku_name) +
                                    " "
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c("p", { staticClass: "good-count lh-20" }, [
                        _vm._v("x" + _vm._s(_vm.order.goods_num)),
                      ]),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("div", { staticClass: "f fbt order-amount" }, [
            _c("span", [_vm._v("实付款")]),
            _c("span", { staticClass: "amount-count" }, [
              _vm._v("¥ " + _vm._s(_vm.order.amount)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "borderFFF orderInfo" }, [
          _c("div", { staticClass: "f fbt infoItem" }, [
            _c("div", { staticClass: "label" }, [_vm._v("订单编号")]),
            _c("div", { staticClass: "f fc value" }, [
              _c("p", [
                _vm._v(" " + _vm._s(_vm.order.order_center_id || "N/A") + " "),
              ]),
              _vm.order.order_center_id
                ? _c(
                    "div",
                    {
                      staticClass: "copy",
                      on: {
                        click: function ($event) {
                          return _vm.copy(_vm.order.order_center_id)
                        },
                      },
                    },
                    [_vm._v(" | 复制")]
                  )
                : _vm._e(),
            ]),
          ]),
          _c("div", { staticClass: "f fbt infoItem" }, [
            _c("div", { staticClass: "label" }, [_vm._v("支付方式")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.order.payment_mode || "N/A") + " "),
            ]),
          ]),
          _vm.category === "entity"
            ? _c("div", { staticClass: "f fbt infoItem" }, [
                _c("div", { staticClass: "label" }, [_vm._v("物流公司")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.order.logistics &&
                          _vm.order.logistics.logistics_name) ||
                          "暂无快递"
                      ) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.category === "entity"
            ? _c("div", { staticClass: "f fbt infoItem" }, [
                _c("div", { staticClass: "label" }, [_vm._v("快递单号")]),
                _c("div", { staticClass: "f fc value" }, [
                  _c("p", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.order.logistics &&
                            _vm.order.logistics.logistics_sn) ||
                            "暂无快递"
                        ) +
                        " "
                    ),
                  ]),
                  _vm.order.logistics && _vm.order.logistics.logistics_sn
                    ? _c(
                        "div",
                        {
                          staticClass: "copy",
                          on: {
                            click: function ($event) {
                              return _vm.copy(_vm.order.logistics.logistics_sn)
                            },
                          },
                        },
                        [_vm._v("  | 复制 ")]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "f fbt infoItem" }, [
            _c("div", { staticClass: "label" }, [_vm._v("下单时间")]),
            _c("div", { staticClass: "value" }, [
              _vm._v(" " + _vm._s(_vm.order.created_at) + " "),
            ]),
          ]),
          _vm.order.status !== 0
            ? _c("div", { staticClass: "f fbt infoItem" }, [
                _c("div", { staticClass: "label" }, [_vm._v("支付时间")]),
                _c("div", { staticClass: "value" }, [
                  _vm._v(" " + _vm._s(_vm.order.pay_at) + " "),
                ]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "f fvc kefu", on: { click: _vm.contact } }, [
            _c("img", {
              staticClass: "address-icon",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/d008cacf1336cb-16x16.png",
                alt: "",
                srcset: "",
              },
            }),
            _vm._v(" 联系客服 "),
          ]),
        ]),
      ]),
      _vm.order.status !== 0
        ? _c(
            "div",
            {
              class: [
                "handle",
                "pidhandle",
                "f",
                _vm.handleList.length ? "" : "nohandle",
              ],
            },
            _vm._l(_vm.handleList, function (item) {
              return _c(
                "button",
                {
                  key: item.name,
                  staticClass: "handle-button",
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.order.status === 0
        ? _c(
            "div",
            {
              class: [
                "handle",
                "unpidhandle",
                "f",
                _vm.handleList.length ? "" : "nohandle",
              ],
            },
            _vm._l(_vm.handleList, function (item) {
              return _c(
                "button",
                {
                  key: item.name,
                  staticClass: "unpid-button",
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.label) + " ")]
              )
            }),
            0
          )
        : _vm._e(),
      _c("Dialog", { ref: "dialog" }),
      _c("cancelPop", {
        ref: "cancelPop",
        on: { cancelSuccess: _vm.getOrderDetail },
      }),
      _c("confirmPop", {
        ref: "confirmPop",
        on: { receiveConfirm: _vm.getOrderDetail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }