export const enshrineOfferings = [
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/1eb06596795f45ffcd4ce4ddfa983c61-192x292.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/494bc7fc544129d1c1aa510be43e9425-192x292.png',
    offer_type: 1,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/684e10716444e6ca5de96850ef51dca8-102x208.png',
    offer_type: 2,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/c6f8134db7a8093904aee258aa8c9601-132x190.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/be9d311f2b3873a6e74f1fc51b1b51d2-132x190.png',
    offer_type: 3,
  },
  {
    offer_left_image: 'https://img-fe.tengzhihh.com/image/82379177f5a8dd-104x164.png',
    offer_right_image: 'https://img-fe.tengzhihh.com/image/b16d0fae9e1ab5-104x164.png',
    offer_type: 4,
  },
];
export const fireImg = 'https://img-fe.tengzhihh.com/image/26b2cc7b06f2e3-207x207.png';
