var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$tools.isInApp()
        ? _c("input", {
            ref: "fileInput",
            attrs: { type: "file", id: "imgReader", accept: "image/*" },
            on: { change: _vm.loadingImg },
          })
        : _vm.$tools.isHuaWei()
        ? _c("input", {
            ref: "fileInput",
            attrs: { type: "file", id: "imgReader" },
            on: { change: _vm.loadingImg },
          })
        : _c("input", {
            ref: "fileInput",
            attrs: { type: "file", id: "imgReader", accept: "image/*" },
            on: { change: _vm.loadingImg },
          }),
      _vm.showCom
        ? _c("div", { staticClass: "com-img-picker-box" }, [
            _c("img", { attrs: { id: "cropImg" } }),
            _c(
              "div",
              { staticClass: "btn-group f fc frd" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "cancel-btn",
                    attrs: { size: "small" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "van-button",
                  {
                    staticClass: "confirm-btn",
                    attrs: { size: "small", loading: _vm.uploadLoading },
                    on: { click: _vm.confirm },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "van-overlay",
        { attrs: { show: _vm.uploadLoading } },
        [
          _c(
            "van-loading",
            { attrs: { size: "24px", color: "#fff", vertical: "" } },
            [
              _c("div", { staticClass: "f fv fvc uploading-box" }, [
                _c("div", { staticClass: "top-tip" }, [_vm._v("正在上传数据")]),
                _c("div", { staticClass: "progress" }, [
                  _vm._v(" " + _vm._s(_vm.uploadProgress) + " "),
                ]),
                _c("div", { staticClass: "main-tip" }, [
                  _vm._v("请保持网络畅通"),
                ]),
                _c("div", { staticClass: "main-tip" }, [
                  _vm._v("不要离开本页面"),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }