import { request } from '@/utils/request';

export function orderList(params) {
  return request({
    url: '/order/list',
    method: 'get',
    params,
  });
}
export function orderDetail(params) {
  return request({
    url: '/order/detail',
    method: 'get',
    params,
  });
}
export function cancelOrder(data) {
  return request({
    url: '/order/cancel',
    method: 'post',
    data,
  });
}
export function receiveConfirm(data) {
  return request({
    url: '/order/receiving_goods',
    method: 'post',
    data,
  });
}
export function getBadge() {
  return request({
    url: '/user/order/table',
    method: 'get',
  });
}
