<template>
  <div class="f fv fc page-box">
    <div class="info f fc fv">
      <div class="img-box">
        <img :src="image" v-if="image" class="device-img" />
      </div>
      <div class="tip">检测到</div>
      <div class="name">{{ name }}</div>
    </div>
    <div class="btn-box f fv fc">
      <div v-if="bindCount === 0" class="tip">神台机首次启动，需要进行初始化设置才能绑定</div>
      <div v-else-if="userIsBind === 1" class="tip">你已经绑定该设备，进入首页开始管理</div>

      <van-button @click="toHome" v-if="userIsBind === 1" class="add-btn">开始管理</van-button>
      <van-button @click="startSetting" v-else :loading="loading" class="add-btn">{{
        bindCount === 0 ? '开始设置' : '添加设备'
      }}</van-button>

      <div @click="reScan" class="re-btn">重新扫描</div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      serialId: '',
      sign: '',
      name: '',
      image: '',
      bindCount: -1,
      userIsBind: 0,
      loading: false,
    };
  },

  mounted() {
    this.serialId = this.$route?.query?.serial_id;
    this.sign = this.$route?.query?.sign;

    this.$utils.checkToken(() => {
      this.$initWXSDK();

      if (this.sign && this.serialId) {
        this.autuBind();
      }

      if (this.serialId) {
        this.$http
          .get(apiPath.getDeviceInfo, {
            serial_id: this.serialId,
          })
          .then((res) => {
            this.name = res.data.name;
            this.image = res.data.image;
            this.bindCount = res.data.bind_count;
            this.userIsBind = res.data.user_is_bind;
          });
      }
    }, `/bind/index?serial_id=${this.serialId}`);
  },
  methods: {
    autuBind() {
      this.$http
        .post(apiPath.autoBind, {
          serial_id: this.serialId,
          sign: this.sign,
        })
        .then(() => {
          this.$router.replace(`/bind/success?serial_id=${this.serialId}&sign=${this.sign}`);
        });
    },
    toHome() {
      this.$router.replace('/');
    },
    startSetting() {
      if (this.bindCount === 0) {
        this.$router.replace(`/settings/initSelect?serial_id=${this.serialId}&from=mode_select`);
      } else {
        this.autuBind();
      }
    },

    reScan() {
      this.$tools.scanCode();
    },
    unBind() {
      this.loading = true;
      const formData = new FormData();
      formData.append('serial_id', this.serialId);
      this.$http
        .formPost(apiPath.unBind, formData)
        .then(() => {
          this.loading = false;
          this.$toast('解绑成功');
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  width: 100%;
  height: calc(var(--vh) * 100);
  box-sizing: border-box;
  .info {
    margin-top: 107px;
    .img-box {
      width: 104px;
      .device-img {
        width: 104px;
      }
    }
    .tip {
      font-size: 16px;
      color: #333333;
      margin: 12px 0 16px;
    }
    .name {
      font-size: 20px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .btn-box {
    margin-top: 20px;
    .tip {
      margin: 30px 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }
    .add-btn {
      width: 299px;
      height: 44px;
      background: #f87a1b;
      border-radius: 10px;
      border: none;
      font-size: 16px;
      color: #fff;
    }
    .re-btn {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-top: 17px;
    }
  }
}
</style>
