var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "detail-box" },
        [
          _c(
            "van-swipe",
            {
              staticClass: "my-swipe",
              attrs: {
                autoplay: _vm.playing ? 0 : 3000,
                "indicator-color": "white",
              },
            },
            _vm._l(_vm.goodsItem.atlas, function (item, index) {
              return _c("van-swipe-item", { key: index }, [
                item.type === "video"
                  ? _c("div", { staticClass: "main-pic f fc fvc" }, [
                      _c("video", {
                        staticClass: "main-pic",
                        attrs: {
                          src: item.url,
                          poster: item.cover_image,
                          id: "video-player",
                          controls: "",
                          autoplay: "",
                          playsinline: "",
                        },
                      }),
                    ])
                  : _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: item.url,
                          expression: "item.url",
                        },
                      ],
                      staticClass: "main-pic",
                    }),
              ])
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "goods-area" },
            [
              _c("goods-item", { attrs: { item: _vm.goodsItem } }),
              _c(
                "div",
                { staticClass: "select-area" },
                [
                  _vm.goodsItem.category === "virtual"
                    ? [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("选择智能神台购买"),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "select-item f fc fbt",
                            on: { click: _vm.selectMachine },
                          },
                          [
                            _c("div", { staticClass: "border tip" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedMachine
                                      ? _vm.selectedMachine.name
                                      : "点击选择智能神台"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("van-icon", {
                              staticClass: "right-arrow",
                              attrs: { name: "arrow" },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm.goodsItem.category === "entity"
                    ? [
                        _c(
                          "div",
                          { staticClass: "buy-count select-item f fc fbt" },
                          [
                            _c("div", { staticClass: "tip" }, [
                              _vm._v("购买数量"),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "f fc",
                                on: { click: _vm.openGoodNum },
                              },
                              [
                                _c("span", { staticClass: "count" }, [
                                  _vm._v(_vm._s(_vm.entityCorrelate.goods_num)),
                                ]),
                                _c("van-icon", {
                                  staticClass: "right-arrow",
                                  attrs: { name: "arrow" },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.entityCorrelate.address_id
                          ? _c("div", { staticClass: "f fbt address-all" }, [
                              _c("img", {
                                staticClass: "address-icon",
                                attrs: {
                                  src: "https://img-fe.tengzhihh.com/image/1b4f21eebe0a3e-24x24.png",
                                  alt: "",
                                  srcset: "",
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "address-detail",
                                  on: { click: _vm.chooseAddress },
                                },
                                [
                                  _c("div", { staticClass: "mb8 f" }, [
                                    _c("span", { staticClass: "name mr10" }, [
                                      _vm._v(
                                        _vm._s(_vm.address_detail.username)
                                      ),
                                    ]),
                                    _c("span", { staticClass: "mobile" }, [
                                      _vm._v(_vm._s(_vm.address_detail.mobile)),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "f fbt fc" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.address_detail.province
                                              ) +
                                              " " +
                                              _vm._s(_vm.address_detail.city) +
                                              " " +
                                              _vm._s(
                                                _vm.address_detail.district
                                              ) +
                                              " " +
                                              _vm._s(
                                                _vm.address_detail.street
                                              ) +
                                              _vm._s(
                                                _vm.address_detail.address
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("van-icon", {
                                        staticClass: "right-arrow",
                                        attrs: { name: "arrow" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ])
                          : _c(
                              "div",
                              {
                                staticClass: "buy-address select-item f fc fbt",
                                on: { click: _vm.addressClick },
                              },
                              [
                                _c("div", { staticClass: "tip" }, [
                                  _vm._v("选择发货地址"),
                                ]),
                                _c("van-icon", {
                                  staticClass: "right-arrow",
                                  attrs: { name: "arrow" },
                                }),
                              ],
                              1
                            ),
                      ]
                    : _vm._e(),
                  _vm.goodsItem.is_package
                    ? _c("div", [
                        _c("div", { staticClass: "title" }, [
                          _vm._v("选择类型"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "f fc flex-wrap" },
                          _vm._l(_vm.packages, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                class: `pack-item ${
                                  item.id === _vm.packageId
                                    ? "current-pack"
                                    : ""
                                }`,
                                on: {
                                  click: function ($event) {
                                    return _vm.choosePack(item.id)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          0
                        ),
                      ])
                    : _c("div", { staticClass: "total-amount f fc fbt" }, [
                        _c("div", { staticClass: "tip" }, [_vm._v("总价")]),
                        _c("div", { staticClass: "amount" }, [
                          _vm._v("￥" + _vm._s(_vm.goodsItem.final_amount)),
                        ]),
                      ]),
                ],
                2
              ),
            ],
            1
          ),
          _c("div", { staticClass: "line" }),
          _c(
            "div",
            { staticClass: "fixed pay-area" },
            [
              _c("pay-ways", {
                attrs: {
                  virtual: _vm.goodsItem.is_allow_use_coin == 1,
                  goodsItem: _vm.goodsItem,
                  customParams: _vm.entityCorrelate,
                  goodsId: _vm.goodsId,
                  packageId: _vm.packageId,
                  packages: _vm.packages,
                  userCoins: _vm.userCoins,
                  serialId:
                    _vm.selectedMachine && _vm.selectedMachine.serial_id,
                  from: "goodsDetail",
                },
                on: { insufficientBalance: _vm.insufficientBalance },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "goods-detail" }, [
            _c("div", { staticClass: "detail-header f fc fvc" }, [
              _vm._v("商品介绍"),
            ]),
            _c("div", {
              staticClass: "introduction",
              domProps: { innerHTML: _vm._s(_vm.introduction) },
            }),
          ]),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          attrs: { "close-on-click-overlay": "", position: "bottom" },
          model: {
            value: _vm.failedPopupFlag,
            callback: function ($$v) {
              _vm.failedPopupFlag = $$v
            },
            expression: "failedPopupFlag",
          },
        },
        [
          _vm.failedPopupType === 1
            ? _c("div", { staticClass: "failed-popup" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("余额不足")]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v("当前余额不足，请充值后再支付或使用其他支付方式"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "pay-area" },
                  [
                    _c("pay-ways", {
                      attrs: {
                        userCoins: _vm.userCoins,
                        goodsId: _vm.goodsId,
                        serialId:
                          _vm.selectedMachine && _vm.selectedMachine.serial_id,
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "recharge-btn",
                        on: { click: _vm.recharge },
                      },
                      [_vm._v("充值")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.failedPopupType === 2
            ? _c("div", { staticClass: "failed-popup" }, [
                _c("div", { staticClass: "header" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("支付失败")]),
                  _c("div", { staticClass: "subtitle" }, [
                    _vm._v("没有获取到支付成功的信息"),
                  ]),
                ]),
                _c("div", { staticClass: "btn-option f fc fbt" }, [
                  _c("div", { staticClass: "give-up" }, [_vm._v("放弃")]),
                  _c("div", { staticClass: "pay" }, [_vm._v("继续支付")]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "contact-area f fc fvc",
                    on: { click: _vm.kefu },
                  },
                  [_c("span", [_vm._v("联系客服")])]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("Dialog", { ref: "dialog" }),
      _c(
        "van-popup",
        {
          staticClass: "addAddressPop",
          model: {
            value: _vm.addAddressPop,
            callback: function ($$v) {
              _vm.addAddressPop = $$v
            },
            expression: "addAddressPop",
          },
        },
        [
          _c("p", { staticClass: "title" }, [_vm._v("添加收货地址")]),
          _c("p", [_vm._v("您还没有收货地址，请先添加一个新的收货地址")]),
          _c(
            "van-button",
            {
              attrs: { block: "", color: "#F87A1B" },
              on: { click: _vm.goAddress },
            },
            [_vm._v("去添加")]
          ),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "goodsNumPop",
          model: {
            value: _vm.goodsNumPop,
            callback: function ($$v) {
              _vm.goodsNumPop = $$v
            },
            expression: "goodsNumPop",
          },
        },
        [
          _c(
            "div",
            { staticClass: "f fbt mb20" },
            [
              _c("div", { staticClass: "fc" }, [_c("p", [_vm._v("数量")])]),
              _c("van-stepper", {
                attrs: { "disable-input": "", min: "0" },
                model: {
                  value: _vm.pop_goods_num,
                  callback: function ($$v) {
                    _vm.pop_goods_num = $$v
                  },
                  expression: "pop_goods_num",
                },
              }),
            ],
            1
          ),
          _c(
            "van-button",
            {
              attrs: { block: "", color: "#F87A1B" },
              on: { click: _vm.changeGoodNum },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }