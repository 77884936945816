var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-page-box" },
    [
      _c("GoBack", {
        attrs: {
          backIcon:
            "https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png",
          backFunction: _vm.goBack,
        },
      }),
      _c("img", {
        staticClass: "bg",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/4e0e73a30570bb-750x1496.png",
        },
      }),
      _c(
        "div",
        [
          _c("div", { staticClass: "title" }, [_vm._v("输入功课名称")]),
          _c(
            "div",
            { staticClass: "textarea-box" },
            [
              _c("van-field", {
                staticClass: "textarea-area",
                attrs: {
                  type: "textarea",
                  maxlength: "5",
                  autosize: "",
                  "show-word-limit": "",
                  row: "2",
                  placeholder: "输入新的标题",
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "title" }, [_vm._v("添加功课")]),
          _c(
            "div",
            { staticClass: "list f fc flex-wrap" },
            [
              _vm._l(_vm.selectedArr, function (item, idx) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "item f fc fvc" },
                  [
                    _c("img", {
                      staticClass: "cover",
                      attrs: { src: item.image },
                    }),
                    _c("img", {
                      staticClass: "tag",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/b8d88cbe0703a7-37x181.png",
                      },
                    }),
                    _c("div", { staticClass: "name f fc" }, [
                      _vm._v(" " + _vm._s(item.name.slice(0, 5)) + " "),
                    ]),
                    _vm.editMode === "new"
                      ? _c("img", {
                          staticClass: "del-icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/1583afb72514b3-36x36.png",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.delItem(idx)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                )
              }),
              _vm.selectedArr.length < 6 && _vm.editMode === "new"
                ? _c(
                    "div",
                    {
                      staticClass: "item add f fc fvc",
                      on: { click: _vm.addLesson },
                    },
                    [
                      _c("img", {
                        staticClass: "add-icon",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/24ebe98d781139-28x28.png",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "time-tip" }, [_vm._v("设定播放时间")]),
          _c(
            "div",
            {
              staticClass: "select-time-box f fc fbt",
              on: { click: _vm.openDatePicker },
            },
            [
              _c("div", { staticClass: "tip" }, [
                _vm._v(_vm._s(_vm.pushTime || "点击选择时间")),
              ]),
              _c(
                "div",
                { staticClass: "f fc" },
                [
                  _c("van-icon", { attrs: { name: "arrow", size: "20px" } }),
                  _vm.pushTime
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            "margin-left": "10px",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.pushTime = ""
                            },
                          },
                        },
                        [_vm._v("取消")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c(
            "van-button",
            {
              staticClass: "save-btn",
              attrs: { type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c("date-picker", {
        attrs: {
          pickerType: "time",
          show: _vm.datePickerFlag,
          filter: _vm.timeFilter,
          currentHour: _vm.pushTime,
          propMinDate: new Date(),
          propMaxDate: new Date(210255, 255, 255),
        },
        on: { onCancel: _vm.onCancel, onConfirm: _vm.onConfirm },
      }),
      _c("img-picker", {
        ref: "comImgPicker",
        on: { emitImgUrl: _vm.emitImgUrl },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }