import { request } from '@/utils/request';

export function getQuickOffering(params) {
  return request({
    url: '/user/quick_offering_set',
    method: 'get',
    params,
  });
}
export function setQuickOffering(data) {
  return request({
    url: '/user/quick_offering_set',
    method: 'post',
    data,
  });
}
export function saveQuickOffering(data) {
  return request({
    url: '/offering/quick/save',
    method: 'post',
    data,
  });
}
export function getMachineColor(params) {
  return request({
    url: '/config/general/machine_color',
    method: 'get',
    params,
  });
}
export function setConfigGeneral(data) {
  return request({
    url: '/config/general',
    method: 'post',
    data,
  });
}
export function getDeceaseShow(params) {
  return request({
    url: '/config/general/decease_show',
    method: 'get',
    params,
  });
}