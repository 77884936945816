var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "msg-detail" },
    [
      _c("div", { staticClass: "detail-header f fc fbt" }, [
        !_vm.manageStatus
          ? _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    _vm.manageStatus = true
                  },
                },
              },
              [_vm._v("消息管理")]
            )
          : _vm._e(),
        _vm.manageStatus
          ? _c("div", { on: { click: _vm.selectAll } }, [
              _vm._v(" " + _vm._s(_vm.allFlag ? "全不选" : "全选") + " "),
            ])
          : _vm._e(),
        _vm.manageStatus
          ? _c("div", { on: { click: _vm.cancelSelect } }, [_vm._v("取消")])
          : _vm._e(),
      ]),
      _c(
        "van-checkbox-group",
        {
          ref: "checkboxGroup",
          model: {
            value: _vm.result,
            callback: function ($$v) {
              _vm.result = $$v
            },
            expression: "result",
          },
        },
        [
          _c(
            "div",
            {
              class: `detail-body ${_vm.manageStatus ? "manage-status" : ""}`,
              attrs: { id: "msg-list" },
            },
            [
              _vm.calcList.length === 0
                ? _c("div", { staticClass: "empty-box f fc fvc" }, [
                    _vm._v("暂无消息"),
                  ])
                : _vm._e(),
              _vm.hasMore && _vm.calcList.length > 0
                ? _c(
                    "div",
                    { staticClass: "loadmore", on: { click: _vm.loadMore } },
                    [_vm._v("点击加载更多")]
                  )
                : _vm._e(),
              _vm._l(_vm.calcList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "msg-group f fv fvc",
                    attrs: { id: item.id },
                  },
                  [
                    _c("div", { staticClass: "group-date" }, [
                      _vm._v(_vm._s(item.created_at)),
                    ]),
                    _c("div", { staticClass: "group-list" }, [
                      _c(
                        "div",
                        { staticClass: "list-item f fc" },
                        [
                          _vm.manageStatus
                            ? _c("van-checkbox", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: { name: item.id, "icon-size": "18px" },
                              })
                            : _vm._e(),
                          _c("div", {
                            staticClass: "msg-content",
                            domProps: { innerHTML: _vm._s(item.content) },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
      _vm.manageStatus
        ? _c(
            "van-button",
            {
              staticClass: "default-btn del-btn",
              attrs: { size: "small" },
              on: { click: _vm.delMsg },
            },
            [_vm._v("删除所选")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }