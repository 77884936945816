<template>
  <div class="page-box">
    <div class="box-header f fc fbt">
      <van-icon name="arrow-left" @click="goBack" class="back-icon" />
      <div class="record" @click="viewRecord">消费记录</div>
    </div>
    <div class="data-header">
      <div class="title">我的余额</div>
      <div class="amount-area f fc fvc">
        <div>
          {{ coin }}
        </div>
        <img src="https://img-fe.tengzhihh.com/image/340376cac80e1b-55x55.png" class="coin-icon" />
        <van-icon name="question" class="question-icon" />
      </div>
    </div>
    <div class="line"></div>
    <div class="recharge-area">
      <div class="title">充值虚拟币</div>
      <div class="options f fc flex-wrap">
        <div class="option-box f fv fc" v-for="(item, index) in rechargeOptions" :key="index" @click="select(item)">
          <div :class="`option-item f fc fvc ${selectedItem.id === item.id ? 'selected' : ''}`">
            <div>{{ item.final_amount }}</div>
            <img src="https://img-fe.tengzhihh.com/image/340376cac80e1b-55x55.png" class="coin-icon" />
          </div>
          <div class="money">￥{{ item.final_amount }}</div>
        </div>
      </div>
    </div>
    <div class="pay-area">
      <div class="title">支付方式</div>
      <pay-ways :goodsId="selectedItem.id" failedTip="请先选择充值项目" from="wallet"></pay-ways>
      <div class="tips">充值说明</div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import PayWays from '@/components/PayWays/PayWays';
export default {
  components: {
    PayWays,
  },
  data() {
    return {
      rechargeOptions: [],
      selectedItem: { id: 0 },
      coin: 0,
      orderId: '',
    };
  },
  mounted() {
    this.orderId = this.$tools.getQueryFromUrl('order_id');

    if (this.orderId) {
      this.getOrderDetail();
    }
    this.getList({ category: 'recharge' });
    this.getUserCoin();
  },
  methods: {
    getOrderDetail() {
      this.$http.get(`${apiPath.orderDetail}?order_id=${this.orderId}`).then((res) => {
        if (res.data.order.status == 1) {
          this.$toast('充值成功');
        } else {
          this.$toast('充值失败');
        }
      });
    },
    getUserCoin() {
      this.$http.get(apiPath.userCoin).then((res) => {
        this.coin = res.data.coin;
      });
    },
    select(item) {
      this.selectedItem = item;
    },
    getList(e) {
      this.$http
        .get(
          `${apiPath.goods}?page=1&size=200${e.category ? '&category=' + e.category : ''}${
            e.is_recommend ? '&is_recommend=' + e.is_recommend : ''
          }`
        )
        .then((res) => {
          this.rechargeOptions = res.data.list;
          this.rechargeOptions.length > 0 && (this.selectedItem = this.rechargeOptions[0]);
        });
    },
    viewRecord() {
      this.$router.push('/shop/orderList');
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  background-color: #e9e7e6;
  min-height: 100%;
  .line {
    width: 375px;
    height: 10px;
  }
  .box-header {
    width: 375px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 20px 20px 0;
    .back-icon {
      color: #000;
      font-size: 18px;
      font-weight: bold;
    }
    .record {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
  }
  .data-header {
    width: 375px;
    box-sizing: border-box;
    padding: 20px 22px 14px;
    background-color: #fff;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
    .amount-area {
      font-size: 36px;
      font-weight: bold;
      color: #333333;
      position: relative;
      .question-icon {
        position: absolute;
        color: #ffcf27;
        font-size: 18px;
        top: 0px;
        right: 96px;
      }
    }
    .coin-icon {
      width: 29px;
      height: 29px;
      margin-left: 8px;
    }
  }
  .recharge-area {
    background-color: #fff;
    padding: 25px 22px;
    border-bottom: 1px solid #f2f2f2;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 14px;
    }
    .options {
      .option-box {
        margin-right: 6px;
        margin-bottom: 6px;
        .option-item {
          width: 102px;
          height: 50px;
          border: 1px solid #bfbfbf;
          border-radius: 10px;
          font-size: 18px;
          font-weight: 800;
          color: #ffcf27;
          margin-bottom: 13px;
          .coin-icon {
            width: 23px;
            height: 23px;
            margin-left: 4px;
          }
        }
        .selected {
          border: 2px solid #ffcf27;
        }
        .money {
          font-size: 14px;
          font-weight: 500;
          color: #ff0000;
        }
      }
      .option-box:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  .pay-area {
    background-color: #fff;
    padding: 25px 22px 16px;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 24px;
    }
    .tips {
      margin-top: 28px;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
  }
}
</style>
