var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "goods-item f fc" }, [
    _c("div", { staticClass: "img" }, [
      _c("img", { attrs: { src: _vm.item.thumbnail } }),
    ]),
    _c("div", { staticClass: "info f fv fbt" }, [
      _c("div", { staticClass: "name clamp2" }, [
        _vm._v(" " + _vm._s(_vm.item.name) + " "),
      ]),
      _c("div", { staticClass: "data f fe fbt" }, [
        _c("div", { staticClass: "price" }, [
          _c("div", { staticClass: "current-price" }, [
            _vm._v("￥" + _vm._s(_vm.item.final_amount)),
          ]),
          _c("div", { staticClass: "origin-price" }, [
            _vm._v("￥" + _vm._s(_vm.item.original_amount)),
          ]),
        ]),
        _c("div", { staticClass: "stock" }, [
          _vm._v("库存：" + _vm._s(_vm.item.stock)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }