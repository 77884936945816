const OSS = require('ali-oss');
class OssTools {
  constructor(config) {
    this.config = config;
    this.Client = new OSS({
      region: config.region,
      accessKeyId: config.accessKeyId,
      accessKeySecret: config.accessKeySecret,
      bucket: config.bucket,
    });
  }

  async uploadFile(path, data) {
    console.log('开始上传');
    return new Promise(async (resolve, reject) => {
      try {
        let result = await this.Client.put(path, data);
        resolve(result);
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }
  async multipartUpload({ path, file, progress, error }) {
    return new Promise(async (resolve, reject) => {
      this.Client.multipartUpload(path, file, {
        parallel: 4,
        partSize: 1024 * 1024,
        progress: function(p, cpt, res) {
          progress(p);
        },
      })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
export class ossTools extends OssTools {}
