var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _c("div", { staticClass: "line" }),
      _c(
        "div",
        { staticClass: "box-header" },
        [
          _c("van-icon", {
            staticClass: "back-icon",
            attrs: { name: "arrow-left" },
            on: { click: _vm.goBack },
          }),
        ],
        1
      ),
      _c(
        "van-radio-group",
        {
          model: {
            value: _vm.defaultMachine,
            callback: function ($$v) {
              _vm.defaultMachine = $$v
            },
            expression: "defaultMachine",
          },
        },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            { key: item.serial_id, staticClass: "machine-item" },
            [
              _c("div", { staticClass: "item-box" }, [
                _c("div", { staticClass: "top f fc fbt" }, [
                  _c("div", { staticClass: "name-box f fc" }, [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    item.is_vip
                      ? _c("img", {
                          staticClass: "vip-icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/45c9e2c3351bcd-56x39.png",
                          },
                        })
                      : _vm._e(),
                    item.is_buy
                      ? _c("span", { staticClass: "is-buy" }, [
                          _vm._v("已购买"),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", {
                    class: `select ${
                      _vm.selectedItem.serial_id === item.serial_id
                        ? "selected"
                        : ""
                    }`,
                    on: {
                      click: function ($event) {
                        return _vm.selectMachine(item)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "line" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }