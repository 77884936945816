<template>
  <div class="container f fv fc">
    <GoBack backIcon="https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png" />

    <div class="list">
      <div class="item">
        <div class="content" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import GoBack from '@/components/GoBack/GoBack.vue';
import apiPath from '@/utils/apiPath';
import { commonOperate } from '@/mixins/commonOperate';
const identifierData = {
  user_fude: 'gongde_introduce-v2',
  user_god_fude: 'god_gongde_introduce-v2',
  user_decease_fude: 'decease_gongde_introduce',
  gong_feng_decease_notice: 'gong_feng_decease_notice',
};
export default {
  mixins: [commonOperate],
  components: {
    GoBack,
  },
  data() {
    return {
      content: '',
      fudeType: '',
    };
  },
  created() {
    this.fudeType = this.$route.query.fude_type;
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    getConfig() {
      this.$http
        .get(apiPath.systemConfig, {
          identifier: identifierData[this.fudeType] || 'gongde_introduce-v2',
        })
        .then(res => {
          this.content = res.data.content.replace(/\n/g, '<br />');
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 375px;
  height: calc(var(--vh) * 100);
  box-sizing: border-box;
  overflow: scroll;
  z-index: 1111;
  background: #f8f8f8;
  padding-top: 70px;
  .list {
    padding-bottom: 20px;
  }
  .item {
    width: 343px;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 16px;
    box-sizing: border-box;
    font-size: 14px;

    .title {
      color: #f87a1b;
      margin-bottom: 16px;
    }
    .content {
      line-height: 1.3;
    }
    .img {
      width: 318px;
      margin: 20px auto;
    }
  }
}
</style>
