var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-container" },
    [
      _c("GoBack", {
        attrs: {
          backIcon:
            "https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png",
        },
      }),
      _c("div", { staticClass: "top-bar" }, [
        _c("div", { staticClass: "btn", on: { click: _vm.explanation } }, [
          _vm._v("说明"),
        ]),
        _c("div", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.fudeType === "user_fude" ? "总" : `${_vm.godName} `) +
              _vm._s(
                _vm.fudeType === "user_fude"
                  ? "功德值"
                  : ["user_decease_fude"].includes(_vm.fudeType) && !_vm.godName
                  ? "历代元祖宗亲之位"
                  : "香火"
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "sub" }, [
          _c("p", { staticClass: "sub-title" }, [_vm._v("我的功德")]),
          _c("div", { staticClass: "f fvc" }, [
            _c("img", {
              staticClass: "sub-icon",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png",
              },
            }),
            _c("span", { staticClass: "sub-number" }, [
              _vm._v(_vm._s(_vm.fude)),
            ]),
          ]),
        ]),
      ]),
      _vm.list.length > 0
        ? _c(
            "div",
            {
              staticClass: "list",
              attrs: { id: "fude-list" },
              on: { scroll: _vm.scrollBottomCheck },
            },
            _vm._l(_vm.list, function (item) {
              return _c("div", { key: item.id, staticClass: "item f fc fbt" }, [
                _c("div", { staticClass: "name clamp1" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _c("div", { staticClass: "time f fv fc" }, [
                  _c("div", [_vm._v(_vm._s(item.created_at.split(" ")[0]))]),
                  _c("div", [_vm._v(_vm._s(item.created_at.split(" ")[1]))]),
                ]),
                _c("div", { staticClass: "desc clamp2" }, [
                  _vm._v(_vm._s(item.content)),
                ]),
                _c("div", { staticClass: "number" }, [
                  _vm._v(_vm._s(_vm._f("fude")(item.numbers))),
                ]),
              ])
            }),
            0
          )
        : _c("div", { staticClass: "list f fv fvc" }, [
            _c("img", {
              staticClass: "icon",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/ca001910984ac3-140x132.png",
              },
            }),
            _c("div", { staticClass: "tip" }, [_vm._v("没有数据")]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }