var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box f fc fv" },
    [
      _c("div", { staticClass: "img-box" }, [
        _c("img", { staticClass: "img", attrs: { src: _vm.statusImg } }),
      ]),
      _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.capacity) + "%")]),
      _c("div", { staticClass: "sub-tip" }, [
        _vm._v(" " + _vm._s(_vm.statusStr) + " "),
      ]),
      _c("van-button", { staticClass: "btn fixed", on: { click: _vm.buy } }, [
        _vm._v("购买香薰"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }