<template>
  <div class="edit-page-box">
    <GoBack backIcon="https://img-fe.tengzhihh.com/image/5daf2c4249bbab-56x56.png" :backFunction="goBack" />

    <img src="https://img-fe.tengzhihh.com/image/4e0e73a30570bb-750x1496.png" class="bg" />

    <div>
      <div class="title">输入功课名称</div>
      <div class="textarea-box">
        <van-field
          type="textarea"
          class="textarea-area"
          maxlength="5"
          autosize
          show-word-limit
          row="2"
          v-model="content"
          placeholder="输入新的标题"
        ></van-field>
      </div>
      <div class="title">添加功课</div>

      <div class="list f fc flex-wrap">
        <div class="item f fc fvc" v-for="(item, idx) in selectedArr" :key="item.id">
          <img :src="item.image" class="cover" />
          <img src="https://img-fe.tengzhihh.com/image/b8d88cbe0703a7-37x181.png" class="tag" />
          <div class="name f fc">
            {{ item.name.slice(0, 5) }}
          </div>
          <img
            v-if="editMode === 'new'"
            src="https://img-fe.tengzhihh.com/image/1583afb72514b3-36x36.png"
            class="del-icon"
            @click="delItem(idx)"
          />
        </div>
        <div class="item add f fc fvc" @click="addLesson" v-if="selectedArr.length < 6 && editMode === 'new'">
          <img src="https://img-fe.tengzhihh.com/image/24ebe98d781139-28x28.png" class="add-icon" />
        </div>
      </div>
      <!-- <div class="title">添加封面</div>

      <div class="images-box f fc flex-wrap">
        <div v-for="(item, index) in images" class="img-box" :key="index">
          <img :src="item.url" @click.stop.prevent="preview(item)" />

          <img
            src="https://img-fe.tengzhihh.com/image/526d3d05af8245-50x50.png"
            class="del-icon"
            @click.stop.prevent="delImg(item, index)"
          />
        </div>
        <div class="img-box f fv fvc fc border" v-if="images.length < 1" @click="chooseImg()">
          <van-icon name="plus" size="16px" color="#000" />
        </div>
      </div> -->

      <div class="line"></div>
      <div class="time-tip">设定播放时间</div>
      <div class="select-time-box f fc fbt" @click="openDatePicker">
        <div class="tip">{{ pushTime || '点击选择时间' }}</div>
        <div class="f fc">
          <van-icon name="arrow" size="20px" />
          <div style="font-size: 14px; margin-left: 10px" v-if="pushTime" @click.stop.prevent="pushTime = ''">取消</div>
        </div>
      </div>
      <van-button class="save-btn" type="primary" @click="save">保存</van-button>
    </div>

    <date-picker
      pickerType="time"
      :show="datePickerFlag"
      :filter="timeFilter"
      :currentHour="pushTime"
      :propMinDate="new Date()"
      :propMaxDate="new Date(210255, 255, 255)"
      @onCancel="onCancel"
      @onConfirm="onConfirm"
    ></date-picker>
    <img-picker ref="comImgPicker" @emitImgUrl="emitImgUrl"></img-picker>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker/DatePicker.vue';
import apiPath from '@/utils/apiPath';
import { mapState } from 'vuex';
import GoBack from '@/components/GoBack/GoBack.vue';
import ImgPicker from '@/components/ImgPicker/ImgPicker.vue';

export default {
  components: {
    DatePicker,
    GoBack,
    ImgPicker,
  },
  props: {
    lessonId: {
      type: [Number, String],
      default: 0,
    },
    serialId: {
      type: String,
      default: '',
    },
    selectedArr: {
      type: Array,
      default: () => [],
    },
    editMode: {
      type: String,
      default: 'new',
    },
  },
  data() {
    return {
      timeFilter: (type, options) => {
        const nowMin = new Date().getMinutes();
        let minArr = [],
          hourArr = [];
        if (type === 'minute') {
          return options.filter((option) => option % 5 === 0);
        }
        if (type === 'hour') {
          hourArr = options.filter((option, idx) => {
            return (idx === 0 && nowMin <= 55) || idx >= 1;
          });
          return hourArr;
        }
        return options;
      },
      content: '',
      datePickerFlag: false,
      pushTime: '',
      images: [],
    };
  },

  mounted() {},
  methods: {
    emitImgUrl(e) {
      if (this.mottoType === 'audio') {
        this.images.push(e);
      } else {
        this.images = [e];
      }
    },
    goBack() {
      this.$router.replace(`/music/lessonList?serial_id=${this.serialId}`);
    },
    openDatePicker() {
      this.datePickerFlag = true;
    },
    delItem(index) {
      this.$emit('deleteItem', index);
    },
    setDetail(lessonDetail) {
      this.content = lessonDetail.name;
      this.images = [lessonDetail.cover_image];
      this.pushTime = lessonDetail.remind_time;
    },
    onConfirm(e) {
      this.pushTime = e;
    },
    onCancel() {
      this.datePickerFlag = false;
    },
    addLesson() {
      this.$emit('setValue', 'pageStep', 'lesson_select');
    },
    chooseImg() {
      this.$refs.comImgPicker.uploadImg();
    },
    delImg(item, index) {
      this.images.splice(index, 1);
    },

    save() {
      if (this.selectedArr.length === 0) {
        return this.$toast('功课内容不能为空');
      }
      if (!this.content) {
        return this.$toast('功课名称不能为空');
      }
      if (this.content.length > 5) {
        return this.$toast('功课名称最多只能五个字符');
      }
      const emojiRegex =
        /[\u{1F300}-\u{1F6FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{20D0}-\u{20FF}\u{FE00}-\u{FE0F}\u{1F1E6}-\u{1F1FF}]/u;

      if (emojiRegex.test(this.content)) {
        return this.$toast('功课名称不能含有特殊字符');
      }
      const data = {
        serial_id: this.serialId,
        name: this.content,

        remind_type: this.pushTime ? 'every_day' : 'not',
      };
      if (this.editMode === 'new') {
        data['music_id'] = this.selectedArr.map((i) => i.id).join(',');
      }
      if (this.pushTime) {
        data['remind_time'] = this.pushTime;
      }
      if (this.editMode === 'edit') {
        data['curriculum_id'] = this.lessonId;
      }
      this.$http.post(this.editMode === 'new' ? apiPath.addLesson : apiPath.saveLesson, data).then(() => {
        this.$toast('操作成功');
        this.editMode === 'new' && this.$track('new_gongke_success');
        if (this.pushTime) {
          this.$track('gongke_set_play_time');
        }

        if (this.editMode === 'new') {
          this.$router.replace(`/music/lessonList?serial_id=${this.serialId}`);
        } else {
          this.$router.replace(
            `/music/musicIndex?from=lesson&serial_id=${this.serialId}&lesson_id=${this.lessonId}&lesson_category_id=4`
          );
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.edit-page-box {
  top: 0;
  left: 0;
  padding: 70px 20px 14px;
  height: calc(var(--vh) * 100);
  overflow-y: auto;
  box-sizing: border-box;
  width: 375px;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh) * 100);
    z-index: -1;
  }

  .textarea-box {
    width: 335px;
    height: 88px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    margin-bottom: 34px;
    overflow: hidden;
    position: relative;
    .textarea-area {
      width: 100%;
      background: rgba(255, 255, 255, 0.3);
    }
    .placeholder {
      position: absolute;
      left: 15px;
      top: 12px;
      font-size: 14px;
      color: rgba(3, 3, 3, 0.65);
    }
  }
  .list {
    .item {
      margin: 0 4px 6px 0;
      width: 105px;
      height: 105px;
      border-radius: 10px;
      position: relative;
      box-sizing: border-box;

      overflow: hidden;
      .add-icon {
        width: 14px;
        height: 14px;
      }
      .cover {
        width: 100%;
        height: 100%;
      }
      .tag {
        width: 19px;
        height: 90px;
        position: absolute;
        top: 0;
        left: 10px;
      }
      .name {
        position: absolute;
        writing-mode: vertical-lr;
        text-orientation: upright;
        font-size: 12px;
        color: #ad7d48;
        left: 11px;
        top: 6px;
        text-align: center;
        letter-spacing: 2px;
      }
      .del-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .add {
      border: 1px solid rgba(0, 0, 0, 0.65);
    }
  }

  .images-box {
    width: 100%;
    margin: 14px 0;
    .img-box {
      position: relative;
      margin: 0 36px 14px 0;
      width: 105px;
      height: 105px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.45);
      img {
        width: 105px;
        height: 105px;
      }
      .del-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        right: -8px;
        top: -8px;
      }
    }
  }
  .save-btn {
    width: 300px;
    height: 43px;
    background: #f87a1b;
    border-radius: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #fff4e4;
    border: none;
    left: 50%;
    margin-left: -150px;
    position: fixed;
    bottom: 20px;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #7b5439;
  margin-bottom: 8px;
}
.tip {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin: 13px 0;
  color: #7b5439;
}
.time-tip {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.line {
  width: 330px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);

  margin-bottom: 20px;
}
</style>

<style lang="less">
.edit-page-box {
  .van-field__control::-webkit-input-placeholder {
    font-size: 14px;
    color: rgba(3, 3, 3, 0.65);
  }
}
</style>
