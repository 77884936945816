<template>
  <div>
    <van-popup v-model="dialogFlag" position="bottom" class="popup">
      <div class="f fv fc popup-box">
        <div class="title">已经累计供奉{{ godInfo.cumulative_days }}天</div>
        <div class="god-img-box">
          <img src="https://img-fe.tengzhihh.com/image/81925064b6126d-276x276.png" class="bg" />
          <img :src="godInfo.wap_main_image" class="god-item-img" />
        </div>
        <div class="name">{{ godInfo.name }}</div>
        <div class="id-box" v-if="godInfo.unique_number">
          <img src="https://img-fe.tengzhihh.com/image/11e1a446efd149-392x64.png" class="id-bg-img" />
          <div class="id">神像编号（ID：{{ godInfo.unique_number }}）</div>
        </div>
        <div class="desc">
          <div class="sub-title">神明简介</div>
          <div class="content" v-html="godInfo.introduce && godInfo.introduce.replace(/\n/g, '<br />')"></div>
        </div>
        <div class="btn-box f fc fbt">
          <div class="cancel-btn" @click="$utils.checkAuth(serialId, sendGod)">送神回天</div>
          <div class="confirm-btn" @click="dialogFlag = false">关闭</div>
        </div>
      </div>
    </van-popup>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';
import apiPath from '@/utils/apiPath';
import { commonInfo } from '@/mixins/commonInfo';
export default {
  mixins: [commonInfo],
  components: {
    Dialog,
  },
  props: {
    serialId: {
      type: String,
      default: '',
    },
    godInfo: {
      type: Object,
      default: () => {
        return {
          wap_detail_image: '',
          name: '',
        };
      },
    },
  },
  data() {
    return {
      dialogFlag: false,
      endY: 0,
    };
  },
  watch: {
    dialogFlag: function (n) {
      if (!n) {
        this.$track('gongfeng_shenxiang_jieshao_close');
      }
    },
  },
  methods: {
    onTouchMove(event) {
      this.endY = event.changedTouches[0].clientY;
    },
    onTouchEnd() {
      if (this.endY > 200) {
        this.dialogFlag = false;
        this.endY = 0;
      }
    },
    sendGod() {
      this.$track('gongfeng_shenxiang_jieshao_songshen');

      this.$refs.dialog.initDialog({
        title: '恭送神明',
        content: `是否恭送本神明？恭送神明24小时内，您不能再向此神明祈愿供奉。24小时后自动送神上天。`,
        confirmText: '确定',
        cancelText: '取消',
        confirmCallback: () => {
          this.$http
            .post(apiPath.sendGod, {
              serial_id: this.serialId,
              god_id: this.godInfo.id,
              ver: this.commonConfig.ver,
            })
            .then(() => {
              this.$track('gongfeng_songshen_sure');
              this.$toast('操作成功');
              this.$emit('updateGodList', this.godInfo.id);
              this.dialogFlag = false;
              this.$refs.dialog.closeDialog();
            });
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    open() {
      this.dialogFlag = true;
      this.$track('gongfeng_shenxiang_jieshao_show');
    },
  },
};
</script>

<style lang="less" scoped>
.popup {
  width: 100%;
  background: #fff;
  border-radius: 10px 10px 0 0;
  .popup-box {
    padding: 20px 0 20px;
    background: #fff;
    border-radius: 15px;
    max-height: calc(var(--vh) * 80);
    overflow: scroll;
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #f87a1b;
    }
    .god-img-box {
      position: relative;
      width: 138px;
      height: 166px;
      flex-shrink: 0;
      .god-item-img {
        position: absolute;
        width: 104px;
        left: 50%;
        margin-left: -52px;
        z-index: 2;
      }
      .bg {
        position: absolute;
        width: 138px;
        height: 138px;
        z-index: 1;
        top: 1px;
      }
    }
    .id-box {
      position: relative;
      width: 196px;
      height: 32px;
      margin-bottom: 16px;
      .id {
        font-size: 14px;
        line-height: 32px;
        color: #a35a26;
        position: absolute;
        width: 196px;
        left: 50%;
        top: 0;
        margin-left: -98px;
        text-align: center;
      }
      .id-bg-img {
        width: 196px;
        height: 32px;
      }
    }
    .name {
      font-size: 14px;
      color: #7b5439;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .desc {
      width: 335px;
      padding: 20px;
      box-sizing: border-box;
      text-align: justify;
      margin-bottom: 20px;
      border: 1px solid #f87a1b;
      border-radius: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      .sub-title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 14px;
        font-weight: bold;
      }
    }
    .confirm-btn {
      width: 299px;
      height: 44px;
      background: #f87a1b;
      border-radius: 10px 10px 10px 10px;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
    }

    .tip {
      font-size: 18px;
      color: #2e2d2d;
      margin-top: 10px;
    }

    .btn-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      margin: 22px 0 0;

      .cancel-btn {
        width: 108px;
        height: 44px;
        background: #f6f6f6;
        line-height: 44px;
        text-align: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
        border-radius: 10px;
      }

      .confirm-btn {
        width: 210px;
        height: 44px;
        background: #f87a1b;
        border-radius: 10px;
        line-height: 44px;
        text-align: center;
        font-size: 16px;
        color: #ffeac9;
      }
    }
    .offering-select {
      .cancel-text {
        font-size: 16px;
        color: #f87a1b;
      }
      .confirm-btn {
        width: 228px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
