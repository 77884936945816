var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cover-mode f fv fc" },
    [
      _c("DownloadBar"),
      _c("img", {
        staticClass: "cover-img",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/c4477fbb7d2f6d-228x282.png",
        },
      }),
      _c("img", {
        staticClass: "cover-slogan",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/d87eef6dfc9d9b-38x232.png",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }