<template>
  <div v-if="$tools.isAndroid()" class="download f fbt fc">
    <div class="download-left f">
      <div class="download-icon f fvc">
        <img src="https://img-fe.tengzhihh.com/image/414d3088a8da7ee76be929f5343f6baf-52x39.png" alt="" />
      </div>
      <div class="download-title">眷吉App客户端上线<br />操作更加顺畅，更多可用功能</div>
    </div>
    <van-button class="downloac-btn primary-btn" @click="downloadFn"> 下载 </van-button>

    <van-overlay z-index="9999999" :show="showOverlay" @click="showOverlay = false">
      <div class="wrapper">
        <img
          class="wrapper-icon wrapper-icon1 f"
          src="https://img-fe.tengzhihh.com/image/3225aa36834982fa6d2d82f938067906-528x198.png"
          alt=""
          srcset=""
        />
        <img
          class="wrapper-icon wrapper-icon2 f"
          src="https://img-fe.tengzhihh.com/image/f679c282c9a150e469f6793754789d22-356x356.png"
          alt=""
          srcset=""
        />
        <img
          class="wrapper-icon wrapper-icon3 f"
          src="https://img-fe.tengzhihh.com/image/e7b81b975c5b33b8309b0bba1c5fe708-166x171.png"
          alt=""
          srcset=""
        />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import apiPath from '@/utils/apiPath';

export default {
  name: 'Download',
  data() {
    return {
      showOverlay: false,
    };
  },
  methods: {
    downloadFn() {
      this.$http
        .get(apiPath.systemConfig, {
          identifier: 'last_app',
        })
        .then(({ data }) => {
          if (this.$tools.isWeChat()) {
            var url = new URL(window.location.href);
            var params = new URLSearchParams(url.search);
            params.set('downloadUrl', data.content);
            url.search = params.toString();
            history.replaceState(null, '', url.href);
            this.showOverlay = true;
            return;
          } else {
            this.$router.replace({
              path: this.$route.path,
              query: Object.assign(
                {
                  downloadUrl: data.content,
                },
                this.$route.query
              ),
            });
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.download {
  width: 100%;
  height: 50px;
  padding: 6px 16px;
  box-sizing: border-box;
  background: #7b5439;
  .download-left {
    .download-icon {
      width: 38px;
      height: 38px;
      background: #fff;
      border-radius: 6px;
      margin-right: 12px;
      img {
        width: 26px;
        height: 19px;
      }
    }
    .download-title {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 20px;
    }
  }
  .downloac-btn {
    width: 80px;
    height: 28px;
    border-radius: 14px;
  }
}
.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  .wrapper-icon {
    position: absolute;
  }
  .wrapper-icon1 {
    width: 264px;
    height: 99px;
    top: 308px;
    left: 50%;
    transform: translateX(-50%);
  }
  .wrapper-icon2 {
    width: 160px;
    height: 160px;
    left: 50%;
    top: 203px;
  }
  .wrapper-icon3 {
    width: 78px;
    height: 81px;
    top: 20px;
    right: 37px;
  }
}
</style>
