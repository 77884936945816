<template>
  <div class="page">
    <div
      v-for="{ id, icon, title, des, status, task_type, fu_de, button_title, finish_num, limit_num } in list"
      class="list f"
    >
      <img :src="icon" alt="" srcset="" class="icon f" />
      <div class="content">
        <div class="title" :key="id">
          {{ title }}
        </div>
        <div class="des">{{ des }}</div>
      </div>
      <div class="handle">
        <div class="f fe">
          <van-button v-if="status === 1" class="primary-btn" size="small" @click="getFude(id, title, fu_de)">
            领取
          </van-button>
          <van-button v-else-if="status === 2" disabled class="primary-btn" size="small">已领取</van-button>
          <van-button v-else class="primary-btn" size="small" @click="handleFn(task_type, title, fu_de)">{{
            button_title
          }}</van-button>
        </div>
        <div class="finish_num">已完成{{ finish_num }}/{{ limit_num }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDailyTaskList, getRaskFude } from '@/api/fude';
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const { code, data } = await getDailyTaskList({
        serial_id: this.$route.query.serial_id,
      });
      this.list = data;
    },
    async getFude(task_id, title, fu_de) {
      const res = await getRaskFude({
        task_id,
        serial_id: this.$route.query.serial_id,
      });
      this.$wToast({
        icon: 'https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png',
        content: `已${title}，功德值+${fu_de}`,
      });
      this.getList();
    },
    handleFn(task_type, title, fu_de) {
      const query = {
        serial_id: this.$route.query.serial_id,
      };
      const routeName = ['', 'godIndex', 'godIndex', 'lessonList', 'woodfish'];
      this.$router.push({
        name: routeName[task_type],
        query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 375px;
  height: calc(var(--vh) * 100);
  background: #fcf5ec;
  padding: 0 16px;
  box-sizing: border-box;
  .fe {
    justify-content: flex-end;
  }
  .list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 0;
    .icon {
      width: 60px;
      height: 60px;
    }
  }
  .content {
    padding-left: 16px;
    flex: 1;
    .title {
      font-size: 16px;
      color: #85531f;
      line-height: 36px;
    }
    .des {
      font-weight: 400;
      font-size: 14px;
      color: rgba(123, 84, 57, 0.65);
      line-height: 14px;
    }
  }
  .handle {
    .primary-btn {
      height: 30px;
      width: 70px;
      margin-bottom: 4px;
    }
    .finish_num {
      font-size: 11px;
      text-align: center;
      color: rgba(123, 84, 57, 0.65);
    }
  }
}
</style>
