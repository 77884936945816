<template>
  <div v-if="hasGift">
    <img
      v-if="btnShow"
      class="float-btn"
      src="https://fe-img.tengzhihh.com/image/0749e5b222830d8694fbe461618bb7cd-112x112.png"
      :style="{ ...position }"
      @click="handleBtnClick"
    />
    <!-- 功德赠礼弹窗 -->
    <van-popup class="gongde-gift" v-model="popupShow" @closed="onPopupClose">
      <img
        class="gongde-gift-btn"
        src="https://fe-img.tengzhihh.com/image/89ae660b7f7ed098462fe3ec14574185-380x80.png"
        @click="handleGoFudeGift"
      />
      <van-icon class="close-icon" name="close" @click="popupShow = false" />
    </van-popup>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    position: {
      type: Object,
      default: () => ({ bottom: '60px', right: '15px' }),
    },
  },
  data() {
    return {
      btnShow: false,
      popupShow: false,
    };
  },
  computed: {
    ...mapState({
      hasGift: state => state.fudeGift.fude,
      userInfo: state => state.userInfo,
    }),
  },
  // 触发检测是否有福德大礼包机制为用户信息更新完毕时，为避免用户切换账号，福德大礼包显示未更新
  watch: {
    'userInfo.user_id': {
      handler(id) {
        if (id) {
          this.$utils.fetchAndUpdateFudeGift();
        }
      },
      immediate: true,
    },
    hasGift(val) {
      if (val) {
        this.popupShow = true;
      } else {
        this.btnShow = false;
        this.popupShow = false;
      }
    },
  },
  methods: {
    onPopupClose() {
      this.btnShow = true;
    },
    handleBtnClick() {
      this.btnShow = false;
      this.popupShow = true;
    },
    handleGoFudeGift() {
      this.popupShow = false;
      const query = this.$route.query;
      this.$router.push({ path: '/activity/fudeGift', query });
    },
  },
};
</script>

<style lang="less" scoped>
.float-btn {
  z-index: 9;
  position: fixed;
  width: 56px;
}
/deep/.van-popup {
  &.gongde-gift {
    background-color: transparent;
    text-align: center;
    background-image: url('https://fe-img.tengzhihh.com/image/4b64686e8d4df3d394223a20d8499858-752x1030.png');
    width: 376px;
    height: 515px;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100vw;
    }
    .close-icon {
      position: absolute;
      bottom: 55px;
      color: #fff;
      font-size: 32px;
    }
    .gongde-gift-btn {
      position: absolute;
      bottom: 75px;
      width: 190px;
      margin-bottom: 30px;
    }
  }
}
</style>
