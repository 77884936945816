<template>
  <div class="f fv fc page-box" v-if="successFlag">
    <DownloadBar />
    <img src="https://img-fe.tengzhihh.com/image/dd9a19cedebd3b-144x144.png" class="icon" />
    <div class="tip">登录成功</div>
    <div class="sub-tip">你好，{{ userInfo.name || '尊敬的用户' }}，欢迎你的加入</div>
    <van-button @click="bind" type="info" class="btn" v-if="serialId">下一步</van-button>
    <van-button @click="addDevice" type="info" class="btn" v-else>添加我的设备</van-button>
  </div>
  <div v-else class="page-box f fc fvc loading-tip">跳转中...</div>
</template>

<script>
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
import DownloadBar from '@/components/DownloadBar/index.vue';
export default {
  components: {
    DownloadBar,
  },
  props: {
    sign: {
      type: String,
      default: '',
    },
    serialId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      successFlag: false,
    };
  },
  computed: {
    ...mapState(['userInfo']),
  },

  mounted() {
    this.$utils.checkToken(() => {
      this.$initWXSDK();
      this.getUserDevices();
    });
  },
  methods: {
    getUserDevices() {
      this.$http.get(apiPath.getUserDevices).then(res => {
        this.$store.commit('setUserMachinesList', res.data.list);
        if (res.data?.list?.length > 0) {
          this.$store.commit('setDevicesNumber', res.data.list.length);
          if (res.data.list.some(x => x.serial_id === this.serialId)) {
            //扫描已有设备id直接跳转
            this.$router.replace('/');
            return;
          }
          if (this.sign && this.serialId) {
            this.successFlag = true;
          } else {
            this.$router.replace('/');
          }
        } else {
          this.successFlag = true;
        }
      });
    },
    addDevice() {
      this.$tools.scanCode();
    },
    bind() {
      this.$router.replace(`/bind/index?serial_id=${this.serialId}&sign=${this.sign}`);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  width: 100%;
  height: calc(var(--vh) * 100);
  box-sizing: border-box;

  .icon {
    width: 72px;
    height: 72px;
    margin: 66px 0 30px;
  }
  .tip {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 8px;
  }
  .sub-tip {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
  }

  .btn {
    width: 299px;
    height: 44px;
    background: #f87a1b;
    border-radius: 10px;
    font-size: 16px;
    border: none;
    margin-top: 105px;
    line-height: 44px;
  }
}
.loading-tip {
  font-size: 14px;
  color: #000;
  line-height: 13px;
}
</style>
