<template>
  <div>
    <div class="list-box f fv fvc">
      <div class="btn-header f fc fbt">
        <img src="https://img-fe.tengzhihh.com/image/e1de3948873ac8-48x48.png" class="back-icon" @click="back" />

        <div class="f fv fvc" v-for="(item, idx) in configMap" :key="idx">
          <img v-if="currentOfferingType === item.type" :src="item.active" class="offering-btn" />
          <img v-else :src="item.default" class="offering-btn" />
          <div :class="`line ${currentOfferingType === item.type ? '' : 'transparent'}`"></div>
        </div>
      </div>

      <div class="list-body">
        <div class="group-item f fc" v-for="(group, idx) in currentList" :key="idx">
          <img src="https://img-fe.tengzhihh.com/image/e2021abed3c401-750x546.png" class="group-bg" />

          <div class="body-item f fv fvc" v-for="item in group" :key="item.id">
            <img v-lazy="item.image" :class="`item-img `" alt="" />

            <div class="name-box">
              <img :src="item.name_icon" class="name-img" />
            </div>

            <div class="option-area">
              <img
                src="https://img-fe.tengzhihh.com/image/a820b542e30df5-162x48.png"
                class="set-btn"
                @click="setOffering(item)"
              />
            </div>
          </div>
        </div>
        <img src="https://img-fe.tengzhihh.com/image/57e9ae954ed5e2-1125x220.png" class="shadow" />
      </div>
      <img src="https://img-fe.tengzhihh.com/image/1e033c3e04ba47-750x1558.png" class="bg" />
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import Dialog from '@/components/Dialog/Dialog.vue';

const configMap = [
  {
    type: 'flower',
    active: 'https://img-fe.tengzhihh.com/image/9596401b09b1e7-66x32.png',
    default: 'https://img-fe.tengzhihh.com/image/85d4d95fc65832-66x32.png',
    title: '花',
  },
  {
    type: 'incense',
    active: 'https://img-fe.tengzhihh.com/image/78233095586dca-66x32.png',
    default: 'https://img-fe.tengzhihh.com/image/86ac95b571256c-66x32.png',
    title: '香',
  },
  {
    type: 'fruit',
    active: 'https://img-fe.tengzhihh.com/image/d90a577c1a3aa3-66x32.png',
    default: 'https://img-fe.tengzhihh.com/image/cd7c625e8f4cb9-66x32.png',
    title: '果',
  },
  {
    type: 'candle',
    active: 'https://img-fe.tengzhihh.com/image/195a4037b7b200-68x32.png',
    default: 'https://img-fe.tengzhihh.com/image/c39c08e4be14c7-68x32.png',
    title: '蜡烛',
  },
];
const arrMap = ['', 'flower', 'incense', 'fruit', 'candle'];
export default {
  components: { Dialog },
  props: {
    serialId: {
      type: String,
      default: '',
    },
    godType: {
      type: [Number, String],
      default: 1,
    },
    godId: {
      type: [Number, String],
      default: 0,
    },
    indexMode: {
      type: [Number, String],
      default: 'all',
    },
    from: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      offeringsList: [],
      currentOfferingType: 'flower',
      selectedOfferings: {},
      activeClose: false,
      backType: '',
      next: null,
      loading: false,
    };
  },

  mounted() {
    this.getOfferingsList();
  },

  computed: {
    configMap() {
      return this.indexMode !== 'western' ? configMap : configMap.filter(x => x.type !== 'incense');
    },
    currentList: function () {
      const index = arrMap.findIndex(i => i === this.currentOfferingType);
      const tmp = this.offeringsList.find(x => x.type == index);
      return this.$tools.sliceArr(tmp?.list, 3);
    },
    calcConfigMap: function () {
      return this.configMap.filter(i => i.type === this.currentOfferingType);
    },
  },

  methods: {
    setNextFunc(next) {
      this.next = next;
    },
    handleLeave(backType) {
      this.backType = backType;
      const { flower, incense, fruit, candle } = this.selectedOfferings;
      // if (!flower || !incense || !fruit || !candle) {
      // } else {
      //   this.activeClose = true;
      // }

      this.$refs.dialog.initDialog({
        title: '您还没有上完供品',
        content: `您还没有上完供品，继续完成供花、${
          this.indexMode === 'western' ? '' : '供香、'
        }供果、供蜡烛即可获得10功德值，是否继续`,
        confirmText: '确认',
        cancelText: '取消',
        confirmCallback: () => {
          this.$refs.dialog.closeDialog();
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
          this.confirmBack();
        },
      });
    },
    back() {
      // 页面返回状态
      this.handleLeave('pageBack');
      this.activeClose && this.$emit('setValue', 'pageStep', 'index');
    },
    confirmBack() {
      this.activeClose = true;
      if (this.backType === 'routeBack') {
        this.next();
      } else {
        this.$emit('setValue', 'pageStep', 'index');
      }
    },

    getOfferingsList() {
      this.$http
        .get(apiPath.offeringsList, {
          serial_id: this.serialId,
          god_type: this.godType,
          god_id: this.godId,
          ver: this.indexMode === 'western' ? '1.1' : '1.0', //默认ver=1.0,ver=1.1时，西方宗教不返回供香
        })
        .then(res => {
          this.offeringsList = res.data;
        });
    },
    setOffering(item) {
      this.selectedOfferings[this.currentOfferingType] = item.id;
      const currentTabIndex = this.offeringsList.findIndex(x => x.type == item.type);
      if (item.type === 4) {
        this.handleSetOffering();
        return;
      }
      this.currentOfferingType = this.configMap[currentTabIndex + 1].type;
      this.$toast(`选${this.configMap[currentTabIndex].title}成功`);
    },
    handleSetOffering() {
      if (this.loading) {
        return false;
      }
      this.loading = true;
      this.$http
        .post(apiPath.setOffering, {
          serial_id: this.serialId,
          type: 'god',
          obj_id: this.godId,
          offerings: JSON.stringify(this.selectedOfferings),
          ver: this.indexMode === 'western' ? '1.1' : '1.0', //默认ver=1.0,ver=1.1时，西方宗教不返回供香
        })
        .then(res => {
          this.$wToast({
            icon: 'https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png',
            content: res.data.fude ? `供奉成功，功德值+${res.data.fude}` : `供奉成功`,
          });
          this.$track('H5_complete_gongpin_all');
          this.$track('H5_shenming_complete_gongpin');
          this.$track('complete_gongpin');
          this.$emit('updateGodInfo', this.godId);
          this.$emit('setValue', 'pageStep', 'index');
        })
        .catch(res => {
          if (res?.code === 400001) {
            this.$emit('updateGodInfo', this.godId);
            this.$emit('setValue', 'pageStep', 'index');
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.jump-btn {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 30px;
  z-index: 10000;
}
.bg {
  position: fixed;
  width: 100%;
  min-height: calc(var(--vh) * 100);
  left: 0;
  bottom: 0;
  z-index: -1;
}
.list-box {
  width: 375px;
  // min-height: calc(var(--vh) * 100);
  z-index: 100;
  .btn-header {
    box-sizing: border-box;
    width: 100%;
    padding: 0 90px;
    position: absolute;
    left: 0;
    top: 0;
    height: 44px;
    background-image: url('https://img-fe.tengzhihh.com/image/542f525d7ee85d-750x88.png');
    background-repeat: no-repeat;
    background-size: cover;
    .back-icon {
      width: 24px;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    .offering-btn {
      width: 33px;
      height: 16px;
    }
    .line {
      width: 34px;
      border-radius: 3px;
      height: 3px;
      background: #ff913e;
      margin-top: 5px;
    }
    .transparent {
      background: transparent !important;
    }
  }

  .list-body {
    padding: 44px 0 0;
    width: 100%;
    .shadow {
      width: 375px;
    }
    .group-item {
      position: relative;
      width: 100%;
      height: 273px;

      .group-bg {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .body-item {
      width: 33%;
      position: relative;
      .name-box {
        position: absolute;
        left: 85%;
        top: 50px;

        .name-img {
          width: 18px;
        }
      }
      .item-img {
        height: 210px;
        z-index: 1;
        position: relative;
        top: -6px;
      }
    }
    .option-area {
      z-index: 3;
      position: relative;
      top: 8px;
      .set-btn {
        width: 81px;
        height: 24px;
        margin-top: 6px;
      }
      .tag {
        width: 32px;
        height: 20px;
        position: absolute;
        right: -16px;
        top: -8px;
      }
      .moral {
        text-align: center;
        color: #8d2b00;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .tip {
      line-height: 1.5;
      font-size: 14px;
    }
    .btn {
      margin-top: 10px;
    }
  }
}
.confirm-box {
  width: 295px;
  background: #fff;
  border-radius: 5px;
  padding: 20px 20px 0;
  .tip {
    font-size: 16px;
    color: #3c3c3c;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .content {
    font-size: 14px;
    text-align: justify;
    margin-bottom: 20px;
  }
  .btn-box {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 0 35px;
    .btn {
      width: 100px;
      height: 43px;
      background: #f6f6f6;
      border-radius: 10px;
      font-size: 15px;
      border: none;
      color: rgba(0, 0, 0, 0.65);
    }
    .cancel {
      width: 100px;
      height: 43px;
      background: #f87a1b;
      border-radius: 10px;
      font-size: 15px;
      color: #fff;
    }
  }
}
</style>
