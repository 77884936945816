var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form" }, [
    _c("div", { staticClass: "field" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.wish_name,
            expression: "form.wish_name",
          },
        ],
        staticClass: "field-inner wish-name",
        attrs: { type: "text", placeholder: "祈福人名字" },
        domProps: { value: _vm.form.wish_name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "wish_name", $event.target.value)
          },
        },
      }),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.wish_address,
            expression: "form.wish_address",
          },
        ],
        staticClass: "field-inner wish-address",
        attrs: { autocomplete: "off", rows: "6", placeholder: "祈福人地址" },
        domProps: { value: _vm.form.wish_address },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "wish_address", $event.target.value)
          },
        },
      }),
    ]),
    _c("div", { staticClass: "field" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.wish_content,
            expression: "form.wish_content",
          },
        ],
        staticClass: "field-inner wish-content",
        attrs: { autocomplete: "off", rows: "12", placeholder: "你的祈福内容" },
        domProps: { value: _vm.form.wish_content },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "wish_content", $event.target.value)
          },
        },
      }),
    ]),
    _c(
      "div",
      { staticClass: "submit" },
      [
        _c(
          "van-button",
          {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce",
                value: _vm.submit,
                expression: "submit",
              },
            ],
            attrs: {
              disabled: !(
                _vm.form.wish_name &&
                _vm.form.wish_address &&
                _vm.form.wish_content
              ),
              block: "",
              color: "#F87A1B",
              size: "small",
              "native-type": "submit",
            },
          },
          [_vm._v("提交祈愿")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }