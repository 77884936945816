import { Toast } from 'vant';
import store from '@/store';
let rec = null;
export default {
  recOpen(cb) {
    store.commit('setAudioDuration', 0);

    if (Recorder.IsOpen()) {
      typeof cb === 'function' && cb();
      return false;
    }
    //一般在显示出录音按钮或相关的录音界面时进行此方法调用，后面用户点击开始录音时就能畅通无阻了
    rec = Recorder({
      //本配置参数请参考下面的文档，有详细介绍
      type: 'mp3',
      sampleRate: 16000,
      bitRate: 16, //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
      onProcess: function(buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) {
        //录音实时回调，大约1秒调用12次本回调
        //可利用extensions/waveview.js扩展实时绘制波形
        //可利用extensions/sonic.js扩展实时变速变调，此扩展计算量巨大，onProcess需要返回true开启异步模式
        store.commit('setAudioDuration', bufferDuration);
      },
    });

    //var dialog=createDelayDialog(); 我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调，此处demo省略了弹窗的代码
    rec.open(
      function() {
        typeof cb === 'function' && cb();
        //打开麦克风授权获得相关资源
        //dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
        //rec.start() 此处可以立即开始录音，但不建议这样编写，因为open是一个延迟漫长的操作，通过两次用户操作来分别调用open和start是推荐的最佳流程
      },
      function(msg, isUserNotAllow) {
        //用户拒绝未授权或不支持
        //dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
        Toast(msg);
      }
    );
  },
  recStart() {
    //打开了录音后才能进行start、stop调用
    rec.start();
  },

  recStop(cb) {
    rec &&
      rec.stop(
        (blob, duration) => {
          console.log(blob, (window.URL || webkitURL).createObjectURL(blob), '时长:' + duration + 'ms');
          this.recClose();
          typeof cb === 'function' && cb(blob, duration);
        },
        msg => {
          Toast('录音失败 ' + msg);
          this.recClose();
        }
      );
  },

  recClose() {
    rec && typeof rec.close === 'function' && rec.close();
    console.log('关闭录音');
    rec = null;
  },
};
