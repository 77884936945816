var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      attrs: { position: "bottom" },
      on: { "click-overlay": _vm.onCancel },
      model: {
        value: _vm.showFlag,
        callback: function ($$v) {
          _vm.showFlag = $$v
        },
        expression: "showFlag",
      },
    },
    [
      _c("div", { staticClass: "f fc fbt time-range-header" }, [
        _c("div", { staticClass: "cancel", on: { click: _vm.onCancel } }, [
          _vm._v("取消"),
        ]),
        _c("div", { staticClass: "title" }, [_vm._v("选择时间段")]),
        _c("div", { staticClass: "confirm", on: { click: _vm.onConfirm } }, [
          _vm._v("确定"),
        ]),
      ]),
      _vm.pickerType === "hourminter"
        ? _c("div", { staticClass: "f fc frd time-range-body" }, [
            _c(
              "div",
              { staticStyle: { width: "40%" } },
              [
                _c("van-datetime-picker", {
                  attrs: { type: "time", "show-toolbar": false },
                  model: {
                    value: _vm.beginTime,
                    callback: function ($$v) {
                      _vm.beginTime = $$v
                    },
                    expression: "beginTime",
                  },
                }),
              ],
              1
            ),
            _c("div", [_vm._v(" 至 ")]),
            _c(
              "div",
              { staticStyle: { width: "40%" } },
              [
                _c("van-datetime-picker", {
                  attrs: { type: "time", "show-toolbar": false },
                  model: {
                    value: _vm.endTime,
                    callback: function ($$v) {
                      _vm.endTime = $$v
                    },
                    expression: "endTime",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.pickerType === "hour"
        ? _c("div", { staticClass: "f fc frd time-range-body" }, [
            _c(
              "div",
              { staticStyle: { width: "40%" } },
              [
                _c("van-picker", {
                  attrs: {
                    columns: _vm.columns,
                    "default-index": _vm.defaultStart,
                  },
                  on: { change: _vm.changeStart },
                }),
              ],
              1
            ),
            _c("div", [_vm._v(" 至 ")]),
            _c(
              "div",
              { staticStyle: { width: "40%" } },
              [
                _c("van-picker", {
                  attrs: {
                    columns: _vm.columns,
                    "default-index": _vm.defaultEnd,
                  },
                  on: { change: _vm.changeEnd },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }