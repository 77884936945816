<template>
  <div>
    <!-- 1.一个用于获取上传文件的input，type="file"，并且监听onchange事件  -->
    <input type="file" ref="fileInput" id="imgReader" accept="image/*" v-if="$tools.isInApp()" @change="loadingImg" />
    <input type="file" ref="fileInput" id="imgReader" v-else-if="$tools.isHuaWei()" @change="loadingImg" />
    <input type="file" ref="fileInput" id="imgReader" accept="image/*" v-else @change="loadingImg" />

    <div class="com-img-picker-box" v-if="showCom">
      <img id="cropImg" />
      <div class="btn-group f fc frd">
        <van-button @click="cancel" class="cancel-btn" size="small">取消</van-button>

        <van-button @click="confirm" class="confirm-btn" size="small" :loading="uploadLoading">确定</van-button>
      </div>
    </div>

    <van-overlay :show="uploadLoading">
      <van-loading size="24px" color="#fff" vertical>
        <div class="f fv fvc uploading-box">
          <div class="top-tip">正在上传数据</div>
          <div class="progress">
            {{ uploadProgress }}
          </div>
          <div class="main-tip">请保持网络畅通</div>
          <div class="main-tip">不要离开本页面</div>
        </div>
      </van-loading>
    </van-overlay>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
let CROPPER;
export default {
  props: {
    aspectRatio: {
      type: [String, Number, NaN],
      default: NaN,
    },
    from: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      showCom: false,
      fileName: '',
      uploadLoading: false,
    };
  },

  computed: {
    ...mapState(['uploadProgress', 'userInfo']),
  },

  methods: {
    cancel() {
      this.$refs.fileInput.value = '';
      this.showCom = false;
    },
    uploadImg() {
      this.$store.commit('setUploadProgress', '0%');

      document.querySelector('#imgReader').click();
    },
    loadingImg(e) {
      console.log('获取到照片流', e);
      try {
        let reader = new FileReader();
        if (e.target.files[0]) {
          this.fileName = e.target.files[0].name;
          const split_list = this.fileName.split('.');
          let ext = split_list[split_list.length - 1];

          if (!this.$tools.isPicture(ext)) {
            return this.$toast('请选择图片');
          }
          this.showCom = true;

          reader.readAsDataURL(e.target.files[0]);
          reader.onload = e => {
            let dataURL = reader.result;
            document.querySelector('#cropImg').src = dataURL;

            const image = document.getElementById('cropImg');

            CROPPER = new Cropper(image, {
              viewMode: 2,
              aspectRatio: this.aspectRatio,
              containerWidth: '100vw',
              containerHeight: '500px',
              dragMode: 'crop',
            });
          };
        }
      } catch (error) {
        this.showCom = false;
        return this.$toast('处理失败，请刷新后重试');
      }
    },
    confirm() {
      this.uploadLoading = true;
      this.showCom = false;

      try {
        CROPPER.getCroppedCanvas({
          maxWidth: 1500,
          maxHeight: 1500,
          fillColor: '#fff',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
        }).toBlob(blob => {
          const file = new window.File([blob], `${new Date().getTime()}_${this.fileName}`, { type: blob.type });
          const formData = new FormData();
          formData.append('file', file);
          this.from && formData.append('type', this.from);
          this.$http
            .formPost(
              apiPath.uploadImg,
              formData,
              {
                'Content-Type': 'multipart/form-data	',
              },
              {
                needProgress: true,
              }
            )
            .then(res => {
              this.uploadLoading = false;
              this.$refs.fileInput.value = '';
              this.$emit('emitImgUrl', { url: res.data.url, size: parseInt(file.size / 1024) || 1 });
            })
            .catch(err => {
              console.error(err);
              this.$refs.fileInput.value = '';
              this.uploadLoading = false;

              this.$toast('上传失败，请重试');
            });
        });
      } catch (err) {
        console.error(err);
        this.$refs.fileInput.value = '';
        this.uploadLoading = false;
        this.$toast('图片获取失败，请重试');
      }
    },
  },
};
</script>
<style lang="less">
.cropper-bg {
  background: #333333 !important;
}
</style>

<style lang="less" scoped>
#imgReader {
  display: none;
}

.uploading-box {
  box-sizing: border-box;
  padding: 37px 0 20px;
  width: 281px;
  background: #fff;
  border-radius: 15px;
  .top-tip {
    font-size: 18px;
    font-weight: 500;
    color: #333333;
  }
  .progress {
    font-size: 34px;
    font-weight: 500;
    color: #333333;
    margin: 44px 0;
  }
  .main-tip {
    font-size: 15px;
    font-weight: 500;
    color: #af6a34;
  }
  .cancel-btn {
    margin-top: 44px;
    width: 218px;
    height: 43px;
    background: #f87a1b;
    border-radius: 15px;
    font-size: 15px;
    font-weight: bold;
    color: #ffeac9;
  }
}

.com-img-picker-box {
  height: calc(var(--vh) * 100);
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 2000;
  img #cropImg {
    height: calc(var(--vh) * 100);
    width: 100vw;
    box-shadow: 0 0 5px #adadad;
  }
  .btn-group {
    width: 100vw;
    position: fixed;
    bottom: 20px;
    .cancel-btn {
      width: 100px;
      height: 40px;
      background: #f6f6f6;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.65);
      border-radius: 10px;
      border: none;
    }

    .confirm-btn {
      width: 100px;
      height: 40px;
      background: #f87a1b;
      border-radius: 10px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #ffeac9;
      border: none;
    }
  }
}
</style>
