<template>
  <div class="empty-list f fc fvc"
    v-if="list.length===0">暂无内容</div>
  <div class="list-box f fbt "
    v-else
    id='goods-list'>
    <div class="left">
      <list-item v-for="(item,index) in leftList"
        :key="index"
        :item="item"></list-item>
    </div>
    <div class="right">
      <list-item v-for="(item,index) in rightList"
        :key="index"
        :item="item"></list-item>
    </div>

  </div>

</template>

<script>
import ListItem from '@/components/Goods/ListItem';
export default {
  components: {
    ListItem,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    leftList() {
      return this.list.filter((i, idx) => idx % 2 === 0);
    },
    rightList() {
      return this.list.filter((i, idx) => idx % 2 === 1);
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.list-box {
  padding: 0 9px;
  flex-direction: row;
}
.empty-list {
  font-size: 14px;
}
</style>