var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid-list" },
    [
      _c(
        "van-grid",
        { attrs: { border: false, "column-num": _vm.columnNum } },
        _vm._l(_vm.list, function ({ icon, label, name, badge }) {
          return _c(
            "van-grid-item",
            {
              key: name,
              on: {
                click: function ($event) {
                  return _vm.clickFn({ name, badge })
                },
              },
            },
            [
              _c("van-badge", { attrs: { content: badge, max: "99" } }, [
                _c("img", { staticClass: "icon", attrs: { src: icon } }),
              ]),
              _c("div", { staticClass: "name" }, [_vm._v(_vm._s(label))]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }