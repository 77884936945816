<template>
  <div class="page-box">
    <van-cell title="自动供奉" is_link center>
      <template #right-icon>
        <van-switch
          active-color="#f87a1b"
          inactive-color="#ffeac9"
          size="20px"
          :value="generalConfig.auto_offering.status ? true : false"
          @input="gongfengChange"
        />
      </template>
    </van-cell>
    <van-cell is_link center @click="handleTimeclick">
      <template #title>
        {{
          generalConfig.auto_offering.offering_time && generalConfig.auto_offering.offering_time >= 0
            ? generalConfig.auto_offering.offering_time
            : 0
        }}点
      </template>
      <template #right-icon>
        <van-button type="default" size="mini" round>设置时间</van-button>
      </template>
    </van-cell>
    <van-popup v-model="timePicker" position="bottom">
      <van-picker
        show-toolbar
        title="选择时间"
        :columns="columns"
        :default-index="generalConfig.auto_offering.offering_time ? generalConfig.auto_offering.offering_time - 1 : 0"
        @cancel="onCancel"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      serialId: '',
      timePicker: false,
      columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
    };
  },
  computed: {
    ...mapState(['generalConfig']),
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.$utils.getGeneralConfig(this.serialId);
  },
  methods: {
    gongfengChange(val) {
      this.$utils.updateGeneralConfig(this.serialId, [
        {
          key: 'auto_offering',
          value: JSON.stringify({
            status: val ? 1 : 0,
            offering_time: this.currentTime,
          }),
        },
      ]);
    },
    handleTimeclick() {
      if (this.generalConfig.auto_offering.status) {
        this.timePicker = true;
      } else {
        this.$toast('请先开启自动供奉功能');
      }
    },
    onConfirm(val) {
      this.currentTime = val;
      this.timePicker = false;
      this.$utils.updateGeneralConfig(this.serialId, [
        {
          key: 'auto_offering',
          value: JSON.stringify({
            status: this.generalConfig.auto_offering ? this.generalConfig.auto_offering.status : 0,
            offering_time: this.currentTime,
          }),
        },
      ]);
    },
    onCancel() {
      this.timePicker = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
</style>
