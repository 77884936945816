<template>
  <div id="god-index-container">
    <div v-if="pageStep === 'index'">
      <div class="index-top f fc fbt">
        <img src="https://img-fe.tengzhihh.com/image/5e5eeee8268676-56x56.png" class="top-icon" @click="goDevice" />

        <div :class="`index-top-box f fc fvc ${indexTopBoxClss}`">
          <div class="btn" @click="$utils.checkAuth(routeQuery.serial_id, inviteGod)">恭请神明</div>
          <div class="btn" @click="enshrineSetting">供奉设置</div>
        </div>
        <img
          src="https://img-fe.tengzhihh.com/image/01875a0e427436-56x56.png"
          class="top-icon"
          @click="showExplanation"
        />
      </div>

      <img :src="currentGodInfo.wap_background_image || default_img.wap_background_image" class="god-bg" />

      <div class="flags-box f fc fbt" v-if="currentGodInfo.left_golden_flag_image">
        <img :src="currentGodInfo.left_golden_flag_image" class="flag-item left" />
        <img :src="currentGodInfo.right_golden_flag_image" class="flag-item right" />
      </div>

      <swiper class="swiper" :options="swiperOption" ref="godSwiper">
        <swiper-slide v-for="item in godList" :key="item.id">
          <GodIndex
            :godType="godType"
            :serialId="routeQuery.serial_id"
            :godData="item.godData"
            :godId="item.god_id"
            :emptyIndex="!!item.emptyIndex"
            :indexMode="indexMode"
            :religion="religion"
            :activeIndex="activeIndex"
            :defaultImg="item.default_img"
            @setValue="setValue"
            @openPopup="openPopup"
            @updateGodList="updateGodList"
            @updateIndex="updateIndex"
          />
        </swiper-slide>

        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div v-else-if="pageStep === 'list'">
      <GodList
        :godType="godType"
        :serialId="routeQuery.serial_id"
        :indexMode="indexMode"
        :religion="religion"
        from="god_index"
        @setValue="setValue"
        @updateGodList="updateGodList"
      />
    </div>
    <div v-else-if="pageStep === 'offerings'">
      <OfferingsList
        :godType="godType"
        :serialId="routeQuery.serial_id"
        :godId="currentGodInfo.id"
        :indexMode="indexMode"
        from="godIndex"
        ref="OfferingsListRef"
        @setValue="setValue"
        @updateGodInfo="updateGodInfo"
      />
    </div>
    <div v-else-if="pageStep === 'detail'">
      <GodDetail :godDetail="godPreviewDetail" from="godIndex" @setValue="setValue" />
    </div>

    <div v-else-if="pageStep === 'explanation'">
      <Explanation @setValue="setValue" />
    </div>

    <Guide v-if="showGuide && pageStep === 'index'" ref="guideRef" @setValue="setValue" />
    <SendGod
      ref="SendGodRef"
      :serialId="routeQuery.serial_id"
      :godInfo="currentGodInfo"
      @updateGodList="updateGodList"
    />
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import apiPath from '@/utils/apiPath';
import GodList from '@/components/God/GodList.vue';
import OfferingsList from '@/components/God/OfferingsList.vue';
import GodDetail from './comp/GodDetail.vue';
import GodIndex from './comp/GodIndex.vue';
import Guide from './comp/Guide.vue';

import SendGod from './comp/SendGod.vue';
import Explanation from './comp/Explanation.vue';
import sendMsg from '@/webSocket/sendMsg';
import { commonOperate } from '@/mixins/commonOperate';
export default {
  mixins: [commonOperate],
  components: {
    Swiper,
    SwiperSlide,
    GodIndex,
    GodDetail,
    GodList,
    OfferingsList,
    Guide,
    SendGod,
    Explanation,
  },
  data() {
    const vue = this; //保存Vue实例
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        on: {
          slideChangeTransitionStart: function () {
            // 此处的this为swiper实例
            vue.$track('gongfeng_left_and_right_switch');
            vue.activeIndex = this.activeIndex;
            sendMsg(+vue.godType === 2 ? 'pageViewListMoveLand' : 'pageViewListMove', this.activeIndex);
            vue.$tools.setDocumentTitle(vue.godList[vue.activeIndex]?.godData.god.name);
          },
        },
      },
      swiper: null,
      godList: [],
      pageStep: 'index',
      godType: 1, //1 神佛 2 土地公
      indexMode: '',
      religion: '',
      godPreviewDetail: null,
      fudeListFlag: false,
      sendGodFlag: false,
      activeClose: false,
      showGuide: false,
      activeIndex: 0,
      incense_capacity: '',
      default_img: {},
    };
  },
  created() {
    this.godType = this.$route.query.god_type || 1;
    this.pageStep = this.$route.query.page_step || 'index';
    this.religion = this.$route.query.religion;
  },
  beforeRouteLeave(to, from, next) {
    typeof this.$refs?.OfferingsListRef?.setNextFunc === 'function' && this.$refs.OfferingsListRef.setNextFunc(next);
    if (this.$refs?.OfferingsListRef?.activeClose || this.activeClose || this.pageStep !== 'offerings') {
      next();
    } else {
      typeof this.$refs?.OfferingsListRef?.handleLeave === 'function' &&
        this.$refs.OfferingsListRef.handleLeave('routeBack');
    }
  },
  mounted() {
    this.getCurGodList();
  },
  computed: {
    currentGodInfo: function () {
      return (
        this.godList[this.activeIndex]?.godData?.god || {
          god_id: 0,
          emptyIndex: true,
          wap_background_image: this.isWestern
            ? 'https://img-fe.tengzhihh.com/image/75fe1240d4572e-750x1624.png'
            : 'https://img-fe.tengzhihh.com/image/a2fb4519b7abd5-750x1624.png',
        }
      );
    },
    isWestern: function () {
      return this.indexMode === 'western';
    },
    indexTopBoxClss: function () {
      if (this.indexMode === 'western') return 'western';
      if (this.indexMode) return 'no-western';
      return '';
    },
  },
  watch: {
    pageStep: function (n) {
      document.getElementById('god-index-container').scrollTo(0, 0);
      if (n === 'index') {
        this.sliceToTarget();
      }
    },
  },
  methods: {
    getSystemInfo() {
      sendMsg('systemInfo', {
        operation: 'getBatteryStatus',
      });
    },
    handleMsg(msg) {
      const action = msg.action;
      const operation = msg.data.operation;
      const map = {
        systemInfo: {
          operation: {
            updateBatteryStatus: () => {
              this.updateBatteryStatus(msg);
            },
            earthGodListRefresh: () => {
              this.updateGodList(this.godList[this.activeIndex].god_id);
            },
            godListRefresh: () => {
              this.updateGodList(this.godList[this.activeIndex].god_id);
            },
            godRefresh: () => {
              this.updateGodList(msg.data.id);
            },
          },
        },
      };
      const actionCb = map[action]?.cb;
      const operationCb = map[action] && map[action]['operation'] && map[action]['operation'][operation];
      typeof actionCb === 'function' && actionCb();
      typeof operationCb === 'function' && operationCb();
    },
    updateBatteryStatus(msg) {
      if (msg.action !== 'systemInfo') return;
      //香薰容量
      this.$store.commit('setGeneralConfig', { incenseCapacity: msg.data.incenseCapacity });
    },
    updateIndex(e) {
      e === 'left' ? this.activeIndex-- : this.activeIndex++;
      sendMsg(+this.godType === 2 ? 'pageViewListMoveLand' : 'pageViewListMove', this.activeIndex);
      this.$track('gongfeng_left_and_right_switch');
      this.sliceToTarget();
      this.$tools.setDocumentTitle(this.godList[this.activeIndex].godData.god.name);
    },
    sliceToTarget() {
      this.$nextTick(() => this.$refs?.godSwiper?.$swiper?.slideTo(this.activeIndex));
    },
    goDevice() {
      this.activeClose = true;
      this.$router.go(-1);
    },
    inviteGod() {
      this.$track('gongfeng_gongqing_button_click');
      this.pageStep = 'list';
      this.$tools.setDocumentTitle('恭请神明');
    },
    showExplanation() {
      this.$track('gongfeng_shuoming_button_click');
      this.pageStep = 'explanation';
      this.$tools.setDocumentTitle('供奉说明');
    },
    updateGodList(god_id) {
      this.showGuide = false;
      this.sliceToTarget();
      this.$nextTick(async () => {
        this.godList = [];
        await this.getCurGodList();
        if (god_id) {
          const index = this.godList.findIndex(i => i.god_id == god_id);
          if (index !== -1) {
            this.activeIndex = index;
            this.sliceToTarget();
          }
        } else {
          this.activeIndex = 0;
        }
      });
    },
    async updateGodInfo(god_id) {
      this.showGuide = false;
      const data = await this.getGodInfo(god_id);
      const index = this.godList.findIndex(i => i.god_id == god_id);
      if (index !== -1) {
        this.godList.splice(index, 1, {
          ...this.godList[index],
          godData: data,
        });
      }
    },
    getCurGodList() {
      return this.$http
        .get(apiPath.currentGodList, {
          god_type: this.godType,
          serial_id: this.routeQuery.serial_id,
        })
        .then(async res => {
          this.default_img = res.data.default_img;
          this.indexMode = res.data.index_mode;
          this.religion = res.data.religion;
          if (res.data.first_use_guide === 1 && res.data.index_mode !== 'western') {
            this.showGuide = true;
            this.$nextTick(() => {
              this.$track('xinshou_tanchuan_gongpin_show');
              typeof this.$refs.guideRef.show === 'function' && this.$refs.guideRef.show();
            });
          }
          const list = await Promise.all(
            res.data.list.map(async i => {
              const data = await this.getGodInfo(i.god_id);
              return {
                ...i,
                godData: data,
              };
            })
          );
          this.godList = [
            ...list,
            {
              emptyIndex: true,
              godId: 0,
              default_img: res.data.default_img,
              godData: {
                god: {
                  name: '眷吉',
                },
              },
            },
          ];
          this.$tools.setDocumentTitle(this.godList[this.activeIndex]?.godData.god.name);
        });
    },
    async getGodInfo(id) {
      if (!id) {
        return false;
      }
      const params = {
        god_type: this.godType,
        serial_id: this.routeQuery.serial_id,
        god_id: id,
        ver: '1.1',
      };
      const res = await this.$http.get(apiPath.currentGod, params);
      return res.data;
    },
    setValue(key, value) {
      this[key] = value;
      this.$tools.setDocumentTitle(this.godList[this.activeIndex]?.godData.god.name);
    },
    openPopup(ref, godId) {
      const index = this.godList.findIndex(i => i.god_id == godId);
      if (index !== -1) {
        this.activeIndex = index;
      }
      typeof this.$refs[ref]?.open === 'function' && this.$refs[ref].open();
    },
    enshrineSetting() {
      this.$track('gongfeng_mygongfeng_button_click');
      this.$router.push({
        name: 'enshrineSetting',
        query: this.$route.query,
      });
    },
  },
};
</script>

<style lang="less" scoped>
#god-index-container::-webkit-scrollbar {
  width: 0 !important;
}

#god-index-container {
  height: calc(var(--vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.hidden-overflow {
  overflow: hidden !important;
}
.index-top {
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  padding: 0 12px;
  box-sizing: border-box;
  .top-icon {
    width: 28px;
    height: 28px;
  }
  .index-top-box {
    width: 236px;
    height: 47px;
    position: relative;
    top: -2px;
    background-size: 100%;
    background-repeat: no-repeat;

    .btn {
      width: 72px;
      height: 20px;
      border-radius: 10px;
      line-height: 20px;
      text-align: center;
      font-size: 12px;
      margin: 0 8px;
      position: relative;
      top: -4px;
    }
  }
  .no-western {
    background-image: url('https://img-fe.tengzhihh.com/image/06e50b31c6a6ec-472x94.png');
    .btn {
      color: #ff913e;
      border: 1px solid #ba7e60;
      background: #884f30;
    }
  }
  .western {
    background-image: url('https://img-fe.tengzhihh.com/image/58581da2165ebe-472x94.png') !important;
    .btn {
      color: #ff913e !important;
      border: 1px solid #f1dca4;
      background: #ffffd5;
    }
  }
}
.god-bg {
  position: absolute;
  width: 375px;
  height: 821px;
  object-fit: cover;
}
.flags-box {
  width: 100%;
  position: absolute;
  .flag-item {
    width: 58px;
    height: 336px;
  }
}
/deep/.swiper-pagination {
  position: absolute;
  top: 630px;
  .swiper-pagination-bullet-active {
    background: #f87a1b;
  }
  .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
  }
}
.enshrine-pop-title {
  line-height: 30px;
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 2px;
}
.enshrine-pop-text {
  width: 255px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin: 20px 0 30px;
}
</style>
<style lang="less">
#god-index-container {
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    height: 30px;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal {
    // height: 780px;
  }
}
</style>
