var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "list-box f fv fvc" }, [
        _c(
          "div",
          { staticClass: "btn-header f fc fbt" },
          [
            _c("img", {
              staticClass: "back-icon",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/e1de3948873ac8-48x48.png",
              },
              on: { click: _vm.back },
            }),
            _vm._l(_vm.configMap, function (item, idx) {
              return _c("div", { key: idx, staticClass: "f fv fvc" }, [
                _vm.currentOfferingType === item.type
                  ? _c("img", {
                      staticClass: "offering-btn",
                      attrs: { src: item.active },
                    })
                  : _c("img", {
                      staticClass: "offering-btn",
                      attrs: { src: item.default },
                    }),
                _c("div", {
                  class: `line ${
                    _vm.currentOfferingType === item.type ? "" : "transparent"
                  }`,
                }),
              ])
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "list-body" },
          [
            _vm._l(_vm.currentList, function (group, idx) {
              return _c(
                "div",
                { key: idx, staticClass: "group-item f fc" },
                [
                  _c("img", {
                    staticClass: "group-bg",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/e2021abed3c401-750x546.png",
                    },
                  }),
                  _vm._l(group, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "body-item f fv fvc" },
                      [
                        _c("img", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy",
                              value: item.image,
                              expression: "item.image",
                            },
                          ],
                          class: `item-img `,
                          attrs: { alt: "" },
                        }),
                        _c("div", { staticClass: "name-box" }, [
                          _c("img", {
                            staticClass: "name-img",
                            attrs: { src: item.name_icon },
                          }),
                        ]),
                        _c("div", { staticClass: "option-area" }, [
                          _c("img", {
                            staticClass: "set-btn",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/a820b542e30df5-162x48.png",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setOffering(item)
                              },
                            },
                          }),
                        ]),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
            _c("img", {
              staticClass: "shadow",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/57e9ae954ed5e2-1125x220.png",
              },
            }),
          ],
          2
        ),
        _c("img", {
          staticClass: "bg",
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/1e033c3e04ba47-750x1558.png",
          },
        }),
      ]),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }