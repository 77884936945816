var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$utils.calcUserIsMasterWithSerialId(_vm.routeQuery.serial_id)
        ? _c("div", { staticClass: "fude f fc", on: { click: _vm.openFude } }, [
            _c("img", {
              staticClass: "fude-img",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png",
              },
            }),
            _c("div", { staticClass: "fude-text" }, [
              _vm._v("香火" + _vm._s(_vm.godInfo.user_god_fude || 0)),
            ]),
          ])
        : _vm._e(),
      _vm.deceasedDetail.images_icon
        ? _c(
            "div",
            {
              staticClass: "photo-icon",
              on: { click: _vm.viewDeceasedDetail },
            },
            [
              _c("img", {
                attrs: {
                  src: _vm.deceasedDetail.images_icon,
                  alt: "",
                  srcset: "",
                },
              }),
              _c("p", [_vm._v("相册")]),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "enshrine-item" }, [
        _vm.godInfo.decease_show == 1
          ? _c("div", { staticClass: "enshrine-center-0 f abs-c" }, [
              _c("img", {
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/9bfdaf39ae173d8970ee25236b3322c4-314x662.png",
                },
              }),
              _c("div", { staticClass: "enshrine-name abs-c" }, [
                _vm._v(_vm._s(_vm.godInfo.name)),
              ]),
            ])
          : _vm._e(),
        _vm.godInfo.decease_show == 2
          ? _c("img", {
              staticClass: "enshrine-center-1 abs-c",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/fca11a486c34d8a43eb45832ff8cd5be-764x1014.png",
              },
            })
          : _vm._e(),
        _vm.godInfo.decease_show == 2
          ? _c("img", {
              staticClass: "enshrine-img abs-c",
              attrs: { src: _vm.godInfo.avatar, alt: "", srcset: "" },
            })
          : _vm._e(),
      ]),
      _c("img", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce",
            value: _vm.touchOffering,
            expression: "touchOffering",
          },
        ],
        staticClass: "offering-item hua left",
        attrs: { src: _vm.flower.offer_left_image },
      }),
      _c("img", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce",
            value: _vm.touchOffering,
            expression: "touchOffering",
          },
        ],
        staticClass: "offering-item hua right",
        attrs: { src: _vm.flower.offer_right_image },
      }),
      _c("img", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce",
            value: _vm.touchOffering,
            expression: "touchOffering",
          },
        ],
        staticClass: "offering-item xiang",
        attrs: { src: _vm.incense.offer_left_image },
      }),
      _c("img", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce",
            value: _vm.touchOffering,
            expression: "touchOffering",
          },
        ],
        staticClass: "offering-item shuiguo left",
        attrs: { src: _vm.fruit.offer_left_image },
      }),
      _c("img", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce",
            value: _vm.touchOffering,
            expression: "touchOffering",
          },
        ],
        staticClass: "offering-item shuiguo right",
        attrs: { src: _vm.fruit.offer_right_image },
      }),
      _c("img", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce",
            value: _vm.touchOffering,
            expression: "touchOffering",
          },
        ],
        staticClass: "offering-item lazhu left",
        attrs: { src: _vm.candle.offer_left_image },
      }),
      _c("img", {
        directives: [
          {
            name: "debounce",
            rawName: "v-debounce",
            value: _vm.touchOffering,
            expression: "touchOffering",
          },
        ],
        staticClass: "offering-item lazhu right",
        attrs: { src: _vm.candle.offer_right_image },
      }),
      !_vm.emptyOfferings
        ? _c("img", {
            staticClass: "fire-ani fire-left",
            attrs: { src: _vm.fireImg },
          })
        : _vm._e(),
      !_vm.emptyOfferings
        ? _c("img", {
            staticClass: "fire-ani fire-right",
            attrs: { src: _vm.fireImg },
          })
        : _vm._e(),
      _c("div", {
        staticClass: "yan-svga",
        attrs: { id: `yan-svga-${_vm.enshrineId}` },
      }),
      _c("img", {
        staticClass: "incense-image",
        attrs: {
          src:
            _vm.incense_image ||
            "https://img-fe.tengzhihh.com/image/7c9e5043451050-120x120.png",
        },
        on: { click: _vm.handleIncense },
      }),
      _c("img", {
        staticClass: "bg-img-desk",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/e4602cce5cc2f3c7192e4f89f1a27156-750x247.png",
        },
      }),
      _c("div", { staticClass: "main-btn-list f" }, [
        _vm.godInfo.is_default === 0
          ? _c(
              "div",
              {
                staticClass: "set-main-btn",
                on: {
                  click: function ($event) {
                    return _vm.$utils.checkAuth(
                      _vm.routeQuery.serial_id,
                      _vm.priorityWorship
                    )
                  },
                },
              },
              [_vm._v(" 设为主位 ")]
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "debounce",
                rawName: "v-debounce",
                value: _vm.touchOffering,
                expression: "touchOffering",
              },
            ],
            staticClass: "set-main-btn",
          },
          [_vm._v("供奉")]
        ),
        _c(
          "div",
          { staticClass: "set-main-btn", on: { click: _vm.autoOffering } },
          [_vm._v("一键供奉")]
        ),
      ]),
      _c("Fude", {
        ref: "FudeRef",
        attrs: {
          godFude: _vm.godInfo.user_god_fude,
          godName: _vm.godInfo.name,
          todayTask: _vm.todayTask,
        },
        on: { goOffer: _vm.goOffer, viewFude: _vm.viewFude },
      }),
      _c("OfferingsTimeout", {
        ref: "OfferingsTimeoutRef",
        attrs: {
          offerings: _vm.offerings,
          time: _vm.calcExpireTime,
          offerTarget: "逝者",
        },
      }),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }