var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "van-popup",
    {
      staticClass: "confirm-popup",
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("div", { staticClass: "confirm-title" }, [_vm._v("确认兑换信息")]),
      _c(
        "div",
        { staticClass: "confirm-content" },
        [
          _c("div", { staticClass: "confirm-item" }, [
            _c("span", { staticClass: "confirm-label" }, [
              _vm._v("收件人名称"),
            ]),
            _c("span", { staticClass: "confirm-value" }, [
              _vm._v(_vm._s(_vm.name)),
            ]),
          ]),
          _c("div", { staticClass: "confirm-item" }, [
            _c("span", { staticClass: "confirm-label" }, [
              _vm._v("收件人手机号"),
            ]),
            _c("span", { staticClass: "confirm-value" }, [
              _vm._v(_vm._s(_vm.phone)),
            ]),
          ]),
          _c("div", { staticClass: "confirm-item" }, [
            _c("span", { staticClass: "confirm-label" }, [
              _vm._v("收件人地址"),
            ]),
            _c("span", { staticClass: "confirm-value" }, [
              _vm._v(_vm._s(_vm.address)),
            ]),
          ]),
          _c("div", { staticClass: "confirm-item" }, [
            _c("span", { staticClass: "confirm-label" }, [_vm._v("兑换物品")]),
            _c("span", { staticClass: "confirm-value" }, [
              _vm._v(_vm._s(_vm.giftName) + "×" + _vm._s(_vm.giftNum)),
            ]),
          ]),
          _c(
            "van-button",
            {
              staticClass: "confirm-btn",
              attrs: { round: "", block: "", color: "#ff8e38" },
              on: { click: _vm.close },
            },
            [_vm._v("确认")]
          ),
          _c("div", { staticClass: "kefu", on: { click: _vm.kefu } }, [
            _vm._v("联系客服"),
          ]),
        ],
        1
      ),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }