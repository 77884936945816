var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-page-box" },
    [
      _c("img", {
        staticClass: "bg",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png",
        },
      }),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "textarea-box" },
            [
              _c("van-field", {
                staticClass: "textarea-area",
                attrs: {
                  type: "textarea",
                  maxlength: "300",
                  autosize: "",
                  "show-word-limit": "",
                  placeholder: "请输入你想说的话",
                },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              }),
            ],
            1
          ),
          _vm.mottoType === "video"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "tip" }, [_vm._v("我的视频")]),
                  _vm.playing
                    ? _c("van-icon", {
                        staticClass: "back",
                        attrs: { name: "arrow-left", size: "24px" },
                        on: { click: _vm.stopPlay },
                      })
                    : _vm._e(),
                  _c("video", {
                    class: `video-com ${_vm.playing ? "full" : ""}`,
                    staticStyle: { "object-fit": "contain" },
                    attrs: {
                      id: "edit-video",
                      playsinline: "",
                      allowfullscreen: "",
                      controls: _vm.playing,
                      "x5-video-player-type": "h5",
                    },
                    on: { click: _vm.playVideo },
                  }),
                  _c(
                    "div",
                    { staticClass: "images-box" },
                    _vm._l(_vm.images, function (item, index) {
                      return _c("div", { key: index, staticClass: "img-box" }, [
                        _c("img", {
                          staticClass: "image-item",
                          attrs: { src: item.url },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.preview(item)
                            },
                          },
                        }),
                        _c("img", {
                          staticClass: "del-icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/526d3d05af8245-50x50.png",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.delImg(item, index)
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                  _vm.images.length === 0
                    ? _c(
                        "div",
                        {
                          staticClass: "cover-tip f fc",
                          on: {
                            click: function ($event) {
                              return _vm.chooseImg(1)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "tip-img",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/64779b7183de79-32x32.png",
                            },
                          }),
                          _vm._v(" 添加封面图 "),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.mottoType === "audio"
            ? _c("div", [
                _c("audio", { attrs: { controls: "", id: "edit-audio" } }),
                _c("div", { staticClass: "tip" }, [_vm._v("我的音频")]),
                _c(
                  "div",
                  { staticClass: "icon-box", on: { click: _vm.playAudio } },
                  [
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.audioPlaying,
                          expression: "!audioPlaying",
                        },
                      ],
                      staticClass: "icon-gif",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/4a8cb9c7d0e114-58x47.png",
                      },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.audioPlaying,
                          expression: "audioPlaying",
                        },
                      ],
                      staticClass: "icon-gif",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/537b413f01a8e4-59x50.gif",
                      },
                    }),
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src: "https://img-fe.tengzhihh.com/image/b25c67d927904b-300x90.png",
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "tip" }, [_vm._v("上传照片")]),
                _c(
                  "div",
                  { staticClass: "images-box f fc flex-wrap" },
                  [
                    _vm._l(_vm.images, function (item, index) {
                      return _c("div", { key: index, staticClass: "img-box" }, [
                        _c("img", {
                          attrs: { src: item.url },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.preview(item)
                            },
                          },
                        }),
                        _c("img", {
                          staticClass: "del-icon",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/526d3d05af8245-50x50.png",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.delImg(item, index)
                            },
                          },
                        }),
                      ])
                    }),
                    _vm.images.length < 9
                      ? _c(
                          "div",
                          {
                            staticClass: "img-box f fv fvc fc border",
                            on: {
                              click: function ($event) {
                                return _vm.chooseImg(2)
                              },
                            },
                          },
                          [
                            _c("van-icon", {
                              attrs: {
                                name: "plus",
                                size: "16px",
                                color: "#000",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "time-tip" }, [_vm._v("设定播放时间")]),
          _c(
            "div",
            {
              staticClass: "select-time-box f fc fbt",
              on: { click: _vm.openDatePicker },
            },
            [
              _c("div", { staticClass: "tip" }, [
                _vm._v(_vm._s(_vm.pushTime || "点击选择时间")),
              ]),
              _c("van-icon", { attrs: { name: "arrow", size: "20px" } }),
            ],
            1
          ),
          _c(
            "van-button",
            {
              staticClass: "save-btn",
              attrs: { type: "primary", loading: _vm.saveLoading },
              on: { click: _vm.preSave },
            },
            [_vm._v(_vm._s(_vm.saveLoading ? "上传中" : "上传"))]
          ),
        ],
        1
      ),
      _c("date-picker", {
        attrs: {
          pickerType: "datetime",
          show: _vm.datePickerFlag,
          filter: _vm.timeFilter,
          propMinDate: new Date(),
          propMaxDate: new Date(2100, 0, 0),
        },
        on: { onCancel: _vm.onCancel, onConfirm: _vm.onConfirm },
      }),
      _c("img-picker", {
        ref: "comImgPicker",
        attrs: { from: _vm.imgType === 1 ? "4" : "5" },
        on: { emitImgUrl: _vm.emitImgUrl },
      }),
      _c(
        "van-overlay",
        { attrs: { show: _vm.loading && _vm.uploading } },
        [
          _c(
            "van-loading",
            { attrs: { size: "24px", color: "#fff", vertical: "" } },
            [
              _c(
                "div",
                { staticClass: "f fv fvc uploading-box" },
                [
                  _c("div", { staticClass: "top-tip" }, [
                    _vm._v("正在上传数据"),
                  ]),
                  _c("div", { staticClass: "progress" }, [
                    _vm._v(" " + _vm._s(_vm.uploadProgress) + " "),
                  ]),
                  _c("div", { staticClass: "main-tip" }, [
                    _vm._v("请保持网络畅通"),
                  ]),
                  _c("div", { staticClass: "main-tip" }, [
                    _vm._v("不要离开本页面"),
                  ]),
                  _c(
                    "van-button",
                    {
                      staticClass: "cancel-btn",
                      on: { click: _vm.cancelUpload },
                    },
                    [_vm._v("取消上传")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }