var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$tools.isAndroid()
    ? _c(
        "div",
        { staticClass: "download f fbt fc" },
        [
          _vm._m(0),
          _c(
            "van-button",
            {
              staticClass: "downloac-btn primary-btn",
              on: { click: _vm.downloadFn },
            },
            [_vm._v(" 下载 ")]
          ),
          _c(
            "van-overlay",
            {
              attrs: { "z-index": "9999999", show: _vm.showOverlay },
              on: {
                click: function ($event) {
                  _vm.showOverlay = false
                },
              },
            },
            [
              _c("div", { staticClass: "wrapper" }, [
                _c("img", {
                  staticClass: "wrapper-icon wrapper-icon1 f",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/3225aa36834982fa6d2d82f938067906-528x198.png",
                    alt: "",
                    srcset: "",
                  },
                }),
                _c("img", {
                  staticClass: "wrapper-icon wrapper-icon2 f",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/f679c282c9a150e469f6793754789d22-356x356.png",
                    alt: "",
                    srcset: "",
                  },
                }),
                _c("img", {
                  staticClass: "wrapper-icon wrapper-icon3 f",
                  attrs: {
                    src: "https://img-fe.tengzhihh.com/image/e7b81b975c5b33b8309b0bba1c5fe708-166x171.png",
                    alt: "",
                    srcset: "",
                  },
                }),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "download-left f" }, [
      _c("div", { staticClass: "download-icon f fvc" }, [
        _c("img", {
          attrs: {
            src: "https://img-fe.tengzhihh.com/image/414d3088a8da7ee76be929f5343f6baf-52x39.png",
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "download-title" }, [
        _vm._v("眷吉App客户端上线"),
        _c("br"),
        _vm._v("操作更加顺畅，更多可用功能"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }