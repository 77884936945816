var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-box" }, [
    _c(
      "div",
      { staticClass: "box-header f fc fbt" },
      [
        _c("van-icon", {
          staticClass: "back-icon",
          attrs: { name: "arrow-left" },
          on: { click: _vm.goBack },
        }),
        _c("div", { staticClass: "record", on: { click: _vm.viewRecord } }, [
          _vm._v("消费记录"),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "data-header" }, [
      _c("div", { staticClass: "title" }, [_vm._v("我的余额")]),
      _c(
        "div",
        { staticClass: "amount-area f fc fvc" },
        [
          _c("div", [_vm._v(" " + _vm._s(_vm.coin) + " ")]),
          _c("img", {
            staticClass: "coin-icon",
            attrs: {
              src: "https://img-fe.tengzhihh.com/image/340376cac80e1b-55x55.png",
            },
          }),
          _c("van-icon", {
            staticClass: "question-icon",
            attrs: { name: "question" },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "line" }),
    _c("div", { staticClass: "recharge-area" }, [
      _c("div", { staticClass: "title" }, [_vm._v("充值虚拟币")]),
      _c(
        "div",
        { staticClass: "options f fc flex-wrap" },
        _vm._l(_vm.rechargeOptions, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "option-box f fv fc",
              on: {
                click: function ($event) {
                  return _vm.select(item)
                },
              },
            },
            [
              _c(
                "div",
                {
                  class: `option-item f fc fvc ${
                    _vm.selectedItem.id === item.id ? "selected" : ""
                  }`,
                },
                [
                  _c("div", [_vm._v(_vm._s(item.final_amount))]),
                  _c("img", {
                    staticClass: "coin-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/340376cac80e1b-55x55.png",
                    },
                  }),
                ]
              ),
              _c("div", { staticClass: "money" }, [
                _vm._v("￥" + _vm._s(item.final_amount)),
              ]),
            ]
          )
        }),
        0
      ),
    ]),
    _c(
      "div",
      { staticClass: "pay-area" },
      [
        _c("div", { staticClass: "title" }, [_vm._v("支付方式")]),
        _c("pay-ways", {
          attrs: {
            goodsId: _vm.selectedItem.id,
            failedTip: "请先选择充值项目",
            from: "wallet",
          },
        }),
        _c("div", { staticClass: "tips" }, [_vm._v("充值说明")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }