<template>
  <div class="page-box f fc fv">
    <div class="img-box">
      <img :src="statusImg" class="img" />
    </div>
    <div class="tip">{{ capacity }}%</div>
    <div class="sub-tip">
      {{ statusStr }}
    </div>
    <van-button @click="buy" class="btn fixed">购买香薰</van-button>
  </div>
</template>

<script>
import bus from '@/utils/bus';
import sendMsg from '@/webSocket/sendMsg';
export default {
  data() {
    return {
      serialId: '',
      capacity: 0,
    };
  },
  computed: {
    statusImg: function () {
      return this.capacity >= 60
        ? 'https://img-fe.tengzhihh.com/image/2eeb87b50b4015-718x827.png'
        : this.capacity >= 30
        ? 'https://img-fe.tengzhihh.com/image/3a6bbe6ce03e58-717x826.png'
        : 'https://img-fe.tengzhihh.com/image/0441279533ceb7-717x826.png';
    },
    statusStr: function () {
      return this.capacity >= 60
        ? '当前香薰状况健康，可正常使用'
        : this.capacity >= 30
        ? '当前香薰状已使用过半，请注意后续的香薰状况'
        : '当前香薰已过低，请添加或更换香薰';
    },
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    bus.$on('onMsg', (serialId, content) => {
      if (serialId !== this.serialId) return false;
      this.updateBatteryStatus(content);
    });
    this.$webSocket.initIM(this.serialId).then(() => {
      sendMsg('systemInfo', {
        operation: 'getBatteryStatus',
      });
    });
  },
  beforeDestroy() {
    bus.$off('onMsg');
  },
  methods: {
    buy() {
      this.$router.replace('/shop/list');
    },
    updateBatteryStatus(msg) {
      if (msg.action !== 'systemInfo') return;
      if (msg.data.incenseCapacity <= 0) return;
      //香薰容量
      this.capacity = msg.data.incenseCapacity;
    },
  },
};
</script>
<style lang="less" scoped>
.page-box {
  height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
  .btn {
    width: 330px;
    height: 43px;
    background: #f87a1b;
    border-radius: 15px;
    font-size: 15px;
    font-weight: bold;
    color: #fff4e4;
    border: none;
  }
  .fixed {
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: -165px;
  }
  .img-box {
    width: 168px;
    border-radius: 12px;
    overflow: hidden;
    margin-top: 131px;
    .img {
      width: 168px;
    }
  }

  .capacity {
    font-size: 19px;
    font-weight: 800;
  }
  .tip {
    font-size: 12px;
    font-weight: bold;
    color: #505050;
    margin: 16px 0 22px;
  }
  .sub-tip {
    font-size: 12px;
    font-weight: 500;
    color: #505050;
  }
}
</style>
