<template>
  <div id="main-container" :class="`${showBottom ? 'show-bottom' : ''} `">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
export default {
  props: {
    showBottom: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#main-container {
  height: calc(var(--vh) * 100);
  overflow: auto;
}
.show-bottom {
  padding-bottom: 50px !important;
  height: calc(var(--vh) * 100 - 50px) !important;
}
</style>
