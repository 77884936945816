import { render, staticRenderFns } from "./PopupDetails.vue?vue&type=template&id=59eca7eb&scoped=true&"
import script from "./PopupDetails.vue?vue&type=script&lang=js&"
export * from "./PopupDetails.vue?vue&type=script&lang=js&"
import style0 from "./PopupDetails.vue?vue&type=style&index=0&id=59eca7eb&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59eca7eb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('59eca7eb')) {
      api.createRecord('59eca7eb', component.options)
    } else {
      api.reload('59eca7eb', component.options)
    }
    module.hot.accept("./PopupDetails.vue?vue&type=template&id=59eca7eb&scoped=true&", function () {
      api.rerender('59eca7eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/activity/xiangxunGift/comp/PopupDetails.vue"
export default component.exports