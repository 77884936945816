<template>
  <cell-group :list="settingList"></cell-group>
</template>

<script>
import apiPath from '@/utils/apiPath';
import CellGroup from '@/components/CellGroup/CellGroup.vue';
export default {
  components: { CellGroup },
  data() {
    return {
      settingList: [
        // {
        //   title: '21212112121',
        //   center: true,
        //   label: '本机',
        //   cb: () => {},
        //   value: 4,
        // },
      ],
      serialId: '',
    };
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.getAccount();
  },
  methods: {
    getAccount() {
      this.$http
        .get(apiPath.getAccount, {
          serial_id: this.serialId,
        })
        .then((res) => {
          const machine = {
            title: res.data.machine.machine_id,
            center: true,
            label: '神台机',
          };
          const users = res.data.user.map((i) => {
            return {
              title: i.name,
              label: '绑定用户',
              center: true,
            };
          });
          this.settingList = [machine, ...users];
        });
    },
  },
};
</script>

<style>
</style>