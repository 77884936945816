var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("div", { staticClass: "calendar-container" }, [
        _c("div", { staticClass: "top-area f fc fbt" }, [
          _c(
            "div",
            {
              staticClass: "month-area f fc",
              on: {
                click: function ($event) {
                  _vm.datePickerFlag = true
                },
              },
            },
            [
              _c("div", { staticClass: "detail" }, [
                _vm._v(
                  _vm._s(_vm.currentYear) +
                    "年" +
                    _vm._s(_vm.currentMonth) +
                    "月"
                ),
              ]),
              _c("img", {
                staticClass: "down-arrow",
                attrs: {
                  src: "https://img-fe.tengzhihh.com/image/9043985035d0a2-32x32.png",
                },
              }),
            ]
          ),
          _c("div", { staticClass: "tag-info f fc" }, [
            _c("div", { staticClass: "info-item f fc" }, [
              _c("img", { attrs: { src: _vm.foTag } }),
              _vm._v(" 佛教节日 "),
            ]),
            _c("div", { staticClass: "f fc" }, [
              _c("img", { attrs: { src: _vm.daoTag } }),
              _vm._v(" 道教节日 "),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "days-area" },
          [
            _vm._m(0),
            _vm._l(_vm.calcDaysLine, function (line, index) {
              return _c(
                "div",
                { key: index, staticClass: "days-line f fc frd" },
                [
                  _vm._l(line, function (item, idx) {
                    return _c(
                      "div",
                      {
                        key: idx,
                        class: `day-item f fv fc fvc ${
                          idx === 0 || idx === 6 ? "orange" : ""
                        }
          ${item.solar === _vm.today ? "is-today" : ""}
          ${
            _vm.currentDateInfoObj &&
            item.solar === _vm.currentDateInfoObj.solar
              ? "is-selected"
              : ""
          }
          ${item.is_now_month ? "" : "not-current-month"}
          `,
                        on: {
                          click: function ($event) {
                            return _vm.setCurrentDate(item)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "day-num" }, [
                          _vm._v(" " + _vm._s(item.solar.split("-")[2]) + " "),
                        ]),
                        _c(
                          "div",
                          {
                            class: `${
                              item.solar === _vm.today ? "is-today" : ""
                            }
          ${
            _vm.calcItemSubTitle(item).key === "festival" ? "is-festival" : ""
          } clamp1 day-n`,
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.calcItemSubTitle(item).value) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "tag-img-box f fv" }, [
                          _vm.haveFo(item.festivals)
                            ? _c("img", {
                                staticClass: "tag-img",
                                attrs: { src: _vm.foTag },
                              })
                            : _vm._e(),
                          _vm.haveDao(item.festivals)
                            ? _c("img", {
                                staticClass: "tag-img",
                                attrs: { src: _vm.daoTag },
                              })
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  _vm._l(new Array(7 - line.length), function (j) {
                    return _c("i", { key: j, staticClass: "day-item" })
                  }),
                ],
                2
              )
            }),
          ],
          2
        ),
      ]),
      _c("div", { staticClass: "detail-box" }, [
        _c("div", { staticClass: "day-detail" }, [
          _c("div", { staticClass: "top f fc" }, [
            _c("div", { staticClass: "left" }, [
              _vm._v(_vm._s(_vm.currentDate)),
            ]),
            _c("div", { staticClass: "right" }, [
              _c("div", { staticClass: "sub-top" }, [
                _vm._v("农历 " + _vm._s(_vm.lunar)),
              ]),
              _c("div", { staticClass: "sub-bottom" }, [
                _vm._v(" " + _vm._s(_vm.lunarYear) + " "),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "bottom" }, [
            _c("div", { staticClass: "bottom-sub f fc" }, [
              _c("div", { staticClass: "tag good f fc fvc" }, [_vm._v("宜")]),
              _c("div", { staticClass: "good-detail" }, [
                _vm._v(_vm._s(_vm.yi)),
              ]),
            ]),
            _c("div", { staticClass: "f fc" }, [
              _c("div", { staticClass: "tag bad f fc fvc" }, [_vm._v("忌")]),
              _c("div", { staticClass: "bad-detail" }, [
                _vm._v(_vm._s(_vm.ji)),
              ]),
            ]),
          ]),
        ]),
        _vm.latelyFestival.length > 0
          ? _c(
              "div",
              { staticClass: "festival-about" },
              [
                _c("div", { staticClass: "title" }, [_vm._v("最近的节日")]),
                _vm._l(_vm.latelyFestival, function (item) {
                  return _c(
                    "div",
                    { key: item.id, staticClass: "festival-item" },
                    [
                      _c("div", { staticClass: "date" }, [
                        _c("div", { staticClass: "g f fbt" }, [
                          _c("span", [_vm._v(_vm._s(item.solar))]),
                          _vm.expiredFn(item.solar)
                            ? _c(
                                "button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.remindFn(item)
                                    },
                                  },
                                },
                                [_vm._v("设置提醒")]
                              )
                            : _vm._e(),
                        ]),
                        _c("div", { staticClass: "n" }, [
                          _vm._v(_vm._s(item.lunar)),
                        ]),
                      ]),
                      _vm._l(item.festivals, function (f) {
                        return _c(
                          "div",
                          { key: f.id, staticClass: "detail f fc fbt" },
                          [
                            _c("div", { staticClass: "info f fc" }, [
                              f.type === 1 || f.type === 2
                                ? _c("img", {
                                    staticClass: "tag",
                                    attrs: {
                                      src:
                                        f.type === 1
                                          ? _vm.daoTag
                                          : f.type === 2
                                          ? _vm.foTag
                                          : "",
                                    },
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "name" }, [
                                _vm._v(_vm._s(f.name)),
                              ]),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ]),
      _c("date-picker", {
        attrs: {
          show: _vm.datePickerFlag,
          currentDate: _vm.pickDate,
          pickerType: "year-month",
        },
        on: { onCancel: _vm.onCancel, onConfirm: _vm.onConfirm },
      }),
      _c(
        "van-popup",
        {
          staticClass: "remindPop",
          attrs: { round: "" },
          on: { close: _vm.closeRemindPop },
          model: {
            value: _vm.remindPop,
            callback: function ($$v) {
              _vm.remindPop = $$v
            },
            expression: "remindPop",
          },
        },
        [
          _vm._l(_vm.remindSwitch.remindFestivals, function (item) {
            return _c(
              "div",
              { key: item.id, staticClass: "remindTitle txt-c" },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          _c("div", { staticClass: "remindSub f fvc" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.remindSwitch.left_day < 0 ? "已过去" : "还有") + " "
              ),
            ]),
            _c("span", { staticClass: "timeoutDay light-txt" }, [
              _vm._v(_vm._s(Math.abs(_vm.remindSwitch.left_day))),
            ]),
            _c("span", [_vm._v(" 天")]),
          ]),
          _c("div", { staticClass: "remindDate txt-c" }, [
            _vm._v(
              _vm._s(_vm.remindSwitch.solar) +
                " 星期" +
                _vm._s(_vm.weekTxt[_vm.remindSwitch.week])
            ),
          ]),
          _c("div", { staticClass: "remindLunar txt-c" }, [
            _vm._v(_vm._s(_vm.remindSwitch.lunar)),
          ]),
          _c("div", { staticClass: "remindSwitch" }, [
            _vm.showEarlyRemind(_vm.remindSwitch.solar)
              ? _c(
                  "div",
                  { staticClass: "f fc fbt mb10" },
                  [
                    _c("span", [_vm._v("提前一天提醒 上午9:00")]),
                    _c("van-switch", {
                      attrs: {
                        size: "26",
                        "active-color": "#F87A1B",
                        "inactive-color": "#D5D5D5",
                      },
                      model: {
                        value: _vm.remindSwitch.earlySwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.remindSwitch, "earlySwitch", $$v)
                        },
                        expression: "remindSwitch.earlySwitch",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "f fc fbt" },
              [
                _c("span", [_vm._v("当天提醒 上午9:00")]),
                _c("van-switch", {
                  attrs: {
                    size: "26",
                    "active-color": "#F87A1B",
                    "inactive-color": "#D5D5D5",
                  },
                  model: {
                    value: _vm.remindSwitch.timelySwitch,
                    callback: function ($$v) {
                      _vm.$set(_vm.remindSwitch, "timelySwitch", $$v)
                    },
                    expression: "remindSwitch.timelySwitch",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "popHandle f fvc" },
            [
              _c(
                "van-button",
                {
                  staticClass: "basics-btn",
                  attrs: { type: "default" },
                  on: {
                    click: function ($event) {
                      _vm.remindPop = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "van-button",
                {
                  staticClass: "primary-btn",
                  attrs: { color: "#F87A1B" },
                  on: { click: _vm.setRemindFn },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "days f fc frd" }, [
      _c("div", { staticClass: "day-item orange f fv fc fvc" }, [_vm._v("日")]),
      _c("div", { staticClass: "day-item f fv fc fvc" }, [_vm._v("一")]),
      _c("div", { staticClass: "day-item f fv fc fvc" }, [_vm._v("二")]),
      _c("div", { staticClass: "day-item f fv fc fvc" }, [_vm._v("三")]),
      _c("div", { staticClass: "day-item f fv fc fvc" }, [_vm._v("四")]),
      _c("div", { staticClass: "day-item f fv fc fvc" }, [_vm._v("五")]),
      _c("div", { staticClass: "day-item orange f fv fc fvc" }, [_vm._v("六")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }