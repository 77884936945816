var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box f fv fc fvc" },
    [
      _c("img", {
        staticClass: "bg",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/bab00d771acfb2-375x765.png",
        },
      }),
      _c("GoBack"),
      _c(
        "div",
        { staticClass: "list f fv fc" },
        _vm._l(_vm.list, function (item, idx) {
          return _c("img", {
            key: idx,
            staticClass: "item-img",
            attrs: { src: item.icon },
            on: {
              click: function ($event) {
                return _vm.view(item)
              },
            },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }