var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "my-about",
    attrs: {
      src: "https://img-fe.tengzhihh.com/image/f01381d4514170-750x1624.jpg",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }