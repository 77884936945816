const isLocal = /localhost|127\.0.0.1|10.1/i.test(location.hostname);
const isTest = /sandbox/i.test(location.hostname);
const isPre = /pre/i.test(location.hostname);

export default {
  env: isLocal ? 'isLocal' : isTest ? 'isTest' : isPre ? 'isPre' : 'isProd',
  // TODO：后续需要新增预发布对应的appID
  appId: isTest || isLocal ? 'wx188d8f976f7f65e5' : 'wxc0ba6ce32b7bea88',
  currentRouteInfo: null,
  userAccessToken: '',
  userOpenId: '',
  userInfo: {
    user_id: '',
    name: '',
    avatar: '',
    phone: '',
  },
  videoFile: null,
  audioFile: null,
  uploadProgress: '0%',
  devicesNumber: 0,
  generalConfig: {
    auto_offering: {
      status: 0,
      time: '',
    },
    account_num: 5,
    backlight: true,
    dnd_end_hour: 17,
    dnd_mode: true,
    dnd_start_hour: 17,
    id: 4,
    incense_sound: true,
    luminance: 3,
    machine_id: 7,
    name: '',
    screen_time: null,
    sound_size: 3,
    spray_duration: 3,
    festival_tab: false,
    incenseCapacity: 0,
  },
  audioDuration: 0,
  musicList: [],
  siMiaoLianMingGodGoodsId: 0,
  userMachinesList: [],
  fudeGift: {
    fude: 0, //公德值
  },
};
