<template>
  <div class="layout">
    <div class="page">
      <div class="orderStatus f fvc">
        <van-icon size="18px" :name="statusIcon[order.status]" class="mr8" />
        <p>{{ orderStatusTxt[order.status] }}</p>
        <div v-if="order.status === 0" class="f fvc">
          <span>（剩</span>
          <van-count-down :time="timeOut" @finish="getOrderDetail">
            <template #default="timeData">
              <div class="ordertime">
                &nbsp;{{ timeData.hours }}&nbsp;小时&nbsp;{{ timeData.minutes }}&nbsp;分&nbsp;{{
                  timeData.seconds
                }}秒&nbsp;
              </div>
            </template>
          </van-count-down>
          <span>自动关闭）</span>
        </div>
      </div>
      <div v-if="category === 'entity' && order.logistics.user_name" class="borderFFF f fbt mb10 orderAddress">
        <img src="https://img-fe.tengzhihh.com/image/1b4f21eebe0a3e-24x24.png" alt="" srcset="" class="address-icon" />
        <div class="address-detail">
          <div class="f">
            <span class="name mr10">{{ order.logistics.user_name }}</span
            ><span class="mobile">{{ order.logistics.mobile }}</span>
          </div>
          <p style="word-break: break-all">
            {{ order.logistics.province }}&nbsp;{{ order.logistics.city }}&nbsp;{{ order.logistics.district }}&nbsp;{{
              order.logistics.street
            }}{{ order.logistics.address }}
          </p>
        </div>
      </div>
      <div class="borderFFF mb10">
        <div class="f orderDetail" v-if="order.goods_info">
          <van-image :src="order.goods_info && order.goods_info.image" class="good-img">
            <template v-slot:error>加载失败</template>
          </van-image>
          <div class="order-content">
            <div class="name-amount f mb10">
              <p class="good-name">{{ order.goods_info.name }}</p>
              <p class="good-amount">¥&nbsp;{{ order.goods_info.original_amount }}</p>
            </div>
            <div class="stats-count f">
              <p class="good-desc">
                <template v-if="category === 'virtual'">
                  {{ order.serial_id }}
                </template>
                <template v-if="category === 'entity' && Object.keys(order.product.sku).length">
                  {{ order.product.sku.sku }}：{{ order.product.sku.sku_name }}
                </template>
              </p>
              <p class="good-count lh-20">x{{ order.goods_num }}</p>
            </div>
          </div>
        </div>
        <div class="f fbt order-amount">
          <span>实付款</span><span class="amount-count">¥&nbsp;{{ order.amount }}</span>
        </div>
      </div>
      <div class="borderFFF orderInfo">
        <div class="f fbt infoItem">
          <div class="label">订单编号</div>
          <div class="f fc value">
            <p>
              {{ order.order_center_id || 'N/A' }}
            </p>
            <div class="copy" v-if="order.order_center_id" @click="copy(order.order_center_id)">&nbsp;|&nbsp;复制</div>
          </div>
        </div>
        <div class="f fbt infoItem">
          <div class="label">支付方式</div>
          <div class="value">
            {{ order.payment_mode || 'N/A' }}
          </div>
        </div>
        <div class="f fbt infoItem" v-if="category === 'entity'">
          <div class="label">物流公司</div>
          <div class="value">
            {{ (order.logistics && order.logistics.logistics_name) || '暂无快递' }}
          </div>
        </div>
        <div class="f fbt infoItem" v-if="category === 'entity'">
          <div class="label">快递单号</div>
          <div class="f fc value">
            <p>
              {{ (order.logistics && order.logistics.logistics_sn) || '暂无快递' }}
            </p>
            <div
              class="copy"
              v-if="order.logistics && order.logistics.logistics_sn"
              @click="copy(order.logistics.logistics_sn)"
            >
              &nbsp;|&nbsp;复制
            </div>
          </div>
        </div>
        <div class="f fbt infoItem">
          <div class="label">下单时间</div>
          <div class="value">
            {{ order.created_at }}
          </div>
        </div>
        <div class="f fbt infoItem" v-if="order.status !== 0">
          <div class="label">支付时间</div>
          <div class="value">
            {{ order.pay_at }}
          </div>
        </div>
        <div class="f fvc kefu" @click="contact">
          <img
            src="https://img-fe.tengzhihh.com/image/d008cacf1336cb-16x16.png"
            alt=""
            srcset=""
            class="address-icon"
          />
          联系客服
        </div>
      </div>
    </div>

    <div v-if="order.status !== 0" :class="['handle', 'pidhandle', 'f', handleList.length ? '' : 'nohandle']">
      <button v-for="item in handleList" :key="item.name" class="handle-button" @click="handleClick(item)">
        {{ item.label }}
      </button>
    </div>
    <div v-if="order.status === 0" :class="['handle', 'unpidhandle', 'f', handleList.length ? '' : 'nohandle']">
      <button v-for="item in handleList" :key="item.name" class="unpid-button" @click="handleClick(item)">
        {{ item.label }}
      </button>
    </div>
    <Dialog ref="dialog" />
    <cancelPop ref="cancelPop" @cancelSuccess="getOrderDetail" />
    <confirmPop ref="confirmPop" @receiveConfirm="getOrderDetail" />
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';
import cancelPop from './component/cancelPop';
import confirmPop from './component/confirmPop';
import copy from 'copy-to-clipboard';
import { orderDetail } from '@/api/order';
import { orderStatusTxt, handleMap, virtualHandle } from '@/enum/shop.js';
import { setStore } from '@/utils/localStore';
export default {
  components: { Dialog, cancelPop, confirmPop },
  data() {
    return {
      orderId: '',
      order: {},
      orderStatusTxt: Object.freeze(orderStatusTxt),
      statusIcon: {
        0: 'clock-o',
        1: 'clock-o',
      },
    };
  },
  computed: {
    handleList() {
      if (this.order.goods_info && this.order.goods_info.category === 'entity') {
        return handleMap[this.order.status]?.filter(x => x.name !== 'service') || [];
      }
      if (this.order.goods_info && this.order.goods_info.category === 'virtual') {
        return virtualHandle[this.order.status]?.filter(x => x.name !== 'service') || [];
      }
      return [];
    },
    timeOut() {
      const createtime = new Date(this.order.created_at.replace(/-/g, '/')).getTime();

      const ttl = this.order.pay_info.ttl * 1000;
      const usetime = new Date().getTime() - createtime;
      return usetime > ttl ? 0 : ttl - usetime;
    },
    category() {
      return this.order.goods_info ? this.order.goods_info.category : '';
    },
  },
  created() {
    this.orderId = this.$tools.getQueryFromUrl('order_id');
    if (!this.orderId) return;
    this.getOrderDetail();
    window.addEventListener('pageshow', this.loadpage); //兼容安卓物理返回键页面不刷新
  },
  beforeDestroy() {
    window.removeEventListener('pageshow', this.loadpage);
  },
  methods: {
    loadpage(e) {
      if (e.persisted || (window.performance && window.performance.navigation.type == 2)) {
        console.log('window.performance', window.performance.navigation.type);
        window.location.reload();
      }
    },
    contact() {
      this.$utils.kefuDialog(this.$refs.dialog);
    },
    async getOrderDetail() {
      const res = await orderDetail({
        order_id: this.orderId,
      });
      this.order = res.data.order;
    },
    handleClick({ name }) {
      if (name === 'payment') {
        this.payFn(this.order);
      } else if (name === 'cancel') {
        this.$refs.cancelPop.openPop(this.order.id);
      } else if (name === 'confirm') {
        this.$refs.confirmPop.openPop(Object.assign(this.order, { sku: this.order.product.sku }));
      } else if (name === 'express') {
        this.viewExpress(this.order);
      } else if (name === 'repeat') {
        this.$router.push({
          name: 'goodsDetail',
          query: {
            id: this.order.goods_info.id,
          },
        });
      }
    },
    viewExpress({ logistics }) {
      if (!logistics.logistics_sn) {
        this.$toast('暂无物流信息');
        return;
      }
      window.location.href = logistics.logistics_url;
    },
    payFn({ payment_mode, pay_info, id, order_center_id }) {
      const jumpUrl = this.spliceUrl(payment_mode, Object.assign(pay_info, { order_center_id }));
      jumpUrl && (location.href = jumpUrl);
    },
    spliceUrl(payment_mode, response) {
      setStore({
        name: 'routePrevious',
        content: this.$route.path,
      });
      let url = `https://${response.default}/pay?order_id=${response.order_center_id}&channel_id=${response.ali_pay_channel}`;
      if (this.$tools.isWeChat()) {
        url = `https://${response.wechatv3_official}/pay?order_id=${response.order_center_id}&channel_id=${response.wechat_office_pay_channel}`;
      }
      return url;
    },
    copy(value) {
      if (
        copy(value, {
          format: 'text/plain',
        })
      ) {
        this.$toast('复制成功');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  background-color: #f5f5f5;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  .mb10 {
    margin-bottom: 10px;
  }
  .mb8 {
    margin-bottom: 8px;
  }
  .page {
    padding-bottom: 60px;
    background-color: #f5f5f5;
    padding: 0 10px;
    .orderStatus {
      text-align: center;
      padding: 16px 0;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);
      height: 24px;
      .van-count-down {
        display: flex;
        align-items: center;
      }
      .ordertime {
        color: #f87a1b;
        line-height: 14px;
        line-height: 14px;
        font-size: 14px;
      }
      .mr8 {
        margin-right: 8px;
      }
    }
    .orderAddress {
      align-items: flex-start;
      color: rgba(0, 0, 0, 0.45);
      .address-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        margin-top: 3px;
      }
      .address-detail {
        flex: 1;
        font-size: 12px;
        .name {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }
        .mobile {
          font-size: 14px;
          line-height: 22px;
        }
        .mr10 {
          margin-right: 10px;
        }
      }
    }
    .orderDetail {
      font-size: 14px;
      margin-bottom: 15px;
      .good-img {
        width: 90px;
        height: 90px;
        margin-right: 16px;
      }
      .order-content {
        width: calc(100% - 106px);
        .name-amount {
          width: 100%;
          justify-content: space-between;
          .good-name {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 22px;
          }
          .good-amount {
            margin-left: 5px;
            white-space: nowrap;
            line-height: 22px;
          }
        }
        .stats-count {
          color: rgba(0, 0, 0, 0.45);
          justify-content: space-between;
          width: 100%;
          .good-desc {
            flex: 1;
            margin-right: 5px;
          }
        }
      }
    }
    .order-amount {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      span {
        line-height: 22px;
      }
      .amount-count {
        color: #f87a1b;
      }
    }
    .orderInfo {
      .infoItem {
        font-size: 14px;
        margin-bottom: 16px;
        .label {
          color: rgba(0, 0, 0, 0.65);
        }
        .value {
          color: rgba(0, 0, 0, 0.45);
        }
      }
      .kefu {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        padding-top: 17px;
        img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }
  }
  .borderFFF {
    border-radius: 10px;
    background: #fff;
    padding: 16px 12px;
  }
  .unpidhandle {
    padding: 8px 20px;
    .unpid-button {
      font-size: 14px;
      width: 74px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.65);
      border-radius: 10px;
      background-color: #fff;
      margin-right: 10px;
    }
    .unpid-button:last-child {
      width: 164px;
      font-size: 16px;
      color: #fff;
      background: #f87a1b;
      border-color: #f87a1b;
      margin: 0;
    }
  }
  .pidhandle {
    padding: 13px 20px;
  }
  .handle {
    height: 60px;
    width: 100%;
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    justify-content: flex-end;
    background-color: #fff;
    .handle-button {
      font-size: 14px;
      width: 84px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: rgba(0, 0, 0, 0.65);
      border-radius: 10px;
      background-color: #fff;
      margin-right: 10px;
    }
    .handle-button:last-child {
      color: #f87a1b;
      border-color: #f87a1b;
      margin: 0;
    }
  }
  .nohandle {
    background-color: #f5f5f5;
  }
}
</style>
