import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routers';
import store from './../store';
import tools from './../utils/tools';
import { track } from './../utils/tracking';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => err);
};

VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch(err => err);
};

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  routes,
  mode: 'history',
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '神台手机端';
  if (tools.isInApp() && to.path.includes('/shop')) {
    return false;
  }
  if (tools.isAndroid() && to.query.downloadUrl) {
    window.open(to.query.downloadUrl);
    const query = to.query;
    delete query.downloadUrl;
    next({ ...to, query });
  }
  store.commit('setCurrentRouteInfo', to?.meta);

  next();
});

router.afterEach((to, from) => {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  let url = location.origin + to.fullPath;
  if (to.meta?.trackName) {
    track(to.meta?.trackName);
  }
  if (isiOS) {
    // iOS记录初次进入的URL
    if (window.entryUrl == '' || window.entryUrl == undefined) {
      window.entryUrl = url;
    }
  }
});

export default router;
