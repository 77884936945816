<template>
  <div class="page-box">
    <div class="list-box">
      <div class="msg-item f fc" v-for="(item, index) in list" :key="index" @click="viewDetail(item)">
        <div :class="`point ${item.unread_message_count !== 0 ? 'red-point' : ''}`"></div>
        <div class="item-img">
          <img :src="item.image" class="img" />
        </div>
        <div class="item-info fv fbt">
          <div class="item-data f fbt">
            <div class="item-name">{{ item.name }}</div>
            <div class="msg-date">
              {{ (item.last_message && item.last_message.created_at) || ' ' }}
            </div>
          </div>
          <div class="bottom f fc fbt">
            <div class="msg-content clamp1">
              {{ (item.last_message && item.last_message.content) || '暂无新消息' }}
            </div>
            <van-icon name="arrow" style="height: 100%" class="right-arrow-icon f fc" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';

export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    viewDetail(item) {
      this.$router.push(`/msg/detail?serial_id=${item.serial_id}`);
    },
    getList() {
      this.$http.get(apiPath.devicesMsgList).then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
  .list-box {
    background-color: #fff;
    .msg-item {
      padding: 10px 0;
      margin: 0 10px;
      height: 50px;
      font-size: 14px;
      border-bottom: 1px solid #ebebeb;
      .point {
        width: 10px;
        height: 10px;
        margin-right: 5px;
      }
      .red-point {
        width: 10px;
        height: 10px;
        background-color: red;
        border-radius: 50%;
        margin-right: 5px;
      }
      .item-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        img {
          width: 50px;
          height: 50px;
        }
      }
      .msg-content {
        font-size: 12px;
      }
      .item-info {
        height: 100%;
        flex: 1;
        .item-data {
          height: 100%;
          .item-name {
            font-weight: bold;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
