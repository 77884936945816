var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-box" },
    [
      _c("cell-group", { attrs: { list: _vm.settingList } }),
      _c(
        "van-button",
        {
          staticClass: "add-btn fixed",
          attrs: { loading: _vm.loading, type: "primary" },
          on: { click: _vm.save },
        },
        [_vm._v("保存")]
      ),
      _c("date-picker", {
        attrs: { show: _vm.datePickerFlag, pickerType: "time" },
        on: { onCancel: _vm.onCancel, onConfirm: _vm.onConfirmDatePicker },
      }),
      _c("check-group", {
        attrs: {
          showFlag: _vm.checkGroupFlag,
          list: _vm.groupArr,
          selectedResult: _vm.selectedResult,
        },
        on: { onConfirm: _vm.onConfirmCheckGroup, onCancel: _vm.onCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }