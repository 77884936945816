<template>
  <div>
    <div class="detail-box">
      <van-swipe class="my-swipe" :autoplay="playing ? 0 : 3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in goodsItem.atlas" :key="index">
          <div v-if="item.type === 'video'" class="main-pic f fc fvc">
            <video
              :src="item.url"
              :poster="item.cover_image"
              id="video-player"
              class="main-pic"
              controls
              autoplay
              playsinline
            />
          </div>
          <img v-else v-lazy="item.url" class="main-pic" />
        </van-swipe-item>
      </van-swipe>

      <div class="goods-area">
        <goods-item :item="goodsItem"></goods-item>
        <div class="select-area">
          <template v-if="goodsItem.category === 'virtual'">
            <div class="title">选择智能神台购买</div>

            <div class="select-item f fc fbt" @click="selectMachine">
              <div class="border tip">
                {{ selectedMachine ? selectedMachine.name : '点击选择智能神台' }}
              </div>
              <van-icon name="arrow" class="right-arrow" />
            </div>
          </template>
          <template v-if="goodsItem.category === 'entity'">
            <div class="buy-count select-item f fc fbt">
              <div class="tip">购买数量</div>
              <div class="f fc" @click="openGoodNum">
                <span class="count">{{ entityCorrelate.goods_num }}</span>
                <van-icon name="arrow" class="right-arrow" />
              </div>
            </div>
            <div v-if="entityCorrelate.address_id" class="f fbt address-all">
              <img
                src="https://img-fe.tengzhihh.com/image/1b4f21eebe0a3e-24x24.png"
                alt=""
                srcset=""
                class="address-icon"
              />
              <div class="address-detail" @click="chooseAddress">
                <div class="mb8 f">
                  <span class="name mr10">{{ address_detail.username }}</span
                  ><span class="mobile">{{ address_detail.mobile }}</span>
                </div>
                <div class="f fbt fc">
                  <p style="word-break: break-all">
                    {{ address_detail.province }}&nbsp;{{ address_detail.city }}&nbsp;{{
                      address_detail.district
                    }}&nbsp;{{ address_detail.street }}{{ address_detail.address }}
                  </p>
                  <van-icon name="arrow" class="right-arrow" />
                </div>
              </div>
            </div>
            <div v-else class="buy-address select-item f fc fbt" @click="addressClick">
              <div class="tip">选择发货地址</div>
              <van-icon name="arrow" class="right-arrow" />
            </div>
          </template>

          <div v-if="goodsItem.is_package">
            <div class="title">选择类型</div>

            <div class="f fc flex-wrap">
              <div
                v-for="item in packages"
                :key="item.id"
                :class="`pack-item ${item.id === packageId ? 'current-pack' : ''}`"
                @click="choosePack(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>

          <div class="total-amount f fc fbt" v-else>
            <div class="tip">总价</div>
            <div class="amount">￥{{ goodsItem.final_amount }}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="fixed pay-area">
        <pay-ways
          :virtual="goodsItem.is_allow_use_coin == 1"
          :goodsItem="goodsItem"
          :customParams="entityCorrelate"
          :goodsId="goodsId"
          :packageId="packageId"
          :packages="packages"
          :userCoins="userCoins"
          :serialId="selectedMachine && selectedMachine.serial_id"
          from="goodsDetail"
          @insufficientBalance="insufficientBalance"
        ></pay-ways>
      </div>

      <div class="line"></div>
      <div class="goods-detail">
        <div class="detail-header f fc fvc">商品介绍</div>
        <div v-html="introduction" class="introduction"></div>
      </div>
    </div>

    <van-popup v-model="failedPopupFlag" close-on-click-overlay position="bottom">
      <div v-if="failedPopupType === 1" class="failed-popup">
        <div class="header">
          <div class="title">余额不足</div>
          <div class="subtitle">当前余额不足，请充值后再支付或使用其他支付方式</div>
        </div>
        <div class="pay-area">
          <pay-ways
            :userCoins="userCoins"
            :goodsId="goodsId"
            :serialId="selectedMachine && selectedMachine.serial_id"
          ></pay-ways>
          <div class="recharge-btn" @click="recharge">充值</div>
        </div>
      </div>
      <div v-if="failedPopupType === 2" class="failed-popup">
        <div class="header">
          <div class="title">支付失败</div>
          <div class="subtitle">没有获取到支付成功的信息</div>
        </div>
        <div class="btn-option f fc fbt">
          <div class="give-up">放弃</div>
          <div class="pay">继续支付</div>
        </div>
        <div class="contact-area f fc fvc" @click="kefu">
          <span>联系客服</span>
        </div>
      </div>
    </van-popup>
    <Dialog ref="dialog" />
    <van-popup v-model="addAddressPop" class="addAddressPop">
      <p class="title">添加收货地址</p>
      <p>您还没有收货地址，请先添加一个新的收货地址</p>
      <van-button block color="#F87A1B" @click="goAddress">去添加</van-button>
    </van-popup>
    <van-popup v-model="goodsNumPop" class="goodsNumPop">
      <div class="f fbt mb20">
        <div class="fc"><p>数量</p></div>
        <van-stepper v-model="pop_goods_num" disable-input min="0" />
      </div>
      <van-button block color="#F87A1B" @click="changeGoodNum">确定</van-button>
    </van-popup>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog/Dialog.vue';
import { getDefault, getAddress } from '@/api/address.js';
import apiPath from '@/utils/apiPath';
import GoodsItem from '@/components/Goods/GoodsItem';
import PayWays from '@/components/PayWays/PayWays';
export default {
  components: { GoodsItem, PayWays, Dialog },
  data() {
    return {
      goodsId: 0,
      goodsItem: {
        name: '',
        image: '',
        original_amount: '0',
        final_amount: '0',
        category: 'virtual',
        is_package: 0,
        is_allow_use_coin: 1,
      },
      userMachines: [],
      userCoins: 0,
      selectedMachine: null,
      failedPopupFlag: false,
      failedPopupType: 1,
      packages: [],
      packageId: 0,
      introduction: '',
      playing: false,
      entityCorrelate: {
        goods_num: 1,
        address_id: '',
      },
      address_detail: {},
      addAddressPop: false,
      goodsNumPop: false,
      pop_goods_num: 1,
    };
  },
  created() {
    this.init();
    window.addEventListener('pageshow', this.loadpage); //兼容安卓物理返回键页面不刷新
  },
  beforeDestroy() {
    const dom = document.getElementById('video-player');
    if (!dom) return false;
    dom.removeEventListener('play', this.playingStatus);
    dom.removeEventListener('pause', this.pausedStatus);
    dom.removeEventListener('ended', this.endedStatus);
    window.removeEventListener('pageshow', this.loadpage);
  },
  methods: {
    loadpage(e) {
      if (e.persisted || (window.performance && window.performance.navigation.type == 2)) {
        console.log('window.performance', window.performance.navigation.type);
        window.location.reload();
      }
    },
    init() {
      this.goodsId = Number(this.$route.query.id || 0);
      let tmp = this.$route.query.machine;
      this.selectedMachine = tmp && JSON.parse(decodeURIComponent(tmp));
      this.getDetail();
      this.getAddress();
      this.initListener();
    },
    changeGoodNum() {
      this.goodsNumPop = false;
      if (this.pop_goods_num > this.goodsItem.stock) {
        this.$toast('库存不足');
        return false;
      }
      this.entityCorrelate.goods_num = this.pop_goods_num;
    },
    openGoodNum() {
      this.goodsNumPop = true;
      this.pop_goods_num = this.entityCorrelate.goods_num;
    },
    goAddress() {
      this.$router.push({
        name: 'add-address',
      });
    },
    chooseAddress() {
      const { name, query } = this.$route;
      this.$router.push({
        name: 'delivery-address',
        params: {
          name,
          query,
        },
      });
    },
    addressClick() {
      this.addAddressPop = true;
    },
    async getAddress() {
      let res = {};
      if (this.$route.params.address_id) {
        let detail = await getAddress({ id: this.$route.params.address_id });
        res = { data: detail.data.info };
      } else {
        res = await getDefault();
      }
      this.address_detail = res.data;
      this.entityCorrelate.address_id = res.data.id;
    },
    initListener() {
      const dom = document.getElementById('video-player');
      if (!dom) return false;
      dom.addEventListener('play', this.playingStatus);
      dom.addEventListener('pause', this.pausedStatus);
      dom.addEventListener('ended', this.endedStatus);
    },
    playingStatus() {
      this.playing = true;
    },
    pausedStatus() {
      this.playing = false;
    },
    endedStatus() {
      this.playing = false;
    },

    kefu() {
      this.$utils.kefuDialog(this.$refs.dialog);
    },
    fitMachine(id) {
      if (id) {
        const tmp = this.userMachines.find(i => i.serial_id === id);
        if (tmp) {
          this.selectedMachine = tmp;
        }
      }
    },
    choosePack(id) {
      this.packageId = id;
    },
    recharge() {
      this.$router.push('/my/wallet');
    },
    selectMachine() {
      this.$router.push(`/shop/machineList?goods_id=${this.goodsId}`);
    },
    getDetail() {
      return this.$http
        .get(
          `${apiPath.goodsDetail}?goods_id=${this.goodsId}&ver=1.2${this.serialId ? '&serial_id=' + this.serialId : ''}`
        )
        .then(res => {
          this.introduction = res.data.goods.introduction;
          this.goodsItem = res.data.goods;
          this.userMachines = res.data.user_machines;
          this.userCoins = res.data.user.coin;
          this.packages = res.data.goods.packages;
          if (!res.data.goods.stock) {
            this.entityCorrelate.goods_num = res.data.goods.stock;
          }
        });
    },
    insufficientBalance() {
      this.failedPopupFlag = true;
    },
  },
};
</script>
<style lang="less">
.introduction img {
  width: 355px !important;
}
.van-popup {
  border-radius: 0px;
}
</style>
<style lang="less" scoped>
.detail-box {
  .my-swipe {
    position: relative;
    width: 100%;
  }
  .main-pic {
    width: 375px;
    height: 375px;
  }

  .line {
    width: 375px;
    height: 10px;
    background-color: #f2f2f2;
  }

  .right-arrow {
    font-size: 14px;
    font-weight: bold;
    color: #b5b5b5;
  }
  .goods-area {
    padding: 20px;
    .buy-count {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      margin: 12px 0 20px;
      padding-top: 20px;
      .count {
        font-size: 16px;
        line-height: 19px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .address-all {
      align-items: flex-start;
      .address-icon {
        width: 24px;
        height: 24px;
        margin-right: 8px;
        margin-top: 3px;
      }
      .address-detail {
        flex: 1;
        font-size: 12px;
        .name {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 22px;
        }
        .mobile {
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.65);
        }
        .mb8 {
          margin-bottom: 8px;
        }
        .mr10 {
          margin-right: 10px;
        }
      }
    }
    .area-header {
      margin-bottom: 20px;
      .icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
      .name {
        font-size: 15px;
        font-weight: 800;
        color: #333333;
      }
    }
    .select-area {
      .pack-item {
        height: 30px;
        background: #f6f6f6;
        border-radius: 8px;
        line-height: 30px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        padding: 0 16px;
        text-align: center;
        margin-right: 16px;
      }
      .current-pack {
        background: rgba(255, 145, 62, 0.1);
        border: 1px solid #f87a1b;
        color: #f87a1b;
      }
      .title {
        font-size: 15px;
        font-weight: 800;
        color: #333333;
        margin: 30px 0 20px;
      }
      .tip {
        font-size: 14px;
        color: #333333;
      }
      .border {
        border-radius: 4px;
        border: 1px solid rgb(218, 220, 221);
        padding: 2px 4px;
      }
      .total-amount {
        margin: 20px 0 0;
        .amount {
          font-size: 14px;
          font-weight: bold;
          color: #ff0000;
        }
      }
    }
  }
  .pay-area {
    padding: 20px;
  }
  .fixed {
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    position: fixed;
    bottom: 0;
  }
  .goods-detail {
    padding: 10px;
    .detail-header {
      width: 100%;
      height: 47px;
      background-color: #fff;
      font-size: 15px;
      font-weight: 800;
      color: #333333;
    }
  }
}
.failed-popup {
  .title {
    font-size: 18px;
    font-weight: 800;
    color: #333333;
    margin: 26px 0 18px;
    text-align: center;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    margin: 0 0 18px;
    text-align: center;
  }
  .pay-area {
    border-top: 1px solid #f2f2f2;
    padding: 24px;
  }
  .recharge-btn {
    width: 329px;
    height: 43px;
    background: linear-gradient(10deg, #ffe79f 0%, #ffda77 100%);
    border-radius: 21px;
    font-size: 16px;
    font-weight: 800;
    color: #804e00;
    line-height: 43px;
    text-align: center;
    margin-top: 30px;
  }
  .btn-option {
    margin-bottom: 25px;
    text-align: center;
    padding: 0 40px;
    .give-up {
      width: 142px;
      height: 43px;
      border: 1px solid #b7b7b7;
      border-radius: 15px;
      font-size: 15px;
      line-height: 43px;
      font-weight: 800;
      color: #333333;
    }
    .pay {
      width: 142px;
      height: 43px;
      line-height: 43px;

      background: #d00000;
      border-radius: 15px;
      font-size: 15px;
      font-weight: 800;
      color: #ffffff;
    }
  }
  .contact-area {
    font-size: 15px;
    font-weight: 800;
    color: #333333;
    padding: 13px 0 22px;
    border-top: 1px solid #f2f2f2;
  }
}
.addAddressPop {
  padding: 28px 25px 20px;
  border-radius: 15px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 21px;
    margin-bottom: 20px;
  }
  .title {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;
  }
  .van-button {
    width: 245px;
    height: 40px;
    border-radius: 10px;
  }
}
.goodsNumPop {
  padding: 26px 25px 20px;
  border-radius: 15px;
  .mb20 {
    margin-bottom: 20px;
  }
  .van-button {
    width: 245px;
    height: 40px;
    border-radius: 10px;
  }
  p {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
  /deep/.van-stepper__input {
    width: 42px;
    margin: 0;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
    background: #fff;
  }
  /deep/.van-stepper__minus,
  /deep/.van-stepper__plus {
    border: 1px solid #999;
  }
}
</style>
