<template>
  <div>
    <div class="fude f fc" @click="openFude" v-if="$utils.calcUserIsMasterWithSerialId(routeQuery.serial_id)">
      <img class="fude-img" src="https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png" />
      <div class="fude-text">香火{{ godInfo.user_god_fude || 0 }}</div>
    </div>
    <div v-if="deceasedDetail.images_icon" class="photo-icon" @click="viewDeceasedDetail">
      <img :src="deceasedDetail.images_icon" alt="" srcset="" />
      <p>相册</p>
    </div>
    <div class="enshrine-item">
      <div v-if="godInfo.decease_show == 1" class="enshrine-center-0 f abs-c">
        <img src="https://img-fe.tengzhihh.com/image/9bfdaf39ae173d8970ee25236b3322c4-314x662.png" class="" />
        <div class="enshrine-name abs-c">{{ godInfo.name }}</div>
      </div>
      <img
        v-if="godInfo.decease_show == 2"
        src="https://img-fe.tengzhihh.com/image/fca11a486c34d8a43eb45832ff8cd5be-764x1014.png"
        class="enshrine-center-1 abs-c"
      />
      <img v-if="godInfo.decease_show == 2" :src="godInfo.avatar" alt="" srcset="" class="enshrine-img abs-c" />
    </div>
    <img :src="flower.offer_left_image" class="offering-item hua left" v-debounce="touchOffering" />
    <img :src="flower.offer_right_image" class="offering-item hua right" v-debounce="touchOffering" />

    <img :src="incense.offer_left_image" class="offering-item xiang" v-debounce="touchOffering" />

    <img :src="fruit.offer_left_image" class="offering-item shuiguo left" v-debounce="touchOffering" />
    <img :src="fruit.offer_right_image" class="offering-item shuiguo right" v-debounce="touchOffering" />

    <img :src="candle.offer_left_image" class="offering-item lazhu left" v-debounce="touchOffering" />
    <img :src="candle.offer_right_image" class="offering-item lazhu right" v-debounce="touchOffering" />
    <img :src="fireImg" class="fire-ani fire-left" v-if="!emptyOfferings" />
    <img :src="fireImg" class="fire-ani fire-right" v-if="!emptyOfferings" />

    <div :id="`yan-svga-${enshrineId}`" class="yan-svga"></div>
    <img
      :src="incense_image || 'https://img-fe.tengzhihh.com/image/7c9e5043451050-120x120.png'"
      @click="handleIncense"
      class="incense-image"
    />

    <img src="https://img-fe.tengzhihh.com/image/e4602cce5cc2f3c7192e4f89f1a27156-750x247.png" class="bg-img-desk" />
    <div class="main-btn-list f">
      <div
        v-if="godInfo.is_default === 0"
        class="set-main-btn"
        @click="$utils.checkAuth(routeQuery.serial_id, priorityWorship)"
      >
        设为主位
      </div>
      <div class="set-main-btn" v-debounce="touchOffering">供奉</div>
      <div class="set-main-btn" @click="autoOffering">一键供奉</div>
    </div>
    <Fude
      ref="FudeRef"
      :godFude="godInfo.user_god_fude"
      :godName="godInfo.name"
      :todayTask="todayTask"
      @goOffer="goOffer"
      @viewFude="viewFude"
    />
    <OfferingsTimeout ref="OfferingsTimeoutRef" :offerings="offerings" :time="calcExpireTime" offerTarget="逝者" />
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import { enshrineOfferings, fireImg } from '@/enum/offerings';
import { saveQuickOffering } from '@/api/setting';
import { getDeceasedDetail } from '@/api/deceased';
import { commonInfo } from '@/mixins/commonInfo';
import { mapState } from 'vuex';
import svgaplayerweb from 'svgaplayerweb';
import Dialog from '@/components/Dialog/Dialog.vue';
import Fude from './Fude.vue';
import apiPath from '@/utils/apiPath';
import OfferingsTimeout from '@/pages/god/comp/OfferingsTimeout.vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration'; // 引入 dayjs-duration 插件
dayjs.extend(duration);
export default {
  mixins: [commonInfo],
  components: { Fude, Dialog, OfferingsTimeout },
  props: {
    enshrineId: {
      type: [Number, String],
      default: '',
    },
  },
  watch: {
    offeringsExpireTime: {
      handler: function (n) {
        if (n) {
          this.calcSendGodTimeFn();
          this.handleSendGodTime();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState(['generalConfig']),
    flower: function () {
      return this.offerings.find(i => i.offer_type == 1) || '';
    },
    incense: function () {
      return this.offerings.find(i => i.offer_type == 2) || '';
    },
    fruit: function () {
      return this.offerings.find(i => i.offer_type == 3) || '';
    },
    candle: function () {
      return this.offerings.find(i => i.offer_type == 4) || '';
    },
    offeringsExpireTime: function () {
      return this.offerings.find(i => i.offer_type == 1)?.expire_time || '';
    },
  },
  data() {
    return {
      deceasedDetail: {},
      offerings: enshrineOfferings,
      fireImg: Object.freeze(fireImg),
      godInfo: {},
      emptyOfferings: true,
      todayTask: [],
      incense_image: '',
      is_set_quick_offer: false,
      calcExpireTime: '',
      sendIntervalCancel: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.sendIntervalCancel);
  },
  mounted() {
    this.getDeceasedDetailFn();
  },
  methods: {
    viewDeceasedDetail() {
      this.$router.push(
        `/deceased/detail?serial_id=${this.routeQuery.serial_id}&id=${this.enshrineId}&from=enshrineList`
      );
    },
    priorityWorship() {
      const formData = new FormData();
      formData.append('serial_id', this.routeQuery.serial_id);
      this.$refs.dialog.initDialog({
        title: '设为主位',
        content: '是否设置为主位，确定后将作为供奉顺序的第一位，优先展示在首页神龛（神柜）里。',
        confirmText: '确认',
        cancelText: '取消',
        confirmCallback: () => {
          this.$http.formPost(`${apiPath.priorityWorship}/${this.enshrineId}`, formData).then(() => {
            this.$toast('设置成功');
            this.$emit('quickOffering', this.enshrineId);
            this.$refs.dialog.closeDialog();
          });
        },
        cancelCallback: () => {
          this.$refs.dialog.closeDialog();
        },
      });
    },
    autoOffering() {
      this.$track('H5_gongfeng_one_click_all');
      this.$track('H5_zuxian_gongfeng_one_click');
      if (!this.emptyOfferings) {
        typeof this.$refs.OfferingsTimeoutRef.show === 'function' && this.$refs.OfferingsTimeoutRef.show();
        return;
      }
      if (!this.is_set_quick_offer) {
        this.toEnshrineSetting();
        return;
      }
      saveQuickOffering({
        serial_id: this.routeQuery.serial_id,
        god_type: this.routeQuery.god_type,
      })
        .then(res => {
          this.$toast('一键上供成功');
          this.$emit('quickOffering', this.enshrineId);
          this.getDeceasedDetailFn();
        })
        .catch(res => {
          if (res.code === 400504) {
            this.toEnshrineSetting();
          }
        });
    },
    toEnshrineSetting() {
      this.$refs.dialog.initDialog({
        title: '一键供奉',
        content: `你当前还没有完成设置“一键供奉”的操作。设置成功后即可一键点击，为正在供奉的所有神明上供贡品。`,
        confirmText: '去设置',
        showCancelBtn: false,
        confirmCallback: () => {
          this.$router.push({
            name: 'enshrineSetting',
            query: this.routeQuery,
          });
        },
      });
    },
    async getDeceasedDetailFn() {
      const { data } = await getDeceasedDetail({
        serial_id: this.routeQuery.serial_id,
        god_id: this.enshrineId,
      });
      this.offerings = data.offerings.length ? data.offerings : enshrineOfferings;
      this.emptyOfferings = !data.offerings.length;
      this.deceasedDetail = data;
      this.godInfo = data.god;
      this.todayTask = data.today_task;
      this.incense_image = data.incense_image;
      this.is_set_quick_offer = data.is_set_quick_offer;
    },
    openFude() {
      this.$track('gongfeng_xianghuo_click');
      typeof this.$refs.FudeRef.show === 'function' && this.$refs.FudeRef.show();
    },
    viewFude() {
      this.$emit('setValue', 'activeClose', true);
      this.$router.push(
        `/fude/list?fude_type=user_decease_fude&god_id=${this.enshrineId}&type=2&serial_id=${this.routeQuery.serial_id}`
      );
    },
    goOffer() {
      typeof this.$refs.FudeRef.onCancel === 'function' && this.$refs.FudeRef.onCancel();
      this.$emit('setValue', 'pageStep', 'offerings');
    },
    touchOffering() {
      if (!this.emptyOfferings) {
        typeof this.$refs.OfferingsTimeoutRef.show === 'function' && this.$refs.OfferingsTimeoutRef.show();
      } else {
        this.$emit('setValue', 'pageStep', 'offerings');
      }
    },
    handleIncense() {
      this.$utils.useIncense(
        {
          serial_id: this.routeQuery.serial_id,
          from: 'deceased',
          id: this.enshrineId,
          incense_capacity: this.generalConfig.incenseCapacity,
        },
        () => {
          !this.emptyOfferings && this.handleYan() && this.$track('H5_xianren_shangxiang_click');
        }
      );
    },
    async playSvga(dom, svga, loops, endCb) {
      const player = new svgaplayerweb.Player(dom);
      const parser = new svgaplayerweb.Parser(dom);
      parser.load(svga, videoItem => {
        player.clearsAfterStop = true; // 动画结束时，是否清空画布
        loops && (player.loops = loops);
        player.setVideoItem(videoItem);
        player.startAnimation();
        player.onFinished(() => {
          typeof endCb === 'function' && endCb();
        });
      });
    },
    handleYan() {
      this.playSvga(
        `#yan-svga-${this.enshrineId}`,
        'https://mmc-forecast.oss-cn-shanghai.aliyuncs.com/file/1ef5301c-fa83-4e5a-97da-47b9d8c149b3.svga',
        1,
        () => {}
      );
    },
    calcSendGodTimeFn() {
      const calcExpireTime = this.offeringsExpireTime - dayjs().unix();
      if (calcExpireTime < 0 && this.offeringsExpireTime) {
        this.sendIntervalCancel = null;
        this.getDeceasedDetailFn();
        typeof this.$refs.OfferingsTimeoutRef.show === 'function' && this.$refs.OfferingsTimeoutRef.onCancel();
        return;
      }
      this.calcExpireTime = dayjs.duration(calcExpireTime, 'seconds').format('H时m分ss秒');
    },
    handleSendGodTime() {
      this.sendIntervalCancel = this.$tools.myInterval(this.calcSendGodTimeFn, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
.fude {
  width: fit-content;
  height: 25px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 13px 13px 13px 13px;
  padding: 0 10px;
  position: absolute;
  right: 12px;
  z-index: 5;
  top: 50px;
  .fude-text {
    font-size: 12px;
    font-weight: 400;
    color: #ff913e;
  }
  .fude-img {
    width: 25px;
    height: 25px;
  }
}
.photo-icon {
  position: absolute;
  right: 12px;
  z-index: 5;
  top: 90px;
  img {
    display: flex;
    width: 40px;
    height: 40px;
  }
  p {
    line-height: 17px;
    font-size: 12px;
    color: #996531;
    text-align: center;
  }
}
.bg-img-desk {
  position: absolute;
  top: 466px;
  width: 375px;
  height: 123px;
  object-fit: fill;
  z-index: 1;
}
.enshrine-item {
  width: 375px;
  height: 812px;
}
.enshrine-center-0 {
  position: absolute;
  display: flex;
  width: 157px;
  height: 331px;
  top: 158px;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
  }
  .enshrine-name {
    position: absolute;
    font-size: 14px;
    color: #faf6a8;
    z-index: 3;
    width: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    word-break: break-all;
    text-align: center;
  }
}

.offering-item {
  position: absolute;
  z-index: 2;
}

.hua.left {
  width: 96px;
  height: 146px;
  top: 349px;
  left: 50px;
}
.hua.right {
  width: 96px;
  height: 146px;
  top: 350px;
  right: 44px;
}

.shuiguo.left {
  width: 66px;
  top: 471px;
  left: 98px;
  height: 95px;
}
.shuiguo.right {
  width: 66px;
  top: 470px;
  right: 98px;
  height: 95px;
}

.xiang {
  left: 50%;
  width: 40px;
  margin-left: -20px;
  top: 418px;
  z-index: 4;
}

.lazhu.left {
  width: 72px;
  height: 107px;
  top: 456px;
  left: 29px;
  z-index: 200;
}
.lazhu.right {
  width: 72px;
  height: 107px;
  top: 456px;
  right: 22px;
  z-index: 200;
}
.fire-ani {
  width: 90px;
  height: 90px;
  z-index: 100;
  position: absolute;
}
.fire-left {
  top: 435px;
  left: 18px;
}
.fire-right {
  top: 434px;
  right: 15px;
}
.enshrine-img {
  position: absolute;
  width: 130px;
  height: 193px;
  top: 273px;
  z-index: 3;
}
.enshrine-center-1 {
  position: absolute;
  display: flex;
  width: 181px;
  height: 240px;
  top: 248px;
  z-index: 2;
}
.incense-image {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 572px;
  left: 50%;
  margin-left: -30px;
  z-index: 100;
}
.yan-svga {
  position: absolute;
  left: 50%;
  width: 70px;
  height: 120px;
  margin-left: -42px;
  top: 308px;
  z-index: 99;
}
.main-btn-list {
  position: absolute;
  top: 638px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
  .set-main-btn {
    width: 90px;
    height: 25px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 13px;
    border: 1px solid #f87a1b;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #f87a1b;
    margin: 0 5px;
  }
}
</style>
