<template>
  <div class="page-box">
    <cell-group :list="list"></cell-group>
  </div>
</template>
<script>
import CellGroup from '@/components/CellGroup/CellGroup.vue';
import apiPath from '@/utils/apiPath';
export default {
  components: { CellGroup },
  data() {
    return {
      list: [
        {
          title: '进入供奉神佛',
          value: '',
        },
        {
          title: '进入供奉逝者',
          value: '',
        },
        {
          title: '进入供奉地神”',
          value: '',
        },
        {
          title: '进入家风家训',
          value: '',
        },
        {
          title: '打开香薰',
          value: '',
        },
        {
          title: '播放音乐',
          value: '',
        },
        {
          title: '关闭音乐',
          value: '',
        },
        {
          title: '播放下一首',
          value: '',
        },
        {
          title: '播放上一首',
          value: '',
        },
      ],
      serialId: '',
    };
  },
  mounted() {
    this.serialId = this.$route.query.serial_id;
    this.getWord();
  },
  methods: {
    getWord() {
      this.$http
        .get(apiPath.voiceWord, {
          serial_id: this.serialId,
        })
        .then((res) => {
          let tmp = res.data.voice_word.split('|');
          let arr = JSON.parse(JSON.stringify(this.list));
          arr.forEach((i, index) => {
            i.value = `"${tmp[index]}"`;
          });

          this.list = arr;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  min-height: calc(var(--vh) * 100);
  background-color: #e8e8e8;
}
</style>
