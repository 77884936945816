var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-box" },
    [
      _c(
        "div",
        { staticClass: "my-header f fc fbt", on: { click: _vm.chooseImg } },
        [
          _c("div", { staticClass: "label" }, [_vm._v("头像")]),
          _c("div", { staticClass: "f fc fbt" }, [
            _c("div", { staticClass: "my-avatar f fc fvc" }, [
              _c("img", { attrs: { src: _vm.userInfo.avatar } }),
            ]),
            _c(
              "div",
              [
                _c("van-icon", {
                  staticClass: "right-arrow-icon",
                  attrs: { name: "arrow" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "btn f fc fvc" },
        [
          _c(
            "van-button",
            {
              staticClass: "default-btn",
              attrs: { size: "small", loading: _vm.loading },
              on: { click: _vm.logout },
            },
            [_vm._v("退出登录")]
          ),
        ],
        1
      ),
      _c("cell-group", { attrs: { list: _vm.settingList } }),
      _c("img-picker", {
        ref: "comImgPicker",
        attrs: { from: "1" },
        on: { emitImgUrl: _vm.emitImgUrl },
      }),
      _c(
        "van-dialog",
        {
          attrs: {
            title: "修改名称",
            "show-cancel-button": "",
            beforeClose: _vm.beforeNameDialogClose,
          },
          model: {
            value: _vm.nameDialog,
            callback: function ($$v) {
              _vm.nameDialog = $$v
            },
            expression: "nameDialog",
          },
        },
        [
          _c("van-field", {
            attrs: {
              maxlength: "10",
              clearable: "",
              placeholder: "请输入新的名称",
            },
            model: {
              value: _vm.newName,
              callback: function ($$v) {
                _vm.newName = $$v
              },
              expression: "newName",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }