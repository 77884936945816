var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    _vm._l(
      _vm.list,
      function ({
        id,
        icon,
        title,
        des,
        status,
        task_type,
        fu_de,
        button_title,
        finish_num,
        limit_num,
      }) {
        return _c("div", { staticClass: "list f" }, [
          _c("img", {
            staticClass: "icon f",
            attrs: { src: icon, alt: "", srcset: "" },
          }),
          _c("div", { staticClass: "content" }, [
            _c("div", { key: id, staticClass: "title" }, [
              _vm._v(" " + _vm._s(title) + " "),
            ]),
            _c("div", { staticClass: "des" }, [_vm._v(_vm._s(des))]),
          ]),
          _c("div", { staticClass: "handle" }, [
            _c(
              "div",
              { staticClass: "f fe" },
              [
                status === 1
                  ? _c(
                      "van-button",
                      {
                        staticClass: "primary-btn",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.getFude(id, title, fu_de)
                          },
                        },
                      },
                      [_vm._v(" 领取 ")]
                    )
                  : status === 2
                  ? _c(
                      "van-button",
                      {
                        staticClass: "primary-btn",
                        attrs: { disabled: "", size: "small" },
                      },
                      [_vm._v("已领取")]
                    )
                  : _c(
                      "van-button",
                      {
                        staticClass: "primary-btn",
                        attrs: { size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleFn(task_type, title, fu_de)
                          },
                        },
                      },
                      [_vm._v(_vm._s(button_title))]
                    ),
              ],
              1
            ),
            _c("div", { staticClass: "finish_num" }, [
              _vm._v("已完成" + _vm._s(finish_num) + "/" + _vm._s(limit_num)),
            ]),
          ]),
        ])
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }