var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page-box",
      style: { backgroundImage: `url(${_vm.bgImg})` },
    },
    [
      _c("div", { staticClass: "content f fv fc" }, [
        _c("div", { staticClass: "content-detail" }, [
          _c("img", {
            staticClass: "summary-image f",
            attrs: { src: _vm.summary_image, alt: "" },
          }),
        ]),
        _c("div", { staticClass: "pay-area" }, [
          _c("div", { staticClass: "css-type2" }, [
            _vm.wechat
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "debounce",
                        rawName: "v-debounce:wechat",
                        value: _vm.createOrder,
                        expression: "createOrder",
                        arg: "wechat",
                      },
                    ],
                    staticClass: "select-item f fc wechat",
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm.alipay
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "debounce",
                        rawName: "v-debounce:alipay",
                        value: _vm.createOrder,
                        expression: "createOrder",
                        arg: "alipay",
                      },
                    ],
                    staticClass: "select-item f fc alipay",
                  },
                  [_vm._m(1)]
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "contact-area f fc fvc", on: { click: _vm.kefu } },
          [_vm._v("联系客服")]
        ),
      ]),
      _c("Dialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-way f fc" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/951c7d88ff5302-47x47.png",
        },
      }),
      _vm._v("微信支付 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-way f fc" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/134b410b4a030a-47x47.png",
        },
      }),
      _vm._v("支付宝 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }