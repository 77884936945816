<template>
  <div class="container">
    <div class="title">
      {{ title }}
    </div>
    <div class="date">
      {{ date }}
    </div>
    <div class="content" v-html="content"></div>
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
export default {
  data() {
    return {
      id: 0,
      title: '',
      date: '',
      content: '',
    };
  },
  mounted() {
    this.id = this.$tools.getQueryFromUrl('id');
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$http.get(apiPath.getFestivalDetail + `/${this.id}`).then(res => {
        this.detail = res.data;
        this.title = res.data.title;
        this.date = res.data.date_calender ? res.data.date_calender + res.data.lunar : '日期';
        this.content = res.data.content;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 16px;
  .title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 23px;
  }
  .date {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .content {
    margin-top: 43px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5;
    text-align: justify;
    word-break: break-all;
  }
}
</style>
