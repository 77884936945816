var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "guide-popup" },
    [
      _c(
        "van-popup",
        {
          on: { "click-overlay": _vm.onCancel },
          model: {
            value: _vm.showFlag,
            callback: function ($$v) {
              _vm.showFlag = $$v
            },
            expression: "showFlag",
          },
        },
        [
          _c("div", { staticClass: "popup-box f fv fc" }, [
            _c("div", { staticClass: "title" }, [_vm._v("【上供物品】")]),
            _c("div", { staticClass: "sub-title" }, [
              _vm._v("上供花、香、果、蜡烛"),
            ]),
            _c("div", { staticClass: "btn", on: { click: _vm.know } }, [
              _vm._v("知道了"),
            ]),
            _c("img", {
              staticClass: "guide",
              attrs: {
                src: "https://img-fe.tengzhihh.com/image/af54b8f6aeec05-476x484.png",
              },
              on: { click: _vm.know },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }