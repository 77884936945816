<template>
  <div class="guide-popup">
    <van-popup v-model="showFlag" @click-overlay="onCancel">
      <div class="popup-box f fv fc">
        <div class="title">【上供物品】</div>
        <div class="sub-title">上供花、香、果、蜡烛</div>
        <div class="btn" @click="know">知道了</div>
        <img src="https://img-fe.tengzhihh.com/image/af54b8f6aeec05-476x484.png" class="guide" @click="know" />
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showFlag: true,
    };
  },
  methods: {
    onCancel() {
      this.showFlag = false;
      this.$track('xinshou_tanchuan_gongpin_close');
      this.$emit('setValue', 'showGuide', false);
    },
    show() {
      this.showFlag = true;
    },
    know() {
      this.onCancel();
      this.$emit('setValue', 'pageStep', 'offerings');
    },
  },
};
</script>

<style lang="less" scoped>
.guide-popup {
  .van-popup {
    background: transparent !important;
  }
}
.popup-box {
  padding-top: 50px;
}

.btn {
  width: 81px;
  height: 28px;
  border-radius: 16px;
  border: 2px solid #ff913e;
  line-height: 28px;
  text-align: center;
  color: #ff913e;
  font-size: 14px;
  margin-bottom: 24px;
}
.title {
  text-align: center;
  font-size: 18px;
  color: #ff913e;
  margin-bottom: 12px;
}
.sub-title {
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 24px;
}
.guide {
  width: 238px;
  height: 242px;
}
</style>
