<template>
  <div>
    <app-main :showBottom="currentRouteInfo.showBottom"></app-main>
    <bottom v-if="currentRouteInfo.showBottom"></bottom>
  </div>
</template>
<script>
import AppMain from '@/components/Layout/AppMain.vue';
import Bottom from '@/components/Layout/Bottom.vue';

import { mapState } from 'vuex';
export default {
  components: {
    AppMain,
    Bottom,
  },
  computed: {
    ...mapState(['currentRouteInfo']),
  },
};
</script>
