var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.registerState === 1
    ? _c(
        "div",
        { staticClass: "phone-reg-box" },
        [
          _c("DownloadBar"),
          _c("div", { staticClass: "page-title" }, [_vm._v("手机号注册")]),
          _c(
            "div",
            { staticClass: "f fv fvc input-container" },
            [
              _c("van-field", {
                staticClass: "input-box",
                attrs: {
                  clearable: "",
                  center: "",
                  placeholder: "请输入电话号码",
                  maxlength: "11",
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
              _c("van-field", {
                staticClass: "input-box",
                attrs: {
                  center: "",
                  clearable: "",
                  "max-length": "6",
                  placeholder: "请输入短信验证码",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "button",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "f fc" },
                            [
                              _c(
                                "van-button",
                                {
                                  staticClass: "code-btn",
                                  attrs: { size: "small" },
                                  on: { click: _vm.getCode },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.codeSentStatus
                                        ? _vm.calcTime
                                        : "获取验证码"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1412669746
                ),
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _c(
                "van-button",
                {
                  staticClass: "next-btn",
                  attrs: { loading: _vm.loading, type: "info" },
                  on: { click: _vm.checkPhone },
                },
                [_vm._v("下一步")]
              ),
              _vm.env === "isLocal"
                ? _c(
                    "van-button",
                    {
                      staticClass: "next-btn",
                      attrs: { loading: _vm.loading, type: "info" },
                      on: { click: _vm.oneClick },
                    },
                    [_vm._v("一键登录（上线时去除）")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "phone-reg-box" },
        [
          _c("DownloadBar"),
          _c("div", { staticClass: "page-title" }, [_vm._v("完善个人信息")]),
          _c(
            "div",
            { staticClass: "f fv reg-box" },
            [
              _c("img-picker", {
                ref: "comImgPicker",
                attrs: { from: "1" },
                on: { emitImgUrl: _vm.emitImgUrl },
              }),
              _c(
                "div",
                {
                  staticClass: "my-avatar f fc fvc",
                  on: { click: _vm.chooseImg },
                },
                [
                  _vm.avatarUrl
                    ? _c("img", { attrs: { src: _vm.avatarUrl } })
                    : _vm._e(),
                  _c("img", {
                    staticClass: "edit-icon",
                    attrs: {
                      src: "https://img-fe.tengzhihh.com/image/f06e227958b224-84x84.png",
                    },
                  }),
                ]
              ),
              _c("van-field", {
                staticClass: "input-box",
                attrs: {
                  placeholder: "输入你的名字",
                  clearable: "",
                  maxlength: "10",
                },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _c("div", { staticClass: "sub-tip" }, [
                _vm._v("为方便机主管理账号，请填写真实信息"),
              ]),
              _c(
                "van-button",
                {
                  staticClass: "next-btn",
                  attrs: { loading: _vm.loading, type: "info" },
                  on: { click: _vm.register },
                },
                [_vm._v("下一步")]
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }