<template>
  <div>
    <SystemEdit
      ref="systemEdit"
      v-show="pageStep === 'sys_edit'"
      :serialId="serialId"
      :lessonId="lessonId"
      :editMode="editMode"
      :categoryId="categoryId"
      @setValue="setValue"
    />
    <CusEdit
      ref="cusEdit"
      v-show="pageStep === 'cus_edit'"
      :editMode="editMode"
      :selectedArr="selectedArr"
      :serialId="serialId"
      :lessonId="lessonId"
      @setValue="setValue"
      @deleteItem="deleteItem"
    />
    <LessonSelect
      ref="lessonSelect"
      v-show="pageStep === 'lesson_select'"
      :serialId="serialId"
      :indexMode="indexMode"
      @setValue="setValue"
      @confirmSelectedList="confirmSelectedList"
    />
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import tools from '@/utils/tools';
import Introduce from './comp/Lesson/Introduce.vue';
import CusEdit from './comp/Lesson/CusEdit.vue';
import SystemEdit from './comp/Lesson/SystemEdit.vue';
import LessonSelect from './comp/Lesson/LessonSelect.vue';

export default {
  components: {
    Introduce,
    CusEdit,
    SystemEdit,
    LessonSelect,
  },
  data() {
    return {
      serialId: '',
      pageStep: 'sys_edit',
      editMode: 'new', // new or edit
      selectedArr: [],
      lessonId: 0,
    };
  },
  created() {
    this.lessonId = this.$route?.query?.lesson_id;
    this.serialId = this.$route?.query?.serial_id;
    this.editMode = this.$route?.query?.edit_mode;
    this.pageStep = this.$route?.query?.page_step;
    this.categoryId = this.$route?.query?.category_id;
    this.indexMode = this.$route?.query?.index_mode;
  },
  mounted() {
    if (this.editMode === 'edit' && this.lessonId) {
      this.getLessonDetail();
    }
  },
  methods: {
    getLessonDetail() {
      this.$http
        .get(`${apiPath.lessonDetail}/${this.lessonId}`, {
          serial_id: this.serialId,
        })
        .then((res) => {
          if (res.data.category_id === 4) {
            this.pageStep = 'cus_edit';
            this.$refs.cusEdit.setDetail(res.data);
            this.selectedArr = res.data.chapters;
            this.$refs.lessonSelect.setSelectedArr(this.selectedArr);
          } else {
            this.pageStep = 'sys_edit';
            this.$refs.systemEdit.setDetail(res.data);
          }
        });
    },
    confirmSelectedList(list) {
      this.selectedArr = list;
    },
    setValue(k, v) {
      this[k] = v;
    },
    goHome() {
      this.$router.push(`/device/index?serial_id=${this.STJkgl88313}`);
    },

    deleteItem(index) {
      if (index !== -1) {
        this.selectedArr.splice(index, 1);
      }
    },
  },
};
</script>
