<template>
  <div class="goods-item f fc">
    <div class="img">
      <img :src="item.thumbnail" />
    </div>
    <div class="info f fv fbt">
      <div class="name clamp2">
        {{ item.name }}
      </div>
      <div class="data f fe fbt">
        <div class="price">
          <div class="current-price">￥{{ item.final_amount }}</div>
          <div class="origin-price">￥{{ item.original_amount }}</div>
        </div>
        <div class="stock">库存：{{ item.stock }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: Object,
      default: () => {
        return {
          name: '',
          image: '',
          original_amount: '0',
          final_amount: '0',
          category: 'virtual',
          introduction: '',
        };
      },
    },
  },
  data() {
    return {
      count: 1,
    };
  },
  methods: {
    onChange(e) {
      this.count = e;
      this.$emit('countChange', e);
    },
  },
};
</script>

<style lang="less">
.van-stepper__minus,
.van-stepper__plus {
  background-color: #fff;
}
</style>
<style lang="less" scoped>
.fe {
  align-items: flex-end;
}
.goods-item {
  width: 100%;
  height: 95px;
  .img {
    margin-right: 20px;
    width: 95px;
    height: 95px;
    img {
      width: 95px;
      height: 95px;
    }
  }
  .info {
    height: 100%;
    flex: 1;
    .name {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
    .stock {
      font-size: 14px;
    }
    .current-price {
      font-size: 17px;
      font-weight: bold;
      color: #ff0000;
    }
    .origin-price {
      font-size: 12px;
      font-weight: 500;
      text-decoration: line-through;
      color: #a7a7a7;
    }
  }
}
</style>
