<template>
  <div class="page">
    <div class="layout">
      <div class="address-list" v-if="list.length">
        <div class="list" v-for="item in list" :key="item.id" @click="addressClick(item)">
          <div class="head flex">
            <div class="flex mr-15">
              <div class="name">{{ item.username }}</div>
              <div class="mobile">{{ item.mobile }}</div>
            </div>
            <div class="remove" @click.stop="removeClick(item)"><van-icon name="cross" /></div>
          </div>
          <div class="detail">
            {{ item.province }}&nbsp;{{ item.city }}&nbsp;{{ item.district }}&nbsp;{{ item.street }}{{ item.address }}
          </div>
          <div class="handle flex">
            <div :class="['default', 'flex', item.is_default === 1 ? 'checked' : '']">
              <img
                v-if="item.is_default === 1"
                class="mr8"
                src="https://img-fe.tengzhihh.com/image/ddf795e2137823-14x14.png"
                alt=""
                srcset=""
              />
              <div v-else class="check mr8" v-throttle:[item]="patchFn"></div>
              {{ item.is_default === 1 ? '已设为默认' : '默认' }}
            </div>
            <div @click.stop="editFn(item)">修改</div>
          </div>
        </div>
      </div>
      <empty :label="'暂无地址'" v-else />
    </div>
    <div class="add-address">
      <van-button block color="#F87A1B" size="small" v-throttle="addAddress">+添加收货地址</van-button>
    </div>
    <van-popup v-model="removepop">
      <p class="remove-tip">确定要删除该地址吗？</p>
      <div class="popHandle flex">
        <van-button type="default" @click="removepop = false">取消</van-button>
        <van-button color="#F87A1B" v-throttle="removeFn" class="btn">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import empty from '@/components/Empty';
import { addressList, removeAddress, patchAddress, editAddress } from '@/api/address.js';
export default {
  components: {
    empty,
  },
  data() {
    return {
      removepop: false,
      list: [],
      removeId: '',
      routerParmas: {},
    };
  },
  created() {
    if (this.$route.params) {
      this.routerParmas = this.$route.params;
    }
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      const res = await addressList();
      this.list = res.data.list;
    },
    removeClick({ id }) {
      this.removeId = id;
      this.removepop = true;
    },
    async removeFn() {
      await removeAddress({ id: this.removeId });
      this.removepop = false;
      this.getList();
    },
    async patchFn(data) {
      await patchAddress({ id: data.id, is_default: 1 });
      this.getList();
    },
    async editFn({ id }) {
      this.addAddress({ id });
    },
    addressClick(params = {}) {
      const { name, query } = this.routerParmas;
      if (!name) return;
      this.$router.replace({
        name,
        query,
        params: {
          address_id: params.id,
        },
      });
    },
    addAddress(params = {}) {
      this.$router.push({
        name: 'add-address',
        params,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  height: 100vh;
  box-sizing: border-box;
  background: #f5f5f5;
}
.flex {
  display: flex;
}
.layout {
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 50px;
  background: #f5f5f5;
  .address-list {
    padding: 16px 12px 16px 16px;
    margin: 0 8px;
    background: #fff;
    border-radius: 10px;

    .list {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      margin-bottom: 18px;
      .head {
        position: relative;
        justify-content: space-between;
        margin-bottom: 8px;
        .name {
          font-size: 14px;
          line-height: 22px;
          font-weight: bold;
          margin-right: 16px;
          color: rgba(0, 0, 0, 0.85);
        }
        .mobile {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.85);
        }
        .mr-15 {
          margin-right: 15px;
        }
        .remove {
          right: 0;
          font-size: 14px;
          position: absolute;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.45);
          top: 4px;
        }
      }
      .detail {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 8px;
        color: rgba(0, 0, 0, 0.65);
        word-wrap: break-word;
      }
      .handle {
        font-size: 13px;
        justify-content: space-between;
        margin-bottom: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        .checked {
          color: #f87a1b;
        }
        .default {
          align-items: center;
          line-height: 18px;
          .check {
            display: flex;
            width: 12px;
            height: 12px;
            border: 1px solid rgba(0, 0, 0, 0.45);
            border-radius: 50%;
          }
          img {
            width: 14px;
            height: 14px;
          }
          .mr8 {
            margin-right: 6px;
          }
        }
      }
    }
    .list:last-child {
      border: 0;
      margin: 0;
      .handle {
        margin-bottom: 0;
      }
    }
  }
}
.add-address {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  background: #f5f5f5;
  .van-button {
    border-radius: 10px;
  }
}
.remove-tip {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
  width: 295px;
  text-align: center;
  padding: 30px 0;
}
.popHandle {
  padding: 0 35px 20px;
  justify-content: space-between;
  /deep/.van-button--default {
    background: #f6f6f6;
  }
  .van-button {
    width: 100px;
    height: 40px;
    border-radius: 10px;
  }
}
</style>
