import axios from 'axios';
import store from '@/store';
const env = store.state.env;
const service = axios.create({
  timeout: 10 * 60 * 1000, // 请求超时时间
});

export function errReport(target, msg) {
  const data = {
    id: 92, // 项目号
    uin: '神台手机端', // 子项目标识
    msg, //错误信息
    target, //错误目标接口或文件或组件
    from: location.href, //来源链接
  };
  env === 'isProd' && service.post('https://error.qiyuan333.cn/api/errrorlog/receive', data);
}

export function errHandler(err, vm) {
  let { message, name, stack } = err;
  let stackStr = stack ? stack.toString() : `${name}:${message}`;
  errReport({
    target: vm?.$options?._componentTag,
    message: stackStr,
  });
}
