var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "gift-fude" }, [
    _c("div", { staticClass: "container" }, [
      _c("img", {
        staticClass: "gift-fude-img",
        attrs: {
          src: "https://fe-img.tengzhihh.com/image/d457944a873cdc01f9f5d0ebcd5fdc90-690x690.png",
          alt: "",
        },
      }),
      _vm._m(0),
      _c("div", { staticClass: "gongde-num" }, [
        _vm._v(" " + _vm._s(_vm.num) + " "),
      ]),
      _c("div", { staticClass: "btn", on: { click: _vm.handleGoRankList } }, [
        _vm._v("查看我的功德排名"),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tips" }, [
      _vm._v("感谢您一直以来的支持"),
      _c("br"),
      _vm._v("获得功德"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }