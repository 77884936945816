export default {
  clearUserData({ commit }) {
    commit('setUserInfo', {
      name: '',
      avatar: '',
      phone: '',
    });
    commit('setUserAccessToken', '');
    commit('setUserOpenId', '');
  },
};
