var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "f fv fc page-box" },
    [
      _c("DownloadBar"),
      _c("img", {
        staticClass: "logo-img",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/c4477fbb7d2f6d-228x282.png",
        },
      }),
      _c("img", {
        staticClass: "slogan-img",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/0563000ddcff6a-178x52.png",
        },
      }),
      _c(
        "div",
        { staticClass: "f fv" },
        [
          _c(
            "van-button",
            {
              staticClass: "login-btn",
              attrs: { type: "info" },
              on: { click: _vm.phoneLogin },
            },
            [_vm._v(" 手机号登录 ")]
          ),
          _vm.$tools.isWeChat()
            ? _c(
                "van-button",
                {
                  staticClass: "login-btn wc",
                  attrs: { type: "primary" },
                  on: { click: _vm.weChatLogin },
                },
                [_vm._v(" 微信登录 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "f fc" },
        [
          _c(
            "van-checkbox",
            {
              attrs: { "checked-color": "#F87A1B", "icon-size": "14px" },
              model: {
                value: _vm.checked,
                callback: function ($$v) {
                  _vm.checked = $$v
                },
                expression: "checked",
              },
            },
            [
              _c("span", { staticClass: "tip" }, [
                _vm._v("我已阅读并同意眷吉的 "),
                _c(
                  "span",
                  {
                    staticStyle: { color: "#f87a1b" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.$router.push("/my/userAgreement")
                      },
                    },
                  },
                  [_vm._v("用户协议")]
                ),
                _vm._v(" 和 "),
                _c(
                  "span",
                  {
                    staticStyle: { color: "#f87a1b" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.$router.push("/my/privacyPolicy")
                      },
                    },
                  },
                  [_vm._v("隐私政策")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }