import { render, staticRenderFns } from "./privacyPolicy.vue?vue&type=template&id=ade0ee8a&scoped=true&"
var script = {}
import style0 from "./privacyPolicy.vue?vue&type=style&index=0&id=ade0ee8a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ade0ee8a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ade0ee8a')) {
      api.createRecord('ade0ee8a', component.options)
    } else {
      api.reload('ade0ee8a', component.options)
    }
    module.hot.accept("./privacyPolicy.vue?vue&type=template&id=ade0ee8a&scoped=true&", function () {
      api.rerender('ade0ee8a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/my/privacyPolicy.vue"
export default component.exports